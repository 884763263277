<ul class="list-unstyled">
  <ng-container *ngFor="let specGroup of specs">
    <ng-container *ngFor="let spec of specGroup['items']">
      <li *ngIf="spec && spec.description" [ngClass]="{'bg-success text-white px-1':articleService.selectedArticleSpecCode === spec.code}" (click)="highlightSpec(spec.code)">
        <span [innerHTML]="spec.description"></span>
        <b item-end [innerHTML]="spec.value"></b>
      </li>
    </ng-container>
  </ng-container>
</ul>
