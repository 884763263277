import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SearchService } from "src/app/services/search.service";
import { Observable, of, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  map,
  tap
} from "rxjs/operators";
import { Article } from "src/app/interfaces/article";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-bicycle-finder",
  templateUrl: "./bicycle-finder.component.html",
  styleUrls: ["./bicycle-finder.component.scss"]
})
export class BicycleFinderComponent implements OnInit {
  public bicycleSelected$ = new Subject();
  model: any;
  searching = false;
  showUnknown = true;
  searchFailed = false;
  @ViewChild('searchText') input: ElementRef;
  constructor(
    public modal: NgbActiveModal,
    private searchService: SearchService,
    private renderer: Renderer2,
    private alertService: AlertService,
    private translationService: TranslateService,
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.input) {
      this.renderer.selectRootElement(this.input["nativeElement"]).focus();
    }
  }

  bicycleUnknown(framenumber: string) {
    this.bicycleSelected$.next({
      bicycle: null,
      framenumber: framenumber ? framenumber : "-"
    });
    this.modal.close();
  }

  selectArticle(bicycle: Article, framenumber: string) {
    if (bicycle.article_type === "P") {
      this.alertService.showPopup(this.translationService.instant("NO_BICYCLE"), "error");
    } else {
      this.bicycleSelected$.next({
        bicycle: bicycle,
        framenumber: framenumber
      });
      this.modal.close();
    }
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap(term =>
        this.searchService.searchInstant(term).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }),
          map(response => (response ? response["result"] : []))
        )
      ),
      tap(() => (this.searching = false))
    );

  formatter = (x: { single_article_code: string }) => x.single_article_code;
}
