import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ApiResponse } from "src/app/interfaces/api-response";
import { User } from "src/app/interfaces/user";
import { AccountService } from "src/app/services/account.service";
import { AlertService } from "src/app/services/alert.service";
import { ApplicationService } from "src/app/services/application.service";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-resend-okta-activation-modal",
  templateUrl: "./resend-okta-activation-modal.component.html",
  styleUrls: ["./resend-okta-activation-modal.component.css"],
})
export class ResendOktaActivationModalComponent implements OnInit {
  @Output() activationResent: EventEmitter<boolean> = new EventEmitter<boolean>();
  user: User;
  email: string = "";
  loading: boolean;

  get emailInvalid() {
    return !this.helperService.emailRegex.test(this.email);
  }

  constructor(
    public modal: NgbActiveModal,
    private accountService: AccountService,
    private helperService: HelperService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private applicationService: ApplicationService
  ) {}

  ngOnInit(): void {}

  submit() {
    this.loading = true;
    this.applicationService.showLoader(true);
    this.accountService
      .resendOktaActivation(this.user, this.email)
      .subscribe((apiResponse: ApiResponse) => {
        this.loading = false;
        this.applicationService.hideLoader();
        if (this.helperService.checkResponse(apiResponse)) {
          this.alertService.showInfoNotification(
            this.translateService.instant("SUCCESS")
          );
          this.activationResent.emit(true);
          this.modal.close();
        }
      });
  }
}
