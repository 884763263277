import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NewBasketComponent } from "./new-basket/new-basket.component";
import { BasketService } from "src/app/services/basket.service";
import * as _ from "underscore";
import { Basket } from "src/app/interfaces/basket";
import { ApiResponse } from "src/app/interfaces/api-response";

@Component({
  selector: "app-select-basket",
  templateUrl: "./select-basket.component.html",
  styleUrls: ["./select-basket.component.scss"]
})
export class SelectBasketComponent implements OnInit {
  @Output() basketSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() newModalClosed: EventEmitter<any> = new EventEmitter<any>();
  @Input() basket:number|string = -1;
  baskets: any = [];
  modalRef: any;
  constructor(
    private modalService: NgbModal,
    private basketService: BasketService
  ) {}

  ngOnInit() {
    this.getBaskets();
  }

  getBaskets() {
    this.basketService.getBaskets().subscribe(response => {
      if (response["result"]) {
        this.baskets = response["result"];

        if (this.baskets.length) {
          const activeBasket = _.findWhere(this.baskets, {
            active: true,
            pre_order: false,
            locked: false
          });

          if (activeBasket) {
            this.basket = activeBasket["id"];
            // Emit active basket id
            this.basketSelected.emit(this.basket);
          } else {
            this.basketService.getActiveBasket().then((apiResponse: ApiResponse) => {
              if (apiResponse && apiResponse.result) {
                const basket = apiResponse.result as Basket;
                if (!basket.pre_order && !basket.locked) {
                  this.basket  = basket.id;
                  // Emit active basket id
                  this.basketSelected.emit(this.basket);
                }
              } else {
                this.newBasket();
              }
            })
          }
        }
      }
    });
  }

  newBasket() {
    this.modalRef = this.modalService.open(NewBasketComponent, {
      centered: true,
      container: "#modalContainer"
    });

    this.modalRef.result.then((result) => {

    }, (reason) => {
        this.newModalClosed.emit(true);
    });

    this.modalRef.componentInstance.basketCreated.subscribe(response => {
      this.basket = response;
      this.getBaskets();
    });
  }

  selectBasket(event) {
    this.basketSelected.emit(this.basket);
  }
}
