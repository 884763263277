import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { linkedDealerInfo } from 'src/app/interfaces/account-information';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { Dealer } from 'src/app/interfaces/dealer';
import { TokenContainer } from 'src/app/interfaces/tokencontainer';
import { AlertService } from 'src/app/services/alert.service';
import { AuthenticateService } from 'src/app/services/authenticate.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: "app-select-linked-dealer",
  templateUrl: "./select-linked-dealer.component.html",
  styleUrls: ["./select-linked-dealer.component.scss"],
})
export class SelectLinkedDealerComponent implements OnInit {
  @Input() dealers: linkedDealerInfo[];
  @Output() dealerSelected: EventEmitter<TokenContainer> =
    new EventEmitter<TokenContainer>();
  constructor(public modal: NgbActiveModal, private authService: AuthenticateService, private helperService: HelperService, private alertService: AlertService, private translateService: TranslateService) {}

  ngOnInit(): void {}

  select(dealer: linkedDealerInfo) {
    this.authService.authorizeLinkedAccount(dealer.dealer_code).subscribe((apiResponse: ApiResponse) => {
      if (this.helperService.checkResponse(apiResponse)) {
        this.dealerSelected.next(apiResponse.result)
      } else {
         this.alertService.showDangerNotification(
           apiResponse.result
             ? apiResponse.result
             : this.translateService.instant("ERROR")
         );
      }
    })
  }
}
