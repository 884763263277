import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ApiResponse } from "../interfaces/api-response";
import * as appGlobal from "../globals";
import { Subject } from "rxjs";
import { AlertItem } from "../interfaces/cms/alert-item";
import { NewsFilters } from "../interfaces/cms/news-filters";
import { Quicklink } from "../interfaces/cms/quicklinks";
import { CompanyPage } from "../interfaces/cms/company-page";
import { AlertService } from "./alert.service";
import { Router } from "@angular/router";
import { ApplicationService } from "./application.service";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class CmsService {
  public cachedContent = {};
  apiCallPromise: Promise<unknown>;

  constructor(
    private http: HttpClient,
    private helperService: HelperService,
    private router: Router,
    private applicationService: ApplicationService
  ) {}
  getCarousel() {
    return this.http.get(environment.apiendpoint + "cms/carousels");
  }
  getWidgets() {
    return this.http.get(environment.apiendpoint + "cms/widgets");
  }
  getBrandWidgets() {
    const key = `widgets_${this.applicationService.getSelectCompanyGroupCode()}`;

    return new Promise((resolve) => {
      if (typeof this.cachedContent[key] !== "undefined") {
        resolve(this.cachedContent[key]);
      } else {
        this.http
          .get(environment.apiendpoint + "cms/widgets/brands?folder=widgets/brand")
          .subscribe((apiResponse: ApiResponse) => {
            if (this.helperService.checkResponse(apiResponse)) {
              this.cachedContent[key] = apiResponse;
              resolve(apiResponse);
            }
          });
      }
    });
  }

  getAlerts() {
    const storedAlerts = appGlobal.getAlerts();
    if (storedAlerts && storedAlerts.length) {
      return Promise.resolve(storedAlerts);
    }

    if (this.apiCallPromise) {
      // If an API call is already in progress, return the existing promise
      return this.apiCallPromise;
    }

    // Create a new promise for the API call
    this.apiCallPromise = new Promise((resolve, reject) => {
      this.http.get(environment.apiendpoint + "cms/alerts").subscribe({
        next: (apiResponse: ApiResponse) => {
          if (
            apiResponse.success &&
            apiResponse.result &&
            apiResponse.result.total_items
          ) {
            const alerts = apiResponse.result["items"];
            appGlobal.saveAlerts(alerts);
            resolve(alerts);
          } else {
            resolve([]);
          }
          // Reset the promise after the API call is complete
          this.apiCallPromise = null;
        },
        error: (error) => {
          reject(error);
          // Reset the promise if the API call fails
          this.apiCallPromise = null;
        }
      });
    });

    return this.apiCallPromise;
  }

  checkIfAlertRead(alert: AlertItem) {
    return localStorage.getItem(`alert-read-${alert.id}`);
  }

  saveAlertRead(alert: AlertItem) {
    localStorage.setItem(`alert-read-${alert.id}`, "1");
  }

  checkIfAlertClosed(alert: AlertItem) {
    return localStorage.getItem(`alert-closed-${alert.id}`);
  }

  saveAlertClosed(alert: AlertItem) {
    localStorage.setItem(`alert-closed-${alert.id}`, "1");
  }

  // The news
  getNews(filters: NewsFilters) {
    return this.http.post(environment.apiendpoint + "cms/news", filters);
  }

  // The news item details
  getNewsItem(id: String) {
    return this.http.get(environment.apiendpoint + `cms/news/${id}`);
  }

  // The quick links
  getQuickLinks(companyGroupCode: string, folder?: string) {
    return new Promise((resolve) => {
      const storedQuicklinks = appGlobal.getQuicklinks(companyGroupCode);
      if (storedQuicklinks && storedQuicklinks.length) {
        resolve(storedQuicklinks);
      } else {
        this.http
          .get(environment.apiendpoint + `cms/links${folder ? '?folder=' + folder : ''}`)
          .subscribe((apiResponse: ApiResponse) => {
            if (
              this.helperService.checkResponse(apiResponse) &&
              apiResponse.result &&
              apiResponse.result.total_items
            ) {
              const quicklinks = <Quicklink>apiResponse.result["items"];
              appGlobal.saveQuicklinks(companyGroupCode, quicklinks);
              resolve(quicklinks);
            }
          });
      }
    });
  }

  clearCmsItems(companyGroupCode: string) {
    const items = ["brandwidgets","alerts", "quicklinks", "news"];
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);

      // Check if the key contains any of the specified substrings
      const shouldRemove = items.some(item => key.includes(item));

      if (shouldRemove) {
        // Remove the item from session storage
        sessionStorage.removeItem(key);
      }
    }
  }

   // Get the company page
  getPage(pageId: string) {
    const id = String(pageId).split("#")[0];
    return this.http.get(environment.apiendpoint + `cms/pages/${id}`);
  }

  // Autodownload pdf
  getDownload(menuUrl: string) {
    const urlData = menuUrl.split("/");
    const id = urlData[urlData.length - 1];
    const redirectUrl = `/${this.applicationService.getSelectCompanyGroupCode()}/companyPage/${id}`;

    this.getPage(id).subscribe((apiResponse: ApiResponse) => {
      if (apiResponse && apiResponse.success && apiResponse.result) {
        const page = apiResponse.result as CompanyPage;
        if (
          page &&
          page.language_definitions &&
          page.language_definitions.length
        ) {
          const content = page.language_definitions[0].content;
          const url = content.match(/(https?:\/\/[^\s]+)/g);

          if (url === null) {
            this.router.navigateByUrl(redirectUrl);
          } else {
            const formattedUrl = decodeURIComponent(url[0])
              .replace("%22", "")
              .replace('"', "");
            if (formattedUrl.indexOf("pdf") >= 0) {
              window.location.href = formattedUrl;
            } else {
              this.router.navigateByUrl(redirectUrl);
            }
          }
        }
      } else {
        this.router.navigateByUrl(redirectUrl);
      }
    });
  }
}
