import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { WarrantyService } from "src/app/services/warranty.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import * as moment from "moment";
import { GuaranteeDate } from "src/app/interfaces/warranty/guarantee-date";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { ArticleCompensationResult } from "src/app/interfaces/warranty/article-compensation-result";
import { NumberFormatPipe } from "src/app/pipes/number-format.pipe";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-warranty-guarantee-date",
  templateUrl: "./warranty-guarantee-date.component.html",
  providers: [NumberFormatPipe]
})
export class WarrantyGuaranteeDateComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() framenumber: string;
  @Input() articleCode: string;
  @Input() tabSelected: string;
  @Input() warrantyId: number;
  @Input() manualSelect: boolean;
  @Input() bicycleUnknown: boolean;
  hasGuarantee: boolean;
  articleInDealerHistory: boolean;
  guaranteeDateFormatted = "";
  guaranteeDate: string;
  selectedDate: NgbDate;
  guaranteeDateFake: NgbDate;
  warrantyCompensation: ArticleCompensationResult;
  warrantyPathCode = "";
  warrantyPathMessages = {
    A: {
      msg: "COMPENSATION",
      status: "success"
    },
    B: {
      msg: "COMPENSATION",
      status: "success"
    },
    C: {
      msg: "GUARANTEE_VIA_MANUFACTURER",
      status: "warning"
    },
    D: {
      msg: "NO_GUARANTEE_FOR_ARTICLE",
      status: "danger"
    },
    E: {
      msg: "DISPOSE_ARTICLE",
      status: "success"
    },
    F: {
      msg: "COMPENSATION",
      status: "success"
    },
  };
  startDate: NgbDate;
  constructor(private warrantyService: WarrantyService, public numberFormat: NumberFormatPipe, private helperService: HelperService) {}

  ngOnInit() {
    if (this.formGroup.get("guaranteeDate").value) {
      const guaranteeDate = moment(this.formGroup.get("guaranteeDate").value, "YYYY-MM-DD");
      this.guaranteeDate = guaranteeDate.format("DD-MM-YYYY");
      this.formGroup.get("guaranteeDateValid").setValue("1");
      this.startDate = new NgbDate(Number(guaranteeDate.format("YYYY")), Number(guaranteeDate.format("MM")), Number(guaranteeDate.format("DD")));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["framenumber"] && this.framenumber) {
      this.warrantyPathCode = "";
      this.guaranteeDateFormatted = "";
      this.hasGuarantee = false;
      this.getGuaranteeDate();
    }
    // When article unknown - user has to upload invoice
    if ((changes["articleCode"] || (changes["tabSelected"])) && this.articleCode) {
      this.warrantyPathCode = "";
      if (this.tabSelected === "separate_part" || this.bicycleUnknown) {
        if (this.articleCode === "999999") {
          this.manualSelect = true;
          this.enableGuaranteeDate();
        } else if (
          this.tabSelected === "separate_part" &&
          this.articleCode !== "999999"
        ) {
          this.checkArticleInDealerHistory(this.articleCode).then(() => {
            if (this.guaranteeDate || this.formGroup.get("returnCondition").value) {
              this.checkArticleGuarantee();
            }
          })
        } else if (this.articleCode !== "999999") {
          this.checkArticleGuarantee();
        }
      } else {
        if (this.articleCode !== "999999") {
          this.checkArticleGuarantee();
        }
        if (this.tabSelected === "separate_part") {
          this.manualSelect = true;
          this.enableGuaranteeDate();
        }
        if (this.tabSelected === "lease_bike_part") {
          this.manualSelect = false;
          this.disableGuaranteeDate();
        }
      }
    }

    if (changes["tabSelected"] && !this.formGroup.get("articleCode").value) {
      this.warrantyPathCode = "";
      this.formGroup.get("fileUrl").setValue("");
      this.formGroup.get("uploadFile").setValue("");
      this.formGroup.get("guaranteeDate").setValue("");
    }
  }

  disableGuaranteeDate() {
     this.formGroup.get("guaranteeDate").disable();
     this.formGroup.get("guaranteeDateValid").setValue("1");
  }

   enableGuaranteeDate() {
     this.formGroup.get("guaranteeDate").enable();
     this.formGroup.get("guaranteeDateValid").setValue("0");
  }

  // Get the enddate guarantee of the bicycle
  getGuaranteeDate() {
    this.formGroup.get("guaranteeDateValid").enable();
    this.warrantyService
      .getBicycleGuaranteeDate(this.framenumber)
      .subscribe((apiResponse: ApiResponse) => {
        let guaranteeInformation = new GuaranteeDate();
        if (this.helperService.checkResponse(apiResponse)) {
          guaranteeInformation = apiResponse.result as GuaranteeDate;
        }

        if (this.helperService.checkResponse(apiResponse) && guaranteeInformation.warranty_end_date) {
          this.manualSelect = false;
          this.checkBicycleGuarantee(
            Number(guaranteeInformation.warranty_end_date)
          );

          this.guaranteeDateFormatted =
            guaranteeInformation.warranty_end_date_converted;

          // Set inputs
          this.formGroup
            .get("guaranteeDateValid")
            .setValue("1");
          this.formGroup.get("fileUrl").disable();
          this.formGroup
            .get("guaranteeDate")
            .setValue(this.guaranteeDateFormatted);


        } else {
          this.hasGuarantee = false;
          this.manualSelect = true;
          this.enableGuaranteeDate();
        }
      });
  }

  // Check if gurantee of the bicycle is still valid
  checkBicycleGuarantee(guaranteeDate: number) {
    const now = Number(moment().format("YYYYMMDD"));

    // Check if bicycle is within guarantee period
    if (Number(guaranteeDate) >= now) {
    //  this.hasGuarantee = true;
      this.formGroup.get("guaranteeDateValid").setValue(1);
    } else {
      this.hasGuarantee = false;
    }
  }

  // Check the warranty conditions for the article
  // A, B: valid for warranty. C: send to the manfucturer. E: dispose article
  checkArticleGuarantee() {
    this.warrantyCompensation = null;
    this.warrantyPathCode = "";
    this.formGroup.get("articleValid").setValue(1);
    const guaranteeDate = this.guaranteeDate
      ? moment(this.guaranteeDate, "DD-MM-YYYY").format("YYYYMMDD")
      : moment().format("YYYYMMDD");
    if (this.articleCode && guaranteeDate && this.formGroup.get("returnCondition").value) {
      this.warrantyService
        .checkArticleGuarantee(this.articleCode, guaranteeDate, this.formGroup.get("returnCondition").value)
        .subscribe((apiResponse: ApiResponse) => {
          if (this.helperService.checkResponse(apiResponse)) {
            this.warrantyCompensation = apiResponse.result;

            if (!this.warrantyCompensation.path_code || !this.warrantyCompensation.path_code.trim() && this.warrantyCompensation.compensation_amount) {
              this.warrantyCompensation.path_code = "A";
            }

            if (
              this.warrantyCompensation.path_code &&
              this.warrantyCompensation.path_code.trim()
            ) {

              this.warrantyPathCode = this.warrantyCompensation.path_code;

              // If bicycle has no guarantee, override pathCode E
              if (this.manualSelect && this.warrantyPathCode === "E") {
                this.warrantyPathCode = "A";
              }

              // Save data
              this.formGroup.get("articleValid").setValue(this.warrantyPathCode === "A" || this.warrantyPathCode === "F" || this.warrantyPathCode === "B" || this.warrantyPathCode === "E" ? "1" : "");
              this.formGroup.get("warrantyPathCode").setValue(!this.manualSelect || this.hasGuarantee ? this.warrantyPathCode : "");
            }
          }
        });
    }
  }

  // Check if article hasbeen purchased by the dealer
  // If not the dealer has to upload invoice
  checkArticleInDealerHistory(articleCode: string) {
    const promise = new Promise((resolve, reject) => {
    this.warrantyService
      .checkArticleInDealerHistory(articleCode)
      .subscribe((apiResponse: ApiResponse) => {
        this.articleInDealerHistory = false;
        if (this.helperService.checkResponse(apiResponse)) {
          this.articleInDealerHistory = true;
          this.formGroup.get("guaranteeDate").disable();
          this.manualSelect = false;
          this.checkArticleGuarantee();
        } else {
          this.manualSelect = true;
          this.enableGuaranteeDate();
        }
        resolve(true);
      });
    });
    return promise;
  }

  formatDate(data) {
    this.guaranteeDate = `${data.day < 10 ? "0" : ""}${data.day}-${
      data.month < 10 ? "0" : ""
      }${data.month}-${data.year}`;

    let formattedDate = moment(this.guaranteeDate, "DD-MM-YYYY");
    formattedDate.add(2, "years");

    if (this.tabSelected === "bike_part") {
      this.checkBicycleGuarantee(Number(formattedDate.format("YYYYMMDD")));
    } else {
      this.checkArticleGuarantee();
    }
  }
}
