<div class="px-3 row px-md-2">
  <div class="col-12 col-md-3 col-lg-3 col-xl-3">
    <a [routerLink]="!deleted ? ('/' + companyGroupCode + basketUrl) : ('/' + companyGroupCode + '/baskets/archive/' + basket['id'])" class="text-decoration-none">
      <h5>{{basket['name']}}</h5>
       <p class="text-danger" *ngIf="basket.locked && !checkIfOrdered() && !deleted" type="danger">{{"BASKET_LOCKED"|translate}}</p>
      <span class="px-2 py-1 me-1 badge bg-success badge-pill" *ngIf="basket.active && !basket.pre_order && !deleted">{{'ACTIVE' | translate}}</span>
      <span class="px-2 py-1 me-1 badge bg-warning badge-pill" *ngIf="basket.pre_order">{{'PREORDER' | translate}} </span>
      <span class="px-2 py-1 me-1 badge bg-info badge-pill ps-3 position-relative" *ngIf="basket.pre_order">
        <i class="material-icons position-absolute" style="top: 3px; left: 3px; font-size: 12px;">{{(basket.preorder_type === 'Parts' ? 'settings' : 'pedal_bike')}}</i>
        <span class="ms-1">{{(basket.basket_type === 'PreOrderParts' ? 'PARTS' : 'BICYCLES') | translate}}</span>
      </span>
      <small *ngIf="checkIfOrdered() && basket['order_number']">{{"ORDER_NUMBER"|translate}}: {{basket['order_number']}}</small>
    </a>
    <button *ngIf="basket.locked && !checkIfOrdered()" class="mt-3 btn btn-sm btn-outline-primary d-block" (click)="unlockBasket()">{{ 'UNLOCK_BASKET' | translate }}</button>
  </div>
  <div class="col-12 col-md-2">{{basket['user_name']}}<br><small>{{getLastEditTime(basket['last_edit'])}}</small></div>
  <div class="col-8 col-md-2" [ngClass]="{'d-none d-sm-block': showroomMode}">
    <ng-container *ngIf="!deleted && !showroomMode">
      {{ "AMOUNT" | translate}}:
      <app-article-sum [netPrice]="basket['total_price']" [amount]="1"></app-article-sum>
    </ng-container>
  </div>
  <div class="col-md-3"  [ngClass]="showroomMode ? 'col-12' : 'col-4'">
    <app-item-count [companyGroupCode]="companyGroupCode" *ngIf="basket" [items]="basket['items']" [preorder]="basket.pre_order" [item_count]="basket?.item_count"></app-item-count>
  </div>
  <div class="pe-0 col-8 col-md-2 col-lg-1 col-xl-1 d-flex align-items-center">
    <a (click)="deleteBasket()" class="px-0 btn btn-link text-danger px-md-2"
      *ngIf="!checkIfOrdered() && !basket.locked && !deleted">{{'DELETE'|translate}}</a>
    <span *ngIf="checkIfOrdered()" class="d-block d-sm-none">{{basket["last_edit"]}}</span>
  </div>
  <div class="col-4 col-md-1 d-flex align-items-center">
    <a class="btn btn-primary ms-auto"
    *ngIf="!deleted" [queryParams]="{basePage: basePage}"
      [routerLink]="'/' + companyGroupCode + basketUrl">{{
      'VIEW' |
      translate }}</a>
      <a class="btn btn-primary float-md-right"
    *ngIf="deleted"
      [routerLink]="'/' + companyGroupCode + '/baskets/archive/' + basket['id']">{{
      'VIEW' |
      translate }}</a>
  </div>
</div>
