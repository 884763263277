<div
  [ngClass]="{
    invalid: invalid,
    locked: yearAgreementService.yearAgreementParts?.locked || loading
  }"
>
  <ngb-alert
    type="info"
    *ngIf="selectedDealer && yearAgreementService.yearAgreementParts?.send"
    [dismissible]="false"
    >{{ "AGREEMENT_SENT" | translate }}
    {{
      _moment(yearAgreementService.yearAgreementParts?.send).format(
        "DD-MM-YYYY"
      )
    }}</ngb-alert
  >
  <nav
    ngbNav
    #nav="ngbNav"
    [destroyOnHide]="false"
    (navChange)="tabChange($event)"
    [activeId]="selectedTab"
    class="nav-pills"
  >
    <ng-container *ngIf="yearAgreementService.yearAgreementParts">
      <ng-container ngbNavItem="dealerInformation" *ngIf="selectedDealer">
        <a ngbNavLink>
          <span
            [ngClass]="
              commercialSettingsInvalid || dealerLevelInvalid
                ? 'text-white bg-danger p-1'
                : ''
            "
          >
            {{ "DEALER" | translate }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <div
            class="row"
            *ngIf="
              yearAgreementService.yearAgreementParts.commercial_agreements
            "
          >
            <div class="col-4">
              <app-dealer-information
                [selectedDealerInformation]="selectedDealerInformation"
                [selectedDealer]="selectedDealer"
              ></app-dealer-information>
            </div>
            <div class="col-4">
              <app-dealer-commercial-partner-information
                (levelChanged)="checkBrandSelection()"
              ></app-dealer-commercial-partner-information>
            </div>
            <div class="col-4">
              <app-dealer-commercial-partner-settings></app-dealer-commercial-partner-settings>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem="brands" *ngIf="selectedDealer">
        <a ngbNavLink>
          <span [ngClass]="brandsInvalid ? 'text-white bg-danger p-1' : ''">
            {{ "BRANDS" | translate }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <app-dealer-parts-brands-selection
            #brands
            [brands]="yearAgreementService.yearAgreementParts.brands"
            [showToggle]="true"
            [exactSelectionRequired]="true"
            label2="{{ 'BRAND' | translate }}"
            label3="{{ ('DISCOUNT' | translate) + ' %' }}"
            (brandsChange)="checkBrandSelection()"
            [showDiscount]="true"
            *ngIf="yearAgreementService.yearAgreementParts.id"
          ></app-dealer-parts-brands-selection>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem="dealership" *ngIf="selectedDealer">
        <a ngbNavLink>
          <span [ngClass]="dealerShipInvalid ? 'text-white bg-danger p-1' : ''">
            {{ "DEALERSHIPS" | translate }}
          </span>
        </a>
        <ng-template ngbNavContent>
          <app-year-agreement-dealer-ships
            #dealerShips
            *ngIf="yearAgreementService.yearAgreementParts.id"
          ></app-year-agreement-dealer-ships>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem="exceptions" *ngIf="selectedDealer">
        <a ngbNavLink>{{ "EXCEPTIONS" | translate }}</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-md-12 bg-transparent">
              <div class="card-body section">
                <h4>{{ "EXCEPTIONS" | translate }}</h4>
                <textarea
                  class="form-control"
                  rows="6"
                  placeholder="Eventuele uitzonderingen"
                  [(ngModel)]="
                    yearAgreementService.yearAgreementParts.exceptions
                  "
                  *ngIf="yearAgreementService.yearAgreementParts.id"
                ></textarea>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem="summary">
        <a ngbNavLink *ngIf="selectedDealer">{{
          "YEARGREEMENT_SUMMARY" | translate
        }}</a>
        <ng-template ngbNavContent>
          <ng-container
            *ngIf="
              yearAgreementService.yearAgreementParts &&
              yearAgreementService.yearAgreementParts.id > 0
            "
          >
            <app-year-agreement-summary
              #summary
              [yearAgreementModel]="yearAgreementService.yearAgreementParts"
              [id]="yearAgreementService.yearAgreementParts.id"
              (saved)="save()"
              [ts]="ts"
              (send)="agreementSubmitted()"
              [type]="'parts'"
            ></app-year-agreement-summary>
          </ng-container>
        </ng-template>
      </ng-container>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
  <div class="col-12 d-flex px-0 pt-2">
    <button
      *ngIf="
        selectedDealer &&
        accountInformation &&
        yearAgreementService.yearAgreementParts?.locked &&
        accountInformation.can_reopen_yearagreements_parts
      "
      class="btn btn-info btn-lg  mt-3"
      (click)="reopenAgreement()"
    >
      {{ "RE_OPEN" | translate }}
    </button>

    <ng-container
      *ngIf="
        yearAgreementService.yearAgreementParts &&
        !yearAgreementService.yearAgreementParts.locked &&
        yearAgreementService.yearAgreementParts.id
      "
    >
      <button
        class="btn btn-primary btn-lg ms-auto"
        (click)="save()"
        *ngIf="selectedTab !== 'summary'"
        [disabled]="loading ? true : undefined"
        style="min-width: 120px"
      >
        <ng-container *ngIf="!loading">{{ "SAVE" | translate }}</ng-container>
        <span
          class="spinner-border spinner-border-sm text-white ms-auto"
          role="status"
          aria-hidden="true"
          *ngIf="loading"
        ></span>
      </button>
      <button
        class="btn btn-primary btn-lg ms-auto"
        (click)="send()"
        *ngIf="
          selectedTab === 'summary' &&
          !yearAgreementService.yearAgreementParts.locked
        "
        [disabled]="loading ? true : undefined"
        style="min-width: 120px"
      >
        <ng-container *ngIf="!loading">{{ "SEND" | translate }}</ng-container>
        <span
          class="spinner-border spinner-border-sm text-white ms-auto"
          role="status"
          aria-hidden="true"
          *ngIf="loading"
        ></span>
      </button>
    </ng-container>
  </div>
</div>
