import { Component, OnInit, Input } from "@angular/core";
import { User } from "src/app/interfaces/user";
import { AccountService } from "src/app/services/account.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticateService } from "src/app/services/authenticate.service";
import { AccountSettings } from "src/app/interfaces/account-settings";
import { HelperService } from "src/app/services/helper.service";
import { ResendOktaActivationModalComponent } from "../resend-okta-activation-modal/resend-okta-activation-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ResetOktaActivationModalComponent } from "../reset-okta-activation-modal/reset-okta-activation-modal.component";

@Component({
  selector: "app-user-options",
  templateUrl: "./user-options.component.html",
  styleUrls: ["./user-options.component.scss"],
})
export class UserOptionsComponent implements OnInit {
  @Input() user: User;
  passwordResetted: boolean;
  mailSend: boolean;
  accountResetted: boolean;
  accountActivationResend: boolean;
  unlinked: boolean;
  resetted: boolean;
  activationResent: boolean;
  constructor(
    public accountService: AccountService,
    private authenticateService: AuthenticateService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private helperService: HelperService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}

  resetPassword() {
    this.authenticateService
      .resetOkta(this.user)
      .subscribe((apiResponse: ApiResponse) => {
        this.alertService.showInfoNotification(
          this.translateService.instant("SUCCESS")
        );
        this.passwordResetted = true;
      });
  }

  resendActivation() {
    this.accountService
      .resendActivation(this.user)
      .subscribe((apiResponse: ApiResponse) => {
        this.alertService.showInfoNotification(
          this.translateService.instant("SUCCESS")
        );
      });
  }

  unlinkAccount() {
    this.accountService
      .unlinkOktaAccount(this.user)
      .subscribe((apiResponse: ApiResponse) => {
        this.alertService.showInfoNotification(
          this.translateService.instant("SUCCESS")
        );
        this.unlinked = true;
      });
  }

  resetAccount() {
    const modalRef = this.modalService.open(ResetOktaActivationModalComponent, {
      centered: true,
      container: "#modalContainer",
    });
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.resetted.subscribe(() => {
      this.resetted = true;
    });
  }

  resendNewActivation() {
    const modalRef = this.modalService.open(
      ResendOktaActivationModalComponent,
      {
        centered: true,
        container: "#modalContainer",
      }
    );
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.email = this.user.activation_email;
    modalRef.componentInstance.activationResent.subscribe(() => {
      this.activationResent = true;
    });
  }

  // Get the activation link and copy to clipboard
  getActivationLink() {
    this.accountService
      .activationLink(this.user.account_code, String(this.user.user_id))
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.helperService
            .copyTextToClipboard(apiResponse.result)
            .then((result) => {
              if (result) {
                this.alertService.showInfoNotification(
                  this.translateService.instant("URL_COPIED")
                );
              } else {
                alert(apiResponse.result);
              }
            });
        } else {
          this.alertService.showErrorNotification(
            apiResponse.result
              ? apiResponse.result
              : this.translateService.instant("ERROR")
          );
        }
      });
  }
}
