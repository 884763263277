<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container">
  <div *ngIf="story && story.content && !story.html">
    <ndc-dynamic
      [ndcDynamicComponent]="components['page']"
      [ndcDynamicInputs]="story"
    >
    </ndc-dynamic>
  </div>
  <div class="content" [hidden]="!story.html"></div>
</div>
