import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { ApplicationService } from "src/app/services/application.service";
import { TranslateService } from "@ngx-translate/core";
import { BasketService } from "src/app/services/basket.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Basket } from "src/app/interfaces/basket";
import { GroupedBasketArticles } from "src/app/interfaces/grouped-basket-articles";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BasketOptionComponent } from "../basket/basket-option/basket-option.component";
import * as _ from 'underscore';
@Component({
  selector: "app-sent-basket-overview",
  templateUrl: "./sent-basket-overview.component.html",
  styleUrls: ["./sent-basket-overview.component.scss"],
})
export class SentBasketOverviewComponent implements OnInit {
  @Input() unOrderedArticles: GroupedBasketArticles = null;
  @Input() basket: Basket = null;
  groupedArticles: any = {};
  breadcrumbs: any = [];
  basketSum: any = {
    parts: 0,
    bicycles: 0,
    total: 0,
  };
  basketSumUnordered: any = {
    parts: 0,
    bicycles: 0,
    total: 0,
  };
  @Input() basketId = 0;
  showroomMode = false;
  ordered = false;
  pre_order = false;
  showSentMessage: string;



  constructor(
    public applicationService: ApplicationService,
    private translateService: TranslateService,
    private basketService: BasketService,
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.cdRef.detectChanges();
    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.basketId = params["id"];
      }
      this.getBasket();
    });
    const urlParams = new URLSearchParams(window.location.search);
    this.showSentMessage = urlParams.get("processed");
    this.getShowroomMode();
  }

  truckImg() {
    return "https://a.storyblok.com/f/118996/1024x640/125d31ca70/truck.svg";
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );

    this.applicationService.changeEmitted$.subscribe((response) => {
      this.getShowroomMode();
    });
  }

  addBreadCrumbs() {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/"
      ),
      new Breadcrumb(
        this.translateService.instant("BASKETS"),
        this.applicationService.getSelectCompanyGroupCode() + "/baskets/sent"
      ),
      new Breadcrumb(
        `${this.translateService.instant("BASKET_ORDER")} #${this.basket.order_number}`,
        "#"
      ),
    ];
  }

  getBasket() {

    if (this.basket === null) {
      this.applicationService.showLoader(false);
      let service = null;

      if (this.applicationService.isV2Basket()) {
        service = this.basketService
        .getOrderedBasket(this.basketId)
      } else {
        service = this.basketService
        .getBasket(this.basketId)
      }

      service
        .subscribe((response: ApiResponse) => {
          this.applicationService.hideLoader();
          if (response && response.success) {
            this.basket = response.result;
            this.parseBasket();
            this.addBreadCrumbs();
          }
        });
    } else {
      this.parseBasket();
      this.addBreadCrumbs();
    }
  }

  parseBasket() {
    this.pre_order = this.basket.pre_order;
    const orderedItems = this.basket.items.filter((o) => {
      return o.orderline_number !== "";
    });

    this.groupedArticles = this.basketService.groupArticles(
      this.basket.items.filter((o) => {
        return o.orderline_number !== "";
      })
    );

    if (this.unOrderedArticles === null && typeof this.basket.not_ordererd_items !== "undefined") {
      this.unOrderedArticles = this.basketService.groupArticles(
        this.basket.not_ordererd_items
      );
    }

    this.calcTotal("basketSum", orderedItems);
    if (this.basket.not_ordererd_items && typeof this.unOrderedArticles !== "undefined") {
      this.calcTotal("basketSumUnordered", this.unOrderedArticles.parts.items.concat(this.unOrderedArticles.bicycles.items));
    }
  }

  calcTotal(sum, articles) {
    const total = this.basketService.calcTotal(
      articles,
      "basket",
      this.showroomMode,
      this.basket.shipping_cost
    );

    this[sum] = total;
  }

  back() {
    this.router.navigateByUrl(
      this.applicationService.getSelectCompanyGroupCode() +
        (window.location.href.includes("baskets")
          ? `/baskets/sent`
          : `/financial/sent_orders`)
    );
  }

  basketOptionsPopup() {
    const modalRef = this.modalService.open(BasketOptionComponent, {
      centered: true,
      windowClass: "medium",
      container: "#modalContainer",
    });
    modalRef.componentInstance.basket = this.basket;
  }

  gotoOrder() {
    this.router.navigateByUrl(`/${this.applicationService.getSelectCompanyGroupCode()}/financial/openorders/`)
  }
}
