<span class="d-block" *ngIf="lowestPrice">
   {{"LEASE_PRICE"|translate}} <span class="text-lowercase" *ngIf="parsedPricesList && parsedPricesList.length > 1">{{'FROM_MIN_PRICE'|translate}}</span><ng-container  *ngIf="parsedPricesList && parsedPricesList.length > 1">&nbsp;</ng-container><span innerHTML="{{lowestPrice.value  | currencyConvert: lowestPrice.currency}} {{'PER_MONTH'|translate}} {{'PRICE_INCL_VAT'|translate}}"></span>
    <i class="material-icons light popoverTrigger" *ngIf="showTable && parsedPricesList && parsedPricesList.length > 1" triggers="mouseenter:mouseleave" placement="bottom"
        [ngbPopover]="volumeContent" [popoverTitle]="'LEASE_PRICES' | translate" #p="ngbPopover" [autoClose]="'mouseleave'">arrow_drop_down
    </i>
</span>

<ng-container>
    <ng-template #volumeContent>
        <table class="table">
          <thead class="fw-bold">
            <tr>
              <td>{{ 'MONTHS' | translate }}</td>
              <td>{{ 'PRICE' | translate }}</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let priceData of parsedPricesList">
              <td>{{priceData.volume}} &times;</td>
              <td [innerHTML]="priceData.value | currencyConvert: priceData.currency "></td>
            </tr>
          </tbody>
        </table>
      </ng-template>
</ng-container>

<div *ngIf="((selectedDuration && parsedPrices[selectedDuration]))">
    {{"LEASE_PRICE"|translate}} &euro; {{parsedPrices[selectedDuration]["value"]}},- {{"PER_MONTH"|translate}} {{'PRICE_INCL_VAT'|translate}}
</div>
