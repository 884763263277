<div class="btn-group btn-group-toggle py-2 d-flex gap-2">
  <button class="btn" (click)="switchTab('lease_bike_part')" [ngClass]="leaseType === 'lease_bike_part' ? 'btn-dark' : 'btn-primary'">
   {{'DECLARE_PART_REPARATION'|translate}}
  </button>
  <button class="btn" (click)="switchTab('lease_service')" [ngClass]="leaseType === 'lease_service' ? 'btn-dark' : 'btn-primary'">
     {{"DECLARE_READJUSTMENT_AND_MAINTENANCE"|translate}}
  </button>
  </div>

<ng-container *ngIf="leaseType === 'lease_bike_part'">

  <app-warranty-general-inputs (partSelected)="partSelected($event)" (bicycleSelected)="bicycleSelected($event);" [bicycle]="bicycle" [type]="'leasing'"
    [formGroup]="formGroup" [currentArticle]="currentArticle" [tabSelected]="leaseType">
    <div class="mb-3 row" *ngIf="formGroup.get('mileage').enabled">
      <div class="col-md-4">
        <label>{{"MILEAGE"|translate}} *</label>
      </div>
      <div class="col-md-4">
        <input type="number" class="form-control" formControlName="mileage" name="mileage" min="1" oninput="validity.valid||(value='')" maxlength="5" (change)="checkArticleWarranty()" required>
      </div>
    </div>
    <div class="mb-3 ticketNumber row" *ngIf="formGroup.get('articleCode').value" >
      <label for="name" class="col-md-4 control-label text-start">
        {{'TICKET_NUMBER'|translate}}
        </label>
      <div class="col-md-4" [formGroup]="formGroup"> <input type="number" formControlName="complaintCode" (change)="checkArticleWarranty()"
          class="form-control complaintCode" name="complaintCode"> </div>
    </div>
    <app-warranty-article-description-input [formGroup]="formGroup">
    </app-warranty-article-description-input>
  </app-warranty-general-inputs>

  <app-warranty-guarantee-date [warrantyId]="warrantyId" [bicycleUnknown]="bicycleUnknown" [tabSelected]="leaseType" [hidden]="!formGroup.get('articleValid').valid"
    [formGroup]="formGroup" [framenumber]="formGroup.get('framenumber').value"
    [articleCode]="formGroup.get('articleCode').value">
  </app-warranty-guarantee-date>
  <app-warranty-complain-description-inputs *ngIf="formGroup.get('articleCode').valid && formGroup.get('articleValid').valid" [formGroup]="formGroup" [tabSelected]="leaseType" [hideComplaintCode]="true">

  </app-warranty-complain-description-inputs>
</ng-container>

<ng-container *ngIf="leaseType === 'lease_service'">
  <app-warranty-general-inputs [tabSelected]="'lease_service'" (partSelected)="partSelected($event)" [type]="'leasing'" (bicycleSelected)="bicycleSelected($event)"
    [formGroup]="formGroup" [currentArticle]="currentArticle" [bicycle]="bicycle">
    <div class="mb-3 row" *ngIf="formGroup.get('mileage').enabled">
      <div class="col-md-4">
        <label>{{"MILEAGE"|translate}} *</label>
      </div>
      <div class="col-md-4">
        <input type="number" class="form-control" formControlName="mileage" min="1" oninput="validity.valid||(value='')" name="mileage" maxlength="5" (change)="checkArticleWarranty()">
      </div>
    </div>
    <div class="mb-3 complaintCode row" *ngIf="formGroup.get('articleCode').value">
      <label for="name" class="col-md-4 control-label text-start">
        {{'TICKET_NUMBER'|translate}}
        </label>
      <div class="col-md-4" [formGroup]="formGroup"> <input type="number" formControlName="complaintCode" (change)="checkArticleWarranty()"
          class="form-control complaintCode" name="complaintCode"> </div>
    </div>
    <app-warranty-article-description-input [formGroup]="formGroup" *ngIf="formGroup.get('articleCode').value && formGroup.get('mileage').value && isLease">
    </app-warranty-article-description-input>
  </app-warranty-general-inputs>
</ng-container>
<ng-container *ngIf="showApproveMessage">
  <ngb-alert class="mt-2" *ngIf="approved && formGroup.get('articleCode').valid" [type]="'success'" [dismissible]="false">
    {{'ARTICLE_VALID' |translate}}
  </ngb-alert>
  <ngb-alert class="mt-2" *ngIf="!approved && formGroup.get('articleCode').valid" [type]="'danger'" [dismissible]="false">
    <app-cms-page-content pageId="960422060"></app-cms-page-content>
  </ngb-alert>
</ng-container>
