import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Breadcrumb } from 'src/app/interfaces/bread-crumb';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { Basket } from 'src/app/interfaces/basket';
import { GroupedBasketArticles } from 'src/app/interfaces/grouped-basket-articles';
import { AlertService } from 'src/app/services/alert.service';
import { ApplicationService } from 'src/app/services/application.service';
import { BasketService } from 'src/app/services/basket.service';
import { BasketOptionComponent } from '../basket/basket-option/basket-option.component';

@Component({
  selector: "app-deleted-basket-overview",
  templateUrl: "./deleted-basket-overview.component.html",
  styleUrls: ["./deleted-basket-overview.component.scss"],
})
export class DeletedBasketOverviewComponent implements OnInit {
  unOrderedArticles: GroupedBasketArticles;
  basket: Basket;
  groupedArticles: any = {};
  breadcrumbs: any = [];
  basketSum: any = {
    parts: 0,
    bicycles: 0,
    total: 0,
  };
  basketSumUnordered: any = {
    parts: 0,
    bicycles: 0,
    total: 0,
  };
  basketId: 0;
  showroomMode = false;
  ordered = false;

  constructor(
    public applicationService: ApplicationService,
    private translateService: TranslateService,
    private basketService: BasketService,
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.cdRef.detectChanges();
    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.basketId = params["id"];
        this.getBasket();
      }

      this.addBreadCrumbs();
    });

    this.getShowroomMode();
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  addBreadCrumbs() {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/"
      ),
      new Breadcrumb(
        this.translateService.instant("DELETED_BASKETS"),
        this.applicationService.getSelectCompanyGroupCode() + "/baskets/archive"
      ),
      new Breadcrumb(
        `${this.translateService.instant("BASKET")} #${this.basketId}`,
        "#"
      ),
    ];
  }

  getBasket() {
    this.applicationService.showLoader(false);
    this.basketService
      .getDeletedBasket(this.basketId)
      .subscribe((response: ApiResponse) => {
        this.applicationService.hideLoader();
        if (response && response.success) {
          this.basket = response.result;
          this.parseBasket();
        }
      });
  }

  parseBasket() {
    this.groupedArticles = this.basketService.groupArticles(this.basket.items);

    this.calcTotal("basketSum", this.basket.items);
  }

  calcTotal(sum, articles) {
    const total = this.basketService.calcTotal(
      articles,
      "basket",
      this.showroomMode,
      this.basket.shipping_cost
    );

    this[sum] = total;
  }

  back() {
    this.router.navigateByUrl(
      this.applicationService.getSelectCompanyGroupCode() + `/baskets/archive`
    );
  }

  basketOptionsPopup() {
    const modalRef = this.modalService.open(BasketOptionComponent, {
      centered: true,
      windowClass: "medium",
      container: "#modalContainer",
    });
    modalRef.componentInstance.basket = this.basket;
  }

  restore() {
    this.basketService
      .restoreBasket(this.basketId)
      .subscribe((apiResponse: ApiResponse) => {
        if (apiResponse && apiResponse.success) {
          this.alertService.showPopup(
            `${this.translateService.instant("BASKET_RESTORED")}`,
            "success"
          );
          this.router.navigateByUrl(`${this.applicationService.getSelectCompanyGroupCode()}/basket/${this.basketId}`);
        } else {
          this.alertService.showPopup(
            `${this.translateService.instant("ERROR")}`,
            "error"
          );
        }
      });
  }
}
