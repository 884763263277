import { ShippingAddress } from "./shipping-address";

export interface ShippingAddressGroup {
  company_id: string;
  shipping_addresses: ShippingAddress[];
  selected: number;
  address_access: string;
  access_only_address: boolean;
}

export class ShippingAddressGroup implements ShippingAddressGroup {}
