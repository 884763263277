import { Component, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert.service';
import { BasketService } from 'src/app/services/basket.service';
import { Article } from 'src/app/interfaces/article';
import { TranslateService } from '@ngx-translate/core';
import { SearchService } from 'src/app/services/search.service';
import { ArticleService } from 'src/app/services/article.service';
import { SelectWeekComponent } from 'src/app/components/general-components/select-week/select-week.component';
import * as _ from 'underscore';
import { Price } from 'src/app/interfaces/price';
import { ApplicationService } from 'src/app/services/application.service';
import { AvailableArticleTypes } from 'src/app/interfaces/article-type';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { Basket } from 'src/app/interfaces/basket';
import { EcommerceArticlesComponent } from '../../ecommerce-components/ecommerce-articles/ecommerce-articles.component';
import { PreorderService } from 'src/app/services/preorder.service';
import { HelperService } from 'src/app/services/helper.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { BackorderArticle } from 'src/app/interfaces/backorder-article';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-add-to-basket-modal',
  templateUrl: 'add-to-basket-modal.component.html',
  styleUrls: ['add-to-basket-modal.component.scss'],
})
export class AddToBasketModalComponent implements OnInit {
  article: Article;
  eCommerceArticles: Article[] = [];
  quantity: number = 1;
  totalQtyPreorderItems = 0;
  preOrderType: string;
  sumNetPrice: Price;
  sumRetailPrice: Price;
  basket: string;
  size = '';
  parsedDeliveryWeek = '';
  articleType: typeof AvailableArticleTypes = AvailableArticleTypes;
  loading = false;
  @ViewChild(SelectWeekComponent) selectWeek: SelectWeekComponent;
  matrixOptions: any = [];
  mandatoryValid = true;
  detailsLoaded = false;
  hideEcommerce = false;
  onlyMandatory = false; // Only show mandatory items (not the main article)
  ts = 0;
  highlight: boolean;
  mainArticle: any;
  selectedArticles: any = {};
  volumePrices: any = {};
  disableAdding: boolean;
  showDealerOptions = !this.applicationService.isPartnerPortal();
  constructor(
    public modal: NgbActiveModal,
    public applicationService: ApplicationService,
    private alertService: AlertService,
    private basketService: BasketService,
    private translateService: TranslateService,
    private searchService: SearchService,
    private articleService: ArticleService,
    private preorderService: PreorderService,
    private helperService: HelperService,
    private accountService: AccountService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.applicationService.emitModalChange(true);
    this.getArticleDetails();
  }

  getArticleDetails() {
    const nettPrices = this.article.nett_prices;
    const hasDifferentPrices = this.article.has_different_prices;
    const service = this.totalQtyPreorderItems
      ? this.articleService.preorderArticleDetails(this.article.id)
      : this.searchService.articleDetails(
          this.article.id,
          this.article.single_article_code
        );
    this.loading = true;
    service.subscribe((response: ApiResponse) => {
      this.loading = false;
      if (this.helperService.checkResponse(response)) {
        let warehouse = undefined;
        if (this.article.warehouse) {
          warehouse = this.article.warehouse;
        }
        this.detailsLoaded = true;
        this.article = response.result;
        if (warehouse) {
          this.article.warehouse = warehouse;
        }
        this.article.nett_prices = nettPrices;

        if (this.article.article_codes.length > 1) {
          this.article.has_different_prices = hasDifferentPrices;
        }

        if (!this.article.net_price && nettPrices && nettPrices.length) {
          this.article.net_price = nettPrices[0].net_price;
        }

        this.parseDeliveryWeek();
        this.parseVolumePrices();

        if (this.article.mandatory_battery) {
          this.mandatoryValid = false;
        }

        // Calc initial price
        this.calcTotal();

        setTimeout(() => {
          this.setModalSize();
        }, 0);
      } else {
        this.alertService.showErrorNotification(
          response && response.result
            ? response.result
            : this.translateService.instant('ERROR')
        );
      }
    });
  }

  parseDeliveryWeek() {
    this.article.delivery_week = this.basketService.parseDeliveryWeek(
      this.article
    );
    this.parsedDeliveryWeek = this.article.delivery_week;
  }

  setModalSize() {
    const modalContainer = document.getElementsByClassName(
      'modal-' + this.article.id
    );
    if (modalContainer && modalContainer.length) {
      if (
        this.article.cross_sell_ecommerce ||
        this.article.mandatory_battery ||
        this.article.has_flex_batt
      ) {
        const modal = modalContainer[0].getElementsByClassName('modal-dialog');
        modal[0].classList.add('modal-lg');
        const mainArticleContainer = document.getElementById(
          'mainArticleContainer'
        );
        if (mainArticleContainer) {
          mainArticleContainer.classList.add('col-md-5');
        }
      }
    }
  }

  content() {}

  save() {
    this.article.quantity = this.quantity;
    this.loading = true;

    // Set properties of commerce articles to main article
    if (this.eCommerceArticles && this.eCommerceArticles.length) {
      this.eCommerceArticles.forEach((article: Article) => {
        article.remarks = this.article.remarks;
        article.sold = this.article.sold;
        article.shopType = this.article.shopType;
      });
    }

    const articleList = !this.onlyMandatory
      ? [this.article].concat(this.eCommerceArticles)
      : this.eCommerceArticles;

    // First check if the article is already in backorder (ANL) and if so let the user confirm
    this.basketService
      .getArticlesInBackorder(
        [this.article],
        this.applicationService.getSelectCompanyGroupCode()
      )
      .then((backorderArticles: Array<BackorderArticle>) => {
        const promise = new Promise((resolve) => {
          if (!backorderArticles.length) {
            resolve(true);
          } else {
            this.loading = false;
            const modalRef = this.modalService.open(ConfirmDialogComponent, {
              centered: true,
              size: 'sm',
              windowClass: 'confirmDialog',
            });
            modalRef.componentInstance.isConfirm = true;
            modalRef.componentInstance.title =
              this.translateService.instant('NOTE');
            modalRef.componentInstance.confirmText =
              this.translateService.instant('YES');
            modalRef.componentInstance.cancelText =
              this.translateService.instant('NO');
            modalRef.componentInstance.body = `${this.translateService.instant(
              'ARTICLES_IN_BACKORDER_ALERT'
            )}</br><br/>${this.translateService.instant('NUMBER')}: ${
              backorderArticles[0].quantity_in_backorder
            }.`;
            modalRef.componentInstance.buttonText = `${this.translateService.instant(
              'GOTO'
            )} ${this.translateService.instant('OPENORDERS').toLowerCase()}`;
            modalRef.componentInstance.footerText =
              this.translateService.instant('BACKORDER_ALERT_FOOTER');

            modalRef.componentInstance.buttonLink = `${this.applicationService.getSelectCompanyGroupCode()}/financial/openorders?articleCode=${
              this.article.single_article_code
            }`;
            modalRef.componentInstance.confirmClicked.subscribe(() => {
              resolve(true);
            });
          }
        });

        promise.then(() => {
          this.loading = true;
          this.basketService
            .addArticlesToBasket(articleList, this.basket)
            .then((response: ApiResponse) => {
              this.modal.dismiss();
              this.loading = false;
              if (response.success) {
                this.alertService.showInfoNotification(response.result);

                if (this.article.has_flex_batt) {
                  this.showBatteriesAndChargers();
                }
              } else {
                this.alertService.showDangerNotification(
                  this.translateService.instant(response.result)
                );
              }
            });
        });
      });
  }

  showBatteriesAndChargers() {
    this.articleService
      .getBatteriesAndChargers(this.article.single_article_code)
      .subscribe();
  }

  showInstant() {}

  articleCodeSelected(article: Article) {
    this.disableAdding = true;
    this.searchService
      .articleDetails(null, article.single_article_code)
      .subscribe((response: ApiResponse) => {
        this.disableAdding = false;
        if (response && response.success) {
          const articleDetails = response.result as Article;
          this.article.stock_week = articleDetails.stock_week;
          this.article.stock_year = articleDetails.stock_year;
          this.article.stock_date = articleDetails.stock_date;
          this.article.stock_status = articleDetails.stock_status;
          this.article.net_price = articleDetails.net_price;
          this.parseDeliveryWeek();
          this.calcTotal();
        }
      });
  }

  articleSelected(dat) {
    this.hideEcommerce = false;
    if (this.selectWeek) {
      this.selectWeek.renderWeeks();
    }
    this.parseDeliveryWeek();

    this.ts++;

    // Check batteries
    if (this.article.mandatory_battery) {
      this.mandatoryValid = this.checkMandatory();
    }

    this.calcTotal();
  }

  weekSelected(deliveryWeek) {
    this.article.delivery_week = deliveryWeek;
  }

  selectForBasket(article) {
    if (article) {
      this.eCommerceArticles = _.without(
        this.eCommerceArticles,
        _.findWhere(this.eCommerceArticles, {
          single_article_code: article.single_article_code,
        })
      );

      if (article.selected && article.quantity) {
        this.eCommerceArticles.push(article);
      }
    } else {
      // this.eCommerceArticles = Array<Article>();
    }
    this.quantityChanged();
  }

  checkMandatory() {
    let totalSelected = 0;
    this.eCommerceArticles.forEach((article: Article) => {
      if (article.is_mandatory_item) {
        totalSelected += article.quantity;
      }
    });
    return totalSelected === this.quantity;
  }

  quantityChanged() {
    if (isNaN(this.quantity as any) || !this.quantity) {
      this.quantity = this.article.min_order_amount
        ? this.article.min_order_amount
        : 1;
    }

    // Check when mandatory if enough selected
    if (this.article.mandatory_battery) {
      this.mandatoryValid = this.checkMandatory();
    } else {
      this.mandatoryValid = true;
    }
    this.calcTotal();
  }

  parseVolumePrices() {
    if (this.article.volume_price && this.article.volume_price.length) {
      const volumePrices = this.article.volume_price;
      let parsedPrices = {};
      for (const volumePrice of volumePrices) {
        parsedPrices[volumePrice['volume']] = (
          this.articleService.getVolumePrice(volumePrice) as Price
        ).value;
      }
      this.volumePrices = parsedPrices;
    }
  }

  calcTotal() {
    if (!this.sumNetPrice) {
      this.sumNetPrice = new Price();
    }
    if (!this.sumRetailPrice) {
      this.sumRetailPrice = new Price();
    }
    let netPrice = 0;
    const basketQty = this.quantity;

    if (this.volumePrices && Object.keys(this.volumePrices).length) {
      let totalNetPrice = 0;
      let quantityRemaining = basketQty;
      Object.keys(this.volumePrices)
        .sort()
        .reverse()
        .forEach((q) => {
          addToNettPrice(Number(q), this.volumePrices);
          const self = this;
          function addToNettPrice(volume, volumePrices) {
            if (quantityRemaining >= volume) {
              const volumeOccurences = Math.floor(quantityRemaining / volume);
              if (quantityRemaining / volume === volumeOccurences) {
                //volume is same as quantity remaining
                totalNetPrice += volume * volumePrices[volume];
                quantityRemaining -= volume;
              } else {
                //still some qty left
                totalNetPrice += volume * volumePrices[volume];

                quantityRemaining -= volume;
              }
              if (quantityRemaining >= volume) {
                addToNettPrice(volume, volumePrices);
              }
            }
          }
        });

      // Some volume left which is too small for a volume discount -> use normal price
      if (quantityRemaining > 0 && this.article.net_price?.value) {
        netPrice = this.article.net_price.value;
        totalNetPrice += netPrice * quantityRemaining;
      }

      this.sumNetPrice.value =
        totalNetPrice && !this.onlyMandatory ? totalNetPrice : 0;
    } else if (this.article.net_price) {
      netPrice = this.article.net_price.value;
      // Init total prices
      this.sumNetPrice.value =
        netPrice && !this.onlyMandatory ? netPrice * basketQty : 0;
      this.sumNetPrice.currency = this.article.net_price.currency;
      this.sumRetailPrice.currency = this.article.net_price.currency;
    }

    this.sumRetailPrice.value =
      this.article.retail_price && !this.onlyMandatory
        ? this.article.retail_price
          ? this.article.retail_price.value * basketQty
          : 0
        : 0;

    let lowestBatteryPrice = 0;
    let retailSumWithoutEcommerceArticle = this.sumRetailPrice.value;

    if (this.article?.batteries?.length) {
      for (const article of this.article?.batteries) {
        if (
          article.retail_price.value < lowestBatteryPrice ||
          lowestBatteryPrice === 0
        ) {
          lowestBatteryPrice = article.retail_price.value;
        }
      }
    }

    // Sum each e-commerce article price
    for (const article of this.eCommerceArticles) {
      if (article.net_price) {
        this.sumNetPrice.value += article.net_price.value * article.quantity;
      }
      if (article.retail_price) {
        retailSumWithoutEcommerceArticle +=
          article.retail_price.value * article.quantity;
      }
    }
    retailSumWithoutEcommerceArticle -= lowestBatteryPrice;
    this.sumRetailPrice.value = retailSumWithoutEcommerceArticle;
  }

  noEcommerceArticles(data) {
    this.hideEcommerce = true;
  }

  highlightMatrix(state: boolean) {
    if (!this.article.single_article_code) {
      this.highlight = state;
    }
  }

  getTotalPreorderQuantity() {
    let totalQty = 0;
    let parent = document.getElementsByTagName('app-ecommerce-articles')[0];
    let lists = parent.getElementsByTagName('app-article-weeks-table');
    for (let i = 0; i < lists.length; i++) {
      const preorderInputCollection = this.preorderService.collectInput(
        lists[i]
      );
      totalQty += preorderInputCollection.totalQuantity;
    }

    this.quantity = totalQty;
  }

  savePreorder() {
    this.applicationService.showLoader(true);
    const preorderInputCollectionMain = this.preorderService.collectInput(
      document.getElementById('mainPreorderArticle')
    );
    let promises = [];
    if (
      preorderInputCollectionMain &&
      preorderInputCollectionMain.articleList &&
      preorderInputCollectionMain.articleList.length
    ) {
      let preorderArticles = [];
      let parent = document.getElementsByTagName('app-ecommerce-articles')[0];
      let lists = parent.getElementsByTagName('app-article-weeks-table');
      let articleCodes = [];

      const deleteMainArticle = new Promise((resolve) => {
        this.basketService
          .deletePreorderModel(this.mainArticle[0].id, this.preOrderType)
          .subscribe((apiResponse: ApiResponse) => {
            resolve(true);
          });
      });

      deleteMainArticle.then(() => {
        // Collect articleCodes and delete article first (to prevent mixed up batteries)
        for (let article of preorderInputCollectionMain.articleList) {
          if (!articleCodes.includes(article.single_article_code)) {
            const promise = new Promise((resolve, reject) => {
              this.basketService
                .deletePreorderModelArticle(
                  article.single_article_code,
                  this.preOrderType
                )
                .subscribe((apiResponse: ApiResponse) => {
                  resolve(true);
                });
            });
            articleCodes.push(article.single_article_code);
            promises.push(promise);
          }
        }

        for (let i = 0; i < lists.length; i++) {
          const preorderInputCollection = this.preorderService.collectInput(
            lists[i]
          );
          preorderArticles = preorderArticles.concat(
            preorderInputCollection.articleList
          );
        }

        Promise.all(promises).then(() => {
          // Add to selected mandatory items to the preorder basket
          this.basketService
            .addArticlesToBasket(
              preorderInputCollectionMain.articleList.concat(preorderArticles),
              this.basket
            )
            .then((response: ApiResponse) => {
              this.applicationService.hideLoader();
              this.modal.dismiss();
              this.loading = false;
              if (response.success) {
                this.alertService.showInfoNotification(response.result);

                if (this.article.has_flex_batt) {
                  this.showBatteriesAndChargers();
                }
              } else {
                this.alertService.showDangerNotification(
                  this.translateService.instant(response.result)
                );
              }
            });
        });
      });
    }
  }
}
