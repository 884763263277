import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { SearchService } from '../services/search.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiResponse } from '../interfaces/api-response';
import { ArticleModalComponent } from '../components/modal-components/article-modal/article-modal.component';
import { ApplicationService } from '../services/application.service';

@Directive({
  selector: '[articleQuickView]',
  host: {
    '[style.position]': '"relative"',
  }
})
export class ArticleQuickViewDirective {
  @Input() articleQuickView: string;
  modalRef: NgbModalRef;
  initialHtml: string;
  constructor(private el: ElementRef, private searchService: SearchService, private ngbModal: NgbModal, private applicationService: ApplicationService) {
    this.el.nativeElement.classList.add("quickview")
    this.initialHtml = this.el.nativeElement.innerHTML;
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

  @HostListener('click', ['$event']) onClick($event){
    this.getArticle();
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.initialHtml) {
      this.initialHtml = this.el.nativeElement.innerHTML;
    }
    // this.el.nativeElement.insertAdjacentHTML('beforeend', '<div class="position-absolute" style="left: -10px; top: 0"><i class="material-icons">search</i></div>');
    this.highlight('underline');
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.el.nativeElement.innerHTML = this.initialHtml;
    this.highlight(null);
  }

  private highlight(td: string) {
    this.el.nativeElement.style.textDecoration = td;
  }

  getArticle() {
    this.applicationService.showLoader(true);
    this.searchService.searchInstant(this.articleQuickView).subscribe((apiResponse: ApiResponse) => {
      this.applicationService.hideLoader();
      if (apiResponse && apiResponse.success && apiResponse.result && apiResponse.result.length) {
      const article = apiResponse.result[0];
      this.modalRef = this.ngbModal.open(ArticleModalComponent, {
        size: "lg",
        windowClass: "sticky-right",
        backdrop: true,
        container: "#modalContainer"
      });
      this.modalRef.componentInstance.article = article;
      this.modalRef.componentInstance.modelId = article.id;
      this.modalRef.componentInstance.article.img = article.images;
      this.modalRef.componentInstance.showDetailed = false;
      this.modalRef.componentInstance.showInstant();
      }
    })
  }
}
