import { Component, OnInit, Input } from "@angular/core";
import { StoryblokService } from "src/app/services/storyblok.service";
import { StoryblokComponents } from "src/app/components/storyblok/storyblok-components";

@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"],
})
export class PageComponent implements OnInit {
  components = StoryblokComponents;

  constructor(private storyblok: StoryblokService) {}
  @Input() _uid: string;
  @Input() component: any;
  @Input() type: any;
  @Input() content: any[];
  @Input() _editable: any;
  @Input() html: boolean;

  ngOnInit() {
    let hasAnchor = window.location.href.includes("#")
    if (hasAnchor) {
      const anchor = window.location.href.split("#")[1];
      const element = document.getElementById(anchor);
      if (element) {
        element.scrollIntoView({ block: "center", inline: "nearest" });
      }
    }
  }

  renderHtml(richText) {
    if (typeof richText.content !== "undefined") {
      // Render plain html (old CMS layout)
      if (this.html) {
        return richText.content[0].text;
      } else {
        let html = this.storyblok.renderHtml(richText);
        return html;
      }
    } else {
      return "";
    }

  }
}
