import {
  Component,
  OnInit,
  asNativeElements,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { AvailableAgreementTypes } from "src/app/interfaces/yearAgreement/available-agreement-types";
import { YearAgreementService } from "src/app/services/year-agreement.service";
import { AccountAgreement } from "src/app/interfaces/yearAgreement/account-agreement";
import { ApiResponse } from "src/app/interfaces/api-response";
import { ApplicationService } from "src/app/services/application.service";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { AccountService } from "src/app/services/account.service";
import { YearAgreementBaseService } from "src/app/services/year-agreements/year-agreement-base.service";
import { YearAgreementType } from "src/app/interfaces/year-agreement-type";
import { Subscription } from "rxjs";
import { saveAs } from 'file-saver';

@Component({
  selector: "app-year-agreement-summary",
  templateUrl: "./year-agreement-summary.component.html",
  styleUrls: ["./year-agreement-summary.component.scss"],
})
export class YearAgreementSummaryComponent implements OnInit {
  @Input() yearAgreementModel: any;
  @Input() accountAgreement: AccountAgreement;
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();
  @Output() send: EventEmitter<any> = new EventEmitter<any>();
  @Input() id: number;
  @Input() ts: number;
  @Input() showRemarks: boolean = true;
  isHelpdesk: boolean;
  invalidTabs: string[];
  agreementSubmitted: boolean;
  pdfSrc: string;
  file: Blob;
  @Input() type: string = "bikes";
  private _subscription: Subscription;
  constructor(
    public yearAgreementService: YearAgreementService,
    public applicationService: ApplicationService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private accountService: AccountService,
    private yearAgreementBaseService: YearAgreementBaseService
  ) {}

  ngOnInit() {
    this.agreementSubmitted = false;
    this.isHelpdesk = this.accountService.isHelpdesk();
    this.getPdf();
    this._subscription = this.yearAgreementBaseService.changeEmitted$.subscribe(
      () => {
        this.getPdf();
      }
    );
  }

  ngOnDestroy() {
    if (typeof this._subscription !== "undefined") {
      this._subscription.unsubscribe();
    }
  }

  getPdf() {
    switch (this.type) {
      case "bikes":
        this.getPreviewPdf();
        break;
      case "parts":
        this.getPdfPreview(YearAgreementType.parts);
        break;
      case "dach":
        this.getPreviewPdfDach();
        break;
      case "ANL_Bikes_DK":
      case "ANL_Bikes_DE":
        this.getPdfPreview(this.type as YearAgreementType);
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["ts"] && this.ts && typeof this.ts !== "undefined") {
      this.getPdf();
    }
  }

  submit() {
    this.applicationService.showLoader(true);
    const invalidTabs = [];
    const selects = Array.from(
      document.getElementsByClassName("agreementSelect")
    );
    selects.forEach((select: HTMLInputElement) => {
      if (!select.value && !invalidTabs.includes(select.dataset["tab"])) {
        invalidTabs.push(select.dataset["tab"]);
      }
    });

    const service =
      this.type === "bikes"
        ? this.yearAgreementService.sendAgreement(this.accountAgreement.id)
        : this.yearAgreementBaseService.send(this.id);

    service.subscribe((apiResponse: ApiResponse) => {
      if (apiResponse.success) {
        this.send.emit(true);
        this.agreementSubmitted = true;

        if (this.type === "bikes") {
          // Set send date
          if (!this.accountAgreement.send) {
            this.accountAgreement.send = moment().format("YYYY-MM-DD HH:mm");
          }

          // Remove manual cm turnover
          localStorage.removeItem(
            `${this.accountAgreement.account_code}_cm_turnover`
          );
        }
      } else {
        this.alertService.showErrorNotification(
          this.translateService.instant("ERROR")
        );
      }
      setTimeout(() => {
        this.applicationService.hideLoader();
      }, 200);
    });
  }

  getPreviewPdf() {
    if (this.type === "bikes") {
      this.applicationService.showLoader(true);
      this.yearAgreementService
        .getAgreementPdf(this.accountAgreement.id)
        .subscribe((blob: Blob) => {
          this.applicationService.hideLoader();
          this.generateUrl(blob);
        });
    }
  }


  getPreviewPdfDach() {
    this.yearAgreementService
      .getAgreementPdfDach(this.id)
      .subscribe((blob: Blob) => {
        this.applicationService.hideLoader();
        this.generateUrl(blob);
      });
  }

  getPdfPreview(type: YearAgreementType) {
    this.yearAgreementBaseService
      .pdf(type, this.id.toString())
      .subscribe((blob: Blob) => {
        this.applicationService.hideLoader();
        this.generateUrl(blob);
      });
  }

  getPreviewPdfDe() {
    this.yearAgreementBaseService
      .pdf(YearAgreementType.dekoga, this.id.toString())
      .subscribe((blob: Blob) => {
        this.applicationService.hideLoader();
        this.generateUrl(blob);
      });
  }

  // Generate pdf
  generateUrl(blob) {
    this.file = new Blob([blob], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(this.file);
    this.pdfSrc = fileURL;
  }

  download() {
    let fileName = "";
    switch (this.type) {
      case "bikes":
        fileName = `agreement_${this.accountAgreement.account_code}_${this.accountAgreement.year}.pdf`;
        break;
      case "parts":
        fileName = `${this.translateService.instant(
          "YEAR_AGREEMENT"
        )}_juncker.pdf`;
        break;
      case "dach":
        fileName = `${this.translateService.instant(
          "YEAR_AGREEMENT"
        )}_dach.pdf`;
    }

    saveAs(this.file, fileName);
  }
  save() {
    this.saved.emit(true);
  }
}
