import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BasketArticle } from "src/app/interfaces/basketArticle";
import { ApiResponse } from "src/app/interfaces/api-response";
import { BasketAction } from "src/app/interfaces/basket-action";
import { BasketService } from "src/app/services/basket.service";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-edit-basket-article-modal",
  templateUrl: "./edit-basket-article-modal.component.html",
  styleUrls: ["./edit-basket-article-modal.component.scss"]
})
export class EditBasketArticleModalComponent implements OnInit {
  @Input() article: BasketArticle;
  @Input() lockedDeliveryWeeks: boolean;
  @Input() showShopDropdown: boolean;
  @Input() preorder: boolean;
  @Input() basketId: number;
  @Input() type: string;
  @Output() articleChanged: EventEmitter<BasketArticle> = new EventEmitter<
    BasketArticle
  >();
  companyGroup: string;
  constructor(private applicationService: ApplicationService, public modal: NgbActiveModal, private basketService: BasketService) {}

  ngOnInit() {
    console.log(this.lockedDeliveryWeeks)
    this.companyGroup = this.applicationService.getSelectCompanyGroupCode();
  }

  save() {
    this.articleChanged.emit(this.article);
  }


  deleteArticle() {
    const promise = new Promise((resolve, reject) => {
      if (this.basketId < 0) {
        resolve(this.article);
      } else {
        this.basketService
          .deleteArticle(this.basketId, this.article)
          .subscribe((response: ApiResponse) => {
            if (response.success) {
              resolve(this.article);
            }
          });
      }
    });
    // Trigger update (only from basketpreview)
    // if (this.previewItem) {
    promise.then(() => {
      const basketAction = new BasketAction();
      basketAction.type = "deleteArticle";
      basketAction.article = this.article;
      basketAction.basketId = this.basketId;
      basketAction.basketArticleId = this.article.id;
      this.basketService.emitChange(basketAction);
      this.modal.close();
    });
    //}
  }
}
