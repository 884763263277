<a class="navbar-brand d-flex align-items-center align-content-center">
  <a [routerLink]="'/' + selectedCompanyGroup + homepage">
    <app-accentry-logo
      [@enterAnimation]
      *ngIf="selectedCompanyGroup === 'ANL'"
    ></app-accentry-logo>
    <img
      [@enterAnimation]
      *ngIf="selectedCompanyGroup === 'GHO'"
      class="logo"
      src="./assets/images/Ghost-logo.png"
      alt="Ghost - Accentry"
      title="Ghost - Accentry"
    />
    <img
      [@enterAnimation]
      *ngIf="selectedCompanyGroup === 'VAR'"
      class="logo logo-svg"
      src="./assets/images/tunturi-accell-white.png"
      alt="Tunturi Sweden - Accentry"
      title="Tunturi Sweden - Accentry"
    />
    <img
      [@enterAnimation]
      *ngIf="selectedCompanyGroup === 'LAP'"
      class="logo"
      src="./assets/images/accell-sud.png"
      alt="Lapierre - Accentry"
      title="Accell Sud"
    />
    <img
      [@enterAnimation]
      *ngIf="selectedCompanyGroup === 'COM'"
      class="logo"
      src="./assets/images/Comet-logo-white.png"
      alt="Comet - Accentry"
      title="Comet - Accentry"
    />
    <img
      [@enterAnimation]
      *ngIf="selectedCompanyGroup === 'TUN'"
      class="logo logo-svg"
      src="./assets/images/Tunturi-logo.png"
      alt="Tunturi - Accentry"
      title="Tunturi - Accentry"
    />
    <img
      [@enterAnimation]
      *ngIf="
        selectedCompanyGroup === 'WG'
      "
      class="logo winora"
      style="height: 40px"
      src="./assets/images/Winora-logo.png"
      alt="Winora - Accentry"
      title="Winora - Accentry"
    />
    <img
      [@enterAnimation]
      *ngIf="selectedCompanyGroup === 'RAL'"
      class="logo logo-svg"
      src="./assets/images/raleigh.svg"
      alt="Raleigh"
      title="Raleigh"
      style="min-height: 30px"
    />
  </a>
  <span
    (click)="toggleCompanyDropdown(); t.close()"
    class="company-group-dropdown-button d-flex align-content-center ms-2"
    style="cursor: pointer"
    *ngIf="availableCompanyGroups.length > 1"
  >
    <span
      class="material-icons d-flex align-content-center align-self-center dropdown-icon"
      [ngbTooltip]="'SELECT_COMPANY' | translate"
      placement="bottom"
      #t="ngbTooltip"
    >
      arrow_drop_down
    </span>
  </span>
  <app-brand-selector [hidden]="!showCompanyDropdown" (brandClicked)="showCompanyDropdown = false"></app-brand-selector>
</a>
