<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container px-2">
  <div class="row mx-0">
    <div class="col-md-12 p-sm-4">
      <h1 class="mt-0"><app-header-icon iconName="speed"></app-header-icon> {{'QUICKORDER'|translate}}</h1>
      <p>{{'QUICKORDER_INFO1'|translate}}</p>
      <p>{{'QUICKORDER_INFO2'|translate}}</p>
      <br />
      <div class="row mx-md-0">

        <!-- Article input textarea -->
        <div class="col-md-2 col-xs-12 ps-md-0">
          <div>
            <label for="input">{{ "ARTICLE_NO" | translate }} / {{ "QUANTITY" | translate }}
            </label>
            <div *ngIf="isMobile" class="d-flex mb-1 align-items-center">
              <button class="btn btn-dark btn-sm btn-lg me-1 d-inline-flex align-items-center" id="addArticle"
                (click)="scanBarcode()">
                {{ "SCAN" | translate }}
                <app-bar-code-icon class="d-inline-flex ms-1" style="width: 32px"></app-bar-code-icon>
              </button>
            </div>
            <textarea placeholder="{{ 'FOR_EXAMPLE' | translate }}: 04507783 / 2" [(ngModel)]="input"
              class="form-control number inputItems customScrollbar"></textarea>
          </div>
          <div class="w-100 text-center">
            <span class="hint d-block"
              [ngClass]="getNumberOfLines() !== '0' ? 'show' : 'invisible'">{{getNumberOfLines()}}
              <span class="w-100">{{
                "LINES_ADD" |
                translate }}. {{
                "QUICKORDER_PRESS_CHECK" | translate }}</span></span>
            <button class="btn btn-primary btn-lg mt-2 mb-5 "
              [ngClass]="loading || getNumberOfLines() === '0' ? 'disabled' : ''"
              (click)="checkUserInput()">{{'CHECK'|translate}}</button>
          </div>
        </div>
        <!-- Processed articles -->
        <div class="col-md-10 col-xs-12 pe-md-0">
          <label for="selectedItems">

          </label>
          <div id="selectedItems" class="card quickOrderItems customScrollbar border">
            <table class="table">
              <thead *ngIf="quickOrderItems?.length">
                <tr>
                  <th class="bg-dark"></th>
                  <th class="bg-dark"></th>
                  <th class="bg-dark">{{'ARTICLE_NO'|translate}}</th>
                  <th class="bg-dark">{{'DESCRIPTION'|translate}}</th>
                  <th class="bg-dark"></th>
                  <th class="bg-dark">{{'QUANTITY'|translate}}</th>
                  <th class="bg-dark">{{'NETPRICE'|translate}}</th>
                  <th class="bg-dark">{{'AMOUNT'|translate}}</th>
                  <th class="bg-dark"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let article of quickOrderItems; let i = index">
                  <ng-container *ngIf="!article.unknown">
                    <td>
                      <input type="checkbox" [(ngModel)]="article.selected" (change)="calcTotal()">
                    </td>
                    <td (click)="openModal(article)">
                      <app-article-image [imageUrl]="article.item_details?.thumbs[0]" [height]="'30'"
                        [articleType]="article.item_details?.article_type" [useLazyLoad]="true"
                        [description]="article.item_details?.description"
                        [classList]="'img-thumbnail'"></app-article-image>
                    </td>
                    <td>
                      {{article.single_article_code}}
                    </td>
                    <td (click)="openModal(article)">
                      {{article.description}} - {{article.brand}}
                      <small class="d-block">{{article.caption}}</small>
                    </td>
                    <td class="text-center">
                      <app-stock-status class="col-2 mt-1" [showText]="false" *ngIf="
                      !article?.item_details?.setitem &&
                      !basketLocked &&
                      (!article?.warehouse || article?.item_details?.stock_status < 3)
                    " [article]="article?.item_details"></app-stock-status>
                    </td>
                    <td>
                      <p-inputNumber type="number" numberOnly [allowDecimals]="false" [(ngModel)]="article.amount"
                        [min]="1" [max]="999" numberOnly [allowDecimals]="false" [showButtons]="true"
                        (onInput)="checkQty(article);" (click)="
                      article.item_type === 'Bicycles' ? editPreorderQty() : undefined
                    " *ngIf="!isChild" [inputStyleClass]="'ps-2 pe-1'" />
                    </td>
                    <td class="number text-end">
                      <span class="col-6 ps-0 pe-3 pe-sm-0 text-end">
                        <app-nett-price *ngIf="article.net_price"  [showFrom]="article.below_volume_amount" [price]="article.calculated_net_price ?? article.net_price"
                          class="price d-inline-block"></app-nett-price>
                        <app-retail-price *ngIf="article.retail_price && showroomMode" [price]="article.retail_price"
                          [hideLabel]="true" class="price d-inline-block"></app-retail-price>

                        <div *ngIf="article.volume_price && !showroomMode" class="d-flex justify-content-end">
                          <ng-template #popContent> <app-volume-prices [headerLeft]="'QUANTITY'" [headerRight]="'PRICE'"
                              [popoverTitle]="'VOLUME_PRICES'" [price]="article.volume_price"
                              [regularPrice]="article.net_price"></app-volume-prices>
                          </ng-template>
                          <button type="button" class="btn btn-sm btn-outline-secondary d-block p-1 " [ngbPopover]="popContent"
                            [popoverTitle]="popTitle">
                            {{"VOLUME_PRICES"|translate}}
                          </button>
                        </div>
                      </span>
                    </td>
                    <td class="number text-end">
                      <span *ngIf="!showroomMode">
                        <span [innerHTML]="(article.sum) | currencyConvert: article.net_price?.currency"></span>
                      </span>
                      <span *ngIf="showroomMode">
                        <span [innerHTML]="(article.amount * article.retail_price?.value) | currencyConvert: retailPrice?.currency"></span>
                      </span>
                    </td>
                    <td class="border-end-0">
                      <div inputAddition class="col-md-1"><i
                          class="material-icons text-danger mt-1 clickable withOpacity" [quickorder]="true"
                          style="font-size: 1.3rem;" (click)="deleteItem(i)">delete_forever</i></div>
                    </td>
                  </ng-container>
                  <ng-container *ngIf="article.unknown">
                    <td></td>
                    <td>
                      {{article.single_article_code}}
                    </td>
                    <td colspan="7" class="text-danger border-end-0">
                      {{article.description}}
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="row mx-0">
            <div class="col-12 col-md-12 text-end h5 number" *ngIf="quickOrderItems.length">
              {{"TOTAL"|translate}}:
              <span *ngIf="!showroomMode" [innerHTML]="basketSumNet | currencyConvert: currency"></span>
              <span *ngIf="showroomMode" [innerHTML]="basketSumRetail | currencyConvert: currency"></span>
            </div>
            <!-- Basket controls -->
            <div class="col-12 col-md-6 ms-auto row px-3" [ngClass]="validQuickOrder ? '' : 'd-none'">
              <div class="col-md-8 pt-3">
                <label class="basketControls">{{ "BASKET" | translate }}</label>
                <app-select-basket [basket]="basketId" (basketSelected)="basketSelected($event)"
                  #selectBasket></app-select-basket>
              </div>
              <div class="col-md-4 pt-3 pe-0">
                <button class="btn btn-success  btn-lg mt-2" [disabled]="loading" [ngClass]="loading ? 'disabled' : ''"
                  (click)="
                  !selectBasket.baskets.length
                    ? createBasket(selectBasket)
                    : addToBasket()
                ">
                  {{ "ADD_TO_BASKET" | translate }}
                </button>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-end gap-2 ps-0 pe-1 pb-3"
              [ngClass]="validQuickOrder ? '' : 'd-none'">
              <button *ngIf="isShowButtons" class="btn btn-primary  btn-lg mt-2" [disabled]="loading"
                [ngClass]="loading ? 'disabled' : ''" (click)="goToBasket()">
                {{ "BASKET" | translate }} &raquo;
              </button>
              <button *ngIf="isShowButtons" class="btn btn-primary  btn-lg mt-2  mr-1" [disabled]="loading"
                [ngClass]="loading ? 'disabled' : ''" (click)="goToCheckout()">
                {{ "CHECKOUT" | translate }} &raquo;

              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
