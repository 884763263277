export interface OpenOrdersFilterOptions {
  keyWordOptions: any[];
  soldOptions: any[];
  articleKindOptions: any[];
}

export class OpenOrdersFilterOptions implements OpenOrdersFilterOptions {
  constructor(showAdvancedFilters: boolean) {
    this.keyWordOptions = [
      {
        value: "Description",
        description: "DESCRIPTION",
      },

      {
        value: "ArticleCode",
        description: "ARTICLE_NO",
      },
    ];

    if (showAdvancedFilters) {
      this.keyWordOptions.push({
        value: "Remarks",
        description: "REMARKS",
      });
      this.keyWordOptions.push({
        value: "Id",
        description: "ORDER_NUMBER",
      });
    } else {
      this.keyWordOptions.unshift({
        value: "OrderNumber",
        description: "ORDER_NUMBER",
      });
    }

    this.soldOptions = [
      {
        value: "all",
        description: "ALL",
      },
      {
        value: "true",
        description: "YES",
      },
      {
        value: "false",
        description: "NO",
      },
    ];

    this.articleKindOptions = [
      {
        value: "all",
        description: "ALL",
      },
      {
        value: "parts",
        description: "PARTS",
      },
      {
        value: "bicycles",
        description: "BICYCLES",
      },
    ];
  }
}
