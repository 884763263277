import { trigger, transition, style, animate } from "@angular/animations";
import { Component, OnInit, Input, ElementRef } from "@angular/core";
import { SpecificationGroup } from "src/app/interfaces/specificationGroup";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-article-specifications",
  templateUrl: "./article-specifications.component.html",
  styleUrls: ["./article-specifications.component.scss"],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('200ms ease-out',
                    style({  opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({  opacity: 1, "z-index": 0 }),
            animate('200ms ease-in',
                    style({opacity: 0 }))
          ]
        )
      ]
    )]
})
export class ArticleSpecificationsComponent implements OnInit {
  @Input() specifications: SpecificationGroup[] = [];
  @Input() companyId: number;
  @Input() showDetailed: boolean;
  @Input() specsCollapsed: boolean;
  kioskMode: boolean;
  hasSearched: boolean;
  hasBeenCollapsed: boolean;
  keyword: string = "";
  height: any;

  constructor(private applicationService: ApplicationService, private el: ElementRef) {}

  ngOnInit() {
    this.getKioskMode();
  }

  getKioskMode() {
    this.kioskMode = this.applicationService.getKioskMode(this.kioskMode);
  }

  splitStringIntoArrayByCommaOrStar(string) {
    let firstFilter = string.split(/,[\s]|[\s][*][\s]/);
    return firstFilter.map(function (d) {
      return d.replace("* ", "");
    });
  }

  matchesKeyword(spec: string) {
    if (spec.toLowerCase().includes(this.keyword.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  ngAfterContentChecked() {
    this.getSpecsContainerHeight();
  }

  getSpecsContainerHeight() {
    const specsContainer = this.el.nativeElement.querySelector('.specs-container');
    if (specsContainer) {
      const height = specsContainer.clientHeight;
      this.height = height;
    }
  }
}
