<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
    </button>
</div>

<div class="modal-body p-3">
  <div *ngIf="body" [innerHTML]="body"></div>
  <ng-container *ngIf="buttonText">
    <button class="btn btn-primary mt-2" (click)="gotoLink()">{{buttonText}}</button>
  </ng-container>
  <ng-template appModalContent></ng-template>
</div>
<div class="modal-footer justify-content-center">
  <ng-container *ngIf="footerText">
    <div class="col-12">
      <p>{{footerText}}</p>
    </div>
  </ng-container>
  <div class="d-flex col-md-12 p-0 justify-content-between w-100">
    <ng-container *ngIf="isConfirm">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{cancelText
        |
        translate}}</button>
      <button class="btn btn-success " (click)="confirm()">{{confirmText | translate}}</button>
    </ng-container>
    <ng-container *ngIf="!isConfirm">
      <button class="btn btn-dark " (click)="modal.dismiss('cancel click')">{{'CLOSE' | translate}}</button>
    </ng-container>
  </div>
</div>
