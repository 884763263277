import { Component, OnInit, Input } from "@angular/core";
import { BasketService } from "src/app/services/basket.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Basket } from "src/app/interfaces/basket";
import { TranslateService } from "@ngx-translate/core";
import { ApiResponse } from "src/app/interfaces/api-response";
import { BasketAction } from "src/app/interfaces/basket-action";
import { BasketArticle } from "src/app/interfaces/basketArticle";
import { PricelabelService } from "src/app/services/pricelabel.service";
import { AlertService } from "src/app/services/alert.service";
import { saveAs } from 'file-saver';
import { ApplicationService } from "src/app/services/application.service";
import { BarCodeScannerComponent } from "src/app/components/general-components/bar-code-scanner/bar-code-scanner.component";
import { BarcodeScanResult } from "src/app/interfaces/barcode-scan-result";
import { HelperService } from "src/app/services/helper.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { Router } from "@angular/router";

@Component({
  selector: "app-basket-option",
  templateUrl: "./basket-option.component.html",
  styleUrls: ["./basket-option.component.scss"]
})
export class BasketOptionComponent implements OnInit {
  @Input() basket: Basket;
  closed = true;
  isMobile: boolean;
  constructor(
    private applicationService: ApplicationService,
    private basketService: BasketService,
    private translateService: TranslateService,
    private pricelabelService: PricelabelService,
    public modal: NgbActiveModal,
    public modalService: NgbModal,
    public alertService: AlertService,
    private helperService: HelperService,
    private deviceDetector: DeviceDetectorService,
    private router: Router
  ) {}

  ngOnInit() {
    this.isMobile = this.deviceDetector.isMobile();
  }

  getPdfFile() {
    this.applicationService.showLoader(true);
    const basketName =
      this.translateService.instant("BASKET") + "_" + this.basket.name + ".pdf";
    this.basketService.getPdfFile(this.basket.id).subscribe((blob: Blob) => {
      this.applicationService.hideLoader();
      const file = new Blob([blob], { type: "application/pdf" });
      saveAs(file, basketName);
    });
    this.modal.close();
  }

  getOfferFile() {
    this.applicationService.showLoader(true);
    const offerName =
      this.translateService.instant("QUOTE_ORDER_TYPE") +
      "_" +
      this.basket.name +
      ".pdf";
    this.basketService.getOfferFile(this.basket.id).subscribe((blob: Blob) => {
      this.applicationService.hideLoader();
      const file = new Blob([blob], { type: "application/pdf" });
      saveAs(file, offerName);
    });
    this.modal.close();
  }

  getExcelFile() {
    this.applicationService.showLoader(true);
    const excelName =
      this.translateService.instant("BASKET") +
      "_" +
      this.basket.name +
      ".xlsx";
    this.basketService.getExcelFile(this.basket.id).subscribe((blob: Blob) => {
      this.applicationService.hideLoader();
      const file = new Blob([blob], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      saveAs(file, excelName);
    });
    this.modal.close();
  }

  emptyBasket() {
    this.applicationService.showLoader(true);
    this.basketService
      .emptyBasket(this.basket.id)
      .subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        const basketAction = new BasketAction();
        basketAction.type = "empty";
        basketAction.basketId = this.basket.id;
        this.basketService.emitChange(basketAction);
        this.modal.close();
      });
  }

  deleteBasket() {
    this.applicationService.showLoader(true);
    this.basketService
      .deleteBasket(this.basket.id)
      .subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        this.modal.close();
        this.alertService.showPopup(
          this.translateService.instant("DELETED"),
          "success"
        );

        this.router.navigateByUrl(`${this.applicationService.getSelectCompanyGroupCode()}/baskets`)
      });
  }

  addToPriceLabels() {
    this.applicationService.showLoader(true);
    const checkedArticles = [];
    this.basket.items.forEach((item: BasketArticle) => {
      if (item.checkout) {
        checkedArticles.push(item.article_code);
      }
    });

    if (checkedArticles.length) {
      this.pricelabelService.addArticles(checkedArticles).subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        if (apiResponse.success) {
          this.alertService.showInfoNotification(
            this.translateService.instant("PRICELABELS_SUCCESS_MESSAGE")
          );
        }
      });
    }
  }

  scanBarcode() {
    const modalRef = this.modalService.open(BarCodeScannerComponent, {
      centered: true,
      container: "#modalContainer",
      backdrop: "static",
    });
    modalRef.componentInstance.autoClose = false;
    modalRef.componentInstance.articleSelected$.subscribe(
      (addData: BarcodeScanResult) => {
        if (addData.article_code) {
          addData.article_data.quantity = addData.quantity;
          this.basketService.addArticlesToBasket([addData.article_data], this.basket.id).then((basketResponse: ApiResponse) => {
            if (this.helperService.checkResponse(basketResponse)) {
              modalRef.componentInstance.qty = 1;
              this.alertService.showInfoNotification(
                this.translateService.instant("ARTICLE_ADDED")
              );
            } else {
              this.alertService.showInfoNotification(
                basketResponse.result ? basketResponse.result : this.translateService.instant("ERROR")
              );
            }
          })
        }
      }
    );
    modalRef.closed.subscribe(() => {
      modalRef.componentInstance.barcodeScanner.stop();
    });
  }
}
