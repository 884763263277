<ng-container>
  <div class="d-flex align-items-end" style="height: 170px">
    <nav
      class="navbar navbar-light bg-light px-0 w-100  border-top"
      [ngClass]="showFixedOptions ? 'fixed-bottom' : 'static-bottom'"
    >
      <div class="container px-3">
        <div class="row mx-0 w-100">
          <div class="col-md-12 px-0 row mx-0 mb-4">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </nav>
  </div>
</ng-container>
