<nav
  ngbNav
  #nav="ngbNav"
  [activeId]="type"
  (navChange)="tabChange($event)"
  class="nav-pills"
>
  <ng-container [ngbNavItem]="'open'">
    <a ngbNavLink>
      {{ "BASKETS" | translate }}
    </a>
  </ng-container>
  <ng-container [ngbNavItem]="'sent'">
    <a ngbNavLink>
      {{ "SENT_ORDERS" | translate }}
    </a>
  </ng-container>
  <ng-container [ngbNavItem]="'archive'">
    <a ngbNavLink>
      {{ "DELETED_BASKETS" | translate }}
    </a>
  </ng-container>
</nav>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
