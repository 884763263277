
<div class="mt-3">
  <app-warranty-framenumber-input [formGroup]="formGroup" (inputClicked)="showBicycleFinder()"
    *ngIf="tabSelected === 'bike_part' || tabSelected === 'lease_bike_part' || tabSelected === 'REC'  || tabSelected === 'battery' || tabSelected === 'REC_BATT' || tabSelected === 'lease_service'">
  </app-warranty-framenumber-input>
  <ng-content></ng-content>

  <div [style.display]=" ((tabSelected !== 'bike_part' && tabSelected !== 'lease_bike_part' && tabSelected !== 'lease_service') || formGroup.get('framenumber').value) && tabSelected !== 'REC_BATT' ? '' : 'none'">
    <div class="mb-3 row" id="articleCodeContainer" *ngIf="bicycle || tabSelected === 'separate_part' || tabSelected === 'battery' || (tabSelected === 'bike_part' && bicycleUnknown) || formGroup.get('articleCode').value">
      <label for="articleCode" class="col-md-4 control-label text-start">{{'ARTICLE_NO'|translate}} *</label>
      <div class="col-8 col-sm-4" [formGroup]="formGroup">
        <input type="text" readonly class="bg-white form-control articleCode" name="articleCode" formControlName="articleCode"
          (click)="(tabSelected === 'bike_part' || tabSelected === 'lease_bike_part' || tabSelected === 'lease_service') && bicycle  ? showBom() : (tabSelected === 'separate_part' || tabSelected === 'bike_part' || tabSelected === 'battery' || tabSelected === 'REC'  ? showArticleFinder() : showPackingSlipFinder())">
      </div>
      <div class="col-4 col-sm-4 px-0" *ngIf="tabSelected !== 'REC_BATT' && bicycle">
        <button
          (click)="(tabSelected === 'bike_part' || tabSelected === 'lease_bike_part'  || tabSelected === 'lease_service' || tabSelected === 'battery' || tabSelected === 'REC') && bicycle ? showBom() : (tabSelected === 'separate_part' || tabSelected === 'bike_part' || tabSelected === 'lease_bike_part'   || tabSelected === 'lease_service' ? showArticleFinder() : showPackingSlipFinder())"
          class="btn btn-dark search">
          <i class="material-icons me-2" style="font-size: 14px;">pedal_bike</i>
          <ng-container *ngIf="bicycle || tabSelected === 'lease_bike_part'">{{(tabSelected === 'lease_service' ? "SERVICE_LIST" : "BOM")|translate}} </ng-container>
        </button>
        <button
          *ngIf="tabSelected === 'REC'"
          (click)="showArticleFinder()"
          class="btn btn-dark search ms-1">
          <i class="material-icons me-2">search</i>
          {{"SEARCH"|translate}}
        </button>
      </div>
    </div>
  </div>
  <ng-content select=".ticketNumber"></ng-content>


  <div class="mb-3 row">
    <label for="name" class="col-md-4 control-label text-start">
      {{'EMPLOYEE'|translate}}
      *</label>
    <div class="col-md-4" [formGroup]="formGroup"> <input type="text" formControlName="employeeName"
        class="form-control employeeName" name="employeeName"> </div>
  </div>
</div>
