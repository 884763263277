export interface OktaRegisterInput {
  firstname: string;
  lastname: string;
  username: string;
}

export class OktaRegisterInput implements OktaRegisterInput {
  constructor (firstName: string, lastName: string, username: string) {
    this.firstname = firstName;
    this.lastname = lastName;
    this.username = username;
  }
}
