import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { WarrantyArticle } from "../interfaces/warranty/warranty-article";
import { GroupedWarrantyArticles } from "../interfaces/warranty/grouped-warranty-articles";
import * as _ from "underscore";
import { WarrantyDetails } from "../interfaces/warranty/warranty-details";
import { Article } from "../interfaces/article";
import { WarrantyFilterInput } from "../interfaces/warranty/warranty-filter-input";
import { Observable, Subject } from "rxjs";
import { AddWarrantyArticleRequest } from "../interfaces/warranty/add-warranty-article-request";
import { SendWarrantyRequest } from "../interfaces/warranty/send-warranty-request";
import { WarrantyFormRequestInput } from "../interfaces/warranty/warranty-form-request-input";
import { CheckLeaseArticleInput } from "../interfaces/warranty/check-lease-article-input";
import { CrmFormInput } from "../interfaces/crm/crmFormInput";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class WarrantyService {
  public partSelect = new Subject<Article>();

  constructor(private http: HttpClient, private helperService: HelperService) {}

  public getOpenWarrantyList() {
    return this.http.get(environment.apiendpoint + "warranties");
  }

  public getWarrantyDetails(id: number) {
    return this.http.get(`${environment.apiendpoint}warranties/${id}`);
  }

  public filterArticles(
    articles: WarrantyArticle[],
    filterDisposedArticles: boolean
  ) {
    const groupedArticles = new GroupedWarrantyArticles();
    groupedArticles.lease = articles.filter((article) => {
      return (
        article.flow_type === "lease" &&
        article.article_type.startsWith("regular")
      );
    });
    groupedArticles.regular = articles.filter((article) => {
      return (
        article.flow_type !== "lease" &&
        article.article_type === "regular" &&
        ((article.warranty_path_code !== "E" && filterDisposedArticles) ||
          !filterDisposedArticles)
      );
    });
    groupedArticles.regular_dispose = articles.filter((article) => {
      return (
        article.flow_type !== "lease" &&
        article.article_type === "regular" &&
        ((filterDisposedArticles && article.warranty_path_code === "E") ||
          !filterDisposedArticles)
      );
    });
    groupedArticles.batteries = articles.filter((article) => {
      return article.article_type.endsWith("battery");
    });
    groupedArticles.motors = articles.filter((article) => {
      return (
        !article.article_type.endsWith("battery") &&
        !article.article_type.startsWith("regular")
      );
    });
    return groupedArticles;
  }

  public deleteArticle(warrantyId: number, warrantyArticleId: number) {
    return this.http.delete(
      `${environment.apiendpoint}warranties/${warrantyId}/${warrantyArticleId}`
    );
  }

  public updateReference(warrantyDetails: WarrantyDetails) {
    return this.http.put(
      `${environment.apiendpoint}warranties/${warrantyDetails.warranty_id}/updatereference`,
      {
        reference: warrantyDetails.remarks,
      }
    );
  }

  public getLabel(type: string, id: number) {
    return this.http.get(
      `${environment.apiendpoint}warranties/label/${type}/${id}/0?fileType=pdf`
    );
  }

  public getArticleLabel(type: string, id: number, warrantyArticleId: number) {
    return this.http.get(
      `${environment.apiendpoint}warranties/label/${type}/${id}/${warrantyArticleId}?fileType=pdf`
    );
  }

  partSelected(article: Article) {
    this.partSelect.next(article);
  }

  public getBicycleGuaranteeDate(framenumber: string) {
    if (framenumber) {
      framenumber = String(framenumber).toUpperCase();
    }
    return this.http.get(
      `${environment.apiendpoint}warranties/enddate/${framenumber}`
    );
  }

  public uploadFile(warrantyId: number, fileData: string) {
    return this.http.post(
      `${environment.apiendpoint}warranties/${warrantyId}/uploadfile`,
      {
        file_data: fileData,
      }
    );
  }

  public addArticle(
    warrantyId: number,
    warrantyArticle: AddWarrantyArticleRequest
  ) {
    return this.http.post(
      `${environment.apiendpoint}warranties/${warrantyId}/addarticle`,
      warrantyArticle
    );
  }

  public updateArticle(
    warrantyId: number,
    warrantyArticleId: number,
    warrantyArticle: AddWarrantyArticleRequest
  ) {
    return this.http.put(
      `${environment.apiendpoint}warranties/${warrantyId}/${warrantyArticleId}/updatearticle`,
      warrantyArticle
    );
  }

  // Check if there is warranty for an article (A/B means compensation - else (C) warranty via manufacturer)
  public checkArticleGuarantee(
    articleCode: string,
    purchaseDate: string,
    returnCondition: string
  ) {
    return this.http.get(
      `${environment.apiendpoint}warranties/checkwarranty/${articleCode}/${purchaseDate}/${returnCondition}`
    );
  }

  public checkArticleInDealerHistory(articleCode: string) {
    return this.http.get(
      `${environment.apiendpoint}warranties/checkdealerhistory/${articleCode}`
    );
  }

  // Download an attachment
  public downloadFile(warrantyId: number, fileName: string): Observable<any> {
    const fileType = this.helperService.getFileType(fileName);

    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: fileType,
      }),
      responseType: 'blob' as 'json',
    };

    return this.http.get(
      `${environment.apiendpoint}warranties/downloadattachment/${warrantyId}/${fileName}?fileType=pdf`,
      HTTPOptions
    );
  }

  public submit(sendWarrantyRequest: SendWarrantyRequest) {
    return this.http.put(
      `${environment.apiendpoint}warranties/${sendWarrantyRequest.id}/submit`,
      sendWarrantyRequest
    );
  }

  public submitWarrantyForm(
    warrantyFormRequestInput: WarrantyFormRequestInput
  ) {
    return this.http.post(
      `${environment.apiendpoint}warranties/sendform`,
      warrantyFormRequestInput
    );
  }

  // Get the lease BOM
  public getArticleBom(article) {
    return this.http.get(
      `${environment.apiendpoint}warranties/${article.frame_number}/lease/groups`
    );
  }

  // Get articles of the Lease BOM group
  public getBomArticles(group, article) {
    return this.http.get(
      `${environment.apiendpoint}warranties/${article.frame_number}/lease/groups/${group}`
    );
  }

  // Check if article can be claimed
  public checkLeaseWarrantyArticle(input: CheckLeaseArticleInput) {
    return this.http.post(
      `${environment.apiendpoint}warranties/lease/check/`,
      input
    );
  }

  /* CRM */
  public settings() {
    return this.http.get(`${environment.apiendpoint}warranties/crm/settings`);
  }

  public search(warrantyFilterInput: WarrantyFilterInput) {
    return this.http.post(
      `${environment.apiendpoint}warranties/crm/search`,
      warrantyFilterInput
    );
  }

  public saveCrmWarranty(crmFormInput: CrmFormInput) {
    return this.http.post(
      `${environment.apiendpoint}warranties/crm/`,
      crmFormInput
    );
  }

  public uploadCrmFile(fileName: string, fileData: string) {
    return this.http.post(
      `${environment.apiendpoint}warranties/crm/uploadfile`,
      {
        file_name: fileName,
        file_data: fileData,
      }
    );
  }

  checkKogaRecall(framenumber: string) {
    return this.http.get(
      `https://public-gateway.accentry.com/recall/koga/${framenumber}`
    );
  }

  checkLapierreRecall(framenumber: string) {
    return this.http.get(
      `${environment.publicapiendpoint}recall/lapierre/${framenumber}`
    );
  }

  getLapierre2024ModelYears() {
    return this.http.get(
      `${environment.publicapiendpoint}recall/lapierre/modelyears`
    );
  }

  getLapierre2024Models() {
    return this.http.get(
      `${environment.publicapiendpoint}recall/lapierre/models`
    );
  }
}
