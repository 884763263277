<div id="sidebarContainer" class="position-relative pt-3">
  <div class="row pb-4 pt-1"  (clickOutside)="collapseFilters()">
    <div class="col-12">
      <app-side-bar #sb style="width: 100%" [priceRangeData]="priceRangeData" [stockweekData]="stockweekData"
        [groups]="groups" [availableFilters]="filters" [showStockFilter]="showStockFilter" [selectedFilterList]="selectedFilterList" [globalFilterKeyword]="keyword"
        [showControls]="false" [sidebarTop]="0" [ts]="ts" (filtersUpdated)="filterUpdate($event)">
        <input type="search" class="form-control mt-2" [(ngModel)]="keyword" [placeholder]="'KEYWORD'|translate" (keyup)="checkKeyword()">
      </app-side-bar>
    </div>
  </div>
</div>
