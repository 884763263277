<app-bread-crumbs *ngIf="!hideHeader" [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container pb-5">
  <div class="row">
    <div class="col-md-12" [ngClass]="!hideHeader ? 'px-3' : 'px-0'">
      <h1 *ngIf="!hideHeader"><app-header-icon iconName="shopping_basket"></app-header-icon>{{ "BASKETS" | translate }}</h1>
      <app-select-basket-type
        *ngIf="!hideHeader"
        [type]="type"
        (tabChanged)="tabChanged($event)"
      ></app-select-basket-type>
      <ul class="ps-0 list-group list-group-flush bg-transparent" *ngIf="baskets && baskets.length" [@pageAnimations]>
        <li *ngFor="let basket of baskets" class="px-0 list-group-item px-md-2 basket-item mb-2 rounded">
          <app-basket-item
            [basket]="basket"
            (basketDeleted)="basketDeleted($event)"
            [preorder]="basket.pre_order"
            [style.order]="basket.pre_order ? '1' : 'initial'"
            [deleted]="type === 'archive'"
            [basePage]="basePage"
          ></app-basket-item>
        </li>
      </ul>
      <ngb-alert
        class="mt-2"
        *ngIf="loaded && baskets && !baskets.length"
        [type]="'warning'"
        [dismissible]="false"
      >
        {{ "NO_RESULTS" | translate }}
      </ngb-alert>
    </div>
  </div>
</div>
