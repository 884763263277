import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-unchecked-articles-notice',
  templateUrl: './unchecked-articles-notice.component.html',
  styleUrls: ['./unchecked-articles-notice.component.css']
})
export class UncheckedArticlesNoticeComponent implements OnInit {
  @Input() type: string;
  noticeText: string;
  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
    this.noticeText = this.translateService.instant("ARTICLE_TYPE_BLOCKED").replace("[type]", this.translateService.instant(this.type));
  }

}
