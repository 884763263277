import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, Validators } from "@angular/forms";
import { Article } from "src/app/interfaces/article";
import { WarrantyPart } from "src/app/interfaces/warranty/warranty-part";
import { WarrantyArticle } from "src/app/interfaces/warranty/warranty-article";

@Component({
  selector: "app-warranty-guarantee",
  templateUrl: "./warranty-guarantee.component.html",
  styleUrls: ["./warranty-guarantee.component.scss"]
})
export class WarrantyGuaranteeComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() bicycle: Article;
  @Input() warrantyId: number;
  @Input() hasLease: boolean;
  @Input() currentArticle: WarrantyArticle;
  @Output() bicycleSelect: EventEmitter<Article> = new EventEmitter<Article>();
  @Output() partSelect: EventEmitter<Article> = new EventEmitter<Article>();
  @Output() typeChange: EventEmitter<number> = new EventEmitter<number>();
  tabSelected = "";
  bicycleUnknown = false;
  companyId: string;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    this.formGroup.get("batteryType").clearValidators();
    this.formGroup.get("batteryType").updateValueAndValidity();
    if (this.currentArticle) {
      if (this.currentArticle.frame_number) {
        if (this.currentArticle.article_type && this.currentArticle.article_type.indexOf("battery") >= 0 || this.currentArticle.article_type === "motor") {
          this.tabSelected = "battery";
        } else {
          this.tabSelected = "bike_part";
        }
        this.formGroup
          .get("framenumber")
          .setValue(this.currentArticle.frame_number);
      } else {
        this.tabSelected = "separate_part";
      }
    }
    this.formGroup.get("guaranteeDate").enable();
    this.formGroup.get("returnCondition").setValidators(Validators.required);
    this.formGroup.get("returnCondition").updateValueAndValidity();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.formGroup.get("returnCondition").clearValidators();
    this.formGroup.get("returnCondition").updateValueAndValidity();
  }

  ngOnChanges(changes: SimpleChanges) {}

  typeChanged(type: string) {
    this.tabSelected = type;
    this.typeChange.emit(8);
    this.formGroup.get("framenumber").setValue("");
    this.formGroup.get("articleCode").enable();

    if (this.tabSelected !== "battery") {
      this.formGroup.get("batteryType").setValue("");
      this.formGroup.get("batteryType").disable();
      this.formGroup.get("batteryType").updateValueAndValidity();
    } else {
      this.formGroup.get("articleCode").setValue("");
      this.formGroup.get("batteryType").setValue("");
      this.formGroup.get("batteryType").enable();
      this.formGroup.get("batteryType").updateValueAndValidity();
    }
  }

  bicycleSelected(bicycle: Article) {
    setTimeout(() => {
      this.bicycleUnknown = bicycle ? false : true;
      this.bicycleSelect.next(bicycle);
    }, 0);
  }

  partSelected(data: WarrantyPart) {
    this.companyId = data.article.company_id;
    setTimeout(() => {
      this.partSelect.next(data.article);
    }, 0);
  }
}
