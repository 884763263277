import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { BasketService } from 'src/app/services/basket.service';
import { BasketArticle } from 'src/app/interfaces/basketArticle';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateService } from '@ngx-translate/core';
import { Breadcrumb } from 'src/app/interfaces/bread-crumb';
import { GroupedBasketArticles } from 'src/app/interfaces/grouped-basket-articles';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { Basket } from 'src/app/interfaces/basket';
import { EditBasketNameComponent } from '../edit-basket-name/edit-basket-name.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AvailableArticleTypes } from 'src/app/interfaces/article-type';
import { BasketOptionComponent } from './basket-option/basket-option.component';
import { Subscription, timer } from 'rxjs';
import { GroupedBasketArticlesByMonth } from 'src/app/interfaces/grouped-basket-articles-by-month';
import { EditPreorderBasketComponent } from '../preorder-basket/edit-preorder-basket/edit-preorder-basket.component';
import * as _ from 'underscore';
import { BasketArticles } from 'src/app/interfaces/basket-articles';
import { ShippingCostOverviewComponent } from '../shipping-cost-overview/shipping-cost-overview.component';
import { ShippingCostDetails } from 'src/app/interfaces/shipping-cost-details';
import { ConfirmDialogComponent } from '../../modal-components/confirm-dialog/confirm-dialog.component';
import { AccountService } from 'src/app/services/account.service';
import { AccountInformation } from 'src/app/interfaces/account-information';
import { ShippingAddressGroup } from 'src/app/interfaces/shipping-address-group';
import { AlertService } from 'src/app/services/alert.service';
import { PreorderSettings } from 'src/app/interfaces/settings/preorder-settings';
import { WishlistModalComponent } from '../../preorder-components/wishlist-modal/wishlist-modal.component';
import { Price } from 'src/app/interfaces/price';
import { BasketSumByBrand } from 'src/app/interfaces/basket/basket-sum-by-brand';
import { BackorderAlerterComponent } from './backorder-alerter/backorder-alerter.component';
import { PreorderService } from 'src/app/services/preorder.service';
import { FinancialService } from 'src/app/services/financial.service';
import { HelperService } from 'src/app/services/helper.service';
import { BackorderArticle } from 'src/app/interfaces/backorder-article';
import { DashboardService } from 'src/app/services/dashboard.service';
import { BasketType } from 'src/app/interfaces/basket-type';
import { ShippingAddress } from 'src/app/interfaces/shipping-address';
import { CompanyGroup } from 'src/app/interfaces/company-group';


@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss'],
})
export class BasketComponent implements OnInit {
  @ViewChild('sticky', { static: false }) elementToMeasureRef: ElementRef;
  @Input() isPreorderBasket: boolean;
  @Input() type: string;

  private _subscription: Subscription;
  basket: Basket;
  basketId = null;
  groupedArticles: GroupedBasketArticles;
  groupedArticlesByMonth: GroupedBasketArticlesByMonth;
  groupedArticlesByDirectDelivery: BasketArticles;
  breadcrumbs: Breadcrumb[];
  individualPreorderCheckout: boolean; //WG - DACH
  visibleArticleType = '';
  basketSum: any = {
    parts: 0,
    bicycles: 0,
    total: 0,
  };
  basketSumMonths: any = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
  };
  showroomMode = false;
  loaded = false;
  companyGroupCode = '';
  totalMonths: number[];
  validPreorderSelection: number;
  basketSumDirect: {};
  optionsClass: string;
  showFixedOptions: boolean;
  shippingAddressGroup: ShippingAddressGroup;
  shippingAddressGroupParts: ShippingAddressGroup;
  shippingAddressId: number;
  preorderSettings: PreorderSettings;
  shipping_minimum_order_amount: Price;
  preorderCMSPageIds = {
    ANL: '',
    WG: '960422052',
    GHO: '960422053',
  };
  basketArticlesByBrand: BasketSumByBrand[];
  showArticleCheckbox: boolean;
  gotoCheckout: boolean;
  preorderType: string = 'Bikes';
  disableCheckout: boolean;
  selectedForBackorder = false;
  showDealerOptions = !this.applicationService.isPartnerPortal();
  referenceDisabled = false;
  shippingBlankOptions: boolean;
  compactMode: boolean;
  stickyWidth: number;
  fixedNavBar = true;
  checkingOut: boolean = false;
  lockedWeeksParts: any;
  lockedWeeksBikes: any;
  backorderXhr: Subscription;

  get preorderBasketExplanation(): string {
    const groupedArticles = document.getElementsByClassName('preorderGroup');
    return `${String(groupedArticles.length)} ${this.translateService.instant(
      'PREORDER_BASKET_OPEN_NOTICE'
    )}`;
  }

  // Template variables to encapsulate complex conditions
  get hasShippingAddresses() {
    return (
      (this.shippingAddressGroup && this.shippingAddressGroup.shipping_addresses?.length) ||
      (this.shippingAddressGroupParts && this.shippingAddressGroupParts.shipping_addresses?.length) &&
      (this.groupedArticles?.parts?.items?.length || this.groupedArticles?.bicycles?.items?.length)
    );
  }

  get showBicycleLabel() {
    return (
      this.shippingAddressGroupParts &&
      this.groupedArticles?.parts?.items?.length &&
      this.groupedArticles?.bicycles?.items?.length
    );
  }

  constructor(
    private basketService: BasketService,
    private router: Router,
    public applicationService: ApplicationService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private alertService: AlertService,
    private accountService: AccountService,
    private preorderService: PreorderService,
    private financialService: FinancialService,
    private helperService: HelperService,
    private dashboardService: DashboardService
  ) {
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
    this.groupedArticles = new GroupedBasketArticles();
    this.getShowroomMode();
    this.totalMonths = this.applicationService.getMonths();
    this.referenceDisabled = this.applicationService.isPartnerPortal();
    this.shippingBlankOptions = this.applicationService.isPartnerPortal();
    this.compactMode = this.applicationService.isPartnerPortal();
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );

    this.fixedNavBar =
      localStorage.getItem('navbar-not-fixed') === '1' ? false : true;
  }

  getCompanyGroupSettings() {
    const companyGroupSettings = this.applicationService.getSettingsForCompanyGroup(
      this.applicationService.getSelectCompanyGroupCode()
    ) as CompanyGroup;
    this.lockedWeeksParts = companyGroupSettings.locked_delivery_week_parts || companyGroupSettings.locked_delivery_week_all;
    this.lockedWeeksBikes = companyGroupSettings.locked_delivery_week_bikes || companyGroupSettings.locked_delivery_week_all;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.basketId = params['id'];
      }

      // From basket preview -> checkout button -> redirect
      if (window.location.href.includes('checkout')) {
        this.applicationService.showLoader(true);
        this.gotoCheckout = true;
      }

      // Check if preorder basket
      if (window.location.href.includes('preorder')) {
        this.isPreorderBasket = true;

        if (params['type']) {
          this.preorderType = params['type'];
        }
      }
      this.getBasket().then(() =>{
        this.getShippingCostMinimumOrderAmount().then(() => {
          if (this.gotoCheckout) {
            this.applicationService.showLoader(true);
            this.checkout(this.basketId);
          }
        });
      })
    });

    this._subscription = this.basketService.changeEmitted$.subscribe(
      (response) => {
        if (this.basket) {
          if (this.isPreorderBasket) {
            switch (response['type']) {
              case 'addArticle':
              case 'deleteArticle':
              case 'deletePreOrderArticle':
              this.getBasket();
              break;

              case 'updateCheckoutStatusArticle':
                this.parseBasket();
                break;

            }
          } else {
            switch (response['type']) {
              case 'empty':
                if (response['basketId'] === this.basket.id) {
                  this.getBasket();
                }
                break;

              case 'addArticle':
                if (response['basketId'] === this.basket.id) {
                  this.getBasket();
                }
                break;

              case 'deleteArticle':
                if (response['basketId'] === this.basket.id) {
                  if (response['article']) {
                    const article: BasketArticle = response['article'];
                    if (article.has_mandatory_item) {
                      this.getBasket();
                    } else {
                      this.deleteArticle(response['basketArticleId']);
                    }
                  }
                }
                break;

              case 'updateArticle':
                if (response['basketId'] === this.basket.id) {
                  this.updateArticle(response['article']);
                  this.parseBasket();
                }
                break;


            case 'updateCheckoutStatusArticle':
              if (response['basketId'] === this.basket.id) {

                this.loaded = false;

                timer(500).subscribe(() => {
                  this.loaded = true;
                })

                this.updateArticle(response['article']);
                this.parseBasket();
              }
              break;

            case 'refresh':
              if (response['basketId'] === this.basket.id) {
                this.getBasket();
              }
              break;

              case 'unlocked':
                if (this.basket && this.basket.locked) {
                  this.basket.locked = false;
                }
                break;
            }
            this.checkBackorderSelection();
          }
        }
      }
    );

    this.applicationService.changeEmitted$.subscribe((response) => {
      this.getShowroomMode();
      if (this.basket) {
        this.calcTotal(this.basket.items);
      }
    });
  }

  getTotalWidth() {
    setTimeout(() => {
      const stickyElement = document.getElementById('sticky');
      if (stickyElement) {
        // Get the width of the element after it's been rendered.
        this.stickyWidth = stickyElement.offsetWidth;
      }
    }, 0);
  }

  changeReference() {
    this.basketService
      .updateBasket(this.basket)
      .subscribe((apiResponse: ApiResponse) => {
        if (apiResponse.success) {
          this.alertService.showInfoNotification(
            this.translateService.instant('SAVED')
          );
        } else {
          this.alertService.showErrorNotification(
            this.translateService.instant('ERROR')
          );
        }
      });
  }

  ngOnDestroy() {
    if (typeof this._subscription !== 'undefined') {
      this._subscription.unsubscribe();
    }
    this.toggleBasketPreviewButton(false);

    if (typeof this.backorderXhr !== "undefined") {
      this.backorderXhr.unsubscribe();
    }
  }

  getPreorderSettings() {
    if (!this.preorderSettings) {
      this.preorderService
        .getPreorderSettingsOfType(this.preorderType === "Parts" ? "Parts" : "Bicycles")
        .then((response: PreorderSettings) => {
          this.preorderSettings = response;
          if (response) {
            this.showArticleCheckbox = !this.preorderSettings.separate_checkout;
            this.individualPreorderCheckout = this.preorderSettings.separate_checkout

            this.calcTotal(this.basket.items);
          }
        });
    } else {
      this.showArticleCheckbox = !this.preorderSettings.use_time_slots;
      this.individualPreorderCheckout = this.preorderSettings.separate_checkout;
      if (this.basket?.items?.length) {
        this.calcTotal(this.basket.items);
      }
    }
  }

  getBasket() {
    this.loaded = false;
    this.applicationService.showLoader(true);
    const promise = new Promise((resolve, reject) => {
      if (!this.basketId && !this.isPreorderBasket) {
        this.basketService
          .getActiveBasket()
          .then((response: ApiResponse) => {
            this.loaded = true;
            this.applicationService.hideLoader();
            if (response && response.success) {
              this.basket = response.result;
              this.basketId = this.basket.id;
              this.parseBasket();
              this.getShippingAddresses(this.basket.company_id, false);
              if (this.companyGroupCode === 'WG') {
                this.getShippingAddresses(this.basket.company_id, true);
              }
            }

            resolve(true);
          });
      } else {
        this.basketService
          .getBasket(
            this.basketId,
            this.isPreorderBasket
              ? (`preorder${this.preorderType}` as BasketType)
              : BasketType.Normal
          )
          .subscribe((response: ApiResponse) => {
            this.loaded = true;
            this.applicationService.hideLoader();
            if (response && response.success) {
              this.basket = response.result;
              this.basketId = this.basket.id;
              this.parseBasket();

              if (this.showDealerOptions) {
                this.getShippingAddresses(this.basket.company_id, false);
                if (this.companyGroupCode === 'WG') {
                  this.getShippingAddresses(this.basket.company_id, true);
                }
              }
            }
            resolve(true);
          });
      }
    });
    promise.then(() => {
      this.getBasketArticlesByBrand();

      if (this.isPreorderBasket) {
        this.getPreorderSettings();
        this.toggleBasketPreviewButton(true);
      }
      if (this.basketService.basketHasBeenOrdered(this.basket)) {
        this.router.navigateByUrl(
          `${this.companyGroupCode}/order/${this.basket.id}`
        );
      }
    });
    return promise;
  }

  getBasketArticlesByBrand() {
    if (this.basket && this.basket.items) {
      this.basketArticlesByBrand = this.basketService.getBasketArticlesByBrand(
        this.basket.items
      );
    }
  }

  parseBasket(fromDelete?: boolean) {
    if (this.basket && this.basket.items && Array.isArray(this.basket.items)) {
      if (this.breadcrumbs && this.breadcrumbs.length) {
        if (this.basketService.basketHasBeenOrdered(this.basket)) {
          this.breadcrumbs[0].url += '/sent';
        }
      }

      if (this.applicationService.isPartnerPortal()) {
        const unavailableArticles = this.basket.items.filter(
          (b: BasketArticle) => {
            if (b.item_details?.stock_status > 2) {
              b.checkout = false;
            }
          }
        );
      }

      // Regular basket
      if (!this.isPreorderBasket) {
        this.groupedArticles = this.basketService.groupArticles(
          this.basket.items
        );
        this.checkBackorderSelection();
      }

      // Preorder basket
      if (this.isPreorderBasket) {
        this.groupedArticlesByDirectDelivery =
          this.basketService.groupArticlesByDirectDelivery(this.basket.items);
        this.groupedArticlesByMonth = this.basketService.groupArticlesByMonth(
          this.basket.items
        );
      }
      this.calcTotal(this.basket.items);

      if (!this.basket.items.length && fromDelete) {
        const modal = this.modalService.open(ConfirmDialogComponent, {
          container: '#modalContainer',
        });

        modal.componentInstance.isConfirm = true;
        modal.componentInstance.title = '';
        modal.componentInstance.body = this.translateService.instant(
          'DELETE_BASKET_CONFIRM'
        );
        modal.componentInstance.confirmClicked.subscribe(() => {
          this.basketService
            .deleteBasket(this.basketId)
            .subscribe((apiResponse: ApiResponse) => {
              this.router.navigateByUrl(
                `/${this.applicationService.getSelectCompanyGroupCode()}/baskets`
              );
            });
        });
      }
    }
    this.getTotalWidth();
  }

  basketChanged(articles) {
    this.getBasket().then((apiResponse: ApiResponse) => {});
  }

  checkBackorderSelection() {
    this.applicationService.hideLoader();
    if (this.showDealerOptions) {
      let selectedForBackorder = false;
      this.basket.items.filter((b: BasketArticle) => {
        if (b.backorder && b.checkout) {
          selectedForBackorder = true;
        }
      });
      this.selectedForBackorder = selectedForBackorder;
    }
  }

  calcTotal(articles) {
    // Regular basket
    if (!this.isPreorderBasket) {
      this.basketSum = this.basketService.calcTotal(
        articles,
        'basket',
        this.showroomMode,
        this.basket.shipping_cost,
        'regular',
        0,
        this.individualPreorderCheckout
      );
    } else {
      this.basketSumMonths = this.basketService.calcTotal(
        articles,
        'basket',
        this.showroomMode,
        this.basket.shipping_cost,
        'preorder',
        0,
        this.individualPreorderCheckout
      );

      this.basketSumDirect = this.basketService.calcTotal(
        articles,
        'basket',
        this.showroomMode,
        this.basket.shipping_cost,
        'direct',
        0,
        this.individualPreorderCheckout
      );

      this.validPreorderSelection = _.pluck(articles, 'checkout').filter(
        function (s) {
          return s === true;
        }
      ).length;
    }
  }

  checkBackorder() {
    if (this.gotoCheckout) {
      this.applicationService.hideLoader();
    }
    return new Promise((resolve, reject) => {
      if (this.isPreorderBasket) {
        resolve(true);
        return false;
      }
      let articlesWithNoBackorder = this.basket.items.filter(function (s) {
        return (
          s.item_details &&
          !s.backorder &&
          s.item_details.stock_status > 2 &&
          s.checkout &&
          (s.item_details.article_type === 'P' ||
            s.item_details.article_type === 'OT1')
        );
      });
      this.loaded = false;
      this.getArticlesInBackorder().then((articlesInBackorder) => {
        if (this.showDealerOptions) {
          this.loaded = true;
          if (
            articlesWithNoBackorder.length ||
            (
              articlesInBackorder &&
              Object.values(articlesInBackorder).length &&
              _.filter(
                Object.values(articlesInBackorder),
                (a: BackorderArticle) => {
                  return a.quantity_in_backorder > 0;
                }
              )
            ).length
          ) {
            const modalRef = this.modalService.open(BackorderAlerterComponent, {
              centered: true,
              container: '#modalContainer',
              backdrop: 'static',
            });
            modalRef.componentInstance.basket = { ...this.basket };
            modalRef.componentInstance.showDeliverAll =
              this.selectedForBackorder;
            modalRef.componentInstance.basketId = this.basketId;
            modalRef.componentInstance.basketItems = this.basket.items;
            modalRef.componentInstance.articles = articlesWithNoBackorder;
            modalRef.componentInstance.articlesInBackorder =
              articlesInBackorder;
            modalRef.componentInstance.submitObserver.subscribe(() => {
              resolve(true);
            });
            modalRef.dismissed.subscribe(() => {
              this.checkingOut = false;
            })
            modalRef.componentInstance.deliverAllObserver.subscribe(
              (state: boolean) => {
                this.basket.complete_delivery_only = state;
              }
            );
          } else {
            resolve(true);
          }
        } else {
          resolve(true);
        }
      });
    });
  }

  checkShippingAddress() {
    const shippingDropdown = document.getElementById('dropdownShipping');
    const blankOption = document.getElementById('blankOption');
    if (blankOption) {
      shippingDropdown.classList.add('border-danger');
      this.alertService.showPopup(
        this.translateService.instant('NO_SHIPPING_ADDRESS_SELECTED'),
        'error'
      );
      return false;
    } else {
      if (shippingDropdown) {
        shippingDropdown.classList.remove('border-danger');
      }
      return true;
    }
  }

  checkout(basketId) {
    if (this.checkShippingAddress()) {

      // Check if there are articles with missing required items
      const articlesWithNoParent = this.basket.items.filter(function (s) {
        return (s.parent_found === false && s.parent_id) && s.checkout; 
      });

      if (articlesWithNoParent?.length) {
          // open modal
          const modalRef = this.modalService.open(ConfirmDialogComponent, {
            size: 'lg',
            windowClass: 'medium',
            container: '#modalContainer',
          });

          modalRef.componentInstance.title = this.translateService.instant("CAUTION");
          modalRef.componentInstance.body = this.translateService.instant("CHECK_INCORRECT_CHILD_PARENT_ARTICLES");
          modalRef.componentInstance.isConfirm = false;
          return false;
      }

      this.checkingOut = true;

      const promise = new Promise((resolve, reject) => {
        this.checkBackorder().then(() => {
          if (this.visibleArticleType === '') {
            resolve(true);
          } else {
            let uncheckedArticles = [];
            if (this.visibleArticleType === 'parts') {
              uncheckedArticles = this.basket.items.filter(function (s) {
                return s.article_type.startsWith('F');
              });
            }
            if (this.visibleArticleType === 'bicycles') {
              uncheckedArticles = this.basket.items.filter(function (s) {
                return s.article_type !== 'F';
              });
            }

            this.loaded = false;
            this.basketService
              .updateArticleCheckoutStatus(
                this.basketId,
                uncheckedArticles,
                false
              )
              .subscribe((apiResponse: ApiResponse) => {
                this.loaded = true;
                this.checkingOut = false;

                resolve(true);
              });
          }
        });
      });
      promise.then(() => {
        const url = this.isPreorderBasket ? 'preorderCheckout' : (this.applicationService.isPartnerPortal() ? 'shipping' : 'checkout');
        this.router.navigateByUrl(
          `${this.applicationService.getSelectCompanyGroupCode()}/${url}/${basketId}`
        );
      });
    }
  }

  getArticlesInBackorder(): Promise<any> {
    const selectedArticles = this.basket.items.filter((b) => {
      return b.checkout && !b.is_mandatory;
    });

    return new Promise((resolve, reject) => {
      if (this.companyGroupCode !== 'WG') {
        const articles = _.pluck(selectedArticles, 'article_code');

        if (articles.length) {
          // call Api
          this.backorderXhr = this.financialService
            .getArticlesInBackorder(articles)
            .subscribe((apiResponse: ApiResponse) => {
              if (this.helperService.checkResponse(apiResponse)) {
                // Filter out articles with > 0 in back order
                const articles = apiResponse.result;
                const keys = Object.keys(articles);
                const values = Object.values(articles) as BackorderArticle[];
                let parsedArticles = {};
                let index = 0;
                values.forEach((v: BackorderArticle) => {
                  if (v.quantity_in_backorder > 0) {
                    parsedArticles[keys[index]] = v;
                  }
                  index++;
                });

                resolve(parsedArticles);
              } else {
                resolve([]);
              }
            });
        } else {
          resolve([]);
        }
      } else {
        resolve([]);
      }
    });
  }

  deleteArticle(basketArticleId) {
    this.basketService.removeFromBasket(this.basket, basketArticleId);
    this.getShippingCostMinimumOrderAmount().then(() => {
      this.parseBasket(true);
    });
  }

  updateArticle(article: BasketArticle) {
    if (!this.isPreorderBasket) {
      let replArt: number;
      const key =
        article.article_type === AvailableArticleTypes.F ? 'bicycles' : 'parts';

      replArt = this.groupedArticles[key].items.findIndex((a) => {
        return a.id === article.id;
      });

      if (replArt >= 0) {
        this.groupedArticles[key].items[replArt] = article;
      }
      this.basket.items = this.groupedArticles.bicycles.items.concat(
        this.groupedArticles.parts.items
      );
    }
    this.calcTotal(this.basket.items);
  }

  editName() {
    const modalRef = this.modalService.open(EditBasketNameComponent, {
      centered: true,
      container: '#modalContainer',
      backdrop: 'static',
    });
    modalRef.componentInstance.basket = this.basket;
  }

  basketOptionsPopup() {
    const modalRef = this.modalService.open(BasketOptionComponent, {
      centered: true,
      windowClass: 'medium',
      container: '#modalContainer',
    });
    modalRef.componentInstance.basket = this.basket;
  }

  editPreorder() {
    this.applicationService.showLoader(true);
    const modalRef = this.modalService.open(EditPreorderBasketComponent, {
      centered: true,
      size: 'lg',
      container: '#modalContainer',
    });
    modalRef.componentInstance.type = this.preorderType === "Bikes" ? "bicycles" : "parts";
    const items = this.basket.items;
    modalRef.componentInstance.articles = _.pluck(items, 'item_details');
  }

  getShippingCostMinimumOrderAmount() {
    return new Promise((approve) => {
      if (this.applicationService.isV2Basket()) {
        this.shipping_minimum_order_amount = this.basket?.shipping_costs?.length ? this.basket?.shipping_costs[0].minimum_order_amount : null;
        approve(true);
      } else {
        this.basketService
          .getShippingCosts()
          .subscribe((apiResponse: ApiResponse) => {
            if (
              apiResponse &&
              apiResponse.success &&
              apiResponse.result &&
              apiResponse.result.length
            ) {
              this.shipping_minimum_order_amount = apiResponse.result[0]
                .minimum_order_amount as Price;
            }
            approve(true);
          });
      }
    });
  }

  showShippingCost() {
    const getShippingCost = new Promise((approve) => {
      if (this.applicationService.isV2Basket()) {
        approve(this.basket.shipping_costs)
      } else {
        this.applicationService.showLoader(true);
        this.basketService
          .getShippingCosts()
          .subscribe((apiResponse: ApiResponse) => {
            this.applicationService.hideLoader();
            if (apiResponse && apiResponse.success) {
              approve(apiResponse.result)
            }
          });
      }
    })

    getShippingCost.then((shippingCosts: Array<ShippingCostDetails>) => {
      // open modal
      const modalRef = this.modalService.open(ConfirmDialogComponent, {
        size: 'lg',
        windowClass: 'medium',
        container: '#modalContainer',
      });

      modalRef.componentInstance.isConfirm = false;
      modalRef.componentInstance.setContent(
        ShippingCostOverviewComponent,
        'shippingCosts',
        shippingCosts
      );
    })

  }

  // Get shipping addresses
  getShippingAddresses(companyId: string, parts: boolean) {
    this.accountService
      .getAccountInformation()
      .then((accountInformation: AccountInformation) => {
        if (
          accountInformation &&
          accountInformation.shipping_addresses &&
          accountInformation.shipping_addresses.length
        ) {
          const addresses =
            this.companyGroupCode !== 'WG'
              ? accountInformation.shipping_addresses
              : _.filter(accountInformation.shipping_addresses, (s) => {
                  return s.company_id === (parts ? '102' : '101');
                });

          const group: ShippingAddressGroup = new ShippingAddressGroup();
          group.company_id = '';
          group.shipping_addresses = addresses.filter((s) => {
            // preselect shipping address
            if (
              String(s.id) ===
              String(
                !parts
                  ? this.basket.shipping_address
                    ? this.basket.shipping_address.id
                    : null
                  : this.basket.shipping_address_parts
                  ? this.basket.shipping_address_parts.id
                  : null
              )
            ) {
              group.selected = s.id;
            }

            if (!this.applicationService.isV2Basket()) {
              return (
                String(s.company_id) ===
                (parts
                  ? '102'
                  : this.companyGroupCode === 'WG'
                  ? '101'
                  : String(companyId))
              );
            } else {
              return (
                s.company_group_code === this.companyGroupCode
              );
            }
          });
          group.address_access = 'all';
          if (!parts) {
            this.shippingAddressGroup = group;
          } else {
            this.shippingAddressGroupParts = group;
          }
        }
      });
  }

  setShippingAddress(data: number) {
    const shippingAddressId = data;
    const selected = this.shippingAddressGroup.shipping_addresses.filter(
      (s) => {
        return s.id == shippingAddressId;
      }
    ) as Array<ShippingAddress>;
    if (selected && selected.length) {

      if (!this.applicationService.isV2Basket()) {
        this.basket.shipping_address = selected[0];
      } else {
        this.basket.shipping_address_id = selected[0].id;
      }

      this.basketService
        .updateBasket(this.basket)
        .subscribe((apiResponse: ApiResponse) => {
          this.alertService.showInfoNotification(
            this.translateService.instant('SAVED')
          );
        });
    }
  }

  setShippingAddressParts(data: number) {
    const shippingAddressId = data;
    const selected = this.shippingAddressGroupParts.shipping_addresses.filter(
      (s) => {
        return s.id == shippingAddressId;
      }
    ) as Array<ShippingAddress>;
    if (selected && selected.length) {

      if (!this.applicationService.isV2Basket()) {
        this.basket.shipping_address_parts = selected[0];
      } else {
        this.basket.shipping_address_parts_id = selected[0].id;
      }

      this.basketService
        .updateBasket(this.basket)
        .subscribe((apiResponse: ApiResponse) => {
          this.alertService.showInfoNotification(
            this.translateService.instant('SAVED')
          );
        });
    }
  }

  // Get corresponding timeslot
  getTimeslotFromMonth(month: string) {
    const formattedNumber = Number(month);
    let timeslot: string;
    const availableTimeSlots = [1, 3, 9, 11];
    if (availableTimeSlots.indexOf(formattedNumber) > 0) {
      timeslot =
        formattedNumber < 10 ? `0${formattedNumber}` : String(formattedNumber);
    } else {
      let timeSlotNumber = formattedNumber + 1;
      if (timeSlotNumber > 12) {
        timeSlotNumber = 1;
      }
      if (timeSlotNumber < 10) {
        timeslot = `0${timeSlotNumber}`;
      } else {
        timeslot = String(timeSlotNumber);
      }
    }
    return timeslot;
  }

  showWishList() {
    const modalRef = this.modalService.open(WishlistModalComponent, {
      centered: true,
      size: 'lg',
      container: '#modalContainer',
    });
  }

  basketHasAnyParts() {
    if (
      this.basket.items.filter((item) => item.article_type === 'P').length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  unlockBasket() {
    this.basketService.unlockBasket(this.basketId);
  }

  private toggleBasketPreviewButton(hide = true) {
    // Hide basket button in App bar
    const basketPreviewButton = document.querySelector(
      '#basketPreview'
    ) as HTMLElement;

    if (basketPreviewButton) {
      basketPreviewButton.style.display = hide ? 'none' : 'initial';
    }
  }

  // Set condition to deliver the order
  updateDelivery(completeDeliveryOnly) {
    this.basket.complete_delivery_only = completeDeliveryOnly;
    this.disableCheckout = true;
    this.basketService.updateBasket(this.basket).subscribe(() => {
      this.disableCheckout = false;
    });
  }
}
