<div class="container">
  <div class="mx-0 row ">
    <div class="col-12 col-sm-6 px-0">
      <form class="bg-light p-4 rounded rounded-4" [formGroup]="form" *ngIf="form" [ngClass]="validated ? 'validated' : ''">
        <h3>{{"PERSONAL_DATA"|translate}}</h3>
        <div class="form-row mb-3 mt-4 row">
          <div class="col-md-6 pe-sm-0 mb-3 mb-md-0">
            <label class="fw-bold" color="primary">{{ "FIRSTNAME" | translate }}</label>
            <input [ngClass]="
          !form.get('firstname').valid && validated ? 'is-invalid' : ''
        " class="form-control" (click)="validated = false" placeholder="{{ 'REQUIRED' | translate }}" text-end
              formControlName="firstname" maxlength="50" />
          </div>
          <div class="col-md-6">
            <label class="fw-bold" color="primary">{{ "LASTNAME" | translate }}</label>
            <input [ngClass]="!form.get('lastname').valid && validated ? 'is-invalid' : ''" class="form-control"
              (click)="validated = false" placeholder="{{ 'REQUIRED' | translate }}" text-end formControlName="lastname"
              maxlength="50" />
          </div>
        </div>

        <div class="form-row mb-3" *ngIf="applicationService.getSelectCompanyGroupCode() !== 'RAL'">
          <div class="col-md-3">
            <label class="fw-bold" color="primary">{{ "ZIP" | translate }}</label>
            <input (change)="getAddress(form)" [ngClass]="!form.get('zipcode').valid && validated ? 'is-invalid' : ''"
              class="form-control" (click)="validated = false" placeholder="{{ 'REQUIRED' | translate }}" text-end
              formControlName="zipcode" maxlength="8" />
          </div>
        </div>


        <div class="form-row mb-3">
          <div class="col-md-12">
            <label class="fw-bold" color="primary">{{ (applicationService.getSelectCompanyGroupCode() === 'RAL' ?
              "ADDRESS_LINE_1" : "STREETNAME") | translate }} <ng-container *ngIf="applicationService.getSelectCompanyGroupCode() !== 'RAL'">
                +
                {{"HOUSENUMBER"| translate }}
                </ng-container>
              <div class="spinner spinner-border text-primary spinner-border-sm" role="status" *ngIf="addressLoader">
                <span class="visually-hidden">{{ "LOADING" | translate }}</span>
              </div>
            </label>
            <input [ngClass]="!form.get('street').valid && validated ? 'is-invalid' : ''" class="form-control"
              (click)="validated = false" placeholder="{{ 'REQUIRED' | translate }}" text-end formControlName="street"
              maxlength="50" />
          </div>
        </div>

        <ng-container *ngIf="showAdditionalAddressFields">
          <div class="form-row mb-3">
            <div class="col-md-12">
              <label class="fw-bold" color="primary">{{ "DISTRICT" | translate }}</label>
              <input [ngClass]="
            !form.get('district').valid && validated ? 'is-invalid' : ''
          " class="form-control" (click)="validated = false" text-end formControlName="district" maxlength="25" />
            </div>
          </div>
        </ng-container>


        <div class="form-row mb-3">
          <div class="col-md-12">
            <label class="fw-bold" color="primary">{{
              (applicationService.getSelectCompanyGroupCode() === "RAL"
              ? "MARKET_CITY"
              : "CITY"
              ) | translate
              }}</label>
            <input [ngClass]="!form.get('city').valid && validated ? 'is-invalid' : ''" class="form-control"
              (click)="validated = false" placeholder="{{ 'REQUIRED' | translate }}" text-end formControlName="city"
              maxlength="50" />
          </div>
        </div>

        <div class="form-row mb-3" *ngIf="applicationService.getSelectCompanyGroupCode() === 'RAL'">
          <div class="col-md-3">
            <label class="fw-bold" color="primary">{{ "ZIP" | translate }}</label>
            <input (change)="getAddress(form)" [ngClass]="!form.get('zipcode').valid && validated ? 'is-invalid' : ''"
              class="form-control" (click)="validated = false" placeholder="{{ 'REQUIRED' | translate }}" text-end
              formControlName="zipcode" maxlength="8" />
          </div>
        </div>

        <div class="form-row mb-3">
          <div class="col-md-3">
            <label class="fw-bold" color="primary">{{ "COUNTRY" | translate }}</label>
            <select [ngClass]="!form.get('country_code').valid && validated ? 'is-invalid' : ''"
              class="form-control" (click)="validated = false"
              formControlName="country_code">
              <option *ngFor="let country of availableCountries" [value]="country">{{'COUNTRY_' + country | translate}}</option>
              </select>
          </div>
        </div>
        <div class="form-row mb-3">
          <div class="col-md-12">
            <label class="fw-bold" color="primary">{{ "PHONE" | translate }}</label>
            <input [ngClass]="!form.get('phone_number').valid && validated ? 'is-invalid' : ''" class="form-control" type="tel"
              (click)="validated = false" placeholder="{{ 'REQUIRED' | translate }}" text-end formControlName="phone_number"
              pattern="^[0-9-+s()]*$" />
          </div>
        </div>
      </form>
    </div>
    <div class="col-12 col-sm-6 px-0 ps-sm-2 pe-sm-0 mt-2 mt-sm-0">
      <div class="bg-light p-4 rounded rounded-4">
        <h3>{{"SHIPPINGADDRESS"|translate}}</h3>
        <p>{{"SHIPPING_SAME_AS_INVOICE"|translate}}</p>
        <p-inputSwitch [(ngModel)]="shippingSameAsInvoice"></p-inputSwitch>

        <form [formGroup]="shippingForm" *ngIf="!shippingSameAsInvoice" class="mt-2">
          <div class="form-row mb-3"  *ngIf="applicationService.getSelectCompanyGroupCode() !== 'RAL'">
            <div class="col-md-3">
              <label class="fw-bold" color="primary">{{ "ZIP" | translate }}</label>
              <input (change)="getAddress(shippingForm)"
                [ngClass]="!shippingForm.get('zipcode').valid && validated ? 'is-invalid' : ''" class="form-control"
                (click)="validated = false" placeholder="{{ 'REQUIRED' | translate }}" text-end
                formControlName="zipcode" maxlength="8" />
            </div>
          </div>

          <div class="form-row mb-3">
            <div class="col-md-12">
              <label class="fw-bold" color="primary">{{ (applicationService.getSelectCompanyGroupCode() === 'RAL' ?
                "ADDRESS_LINE_1" : "STREETNAME") | translate }}
                <ng-container *ngIf="applicationService.getSelectCompanyGroupCode() !== 'RAL'">
                +
                {{"HOUSENUMBER"| translate }}
                </ng-container>
                <div class="spinner spinner-border text-primary spinner-border-sm" role="status" *ngIf="addressLoader">
                  <span class="visually-hidden">{{ "LOADING" | translate }}</span>
                </div>
              </label>
              <input [ngClass]="!shippingForm.get('street').valid && validated ? 'is-invalid' : ''" class="form-control"
                (click)="validated = false" placeholder="{{ 'REQUIRED' | translate }}" text-end formControlName="street"
                maxlength="50" />
            </div>
          </div>

          <ng-container *ngIf="showAdditionalAddressFields">
            <div class="form-row mb-3">
              <div class="col-md-12">
                <label class="fw-bold" color="primary">{{ "DISTRICT" | translate }}</label>
                <input [ngClass]="
              !shippingForm.get('district').valid && validated ? 'is-invalid' : ''
            " class="form-control" (click)="validated = false" text-end formControlName="district" maxlength="25" />
              </div>
            </div>
          </ng-container>

          <div class="form-row mb-3">
            <div class="col-md-12">
              <label class="fw-bold" color="primary">{{
                (applicationService.getSelectCompanyGroupCode() === "RAL"
                ? "MARKET_CITY"
                : "CITY"
                ) | translate
                }}</label>
              <input [ngClass]="!shippingForm.get('city').valid && validated ? 'is-invalid' : ''" class="form-control"
                (click)="validated = false" placeholder="{{ 'REQUIRED' | translate }}" text-end formControlName="city"
                maxlength="50" />
            </div>
          </div>

          <div class="form-row mb-3"  *ngIf="applicationService.getSelectCompanyGroupCode() === 'RAL'">
            <div class="col-md-6">
              <label class="fw-bold" color="primary">{{ "ZIP" | translate }}</label>
              <input (change)="getAddress(shippingForm)"
                [ngClass]="!shippingForm.get('zipcode').valid && validated ? 'is-invalid' : ''" class="form-control"
                (click)="validated = false" placeholder="{{ 'REQUIRED' | translate }}" text-end
                formControlName="zipcode" maxlength="8" />
            </div>
          </div>

          <div class="form-row mb-3">
            <div class="col-md-3">
              <label class="fw-bold" color="primary">{{ "COUNTRY" | translate }}</label>
              <select [ngClass]="!shippingForm.get('shipping_country_code').valid && validated ? 'is-invalid' : ''"
                class="form-control" (click)="validated = false"
                formControlName="shipping_country_code">
                <option *ngFor="let country of availableCountries" [value]="country">{{'COUNTRY_' + country | translate}}</option>
                </select>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-6"></div>
    <ng-content></ng-content>
  </div>
</div>
