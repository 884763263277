import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-favorites-cms-content',
  templateUrl: './favorites-cms-content.component.html',
  styleUrls: ['./favorites-cms-content.component.scss']
})
export class FavoritesCmsContentComponent implements OnInit {

  // TODO: from settings
  pageIds = {
    "ANL": "960422109",
    "GHO": "960422110",
    "LAP": "960422111",
    "COM": "960422112",
    "TUN": "960422113",
    "VAR": "960422114",
    "WG": "960422115"
  }

  get pageId() {
    return this.pageIds[this.applicationService.getSelectCompanyGroupCode()];
  }

  constructor(private applicationService: ApplicationService) { }

  ngOnInit() {
  }

}
