<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'EDIT' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <app-ecommerce-articles
        #batteries
        [mainArticle]="article"
        [type]="'crosssell'"
        [header]="'BATTERIES' | translate"
        [showHeader]="false"
        [shopOption]="true"
        [showQuantity]="false"
        [compact]="true"
        [preSelectedArticle]="preSelectedBattery"
        [hideArticleOverlay]="true"
      ></app-ecommerce-articles>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="me-auto btn btn-outline-dark btn-lg"
    (click)="modal.dismiss('Close click')">{{ 'CANCEL_ACTION' | translate }}</button>
  <button type="button" class="ms-auto btn btn-dark btn-lg" (click)="saveOpenOrder(this.batteries)">{{ 'SAVE' | translate }}</button>
</div>

