import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { EditOpenOrderLineInput } from "../interfaces/financial/openorders/edit-open-order-line-input";
import { Subject, Observable } from "rxjs";
import { ApiResponse } from "../interfaces/api-response";
import { OrderLine } from "../interfaces/financial/openorders/order-line";
import * as _ from "underscore";

@Injectable({
  providedIn: "root",
})
export class FinancialService {
  private emitFinancialChange = new Subject<any>();
  changeEmitted$ = this.emitFinancialChange.asObservable();

  // Service message commands
  emitChange(change: ApiResponse) {
    this.emitFinancialChange.next(change);
  }

  constructor(private http: HttpClient) {}

  /* General  */
  getDate(dateObject) {
    if (dateObject) {
      return (
        String(dateObject["year"]) +
        ((dateObject["month"] < 10 ? "0" : "") + String(dateObject["month"])) +
        ((dateObject["day"] < 10 ? "0" : "") + String(dateObject["day"]))
      );
    } else {
      return null;
    }
  }

  /* Open order lines */
  getOpenOrders(filters) {
    return this.http.post(
      environment.apiendpoint + "financial/openorderlines/search",
      filters
    );
  }

  /* Balance lines */
  getBalance(filters) {
    return this.http.post(
      environment.apiendpoint + "financial/balance/search",
      filters
    );
  }

  // Get the brands for the company brands filter in open order lines
  getBicycleBrands(companyGroup: string) {
    return this.http.get(`${environment.apiendpoint}financial/belongsoptions`);
  }

  getShiftingWeeks(orderLine: OrderLine) {
    const deliveryWeekDate = orderLine.delivery_week_or_date.split(" ");
    const parsedDeliveryWeek =
      String(deliveryWeekDate[1]) + String(deliveryWeekDate[0]);
    return this.http.get(
      environment.apiendpoint +
        `financial/openorderlines/shiftingweekpossibilities/${
          orderLine.article_code
          // tslint:disable-next-line:quotemark
        }/${parsedDeliveryWeek}`
    );
  }

  editOrder(currentOrder, splittedOrder) {
    const editOpenOrderLineInput = new EditOpenOrderLineInput();
    editOpenOrderLineInput.address_type = currentOrder.shipping_code;
    editOpenOrderLineInput.amount = String(currentOrder.quantity);

    if (currentOrder.delivery_week_or_date) {
      editOpenOrderLineInput.delivery_week = currentOrder.delivery_week_or_date;
    }

    editOpenOrderLineInput.line_id = currentOrder.line_id;
    editOpenOrderLineInput.remark = currentOrder.remark;
    editOpenOrderLineInput.shipping_id = currentOrder.shipping_id;
    editOpenOrderLineInput.sold = Boolean(currentOrder.sold);
    editOpenOrderLineInput.order_number = currentOrder.order_number;

    if (splittedOrder) {
      editOpenOrderLineInput.new_address_type = splittedOrder.shipping_id;
      editOpenOrderLineInput.new_amount = String(splittedOrder.quantity);
      editOpenOrderLineInput.new_delivery_week =
        currentOrder.delivery_week_or_date !== null
          ? splittedOrder.delivery_week_or_date
          : null;
      editOpenOrderLineInput.new_line_id = currentOrder.line_id;
      editOpenOrderLineInput.new_sold = Boolean(splittedOrder.sold);
      editOpenOrderLineInput.new_shipping_id = splittedOrder.shipping_id;
      editOpenOrderLineInput.new_address_type = splittedOrder.shipping_code;
      editOpenOrderLineInput.new_remarks = splittedOrder.remark;
    } else {
      editOpenOrderLineInput.new_amount = "";
      editOpenOrderLineInput.new_delivery_week = "";
      editOpenOrderLineInput.new_line_id = "";
      editOpenOrderLineInput.new_sold = false;
      editOpenOrderLineInput.new_address_type = "";
      editOpenOrderLineInput.new_shipping_id = "";
      editOpenOrderLineInput.new_remarks = "";
    }
    return this.updateOpenOrder(editOpenOrderLineInput);
  }

  updateOpenOrder(editOpenOrderLineInput: EditOpenOrderLineInput) {
    return this.http.put(
      environment.apiendpoint + "financial/openorderlines/",
      editOpenOrderLineInput
    );
  }

  updateOpenOrderBattery(orderline: OrderLine, articleCode: string) {
    return this.http.put(
      environment.apiendpoint +
        `financial/openorderlines/${orderline.line_id}/${articleCode}`,
      {}
    );
  }

  getOpenOrdersExcelFile(filters) {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }),
      responseType: "blob" as "json",
    };

    return this.http.post(
      environment.apiendpoint + "financial/openorderlines/excel/?t=excel",
      filters,
      HTTPOptions
    );
  }

  getBackOrdersPdfFile() {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: "application/pdf",
      }),
      responseType: "blob" as "json",
    };

    return this.http.get(
      environment.apiendpoint +
        `financial/files/backorder?articleCodes=&fileType=pdf`,
      HTTPOptions
    );
  }

  getOpenOrdersPdfFile(code) {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: "application/pdf",
      }),
      responseType: "blob" as "json",
    };

    return this.http.get(
      environment.apiendpoint +
        `financial/files/openorders?articleCodes=${code}&fileType=pdf`,
      HTTPOptions
    );
  }

  getInvoicesPdfFile(code) {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: "application/pdf",
      }),
      responseType: "blob" as "json",
    };

    return this.http.get(
      environment.apiendpoint +
        `financial/files/invoices?articleCodes=${code}&fileType=pdf`,
      HTTPOptions
    );
  }

  /* Invoices */
  getInvoices(filters) {
    return this.http.post(
      environment.apiendpoint + "financial/invoices/search",
      filters
    );
  }

  /* Invoices */
  getInvoicesPdf(invoiceNumber: string, type?: string) {
    return this.http.get(
      environment.apiendpoint +
        `financial/files/${type}?articleCodes=${invoiceNumber}&fileType=pdf`
    );
  }

  /* Packingslips */
  getPackingSlips(filters) {
    return this.http.post(
      environment.apiendpoint + "financial/packingslips/search",
      filters
    );
  }

  /* Packingslip articles */
  getPackingSlipArticles(packingSlipNumber: string) {
    return this.http.get(`
      ${environment.apiendpoint}warranties/getpackingsliparticles/${packingSlipNumber}?v=1.0`
    );
  }

  getPackingSlip(packingSlipNumber: string) {
    return this.http.get(
      environment.apiendpoint +
        `financial/files/packingslip?articleCodes=${packingSlipNumber}&fileType=pdf`
    );
  }

  getTurnoverStatisticsPdfFile(companyID: string) {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: "application/pdf",
      }),
      responseType: "blob" as "json",
    };

    return this.http.get(
      environment.apiendpoint +
        `financial/turnoverstatistics/pdf/${companyID}?fileType=pdf`,
      HTTPOptions
    );
  }

  getCompanyDataPdfFile(companyId: string) {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: "application/pdf",
      }),
      responseType: "blob" as "json",
    };

    return this.http.get(
      environment.apiendpoint +
        `financial/companydata/pdf/${companyId}?fileType=pdf`,
      HTTPOptions
    );
  }

  getDiscountSheetPdfFile(companyID: string) {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: "application/pdf",
      }),
      responseType: "blob" as "json",
    };

    return this.http.get(
      environment.apiendpoint +
        `financial/discountsheet/pdf/${companyID}?fileType=pdf`,
      HTTPOptions
    );
  }

  // Get the line for balance
  getCreditPdfFile(code) {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: "application/pdf",
      }),
      responseType: "blob" as "json",
    };

    return this.http.get(
      environment.apiendpoint +
        `financial/files/cremo?articleCodes=${code}&fileType=pdf`,
      HTTPOptions
    );
  }

  formatDate(date: string) {
    let formattedDate = [];
    formattedDate = date.split("-");
    return `${formattedDate[2]}${formattedDate[1]}${formattedDate[0]}`;
  }

  deliveryWeekRange(orderLines: OrderLine[]) {
    const firstOrderLine: OrderLine = orderLines[0];
    const dates = [];
    for (let orderLine of orderLines) {
      const orderWeek = orderLine.delivery_week_or_date.split(" ");
      dates.push(Number(`${orderWeek[1]}${orderWeek[0]}`));
      dates.sort();
      if (dates.length > 1 && dates[0] !== dates[1]) {
        firstOrderLine.parsed_delivery_week_range = `${this.formatSortedWeekYearBack(
          dates[0]
        )} - ${this.formatSortedWeekYearBack(dates[1])}`;
      } else {
        firstOrderLine.parsed_delivery_week_range = `${this.formatSortedWeekYearBack(
          dates[0]
        )}`;
      }
    }
    return firstOrderLine;
  }

  formatSortedWeekYearBack(date) {
    date = date.toString();
    const week = date.substring(4, 6).replace(/^0+/, "");
    return `${week}`;
  }

  getTotalInTransitAndQuantity(orderLines: OrderLine[]) {
    let total_in_transit: number = 0;
    let total_quantity: number = 0;
    for (let orderLine of orderLines) {
      total_in_transit += orderLine.quantity_in_transit;
      total_quantity += orderLine.quantity_to_send;
    }
    orderLines[0].total_in_transit = total_in_transit;
    orderLines[0].total_quantity = total_quantity;
  }

  getArticlesInBackorder(articles: string[]) {
    return this.http.get(
      `${environment.apiendpoint}financial/backorder/${"articles?c=" + _.unique(articles).join("&c=")}&v=1.0`
    );
  }
}
