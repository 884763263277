export enum AvailableBrands {
  "Accentry" = 0,
  "Batavus" = 1,
  "Koga" = 2,
  "Sparta" = 5,
  "Juncker" = 4,
  "Loekie" = 3,
  "EBSC" = 7,
  "Lapierre" = 6,
  "Brasseur" = 8,
  "Tunturi Fitness" = 80,
  "Tunturi Hellberg" = 10,
  "SBS" = 60,
  "Hercules" = 20,
  "RedLineBatavus" = 62,
  "RedLineBrasseur" = 63,
  "RedLineLapierre" = 64,
  "RedLineHellberg" = 65,
  "Bikes and More" = 16,
  "Ghost" = 25,
  "Van Nicholas" = 11,
  "Vartex" = 42,
  "Ghost NL" = 27,
  "Comet" = 44
}

export class Brands {
  public AvailableBrands: AvailableBrands;
}
