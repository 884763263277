import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { CmsService } from 'src/app/services/cms.service';
import { AlertItem } from 'src/app/interfaces/cms/alert-item';
import { ApplicationService } from 'src/app/services/application.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-counter',
  templateUrl: './alert-counter.component.html',
  styleUrls: ['./alert-counter.component.scss'],
})
export class AlertCounterComponent implements OnInit {
  @Output() alertDropdownOpen: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('myDrop') dropdown: NgbDropdown;
  count: string;
  alertItems: AlertItem[];
  unreadItems: AlertItem[];
  timer: any;
  selectedCompanyGroup: string;
  loading = false;
  dropdownOpen = false;
  cursorInArea = false;

  constructor(
    private cmsService: CmsService,
    private applicationService: ApplicationService
  ) {}

  ngOnInit() {
    this.unreadItems = Array<AlertItem>();
    this.selectedCompanyGroup =
      this.applicationService.getSelectCompanyGroupCode();
    // Get alerts
    this.getAlerts();

    this.applicationService.emitChangeCompanyGroupChanged$.subscribe(() => {
      this.unreadItems = Array<AlertItem>();
    });
  }

  toggleDropdown(dropdown: NgbDropdown) {
    if (dropdown.isOpen()) {
      dropdown.close();
    } else if (this.unreadItems && this.unreadItems.length) {
      this.showDropdown(dropdown);
    } else {
      this.getAlerts(true).then(() => {
        this.showDropdown(dropdown);
      });
    }
  }

  closeDropdown(dropdown, event) {
    if (event && event.srcElement && event.srcElement.id === 'alertCounter') {
    } else {
      dropdown.close();
    }
  }

  showDropdown(dropdown) {
    this.loading = true;
    this.timer = setTimeout(() => {
      dropdown.open();
      if (dropdown.open) {
        this.alertDropdownOpen.emit(true);
        dropdown.open();
        this.loading = false;
      }
    }, 100);

    this.unreadItems.forEach((alert) => {
      this.cmsService.saveAlertRead(alert);
    });
  }

  hideDropdown(dropdown = null) {
    this.loading = false;
    clearTimeout(this.timer);
  }

  getAlerts(openDropdown = false) {
    // Get alerts
    this.loading = true;
    return new Promise((resolve) => {
      this.cmsService.getAlerts().then((alertItems: AlertItem[]) => {
        this.loading = false;
        this.setCounter(alertItems);
        resolve(true)
      });
    });
  }

  setCounter(alertItems) {
    let count = 0;
    this.unreadItems = Array<AlertItem>();
    if (alertItems && alertItems.length) {
      alertItems.forEach((alert: AlertItem) => {
        if (!this.cmsService.checkIfAlertRead(alert)) {
          count++;
        }
        this.unreadItems.push(alert);
      });
      this.alertItems = alertItems;
      this.count = String(count);
    } else {
      this.alertItems = [];
      this.count = '0';
    }
  }
}
