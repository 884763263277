import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import {
//   NgxGalleryOptions,
//   NgxGalleryImage,
//   NgxGalleryAnimation,
//   NgxGalleryImageSize,
//   NgxGalleryComponent,
// } from 'ngx-gallery-9';
// import Swiper core and required modules
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import _ from 'underscore';
import { ArticleImageLightboxComponent } from '../article-image-lightbox/article-image-lightbox.component';
import { register } from 'swiper/element/bundle';
import { ElementRef } from '@angular/core';
// install Swiper modules
SwiperCore.use([FreeMode, Navigation, Thumbs]);

@Component({
  selector: "app-article-image-carousel",
  templateUrl: "./article-image-carousel.component.html",
  styleUrls: ["./article-image-carousel.component.scss"],
})
export class ArticleImageCarouselComponent implements OnInit {
  @Input() articleImages: any = [];
  @Input() height = "50vh";
  @Input() title = "";
  @Input() initialImage: string;
  thumbsSwiper: any;
  swiper: Element;


  constructor(private modalService: NgbModal, private elRef: ElementRef) {}

  ngOnInit() {
  }

  ngAfterViewChecked() {
    register();

    const swiperEl = this.elRef.nativeElement.parentElement.querySelector(`.image-carousel`);
    if (swiperEl && !swiperEl.classList.contains("swiper-initialized")) {
      const params = {
        injectStylesUrls: ['/assets/carousel.css'],
      };

      Object.assign(swiperEl, params);

      swiperEl['initialize']();
      this.swiper = swiperEl;
    }
  }

  check() {
    if (this.initialImage) {
      this.gotoImage(this.initialImage);
    }
  }

  gotoImage(imageUrl: string) {
    try {
      if (this.swiper) {
        const bigImages = _.pluck(this.articleImages, "big");
        const convertedImages = bigImages.map((element) => {
          return element.toLowerCase();
        });
        const imageData = imageUrl.split("?");
        if (imageData.length) {
          const index = convertedImages.indexOf(imageData[0].toLocaleLowerCase());
          if (index >= 0 && this.swiper) {
            this.swiper['swiper'].slideTo(index);
          }
        }
      }
    } catch (error) {

    }
  }

  openLightBox() {
    const modalRef = this.modalService.open(ArticleImageLightboxComponent, {
      centered: true,
      size: "lg",
      container: "#modalContainer",
    });
    modalRef.componentInstance.articleImages = [...this.articleImages];
    modalRef.componentInstance.title = this.title;
  }
}
