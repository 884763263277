<ngx-slider
  class="custom-slider mb-2"
  [(value)]="value"
  [(highValue)]="highValue"
  [options]="options"
  (userChangeEnd)="changed($event)"
  *ngIf="options"
></ngx-slider>
<div class="w-full text-center">
  <span class="material-icons clickable withOpacity" (click)="expanded = !expanded">
    {{ expanded ? "expand_less" : "expand_more" }}
  </span>
</div>
<div class="weeksContainer" [ngClass]="{ active: expanded }">
  <small
    class="d-block clickable withOpacity"
    *ngFor="let i of [1, 2, 3, 4]"
    (click)="setRange(i)"
    ><i class="material-icons">local_shipping</i>
    < {{ i }} {{ (i === 1 ? "WEEK" : "WEEKS") | translate }}</small
  >
  <u class="clickable withOpacity" (click)="reset()">{{"RESET"|translate}}</u>
</div>
