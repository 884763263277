import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-invoice-balance-overview",
  templateUrl: "./invoice-balance-overview.component.html",
  styleUrls: ["./invoice-balance-overview.component.scss"]
})
export class InvoiceBalanceOverviewComponent implements OnInit {
  @Input() numResults: any;
  constructor() {}

  ngOnInit() {}
}
