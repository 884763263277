import { Component, OnInit, Input, ElementRef } from "@angular/core";
import { trigger, transition, style, animate } from "@angular/animations";
import { ApplicationService } from "src/app/services/application.service";
import { CompanyGroup } from "src/app/interfaces/company-group";
import { BasketService } from "src/app/services/basket.service";
import { MenuItem } from "src/app/interfaces/menuItem";
import { MenuLayout } from "src/app/interfaces/menu-layout";
import { Location } from "@angular/common";
import { HelpdeskService } from "src/app/services/helpdesk.service";
import { User } from "src/app/interfaces/user";
import { Router } from "@angular/router";
import { ChatService } from "src/app/services/chat.service";
import { AccountService } from "src/app/services/account.service";

@Component({
  selector: "app-logo",
  templateUrl: "./logo.component.html",
  styleUrls: ["./logo.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({
          opacity: 0,
          overflow: "hidden",
        }),
        animate("425ms ease-out", style({ opacity: 1 })),
      ]),
    ]),
  ],
  host: {
    "(document:click)": "onClick($event)",
  },
})
export class LogoComponent implements OnInit {
  @Input() selectedCompanyGroup: string;
  companies: number[];
  showCompanyDropdown: boolean = false;
  availableCompanyGroups: any = [];
  companyGroupCode = "";
  companyGroupsSettings: CompanyGroup;
  initialCompanyGroupCode = "";
  hasEWBP: boolean = false;
  brands: any = {};
  homepage = "/home";

  constructor(
    public applicationService: ApplicationService,
    private basketService: BasketService,
    private location: Location,
    private accountService: AccountService,
    private _el: ElementRef,
    private router: Router,
    private chatService: ChatService,
  ) {}

  ngOnInit() {
    this.init();
  }

  init() {
    this.homepage = "/home";
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
    this.companyGroupsSettings =
      this.applicationService.getSettingsForCompanyGroup(this.companyGroupCode) as CompanyGroup
    this.getMenuItems();

    this.applicationService.emitChangeCompanyGroupChanged$.subscribe(
      (response) => {
        this.companyGroupCode =
          this.applicationService.getSelectCompanyGroupCode();
        this.getMenuItems(true);
        this.brands = this.applicationService.getAllGroupBrands();
      }
    );

    this.brands = this.applicationService.getAllGroupBrands();
  }

  toggleCompanyDropdown() {
    this.showCompanyDropdown = !this.showCompanyDropdown;
    // Sets hasEWBP to true if company 102 is present in any of the available companygroups
    if (Array.isArray(this.availableCompanyGroups) && this.availableCompanyGroups.length) {
      this.hasEWBP = this.availableCompanyGroups.some(function(el) {
        return el.companies.includes(102);
      });
    } else {
      // Handle the case where availableCompanyGroups is not an array
      this.hasEWBP = false;
    }
  }

  onClick(event) {
    if (!this._el.nativeElement.contains(event.target))
      this.showCompanyDropdown = false;
  }

  getMenuItems(clear = false) {
    this.initialCompanyGroupCode =
      this.applicationService.getSelectCompanyGroupCode();
    this.companyGroupCode = this.initialCompanyGroupCode;
    this.availableCompanyGroups = this.applicationService.getSetting(
      "companyGroups",
      "[]"
    );
  }

  setCompanyGroup(event, code) {
    this.applicationService.saveSelectedCompanyGroupCode(code);
    this.companyGroupsSettings =
      this.applicationService.getSettingsForCompanyGroup(code) as CompanyGroup;
    this.companyGroupCode = code;
    this.basketService.emitChange("");
    this.location.go(code);
    this.getMenuItems();
    sessionStorage.removeItem("news-" + this.companyGroupCode);
    this.router.navigateByUrl(
      this.applicationService.getSelectCompanyGroupCode() + "/"
    );
    this.chatService.toggleChat(code === "ANL"); // Enable chat for ANL only
    if (event) {
      event.preventDefault();
    }
    return false;
  }

  gotoSearch(brand: string) {}
}
