import { Component, OnInit, Input } from "@angular/core";
import { ApiResponse } from "src/app/interfaces/api-response";
import { FinancialService } from "src/app/services/financial.service";
import { OpenOrdersFilters } from "src/app/interfaces/financial/openorders/open-orders-filters";
import { saveAs } from "file-saver";
import { OrderLine } from "src/app/interfaces/financial/openorders/order-line";
import * as _ from "underscore";
import { ApplicationService } from "src/app/services/application.service";
import { FinancialPaginatorData } from "src/app/interfaces/financial/financial-paginator-data";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PdfViewerComponent } from "src/app/components/general-components/pdf-viewer/pdf-viewer.component";

@Component({
  selector: "app-open-orders-options",
  templateUrl: "./open-orders-options.component.html",
  styleUrls: ["./open-orders-options.component.scss"]
})
export class OpenOrdersOptionsComponent implements OnInit {
  @Input() filters: OpenOrdersFilters;
  @Input() items: OrderLine[];
  @Input() pageData: FinancialPaginatorData;

  get showButtons() {
    if (this.applicationService.getSelectCompanyGroupCode() === "WG") {
      if (
        this.filters.options.company_id == "102" ||
        (!this.applicationService.checkIfDealerHasCompany(101) &&
          this.applicationService.checkIfDealerHasCompany(102))
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (this.applicationService.getSelectCompanyGroupCode() === "GHO") {
      return false;
    }
    return true;
  }

  constructor(private financialService: FinancialService, public applicationService: ApplicationService, private modalService: NgbModal) { }

  ngOnInit() {}

  getExcelFile() {

    // Remove page filter to get all the lines
    if (this.applicationService.getSelectCompanyGroupCode() === "WG") {
      this.filters.options.page = null;
    }

    this.applicationService.showLoader(true);
    const filters = {...this.filters};
    filters.next_page_reference = this.pageData.aceCurrentPage;
    this.financialService
      .getOpenOrdersExcelFile(filters)
      .subscribe((blob: Blob) => {
        this.applicationService.hideLoader();
        saveAs(blob, `openorderlines.xlsx`)
      });
  }

  getPdfFile() {
    this.applicationService.showLoader(true);
    this.financialService
      .getBackOrdersPdfFile()
      .subscribe((blob: Blob) => {
        this.applicationService.hideLoader();
        this.showPdfDialog(blob, 'openorderlines.pdf')
      } );
  }

  showPdfDialog(blob: Blob, fileName: string){
    const modalRef = this.modalService.open(PdfViewerComponent, {
      centered: true,
      size: "lg",
      container: "#modalContainer",
    });
    modalRef.componentInstance.fileName = fileName;
    modalRef.componentInstance.pdfSrc = blob;
    event.preventDefault();
    return false;
  }

  getFilters(data) {}
}
