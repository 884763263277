<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "COMPARE" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    </button>
</div>

<div
  class="modal-body"
  [ngClass]="generating ? 'generating' : ''"
  [style.opacity]="generating ? '0.4' : '1'"
>
  <div class="row">
    <div class="col-md-12 row" *ngIf="compareArticleData.length > 3">
      <ng-container *ngFor="let id of selected; let articleIndex = index">
        <div
          class="d-flex col-lg-4 col-xl-4 col-xs-12 col-sm-6 col-md-6 align-items-stretch"
        >
          <app-select-article
            [availableArticles]="objectArticleData"
            [selected]="selected"
            [id]="id"
            [index]="articleIndex"
            (articleSelected)="articleSelected($event, articleIndex)"
          ></app-select-article>
        </div>
      </ng-container>
    </div>
    <div class="col-md-12" id="comparedArticles">
      <div
        class="articleContainer px-0 mx-0 row col-md-12 h-100"
        *ngIf="compareArticleList.length"
      >
        <ng-container *ngFor="let id of selected; let articleIndex = index">
          <app-article-card
            [article]="objectArticleData[id]"
            *ngIf="objectArticleData[id]"
            [specs]="objectArticleData[id].allSpecs"
            [useLazyLoad]="false"
            (showArticleDetails)="showArticleDetails($event)"
            (removeFromList)="removeFromList($event)"
            class="card d-flex col-lg-4 col-xl-4 col-xs-12 col-sm-6 col-md-6 align-items-stretch"
          ></app-article-card>
        </ng-container>
      </div>
      <div *ngIf="!compareArticleList.length">
        {{ "NOTHING_TO_COMPARE" | translate }}
      </div>
    </div>
  </div>
</div>
<div class="modal-footer" [style.opacity]="generating ? '0.4' : '1'">
  <button
    class="btn btn-danger me-auto"
    [disabled]="!selected.length || generating"
    (click)="empty()"
    *ngIf="selected.length"
  >
    {{ "EMPTY" | translate }}
  </button>
  <button
    class="btn btn-primary"
    *ngIf="showPdfButton"
    [disabled]="!selected.length || generating"
    [ngClass]="!selected.length ? 'disabled' : ''"
    (click)="downloadPdf()"
  >
    PDF
  </button>
  <span
    class="spinner-border spinner-border-sm"
    *ngIf="!showPdfButton && loading"
    role="status"
    aria-hidden="true"
  ></span>
</div>
