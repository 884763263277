<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container">
  <div class="row">
    <div class="px-3 mx-auto col-12 col-md-9 col-lg-6">
      <h1>{{ "MERGE_DEALERS" | translate }}</h1>
      <form>
        <div class="mb-3 form-row">
          <div class="col">
            <label class="fw-bold" for=""
              >{{ "FROM_USER" | translate }}:</label
            >
            <div>
              <input
                type="text"
                class="form-control"
                name="fromDealerId"
                [ngClass]="{ 'is-invalid' : mergeInfo[0] && mergeInfo[0].message !== 'Dealer check ok' }"
                value="Mark"
                [(ngModel)]="fromDealerId"
              />
              <div *ngIf="mergeInfo && mergeInfo.length && mergeInfo[0] && mergeInfo[0].message === 'Dealer check failed, check the details' && mergeInfo[0].details[0] === 'Main user has never logged on'" class="invalid-feedback">
                {{ 'DEALER_ID_NOT_VALID_FOR_MERGE' | translate }} - {{ 'DEALER_NEVER_LOGGED_IN' | translate }}
              </div>
              <div *ngIf="mergeInfo && mergeInfo.length && mergeInfo[0] && mergeInfo[0].message === 'Dealer already merged'" class="invalid-feedback">
                {{ 'DEALER_ID_ALREADY_MERGED' | translate }}
              </div>
              <div *ngIf="mergeInfo && mergeInfo.length && mergeInfo[0] && mergeInfo[0].message === 'Dealer check failed, check the details' && mergeInfo[0].details[0] !== 'Main user has never logged on'" class="invalid-feedback">
                {{ 'DEALER_ID_NOT_VALID_FOR_MERGE' | translate }}
              </div>
              <div class="mt-2 alert alert-danger" role="alert" *ngIf="dealerFromError !== null" [innerHTML]="dealerFromError">
              </div>
            </div>
          </div>
          <div class="col">
            <label class="fw-bold" for=""
              >{{ "TO_USER" | translate }}:</label
            >
            <div>
              <input
                type="text"
                class="form-control"
                [ngClass]="{ 'is-invalid' : mergeInfo[1] && mergeInfo[1].message !== 'Dealer check ok' }"
                name="toDealerId"
                [(ngModel)]="toDealerId"
              />
              <div *ngIf="mergeInfo && mergeInfo.length && mergeInfo[1] && mergeInfo[1].message === 'Dealer already merged'" class="invalid-feedback">
                {{ 'DEALER_ID_ALREADY_MERGED' | translate }}
              </div>
              <div *ngIf="mergeInfo && mergeInfo.length && mergeInfo[1] && mergeInfo[1].message === 'Dealer check failed, check the details' && mergeInfo[1].details[1] !== 'Main user has never logged on'" class="invalid-feedback">
                {{ 'DEALER_ID_NOT_VALID_FOR_MERGE' | translate }}
              </div>
              <div class="mt-2 alert alert-danger" role="alert" *ngIf="dealerToError !== null"  [innerHTML]="dealerToError">
              </div>
            </div>
          </div>
        </div>
        <div class="form-row" *ngIf="!mergeDealersAreBothValid">
          <div class="col">
            <ng-container *ngIf="errorMessage">
              <div class="alert alert-warning" role="alert">
                {{ errorMessage }}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="form-row" *ngIf="mergeDealersAreBothValid">
          <div class="col">
            <ng-container *ngIf="mergeSuccessMessage">
              <div class="alert alert-success" role="alert">
                {{ mergeSuccessMessage }}
              </div>
            </ng-container>
          </div>
        </div>
        <div class="text-center form-row">
          <div class="mt-3 col">
            <input
              type="submit"
              (click)="checkIfDealersAreValid()"
              [value]="'MERGE' | translate"
              [disabled]="!fromDealerId || !toDealerId"
              class="btn btn-primary"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
