import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationService } from '../services/application.service';

@Injectable({
  providedIn: 'root'
})
export class IsCompanyGroupGuard implements CanActivate {
  constructor(
    private router: Router,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const selectedCompanyGroup = next.params["cgc"];
      let companyGroups = next.data['companyGroups'] as Array<string>;
      if (companyGroups.indexOf(selectedCompanyGroup) >= 0) {
        return true;
      } else {
        this.router.navigateByUrl(`${selectedCompanyGroup}/notfound`);
        return false;
      }
  }
}
