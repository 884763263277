import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BasketService } from "src/app/services/basket.service";
import { AlertService } from "src/app/services/alert.service";
import { Article } from "src/app/interfaces/article";
import { ArticleCollectorService } from "src/app/services/article-collector.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { SelectBasketComponent } from "../../general-components/select-basket/select-basket.component";
@Component({
  selector: "app-add-all-modal",
  templateUrl: "./add-all-modal.component.html",
  styleUrls: ["./add-all-modal.component.scss"]
})
export class AddAllModalComponent implements OnInit {
  articles: Article[] = [];
  basketId = "-1";
  adding: boolean;
  subscription: any;
  constructor(
    public modal: NgbActiveModal,
    private basketService: BasketService,
    private alertService: AlertService,
    private articleCollectorService: ArticleCollectorService
  ) {}

  ngOnInit() {}

  save() {
    const articleList = [];
    if (this.articles !== null) {
      for (let i = 0; i < this.articles.length; i++) {
        const article = this.articles[i];
        if (article.authorized_to_place_in_basket && article.quantity > 0) {
          articleList.push({
            quantity: article.quantity,
            remarks: article.remarks,
            sold: article.sold,
            single_article_code: article.single_article_code,
            thumbs: article.thumbs,
            delivery_week: this.parseDeliveryWeek(article),
          });
        }
      }
      this.adding = true;
      this.basketService
        .addArticlesToBasket(articleList, Number(this.basketId))
        .then((responseData: ApiResponse) => {
          this.alertService.showInfoNotification(responseData.result);
          this.modal.close();
          this.articles = null;
          this.articleCollectorService.deselectAll();
        });
    }
  }

  parseDeliveryWeek(article) {
    return this.basketService.parseDeliveryWeek(article);
  }

  createBasket(selectBasket: SelectBasketComponent) {
    selectBasket.newBasket();
    if (!this.subscription) {
      this.subscription = selectBasket.basketSelected.subscribe(() => {
        this.save();
      });
    }
  }
}
