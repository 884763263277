<div
  class="row px-3 py-1"
  [class.wide]="browseGroups?.length > 6"
  (mouseenter)="getPromotions()"
>
  <ng-container *ngFor="let productGroup of browseGroups">
    <div class="col-4 mb-3" [class.col-lg-3]="browseGroups?.length > 6" *ngIf="productGroup.sub_items?.length">
      <h5
        class="mt-0 clickable withOpacity"
        (click)="selectGroup(productGroup.name)"
      >
        {{ productGroup.description }}
      </h5>
      <ul
        class="list-unstyled"
        [class.longlist]="productGroup.sub_items?.length > 5"
      >
        <li
          *ngFor="let articleGroup of productGroup.sub_items"
          class="text-white clickable withOpacity pe-1"
          (click)="selectGroup(productGroup.name, articleGroup.name)"
        >
          {{ articleGroup.description }}
        </li>
      </ul>
    </div>
  </ng-container>
  <div class="col-4 mb-3">
    <h5>{{ "PROMOTIONS" | translate }}</h5>
    <ng-container *ngIf="promotions && !promotions.length && !promotionsLoaded">
      <button class="px-3 py-1 dropdown-item btn">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </ng-container>
    <ul class="list-unstyled" *ngIf="promotions && promotions.length">
      <ng-container *ngFor="let groupedPromotions of promotions">
        <li
          *ngFor="let promotion of groupedPromotions"
          class="text-white clickable withOpacity"
          (click)="
            gotoSearchPage(
              'CMN_PROM&HOMEPROMO=' +
                promotion.promotioncode +
                '&COMPANY=' +
                +promotion.company_id,
              $event
            )
          "
          [innerHTML]="
            promotion.title === '' ? promotion.promotioncode : promotion.title
          "
        ></li>
      </ng-container>
      <li
        class="text-white clickable withOpacity"
        *ngIf="promotionsLoaded"
        (click)="gotoSearchPage('CMN_PROM', $event)"
      >
        {{ "VIEW_ALL" | translate }}
      </li>
    </ul>
  </div>
</div>
