import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-baskets-page",
  templateUrl: "./baskets-page.component.html",
  styleUrls: ["./baskets-page.component.scss"]
})
export class BasketsPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
