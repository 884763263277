import { Component, Input, OnInit } from "@angular/core";
import { ApiResponse } from "src/app/interfaces/api-response";
import { ShippingAddress } from "src/app/interfaces/shipping-address";
import { PickupAddress } from "src/app/interfaces/warranty/pickup-address";
import { DealerService } from "src/app/services/dealer.service";

@Component({
  selector: "app-pickup-address-select",
  templateUrl: "./pickup-address-select.component.html",
  styleUrls: ["./pickup-address-select.component.scss"],
})
export class PickupAddressSelectComponent implements OnInit {
  @Input() addresses: PickupAddress[];
  selectedIndex = 0;
  noAddress = null;
  constructor(private dealerService: DealerService) {}

  ngOnInit() {
    this.addresses = this.addresses.filter(
      (address, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.city === address.city &&
            t.country_code === address.country_code &&
            t.house_number === address.house_number &&
            t.house_number_addition === address.house_number_addition &&
            t.street === address.street &&
            t.zip === address.zip
        )
    );
    if (this.addresses.length > 1) {
      this.selectedIndex = null;
    }
  }

  setAddress() {
    if (String(this.selectedIndex) === "") {
      this.selectedIndex = null
    }
  }
}
