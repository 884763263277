import { Component, OnInit, Input } from "@angular/core";
import { ApplicationService } from "src/app/services/application.service";
import * as appGlobal from "src/app/globals";
import { AccountService } from "src/app/services/account.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  @Input() selectedCompanyGroup: string;
  currentYear: string;
  companies: number[];
  showLinks = true;
  constructor(public applicationService: ApplicationService) {}

  ngOnInit() {
    this.currentYear = String(new Date().getFullYear());
  }

  getLink(id: string, event) {
    let lang = localStorage.getItem("language");
    if (!lang) {
      lang = "en";
    } else {
      lang = lang.toLowerCase();
    }
    event.preventDefault();
    this.applicationService.getLayoutSetting(id).then((url: any) => {
      if (url) {
        url = url.replace("{lang}", lang);
        const formatted = url.replace("www", "shop");
        window.location.href = formatted;
      }
    });
    return false;
  }
}
