import { Component, OnInit } from '@angular/core';
import { ArticleService } from '../../services/article.service';
import { BasketService } from '../../services/basket.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { QuickOrderItem } from 'src/app/interfaces/quick-order-item';
import { AlertService } from 'src/app/services/alert.service';
import { Breadcrumb } from 'src/app/interfaces/bread-crumb';
import { ApplicationService } from 'src/app/services/application.service';
import { SelectBasketComponent } from '../general-components/select-basket/select-basket.component';
import { Article } from 'src/app/interfaces/article';
import { Basket } from 'src/app/interfaces/basket';
import { HelperService } from 'src/app/services/helper.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BarCodeScannerComponent } from '../general-components/bar-code-scanner/bar-code-scanner.component';
import { ModalsService } from 'src/app/modals.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BarcodeScanResult } from 'src/app/interfaces/barcode-scan-result';
import { Router } from '@angular/router';
import { ArticleModalComponent } from '../modal-components/article-modal/article-modal.component';
import { SearchService } from 'src/app/services/search.service';
import { AccountService } from 'src/app/services/account.service';
import { Price } from 'src/app/interfaces/price';

@Component({
  selector: 'app-quick-order',
  templateUrl: './quick-order.component.html',
  styleUrls: ['./quick-order.component.scss'],
})
export class QuickOrderComponent implements OnInit {
  breadcrumbs: Breadcrumb[];

  // The user input text
  public input: string;

  // The list of articles to order / place in basket
  public quickOrderItems: QuickOrderItem[];

  // Indication if the quick order article list is valid
  public validQuickOrder: boolean;

  public basketId: number | string = -1;
  loading: boolean;
  subscription: any;
  isMobile = false;
  isShowButtons = false;
  basketSum: number;
  showroomMode: boolean;
  basketSumNet: number;
  basketSumRetail: number;
  currency: string;

  constructor(
    private articleService: ArticleService,
    private basketService: BasketService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private helperService: HelperService,
    private deviceDetector: DeviceDetectorService,
    private searchService: SearchService,
    private accountService: AccountService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.isMobile = this.deviceDetector.isMobile();
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant('HOME'),
        this.applicationService.getSelectCompanyGroupCode() + '/home'
      ),
      new Breadcrumb(this.translateService.instant('ORDER'), '#'),
      new Breadcrumb(this.translateService.instant('QUICKORDER'), '#'),
    ];
  }

  ngOnInit() {
    this.input = '';
    this.quickOrderItems = Array<QuickOrderItem>();
    this.validQuickOrder = false;

    if (!this.accountService.isHelpdesk()) {
      const saved = localStorage.getItem(
        `quickorder-items-${this.applicationService.getSelectCompanyGroupCode()}`
      );
      if (saved !== null) {
        this.input = JSON.parse(saved);
        this.checkUserInput();
      }
    }

    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );

    this.applicationService.changeEmitted$.subscribe((response) => {
      this.showroomMode = this.applicationService.getShowroomMode(
        this.showroomMode
      );
    });

    this.basketService.changeEmitted$.subscribe((response) => {
      switch (response['type']) {
        case 'deleteArticle':
          const deletedArticle = this.quickOrderItems.findIndex(
            (a) =>
              (a.item_details && a.item_details.id) ===
              response.article.item_details.id
          );
          this.quickOrderItems.splice(deletedArticle, 1);
          break;
      }
    });
  }

  // Checks the user input
  public checkUserInput() {
    // Reset the quick order items
    this.quickOrderItems = [];
    const quickOrderItems: QuickOrderItem[] = this.quickOrderItems;

    const lines = this.input.toString().split('\n');

    // Parse the lines
    for (const line of lines) {
      if (line.length > 0) {
        this.parseLine(line, quickOrderItems);
      }
    }

    this.validQuickOrder = false;

    if (quickOrderItems.length) {
      const qtyAdjustedItems = quickOrderItems.filter((q) => {
        return q.alert === true;
      });

      if (qtyAdjustedItems.length) {
        this.alertService.showErrorNotification(
          this.translateService.instant('AMOUNTS_ADJUSTED')
        );
      }

      // Get the article data
      this.loading = true;
      const searchInput = quickOrderItems.map((a) => a.searchText);
      if (searchInput.length) {
        this.applicationService.showLoader(true);
        this.articleService
          .getArticleByCodes(searchInput)
          .subscribe((response: ApiResponse) => {
            this.loading = false;
            this.applicationService.hideLoader();
            if (response.success) {
              // Parse the response articles
              Object.keys(response.result).map(function (key) {
                const inputItem = quickOrderItems.find(
                  (a) => a.searchText === key
                );

                if (inputItem) {
                  const article = response.result[key] as Article;
                  if (article && article.authorized_to_place_in_basket) {
                    inputItem.item_details = response.result[key];
                  }
                }
              });

              // Validate the articles
              this.validQuickOrder =
                this.validateQuickOrderArticles(quickOrderItems);

              //Not available Articles should be top on the list
              let sortquickOrderItems = this.quickOrderItems.sort(
                ({ unknown: stateA = false }, { unknown: stateB = false }) =>
                  Number(stateB) - Number(stateA)
              );

              this.quickOrderItems = sortquickOrderItems;

              localStorage.setItem(
                `quickorder-items-${this.applicationService.getSelectCompanyGroupCode()}`,
                JSON.stringify(this.input)
              );

              try {
                this.searchService
                  .getNettPrices(this.quickOrderItems)
                  .then(() => {
                    this.calcTotal();
                  });
              } catch (err) {}
            }
          });
      }
    }
  }

  parseVolumePrices(article: QuickOrderItem) {
    if (article.volume_price && article.volume_price.length) {
      const volumePrices = article.volume_price;
      let parsedPrices = {};
      for (const volumePrice of volumePrices) {
        parsedPrices[volumePrice['volume']] = (
          this.articleService.getVolumePrice(volumePrice) as Price
        ).value;
      }
      article.volumePrices = parsedPrices;
    }
  }

  calcTotal() {
    let basketSumNet = 0;
    let basketSumRetail = 0;
    let currency = '';
    let totalNetPrice = 0;

    this.quickOrderItems.forEach((q: QuickOrderItem) => {
      if (q.selected) {
        q.calculated_net_price = undefined;
        q.sum = 0;
        let itemPriceNet = 0;
        let itemPriceRetail = 0;
        const basketQty = q.amount;

        this.parseVolumePrices(q);

        // Check for volume pricing
        if (q.volumePrices && Object.keys(q.volumePrices).length) {
          let quantityRemaining = basketQty;

          Object.keys(q.volumePrices)
            .sort()
            .reverse()
            .forEach((volume) => {
              addToNetPrice(Number(volume), q.volumePrices as any);
              function addToNetPrice(
                volume: number,
                volumePrices: { [key: number]: number }
              ) {
                if (quantityRemaining >= volume) {
                  const volumeOccurrences = Math.floor(
                    quantityRemaining / volume
                  );
                  itemPriceNet = volumePrices[volume];
                  totalNetPrice += itemPriceNet * volume;

                  q.sum += itemPriceNet * volume;
                  quantityRemaining -= volume;
                  if (quantityRemaining >= volume) {
                    addToNetPrice(volume, volumePrices);
                  }
                }
              }
            });

          // Some volume left which is too small for a volume discount -> use normal price
          if (quantityRemaining > 0 && q.net_price?.value) {
            itemPriceNet = q.net_price.value;
            totalNetPrice += itemPriceNet * quantityRemaining;
            q.sum += itemPriceNet * quantityRemaining;
          }

          // || q.amount % divisor === 0
          const keys = Object.keys(q.volumePrices);
          keys.forEach((key) => {
            if (Number(key) <= q.amount) {
              q.calculated_net_price = { ...q.net_price };
              q.calculated_net_price.value = q.volumePrices[key];

              if (Number(key) === q.amount) {
                q.below_volume_amount = false;
              } else {
                q.below_volume_amount = true;
              }
            }
          });

          if (
            Object.keys(q.volumePrices).length >= 1 &&
            Number(Object.keys(q.volumePrices)[0]) > q.amount
          ) {
            q.calculated_net_price = { ...q.net_price };
          }

          // itemPriceNet = totalNetPrice;
        } else {
          // Standard pricing
          if (typeof q.net_price !== 'undefined') {
            itemPriceNet = q.net_price.value;
            q.calculated_net_price = { ...q.net_price };
          }

          totalNetPrice += itemPriceNet * q.amount;
          q.sum = itemPriceNet * q.amount;
        }

        if (typeof q.retail_price !== 'undefined') {
          itemPriceRetail = q.retail_price.value;
          currency = q.retail_price.currency;
        }

        basketSumNet += itemPriceNet * basketQty;
        basketSumRetail += itemPriceRetail * basketQty;
      }
    });

    this.basketSumNet = totalNetPrice;
    this.basketSumRetail = basketSumRetail;
    this.currency = currency;
  }

  // Adds the input line to the selected articles
  private parseLine(line: string, quickOrderItems: QuickOrderItem[]) {
    const splitted = line.split(/[ ,/\t]/);
    if (splitted && splitted.length) {
      // Get the article code
      const searchtext = splitted[0].toUpperCase();

      // Get the amount, if no amount given, set 1 else set the amount to order
      let qty: number;
      const qtyInput =
        splitted.length === 1 ? 1 : splitted[splitted.length - 1];
      // if not numeric then set to 1
      if (isNaN(qtyInput as any)) {
        qty = 1;
      } else {
        qty = Number(qtyInput);
      }

      // If the article already exists, add the amount to the existing item
      const existing = quickOrderItems.find((a) => a.searchText === searchtext);

      if (existing != null) {
        existing.amount += Number(qty);
      } else {
        quickOrderItems.push(
          new QuickOrderItem(
            searchtext,
            qty,
            splitted.length === 3 && splitted[1] !== ''
          )
        );
      }
    }
  }

  // Gets the article data for the input quick order articles
  private validateQuickOrderArticles(quickOrderItems: QuickOrderItem[]) {
    let valid = false;

    for (const quickOrderItem of quickOrderItems) {
      // Check the article data for the quickorder
      const article = quickOrderItem.item_details;
      const setitem = article != null && article.setitem;
      const mandatoryBattery = article != null && article.is_mandatory_item;

      // Add the article data if the article is found and is not an set item, else add the unknown article
      if (article != null && !setitem && !mandatoryBattery) {
        quickOrderItem.single_article_code = article.single_article_code;
        quickOrderItem.description = article.description;
        quickOrderItem.id = article.id;
        quickOrderItem.thumbs = article.thumbs;
        quickOrderItem.caption = article.caption;
        quickOrderItem.delivery_week = this.basketService.parseDeliveryWeek(
          quickOrderItem.item_details
        );
        quickOrderItem.delivery_year = article.stock_year;
        quickOrderItem.description = article.description;
        quickOrderItem.retail_price = article.retail_price;
        quickOrderItem.volume_price = article.volume_price;
        quickOrderItem.company_id = article.company_id;
        quickOrderItem.brand = article.brand;
        quickOrderItem.selected = true;

        if (!article.min_order_amount) {
          article.min_order_amount = 1;
        }

        quickOrderItem.amount =
          quickOrderItem.amount > article.min_order_amount
            ? quickOrderItem.amount
            : article.min_order_amount;

        quickOrderItem.unknown = false;

        if (quickOrderItem.amount > 9999) {
          quickOrderItem.amount = 9999;
        }

        valid = true;
      } else {
        quickOrderItem.single_article_code = quickOrderItem.searchText;

        if (setitem) {
          quickOrderItem.description =
            this.translateService.instant('QUICKORDER_NO_SET');
        } else if (mandatoryBattery) {
          quickOrderItem.description = this.translateService.instant(
            'QUICKORDER_NO_MNDBODT'
          );
          quickOrderItem.item_details = null;
        } else {
          quickOrderItem.description = this.translateService.instant(
            'QUICKORDER_ART_NOT_FOUND'
          );
        }

        quickOrderItem.amount = 0;
        quickOrderItem.unknown = true;
      }
    }
    return valid;
  }

  basketSelected(basketId) {
    this.basketId = basketId;
  }

  addToBasket() {
    this.isShowButtons = false;
    const hasValidItems = this.quickOrderItems.findIndex(
      (a) => a.item_details != null && a.selected
    );
    if (hasValidItems >= 0) {
      this.applicationService.showLoader(true);
      const self = this;
      // Check if there is a valid item in the list

      new Promise((resolve) => {
        if (this.basketId !== -1) {
          resolve(true);
        } else {
          this.basketService
            .createBasket(this.translateService.instant('QUICKORDER'))
            .subscribe((apiResponse: ApiResponse) => {
              if (this.helperService.checkResponse(apiResponse)) {
                this.basketId = (apiResponse.result as Basket).id;
                resolve(true);
              }
            });
        }
      }).then(() => {
        this.loading = true;
        this.basketService
          .addArticlesToBasket(
            this.quickOrderItems.filter((a) => !a.unknown && a.selected),
            this.basketId
          )
          .then((response: ApiResponse) => {
            self.loading = false;
            this.applicationService.hideLoader();
            if (response.success) {
              self.alertService.showPopup(
                response.result.message ?? response.result
              );
              self.input = '';
              self.quickOrderItems = Array<QuickOrderItem>();
              this.isShowButtons = true;
            } else {
              self.alertService.showPopup(
                this.translateService.instant('ERROR'),
                'error'
              );
            }
            localStorage.removeItem(
              `quickorder-items-${this.applicationService.getSelectCompanyGroupCode()}`
            );
          });
      });
    }
  }

  getNumberOfLines() {
    return !this.input.length
      ? '0'
      : String(this.input.split(/\r|\r\n|\n/).length);
  }

  createBasket(selectBasket: SelectBasketComponent) {
    selectBasket.newBasket();
    if (!this.subscription) {
      this.subscription = selectBasket.basketSelected.subscribe(() => {
        this.addToBasket();
      });
    }
  }

  deleteItem(index) {
    this.quickOrderItems.splice(index, 1);
  }

  scanBarcode() {
    const modalRef = this.modalService.open(BarCodeScannerComponent, {
      centered: true,
      container: '#modalContainer',
      backdrop: 'static',
    });
    modalRef.componentInstance.autoClose = false;
    modalRef.componentInstance.articleSelected$.subscribe(
      (addData: BarcodeScanResult) => {
        if (addData.article_code) {
          this.input +=
            (this.input ? '\n' : '') +
            addData.article_code +
            `/ ${addData.quantity}`;
          modalRef.componentInstance.qty = 1;
          this.alertService.showInfoNotification(
            this.translateService.instant('ARTICLE_ADDED')
          );
        }
      }
    );
    modalRef.closed.subscribe(() => {
      modalRef.componentInstance.barcodeScanner.stop();
    });
  }

  checkQty(article: QuickOrderItem) {
    if (!article.amount) {
      article.amount = article.item_details.min_order_amount
        ? article.item_details.min_order_amount
        : 1;
    } else if (article.amount > 999) {
      article.amount = 999;
    }

    this.calcTotal();
  }

  openModal(article: QuickOrderItem) {
    const modalRef = this.modalService.open(ArticleModalComponent, {
      size: 'lg',
      container: '#modalContainer',
    });
    modalRef.componentInstance.article = article;
    modalRef.componentInstance.modelId = article.id;
    modalRef.componentInstance.showDetailed = true;
    modalRef.componentInstance.showInModal = true;
  }

  goToBasket() {
    this.router.navigateByUrl(
      `/${this.applicationService.getSelectCompanyGroupCode()}/basket/${
        this.basketId
      }?basePage=baskets`
    );
  }

  goToCheckout() {
    this.router.navigateByUrl(
      `/${this.applicationService.getSelectCompanyGroupCode()}/checkout/${
        this.basketId
      }`
    );
  }
}
