import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-leave-kiosk-mode-modal",
  templateUrl: "./leave-kiosk-mode-modal.component.html",
  styleUrls: ["./leave-kiosk-mode-modal.component.scss"],
})
export class LeaveKioskModeModalComponent implements OnInit {
  @Output() onExitKioskMode = new EventEmitter<boolean>();
  @Output() kioskCheckerActive = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit() {}

  disableKioskMode() {
    this.kioskCheckerActive.emit(false);
  }
}
