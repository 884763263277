<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'SEARCH'|translate}} ({{'PACKINGSLIP'|translate}})</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
    </button>
</div>

<div class="modal-body p-3">
  <div class="row m-3">
    <div class="mb-3 row col-12 col-xs-6">
      <label class="col-sm-4 col-form-label">{{'PACKINGSLIP_NUMBER'|translate}} / {{'ARTICLE_NO'|translate}}</label>
      <div class="col-sm-7">
        <div class="input-group">
          <input type="text" class="form-control" id="packingSlipSearch" [(ngModel)]="articleCode" (keyup)="startSearch($event)">
          <div class="input-group-append">
            <button class="btn btn-dark search" (click)="searchByArticleCode()"><i class="material-icons">search</i></button>
          </div>
        </div>
      </div>
    </div>


    <table class="table table-striped" *ngIf="packingSlips && packingSlips.length">
      <tr *ngFor="let packingSlip of packingSlips">
        <td>
          <span class="d-block d-sm-none pe-1">{{'PACKINGSLIP_NUMBER'|translate}}: </span>
          {{packingSlip.packing_slip_number}}
        </td>
        <td class="description">
          {{packingSlip.shipment}}
        </td>
        <td>
          {{'ORDERNUMBER' | translate}}: {{packingSlip.ace_id}}
          <br />
        </td>
        <td>
          {{packingSlip.order_status_text}}
        </td>
        <td>
          {{packingSlip.shipment_date}}
        </td>
        <td>
          {{packingSlip | shippingAddressText}}
        </td>
        <td>
          <button class="btn btn-sm btn-primary mb-sm-0 mb-2 text-white"
            (click)="select(packingSlip.packing_slip_number, packingSlip.shipment)">{{'SELECT'|translate}}</button>
        </td>
      </tr>
    </table>
    <ngb-alert class="w-100 m-2" *ngIf="packingSlips && !packingSlips.length" [dismissible]="false">
      {{'GETTING_PACKINGSLIP_FAILURE'|translate}}
    </ngb-alert>
    <button class="btn btn-block btn-primary" *ngIf="hasNextPage"
      (click)="getPackingSlips(false)">{{'SHOW_MORE'|translate}}</button>
  </div>
</div>
