import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-select-basket-type',
  templateUrl: './select-basket-type.component.html',
  styleUrls: ['./select-basket-type.component.scss'],
})
export class SelectBasketTypeComponent implements OnInit {
  @Output() tabChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() type = '';
  companyGroupCode = '';
  constructor(
    private applicationService: ApplicationService,
    private location: Location
  ) {}

  ngOnInit() {
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
  }

  tabChange(data) {
    this.location.go(
      this.applicationService.getSelectCompanyGroupCode() +
        '/baskets/' +
        data.nextId
    );
    this.tabChanged.emit(data.nextId);
  }
}
