<h2>{{'BIKE_DETAILS' | translate}}</h2>

<div class="col-md-12 px-0">
  <form [ngClass]="validated ? 'validated' : ''" ngNativeValidate>
    <div class="form-row">
      <div class="col-md-6 mb-2">

        <label class="fw-bold" for="">{{'FRAMENUMBER' | translate}}</label>
        <div [formGroup]="formGroup">
          <input  type="text"
                  class="form-control"
                  name="frame_number"
                  [formControlName]="'frame_number'"
                  pattern=".{9,9}"
                  required [title]="'9' | minMaxValidationTitle: '9'"
                  (keydown)="resetValidation()">
        </div>
      </div>
      <div class="col-md-6">
        <label class="fw-bold" for="">{{'ORDERNUMBER' | translate}}</label>
        <div [formGroup]="formGroup">
          <input  type="text"
                  class="form-control"
                  name="order_number"
                  [formControlName]="'order_number'"
                  pattern=".{7,9}"
                  required [title]="'7' | minMaxValidationTitle: '9'"
                  (keydown)="resetValidation()">
        </div>
      </div>
    </div>
    <ngb-alert *ngIf="registrationResponse" [dismissible]="false" [type]="registrationResponse" class="mt-3">
      {{ (registrationResponse === 'success' ? 'PC_LOTTERY_SEND_SUCCESS' : 'PC_LOTTERY_ORDERNUMBER_INCORRECT') | translate}}
    </ngb-alert>
    <input type="submit" [value]="'SEND' | translate" class="btn btn-primary mt-3" (click)="submit()">
  </form>
</div>
