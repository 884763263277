export interface StockweekFilter {
  from_week: number;
  from_year: number;
  to_year: number;
  to_week: number;
}

export class StockweekFilter implements StockweekFilter {
  constructor() {

  }
}
