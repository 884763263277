export interface InvoicesFilterOptions {
  keyWordOptions: any[];
  invoiceType: any[];
}

export class InvoicesFilterOptions implements InvoicesFilterOptions {
  constructor() {
    this.keyWordOptions = [
      {
        value: "invoicenumber",
        description: "INVOICE_NUMBER"
      },
      {
        value: "accentry_id",
        description: "ORDERNUMBER"
      }
    ];

    this.invoiceType = [
      {
        value: "All",
        description: "ALL"
      },
      {
        value: "O",
        description: "UNPAID"
      },
      {
        value: "B",
        description: "PAID"
      }
    ];
  }
}
