import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Article } from "src/app/interfaces/article";
import { StockNotification } from "src/app/interfaces/stockNotifications/stock-notification";
import { ApplicationService } from "src/app/services/application.service";
import { ArticleService } from "src/app/services/article.service";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-stock-notification",
  templateUrl: "./stock-notification.component.html",
  styleUrls: ["./stock-notification.component.scss"],
})
export class StockNotificationComponent implements OnInit {
  @Input() notificationData: StockNotification;
  @Output() closeNotification: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  article: Article;
  showNotification: boolean;
  constructor(
    private applicationService: ApplicationService,
    private router: Router,
    private articleService: ArticleService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    //this.getArticleData();
  }

  getArticleData() {
    this.articleService
      .getStockNotificationArticleData(
        this.notificationData.article_code,
        this.notificationData.company_group_code
      )
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.article = apiResponse.result;
          this.notificationData.article_available = true;
          this.showNotification = true;
        } else {
          this.closeNotification.emit(true);
        }
      });
  }

  gotoItem() {
    this.router.navigateByUrl(
      `/${this.notificationData.company_group_code}/articleDetails/${this.article.id}/${this.article.single_article_code}`
    );
    this.closeNotification.emit(true);
  }

  close() {
    this.closeNotification.emit(true);
  }
}
