import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../interfaces/api-response';
import { StorageTypes } from '../interfaces/helpers';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private salesForceDomain = 'force.com';
  private urlWhitelist = [this.salesForceDomain];

  public emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public isBatteryOrMotorPrice(price, currency) {
    switch (currency) {
      case 'GBP': {
        if (price > 286) {
          return true;
        }
        break;
      }
      case 'DKK': {
        if (price > 2800) {
          return true;
        }
        break;
      }
      case 'HUF': {
        if (price > 132375) {
          return true;
        }
        break;
      }
      case 'PLN': {
        if (price > 1688) {
          return true;
        }
        break;
      }
      case 'SEK': {
        if (price > 3750) {
          return true;
        }
        break;
      }
      default: {
        if (price > 375) {
          return true;
        }
        break;
      }
    }
    return false;
  }

  constructor() {}

  checkResponse(apiResponse: ApiResponse) {
    return apiResponse && apiResponse.success;
  }

  // Get current url
  getCurrentPath() {
    return window.location.href.replace(window.location.origin, '');
  }

  getStorageItem(key: string, defaultValue?: any) {
    let parsedValued = '';
    try {
      parsedValued = localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key))
        : defaultValue;
    } catch (error) {
      parsedValued = defaultValue ? defaultValue : null;
      Bugsnag.notify(error);
      Bugsnag.notify(`Storage value: ${localStorage.getItem(key)}`);
    }
    return parsedValued;
  }

  getValue(value) {
    return typeof value !== 'undefined' ? value : '';
  }

  urlEncode(urlValue: string) {
    return typeof urlValue !== 'undefined'
      ? encodeURI(urlValue.replace(/\//g, '_').replace(/ /g, '_'))
      : '';
  }

  fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      return successful;
    } catch (err) {
      return false;
    }

    document.body.removeChild(textArea);
  }

  copyTextToClipboard(text) {
    return new Promise((resolve) => {
      if (!navigator.clipboard) {
        resolve(this.fallbackCopyTextToClipboard(text));
        return;
      }
      navigator.clipboard.writeText(text).then(
        function () {
          resolve(true);
          console.log('Async: Copying to clipboard was successful!');
        },
        function (err) {
          resolve(false);
        }
      );
    });
  }

  hasSalesForceRedirect(url: string = '') {
    return url.includes('serviceportal=true');
  }

  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  // Redirect to external site when on whitelist
  externalRedirect(url: string) {
    let validUrl = false;
    this.urlWhitelist.forEach((u) => {
      if (url.includes(u)) {
        validUrl = true;
      }
    });
    if (validUrl) {
      return url;
    } else {
      return window.location.origin;
    }
  }

  formatDate(value: string) {
    return moment(value, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm');
  }

  formatDateForDateInput(value: string) {
    return moment(value, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
  }

  YYYYWWtoWWYYYY(yearWeek: string) {
    return `${yearWeek.substring(4, 6)}-${yearWeek.substring(0, 4)}`;
  }

  nl2br(text: string) {
    if (text) {
      return text.replace(/(\r\n|\n\r|\r|\n)/g, '<br/>');
    } else {
      return '';
    }
  }

  parseJsonFromStorage(key: string, type: StorageTypes) {
    let result = [];
    try {
      result = JSON.parse(
        type === StorageTypes.localstorage
          ? localStorage.getItem(key)
          : sessionStorage.getItem(key)
      );
      if (result === null) {
        result = [];
      }
      return result;
    } catch (e) {
      if (type === StorageTypes.localstorage) {
        localStorage.removeItem(key);
      } else {
        sessionStorage.removeItem(key);
      }

      return result;
    }
  }

  getLocalDateTime(unformattedDateTime: string) {
    moment.locale(localStorage.getItem('language'));
    return moment(unformattedDateTime).local().format('ddd DD MMM HH:mm');
  }

  getLogisticsCompany(url) {
    // Regular expressions to match against URLs of known logistics companies
    const dhlRegex = /dhl/i;
    const upsRegex = /ups/i;
    const fedexRegex = /fedex/i;
    const glsRegex = /gls/i;
    const postnlRegex = /postnl/i;
    const mikropakketRegex = /mikro/i;
    const idsRegex = /ids/i;
    const mainFreightRegex = /datencubus/i;
    const westermanRegex = /transpasonline/i;
    const seurRegex = /seur/i;
    const dxTrackRegex = /dx-track/i;
    const edestiRegex = /edesti/i;
    const tntRegex = /tnt/i;

    // Check if the URL matches any of the known patterns
    if (dhlRegex.test(url)) {
      return 'DHL';
    } else if (upsRegex.test(url)) {
      return 'UPS';
    } else if (fedexRegex.test(url)) {
      return 'FedEx';
    } else if (glsRegex.test(url)) {
      return 'GLS';
    } else if (postnlRegex.test(url)) {
      return 'PostNL';
    } else if (mikropakketRegex.test(url)) {
      return 'MikroPakket';
    } else if (idsRegex.test(url)) {
      return 'Ids Logistic';
    } else if (westermanRegex.test(url)) {
      return 'Westerman';
    } else if (dxTrackRegex.test(url)) {
      return 'DX Track';
    } else if (seurRegex.test(url)) {
      return 'Seur';
    } else if (edestiRegex.test(url)) {
      return 'Edesti';
    } else if (tntRegex.test(url)) {
      return 'TNT';
    } else if (mainFreightRegex.test(url)) {
      return 'Mainfreight Forwarding';
    } else {
      return ''; // If none of the known companies match
    }
  }

  public getFileType(fileName: string): string {
    const extension = fileName.split('.').pop();
    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      default:
        return 'application/octet-stream';
    }
  }

  public getInvalidFields(formGroup: FormGroup) {
    const invalid = [];
    const controls = formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    if (environment.name === "development") {
      console.log(invalid)
    }
  }
}
