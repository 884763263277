export interface PackingSlipsOptionInput {
  company_id: string;
  part_type: string;
  packing_slip_number: string;
  company_id_filter: number;
  shipping_id: string;
  shipping_type: string;
  article_type: string;
  order_by: string;
  only_new_order_lines: boolean;
  delivery_week: string;
  sold: boolean;
  workshop: boolean;
  article_code: string;
  remarks: string;
  date_from: string;
  date_to: string;
  accentry_id: string;
  ace_id: string;
  article_description: string;
  invoice_type: string;
}

export class PackingSlipsOptionInput {}
