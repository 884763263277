import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { AvailableAgreementTypes } from "src/app/interfaces/yearAgreement/available-agreement-types";
import { YearAgreementService } from "src/app/services/year-agreement.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import {
  AgreementOption,
  AgreementOptionSubGroup,
} from "src/app/interfaces/yearAgreement/agreement-option";
import * as _ from "underscore";
import { AccountAgreement } from "src/app/interfaces/yearAgreement/account-agreement";
import { HelperService } from "src/app/services/helper.service";
import { YearAgreementDataModel } from "src/app/interfaces/yearAgreement/year-agreement-data-model";

@Component({
  selector: "app-year-agreement-tab",
  templateUrl: "./year-agreement-tab.component.html",
  styleUrls: ["./year-agreement-tab.component.scss"],
})
export class YearAgreementTabComponent implements OnInit {
  @Input() type: string;
  @Input() accountAgreement: AccountAgreement;
  @Input() selectedTab: string;
  @Input() yearAgreementModel: YearAgreementDataModel;
  @Input() onlyAffiliateOption: boolean;
  @Input() affiliate: string;
  @Input() isMain: boolean = false;
  @Input() parentClass = "";
  @Input() cardClass = "col-sm";
  @Input() showMarginButton: boolean = true;
  @Input() showCalculation: boolean = true;
  @Input() totalTurnover: number;
  options: AgreementOption[];
  percentages = {};
  totalPercentage: string;
  totalPercentageWithoutBonus: string;

  get allAffiliates() {
    return this.yearAgreementModel && this.yearAgreementModel.affiliates
      ? this.yearAgreementModel.affiliates.split(",")
      : [];
  }

  get mainAffiliate() {
    return this.allAffiliates && this.allAffiliates.length
      ? this.allAffiliates[0]
      : "0";
  }

  get selectedAffiliate() {
    return this.affiliate ? this.affiliate : this.mainAffiliate;
  }

  get modelKey() {
    if (this.type !== AvailableAgreementTypes.Dealer) {
      return "";
    } else {
      return this.affiliate ? this.affiliate : "";
    }
  }

  constructor(
    private yearAgreementService: YearAgreementService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.getGroups();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes["selectedTab"] &&
      changes["selectedTab"].currentValue === this.type
    ) {
      this.getGroups();
      this.calcPercentage();
    }
  }

  // Set the model with saved options
  setSelection() {
    if (typeof this.yearAgreementModel[this.type] !== "undefined") {
      ;
      let totalPercentage = 0;

      const selected = _.pluck(
        this.accountAgreement.options_selection.filter((o) => {
          return (
            o.affiliate === this.selectedAffiliate ||
            (this.isMain && o.affiliate === "")
          );
        }),
        "id"
      );

      this.options.forEach((o) => {
        if (
          !this.yearAgreementModel[this.type]["percentages"][o.sub_group_type]
        ) {
          this.yearAgreementModel[this.type]["percentages"][o.sub_group_type] = 0;
          this.yearAgreementModel[this.type]["section_percentages"][
            o.sub_group_type
          ] = {};
          o.sub_group_options.forEach((s) => {
            s.options.forEach((x) => {
              if (selected.indexOf(x.id) >= 0) {
                this.yearAgreementModel[this.type]["selected"][
                  s.options[0].option_type + this.modelKey
                ] = s.options.length > 1 ? x.option_value : true;

                this.yearAgreementModel[this.type]["percentages"][
                  o.sub_group_type
                ] += x.percentage;

                this.yearAgreementModel[this.type]["section_percentages"][
                  o.sub_group_type
                ][x.option_type] = x.percentage;

                totalPercentage += x.percentage;
              }
            });
          });
        }
      });

      this.totalPercentage = this._formatTotal(totalPercentage);
    }
  }

  // Get the available options for the inputs
  getGroups() {
    this.yearAgreementModel.disabled = true;
    this.yearAgreementService
      .getGroups(this.type as AvailableAgreementTypes, this.accountAgreement.year)
      .then((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          const options = apiResponse.result as AgreementOption[];
          if (!this.onlyAffiliateOption) {
            this.options = options;
          } else {
            this.options = options.filter((o) => {
              return o.sub_group_type === "Turnover";
            });
          }

          // Set readonly fields on true
          options.filter((f) => {
            f.sub_group_options.filter((s) => {
              s.options.filter((x) => {
                if (x.read_only) {
                  this.accountAgreement.options_selection.push({
                    affiliate: this.mainAffiliate,
                    id: x.id,
                  });
                }
              });
            });
          });

          if (typeof this.yearAgreementModel[this.type] !== "undefined") {
            this.yearAgreementModel[this.type]["loaded"] = true;
          }
          this.setSelection();
        }
        this.yearAgreementModel.disabled = false;
      });
  }

  // Calcpercentage
  calcPercentage() {
    // Cap turnover brands: <= 6%
    const turnoverEl = Array.from(
      document.getElementsByClassName("brands_Turnover")
    );
    if (
      this.selectedTab === "brands" &&
      this.yearAgreementModel["brands"]["percentages"]["Turnover"] < 6
    ) {
      turnoverEl.forEach((el: HTMLElement) => {
        el.style.display = "";
      });
    }

    if (
      this.selectedTab === "brands" &&
      this.yearAgreementModel["brands"]["percentages"]["Turnover"] > 6
    ) {
      this.yearAgreementModel["brands"]["percentages"]["Turnover"] = 6;
      turnoverEl.forEach((el: HTMLElement) => {
        el.style.display = "none";
      });
    }

    const totalPercentage = _.reduce(
      Object.values(this.yearAgreementModel[this.type]["percentages"]),
      function (memo: number, num: number) {
        return memo + num;
      },
      0
    );

    let bonusOnly = 0;
    if (this.yearAgreementModel[this.type]["section_percentages"]["Bonus"]) {
      bonusOnly = _.reduce(
        Object.values(
          this.yearAgreementModel[this.type]["section_percentages"]["Bonus"]
        ),
        function (memo: number, num: number) {
          return memo + num;
        },
        0
      );
    }
    let bonusTotal = 0;
    const totalWithoutBonus =
      totalPercentage - bonusOnly > 32 && this.type === "brands"
        ? 32
        : totalPercentage - bonusOnly;

    bonusTotal =
      (100 - totalWithoutBonus) * (bonusOnly / 100) + totalWithoutBonus;

    this.totalPercentageWithoutBonus = this._formatTotal(totalWithoutBonus);

    this.totalPercentage = this._formatTotal(bonusTotal);
  }

  _formatTotal(value) {
    return String(this.roundDown(value, 1)).replace(".", ",").replace(",0", "");
  }

  roundDown(number, decimals) {
    decimals = decimals || 0;
    return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }
}
