<div class="modal-header d-flex align-items-center">
  <h4 class="modal-title" class="d-flex align-items-center mb-0"><i class="material-icons basket-icon light me-2"> shopping_basket </i>{{'ADD_TO_BASKET' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
  </button>
</div>
<div class="px-0 pt-0 modal-body">
  <div
    [ngClass]="article.cross_sell_ecommerce || article.mandatory_battery || article.has_flex_batt  ? 'row hasCrossSell mx-0' : ''">
    <div id="mainArticleContainer" class="p-3" *ngIf="!onlyMandatory"
      [ngClass]="article.cross_sell_ecommerce || article.mandatory_battery || article.has_flex_batt  ? 'mainArticleContainer' : ''">
      <app-article-card [article]="article" [useLazyLoad]="false" class="w-100 d-inline-block mainArticle"
        (articleSelected)="articleSelected($event);" (articleCodeSelected)="articleCodeSelected($event)" [ngClass]="highlight ? 'highlight' : ''"></app-article-card>
      <div class="form-row">
        <div class="mb-3 col-md-12">
          <label class="fw-bold">{{'REMARKS' | translate }}</label>
          <input type="text" class="form-control" maxlength="100" [(ngModel)]="article['remarks']">
        </div>
      </div>
      <div class="form-row row">
        <div class="col-md-12" *ngIf="article.volume_price && !article.hide_nett_price" class="d-inline-block">
          <app-volume-prices
            [headerLeft]="'QUANTITY'"
            [headerRight]="'PRICE'"
            [popoverTitle]="'VOLUME_PRICES'"
            [price]="article.volume_price"
            [regularPrice]="article.net_price"
          ></app-volume-prices>
        </div>
        <div class="mb-3 col-md-3 col-4">
          <label class="fw-bold">{{'QUANTITY' | translate }}</label>
          <p-inputNumber inputId="integeronly" name="quantity" [(ngModel)]="quantity" ngDefaultControl [showButtons]="true" [inputStyleClass]="'w-100 py-0'" [min]="article.min_order_amount ? article.min_order_amount : 1" max="9999" (onInput)="quantityChanged()"> </p-inputNumber>
        </div>
        <div class="pe-2 mb-3 col-md-6 col-6" *ngIf="article.company_id != '4' && article.company_id != '44' && article.single_article_code && showDealerOptions">
          <label class="fw-bold">{{'DELIVERY_WEEK' | translate }}</label>
          <app-select-week #selectWeek [article]="article" [deliveryWeek]="parsedDeliveryWeek" class="d-block"
            (weekSelected)="weekSelected($event)"></app-select-week>
        </div>
        <div class="mb-3 col-md-2 d-flex flex-column justify-content-around" *ngIf="!applicationService.isPartnerPortal()">
          <label class="fw-bold">{{'SOLD' | translate }}</label>

          <p-inputSwitch class="mt-1" [(ngModel)]="article.sold"></p-inputSwitch>
        </div>

        <div class="mb-3 col-md-9">
          <label class="fw-bold">{{'BASKET' | translate }}</label>
          <app-select-basket [basket]="basket" (basketSelected)="basket = $event"></app-select-basket>
        </div>

      </div>
    </div>
    <ng-template [ngIf]="detailsLoaded">
      <div class="col-md-12" *ngIf="mainArticle">
        <div class="articleContainer row h-100" id="mainPreorderArticle" style="padding: 10px">
        <h3>{{"SELECTED_BICYCLE"|translate}}</h3>
        <app-preorder-container class="w-100 selectedPreorderMainArticle" [articles]="mainArticle" [hideSaveButton]="true" [selectedArticles]="selectedArticles"></app-preorder-container>
        </div>
      </div>
      <div #ecommercecontainer
        [ngClass]="(article.cross_sell_ecommerce || article.mandatory_battery || article.has_flex_batt) && !onlyMandatory ? 'col-md-7 additionalArticles' : (onlyMandatory ? 'col-md-12' : '')"
        [style.display]="(article.cross_sell_ecommerce || article.mandatory_battery || article.has_flex_batt) ? '' : 'none'">
        <div class="form-row" [class.preorder]="mainArticle">
          <app-ecommerce-articles #ecommercearticles class="w-100" [ts]="ts" (noResults)="noEcommerceArticles($event);"
            [mainArticle]="article" [type]="'crosssell'" [showHeader]="true" [shopOption]="true"
            [quantityMainArticle]="!onlyMandatory ? quantity : totalQtyPreorderItems" (qtyChanged)="getTotalPreorderQuantity()" [required]="article.mandatory_battery" [onlyMandatory]="onlyMandatory"
            [header]="(article.mandatory_battery ? 'MANDATORY_ITEMS' : 'CROSS_SELL_HEADER') | translate"
            (selectForBasket)="selectForBasket($event)" (batteriesLoaded)="calcTotal()"></app-ecommerce-articles>
        </div>
      </div>
    </ng-template>

  </div>
</div>
<div class="modal-footer" (mouseenter)="highlightMatrix(true)" (mouseleave)="highlightMatrix(false)">
  <div class="d-flex align-items-center p-0 col-12 justify-content-between">
    <h3 class="d-inline-block ps-2" *ngIf="sumNetPrice && sumNetPrice.value > 0">{{ 'TOTAL' | translate }}
      <app-article-sum [netPrice]="sumNetPrice" [retailPrice]="sumRetailPrice" [amount]="1"></app-article-sum>
    </h3>
    <button *ngIf="!loading && onlyMandatory" [ngClass]="quantity !== totalQtyPreorderItems || !article.delivery_week ? 'disabled btn-default' : 'btn-success'" [disabled]="quantity !== totalQtyPreorderItems" class="ms-auto btn btn-lg"
      (click)="savePreorder()">{{'ADD'| translate}}</button>
    <button *ngIf="!loading && !onlyMandatory" class="ms-auto btn btn-lg"
    [ngClass]="[(!article.single_article_code && !onlyMandatory) || article.out_of_delivery || !article.authorized_to_place_in_basket || !mandatoryValid || quantity === 0 || disableAdding ? 'disabled btn-default' : 'btn-success','btn-' + size]"
    [disabled]="article.out_of_delivery || !article.delivery_week || (!article.single_article_code && !onlyMandatory) || !article.authorized_to_place_in_basket || !mandatoryValid || quantity === 0 || disableAdding ? 'disabled' : ''"
      (click)="save()">{{
      'ADD'
      | translate}}</button>
    <div class=" spinner-border text-primary" role="status" *ngIf="loading">
      <span class="visually-hidden">{{'LOADING'|translate}}</span>
    </div>
  </div>
</div>
