import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  AgreementOptionSubGroup,
  AgreementOptionSubGroupCollection,
} from 'src/app/interfaces/yearAgreement/agreement-option';
import * as _ from 'underscore';
import { YearAgreementDataModel } from 'src/app/interfaces/yearAgreement/year-agreement-data-model';
import { YearAgreementService } from 'src/app/services/year-agreement.service';
import { PurchaseDistributionComponent } from '../../purchase-distribution/purchase-distribution.component';
import { HelpdeskService } from 'src/app/services/helpdesk.service';
import { User } from 'src/app/interfaces/user';
import { AccountAgreement } from 'src/app/interfaces/yearAgreement/account-agreement';

@Component({
  selector: "app-year-agreement-tab-section",
  templateUrl: "./year-agreement-tab-section.component.html",
  styleUrls: ["./year-agreement-tab-section.component.scss"],
})
export class YearAgreementTabSectionComponent implements OnInit {
  @Input() sectionData: AgreementOptionSubGroupCollection[];
  @Input() accountAgreement: AccountAgreement;
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() yearAgreementModel: YearAgreementDataModel;
  @Input() onlyAffiliateOption: boolean;
  @Input() showCalculation: boolean;
  @Input() id: string;
  @Input() subGroupId: string;
  @Input() affiliate: string;
  @Input() isMain: boolean = false;

  sectionPercentage: number;
  turnoverBrands: string;
  showExplanation: boolean;
  selected = {};
  tooltipLabels = {
    MINIMALTURNOVER: "YA_MINIMALTURNOVER",
    PLANNED: "YA_PLANNED",
    REALISATIONBONUS: "YA_REALISATIONBONUS",
  };
  selectedDealer: User;

  get allAffiliates() {
    return this.yearAgreementModel && this.yearAgreementModel.affiliates
      ? this.yearAgreementModel.affiliates.split(",")
      : [];
  }

  get mainAffiliate() {
    return this.allAffiliates && this.allAffiliates.length ? 0 : 0;
  }

  get modelKey() {
    if (this.id !== "dealer") {
      return "";
    } else {
      return this.affiliate ? this.affiliate : "";
    }
  }

  get mainAffiliateKey() {
    return ""
  }

  get affiliatesWithoutMain() {
    return this.yearAgreementModel &&
      this.yearAgreementModel.affiliates &&
      this.yearAgreementModel.affiliates.length
      ? _.without(this.allAffiliates, String(this.mainAffiliate))
      : [];
  }



  constructor(
    private yearAgreementService: YearAgreementService,
    private helpdeskService: HelpdeskService
  ) {}

  ngOnInit() {
    this.selectedDealer = this.helpdeskService.getSelectedDealer();
  }

  
  /**
   * This method handles the change event for a specific index and type.
   * It emits a selectionChanged event based on the selected value and type.
   * It also updates certain properties based on the type and id conditions.
   * @param index The index of the section data.
   * @param type The type of the selected option.
   */
  change(index: number, type) {
    const value = this.yearAgreementModel[this.id]["selected"][type];
    this.sectionData[index].options.forEach((element) => {
      if (
        (type === element.option_type && element.option_value === value) ||
        element.option_value === "yes" ||
        element.option_value === "no"
      ) {
        this.selectionChanged.emit({
          type: type,
          percentage: value ? element.percentage : 0,
        });
      }
    });

    if (type === "RealisationBonus") {
      this.showExplanation = value;
    }
    if (this.id === "brands" && type === "DealerType") {
      this.yearAgreementModel[this.id]["selected"]["DealerTypeBonus"] = value;
      this.yearAgreementService.dealerTypeChange(value);
    }

    if (this.id === "brands" && type === "TurnoverBrands") {
      this.yearAgreementModel[this.id]["manualTurnover"] = 0;
    }

    if (type === "ShareStock") {
      this.yearAgreementModel[this.id]["selected"]["AccentryXl"] = false;
      this.selectionChanged.emit({
        type: "AccentryXl",
        percentage: 0,
      });
    }

    this.yearAgreementModel.showMargins = false;
    this.yearAgreementService.turnoverChange();
  }

  /**
   * This method calculates and returns the percentage value based on the type and index provided.
   * If the type is 'TurnoverBrands' and 'cmDealer' is true, it returns the highest percentage value.
   * Otherwise, it calculates the percentage based on the selected value in the options.
   * @param index The index of the section data.
   * @param type The type of the selected option.
   * @returns The calculated percentage value.
   */
  getPerc(index: number, type: string) {
    if (type === "TurnoverBrands" && this.yearAgreementModel["cmDealer"]) {
      const options = _.sortBy(this.sectionData[index].options, "percentage");

      return options[options.length - 1].percentage;
    } else {
      const value = this.yearAgreementModel[this.id]["selected"][type];
      let percentage = 0;
      this.sectionData[index].options.forEach((element) => {
        if (type === element.option_type && element.option_value === value) {
          percentage = value ? element.percentage : 0;
        }
      });

      return percentage;
    }
  }

  getAffiliateSelected(id, affiliate) {
    const selected = this.accountAgreement.options_selection.filter((o) => {
      return o.id === id && o.affiliate === affiliate;
    });
    return selected.length ? true : false;
  }

  saveCMTurnover() {
    localStorage.setItem(
      `${this.selectedDealer.account_code}_cm_turnover`,
      String(this.yearAgreementModel.dummyTurnover)
    );
  }

  accentryXlDisabled(key: string, model: string) {
    if (key === "AccentryXl") {
      if (model) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
