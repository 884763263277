<div>
  <nav
    ngbNav
    #nav="ngbNav"
    justify="fill"
    [activeId]="type"
    (navChange)="tabChange($event)"
    class="nav-pills"
  >
    <ng-container [ngbNavItem]="'openorders'">
      <a ngbNavLink>
        {{ "OPENORDERS" | translate }}
      </a>
    </ng-container>
    <ng-container [ngbNavItem]="'invoices'">
      <a ngbNavLink>
        {{ "INVOICES" | translate }}
      </a>
    </ng-container>
    <ng-container [ngbNavItem]="'packingslips'">
      <a ngbNavLink>
        {{ "PACKINGSLIPS" | translate }}
      </a>
    </ng-container>
    <ng-container
      [ngbNavItem]="'balance'"
      *ngIf="applicationService.getSelectCompanyGroupCode() === 'WG'"
    >
      <a ngbNavLink>
        {{ "BALANCE_LABEL" | translate }}
      </a>
    </ng-container>
    <ng-container
      [ngbNavItem]="'sent_orders'"
    >
      <a ngbNavLink>
        {{ "SENT_ORDERS" | translate }}
      </a>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
