import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { ShippingCostCategory } from "src/app/interfaces/basket";

@Component({
  selector: "app-select-checkout-shipping-date",
  templateUrl: "./select-checkout-shipping-date.component.html",
  styleUrls: ["./select-checkout-shipping-date.component.css"],
})
export class SelectCheckoutShippingDateComponent implements OnInit {
  @Output() dateSelected: EventEmitter<string> = new EventEmitter<string>();
  @Input() shippingDateOptions: ShippingCostCategory[];
  @Input() label: string;
  _moment = null;
  selectedDate: string = "";
  readonly: boolean;
  constructor() {
    this._moment = moment;
    moment.locale("en");
  }

  ngOnInit(): void {

    // When only 1 option, then preselect and disable select
    if (this.shippingDateOptions && this.shippingDateOptions.length === 1) {
      this.selectedDate = this.shippingDateOptions[0].id;
      this.readonly = true;
      this.dateSelected.emit(this.selectedDate);
    }
  }
}
