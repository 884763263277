import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { ShippingAddressGroup } from "src/app/interfaces/shipping-address-group";
import { OpenOrdersSelectedFilters } from "src/app/interfaces/financial/openorders/open-orders-selected-filters";
import { OpenOrdersFilterOptions } from "src/app/interfaces/financial/openorders/open-orders-filter-options";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { InvoicesFilterOptions } from "src/app/interfaces/financial/invoices/invoices-filter-options";
import { InvoicesOptionInput } from "src/app/interfaces/financial/invoices/invoices-option-input";
import { InvoicesSelectedFilters } from "src/app/interfaces/financial/invoices/invoices-selected-filters";
import { ActivatedRoute } from "@angular/router";
import { ApplicationService } from "src/app/services/application.service";
import * as moment from "moment";

@Component({
  selector: "app-invoices-filters",
  templateUrl: "./invoices-filters.component.html",
  styleUrls: ["./invoices-filters.component.scss"]
})
export class InvoicesFiltersComponent implements OnInit {
  @Output() searchSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Input() shippingAddressGroup: ShippingAddressGroup;
  filters: InvoicesSelectedFilters;
  filterOptions: InvoicesFilterOptions;
  fromDate: NgbDate;
  toDate: NgbDate;
  maxDate: Date;

  constructor(private activatedRouter: ActivatedRoute, public applicationService: ApplicationService) {
    this.filters = new InvoicesSelectedFilters(
      "all",
      "deliveryweek",
      false,
      "",
      "invoicenumber",
      this.applicationService.getSelectCompanyGroupCode() === "WG" ? "debit" : "All"
    );
    this.filterOptions = new InvoicesFilterOptions();
    this.maxDate = new Date();
    this.defaultDateRange()
  }

  ngOnInit() {
    let getResults = false;
    this.activatedRouter.params.subscribe(params => {
      if (params["id"] && params["type"] === "invoices" && this.filters.keyWord !== params["id"]) {
        this.filters.keyWord = params["id"];
        getResults = true;
      }
    });
    this.searchSubmitted.next({ filters: this.filters, getResults: getResults });
    // Exclude searching by 'order number' if WG
    if (this.applicationService.getSelectCompanyGroupCode() === 'WG') {
      this.filterOptions.keyWordOptions = this.filterOptions.keyWordOptions.filter(function(option) {
        return option.value !== 'accentry_id'
      })
    }
  }

  submit() {
    this.searchSubmitted.next({ filters: this.filters, getResults: true });
  }

  defaultDateRange() {
    // 4 months prior to now
    this.filters['date_from'] = {
      "year": parseInt(moment().subtract(5, 'months').format('YYYY')),
      "month": parseInt(moment().subtract(5, 'months').format('MM')),
      "day": parseInt(moment().subtract(5, 'months').format('DD'))
    };

    // Current date
    this.filters['date_to'] = {
      "year": parseInt(moment().format('YYYY')),
      "month": parseInt(moment().format('MM')),
      "day": parseInt(moment().format('DD'))
    };
  }

  resetFilters() {
    this.filters = new InvoicesSelectedFilters(
      "all",
      "deliveryweek",
      false,
      "",
      "invoicenumber",
      this.applicationService.getSelectCompanyGroupCode() === "WG"
        ? "debit"
        : "All"
    );
    this.defaultDateRange();
    this.submit();
  }

  setShippingAddress(shippingAddressId: string) {
    this.filters.shippingAddress = shippingAddressId;
  }
}
