import { Component, OnInit } from "@angular/core";
import { CmsService } from "src/app/services/cms.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { NewsItem } from "src/app/interfaces/cms/news-item";
import { ApplicationService } from "src/app/services/application.service";
import { NewsFilters } from "src/app/interfaces/cms/news-filters";
import * as appGlobal from "src/app/globals";
import { HelperService } from "src/app/services/helper.service";
import { CacheService } from "src/app/services/cache.service";
import { CacheType } from "src/app/interfaces/cache-tracker";
import { timer } from "rxjs";
@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.scss"],
})
export class NewsComponent implements OnInit {
  newsItems: NewsItem[];
  selectedCompanyGroup: string;
  totalItems = 0;
  dataLoaded = false;
  constructor(
    private cmsService: CmsService,
    private applicationService: ApplicationService,
    private helperService: HelperService,
    private cacheService: CacheService
  ) {}

  ngOnInit() {
    this.selectedCompanyGroup =
      this.applicationService.getSelectCompanyGroupCode();

      this.totalItems = this.cacheService.totalNewsItems;
      this.getNews();
  }

  getNews() {
    this.selectedCompanyGroup =
      this.applicationService.getSelectCompanyGroupCode();
    if (!appGlobal.companyGroupSettings) {
      this.applicationService.getSettingsForCompanyGroup(
        this.selectedCompanyGroup
      );
    }

    this.newsItems = JSON.parse(
      sessionStorage.getItem(
        "news-" + this.applicationService.getSelectCompanyGroupCode()
      )
    );
    if (!this.cacheService.needRefresh(CacheType.news) && (this.newsItems && this.newsItems.length)) {
      timer(0).subscribe(() => (this.dataLoaded = true));
      return new Promise((resolve) => resolve(true));
    }

    const filters = new NewsFilters();
    filters.page = 1;
    filters.page_size = 10;
    filters.company_ids = appGlobal.companyGroupSettings
      ? appGlobal.companyGroupSettings.companies
      : [];

    const promise = new Promise((resolve) => {
      this.cmsService.getNews(filters).subscribe((apiResponse: ApiResponse) => {
        if (
          this.helperService.checkResponse(apiResponse) &&
          apiResponse.result["items"]
        ) {
          this.newsItems = apiResponse.result["items"];
          this.totalItems = apiResponse.result["total_items"];
          sessionStorage.setItem(
            "news-" + this.applicationService.getSelectCompanyGroupCode(),
            JSON.stringify(this.newsItems)
          );

          this.cacheService.dataLoaded(CacheType.news);
          this.cacheService.totalNewsItems = this.totalItems;
          timer(0).subscribe(() => (this.dataLoaded = true));
          resolve(this.newsItems);
        } else {
          resolve([]);
        }
      });
    });
    return promise;
  }
}
