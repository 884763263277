import { Component, OnInit } from '@angular/core';
import { YearAgreementService } from 'src/app/services/year-agreement.service';
import { YearAgreementPAndAService } from 'src/app/services/year-agreements/year-agreement/year-agreement-p-and-a.service';

@Component({
  selector: "app-year-agreement-dealer-ships",
  templateUrl: "./year-agreement-dealer-ships.component.html",
  styleUrls: ["./year-agreement-dealer-ships.component.scss"],
})
export class YearAgreementDealerShipsComponent implements OnInit {
  constructor(
    public yearAgreementPAndAService: YearAgreementPAndAService
  ) {}

  ngOnInit() {}

  checkSelection() {
    let totalInvalid = 0;
    const container = document.getElementById(
      "dealerShipsContainer"
    ) as HTMLElement;
    const inputs = container.querySelectorAll(".required");
    inputs.forEach((input: HTMLInputElement) => {
      totalInvalid +=
        !input.readOnly && !input.disabled && input.value === "" ? 1 : 0;
    });
    return totalInvalid;
  }
}
