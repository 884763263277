<div class="row">
  <div class="col-md-12 card border-0">
    <div class="card-body">
      <h4>{{ "DEALER_COMMERCIAL_SETTINGS" | translate }}</h4>
      <div class="row mx-0 mt-3">
        <form>
          <div class="mb-3 row">
            <label class="col-sm-6 col-form-label">{{
              "SHIPPINGCOST" | translate
            }}</label>
            <div class="col-sm-6">
              <textarea class="form-control" readonly [ngModelOptions]="{ standalone: true }" [ngModel]="yearAgreementPAndAService.agreementModel.shipping_cost" rows="3"></textarea>
            </div>
          </div>

          <div class="mb-3 row">
            <label class="col-sm-6 col-form-label">{{
              "SHIPPINGSCHEDULE" | translate
            }}</label>
            <div class="col-sm-6">
              <textarea class="form-control" readonly [ngModelOptions]="{ standalone: true }" [ngModel]="yearAgreementPAndAService.agreementModel.shipping_schedule" rows="3"></textarea>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-6 col-form-label">{{
              "DROPSHIPMENT" | translate
            }}</label>
            <div class="col-sm-6">
              <div class="form-check form-switch mt-2">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="dropshipment_select"
                  [ngModelOptions]="{ standalone: true }"
                  disabled
                  [ngModel]="yearAgreementPAndAService.agreementModel.dropshipment"
                />
                <label class="form-check-label" for="dropshipment_select"></label>
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-6 col-form-label">{{
              "REVENUE_TARGET" | translate
            }}</label>
            <div class="col-sm-6">
              <input type="number" id="revenueTarget" [min]="!yearAgreementPAndAService.agreementModel.exceptions ? minimalTurnover : 0" step="1000" required class="form-control" placeholder="{{'REQUIRED'|translate}}" [ngModelOptions]="{ standalone: true }" [(ngModel)]="yearAgreementPAndAService.agreementModel.turnover_target" />
              <small *ngIf="minimalTurnover > 1">Min. &euro; {{minimalTurnover}}</small>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
