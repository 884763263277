import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { YearAgreementDataModel } from "src/app/interfaces/yearAgreement/year-agreement-data-model";
import { AccountAgreement } from "src/app/interfaces/yearAgreement/account-agreement";
import { YearAgreementService } from "src/app/services/year-agreement.service";
import { AccountAffiliates } from "src/app/interfaces/yearAgreement/account-affiliates";
import { ApiResponse } from "src/app/interfaces/api-response";
import * as _ from "underscore";
import { AddAffiliateComponent } from "../../../bikes/add-affiliate/add-affiliate.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-affiliate-information",
  templateUrl: "./affiliate-information.component.html",
  styleUrls: ["./affiliate-information.component.scss"],
})
export class AffiliateInformationComponent implements OnInit {
  @Output() affiliateSelected: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Input() yearAgreementModel: YearAgreementDataModel;
  @Input() affiliates: string[];
  @Input() allAffiliates: AccountAffiliates[];
  @Input() selectedTab: string;
  @Input() accountAgreement: AccountAgreement;
  selectedAffiliates: AccountAffiliates[];
  addedAffiliates = [""];
  savedAffiliates = [];
  customAffiliates = [];
  @Input() selectedSubAffiliate: string;


  constructor(
    private yearAgreementService: YearAgreementService,
    private modalService: NgbModal,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.getAccountAffiliates();
  }

  saveAffiliates() {
    this.selectedAffiliates = _.where(this.allAffiliates, { selected: true });

    this.yearAgreementModel.affiliates = _.pluck(
      this.selectedAffiliates,
      "relation_id"
    )
      .concat(this.savedAffiliates.concat(this.customAffiliates))
      .join();
  }

  getAccountAffiliates() {
    this.addedAffiliates = [""];
    this.savedAffiliates = [];
    this.customAffiliates = [];
    this.yearAgreementService
      .getAccountAgreementAffiliates()
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          if (apiResponse.result.affiliate && !apiResponse.result.affiliate.length) {
            apiResponse.result.affiliate = [
              {
                relation_id: apiResponse.result.main_relation,
                is_main: true
              }
            ]
          }
          this.allAffiliates = apiResponse.result.affiliate.map(
            (entry: AccountAffiliates) => {
              if (
                this.yearAgreementModel.brands.brands.length === 0 &&
                this.yearAgreementModel.sports.brands.length === 0
              ) {
                entry.selected = true;
              } else {
                entry.selected = entry.is_main
                  ? true
                  : this.affiliates
                  ? this.affiliates.includes(entry.relation_id.toString())
                  : false;
              }
            }
          );
          this.allAffiliates = _.sortBy(
            apiResponse.result.affiliate,
            "is_main"
          );
          this.allAffiliates = this.allAffiliates.reverse();
           if (this.allAffiliates.length === 1) {
             this.selectAffiliate(this.allAffiliates[0].relation_id);
           }
        }
        const ids = _.pluck(this.allAffiliates, "relation_id").join(",");
        if (this.affiliates && this.affiliates.length) {
          this.affiliates.forEach((a) => {
            if (ids.indexOf(a) < 0) {
              this.customAffiliates.push(a);
            }
          });
        }
        this.saveAffiliates();
      });
  }

  addAffiliate() {
    const modalRef = this.modalService.open(AddAffiliateComponent, {
      centered: true,
      backdrop: "static",
      windowClass: "confirmDialog",
      container: "#modalContainer",
    });

    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.answerObserver.subscribe((response) => {
      this.customAffiliates.push(response)
      this.saveAffiliates();
    });
  }

  deleteAffiliate(index: number) {
    this.addedAffiliates.splice(index, 1);
  }

  selectAffiliate(affiliate: any) {
    this.yearAgreementModel.showMargins = false;
    this.affiliateSelected.emit(String(affiliate));
  }

  hasSelectedModel(affiliate: string) {

    // Check from filled in selection
    const filledInFocusSelection = this.yearAgreementModel.focusmodels.filter((m) => {
      return m.affiliate == affiliate
    });
    const filledInDemoSelection = this.yearAgreementModel.demomodels.filter((m) => {
      return m.affiliate == affiliate
    });

    // Check from saved selection
    const savedFocusSelection = this.accountAgreement.focus_model_selection.filter((m) => {
      return m.affiliate == affiliate
    });
    const savedDemoSelection = this.accountAgreement.demo_model_selection.filter((m) => {
    return m.affiliate == affiliate
    });

    return filledInFocusSelection.length || filledInDemoSelection.length || savedFocusSelection.length || savedDemoSelection.length;
  }

}
