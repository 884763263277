import { Component, OnInit, Input, input } from "@angular/core";
import { NewsItem } from "src/app/interfaces/cms/news-item";

@Component({
  selector: "app-news-details",
  templateUrl: "./news-details.component.html",
  styleUrls: ["./news-details.component.scss"]
})
export class NewsDetailsComponent implements OnInit {
  newsItem = input.required<NewsItem>
  constructor() {}

  ngOnInit() {}
}
