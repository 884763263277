/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.shopType !== "partner-portal") {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
} else {
  import('./app/app-partner-portal.module')
    .then(({ AppPartnerPortalModule }) => {
      platformBrowserDynamic().bootstrapModule(AppPartnerPortalModule)
        .catch(err => console.error(err));
    })
    .catch(err => console.error('Error loading AppPartnerPortalModule:', err));
}
