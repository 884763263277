export interface EditOpenOrderLineInput {
  line_id: string;
  sold: boolean;
  amount: string;
  shipping_id: string;
  address_type: string;
  remark: string;
  delivery_week: string;
  new_line_id: string;
  new_sold: boolean;
  new_shipping_id: string;
  new_address_type: string;
  new_amount: string;
  new_remarks: string;
  new_delivery_week: string;
  order_number: string;
}

export class EditOpenOrderLineInput implements EditOpenOrderLineInput {}
