import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { Subject } from "rxjs";
import { Article } from "src/app/interfaces/article";
import { PreorderDate } from "src/app/interfaces/preorder-date";
import { AlertService } from "src/app/services/alert.service";
import { ApplicationService } from "src/app/services/application.service";
import { BasketService } from "src/app/services/basket.service";
import { ArticleDetailsComponent } from "../../article-components/article-details/article-details.component";

@Component({
  selector: "app-preorder-article-modal",
  templateUrl: "./preorder-article-modal.component.html",
  styleUrls: ["./preorder-article-modal.component.scss"],
})
export class PreorderArticleModalComponent implements OnInit {
  submitObserver = new Subject<any>();
  submitObserverEmitted$ = this.submitObserver.asObservable();
  article: Article;
  modelId: string;
  selectedArticle: string = null;
  showDetailed: boolean;
  preOrder: boolean;
  showInModal: boolean;
  currentRate = 0;
  month: PreorderDate;

  @ViewChild("details", { static: true, read: ArticleDetailsComponent })
  details: ArticleDetailsComponent;
  firstWeekNumber: number;
  lastWeekNumber: number;
  weeksList: any[];
  @Input() initQuantities = [];
  quantities = {};

  constructor(
    public modal: NgbActiveModal,
    private alertService: AlertService,
    private applicationService: ApplicationService,
    private basketService: BasketService
  ) {}

  ngOnInit() {
    this.initQuantities.forEach((item) => {
      this.quantities[item['week']] = item['quantity'];
    })
  }

  ngAfterViewInit() {
    this.showInstant();
  }

  content() {}

  save() {
    let articles = [];
    let weeks = [];
    // Collect articles
    Object.keys(this.quantities).forEach((week) => {
      const quantity = this.quantities[week];
      articles.push({
        single_article_code: this.article.single_article_code,
        delivery_week: week != "0" ? `${this.month.year}${week}` : 0,
        quantity: quantity,
      });
      weeks.push({
        week: week,
        year: this.month.year,
        quantity: quantity,
      });
    });
    // this.basketService.addArticlesToBasket(articles);
    this.submitObserver.next(weeks);
    this.modal.dismiss();
  }

  showInstant() {
    this.details.modelId = this.modelId;
    this.details.selectedArticle = this.selectedArticle;
    this.details.article = this.article;
    this.details.showBreadCrumbs = false;
    this.details.preOrder = this.preOrder;
    this.details.showInModal = true;
    this.details.showInstant(true);
    this.applicationService.emitModalChange(true);
    this.getWeeksInMonth(this.month.year, this.month.month);
  }

  getWeeksInMonth(year, month) {
    let weeksList = [];

    if (typeof year !== "undefined") {
      const firstWeekNumber = moment(
        `01-${month}-${year}`,
        "DD-MM-YYYY"
      ).isoWeek();
      const lastWeekNumber = moment(`01-${month}-${year}`, "DD-MM-YYYY")
        .endOf("month")
        .isoWeek();
      let weekNumber = firstWeekNumber;
      if (weekNumber === 52 && weekNumber > lastWeekNumber) {
        weekNumber = 1;
      }
      while (weekNumber < lastWeekNumber + 1) {
        weeksList.push({
          weekNumber: weekNumber,
          date: moment().day("Monday").year(year).isoWeek(weekNumber).format("DD-MM-YYYY")
        });
        weekNumber++;
      }
      this.weeksList = weeksList;
    } else {
      this.weeksList = [
        {
          weekNumber: 0,
          date: null,
        },
      ];
    }
  }
}
