<ng-container *ngIf="articles.length">
  <h3 class="mb-4 clickable">{{ "RECENTLY_VIEWED" | translate }}</h3>
  <div
    class="articleContainer row mb-3 px-0 d-flex"
    style="padding-left: 5px"
    [ngClass]="articles && articles.length ? 'hasArticles' : ''"
  >
    <ng-container *ngFor="let article of articles; let i = index">
      <app-article-card
        [article]="article"
        (showArticleDetails)="showArticleDetails(article)"
        [hideMatrix]="true"
        class="card d-flex align-items-stretch" [ngClass]="cardClass"
      >
      </app-article-card>
    </ng-container>
  </div>
</ng-container>
