<ng-container *ngIf="!article?.setitem">
  <span class="d-block pt-1 mt-1 ms-2 ms-md-0"
    *ngIf="((article.article_type === articleType.P || lockedDeliveryWeeks) && article.item_details?.stock_status < 5 && !forceDeliveryWeekSelect) || !showDealerOptions">
    {{article.delivery_week}} {{article.delivery_year}}<br />
    <ng-container *ngIf="article.item_details?.stock_status > 1 && applicationService.getSelectCompanyGroupCode() !== 'WG'">
      <small>{{article.item_details?.stock_date}}</small>
    </ng-container>
  </span>
  <span class="py-2 d-block" *ngIf="article.item_details?.stock_status >= 4">
    {{article.item_details | statusText}}
  </span>
  <ng-container
    *ngIf="((((article.article_type !== articleType.P && !lockedDeliveryWeeks) && article.item_details?.stock_status < 4) && !article.item_details?.setitem) || forceDeliveryWeekSelect) && showDealerOptions">
    <app-select-week [article]="article.item_details" [deliveryWeek]="parsedDeliveryWeek"
      (weekSelected)="changeDeliveryWeek($event)"></app-select-week>
</ng-container>

