<div>
  <span class="position-fixed close-button text-xl" (click)="closeMenu()"
    ><i class="material-icons">close</i></span
  >
  <app-article-groups-browser
    class="d-block py-2"
    [listClass]="''"
    (menuClickedEvent)="closeMenu()"
    *ngIf="!isPartnerPortal"
    ><h3 class="d-block w-100 ps-3" style="padding-left: 1.25rem">
      {{ "CATALOG" | translate }}
    </h3></app-article-groups-browser
  >
  <ul class="list-group">
    <li
      class="list-group-item border-0 rounded-0"
      *ngFor="let item of menuItems"
    >
      <h3 (click)="gotoItem(item)" [ngClass]="{'hasLink': !item.sub_items}">{{ item.description | translate }}</h3>

      <ul class="list-group" *ngIf="item.sub_items && item.sub_items.length">
        <li
          class="list-group-item ps-0"
          *ngFor="let item of item.sub_items"
          (click)="gotoItem(item)"
        >
          <span
            [ngClass]="{
              'has-subitems': item.sub_items && item.sub_items.length
            }"
            >{{ item.description | translate }}</span
          >

          <ul
            class="list-group deepest"
            *ngIf="item.sub_items && item.sub_items.length"
          >
            <li class="list-group-item" *ngFor="let item of item.sub_items">
              <ul
                class="list-group deepest"
                *ngIf="item.sub_items && item.sub_items.length"
              >
                <li
                  class="list-group-item"
                  *ngFor="let sub_item of item.sub_items"
                >
                  <span (click)="gotoItem(sub_item)">{{
                    sub_item.description | translate
                  }}</span>
                </li>
              </ul>
              <span (click)="gotoItem(item)" *ngIf="!item.sub_items">{{
                item.description | translate
              }}</span>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</div>
