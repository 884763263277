import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { EbikeRegistration } from 'src/app/interfaces/ebsc/ebike-registration';
import { ArticleCompensationResult } from 'src/app/interfaces/warranty/article-compensation-result';
import { EbscService } from 'src/app/services/ebsc.service';
import { HelperService } from 'src/app/services/helper.service';
import { WarrantyService } from 'src/app/services/warranty.service';

@Component({
  selector: 'app-ezy-rider-invoice',
  templateUrl: './ezy-rider-invoice.component.html',
})
export class EzyRiderInvoiceComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() warrantyId: number;
  registration: EbikeRegistration;
  showInvoiceUpload = false;
  constructor(
    private ebscService: EbscService,
    private helperService: HelperService,
    private warrantyService: WarrantyService
  ) {}

  ngOnInit(): void {
    this.formGroup.get('framenumber').valueChanges.subscribe(() => {
      this.checkIfInvoiceNeeded();
    });
    this.formGroup.get('articleCode').valueChanges.subscribe(() => {
      this.checkIfInvoiceNeeded();
    });
  }

  ngOnDestroy() {
    this.disableFileUpload();
  }

  disableFileUpload() {
    this.formGroup.get('uploadFile').setValidators([]);
    this.formGroup.get('uploadFile').updateValueAndValidity();
  }

  checkIfInvoiceNeeded() {
    this.showInvoiceUpload = false;
    this.disableFileUpload();
    if (
      this.formGroup.get('framenumber') &&
      this.formGroup.get('articleCode').value &&
      Number(this.formGroup.get('articleCode').value) !== 999999
    ) {
      // First check warranty path (check if code = C)
      this.warrantyService
        .checkArticleGuarantee(
          this.formGroup.get('articleCode').value,
          moment().format('YYYYMMDD'),
          '1'
        )
        .subscribe((response: ApiResponse) => {
          if (this.helperService.checkResponse(response)) {
            const warrantyCompensation =
              response.result as ArticleCompensationResult;

            if (
              warrantyCompensation?.path_code &&
              warrantyCompensation?.path_code.trim() &&
              warrantyCompensation?.path_code === 'C'
            ) {
              // Check ebike has EzyRider
              this.ebscService
                .getEbikeRegistraton(
                  this.formGroup.get('framenumber').value,
                  false
                )
                .subscribe((apiResponse: ApiResponse) => {
                  if (this.helperService.checkResponse(apiResponse)) {
                    const registration =
                      apiResponse.result as EbikeRegistration;
                    if (registration.xtraGuaranteeDate) {
                      this.showInvoiceUpload = true;
                      this.formGroup
                        .get('uploadFile')
                        .setValidators([Validators.required]);
                      this.formGroup.get('uploadFile').updateValueAndValidity();
                    }
                  }
              });
            }
          }
        });
    }
  }
}
