import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateFormatter } from "src/app/ngbFormatter";
@Component({
  selector: "app-warranty-page",
  templateUrl: "./warranty-page.component.html",
  styleUrls: ["./warranty-page.component.scss"],
  providers: [
    { provide: NgbDateFormatter, useClass: NgbDateFormatter },
    NgbModalConfig,
  ],
})
export class WarrantyPageComponent implements OnInit {
  id: number;
  type: string;
  submitted: boolean;
  breadcrumbs: Breadcrumb[];
  add: string;
  action: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      // Get the warranty type
      if (this.route.snapshot.data["type"].indexOf("crm") < 0) {
        this.type =
          window.location.hash.includes("requestForm") ||
          this.applicationService.getSelectCompanyGroupCode() === "GHO" ||
          (this.applicationService.getSelectCompanyGroupCode() === "LAP" &&
            this.route.snapshot.data["type"] !== "aftersales")
            ? "form"
            : this.route.snapshot.data["type"];
      } else {
        this.type = this.route.snapshot.data["type"];
      }

      this.add = this.route.snapshot.queryParamMap.get("add");

      if (params['id']) {
        this.id = params['id'];
      }
      if (this.route.snapshot.data["action"]) {
        this.action = this.route.snapshot.data["action"];
      }
      this.setBreadcrumbs();
    });
  }

  setBreadcrumbs() {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/"
      ),
      new Breadcrumb(
        this.translateService.instant(
          this.applicationService.getSelectCompanyGroupCode() === "LAP"
            ? "SERVICE"
            : "FINANCIAL"
        ),
        "#"
      ),
    ];

    if (this.applicationService.getSelectCompanyGroupCode() === "RAL") {
  this.breadcrumbs.push(
    new Breadcrumb(
      this.translateService.instant("RETURNS"),
      "#"
    )
  );
    } else {
      if (this.id) {
        this.breadcrumbs.push(
          new Breadcrumb(
            this.translateService.instant("RETURNS"),
            this.applicationService.getSelectCompanyGroupCode() + "/Warranty"
          )
        );
        this.breadcrumbs.push(
          new Breadcrumb(
            `${this.translateService.instant("REQUEST_NUMBER")} ${this.id}`,
            this.type === "details"
              ? "#"
              : this.applicationService.getSelectCompanyGroupCode() +
                `/warranty/${this.id}`
          )
        );
      } else {
        if (this.route.snapshot.data["type"] === "aftersales") {
          this.breadcrumbs.push(
            new Breadcrumb(
              this.translateService.instant(
                `${this.applicationService.getSelectCompanyGroupCode()}_AFTERSALES`
              ),
              "#"
            )
          );
        } else {
          if (this.type.indexOf("crm") < 0) {
            this.breadcrumbs.push(
              new Breadcrumb(this.translateService.instant("RETURNS"), "#")
            );
          } else {
            this.breadcrumbs.push(
              new Breadcrumb(
                this.translateService.instant("RETURNS"),
                this.applicationService.getSelectCompanyGroupCode() +
                  "/WarrantyCrm"
              )
            );
          }
        }
      }

      if (this.type === "sendDetails") {
        this.breadcrumbs.push(
          new Breadcrumb(this.translateService.instant("OVERVIEW"), "#")
        );
      }
    }
  }

  showSubmitted() {
    this.type = "details";
    this.submitted = true;
  }
}
