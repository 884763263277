import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { User } from "src/app/interfaces/user";
import { AccountService } from "src/app/services/account.service";
import { UpdateUserInput } from "src/app/interfaces/update-user-input";
import { ApiResponse } from "src/app/interfaces/api-response";
import { OktaRegisterInput } from "src/app/interfaces/okta-register-input";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import { AlertService } from "src/app/services/alert.service";
import { AccountSettings } from "src/app/interfaces/account-settings";
import { Router } from "@angular/router";
import { VerifyPasswordContainer } from "src/app/interfaces/verify-password-container";
import { AuthenticateService } from "src/app/services/authenticate.service";
import { AccountActivationService } from "src/app/services/account-activation.service";

@Component({
  selector: "app-migrate-account",
  templateUrl: "./migrate-account.component.html",
  styleUrls: ["./migrate-account.component.scss"],
})
export class MigrateAccountComponent implements OnInit {
  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  formGroup: FormGroup;
  active: boolean;
  success: boolean;
  userNameDiffers: boolean;
  successText: string;
  loading: boolean;
  error = false;
  username: string;
  hashContainer: VerifyPasswordContainer;
  constructor(
    private applicationService: ApplicationService,
    private accountActivationService: AccountActivationService,
    private translateService: TranslateService,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    this.applicationService.setLanguage();
    this.buildForm();
    this.verifyData();
  }

  _createVerifyContainer(): VerifyPasswordContainer {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userHash = urlParams.get("user");
    const passwordHash = urlParams.get("hash");
    if (userHash && passwordHash) {
      return new VerifyPasswordContainer(userHash, passwordHash, "en");
    } else {
      return null;
    }
  }

  verifyData() {
    const container = this._createVerifyContainer();
    if (container !== null) {
      this.loading = true;
      this.applicationService.showLoader(true);
      this.accountActivationService
        .checkUserAndHash(container.user_hash, container.password_hash)
        .subscribe((apiResponse: ApiResponse) => {
          this.loading = false;
          this.applicationService.hideLoader();
          if (apiResponse && apiResponse.success) {
            this.username = apiResponse.result;
            this.hashContainer = container;
            this.error = false;
          } else {
            this.error = apiResponse.result;
            this.alertService.showErrorNotification(
              apiResponse && apiResponse.result
                ? apiResponse.result
                : this.translateService.instant("ERROR")
            );
          }
        });
    } else {
      this.error = true;
    }
  }

  buildForm() {
    let EMAILPATTERN =
      /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    this.formGroup = new FormGroup({
      username: new FormControl("", [
        Validators.required,
        Validators.pattern(EMAILPATTERN),
      ]),
      usernameconfirm: new FormControl("", [
        Validators.required,
        Validators.pattern(EMAILPATTERN),
      ]),
    });

    this.formGroup.valueChanges.subscribe(() => {
      if (
        this.formGroup.get("username").value !==
        this.formGroup.get("usernameconfirm").value
      ) {
        this.userNameDiffers = false;
      }
    });
    this.active = true;
  }

  submit() {
    if (this.formGroup.valid) {
      const user = new OktaRegisterInput(
        "",
        "",
        this.formGroup.get("username").value
      );
      if (user.username !== this.formGroup.get("usernameconfirm").value) {
        this.userNameDiffers = true;
      } else {
        this.loading = true;
        this.applicationService.showLoader(true);
        // subscribe to call
        this.accountActivationService
          .migrateAccount(this.hashContainer.user_hash, this.hashContainer.password_hash, user)
          .subscribe((apiResponse: ApiResponse) => {
            this.loading = false;
            this.applicationService.hideLoader();
            if (apiResponse.success) {
              this.successText = this.translateService
                .instant("ACTIVATE_P1")
                .replace("{0}", this.formGroup.get("username").value);
              this.success = true;
            } else {
              this.alertService.showPopup(
                this.translateService.instant("ERROR"),
                "error"
              );
            }
          });
      }
    }
  }
}
