<app-login-partner-container [bg]="bg">
  <div class="p-sm-5 p-3" logo>
    <app-partner-portal-logo class="d-block"></app-partner-portal-logo>
  </div>
  <div (keydown.enter)="$event.preventDefault()" class="form p-3 p-sm-5 shadow rounded-4">
    <ng-container *ngIf="type === 'manual'">
      <ngb-alert *ngIf="message" type="success" [dismissible]="false">{{
        message
      }}</ngb-alert>
      <ng-container *ngIf="!message">
        <label><b>{{ "USERNAME" | translate }}</b></label>
        <input type="text" class="form-control" [(ngModel)]="username" />
      </ng-container>
      <button
        class="btn btn-primary mt-2"
        *ngIf="!resetted"
        (click)="resetPassword()"
        [disabled]="!username.length"
      >
        {{ "CONFIRM" | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="type === 'verify' && username">
      <h4>{{ username }}</h4>
      <app-change-password-container
        #passwordContainer
        [hideCurrentPassword]="true"
      ></app-change-password-container>
      <button
        class="btn btn-primary mt-2"
        *ngIf="!resetted"
        (click)="sendNewPassword($event)"
        [disabled]="!passwordContainer.checkPasswordRequirements()"
      >
        {{ "CONFIRM" | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="type === 'verify' && error">
      <ngb-alert type="danger" [dismissible]="false">{{ error }}</ngb-alert>
    </ng-container>
    <ng-container *ngIf="type === 'verify_complete'">
      <h4>{{ username }}</h4>
      <ngb-alert type="success" [dismissible]="false">{{
        "PASSWORD_RESETTED" | translate
      }}</ngb-alert>
    </ng-container>
  <a
    (click)="back.next(true)"
    [routerLink]="'/login'"
    class="d-block text-center mt-3"
  >
    {{ "BACK_TO_LOGIN" | translate }}
  </a>
</div>
</app-login-partner-container>
