import { Component, OnInit, NgModule, ViewChild } from "@angular/core";
import { AuthenticateService } from "src/app/services/authenticate.service";
import { User } from "src/app/interfaces/user";
import { UserProfile } from "src/app/interfaces/user-profile";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AccountService } from "src/app/services/account.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { ShippingAddress } from "src/app/interfaces/shipping-address";
import * as _ from "underscore";
import { ShippingAddressGroup } from "src/app/interfaces/shipping-address-group";
import { UpdateUserInput } from "src/app/interfaces/update-user-input";
import { AcademyAccount } from "src/app/interfaces/academy-account";
import {
  ReactiveFormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  NgControl,
} from "@angular/forms";
import { AcademyAccess } from "src/app/interfaces/academy-access";
import { AccountInformation } from "src/app/interfaces/account-information";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { Subject, forkJoin } from "rxjs";
import { UsersOverview } from "src/app/interfaces/users-overview";
import { ApplicationService } from "src/app/services/application.service";
import { EbstPermissions } from "src/app/interfaces/ebst-permissions";
import { ChangePasswordContainer } from "src/app/interfaces/change-password-container";
import { ChangePasswordContainerComponent } from "../user-settings/change-password-container/change-password-container.component";
import { AccountSettings } from "src/app/interfaces/account-settings";
import { ResendOktaActivationModalComponent } from "../../helpdesk/resend-okta-activation-modal/resend-okta-activation-modal.component";

@Component({
  selector: "app-edit-user-modal",
  templateUrl: "./edit-user-modal.component.html",
  styleUrls: ["./edit-user-modal.component.scss"],
})
export class EditUserModalComponent implements OnInit {
  public dataSaved = new Subject<any>();
  @ViewChild(ChangePasswordContainerComponent)
  passwordContainer: ChangePasswordContainerComponent;
  user: User;
  usersOverview: UsersOverview;
  newUser: false;
  profiles: UserProfile[];
  academy_accounts: AcademyAccount[];
  shippingAddressGroups: ShippingAddressGroup[];
  shippingOptions: any;
  academyForm: FormGroup;
  userForm: FormGroup;
  addressForm: FormGroup;
  profileForm: FormGroup;
  baseAccountShippingAddress: ShippingAddress[];
  accountInformation: AccountInformation;
  acc_wasValidated = false;
  user_wasValidated = false;
  saving: boolean;
  okta: boolean;
  accountType: string;
  rayonCodes: any[] = [];
  selectedRayonCodes: any = {};
  selectedIndex: number;
  searchRayon: any = {};
  companyGroup: string;
  setPasswordContainer: ChangePasswordContainer;
  accountSettings: AccountSettings;
  rayonKeys: string[];
  selectedRayonGroup = "";
  active = 1;
  loading: boolean;
  get isOwner() {
    return this.userForm ? this.userForm.get("owner").value : this.user?.owner;
  }

  constructor(
    public modal: NgbActiveModal,
    public accountService: AccountService,
    public formBuilder: FormBuilder,
    private alertService: AlertService,
    private translationService: TranslateService,
    private authenticateService: AuthenticateService,
    private applicationService: ApplicationService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    // First get account settings
    this.getAccountInformation().then(() => {
      this.setPasswordContainer = new ChangePasswordContainer();
      this.userForm = this.formBuilder.group({
        username: new FormControl(this.newUser ? "" : this.user.username, []),
        owner: new FormControl(this.user.owner),
        first_name: new FormControl(this.user.first_name, [
          Validators.required,
        ]),
        middle_name: new FormControl(this.user.middle_name, []),
        last_name: new FormControl(this.user.last_name, [Validators.required]),
      });
      this.user.academy_access.has_access =
        this.user.academy_access.has_access !== true ? false : true;
      this.academyForm = this.formBuilder.group({
        has_access: new FormControl(this.user.academy_access.has_access),
        email_new: new FormControl(this.user.academy_access.email_new, [
          Validators.required,
          Validators.email,
        ]),
        current_account: new FormControl(
          this.user.academy_access.current_account
        ),
      });

      this.profileForm = this.formBuilder.group({
        profile: new FormControl(
          this.user.profile_id,
          this.user.owner ? [] : [Validators.required]
        ),
      });

      if (this.accountSettings.username === this.user.email_address) {
        this.userForm.get("owner").disable();
      } else {
        this.userForm.get("owner").enable();
      }

      if (this.newUser) {
        if (this.usersOverview && this.usersOverview.okta) {
          this.userForm
            .get("username")
            .setValidators([Validators.required, Validators.email]);
        }
        this.userForm.get("username").updateValueAndValidity();
      }

      this.user.academy_access.email_new = this.user.email_address;

      this.accountType = this.accountService.getAccountType();

      this.companyGroup = this.applicationService.getSelectCompanyGroupCode();
      this.user.ebst_permissions = new EbstPermissions();

      if (
        this.user.ebst_access &&
        this.user.ebst_access.permissions &&
        this.user.ebst_access.permissions.length
      ) {
        this.user.ebst_permissions.patches =
          this.user.ebst_access.permissions.indexOf("PATCHES") >= 0;
        this.user.ebst_permissions.service =
          this.user.ebst_access.permissions.indexOf("SERVICE") >= 0;
        this.user.ebst_permissions.trainer =
          this.user.ebst_access.permissions.indexOf("TRAINER") >= 0;
        this.user.ebst_permissions.training =
          this.user.ebst_access.permissions.indexOf("TRAINING") >= 0;
      }

      if (this.usersOverview && this.usersOverview.use_rayon) {
        this.getRayonCodes();
      }
    });
  }

  // Parse the shipping addresses for use in the dropdowns
  parseShippingAddresses() {
    let allShippingAddresses = Array<ShippingAddress>();

    // Add the options from this main account
    if (
      this.baseAccountShippingAddress &&
      this.baseAccountShippingAddress.length
    ) {
      allShippingAddresses = this.baseAccountShippingAddress;
    }

    // Add the users choices.
    if (this.user.shipping_addresses && this.user.shipping_addresses.length) {
      allShippingAddresses.push.apply(
        allShippingAddresses,
        this.user.shipping_addresses
      );
    }

    const groups = _.chain(allShippingAddresses)
      .groupBy("company_id")
      .map((value: any, key) => {
        const group: ShippingAddressGroup = new ShippingAddressGroup();
        group.company_id = String(key);
        group.shipping_addresses = _.uniq(value, (addr) => {
          return addr.id;
        });

        // Define selected shipping address for the selected user
        const selected = _.findWhere(this.user.shipping_addresses, {
          company_id: String(group.company_id),
        }) as ShippingAddress;
        if (typeof selected !== "undefined") {
          group.selected = selected.id;
          group.access_only_address = selected.access_only_address;
        }
        group.address_access = group.selected ? String(key) : "all";
        return group;
      })
      .value();

    this.shippingAddressGroups = groups;
  }

  // Collect the selected shipping addresses
  getSelectedShippingAddresses() {
    const ids = [];
    if (this.shippingAddressGroups && this.shippingAddressGroups.length) {
      this.shippingAddressGroups.forEach((shippingGroup) => {
        ids.push({
          company_id: shippingGroup.company_id,
          id: shippingGroup.selected ? shippingGroup.selected : 0,
          access_only_address: shippingGroup.access_only_address,
        });
      });
    }
    return ids;
  }

  getAccountInformation() {
    return new Promise((approve, reject) => {
      forkJoin(
        this.accountService.getAccountInformation(),
        this.accountService.getAccountSettings()
      ).subscribe((apiResponse: [AccountInformation, ApiResponse]) => {
        if (apiResponse) {
          if (apiResponse[0]) {
            this.baseAccountShippingAddress = apiResponse[0].shipping_addresses;
          }
          if (apiResponse[1] && apiResponse[1].result) {
            this.accountSettings = apiResponse[1].result;
          }
          this.parseShippingAddresses();
          approve(true);
        } else {
          reject();
        }
      });
    });
  }

  getRayonCodes() {
    this.accountService
      .getRayonCodes()
      .subscribe((apiResponse: ApiResponse) => {
        if (apiResponse && apiResponse.success) {
          const rayonCodes = Object.values(apiResponse.result);
          const rayonKeys = Object.keys(apiResponse.result);
          if (rayonKeys && rayonKeys.length) {
            this.rayonKeys = rayonKeys;
            this.selectedRayonGroup = this.rayonKeys[0];

            let index = 0;
            for (const codes of rayonCodes) {
              const formatted = codes as string[];
              this.rayonCodes.push(formatted);
              this.selectedRayonCodes[this.rayonKeys[index]] = {};
              if (this.user?.rayon_codes) {
                this.user.rayon_codes.forEach((r) => {
                  if (
                    typeof this.selectedRayonCodes[r["company_id"]] ===
                    "undefined"
                  ) {
                    this.selectedRayonCodes[r["company_id"]] = {};
                  }
                  this.selectedRayonCodes[r["company_id"]][r["code"]] = true;
                });
              }

              this.searchRayon[this.rayonKeys[index]] = "";
              index++;
            }
          }
        }
      });
  }

  // Save the data
  save() {
    this.acc_wasValidated = true;
    this.user_wasValidated = true;

    if (
      this.passwordContainer &&
      !this.passwordContainer.passwordMeetsRequirements
    ) {
      this.alertService.showPopup(
        this.translationService.instant("CORRECT_USERNAME_PASSWORD"),
        "error"
      );
      return false;
    }

    if (!this.profileForm.get("profile").value && !this.isOwner) {
      this.alertService.showPopup(
        this.translationService.instant("JS_PROFILE_SELECT"),
        "error"
      );
      return false;
    }

    // update user
    if (
      !this.newUser &&
      this.userForm.valid &&
      (this.academyForm.valid || !this.academyForm.get("has_access").value)
    ) {
      const data = new UpdateUserInput();

      if (this.academyForm.get("has_access").value) {
        data.academy = this.academyForm.value;
      } else {
        data.academy = new AcademyAccess();
        data.academy.has_access = false;
      }

      data.username = this.userForm.get("username").value;
      data.first_name = this.userForm.get("first_name").value;
      data.middle_name = this.userForm.get("middle_name").value;
      data.last_name = this.userForm.get("last_name").value;
      data.owner = this.userForm.get("owner").value;
      data.shipping_ids = this.getSelectedShippingAddresses();
      data.profile_id = this.profileForm.get("profile").value;
      data.ebst = this.user.ebst_access;
      data.ebst_permissions = this.user.ebst_permissions;

      if (
        this.passwordContainer &&
        this.passwordContainer.passwordMeetsRequirements
      ) {
        data.password = this.setPasswordContainer.new_password;
      }
      if (this.rayonKeys) {
        data.rayon_codes = this.getSelectedRayonCodes();
      }

      this.saving = true;
      this.accountService
        .updateUserSettings(this.user.user_id, data)
        .subscribe((response: ApiResponse) => {
          this.saving = false;
          this.alertService.showInfoNotification(
            this.translationService.instant("SAVED")
          );
          this.closeModal();
        });
    } else if (this.userForm.valid) {
      // new user
      this.saving = true;
      const data = new UpdateUserInput();

      if (this.academyForm.get("has_access").value) {
        data.academy = this.academyForm.value;
      } else {
        data.academy = new AcademyAccess();
        data.academy.has_access = false;
      }
      data.first_name = this.userForm.get("first_name").value;
      data.last_name = this.userForm.get("last_name").value;
      data.username = this.userForm.get("username").value;
      data.shipping_ids = this.getSelectedShippingAddresses();
      data.profile_id = this.profileForm.get("profile").value;
      data.owner = this.userForm.get("owner").value;
      data.ebst = this.user.ebst_access;
      if (
        this.passwordContainer &&
        this.passwordContainer.passwordMeetsRequirements
      ) {
        data.password = this.setPasswordContainer.new_password;
      }
      data.ebst_permissions = this.user.ebst_permissions;
      if (this.rayonKeys) {
        data.rayon_codes = this.getSelectedRayonCodes();
      }

      this.accountService
        .createUserSettings(data)
        .subscribe((response: ApiResponse) => {
          this.saving = false;
          if (!response.success) {
            this.alertService.showErrorNotification(response.result);
          } else {
            // close popup
            this.alertService.showInfoNotification(
              this.translationService.instant("SAVED")
            );
            this.closeModal();
          }
        });
    } else {
      this.active = 1;
    }
  }

  private getSelectedRayonCodes() {
    let rayonCodesList = [];
    for (let key of this.rayonKeys) {
      if (this.selectedRayonCodes[key]) {
        for (let rayonCode of Object.keys(this.selectedRayonCodes[key])) {
          if (this.selectedRayonCodes[key][rayonCode]) {
            rayonCodesList.push({
              company_id: key,
              code: rayonCode,
            });
          }
        }
      }
    }
    return rayonCodesList;
  }

  private closeModal() {
    this.dataSaved.next(true);
    this.modal.close();
  }

  resetPassword() {
    this.authenticateService
      .resetPassword(this.userForm.get("username").value)
      .subscribe((apiResponse: ApiResponse) => {
        if (apiResponse && apiResponse.success) {
          const message = this.translationService
            .instant("PASSWORD_LINK_SEND")
            .replace("{0}", this.userForm.get("username").value);
          this.alertService.showInfoNotification(message);
          this.modal.dismiss();
        } else if (apiResponse) {
          this.alertService.showErrorNotification(apiResponse.result);
        }
      });
  }

  resendNewActivation() {
    const modalRef = this.modalService.open(
      ResendOktaActivationModalComponent,
      {
        centered: true,
        container: "#modalContainer",
      }
    );
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.email = this.user.activation_email;
  }
}
