
<div class="container-fluid" [style.background-image]="'url(' + bg + ')'">
  <div class="row">
    <div class="col-12 col-lg-6 bg-image">
      <ng-content select="[logo]"></ng-content>
    </div>

    <div class="col-12 col-lg-6">
      <div class="d-flex justify-content-center align-items-center vh-100">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
