import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountAgreement } from 'src/app/interfaces/yearAgreement/account-agreement';
import { YearAgreementService } from 'src/app/services/year-agreement.service';
import { ApplicationService } from 'src/app/services/application.service';
import { ApiResponse } from 'src/app/interfaces/api-response';
import * as moment from 'moment';
import { YearAgreementBaseService } from 'src/app/services/year-agreements/year-agreement-base.service';
import { YearAgreementType } from 'src/app/interfaces/year-agreement-type';
import { HelperService } from 'src/app/services/helper.service';
import { saveAs } from 'file-saver';

@Component({
  selector: "app-show-dealer-agreements",
  templateUrl: "./show-dealer-agreements.component.html",
  styleUrls: ["./show-dealer-agreements.component.scss"],
})
export class ShowDealerAgreementsComponent implements OnInit {
  agreements: AccountAgreement[];
  pdfSrc: string;
  file: Blob;
  selectedAgreement: AccountAgreement;
  signed: boolean;
  show: boolean;
  dealerName: string;
  contractEmail: string = "";
  contractEmailCheck: string = "";
  signDate: string;
  sending: boolean;
  type: string = "bikes";
  id: number;
  year: number;
  companyGroup: string;
  useBase: boolean;


  get emailRequired() {
    return (this.companyGroup === 'ANL' && (this.type === 'bikes' ||  this.type === '') && this.selectedAgreement.year > 2022) || (['GHO','WG'].includes(this.companyGroup) && this.selectedAgreement.year > 2023)
  }

  get emailInvalid() {
    if (this.contractEmail && this.emailRequired) {
      return (
        !this.helperService.emailRegex.test(this.contractEmail) ||
        this.contractEmail !== this.contractEmailCheck
      );
    } else {
      return false;
    }
  }

  constructor(
    public modal: NgbActiveModal,
    private yearAgreementService: YearAgreementService,
    private applicationService: ApplicationService,
    private yearAgreementBaseService: YearAgreementBaseService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.companyGroup = this.applicationService.getSelectCompanyGroupCode();
    this.show = localStorage.getItem("selectedDealer") ? false : true;
    this.contractEmail = this.selectedAgreement.contract_email;
    this.contractEmailCheck = this.selectedAgreement.contract_email;
  }

  getPdf(id: number, year: number) {
    this.signed = false;
    this.year = year;
    this.applicationService.showLoader(true);
    switch (this.type) {
      case "ANL_Parts":
        this.yearAgreementBaseService
          .pdf(YearAgreementType.parts, id.toString())
          .subscribe((blob: Blob) => {
            this.applicationService.hideLoader();
            this.generateUrl(blob);
          });
        break;
      case "bikes":
      case "":
        this.yearAgreementService
          .getAgreementPdf(id)
          .subscribe((blob: Blob) => {
            this.applicationService.hideLoader();
            this.generateUrl(blob);
          });
        break;
      case "dach":
        this.yearAgreementService
          .getAgreementPdfDach(id)
          .subscribe((blob: Blob) => {
            this.applicationService.hideLoader();
            this.generateUrl(blob);
          });
        break;
      case "ANL_Bikes_DK":
        this.yearAgreementBaseService
          .pdf(YearAgreementType.dk, id.toString())
          .subscribe((blob: Blob) => {
            this.applicationService.hideLoader();
            this.generateUrl(blob);
          });
        break;
      case "ANL_Bikes_DE":
        this.yearAgreementBaseService
          .pdf(YearAgreementType.dekoga, id.toString())
          .subscribe((blob: Blob) => {
            this.applicationService.hideLoader();
            this.generateUrl(blob);
          });
        break;
      case "ANL_Bikes_CH":
        this.yearAgreementBaseService
          .pdf(YearAgreementType.chkoga, id.toString())
          .subscribe((blob: Blob) => {
            this.applicationService.hideLoader();
            this.generateUrl(blob);
          });
        break;
    }
  }

  // Generate pdf
  generateUrl(blob) {
    this.file = new Blob([blob], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(this.file);
    this.pdfSrc = fileURL;
  }

  download() {
    saveAs(this.file, `agreement_${this.year}.pdf`);
  }

  sign() {
    if (this.dealerName || this.type === "parts") {
      if (this.type === "parts") {
        this.dealerName = localStorage.getItem("accountCode");
      }

      this.applicationService.showLoader(true);
      const service = this.useBase
        ? this.yearAgreementBaseService.sign(
            this.id,
            this.dealerName,
            this.contractEmail
          )
        : this.yearAgreementService.signAgreement(
            this.id,
            this.dealerName,
            this.contractEmail,
            this.type
          );

      this.sending = true;

      service.subscribe((apiResponse: ApiResponse) => {
        if (apiResponse.success) {
          this.selectedAgreement.locked = true;
          this.signDate = moment().format("DD-MM-YYYY HH:mm");
        }
        this.sending = false;
        this.applicationService.hideLoader();

        if (this.type === "parts") {
          this.modal.dismiss();
        }
      });
    }
  }

  _getSignedDate(value) {
    return moment(value, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY HH:mm");
  }
}
