<ng-container *ngIf="getShowButton">
  <label
    class="btn btn-tertiary mb-0"
    placement="bottom"
    ngbTooltip="{{ 'SN_STOCK_NOTIFICATION_TOOLTIP' | translate }}"
    *ngIf="!article.has_stock_notification"
    #stockTooltip
    placement="bottom"
    (click)="addToStockList()"
  >
    <i class="material-icons">notifications</i>
  </label>

   <label
    class="btn btn-tertiary text-success mb-0"
    placement="bottom"
    ngbTooltip="{{ 'SN_REMOVE_FROM_STOCK_NOTIFICATION' | translate }}"
    *ngIf="article.has_stock_notification"
    placement="bottom"
    (click)="removeFromStockList()"
  >
    <i class="material-icons">notifications_active</i>
  </label>
</ng-container>
