<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "ACTIVATION.RESET_ACCOUNT" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    </button>
</div>
<form ngNativeValidate #form>
  <div class="modal-body p-3">
    <ngb-alert type="warning" [dismissible]="false">{{"RESET_OKTA_EXPLANATION"|translate}}</ngb-alert>
    <div class="mb-3 mt-3">
      <label for="username">{{ "EMAIL" | translate }}</label>
      <input
        type="email"
        id="email"
        class="form-control form-control-lg"
        name="email"
        [(ngModel)]="email"
      />
    </div>
    <div class="mb-3 mt-3">
      <label for="username">{{ "ACTIVATION.EMAIL_REPEAT" | translate }}</label>
      <input
        type="email"
        id="emailRepeat"
        required
        class="form-control form-control-lg"
        name="emailRepeat"
        [(ngModel)]="emailRepeat"
      />
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="submit"
      class="btn btn-success me-auto"
      [ngClass]="{ disabled: email !== emailRepeat }"
      [attr.disabled]="
        email !== emailRepeat || sendDisabled || loading
          ? 'disabled'
          : undefined
      "
      (click)="submit()"
    >
      {{ "SEND" | translate }}
    </button>
    <button class="btn btn-dark ms-auto" (click)="modal.dismiss()">
      {{ "CANCEL_ACTION" | translate }}
    </button>
  </div>
</form>
