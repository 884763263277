<ng-container *ngIf="user">
  <div
    class="d-block mb-2"
    *ngIf="user.email_address"
  >
    {{ "EMAIL" | translate }}:
    {{
        user.email_address
    }}
  </div>

  <ng-container *ngIf="user.okta">
    <button
      class="btn btn-primary btn-block me-2"
      *ngIf="!passwordResetted && !user.dealerIsOktaAccount"
      (click)="resetPassword()"
    >
      {{ "RESET_PASSWORD" | translate }}
    </button>
  </ng-container>
  <ng-container *ngIf="user.dealerIsOktaAccount">
    <button
    class="btn btn-primary btn-block me-2 reset"
    *ngIf="!passwordResetted && accountService.isHelpdesk() && (user.email_address !== '')"
    (click)="resetPassword()"
  >
    {{ "RESET_PASSWORD" | translate }}

    </button>
    <button
      class="btn btn-primary btn-block me-2 resend"
      *ngIf="
        accountService.isHelpdesk() && !user.linked &&
        ((user.email_address === '') ||
          resetted)
      "
      (click)="resendNewActivation()"
    >
      {{ "ACTIVATION.RESEND_ACTIVATION" | translate }}
    </button>
    <button
      class="btn btn-primary btn-block me-2 getactivation"
      *ngIf="
        accountService.isHelpdesk() && !user.linked &&
        ((user.email_address === '') &&
          activationResent)
      "
      (click)="getActivationLink()"
    >
      {{ "ACTIVATION.GET_ACTIVATION_URL" | translate }}
    </button>

    <button
      class="btn btn-primary btn-block me-2"
      *ngIf="accountService.isHelpdesk() && user.email_address"
      (click)="resetAccount()"
    >
      {{ "ACTIVATION.RESET_ACCOUNT" | translate }}
    </button>

    <button
      class="btn btn-primary btn-block me-2"
      *ngIf="accountService.isHelpdesk() && user.linked"
      (click)="unlinkAccount()"
    >
      {{ "ACTIVATION.UNLINK_ACCOUNT" | translate }}
    </button>
  </ng-container>

  <span class="text-success mt-2" *ngIf="passwordResetted">{{
    "PASSWORD_RESETTED" | translate
  }}</span>
  <span class="text-success mt-2" *ngIf="mailSend">{{
    "VERIFY_MAIL_SEND" | translate
  }}</span>
</ng-container>
