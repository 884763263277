import { Component, OnInit, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrls: ['./bottom-navbar.component.scss'],
})
export class BottomNavbarComponent implements OnInit {
  @Input() fixed: boolean = true;
  showFixedOptions: boolean = false;
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkScrollHeight();
    }, 0);
  }

  checkScrollHeight() {
    const wrapper = document.querySelector<HTMLElement>('.wrapper');
    if (wrapper) {
      const h = document.documentElement;
      const wrapperElement = wrapper;
      if (wrapperElement.offsetHeight / h.clientHeight < 1) {
        this.showFixedOptions = false;
        return false;
      } else {
        this.showFixedOptions = true;
      }
    } else {
      this.showFixedOptions = true;
    }
    return true;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const footer = document.getElementById('footer');
    const footerHeight = footer ? footer.clientHeight : 0;

    const h = document.documentElement;
    const b = document.body;
    const st = 'scrollTop';
    const sh = 'scrollHeight';

    const percent =
      ((h[st] || b[st]) /
        ((h[sh] || b[sh]) - (h.clientHeight + footerHeight))) *
      100;
    if (!this.checkScrollHeight()) {
      return false;
    }

    if (percent >= 100 || percent < 0) {
      this.showFixedOptions = false;
    } else {
      this.showFixedOptions = true;
    }
  }
}
