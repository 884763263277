"use strict";

import { CompanyGroup } from "./interfaces/company-group";
import { AccountSettings } from "./interfaces/account-settings";
import { AlertItem } from "./interfaces/cms/alert-item";
import { Quicklink } from "./interfaces/cms/quicklinks";
import { AccountInformation } from "./interfaces/account-information";
import { DropshipmentConsumer } from "./interfaces/dropshipment/dropshipment-consumer";
export let companyGroupSettings: CompanyGroup;
export let accountSettings: AccountSettings;
export let accountInformation: AccountInformation;
export let consumerFormData: DropshipmentConsumer;

// Save company group settings
export function saveSettings(newValue: CompanyGroup) {
  companyGroupSettings = newValue;
}

// Save account settings
export function saveAccountSettings(newValue: AccountSettings) {
  accountSettings = newValue;
}

// Save account information
export function saveAccountInformation(newValue: AccountInformation) {
  if (typeof newValue !== "undefined") {
    localStorage.setItem("country", newValue.country_code);
    accountInformation = newValue;
  }
}

// Save dropshipment consumer information
export function saveDropshipmentConsumerInformation(
  newValue: DropshipmentConsumer
) {
  consumerFormData = newValue;
}

// Clear dropshipment consumer information
export function clearDropshipmentConsumerInformation() {
  consumerFormData = null;
}

// Get alerts
export function getAlerts() {
  return sessionStorage.getItem("alerts")
    ? JSON.parse(sessionStorage.getItem("alerts"))
    : [];
}

// Save alerts
export function saveAlerts(newValue: AlertItem[]) {
  sessionStorage.removeItem("alerts");
  if (newValue) {
    sessionStorage.setItem("alerts", JSON.stringify(newValue));
  }
}

// Get quicklinks
export function getQuicklinks(companyGroupCode: string, folder?: string) {
  return sessionStorage.getItem(`quicklinks-${companyGroupCode}-${folder}`)
    ? JSON.parse(sessionStorage.getItem(`quicklinks-${companyGroupCode}-${folder}`))
    : [];
}

// Save quicklinks
export function saveQuicklinks(companyGroupCode: string, newValue: Quicklink) {
  sessionStorage.removeItem(`quicklinks-${companyGroupCode}`);
  if (newValue) {
    sessionStorage.setItem(
      `quicklinks-${companyGroupCode}`,
      JSON.stringify(newValue)
    );
  }
}

// Clear alerts
export function clearAlerts() {
  sessionStorage.removeItem("alerts");
}

// Clear settings
export function clearSettings() {
  accountSettings = null;
  accountInformation = null;
  companyGroupSettings = null;
}
