  <div class="d-block" [appStoryblok]="_editable">
    <ng-container *ngFor="let blok of content">
      <p [innerHTML]="renderHtml(blok)" [ngClass]="[blok.type, blok.attrs ? 'level_' + blok.attrs.level : '']">
      </p>
      <ng-container *ngFor="let body of blok.attrs?.body">
        <ndc-dynamic *ngIf="blok.type === 'blok' && blok.attrs" [ndcDynamicComponent]="components[body.component]" [ndcDynamicInputs]="body">
        </ndc-dynamic>
      </ng-container>
    </ng-container>
  </div>

