import { Component, OnInit, Input } from "@angular/core";
import { ApplicationService } from "src/app/services/application.service";
import { CmsService } from "src/app/services/cms.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { NewsItem } from "src/app/interfaces/cms/news-item";
import { AlertItem } from "src/app/interfaces/cms/alert-item";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NewsDetailsComponent } from "../../cms/news-details/news-details.component";
import { ConfirmDialogComponent } from "../../modal-components/confirm-dialog/confirm-dialog.component";
import * as _ from "underscore";
import { Router } from "@angular/router";

@Component({
  selector: "app-alerts",
  templateUrl: "./alerts.component.html",
  styleUrls: ["./alerts.component.scss"]
})
export class AlertsComponent implements OnInit {
  @Input() alerts: AlertItem[];
  @Input() isPreview: boolean;
  loaded: boolean;
  preorder = true;
  newsItems: NewsItem[];
  @Input() inlineAlerts: boolean;

  constructor(
    private cmsService: CmsService,
    private applicationService: ApplicationService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    this.getAlerts();
  }

  getAlerts() {
    this.cmsService.getAlerts().then((alerts: AlertItem[]) => {
      this.loaded = true;
      if (alerts && this.isPreview) {
        this.alerts = alerts;
      }

      if (alerts && !this.isPreview) {
        this.alerts = Array<AlertItem>();
        alerts.forEach((alert: AlertItem) => {
          if (!this.cmsService.checkIfAlertClosed(alert)) {
            this.alerts.push(alert);
          }
        });
      }
    });
  }

  showNewsItem(newsId) {
    // todo get newsitem with the id
    const cachedNewsItem = _.findWhere(this.newsItems, { id: newsId });
    if (cachedNewsItem) {
      const modalRef = this.modalService.open(ConfirmDialogComponent, {
        size: "lg",
        windowClass: "medium",
        container: "#modalContainer"
      });

      const newsItem = Object.assign({}, cachedNewsItem);
      newsItem.detailled = true;
      modalRef.componentInstance.isConfirm = false;
      modalRef.componentInstance.setContent(
        NewsDetailsComponent,
        "newsItem",
        newsItem
      );
    } else {
      this.router.navigateByUrl(
        `${this.applicationService.getSelectCompanyGroupCode()}/news/${newsId}`
      );
    }
  }
}
