import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SearchService } from "src/app/services/search.service";
import { ArticleService } from "src/app/services/article.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { ImageBankArticleDetails } from "src/app/interfaces/imageBank/image-bank-article-details";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { ApplicationService } from "src/app/services/application.service";
import { TranslateService } from "@ngx-translate/core";
import { HelperService } from "src/app/services/helper.service";
import { saveAs } from 'file-saver';

@Component({
  selector: "app-image-bank-article-details",
  templateUrl: "./image-bank-article-details.component.html",
  styleUrls: ["./image-bank-article-details.component.scss"]
})
export class ImageBankArticleDetailsComponent implements OnInit {
  [x: string]: any;
  modelId: string;
  article: ImageBankArticleDetails;
  articleImage: any;
  breadcrumbs: Breadcrumb[] = [];
  backUrl = "";
  hideBreadcrumbs = false;

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private articleService: ArticleService,
    private applicationService: ApplicationService,
    private translate: TranslateService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params["id"]) {
        this.modelId = params["id"];
        this.getArticleDetails();
      }
    });
    this.backUrl = this.route.snapshot.queryParams["u"] ? this.route.snapshot.queryParams["u"] : `${this.applicationService.getSelectCompanyGroupCode()}/search/#filter/&imagebank=1` ;
    if (this.modelId) {
      this.getArticleDetails();
      this.hideBreadcrumbs = true;
    }
  }

  setBreadCrumbs() {
    this.breadcrumbs.push(
      new Breadcrumb(
        this.translate.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/home"
      ),
      new Breadcrumb(
        this.translate.instant("IMAGEBANK"),
        this.backUrl
      )
    );
  }

  getArticleDetails() {
    this.searchService
      .articleDetails(this.modelId)
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.article = apiResponse.result;
        }
        this.setBreadCrumbs();
      });
  }

  downloadImage(index: number) {
    saveAs(this.article.f_img[index], this.article.f_img[index].substring(this.article.f_img[index].lastIndexOf('/') + 1))
  }

  downloadZip(modelId) {
    this.articleService.getDownloadZip(modelId).subscribe((blob: Blob) => {
      const file = new Blob([blob], { type: "application/octet-stream" });
      saveAs(file, `${this.article.description}.zip`);
    });
  }
}
