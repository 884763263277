import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivateInput } from '../interfaces/activation/activate-input';
import { Credentials } from '../interfaces/credentials';
import { OktaRegisterInput } from '../interfaces/okta-register-input';
import { HelpdeskService } from './helpdesk.service';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: "root",
})
export class AccountActivationService {
  constructor(
    private http: HttpClient,
    private helpdeskService: HelpdeskService,
    private helperService: HelperService
  ) {}

  checkUserAndHash(userCode: string, hash: string, code?: string) {

    return this.http.get(
      `${environment.apiendpoint}accounts/${userCode}/canactivate/${hash}${code ?'?code=' + code : ''}`
    );
  }

  checkCustomEmailAddress(
    emailAddress: string,
    userCode: string,
    hash: string
  ) {
    const data = {
      username: emailAddress,
    };
    return this.http.post(
      `${environment.apiendpoint}accounts/${userCode}/updateactivationusername/${hash}`,
      data
    );
  }

  activateAccount(
    userCode: string,
    hash: string,
    code: string,
    activateInput: ActivateInput
  ) {
    return this.http.post(
      `${environment.apiendpoint}accounts/${userCode}/activate/${hash}${
        code ? "?code=" + code : ""
      }${code ? "&nh=true" : "?nh=true"}`,
      activateInput
    );
  }

  linkAccount(userCode: string, hash: string, credentials: Credentials) {
    return this.http.post(
      `${environment.apiendpoint}accounts/${userCode}/linkaccount/${hash}`,
      credentials
    );
  }

  migrateAccount(
    userHash: string,
    hash: string,
    user: OktaRegisterInput
  ) {
    return this.http.post(
      `${environment.apiendpoint}accounts/${userHash}/migrateaccount/${hash}`,
      user
    );
  }

}
