import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { OpeningHour } from "../interfaces/opening-hour";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DealerService {
  private emitDataChange = new Subject<any>();
  changeEmitted$ = this.emitDataChange.asObservable();

  // Service message commands
  emitChange(change: OpeningHour) {
    this.emitDataChange.next(change);
  }

  constructor(private http: HttpClient) {}
  getOpeningHours() {
    return this.http.get(environment.apiendpoint + "dealers/openinghour");
  }
  addOpeningHour(openinghour: OpeningHour) {
    return this.http.post(
      environment.apiendpoint + "dealers/openinghour",
      openinghour
    );
  }
  updateOpeningHour(openingHour: OpeningHour) {
    return this.http.put(
      environment.apiendpoint + "dealers/openinghour",
      openingHour
    );
  }
  deleteOpeningHour(openingHourId: number) {
    return this.http.delete(
      environment.apiendpoint + "dealers/openinghour/" + openingHourId
    );
  }

  uploadLogo(fileData: string) {
    return this.http.post(environment.apiendpoint + "dealers/dealerlogo", {
      file_data: fileData,
    });
  }

  getAddresses() {
    return this.http.get(environment.apiendpoint + "address/all");
  }

  // STOCK LINK TOKENS
  dealerStockLink() {
    return this.http.get(`${environment.apiendpoint}dealers/stocklink`);
  }

  createDealerStockLinkToken() {
    return this.http.post(`${environment.apiendpoint}token/stock`, {});
  }

  activateDealerStock() {
    return this.http.post(`${environment.apiendpoint}dealers/stocklink/activate`, {});
  }

  getDealerStockDate() {
    return this.http.get(
      `${environment.apiendpoint}articles/stock?fileType=pdf`
    );
  }
}
