export enum BasketType {
  Normal = "Normal",
  Archived = "Archived",
  Checkout = "Checkout",
  Order = "Order",
  PreOrderBikes = "PreOrderBikes",
  PreOrderParts = "PreOrderParts",
  Replenishment = "Replenishment",
  Dropshipment = "Dropshipment",
}
