<div [ngClass]="[!article.single_article_code ? 'noSelection' : '', !isInternal ? 'readonly' : '']">
  <select class="form-control mb-2" name="selectedArticled"  [(ngModel)]="article.selected_replenishment_article" *ngIf="selectionByModel && isInternal" (change)="resetArticle()">
    <option [value]="undefined" selected>{{"MAKE_A_CHOICE"|translate}}</option>
    <ng-container *ngFor="let option of article.matrix.options">
    <option *ngFor="let optionArticle of option.value" [value]="option.value[0].single_article_code">
      {{
        option.value[0].single_article_code
      }} - {{ option.description }}
    </option>
  </ng-container>
  </select>
  <ng-container *ngFor="let option of article.matrix.options">
    <div class="d-inline-block w-100 mb-1" >

      <label
        class="d-block mt-2"
        *ngIf="article.matrix.options.length > 1 && option.value.length > 1"
        >
        {{ option.description }}
        <small class="d-block">{{
          option.value[0].single_article_code
        }}</small></label
      >

      <div *ngFor="let optionArticle of option.value">
        <div class="d-flex align-items-center" style="width: 100%" [class.opacity-50]="isInternal && selectionByModel && article.selected_replenishment_article !== option.value[0].single_article_code">
          <span class="me-2 article-info">
            {{ option.description }}
            <small class="d-block">{{
              option.value[0].single_article_code
            }}</small>
          </span>
          <div class="input-group">
            <div class="input-group-prepend d-flex gap-1">
              <span class="input-group-text p-0 d-flex"
                ><span
                  placement="top"
                  class="flex-fill"
                  style="min-height: 20px"
                  ngbTooltip="{{ 'CURRENT_STOCK' | translate }}"
                  >{{
                    replenishmentService.stockList[
                      optionArticle.single_article_code
                    ]?.stock
                  }}</span
                ></span
              >
              <span class="input-group-text p-0 d-flex">
                <span
                  placement="top"
                  class="flex-fill"
                  style="min-height: 20px"
                  ngbTooltip="{{ 'CURRENT_MIN_STOCK' | translate }}"
                >
                  {{
                    replenishmentService.stockList[
                      optionArticle.single_article_code
                    ]?.min_stock
                  }}
                  <small
                    class="d-block battery-indication"
                    [articleQuickView]="replenishmentService.stockList[
                        optionArticle.single_article_code
                      ]?.battery_article_code"
                    *ngIf="
                      replenishmentService.stockList[
                        optionArticle.single_article_code
                      ]?.battery_article_code
                    "
                    ><span class="material-icons"> battery_charging_full </span>
                    {{
                      replenishmentService.stockList[
                        optionArticle.single_article_code
                      ]?.battery_article_code
                    }}
                  </small>
                </span>
              </span>
            </div>
            <input
              type="number"
              class="form-control me-2 px-1 text-center"
              style="max-width: 70px"
              min="0"
              [max]="optionArticle.max_dealer_stock"
              [(ngModel)]="optionArticle.min_dealer_stock"
              [disabled]="selectionByModel && article.selected_replenishment_article !== option.value[0].single_article_code"
              (change)="
                checkMinInput(optionArticle); getBatteries(); inputChange()
              "
              placement="top"
              ngbTooltip="{{ 'DESIRED_MIN_STOCK' | translate }}"
            />

            <app-stock-status
              [showText]="false"
              *ngIf="article.stock_status < 7"
              [article]="article"
              style="margin-top: 2.5px"
            ></app-stock-status>
            <span
              *ngIf="article.nett_prices"
              class="ms-2"
              style="margin-top: 7.5px"
            >
              <app-nett-price
                [price]="getNettPrice(optionArticle.single_article_code)"
              ></app-nett-price>
            </span>
          </div>
        </div>
        <div
          *ngIf="
            article.has_mandatory_item && optionArticle.min_dealer_stock > 0 && article.selected_replenishment_article === option.value[0].single_article_code
          "
          class="p-2 d-block mb-2 rounded mt-1 battery-container position-relative"
          style="background-color: #eee"
        >
          <select
            class="form-control"
            [(ngModel)]="optionArticle.battery_article_code"
            (change)="inputChange()"
            [ngClass]="{
              'border-danger replenishment-invalid':
                optionArticle.min_dealer_stock > 0 &&
                !optionArticle.battery_article_code
            }"
          >
            <option
              *ngFor="let optionBattery of batteries"
              [value]="optionBattery.single_article_code"
            >
              {{ optionBattery.description }} ({{
                optionBattery.single_article_code
              }}) - &euro; {{ optionBattery.net_price?.value }}
            </option>
          </select>
          <span
            *ngIf="!optionArticle.battery_article_code"
            class="position-absolute bg-success text-white px-2 py-1 rounded text-sm"
            style="top: 11px; left: 10px; pointer-events: none"
            >
           {{ "SELECT_FLEX_BATTERY" | translate }}
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            </span
          >
        </div>
      </div>
    </div>
  </ng-container>
</div>
