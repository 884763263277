<!-- Bikes -->
<div class="d-flex flex-column flex-sm-row">
  <span *ngIf="bikesCount" class="me-2">
    <ng-container
      *ngIf="
        companyGroupCode === 'WG' || companyGroupCode === 'GHO';
        else bikesNormal
      "
    >
      {{ "POSITIONS" | translate }}: {{ bikesCount }}<br />
      <small>{{ "BIKES_AMOUNT" | translate }}: {{ totalBikes }}</small
      ><br />
    </ng-container>
    <ng-template #bikesNormal>
      {{ "BICYCLES" | translate }}: {{ bikesCount }}<br />
      <small>{{ "QUANTITY" | translate }}: {{ totalBikes }}</small
      ><br />
    </ng-template>
  </span>

  <ng-container *ngIf="bikesCount && partsCount">
    <br />
  </ng-container>

  <!-- Parts -->
  <span *ngIf="partsCount">
    <ng-container
      *ngIf="
        companyGroupCode === 'WG' || companyGroupCode === 'GHO';
        else partsNormal
      "
    >
      {{ "POSITIONS" | translate }}: {{ partsCount }}<br />
      <small>{{ "PARTS_AMOUNT" | translate }}: {{ totalParts }}</small
      ><br />
    </ng-container>
    <ng-template #partsNormal>
      {{ "PARTS" | translate }}: {{ partsCount }} <br />
      <small>{{ "QUANTITY" | translate }}: {{ totalParts }}</small
      ><br />
    </ng-template>
  </span>
</div>
