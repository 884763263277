import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { OrderLine } from '../interfaces/financial/openorders/order-line';

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  getDashboardData() {
    return this.http.get(
      `${environment.apiendpoint}dealerdashboard/`
    );
  }

  getDashboardTurnover() {
    return this.http.get(`${environment.apiendpoint}financial/turnover/`);
  }

}
