import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  ArticleType,
  AvailableArticleTypes
} from "src/app/interfaces/article-type";

@Component({
  selector: "app-article-image",
  templateUrl: "./article-image.component.html",
  styleUrls: ["./article-image.component.scss"]
})
export class ArticleImageComponent implements OnInit {
  @Input() imageUrl: string;
  @Input() description: string;
  @Input() articleType: string;
  @Input() productGroup: string[];
  @Input() images: string[] = [];
  @Input() classList: string;
  @Input() useLazyLoad = false;
  @Input() width: number;
  @Input() height: number;
  @Input() showControls: boolean;
  @Output() imageClick: EventEmitter<any> = new EventEmitter<any>();
  assetsPath = "assets/images/";
  placeholder = "bicycle-solid.svg";

  currentImageIndex = 0;
  constructor() {
    // Detect if browser has native lazy loading
    if ('loading' in HTMLImageElement.prototype) {
      this.useLazyLoad = false;
    }
  }

  ngOnInit() {

    if (this.showControls) {
      setTimeout(() => {
        this.classList = "zoom";
      },1)
    }

    // Don't show controls when the first and the second image is the same
    if (this.images && this.images.length > 1) {
      if (this.images[0] === this.images[1]) {
        this.showControls = false;
      }
    }

    if (this.articleType && this.articleType.startsWith("F")) {
      this.placeholder = this.assetsPath + "bicycle-solid.svg";
    } else {
      this.placeholder = this.assetsPath + "part.svg";
    }
  }

  previousImage() {
    this.currentImageIndex = (this.currentImageIndex === 0) ? this.images.length - 1 : this.currentImageIndex - 1;
  }

  nextImage() {
    this.currentImageIndex = (this.currentImageIndex === this.images.length - 1) ? 0 : this.currentImageIndex + 1;
  }

  public handleMissingImage(event: Event) {
    (event.target as HTMLImageElement).src = `https://static.accentry.com/images/coming_soon.jpg`;
  }
}
