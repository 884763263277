import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SearchService } from "../../../services/search.service";
import { ApiResponse } from "../../../interfaces/api-response";
import { Article } from "../../../interfaces/article";
import { NgbModal, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { ArticleFinderModalComponent } from "../../search-components/article-finder-modal/article-finder-modal.component";
import * as moment from "moment";
import * as appGlobal from "src/app/globals";
import { WarrantyFormRequestInput } from "src/app/interfaces/warranty/warranty-form-request-input";
import { WarrantyService } from "src/app/services/warranty.service";
import { NgbDateFormatter } from "src/app/ngbFormatter";
import { ApplicationService } from "src/app/services/application.service";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-warranty-request-form",
  templateUrl: "./warranty-request-form.component.html",
  styleUrls: ["./warranty-request-form.component.scss"],
})
export class WarrantyRequestFormComponent implements OnInit {
  formGroup: FormGroup;
  @Input() type = "warranty";
  loading = false;
  unknownArticles = {
    bicycle: false,
    article: false,
  };
  bicycleArticle: Article;
  damagedArticle: Article;
  selectedInvoiceDate: NgbDate;
  validated = false;
  formSend = false;
  pageIds: any = {
    warranty: "",
    returns: "",
    returns_no_warranty: "",
    aftersales: "",
  };
  content: any;
  mediaData: any = {};
  brands: any = {
    GHO: [],
    LAP: [
      {
        brandId: 6,
        description: "Lapierre",
      },
      {
        brandId: 25,
        description: "Ghost",
      },
      {
        brandId: 101,
        description: "Winora",
      },
      {
        brandId: 101,
        description: "Haibike",
      },
    ],
  };
  warrantyOptions = ["warranty", "returns", "returns_no_warranty"];
  companyGroup: string;
  get maxDate(): NgbDate {
    return new NgbDate(
      moment().get("year"),
      moment().get("month") + 1,
      moment().get("date")
    );
  }

  constructor(
    public applicationService: ApplicationService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private searchService: SearchService,
    private modalService: NgbModal,
    private warrantyService: WarrantyService,
    private dateFormatter: NgbDateFormatter,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.companyGroup = this.applicationService.getSelectCompanyGroupCode();

    this.pageIds["warranty"] =
      appGlobal.companyGroupSettings.warranty_wizard_id_warranty;
    this.pageIds["returns"] =
      appGlobal.companyGroupSettings.warranty_wizard_id_returns;
    this.pageIds["returns_no_warranty"] =
      appGlobal.companyGroupSettings.warranty_wizard_id_returns;
    if (this.type === "aftersales") {
      this.pageIds["aftersales"] = "960422090";
    }
    this.buildForm();
  }

  // paste in frnumber input
  framenumberPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData || window["clipboardData"];
    this.getBicycle(clipboardData.getData("text"));
  }

  // change in frnumber input
  framenumberChange(event) {
    this.getBicycle(event.srcElement.value);
  }

  // Get bicycle data
  getBicycle(framenumber: string) {
    if (framenumber) {
      if (this.companyGroup === "LAP") {
        const firstChar = framenumber.charAt(0).toLowerCase();
        switch (firstChar) {
          case "g":
            this.formGroup.get("warranty_brand").setValue(25);
            break;
          default:
            this.formGroup.get("warranty_brand").setValue(6);
            break;
        }
      }
      this.searchService
        .searchInstant(framenumber)
        .subscribe((apiResponse: ApiResponse) => {
          if (
            apiResponse &&
            apiResponse.success &&
            apiResponse.result &&
            apiResponse.result.length
          ) {
            const bicycle = apiResponse.result[0] as Article;
            this.formGroup.get("description").setValue(bicycle.description);
            this.formGroup.get("caption").setValue(bicycle.caption);
            this.formGroup.get("season").setValue(bicycle.season);
            this.bicycleArticle = bicycle;
          }
        });
    }
  }

  // Set the validations
  buildForm() {
    let EMAILPATTERN =
      /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    this.formGroup = new FormGroup({
      request_type: new FormControl("warranty", [Validators.required]),
      framenumber: new FormControl("", [Validators.required]),
      description: new FormControl("", [Validators.required]),
      caption: new FormControl(
        "",
        this.type !== "aftersales" ? [Validators.required] : []
      ),
      warranty_brand: new FormControl(this.companyGroup === "LAP" ? 27 : 25, [
        Validators.required,
      ]),
      season: new FormControl(
        "",
        this.type !== "aftersales" ? [Validators.required] : []
      ),
      purchase_date: new FormControl(""),
      damaged_article: new FormControl(
        "",
        this.type !== "aftersales" ? [Validators.required] : []
      ),
      damage_description: new FormControl(
        "",
        this.type !== "aftersales" ? [Validators.required] : []
      ),
      file: new FormControl(
        "",
        this.type !== "aftersales" ? [Validators.required] : []
      ),
      invoice_file: new FormControl("", [Validators.required]),
      remarks: new FormControl(
        "",
        this.applicationService.getSelectCompanyGroupCode() === "GHO"
          ? [Validators.required]
          : []
      ),
      contact_person: new FormControl(
        localStorage.getItem("sales_person")
          ? localStorage.getItem("sales_person")
          : "",
        [Validators.required]
      ),
      email_address: new FormControl("", [
        Validators.required,
        Validators.pattern(EMAILPATTERN),
      ]),
      phone_number: new FormControl(""),
    });

    if (this.applicationService.getSelectCompanyGroupCode() === "LAP") {
      this.formGroup.get("damage_description").setValidators([]);
      this.formGroup.get("damage_description").updateValueAndValidity();
    }
  }

  setType(type: string) {
    this.type = type;
    if (this.applicationService.getSelectCompanyGroupCode() === "LAP") {
      switch (type) {
        case "returns_no_warranty":
          this.formGroup.get("file").clearValidators();
          this.formGroup.get("file").updateValueAndValidity();
          this.formGroup.get("caption").clearValidators();
          this.formGroup.get("caption").updateValueAndValidity();
          this.formGroup.get("invoice_file").clearValidators();
          this.formGroup.get("invoice_file").updateValueAndValidity();
          break;
        default:
          this.formGroup.get("file").setValidators([Validators.required]);
          this.formGroup.get("file").updateValueAndValidity();
          this.formGroup.get("caption").setValidators([Validators.required]);
          this.formGroup.get("caption").updateValueAndValidity();
          this.formGroup
            .get("invoice_file")
            .setValidators([Validators.required]);
          this.formGroup.get("invoice_file").updateValueAndValidity();
          break;
      }
    }
  }

  // Show article finder modal
  showArticleFinder() {
    const modalRef = this.modalService.open(ArticleFinderModalComponent, {
      centered: true,
      container: "#modalContainer",
      backdrop: "static",
    });

    modalRef.componentInstance.articleSelected$.subscribe(
      (article: Article) => {
        if (
          article &&
          (article.single_article_code || article.article_codes.length)
        ) {
          this.damagedArticle = article;
          this.formGroup
            .get("damaged_article")
            .setValue(
              article.single_article_code
                ? article.single_article_code
                : article.article_codes[0]
            );
        } else {
          this.unknownArticles.article = true;
        }
      }
    );
  }

  // Get base64 string from image
  getImageData(e, name: string) {
    const input = e.currentTarget;
    if (input.files && input.files.length) {
      const file = input.files[0];
      const filesize = Number((file.size / 1024 / 1024).toFixed(4)); // MB
      var reader = new FileReader();

      if (filesize <= 10) {
        reader.readAsDataURL(input.files[0]);
        reader.onload = (evt) => {
          this.mediaData[name] = evt.target["result"];
          this.formGroup.get(name).setValue(this.mediaData[name]);
        };
      } else {
        this.alertService.showErrorNotification(
          `${this.translateService.instant("FILE_SIZE_LIMIT")}: ${file["name"]}`
        );
      }
    }
  }

  // Submit the form
  submit() {
    this.validated = true;
    this.formGroup
      .get("purchase_date")
      .setValue(moment(this.selectedInvoiceDate).format("YYYYMMDD"));
    if (this.formGroup.valid) {
      this.applicationService.showLoader(true);
      this.formGroup.get("request_type").setValue(this.type);
      localStorage.setItem(
        "sales_person",
        this.formGroup.get("contact_person").value
      );
      const input: WarrantyFormRequestInput = this.formGroup
        .value as WarrantyFormRequestInput;
      input.purchase_date = moment(
        this.dateFormatter.format(this.selectedInvoiceDate),
        "YYYY-MM-DD"
      ).format("YYYYMMDD");
      this.warrantyService
        .submitWarrantyForm(input)
        .subscribe((apiResponse: ApiResponse) => {
          this.validated = false;
          this.formSend = apiResponse.success;
          this.applicationService.hideLoader();
        });
    } else {
      this.alertService.showErrorNotification(
        this.translateService.instant("REQUIRED_FIELDS_TEXT")
      );
    }
  }
}
