import { Injectable } from "@angular/core";
import { Slideshow } from "src/app/interfaces/cms/slideshow";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class SlideshowService {
  storageKey = `slideshow_`;

  constructor(private modalService: NgbModal, private http: HttpClient) {}

  getSlideshows() {
    return this.http.get(environment.apiendpoint + "cms/slideshows");
  }

  public checkIfSlideshowSeen(slideshow: Slideshow) {
    const slideshowStorage = localStorage.getItem(`${this.storageKey}${slideshow.id}`);
    return slideshowStorage ? true : false;
  }

  public markSlideshowAsSeen(slideshow: Slideshow) {
    localStorage.setItem(`${this.storageKey}${slideshow.id}`, "1");
  }

}
