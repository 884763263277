export interface AcademyAccess {
  has_access: boolean;
  first_name: string;
  middle_name: string;
  last_name: string;
  email_verified: string;
  email_new: string;
  job_positions: string[];
  current_account: string;
  access_companies: number[];
}

export class AcademyAccess implements AcademyAccess {}
