import { Component, OnInit, Input } from "@angular/core";
import { Widget } from "src/app/interfaces/cms/widget";
import { Router } from "@angular/router";
import { ApplicationService } from "src/app/services/application.service";
import { TrackingService } from "src/app/services/tracking.service";
import { Campaign } from "src/app/interfaces/tracking/campaign";

@Component({
  selector: "app-widget",
  templateUrl: "./widget.component.html",
  styleUrls: ["./widget.component.scss"]
})
export class WidgetComponent implements OnInit {
  @Input() widget: Widget;
  @Input() hideLabel: boolean;
  constructor(private router: Router, private applicationService: ApplicationService, private trackingService: TrackingService) {}

  ngOnInit() {}

  gotoUrl() {
    let internalUrl = false;
    const url = this.widget.language_definitions[0].link_url;
    const urlType = this.widget.language_definitions[0].url_type;
    let formattedUrl = url;

    if (url && url.indexOf("accentry.com") >= 0) {
      internalUrl = true;

      if (url.includes("alpha.")) {
        formattedUrl = url.replace("https://alpha.accentry.com/", "");
      }

      else if (url.includes("beta.")) {
        formattedUrl = url.replace("https://beta.accentry.com/", "");
      }

      else if (url.includes("shop.")) {
        formattedUrl = url.replace("https://shop.accentry.com/", "");
      }

      else if (url.includes("classic.")) {
        formattedUrl = url.replace("https://classic.accentry.com/", "");
      }

      else {
        formattedUrl = url.replace("https://www.accentry.com/", "");
      }

      if (
        formattedUrl.indexOf("search#") >= 0 &&
        formattedUrl.indexOf("filter") < 0
      ) {
        formattedUrl = formattedUrl.replace("search#", "articleDetails/");
      }

      if (
        formattedUrl.indexOf("results#") >= 0 &&
        formattedUrl.indexOf("filter") < 0
      ) {
        formattedUrl = formattedUrl.replace("results#", "articleDetails/");
      }
    }

    if (urlType === 1) {
      internalUrl = true;
      formattedUrl = `${this.applicationService.getSelectCompanyGroupCode()}/companyPage/${formattedUrl}`;
    }

    if (urlType === 2) {
      internalUrl = true;
      formattedUrl = `${this.applicationService.getSelectCompanyGroupCode()}/news/${formattedUrl}`;
    }

    this.trackingService.campaign("promoClick", [new Campaign(this.widget.id, this.widget.language_definitions[0].title, this.widget.position, formattedUrl)]);

    if (internalUrl) {
      formattedUrl = formattedUrl.replace("results", "search");
      formattedUrl = formattedUrl.replace("news#details/", "news/");
      if (formattedUrl.indexOf("search") >= 0) {
        formattedUrl += "&widgetId=" + this.widget.id;
      }

      this.router.navigateByUrl(formattedUrl);
    } else {
      window.open(formattedUrl, "_new");
    }
  }
}
