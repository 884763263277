import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LotteryRegistration } from '../interfaces/lottery-registration';
import { environment } from 'src/environments/environment';
import { PersonalAddress } from '../interfaces/partner-portal/personal-address';
import { map } from 'rxjs';
import { ApiResponse } from '../interfaces/api-response';

@Injectable({
  providedIn: 'root',
})
export class PartnerPortalService {
  constructor(private http: HttpClient) {}

  registerUser(emailAddress: string) {
    return this.http.post(`${environment.apiendpoint}partnerportal/users`, {
      user_name: emailAddress,
    });
  }

  getPersonalAddress() {
    return this.http
      .get(`${environment.apiendpoint}baskets/personaldata`)
      .pipe(map((response: ApiResponse) => response?.result));
  }

  savePersonalAddress(personalAddress: PersonalAddress) {
    return this.http.post(
      `${environment.apiendpoint}baskets/personaldata`,
      personalAddress
    );
  }
}
