import { Component, OnInit } from "@angular/core";
import { SearchService } from "src/app/services/search.service";
import { ApplicationService } from "src/app/services/application.service";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ArticleModalComponent } from "src/app/components/modal-components/article-modal/article-modal.component";
import jspdf from 'jspdf';
import html2canvas from "html2canvas";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { ApiResponse } from "src/app/interfaces/api-response";
import { timer, forkJoin } from "rxjs";
import { ImagebankService } from "src/app/services/imagebank.service";
import { ArticleService } from "src/app/services/article.service";
import { saveAs } from 'file-saver';

@Component({
  selector: "app-compare-articles",
  templateUrl: "./compare-articles.component.html",
  styleUrls: ["./compare-articles.component.scss"],
})
export class CompareArticlesComponent implements OnInit {
  objectArticleData: any = {};
  compareArticleData: any = [];
  selected: any = [];
  specCount: any = {};
  externalIds: any = [];
  compareArticleList: any = [];
  generating: boolean;
  showPdfButton: boolean;
  loading: boolean;

  constructor(
    private searchService: SearchService,
    private applicationService: ApplicationService,
    private articleService: ArticleService,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private loadingBar: LoadingBarService
  ) {}

  ngOnInit() {
    this.compareArticleList = this.externalIds.length
      ? this.externalIds
      : this.applicationService.getCompareListItems();

    this.showArticles();
  }

  parseSpecs(specs) {
    let specList: any = [];
    Object.keys(specs).forEach(function (key) {
      specList = specList.concat(specs[key]["items"]);
    });
    return specList;
  }

  ngOnDestroy() {
    this.articleService.selectedArticleSpecCode = undefined;
  }

  setImages() {
    const imgApiCalls = [];
    let promises = [];
    this.showPdfButton = false;
    for (let i of this.compareArticleData) {
      imgApiCalls.push(this.articleService.getDownloadImage(i.id, 0));
    }
    forkJoin(imgApiCalls).subscribe((results: any[]) => {
      let count = 0;
      const container = document.getElementById("comparedArticles");
      if (container) {
        const images = container.getElementsByClassName("article-image");
        promises = [];
        for (let r of results) {
          const reader = new FileReader();
          const blob = this.generateImage(r);

          reader.readAsDataURL(blob);
          promises.push(
            new Promise((resolve, reject) => {
              reader.onloadend = () => {
                const base64data = reader.result as string;
                const element = images[count] as HTMLImageElement;
                if (element) {
                  element.src = base64data;
                }

                resolve(true);
                count++;
              };
            })
          );
        }
      }
      Promise.all(promises).then(() => {
        this.showPdfButton = true;
      });
    });
  }

  showArticles() {
    this.applicationService.hideLoader();
    this.loading = false;
    let tempStorageItems = [];
    const storageItems = this.applicationService.getCompareListItems();
    const articleList: any = this.externalIds.length
      ? this.externalIds
      : storageItems;
    const tempArticleData: any = [];
    const compareArticles: any = [];
    this.applicationService.showLoader(true);
    if (articleList.length) {
      this.loading = true;
      const count = articleList.length;
      this.searchService
        .getMultiArticles(articleList)
        .subscribe((responseList) => {
          this.applicationService.hideLoader();
          for (let i = 0; i < count; i++) {
            const result: ApiResponse = responseList[i] as ApiResponse;
            if (result && result.success) {
              const article = result.result;
              article.allSpecs = this.searchService.parseSpecs(article.specs);
              article.specList = this.parseSpecs(article.allSpecs);

              tempArticleData.push(article);
              this.objectArticleData[article.id] = article;
              if (i < 3) {
                this.selected[i] = article.id;
                compareArticles.push(article);
              }
            } else {
              // When no data found remove from compare list
              this.removeFromList({
                id: articleList[i],
              });
            }
          }

          this.compareSpecs(compareArticles, false);
          this.compareArticleData = tempArticleData;
          this.setImages();
        });
    }
  }

  // Check which specs have overlap
  compareSpecs(articles, finalize) {
    Object.keys(articles).forEach((key) => {
      const article = articles[key];

      Object.keys(article["allSpecs"]).forEach((key) => {
        const specs = article["allSpecs"][key];
        for (let i = 0; i < specs["items"].length; i++) {
          const spec = specs["items"][i];
          if (!finalize) {

            if (spec["value"] && spec["value"].indexOf("<ul") < 0) {
              if (!this.specCount[spec["code"]]) {
                this.specCount[spec["code"]] = 1;
              } else {
                this.specCount[spec["code"]]++;
              }
            }
          } else {
            if (this.specCount[spec["code"]] !== articles.length) {
              delete specs["items"][i];
            }
          }
        }
      });

      if (finalize) {
        article.allSpecs = article["allSpecs"];
      }
    });

    if (!finalize) {
      this.compareSpecs(articles, true);
    }
  }

  showArticleDetails(article) {
    const modalRef = this.modalService.open(ArticleModalComponent, {
      size: "lg",
      container: "#modalContainer",
    });
    modalRef.componentInstance.article = article;
    modalRef.componentInstance.modelId = article["id"];
    modalRef.componentInstance.article.img = article["images"];
    modalRef.componentInstance.showInstant();
  }

  removeFromList(article) {
    this.applicationService.addOrRemoveToCompareList(article);

    for (let i = 0; i < this.selected.length; i++) {
      if (this.selected[i] === article.id) {
        this.selected.splice(i, 1);
      }
    }

    this.compareArticleList = this.applicationService.getCompareListItems();
    this.specCount = {};
    this.showArticles();
    this.stopLoading();
  }

  articleSelected(articleId, index) {
    const comparableArticles = [];
    this.selected[index] = articleId;
    this.specCount = {};

    for (let i = 0; i < this.selected.length; i++) {
      if (i < 3) {
        const article = this.objectArticleData[this.selected[i]];
        article.allSpecs = this.searchService.parseSpecs(article.specs);
        comparableArticles.push(article);
      }
    }
    this.compareSpecs(comparableArticles, false);
    this.setImages();
  }

  downloadPdf() {
    this.applicationService.showLoader(true);
    this.generating = true;
    document.body.scrollTop = 0;
    const html = document.documentElement;
    if (html) {
      html.scrollTop = 0;
    }
    const data = document.getElementById("comparedArticles");
    timer(300).subscribe(() => {
      html2canvas(data).then((canvas) => {
        const imgWidth = 280;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL("image/jpeg");
        const pdf = new jspdf(
          this.compareArticleData.length > 2 ? "l" : "p",
          "mm",
          "a4"
        ); // A4 size page of PDF
        const position = 0;

        pdf.addImage(contentDataURL, "JPG", 10, 0, imgWidth, imgHeight);
        const blob = pdf.output("blob");
        const file = new Blob([blob], { type: "application/pdf" });
        saveAs(file, "compared_articles.pdf");
        this.applicationService.hideLoader();
        this.generating = false;
      });
    });
  }

  // Generate image
  generateImage(blob) {
    const file = new Blob([blob], { type: "image/jpg" });
    return file;
  }

  empty() {
    this.compareArticleList = [];
    this.selected = [];
    this.applicationService.emptyCompareList();
  }

  stopLoading() {
    this.loadingBar.complete();
  }
}
