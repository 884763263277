<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'NEW_AGREEMENT'|translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
    </button>
</div>

<div class="modal-body p-3">
  <div class="mb-3" *ngIf="enabled">
    <label>{{'YEAR'|translate}}</label>
    <select class="form-control" [(ngModel)]="selectedYear">
      <option *ngFor="let option of options" [value]="option">{{option}}</option>
    </select>
  </div>
  <div class="mb-3" *ngIf="!enabled">
    Een nieuwe jaarafspraak aanmaken is weer mogelijk vanaf 1 september.
  </div>
</div>
<div class="modal-footer">
  <span class="col-md-12 p-0">
    <button class="btn btn-success " *ngIf="enabled" (click)="confirm()">{{'CONFIRM' | translate}}</button>
    <button class="btn btn-success " *ngIf="!enabled" (click)="modal.dismiss()">{{'CLOSE' | translate}}</button>
  </span>
</div>
