import { Component, OnInit } from "@angular/core";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-profiles-page",
  templateUrl: "./profiles-page.component.html",
  styleUrls: ["./profiles-page.component.scss"]
})
export class ProfilesPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  constructor(
    private translateService: TranslateService,
    private applicationService: ApplicationService
  ) {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/home"
      ),
      new Breadcrumb(this.translateService.instant("PROFILES"), "#")
    ];
  }

  ngOnInit() {}
}
