<ng-template #rt let-r="result" let-t="term">
  <div class="autocomplete" *ngIf="autocompleteVisible">
    <div (click)="r.article_codes.length === 1 ? selectArticle(r, r.article_codes[0]) : undefined; $event.stopPropagation()" (mousedown)="r.article_codes.length > 1 ? r.codesVisible = true : undefined" (mouseleave)="r.codesVisible = false" class="article-item">
      <div class="w-100 text-center" *ngIf="!resultCount && r.index === 0">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </div>
      <div *ngIf="r.model_code" class="d-flex align-items-center">
        <app-article-image class="d-inline align-items-center me-2" [articleType]="r.article_type" [imageUrl]="r.thumbs[0]" *ngIf="r.thumbs && r.thumbs.length"
          [description]="r.description" [classList]="'img-thumbnail'">
        </app-article-image>
        <div class="d-inline-block">
          <span class="article-title d-block" [innerHTML]="r.description"></span>
          <span class="article-subtitle d-block" [innerHTML]="r.caption"></span>
          <span *ngIf="r.retail_price" [innerHTML]="r.retail_price.value |  currencyConvert: r.retail_price.currency"></span>
        </div>
      </div>
      <div class="code-overlay" [class.d-flex]="r.codesVisible">
        <span class="add-article-label">{{ 'ADD_ARTICLE_CODE' | translate}}</span>
        <ng-container *ngFor="let code of r.article_codes">
          <button class="code-button" (click)="selectArticle(r, code); r.codesVisible = false">{{ code }}</button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'SEARCH'|translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
    </button>
</div>

<div class="modal-body p-3">
  <div class="row">
    <div class="mb-3 w-100 px-3">
      <label for="typeahead-http">{{'ARTICLE_NO'|translate}} / {{'DESCRIPTION'|translate}}</label>
      <input
        required
        id="typeahead-http"
        #searchText
        type="text"
        class="form-control me-sm-2 ms-auto col-md-12"
        [resultTemplate]="rt"
        [(ngModel)]="model"
        [ngbTypeahead]="search"
        [placeholder]="'SEARCH_BY_ARTICLE_NUMBER_KEYWORD_SEASON'|translate"
        (selectItem)="onSelect($event)"
        #instance="ngbTypeahead"
        (focus)="autocompleteVisible = true"
        (keyup)="detectChange($event)"
        [value]="searchText"
        [ngClass]="resultCount >= 5 ? 'showMore' : ''"
        (clickOutside)="focus = false"
      />

      <span *ngIf="searching">{{'LOADING'|translate}}...</span>
      <div class="invalid-feedback" *ngIf="searchFailed">{{'NO_RESULTS'|translate}}</div>
    </div>
  </div>
  <button class="btn btn-primary" (click)="unknownArticle()" *ngIf="showUnknown">{{'PART_UNKNOWN'|translate}}</button>
</div>
