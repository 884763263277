import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { WarrantyArticle } from "src/app/interfaces/warranty/warranty-article";
import { WarrantyService } from "src/app/services/warranty.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WarrantyWizardComponent } from "../../warranty-wizard/warranty-wizard.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-warranty-details-article",
  templateUrl: "./warranty-details-article.component.html",
  styleUrls: ["./warranty-details-article.component.scss"]
})
export class WarrantyDetailsArticleComponent implements OnInit {
  @Input() article: WarrantyArticle;
  @Input() showControls: boolean;
  @Output() deleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() added: EventEmitter<any> = new EventEmitter<any>();
  descriptions = {
    "GAR": "WARRANTY",
    "SER": "SERVICE",
    "WDL": "RETURN",
    "REC": "CALLBACK"
  };

  constructor(
    private warrantyService: WarrantyService,
    private modalService: NgbModal,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {}

  deleteArticle() {
    if(confirm(this.translateService.instant("DELETE_ARTICLE"))) {
      this.warrantyService
        .deleteArticle(this.article.warranty_id, this.article.warranty_article_id)
        .subscribe((apiResponse: ApiResponse) => {
          if (apiResponse.success) {
            this.deleted.next(true);
          }
        });
    }
  }

  editArticle() {
    const modalRef = this.modalService.open(WarrantyWizardComponent, {
      centered: true,
      container: "#modalContainer",
      size: "lg",
      backdrop: "static"
    });
    modalRef.componentInstance.warrantyId = this.article.warranty_id;
    modalRef.componentInstance.edit(this.article);
    modalRef.componentInstance.articledAdded$.subscribe((response: boolean) => {
      if (response) {
        this.added.next(true);
      }
    });
  }
}
