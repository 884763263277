<div class="row">
  <div class="col-md-12 card border-0">
    <div class="card-body">
      <h5>{{'OVERVIEW'|translate}}</h5>
      <div class="row mx-0">
        <div class="col-4 ps-0">
          <ng-container *ngIf="!agreementSubmitted && showRemarks">
            <label>{{'REMARKS'|translate}}</label>
            <textarea class="form-control mb-2 bg-white" rows="5" *ngIf="type === 'bikes'" [(ngModel)]="yearAgreementModel['remarks']"
              [disabled]="yearAgreementModel.locked || !isHelpdesk"></textarea>
            <textarea class="form-control mb-2 bg-white remarks_ghost" rows="5" *ngIf="type === 'dach' && yearAgreementModel['ghost_dealer'] && applicationService.getSelectCompanyGroupCode() === 'GHO'" [(ngModel)]="yearAgreementModel['remarks_gho']"
              [disabled]="yearAgreementModel.locked || !isHelpdesk"></textarea>
            <textarea class="form-control mb-2 bg-white remarks_wg" rows="5" *ngIf="type === 'dach' && yearAgreementModel['winora_dealer'] && applicationService.getSelectCompanyGroupCode() === 'WG'" [(ngModel)]="yearAgreementModel['remarks_wg']"
              [disabled]="yearAgreementModel.locked || !isHelpdesk"></textarea>
            <ng-content></ng-content>
            <ng-content select="[text]"></ng-content>
            <ngb-alert *ngIf="invalidTabs && invalidTabs.length" [dismissible]="false" type="danger" class="p-3">
              {{'CHECKTABS'|translate}}:
              <ul>
                <li *ngFor="let tab of invalidTabs">{{tab}}</li>
              </ul>
            </ngb-alert>
            <ng-content select="[send]"></ng-content>
            <button class="btn btn-success btn-lg  mt-3" *ngIf="!yearAgreementModel.signed && isHelpdesk"  [disabled]="yearAgreementModel.locked || !isHelpdesk"
              (click)="save()">{{'SAVE'|translate}}</button>
          </ng-container>
          <ng-container *ngIf="agreementSubmitted">
            <ngb-alert type="success" [dismissible]="false">
              <p class="p-3">{{'AGREEMENT_SUBMITTED'|translate}}</p>
            </ngb-alert>
            <button class="btn btn-primary" (click)="download()">{{'VIEW_AGREEMENT'|translate}}</button>
          </ng-container>
        </div>
        <div class="col-8 position-relative">
          <pdf-viewer class="pdf customScrollbar block float-left w-100" [src]="pdfSrc">
          </pdf-viewer>
          <button class="btn btn-dark position-absolute download-agreement" *ngIf="pdfSrc"
            (click)="download()">{{'DOWNLOAD'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
