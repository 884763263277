import { Pipe, PipeTransform } from "@angular/core";
import { User } from "../interfaces/user";

@Pipe({
  name: "SearchUserPipe"
})
export class SearchUserPipe implements PipeTransform {
  public transform(list, key: string, term: string) {
    if (key) {
    return list.length === 0
      ? list
      : list.filter((item: User) => {
          if (item.hasOwnProperty(key)) {
            if (term) {
              const regExp = new RegExp(term, "gi");
              return regExp.test(item[key]);
            } else {
              return true;
            }
          } else {
            return false;
          }
        });
      } else {
        if (list && term) {
          return list.filter((v) => {
            return v.indexOf(term.toUpperCase()) >= 0
          })
        } else {
          return list;
        }
      }
  }
}
