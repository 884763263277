import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-select-pickup-date',
  templateUrl: './select-pickup-date.component.html',
  styleUrls: ['./select-pickup-date.component.scss'],
})
export class SelectPickupDateComponent implements OnInit {
  @Input() type: string;
  @Input() disabled = false;
  selectedDate: any;
  minDate: any = {
    year: 2010,
    month: 1,
    day: 1,
  };
  isDisabled: (date: any, current: { month: number }) => boolean;

  get maxDate() {
    const date = moment().add(28, 'days');
    return new NgbDate(
      Number(date.format('YYYY')),
      Number(date.format('MM')),
      Number(date.format('DD'))
    );
  }

  constructor() {}

  ngOnInit() {
    this.setDates();
  }

  setDates() {
    const deltaDays =
      this.type !== 'batteries'
        ? 1
        : Number(moment().format('HH')) >= 16
        ? 2
        : 1;
    const firstPickupDate = moment().add(deltaDays, 'days');
    this.minDate.year = Number(firstPickupDate.format('YYYY'));
    this.minDate.month = Number(firstPickupDate.format('MM'));
    this.minDate.day = Number(firstPickupDate.format('DD'));

    this.isDisabled = (date: NgbDate, current: { month: number }) => {
      const weekDay = moment(
        `${date.year}/${date.month}/${date.day}`,
        'YYYY/MM/DD'
      ).isoWeekday();

      const calendarDate = moment(
        `${date.year}/${date.month}/${date.day}`,
        'YYYY/MM/DD'
      );

      const currentYear = Number(moment().format('YYYY'));

      // Disable monday on friday > 14 and weekend
      const currentDay = moment().day();
      if (
        ((currentDay === 5 && Number(moment().format('HH')) >= 14) ||
          currentDay > 5) &&
        weekDay === 1 &&
        this.type === 'regular'
      ) {
        return true;
      }
      const countryCode = localStorage.getItem('country');

      // Disable Christmas / New Year period and multiple dates for batteries / chargers
      if (this.type === 'batteries') {

        // BE and NL
        if (
          ['NL', 'BE'].includes(countryCode) &&
          calendarDate.isSameOrAfter(moment(`2024-12-20`, 'YYYY-MM-DD')) &&
          calendarDate.isSameOrBefore(moment(`2025-01-02`, 'YYYY-MM-DD'))
        ) {
          return true;
        }

        // Outside BE and NL
        if (
          !['NL', 'BE'].includes(countryCode) &&
          calendarDate.isSameOrAfter(moment(`2024-12-12`, 'YYYY-MM-DD')) &&
          calendarDate.isSameOrBefore(moment(`2025-01-02`, 'YYYY-MM-DD'))
        ) {
          return true;
        }
      }

      if (this.type === 'batteries' && weekDay === 1) {
        return true;
      }

      if (this.type === 'batteries' && weekDay === 6) {
        return false;
      }

      if (weekDay > 5) {
        return true;
      } else {
        return false;
      }
    };
  }

  dateChanged() {
    if (typeof this.selectedDate === 'string') {
      const date = moment(this.selectedDate, 'D-M-YYYY');
      const selectedDate: NgbDate = new NgbDate(
        Number(date.format('YYYY')),
        Number(date.format('MM')),
        Number(date.format('DD'))
      );
      this.selectedDate = selectedDate;
    }
  }
}
