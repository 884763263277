<div ngbDropdown #myDrop="ngbDropdown" [autoClose]="false" placement="bottom" [ngbTooltip]="'ALERTS'|translate">
  <button class="btn iconWithBadge text-white text-center" ngbDropdownToggle
    [attr.data-count]="count === '0' ? '' : count" (mouseenter)="toggleDropdown(myDrop)" (mouseleave)="cursorInArea = false; hideDropdown()">
    <i class="material-icons light" id="alertCounter">notification_important</i>
    <div class="spinner spinner-border text-light spinner-border-sm position-absolute" role="status" *ngIf="loading">
      <span class="visually-hidden">{{'LOADING'|translate}}</span>
    </div>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownAlerts" class="py-0 my-0" (clickOutside)="closeDropdown(myDrop, $event)"
    (mouseleave)="closeDropdown(myDrop, $event)" >
    <div [ngClass]="unreadItems && unreadItems.length ? 'p-2' : ''">
      <app-alerts [alerts]="unreadItems" [isPreview]="true" (click)="closeDropdown(myDrop, null)">
      </app-alerts>
      <div>
        <h4 *ngIf="unreadItems.length === 0" class="m-0 p-3">{{'NO_ALERTS'|translate}}</h4>
      </div>
    </div>
  </div>
</div>
