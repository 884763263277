import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { YearAgreementBase } from "src/app/interfaces/year-agreement-base";
import { YearAgreementType } from "src/app/interfaces/year-agreement-type";
import { HelperService } from "src/app/services/helper.service";
import { YearAgreementBaseService } from "src/app/services/year-agreements/year-agreement-base.service";
import { ShowDealerAgreementsComponent } from "../../bikes/show-dealer-agreements/show-dealer-agreements.component";
import { saveAs } from "file-saver";

@Component({
  selector: "app-dealer-year-agreements-overview",
  templateUrl: "./dealer-year-agreements-overview.component.html",
  styleUrls: ["./dealer-year-agreements-overview.component.css"],
})
export class DealerYearAgreementsOverviewComponent implements OnInit {
  @Input() agreements: YearAgreementBase[] = [];
  @Input() type: YearAgreementType;
  constructor(
    private yearAgreementBaseService: YearAgreementBaseService,
    private modalService: NgbModal,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {}

  getPdf(agreement: YearAgreementBase) {
    this.yearAgreementBaseService
      .pdf(this.type, agreement.id.toString())
      .subscribe((blob: Blob) => {
        const file = new Blob([blob], { type: "application/pdf" });
        saveAs(file, `agreement_.pdf`);
      });
  }

  openPopup(agreement: YearAgreementBase) {
    const modalRef = this.modalService.open(ShowDealerAgreementsComponent, {
      centered: true,
      size: "lg",
      container: "#modalContainer",
    });
    modalRef.componentInstance.agreements = [agreement];
    modalRef.componentInstance.selectedAgreement = agreement;
    modalRef.componentInstance.useBase = true;
    modalRef.componentInstance.id = agreement.id;
    modalRef.componentInstance.type = this.type;
    modalRef.componentInstance.getPdf(agreement.id, agreement.year);
  }

  _getSignedDate(value) {
    return this.helperService.formatDate(value);
  }
}
