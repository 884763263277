import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { User } from 'src/app/interfaces/user';
import { AccountService } from 'src/app/services/account.service';
import { AlertService } from 'src/app/services/alert.service';
import { ApplicationService } from 'src/app/services/application.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: "app-reset-okta-activation-modal",
  templateUrl: "./reset-okta-activation-modal.component.html",
  styleUrls: ["./reset-okta-activation-modal.component.css"],
})
export class ResetOktaActivationModalComponent implements OnInit {
  @Output() resetted: EventEmitter<boolean> = new EventEmitter<boolean>();
  email: string;
  emailRepeat: string;
  user: User;
  loading: boolean;

  get sendDisabled() {
    return (
      this.user &&
      ((this.email &&
      this.user.email_address.toLowerCase() !== this.email.toLowerCase()) || !this.email)
    );
  }

  constructor(
    public modal: NgbActiveModal,
    private accountService: AccountService,
    private alertService: AlertService,
    private translateService: TranslateService,
    public helperService: HelperService,
    public applicationService: ApplicationService
  ) {}

  ngOnInit(): void { }

  submit() {
    this.loading = true;
    this.applicationService.showLoader(true);
    this.accountService
      .resetOkta(this.user)
      .subscribe((apiResponse: ApiResponse) => {
        this.loading = false;
        this.applicationService.hideLoader();
        if (this.helperService.checkResponse(apiResponse)) {
          this.alertService.showInfoNotification(
            this.translateService.instant("SUCCESS")
          );
          this.resetted.emit(true);
          this.modal.close();
        }
      });
  }
}
