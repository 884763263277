<td scope="row" class="text-end">{{ warrantyRequest.warranty_id }}</td>
<td>
  <i class="material-icons small" *ngIf="warrantyRequest.remarks">
    chat_bubble_outline
  </i>
  {{ warrantyRequest.remarks }}
</td>
<td>{{warrantyRequest.sent_date}}</td>
<td>
  <ng-container *ngIf="!warrantyRequest.sent_date">{{'ACTIVE'|translate}}</ng-container>
  <ng-container *ngIf="warrantyRequest.sent_date">{{'PENDING'|translate}} / {{'HANDLED'|translate}}</ng-container>
  <span class="d-block" *ngIf="warrantyRequest.rth_number">{{'REFERENCENO'|translate}}: {{warrantyRequest.rth_number}}</span>
</td>
<td class="d-flex justify-content-end">
  <a [routerLink]="['/' + companyGroupCode + '/warranty/' + warrantyRequest.warranty_id]" class="btn ms-auto"
  [ngClass]="warrantyRequest.active ? 'btn-primary' : 'btn-outline-primary'">{{(warrantyRequest.active ? 'ADD' : 'VIEW')|translate}}</a>
</td>

