import { EbstAccess } from "./ebst-access";
import { ShippingAddress } from "./shipping-address";
import { AcademyAccess } from "./academy-access";
import { EbstPermissions } from "./ebst-permissions";

export interface User {
  username: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  linked_dealercodes: string[];
  okta: boolean;
  user_id: number;
  question: string;
  answer: string;
  email_address: string;
  account_code: string;
  language_code: string;
  country_code: string;
  owner: boolean;
  profile_id: number;
  profile_name: string;
  ebst_access: EbstAccess;
  ebst_permissions: EbstPermissions;
  shipping_addresses: ShippingAddress[];
  academy_access: AcademyAccess;
  rayon_codes: string[];
  activation_email: string;
  linked: number;
  dealerIsOktaAccount: boolean;
}
export class User implements User {
}
