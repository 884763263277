<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ "BOM" | translate }}</h4>
  <app-quick-settings
    [placement]="'right'"
    class="ms-3 mt-0"
  ></app-quick-settings>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    </button>
</div>
<div class="modal-body">
  <div class="bom-header pb-4">
    <h5
      class="d-block mb-3"
      [innerHTML]="article.description + ' ' + article.caption"
    ></h5>

    <app-stock-status
      [showText]="true"
      [article]="article"
      *ngIf="!article.matrix || article.matrix.options.length === 1"
      class="mb-2 d-block"
    ></app-stock-status>

    <app-matrix
      *ngIf="article.matrix && article.matrix.options.length > 1"
      [article]="article"
      (articleSelected)="selectArticle($event)"
      class="mb-2 d-block"
    ></app-matrix>

    <app-select-group
      *ngIf="bomGroups && bomGroups.length"
      [selectedGroup]="bomGroups[0].code"
      [availableGroups]="bomGroups"
      (groupSelected)="getArticlesFromGroup($event)"
    ></app-select-group>
  </div>
  <div class="bom-list">
    <h6 *ngIf="!selectForWarrantyMode">{{ 'SELECT' | translate }} {{ ('ARTICLES' | translate) | lowercase }}</h6>
    <ng-container *ngIf="selectForWarrantyMode">
      <button class="btn btn-warning" (click)="articleNotFound()">{{"ARTICLE_NOT_FOUND"|translate}}</button>
    </ng-container>
    <app-tree-view
      *ngIf="articles && articles.length"
      #treeview
      [articles]="articles"
      class="w-100 h-100"
      (showArticleDetails)="showArticleDetails($event)"
      (articleExpanded)="expandArticle($event)"
      (articleSelected)="addToSelection($event)"
      (articleCheckedChange)="addToSelection($event)"
      [selectForWarrantyMode]="selectForWarrantyMode"
      [isBom]="true"
    ></app-tree-view>

    <p
      class="col-12 py-4 mt-2"
      *ngIf="loaded && articles && !articles.length"
    >
      <ngb-alert [type]="'warning'" [dismissible]="false">{{
        "NO_RESULTS" | translate
      }}</ngb-alert>
    </p>
  </div>
</div>
<div class="modal-footer">
  <div class="fixedButtonsContainer">
    <button
      class="btn btn-success addAll rounded"
      (click)="addAll()"
      *ngIf="showAddAllButton"
    >
      {{ "ADD_ALL" | translate }} ({{ selectedForBasketCount }})
    </button>
  </div>
</div>
