<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "PDF" | translate }} ({{ fileName }})
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
    v-if="showDownload && loaded"
  >
    </button>
</div>
<div class="modal-body p-0">
  <div class="row">
    <div class="col-md-12 d-flex p-2">
      <button
        type="button"
        class="btn btn-dark btn-lg mx-auto"
        (click)="download()"
        *ngIf="loaded"
      >
        {{ "DOWNLOAD" | translate }}
      </button>
    </div>
    <div class="col-md-12">
      <div class="scrollable customScrollbar pdfContainer">
        <pdf-viewer
          *ngIf="pdfUrl"
          [src]="pdfUrl"
          [render-text]="true"
          [original-size]="false"
          (page-rendered)="showDownload = true"
          class="border d-block w-100"
        >
        </pdf-viewer>
        <div class="spinner-border text-primary" role="status" *ngIf="!loaded">
          <span class="visually-hidden">{{ "LOADING" | translate }}</span>
        </div>
      </div>
      <div class="d-flex justify-content-center py-2"></div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <span class="col-md-12 p-0 d-flex">
    <button
      type="button"
      class="btn btn-default btn-lg btn-outline me-auto"
      (click)="modal.close()"
    >
      {{ "CLOSE" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-dark btn-lg"
      (click)="download()"
      *ngIf="loaded"
    >
      {{ "DOWNLOAD" | translate }}
    </button>
  </span>
</div>
