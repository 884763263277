import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { ArticleService } from "src/app/services/article.service";
import { Article } from "src/app/interfaces/article";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SearchService } from "src/app/services/search.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { BomGroup } from "src/app/interfaces/bom-group";
import { ArticleCollectorService } from "src/app/services/article-collector.service";
import { AddAllModalComponent } from "../../modal-components/add-all-modal/add-all-modal.component";
import { ApplicationService } from "src/app/services/application.service";
import { TreeViewComponent } from "../../search-components/tree-view/tree-view.component";
import { WarrantyService } from "src/app/services/warranty.service";
import * as _ from "underscore";

@Component({
  selector: "app-article-bom",
  templateUrl: "./article-bom.component.html",
  styleUrls: ["./article-bom.component.scss"]
})
export class ArticleBomComponent implements OnInit {
  @ViewChild(TreeViewComponent) treeview: TreeViewComponent;
  @Input() article: Article;
  @Input() bomGroup: string;

  bomGroups: BomGroup[];
  articles: Article[];
  cachedArticles: any = {};
  selectedForBasketCount = 0;
  showAddAllButton = false;
  selectForWarrantyMode = false;
  isLeaseBom = false;
  isServiceBom = false;
  loaded = false;
  lease = false;
  service: any;
  constructor(
    private articleService: ArticleService,
    public modal: NgbActiveModal,
    private applicationService: ApplicationService,
    private articleCollectorService: ArticleCollectorService,
    private modalService: NgbModal,
    private warrantyService: WarrantyService
  ) {}

  ngOnInit() {

    if (!this.article.single_article_code && this.article.article_codes) {
      this.article.single_article_code = this.article.article_codes[0];
    }

    if (this.isLeaseBom) {
      this.service = this.warrantyService;
      if (this.isServiceBom ) {
        this.getArticlesFromGroup("y00")
      } else {
        if (this.article.single_article_code) {
          this.getBomData("y00");
        }
      }
    } else {
      this.service = this.articleService;
      if (this.article.single_article_code) {
        this.getBomData();
      }
    }
  }

  getBomData(hideGroup?: string) {
    this.loaded = false;
    this.applicationService.showLoader(true);
    this.service.getArticleBom(this.article)
      .subscribe((response: ApiResponse) => {
        this.applicationService.hideLoader();
        this.loaded = true;
        if (response.success) {
          const groups = response.result as BomGroup[];

          if (hideGroup) {
            this.bomGroups = groups.filter((g) => {
              return g.code.toLowerCase() !== hideGroup.toLowerCase();
            })
          } else {
            this.bomGroups = groups;
          }

          if (this.bomGroups.length) {
            this.getArticlesFromGroup(this.bomGroups[0].code);
          }
        }
      });
  }

  selectArticle(selectedArticle) {
    this.cachedArticles = {};
    this.article = Object.assign(this.article, selectedArticle);
    this.articles = Array<Article>();
    this.getBomData();
  }

  getArticlesFromGroup(group) {
    if (this.treeview) {
      this.treeview.checkAllState = false;
    }
    if (!this.cachedArticles[group]) {
      this.applicationService.showLoader(true);
      this.service
        .getBomArticles(group, this.article)
        .subscribe((response: ApiResponse) => {
          this.applicationService.hideLoader();
          if (response.success) {
            let articles = _.sortBy(response.result, "single_article_code") as Article[];
            this.articles = articles;
            this.cachedArticles[group] = articles;
          }
        });
    } else {
      this.articles = this.cachedArticles[group];
    }
  }

  expandArticle(article) {
    this.service
      .getBomArticles(article.single_article_code, this.article)
      .subscribe((response: ApiResponse) => {
        if (response.success) {
          article.bom_articles = response.result;
        }
      });
  }

  addToSelection(selectedArticle) {
    this.selectedForBasketCount = this.articleCollectorService.countSelected(
      selectedArticle
    );
    this.showAddAllButton = this.selectedForBasketCount > 0;
  }

  // Add multiple selection to basket
  addAll() {
    const selectedArticles = [];
    const selectedForBasket = this.articleCollectorService.selectedForBasket;

    Object.keys(selectedForBasket).forEach(function(key) {
      // Make a copy - no reference
      const article = Object.assign({}, selectedForBasket[key]);
      selectedArticles.push(article);
    });
    const modalRef = this.modalService.open(AddAllModalComponent, {
      size: "lg",
      container: "#modalContainer"
    });
    modalRef.componentInstance.articles = selectedArticles;
  }

  deselectAll() {
    this.articleCollectorService.deselectAll();
  }

  showArticleDetails(data) {}

  articleNotFound() {
    const article = new Article();
    article.single_article_code = "999999";
    article.description = "-";
    this.warrantyService.partSelected(article)
  }
}
