<app-invoices-filters
  (searchSubmitted)="getFilters($event); clearSelection()"
  [shippingAddressGroup]="shippingAddressGroup"
>
</app-invoices-filters>

<div class="mb-4 mt-1 tableContainer customScrollbar">
  <span *ngIf="invoices && invoices.length"
    >{{ "SHOWS_INVOICES_FROM" | translate }}:
    <strong>{{ formattedDateFrom }}</strong> {{ "TILL" | translate }}
    <strong>{{ formattedDateTo }}</strong></span
  >
  <table class="table">
    <thead>
      <tr>
        <td colspan="3" class="border-0">
          <div class="checkbox" >
            <label>
              <input
                type="checkbox"
                (click)="checkAll()"
                id="checkAll"
                [(ngModel)]="checkAllState"
                (change)="checkAll()"
                [disabled]="pageData?.loading"
              />
              <span class="box"></span>
              {{ "SELECT_ALL" | translate }}
            </label>
          </div>
        </td>
      </tr>
      <tr class="financial-item" *ngIf="invoices && invoices.length">
        <td style="max-width: 30px; float: left"></td>
        <td>{{ "INVOICE_NUMBER" | translate }}</td>
        <td>{{ "INVOICE_DATE" | translate }}</td>
        <ng-container
          *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'"
        >
          <td>{{ "DUE_DATE" | translate }}</td>
          <td>{{ "INVOICE_AMOUNT" | translate }}</td>
          <td>{{ "AMOUNT_DUE" | translate }}</td>
          <td>{{ "ORDERNUMBER" | translate }}</td>
          <td>{{ "ORDERNUMBER" | translate }} Accentry</td>
        </ng-container>
      </tr>
      <tr *ngIf="invoices && !invoices.length && loaded">
        <td class="border-0">
          <app-no-results></app-no-results>
        </td>
      </tr>
    </thead>
    <tbody>
      <app-invoice-line
        class="w-100 d-block d-table-row small financial-item"
        *ngFor="let invoice of invoices; let rowIndex = index"
        [invoice]="invoice"
        [attr.data-index]="rowIndex + 1 + (pageData.page - 1) * 50"
        (invoiceSelectionChange)="getSelection(); getSelectionForPayment()"
        [onlinePaymentRestrictionLevel]="onlinePaymentRestrictionLevel"
        [disablePayment]="invoice_numbers_for_payment.length > 0"
        [disablePaymentSelection]="invoice_numbers_for_payment.length >= 50"
      >
      </app-invoice-line>
    </tbody>
  </table>
  <button class="btn btn-dark btn-block" (click)="showMore()" *ngIf="pageData.page < totalPages">{{"SHOW_MORE"|translate}}</button>
  <ngx-skeleton-loader
    *ngIf="!loaded"
    count="20"
    appearance="line"
    [theme]="{ height: '80px' }"
  >
  </ngx-skeleton-loader>
</div>

<div class="row" *ngIf="invoices && invoices.length">
  <div class="col-md-10">
    <!-- Number of results -->
    <div class="numResults">
      <app-invoice-balance-overview
        [numResults]="pageData.numResults['invoices']"
      ></app-invoice-balance-overview>
    </div>
  </div>
</div>

<!-- Paginator -->
<app-financial-paginator
  [(pageData)]="pageData"
  (navigation)="navigationChanged($event)"
   *ngIf="invoices && invoices.length"
>
  <!-- Options -->
  <i
    class="material-icons  clickable withOpacity mt-1"
    *ngIf="invoice_numbers.length"
    (click)="clearSelection()"
  >
    clear
  </i>
  <button *ngIf="onlinePaymentRestrictionLevel !== 0 || isHelpdesk" [disabled]="!invoice_numbers_for_payment.length || downloading || totalSelectedInvoiceAmountNumber <= 0" (click)="payInvoices()" class="btn btn-dark me-1">
        {{ "PAY_INVOICES" | translate }} <ng-container *ngIf="invoice_numbers_for_payment.length">({{invoice_numbers_for_payment.length}} - {{"TOTAL"|translate}} {{totalSelectedInvoiceAmount}})</ng-container>
  </button>
  <button
    class="btn btn-dark d-inline-block "
    (click)="printSelection()"
    [disabled]="!invoice_numbers.length || downloading"
    [ngClass]="{ disabled: downloading }"
  >
    <span
      *ngIf="downloading"
      class="spinner-border spinner-border-sm me-1"
      role="status"
      aria-hidden="true"
    ></span>

    <span *ngIf="!downloading">{{ "PDF" | translate }}</span>
    <ng-container *ngIf="invoice_numbers.length"
      >({{ invoice_numbers.length }}
      {{
        (invoice_numbers.length ? "INVOICE" : "INVOICES") | translate
      }})</ng-container
    >
  </button>
</app-financial-paginator>
