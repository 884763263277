import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  templateUrl: "./lease-models.component.html",
  selector: 'app-lease-models'
})
export class LeaseModelsComponent implements OnInit {

  constructor(private router: Router, private applicationSerice: ApplicationService) { }

  ngOnInit() {
    const fromLease = window.location.href.indexOf("leaserequest") >= 0 ? "&leaserequest=X" : "";
    this.router.navigateByUrl(`${this.applicationSerice.getSelectCompanyGroupCode()}/search#filter/COMMON=&LEASE=X&MD-MERK=S002,K001,B001&PRICE=1,10000&pageSize=24${fromLease}`);
  }
}
