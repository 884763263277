import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Article } from "src/app/interfaces/article";
import { TrackingService } from "src/app/services/tracking.service";
import { ThreeDeeViewerModalComponent } from "./three-dee-viewer-modal/three-dee-viewer-modal.component";

@Component({
  selector: "app-three-dee-viewer",
  templateUrl: "./three-dee-viewer.component.html",
  styleUrls: ["./three-dee-viewer.component.css"],
})
export class ThreeDeeViewerComponent implements OnInit {
  @Output() show360: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() src: string =
    "https://orbitvu.co/share/jhhjrTf5fT3NNhqypHNgiA/4703540/360/view";
  @Input() fullscreen: boolean = false;
  @Input() id = "threedeeviewer"
  @Input() article: Article;

  show: boolean = false;
  constructor(private modalService: NgbModal, private trackingService: TrackingService) {}

  ngOnInit(): void {}

  showIframe() {
    this.show = true;
    const iframe = document.getElementById(this.id) as HTMLIFrameElement;
    this.show360.emit(true);
    this.trackingService.view3d(this.article);
    if (iframe) {
      iframe.src = this.src;
    }
  }

  hideIframe() {
    this.show = false;
    this.show360.emit(false);
  }

  showFullscreen() {
    const modalRef = this.modalService.open(ThreeDeeViewerModalComponent, {
      size: "lg",
      container: "#modalContainer"
    });
    modalRef.componentInstance.article = this.article;
    modalRef.componentInstance.src = this.src;
  }
}
