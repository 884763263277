import { Component, OnInit, Input } from "@angular/core";
import { WarrantyService } from "src/app/services/warranty.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { WarrantyDetails } from "src/app/interfaces/warranty/warranty-details";
import { GroupedWarrantyArticles } from "src/app/interfaces/warranty/grouped-warranty-articles";
import { ApplicationService } from "src/app/services/application.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WarrantyWizardComponent } from "../warranty-wizard/warranty-wizard.component";
import { WarrantyLabelsComponent } from "../warranty-labels/warranty-labels.component";
import { SelectedForWarrantyShipment } from "src/app/interfaces/warranty/selected-for-warranty-shipment";
import { ActivatedRoute } from "@angular/router";
import { HelperService } from "src/app/services/helper.service";
import { FinancialService } from "src/app/services/financial.service";
import { saveAs } from "file-saver";
import { PdfViewerComponent } from "src/app/components/general-components/pdf-viewer/pdf-viewer.component";
import { WarrantyArticle } from "src/app/interfaces/warranty/warranty-article";

@Component({
  selector: "app-warranty-details",
  templateUrl: "./warranty-details.component.html",
  styleUrls: ["./warranty-details.component.scss"],
})
export class WarrantyDetailsComponent implements OnInit {
  @Input() id: number;
  @Input() add: string;
  warrantyDetails: WarrantyDetails;
  groupedArticles: GroupedWarrantyArticles;
  selectedForShipment: SelectedForWarrantyShipment;
  companyGroupCode: string;
  loaded = false;
  framenumber: any;
  status = {
    "NE": "RETURN_STATUS_NE",
    "PC": "RETURN_STATUS_PC",
    "CO": "RETURN_STATUS_CO",
  }
  warrantyArticles: WarrantyArticle[] = [];

  constructor(
    private warrantyService: WarrantyService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private helperService: HelperService,
    private financialService: FinancialService
  ) {
    this.companyGroupCode = applicationService.getSelectCompanyGroupCode();
  }

  ngOnInit() {
    // Add article
    if (this.add) {
      this.addArticle(this.add);
    }

    this.groupedArticles = new GroupedWarrantyArticles();
    this.getDetails();

    this.route.params.subscribe((params) => {
      if (params["framenumber"]) {
        this.framenumber = params["framenumber"];
      }
    });

    // Auto show wizard with type Lease selected
    this.route.queryParams.subscribe((params) => {
      if (params["type"]) {
        this.addArticle("LEASE");
      }
    });
  }

  // Get all the current warranty articles
  getDetails() {
    this.applicationService.showLoader(true);
    this.warrantyService
      .getWarrantyDetails(this.id)
      .subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();

        if (this.helperService.checkResponse(apiResponse)) {
          this.loaded = true;
          this.warrantyDetails = apiResponse.result;
          this.warrantyArticles = this.warrantyDetails.warranty_articles;
          this.groupedArticles = this.warrantyService.filterArticles(
            this.warrantyDetails.warranty_articles,
            true
          );
          if (this.warrantyDetails.active && this.framenumber) {
            this.addArticle("LEASE", this.framenumber);
          }
          this.selectedForShipment = new SelectedForWarrantyShipment(
            this.id,
            this.groupedArticles.lease.length ? true : false,
            this.groupedArticles.regular.length ? true : false,
            this.groupedArticles.batteries.length ? true : false,
            this.groupedArticles.motors.length ? true : false
          );
        }
      });
  }

  updateReference() {
    this.warrantyService
      .updateReference(this.warrantyDetails)
      .subscribe((apiResponse: ApiResponse) => { });
  }

  addArticle(selectedTab?: string, framenumber?: string) {
    const modalRef = this.modalService.open(WarrantyWizardComponent, {
      centered: true,
      container: "#modalContainer",
      size: "lg",
      backdrop: "static",
    });

    if (framenumber) {
      modalRef.componentInstance.framenumber = framenumber;
    }
    modalRef.componentInstance.warrantyId = this.id;
    modalRef.componentInstance.tabTypeSelected = selectedTab;
    modalRef.componentInstance.currentArticles = this.warrantyArticles;
    modalRef.componentInstance.articledAdded$.subscribe((response: boolean) => {
      if (response) {
        this.getDetails();
      }
    });
  }

  showLabels() {
    const modalRef = this.modalService.open(WarrantyLabelsComponent, {
      centered: true,
      container: "#modalContainer",
      size: "lg",
    });
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.groupedArticles = this.groupedArticles;
  }

  viewInvoice(invoiceNumber: string) {
    this.financialService
      .getInvoicesPdfFile([invoiceNumber])
      .subscribe((blob: Blob) => {
        const modalRef = this.modalService.open(PdfViewerComponent, {
          centered: true,
          size: "lg",
          container: "#modalContainer",
        });
        modalRef.componentInstance.fileName = `invoice_${invoiceNumber}.pdf`;
        modalRef.componentInstance.pdfSrc = blob;
        event.preventDefault();
        return false;
      });
  }
}
