import { Component } from '@angular/core';
import { PartnerPortalSettings } from 'src/app/interfaces/partner-portal/partner-portal-settings';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { SettingsService } from 'src/app/services/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-partner-portal-homepage',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './partner-portal-homepage.component.html',
  styleUrl: './partner-portal-homepage.component.scss'
})
export class PartnerPortalHomepageComponent {
  id: string;
  id_bottom: string;

  constructor(private settingsService: SettingsService) {
    settingsService.getSettings().subscribe((settings: PartnerPortalSettings) => {
      this.id = `${settings.home_page_cms_id}${environment.name !== 'production' ? '-test' : ''}`;
      this.id_bottom = settings.home_page_cms_id_bottom;
    })
  }
}
