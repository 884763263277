import { Component, OnInit, Input } from "@angular/core";
import { Price } from "src/app/interfaces/price";
import { AccountService } from "src/app/services/account.service";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-retail-price",
  templateUrl: "./retail-price.component.html",
  styleUrls: ["./retail-price.component.scss"]
})
export class RetailPriceComponent implements OnInit {
  @Input() price: Price;
  @Input() hideLabel: boolean; // Override all
  @Input() showLabel: boolean;

  showroomMode = false;
  kioskMode = false;
  isPartnerPortal = false;
  constructor(private applicationService: ApplicationService, private accountService: AccountService) {
    this.getShowroomMode();
    this.getKioskMode();
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  getKioskMode() {
    this.kioskMode = this.applicationService.getKioskMode(
      this.kioskMode
    );
  }

  ngOnInit() {
    this.isPartnerPortal = this.applicationService.isPartnerPortal();
    this.applicationService.changeEmitted$.subscribe(response => {
      this.getShowroomMode();
    });
  }
}
