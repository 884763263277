import { LanguageDescription } from "./language-description";

export interface CompanyGroup {
  company_group_code: string;
  company_group_name: LanguageDescription;
  ace_code: string;
  default_language: string;
  image_directory: string;
  companies: number[];
  chat_companies: number[];
  promotion_companies: number[];
  bom_companies: number[];
  academy: boolean;
  ebst: boolean;
  dropshipment: boolean;
  show_openorder_lines: boolean;
  dropshipment_shipping_cost_article: string;
  article_shop_dropdown: boolean;
  stock_max_display: number;
  stock_hide_parts: boolean;
  bikes_per_brand: any;
  basket_backorder_option: boolean;
  show_companies_in_menu: boolean;
  basket_backorder_default: boolean;
  basket_backorder_articletype: string;
  locked_delivery_week_parts: boolean;
  locked_delivery_week_bikes: boolean;
  locked_delivery_week_all: boolean;
  warranty_returns: boolean;
  ask_and_demand: boolean;
  prepare_page_id: string;
  service_page_id: string;
  xtra_page_id: string;
  xtra_pdf_id: number;
  xtra_pdf_conditions_id: number;
  warranty_wizard_id: string;
  warranty_wizard_id_warranty: string;
  warranty_wizard_id_returns: string;
  warranty_wizard_id_callback: string;
  warranty_instructions_regular: string;
  warranty_instructions_batterymotor: string;
}

export class CompanyGroup implements CompanyGroup {}
