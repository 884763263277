<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "REPLENISHMENT_SELECTION" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    </button>
</div>
<div class="modal-body">
  <app-three-dee-viewer #threedeeviewer [id]="'threedeeviewerModal'" class="d-flex w-100 h-100" [src]="src" [fullscreen]="true" [article]="article"></app-three-dee-viewer>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-dark" (click)="modal.dismiss('Close')">
    {{ "CLOSE" | translate }}
  </button>
</div>
