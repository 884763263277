import { Pipe, PipeTransform } from "@angular/core";
import * as _ from "underscore";

@Pipe({ name: "sortBy" })
export class SortByPipe implements PipeTransform {
  transform(value: any[], column: string, order = ""): any[] {
    if (!value || !column || column === "" || order === "") {
      return value;
    } // no array
    if (value.length <= 1) {
      return value;
    } // array with only one item
    return _.sortBy(value, column, order);
  }
}
