import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-warranty-battery',
  templateUrl: './warranty-battery.component.html',
  styleUrls: ['./warranty-battery.component.scss']
})
export class WarrantyBatteryComponent implements OnInit {
  @Input() formGroup: FormGroup;
  tabSelected: string;
  damaged: boolean;
  constructor() { }

  ngOnInit() {
    this.formGroup.get("articleCode").enable();
    this.formGroup.get("guaranteeDate").disable();
    this.formGroup.get("articleDescription").disable();
    this.formGroup.get("articleDescription").setValidators([]);
    this.formGroup.get("guaranteeDateValid").disable();
    this.formGroup.get("batteryType").enable();
    this.formGroup.get("batteryType").setValidators([Validators.required]);
    this.formGroup.get("batteryType").updateValueAndValidity();
    this.formGroup.get("batteryKind").enable();
    this.formGroup.get("serialNumber").setValidators([Validators.required]);
    this.formGroup.get("serialNumber").updateValueAndValidity();
    this.formGroup.get("serialNumber").enable();
    this.formGroup.get("returnCondition").clearValidators();
    this.formGroup.get("returnCondition").updateValueAndValidity();
    this.tabSelected = this.formGroup.get("batteryType").value;
    this._checkBatteryKind();
    this.formGroup.get("batteryType").valueChanges.subscribe((v) => {
      this.tabSelected = this.formGroup.get("batteryType").value;
    });
  }

  ngOnDestroy() {
    this.setFormSubmit("");
    this.formGroup.get("guaranteeDate").enable();
    this.formGroup.get("articleCode").enable();
    this.formGroup.get("batteryType").disable();
    this.formGroup.get("batteryKind").disable();
    this.formGroup.get("serialNumber").disable();
  }

  _checkBatteryKind() {
    if (this.tabSelected === 'liion_battery') {
      this.formGroup.get("batteryKind").enable();
    } else {
      this.formGroup.get("batteryKind").disable();
    }
  }

  typeChanged(type: string) {
    this.tabSelected = type;
    this.formGroup.get("batteryType").setValue(this.tabSelected);
    this.formGroup.get("batteryKind").setValue("");
    this.formGroup
      .get("serialNumber")
      .setValue(this.tabSelected === "nimh_battery" ? "G" : "");
    this._checkBatteryKind();
  }

  damageSwitchChanged(data: any) {
    this.setFormSubmit("none");
  }

  setFormSubmit(style: string) {
    const submitBtn = document.getElementById("submitWizard");
    const submitAnotherBtn = document.getElementById("submitAnotherWizard");
    if (submitBtn) {
      submitBtn.style.display = this.damaged ? style : "";
    }
    if (submitAnotherBtn) {
      submitAnotherBtn.style.display = this.damaged ? style : "";
    }
  }

}
