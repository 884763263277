import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { ApplicationService } from "src/app/services/application.service";
import {
  PreorderSettings,
  PreorderSettingsContainer,
} from "src/app/interfaces/settings/preorder-settings";
import * as moment from "moment";
import { environment } from "src/environments/environment";
import { PreorderService } from "src/app/services/preorder.service";

@Component({
  selector: "app-search-tabs",
  templateUrl: "./search-tabs.component.html",
  styleUrls: ["./search-tabs.component.scss"],
})
export class SearchTabsComponent implements OnInit {
  @Output() tabSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() activeTab = "search";
  preorderSettings: PreorderSettings;
  preorderEnabled: boolean;
  subscription: any;
  selectedTabMobile: string = "search";
  environmentName: string;
  preorderBicyclesEnabled: boolean;
  preorderPartsEnabled: boolean;
  showXl: boolean;
  constructor(
    public applicationService: ApplicationService,
    private preorderService: PreorderService
  ) {}

  ngOnInit() {
    this.environmentName = environment.name;
    this.selectedTabMobile = this.activeTab;
    this.getPreorderSettings();
    this.applicationService.hasMenuItem("order", "ACCENTRY_REPLENISHMENT").then((result: boolean) => {
      this.showXl = result;
    })
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getPreorderSettings() {
    this.preorderService
      .getPreorderSettings()
      .then((response: PreorderSettingsContainer) => {

        this.preorderService
          .preorderEnabled("Bicycles")
          .then((response: boolean) => {
            this.preorderBicyclesEnabled = (this.applicationService.checkIfDealerHasCompany(101) || this.applicationService.getSelectCompanyGroupCode() !== 'WG') && response;
            this.preorderService
              .preorderEnabled("Parts")
              .then((response: boolean) => {
                this.preorderPartsEnabled =
                  (this.applicationService.checkIfDealerHasCompany(102) ||
                    this.applicationService.getSelectCompanyGroupCode() !==
                      "WG") &&
                  response;
                this.preorderEnabled =
                  this.preorderPartsEnabled || this.preorderBicyclesEnabled;
                if (this.activeTab === "preorder" && !this.preorderEnabled) {
                  // Redirect if unavailable
                  this.setTab(null, "search");
                }
              });
          });
      });
  }

  setTab(event, tabId) {
    this.activeTab = tabId;
    this.tabSelected.emit(tabId);
    if (event) {
      event.preventDefault();
    }
    return false;
  }

  setTabMobile() {
    this.activeTab = this.selectedTabMobile;
    this.tabSelected.emit(this.activeTab);
  }
}
