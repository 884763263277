import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import Client from "storyblok-js-client";
import { HelperService } from "./helper.service";

@Injectable({
  providedIn: "root",
})
export class StoryblokService {
  private emitChangeSource = new Subject<any>();
  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();
  private sbClient = new Client({
    accessToken: environment.storyblokPreviewToken, // Add your token here
  });

  constructor(
    private route: ActivatedRoute,
    private helperService: HelperService
  ) {}

  pageChanged() {
    this.emitChangeSource.next(true);
  }

  isInEditorMode(): boolean {
    if (!environment.production) {
      const storyblokInstance = new window["StoryblokBridge"]();
      const inEditor =
        storyblokInstance.isInEditor() && this.helperService.inIframe();
      return inEditor;
    } else return false;
  }

  isOnPreviewPage() {
    const subdomain = window.location.hostname.split(".")[0];
    const isPreviewDomain =
      subdomain.includes("cms") && subdomain.includes("preview");
    return isPreviewDomain && this.helperService.inIframe();
  }

  gotoUrl(url: string) {
    if (!this.isInEditorMode()) {
      window.location.href = `${
        !url.includes("https://") ? "https://" : ""
      }${url}`;
    } else {
      window.open(url, "_new");
    }
  }

  renderHtml(richTextField: any) {
    return this.sbClient.richTextResolver.render(richTextField);
  }

  formatContent(content: string) {
    if (typeof content !== "undefined") {
      let formatted = content.replace(/\[summary\]/g, "<summary>");
      formatted = formatted.replace(/\[\/summary\]/g, "</summary><p>");
      formatted = formatted.replace(/\[\/details\]/g, "</p></details>");
      formatted = formatted.replace(/\[details\]/g, "<details>");

      // Open external links in new tab
      if (
        formatted.indexOf("accentry") < 0 &&
        formatted.indexOf("localhost") < 0 &&
        (formatted.indexOf("http") >= 0 || formatted.indexOf("www") >= 0)
      ) {
        formatted = formatted.replace(
          /href/g,
          `target="_blank" rel="noopener" href`
        );
      }

      formatted = formatted.replace(
        /https:\/\/www.accentry.com/g,
        window.location.origin
      );

      return formatted;
    } else {
      return "";
    }
  }
}
