import { Article } from "./article";

export interface MatrixOption {
  description: string;
  color_code: string;
  value: Article[];
  status: string;
}

export class MatrixOption implements MatrixOption {}
