import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  NgZone,
} from "@angular/core";
import { AgreementTypes } from "src/app/interfaces/yearAgreement/agreement-types";
import { YearAgreementService } from "src/app/services/year-agreement.service";
import { NgbNavChangeEvent, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HelpdeskService } from "src/app/services/helpdesk.service";
import { User } from "src/app/interfaces/user";
import { ApplicationService } from "src/app/services/application.service";
import { AccountAgreement } from "src/app/interfaces/yearAgreement/account-agreement";

import { ApiResponse } from "src/app/interfaces/api-response";
import {
  YearAgreementDataModel,
  YearAgreementDataGroup,
} from "src/app/interfaces/yearAgreement/year-agreement-data-model";
import { AccountService } from "src/app/services/account.service";
import { Dealer } from "src/app/interfaces/dealer";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { ShowDealerAgreementsComponent } from "src/app/components/year-agreement/bikes/show-dealer-agreements/show-dealer-agreements.component";
import { NewAgreementComponent } from "../new-agreement/new-agreement.component";
import * as moment from "moment";
import { TurnOverSelection } from "src/app/interfaces/yearAgreement/turn-over-selection";
import { Router } from "@angular/router";
import { timer } from "rxjs";
import { AccountInformation } from "src/app/interfaces/account-information";
import { YearAgreementModelsSelectionComponent } from "../year-agreement-models-selection/year-agreement-models-selection.component";
import * as _ from "underscore";
import { environment } from "src/environments/environment";
import { AccountAffiliates } from "src/app/interfaces/yearAgreement/account-affiliates";
import { HelperService } from "src/app/services/helper.service";
import { ChangeDetectorRef } from "@angular/core";
import { AgreementBrand } from "src/app/interfaces/yearAgreement/agreement-brand";
import { saveAs } from "file-saver";

@Component({
  selector: "app-year-agreement-container",
  templateUrl: "./year-agreement-container.component.html",
  styleUrls: ["./year-agreement-container.component.scss"],
})
export class YearAgreementContainerComponent implements OnInit {
  @Output() agreementCreated: EventEmitter<number> = new EventEmitter<number>();
  @ViewChildren(YearAgreementModelsSelectionComponent)
  private _modelSelection: QueryList<YearAgreementModelsSelectionComponent>;
  _moment = null;
  availableTypes: AgreementTypes;
  selectedTab = "dealerInformation";
  selectedDealer: User;
  selectedDealerInformation: Dealer;
  yearAgreementModel: YearAgreementDataModel;
  accountAgreements: AccountAgreement[];
  accountAgreement: AccountAgreement;
  affiliates: string[];
  currentYear: number;
  selectedOldAgreement: number;
  refreshing: boolean;
  agreementOverview = false;
  subscription: any;
  noAgreements: boolean;
  dealerChangeEvent: any;
  turnoverChangeEvent: any;
  distributionInvalid = false;
  planningInvalid = false;
  cmTurnoverKey = "";
  accountInformation: AccountInformation;
  sections = ["brands", "specials"];
  selectedAffiliate: string;
  selectedSubAffiliate: string;
  canReopen: boolean;
  canCreateNewAgreement: boolean = true;
  creatableYears: number[] = [];
  changeTs: number;
  mainAffiliate: string;
  test: string;
  totalTurnover: number = 0;
  agreementCurrentYear: AccountAgreement;
  agreementNextYear: AccountAgreement;
  distributionVisited: boolean;
  loading: boolean;
  affiliatesList: Array<AccountAffiliates> = [];
  affiliatesInvalid: boolean;
  get allAffiliates() {
    const affiliates =
      this.yearAgreementModel && this.yearAgreementModel.affiliates
        ? this.yearAgreementModel.affiliates.split(",")
        : [""];
    if (affiliates && affiliates.length > 1 && !this.selectedSubAffiliate) {
      this.selectedSubAffiliate = affiliates[0];
    }
    return affiliates;
  }

  get affiliatesWithoutMain() {
    return this.yearAgreementModel &&
      this.yearAgreementModel.affiliates &&
      this.yearAgreementModel.affiliates.length
      ? _.without(this.allAffiliates, String(this.mainAffiliate))
      : [];
  }

  get currentAgreementYear() {
    const accountCode = localStorage.getItem("accountCode");
    return Number(
      moment().isSameOrAfter(
        moment(
          `0${
            environment.name === "development" ||
            environment.name === "test" ||
            accountCode === "NL7327ADX1" ||
            accountCode === "VO2251BL13"
              ? "06"
              : "09"
          }-10-${this.currentYear}`
        )
      )
        ? Number(moment().format("YYYY")) + 1
        : moment().format("YYYY")
    );
  }

  constructor(
    private yearAgreementService: YearAgreementService,
    private helpdeskService: HelpdeskService,
    private applicationService: ApplicationService,
    private accountService: AccountService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private router: Router,
    private helperService: HelperService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {
    this.currentYear = new Date().getFullYear();
    this.yearAgreementModel = new YearAgreementDataModel();
    this._moment = moment;
  }

  ngOnInit() {
    if (this.applicationService.getSelectCompanyGroupCode() === "ANL") {
      this.init();
      this.dealerChangeEvent =
        this.applicationService.emitChangeCompanyGroupChanged$.subscribe(() => {
          if (this.router.url.indexOf("yearAgreement") >= 0) {
            this.init();
          }
        });

      this.turnoverChangeEvent = this.yearAgreementService.changeTurnoverEmitted$.subscribe(() => {
        this._checkDistribution(true);
      })
    }
  }

  getPdf(accountAgreement: AccountAgreement) {
    this.yearAgreementService
      .getAgreementPdf(accountAgreement.id)
      .subscribe((blob: Blob) => {
        const file = new Blob([blob], { type: "application/pdf" });
        saveAs(file, `agreement_.pdf`);
      });
  }

  openPopup(accountAgreement: AccountAgreement) {
    const modalRef = this.modalService.open(ShowDealerAgreementsComponent, {
      centered: true,
      size: "lg",
      container: "#modalContainer",
    });
    modalRef.componentInstance.agreements = [accountAgreement];
    modalRef.componentInstance.selectedAgreement = accountAgreement;
    modalRef.componentInstance.id = accountAgreement.id;
    modalRef.componentInstance.type = "bikes";
    modalRef.componentInstance.getPdf(
      accountAgreement.id,
      accountAgreement.year
    );
  }

  init() {
    this.selectedDealer = this.helpdeskService.getSelectedDealer();
    this.accountAgreements = [];
    this.getAffiliates().then(() => {
      this.getAgreements();
      if (this.selectedDealer) {
        this.cmTurnoverKey = `${this.selectedDealer.account_code}_cm_turnover`;
        this.selectedDealerInformation =
          this.accountService.getDealerInformation();
        this.getAccountInformation();
        this.availableTypes = new AgreementTypes(true, true, true, true);
      }
    });
  }

  getAccountInformation() {
    return this.accountService
      .getAccountInformation(true)
      .then((accountInformation: AccountInformation) => {
        this.selectedDealerInformation.has_lease =
          accountInformation.is_lease_dealer;
        this.canReopen = accountInformation.can_reopen_yearagreements;
      });
  }

  initTab(hasAccess: boolean) {
    const group = new YearAgreementDataGroup(false, hasAccess);
    return group;
  }

  getAgreements() {
    this.accountAgreement = null;
    const newYearStarted = this.currentAgreementYear > this.currentYear;
    this.applicationService.showLoader(true);
    this.yearAgreementModel.disabled = true;

    this.yearAgreementService
      .getAccountAgreements()
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          const accountAgreements: AccountAgreement[] = apiResponse.result;

          let accountAgreement = accountAgreements.find(
            (a: AccountAgreement) => {
              return a.year === this.currentAgreementYear;
            }
          );

          this.creatableYears = [];
          const agreementCurrentYear = accountAgreements.find(
            (a: AccountAgreement) => {
              return a.year === this.currentYear;
            }
          );

          if (!accountAgreement && agreementCurrentYear) {
            accountAgreement = agreementCurrentYear;
          }

          if (agreementCurrentYear) {
            this.agreementCurrentYear = agreementCurrentYear;
          }

          const agreementNextYear = accountAgreements.find(
            (a: AccountAgreement) => {
              return a.year === this.currentYear + 1;
            }
          );

          this.agreementNextYear = agreementNextYear;

          if (!agreementCurrentYear) {
            this.creatableYears.push(this.currentYear);
          }

          if (!agreementNextYear && newYearStarted) {
            this.creatableYears.push(this.currentYear + 1);
          }

          this.accountAgreements = accountAgreements.sort((a, b) =>
            a.year > b.year ? -1 : 1
          );

          if (accountAgreement) {
            this.getAgreement(accountAgreement.id);
            this.selectedOldAgreement = accountAgreement.id;
          } else if (this.selectedDealer) {
            this.canCreateNewAgreement = true;
            this.newAgreement();
          }
        } else if (this.selectedDealer) {
          this.creatableYears.push(this.currentYear + (newYearStarted ? 1 : 0));
          this.canCreateNewAgreement = true;
          this.newAgreement();
        }
        this.yearAgreementModel.disabled = false;
        this.applicationService.hideLoader();
      });
  }

  _getSignedDate(value) {
    return moment(value, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY HH:mm");
  }

  changeAgreement() {
    this.getAgreement(this.selectedOldAgreement).then(() => {
      this.refresh();
    });
  }

  refresh() {
    this.refreshing = true;
    timer(50).subscribe(() => {
      this.refreshing = false;
    });
  }

  getAgreement(id: number) {
    return new Promise((resolve) => {
      this.applicationService.showLoader(true);
      this.yearAgreementModel.disabled = true;
      this.yearAgreementModel = new YearAgreementDataModel();
      this.distributionVisited = false;
      this.loading = true;
      this.yearAgreementService
        .getAccountAgreement(id)
        .subscribe((apiResponse: ApiResponse) => {
          this.loading = false;
          if (this.helperService.checkResponse(apiResponse)) {
            this.accountAgreement = apiResponse.result;
            this.yearAgreementModel["countryCode"] = this.selectedDealer
              ? this.selectedDealer.country_code
              : null;
            this.yearAgreementModel.remarks = this.accountAgreement.remarks;
            this.yearAgreementModel.year = this.accountAgreement.year;
            this.yearAgreementModel.cmDealer = this.accountAgreement.cm_dealer;
            this.yearAgreementModel.locked = this.accountAgreement.locked;
            this.yearAgreementModel.turnover_history =
              this.setTurnoverHistory();
            this.yearAgreementModel.lease_promo =
              this.accountAgreement.lease_promo;
            this.yearAgreementModel.dummyTurnover = localStorage.getItem(
              this.cmTurnoverKey
            )
              ? Number(localStorage.getItem(this.cmTurnoverKey))
              : Number(Math.floor(this.totalTurnover));
            this.affiliates = this.accountAgreement.affiliates
              ? String(this.accountAgreement.affiliates).split(",")
              : [];
            this.yearAgreementModel.affiliates = this.affiliates.join(",");
            this.selectedAffiliate = this.affiliates[0];
            this.agreementCreated.next(this.accountAgreement.year);
            this.noAgreements = false;
            if (this.accountAgreement.year === this.currentAgreementYear) {
              this.canCreateNewAgreement =
                !this.agreementCurrentYear || !this.agreementNextYear;
            } else {
              this.canCreateNewAgreement = true;
            }

            // Set initial tab
            if (this.accountAgreement.locked && this.selectedDealer) {
              this.selectedTab = "tabSummary";
            } else {
              this.selectedTab = "dealerInformation";
            }
            this.selectedSubAffiliate = "";
            this.getTexts(this.accountAgreement.year);
          } else {
            this.newAgreement();
          }
          this.yearAgreementModel.disabled = false;
          this.applicationService.hideLoader();
          resolve(true);
        });
    });
  }

  newAgreement() {
    //this.noAgreements = true;
    //this.accountAgreement = null;
    const modal = this.modalService.open(NewAgreementComponent, {
      container: "#modalContainer",
    });

    modal.componentInstance.enabled = true;
    modal.componentInstance.options = this.creatableYears;

    this.subscription = modal.componentInstance.confirm$.subscribe(
      (selectedYear) => {
        this.loading = true;
        this.distributionVisited = false;
        this.yearAgreementModel = new YearAgreementDataModel();
        const accountAgreement = new AccountAgreement(selectedYear);
        accountAgreement.account_code = this.selectedDealer.account_code;
        this.applicationService.showLoader(true);

        this.yearAgreementService
          .createAgreement(accountAgreement)
          .subscribe((apiResponse: ApiResponse) => {
            this.loading = false;
            if (this.helperService.checkResponse(apiResponse)) {
              this.canCreateNewAgreement = false;
              this.accountAgreement = apiResponse.result;
              this.yearAgreementModel["year"] = this.accountAgreement.year;
              this.yearAgreementModel["countryCode"] = this.selectedDealer
                ? this.selectedDealer.country_code
                : null;
              this.yearAgreementModel.turnover_history =
                this.setTurnoverHistory();
              this.yearAgreementModel.dummyTurnover = Number(
                Math.floor(this.totalTurnover)
              );
              this.yearAgreementModel.locked = false;
              this.yearAgreementModel.cmDealer =
                this.accountAgreement.cm_dealer;
              this.yearAgreementModel.lease_promo =
                this.accountAgreement.lease_promo;
              this.agreementCreated.next(selectedYear);
              this.affiliates = [];
              this.noAgreements = false;
              this.selectedSubAffiliate = "";
              this.getTexts(this.accountAgreement.year);
              this.accountAgreements.unshift(this.accountAgreement);
              this.selectedOldAgreement = this.accountAgreement.id;
              this.creatableYears = _.without(
                this.creatableYears,
                this.accountAgreement.year
              );
              this.selectedTab = "dealerInformation";
              modal.close();
            } else {
              this.alertService.showPopup(
                apiResponse.result
                  ? apiResponse.result
                  : this.translateService.instant("ERROR"),
                "error",
                3000
              );
            }
            this.refresh();
            modal.close();
            this.applicationService.hideLoader();
          });
      }
    );
  }

  getTexts(year: number) {
    this.yearAgreementService
      .getAgreementTexts(String(year))
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.yearAgreementModel.texts = apiResponse.result;
        }
      });
  }

  setTurnoverHistory() {
    const brands = ["batavus", "sparta", "koga"];
    this.totalTurnover = 0;
    if (
      this.accountAgreement.turnover_history &&
      this.accountAgreement.turnover_history.length
    ) {
      const turnover = {};
      this.accountAgreement.turnover_history.forEach((t: TurnOverSelection) => {
        if (!turnover[t.year]) {
          turnover[t.year] = {};
        }
        turnover[t.year][t.brand] = t.turnover;
        if (
          brands.includes(t.brand) &&
          t.year === this.accountAgreement.year - 2
        ) {
          this.totalTurnover += t.turnover;
        }
      });
      return turnover;
    }
  }

  tabChange(data: NgbNavChangeEvent) {
    this.selectedTab = data.nextId;
    if (data.nextId === "distribution") {
      this.distributionVisited = true;
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.dealerChangeEvent) {
      this.dealerChangeEvent.unsubscribe();
    }

    // If turnover changes - check the distribution
    if (this.turnoverChangeEvent) {
      this.turnoverChangeEvent.unsubscribe();
    }
  }

  getSelection() {
    const formattedSelection = [];

    const selects = document.getElementsByClassName("agreementSelect");
    for (let i = 0; i < selects.length; i++) {
      const selector = selects[i] as HTMLSelectElement;
      if (selector[selector.selectedIndex]) {
        const affiliate =
          selector[selector.selectedIndex].getAttribute("data-affiliate");
        formattedSelection.push({
          id: Number(selector[selector.selectedIndex].getAttribute("data-id")),
          affiliate: affiliate == "0" ? "" : affiliate,
          isMain: selector[selector.selectedIndex].getAttribute("data-ismain"),
        });
      }
    }

    // Checkboxes
    const checkboxes = document.getElementsByClassName("agreementCheckbox");
    for (let j = 0; j < checkboxes.length; j++) {
      const checkbox = checkboxes[j] as HTMLInputElement;
      if (checkbox.checked) {
        const affiliate = checkbox.getAttribute("data-affiliate");
        formattedSelection.push({
          id: Number(checkbox.getAttribute("data-id")),
          affiliate: affiliate == "0" ? "" : affiliate,
          is_main: checkbox.getAttribute("data-ismain"),
        });
      }
    }

    return formattedSelection;
  }

  getSelectedBrands() {
    const formattedSelection = [];

    const checkboxes = document.getElementsByClassName("agreementBrand");
    for (let j = 0; j < checkboxes.length; j++) {
      const checkbox = checkboxes[j] as HTMLInputElement;
      if (checkbox.checked) {
        const affiliate = checkbox.getAttribute("data-affiliate");
        const isMain = checkbox.getAttribute("data-ismain");
        formattedSelection.push({
          brand: checkbox.getAttribute("data-id"),
          affiliate: isMain == "true" ? "" : affiliate,
          is_main: checkbox.getAttribute("data-ismain"),
        });
      }
    }

    return formattedSelection;
  }

  getTurnover() {
    const turnover = [];
    this.sections.forEach((g) => {
      for (let b in this.yearAgreementModel[g]["turnover_selection"]) {
        turnover.push({
          brand: b,
          turnover: this.yearAgreementModel[g]["turnover_selection"][b],
        });
      }
    });
    return turnover;
  }

  getDealerType() {
    let dealerType = "";
    if (
      this.yearAgreementModel.brands &&
      this.yearAgreementModel.brands.selected &&
      this.yearAgreementModel.brands.selected["DealerType"]
    ) {
      dealerType = this.yearAgreementModel.brands.selected["DealerType"];
    }

    return dealerType;
  }

  _checkAffiliates() {
    let validBrands = true;
    let validSpecials = true;
    let hasRental = false;
    this.affiliatesInvalid = false;
    if (this.yearAgreementModel.affiliates) {
      const affiliates = this.yearAgreementModel.affiliates.split(",");
      if (affiliates.length > 1) {
        affiliates.forEach((a: string) => {
          // const selectedBrands =
          //   this.yearAgreementModel.brands.brands_affiliates.find(
          //     (b: AgreementBrand) => {
          //       return b.affiliate === a;
          //     }
          //   );
          // if (!selectedBrands) {
          //   validBrands = false;
          // }

          const selectedSpecials =
            this.yearAgreementModel.specials.brands_affiliates.find(
              (b: AgreementBrand) => {
                return b.affiliate === a;
              }
            );
          if (!selectedSpecials) {
            validSpecials = false;
          }

          hasRental =
            this.yearAgreementModel.dealer.selected[`Rental${a}`] === true;

          const affiliatesInvalid =
            !validBrands && !validSpecials && !hasRental;
          if (affiliatesInvalid) {
            this.affiliatesInvalid = affiliatesInvalid;
            return false;
          }
        });
      }
    }
    return !this.affiliatesInvalid;
  }

  save() {
    const promise = new Promise((resolve, reject) => {


      // Just do the check to remove the invalid labels (not required)
      this._checkDistribution(true);

      this.yearAgreementModel.disabled = true;
      this.applicationService.showLoader(true);
      this.accountAgreement.options_selection = this.getSelection();
      this.accountAgreement.brands = this.getSelectedBrands();
      this.accountAgreement.cm_dealer = this.yearAgreementModel.cmDealer;
      this.accountAgreement.account_code = this.selectedDealer.account_code;
      this.accountAgreement.remarks = this.yearAgreementModel.remarks;
      this.accountAgreement.focus_model_selection =
        this.getModelsForAffiliate("focusmodels");
      this.accountAgreement.demo_model_selection =
        this.getModelsForAffiliate("demomodels");

      this.accountAgreement.affiliates = this.yearAgreementModel.affiliates;
      this.accountAgreement.turnover = this.getTurnover();
      this.accountAgreement.dealer_type = this.getDealerType();
      this.accountAgreement.turnover_cmdealer =
        this.yearAgreementModel.dummyTurnover;
      this.accountAgreement.lease_promo = this.yearAgreementModel.lease_promo;
      this.refreshing = true;
      this.yearAgreementService
        .updateAgreement(this.accountAgreement)
        .subscribe((apiResponse: ApiResponse) => {
          if (apiResponse.success) {
            this.alertService.showInfoNotification(
              this.translateService.instant("SAVED")
            );
          } else {
            this.alertService.showErrorNotification(
              this.translateService.instant("ERROR")
            );
          }
          this.yearAgreementModel.disabled = false;
          resolve(true);

          setTimeout(() => {
            this.refreshing = false;
          }, 0);
        });

      timer(800).subscribe(() => {
        this.yearAgreementModel.disabled = false;
        this.applicationService.hideLoader();
      });
    });
    return promise;
  }

  getModelsForAffiliate(modelType: string) {
    let selection = [];
    const modelSelectionComponents = this._modelSelection;
    for (let component of modelSelectionComponents["_results"]) {
      if (typeof component.selectedModels !== "undefined") {
        selection = selection.concat(
          component.selectedModels.filter((m) => {
            return (
              m.selected &&
              (m.affiliate === component.affiliate ||
                (component.isMain && m.affiliate === "")) &&
              component.modelType === modelType
            );
          })
        );
      }
    }
    return selection;
  }

  sendAgreement(summaryContainer) {
    this.save().then((success: boolean) => {
      // Check form
      if (this._checkDistribution(true) && this._checkAffiliates()) {
        if (success) {
          summaryContainer.submit();
        }
      } else {
        window.scrollTo(0, 0);
      }
    });
  }

  _checkDistribution(showErrors) {
    if (
      document.getElementsByClassName("distributionInvalid").length &&
      (this.distributionVisited ||
        (!this.yearAgreementModel.locked &&
          (this.yearAgreementModel["brands"]["hasAccess"] ||
            this.yearAgreementModel["specials"]["hasAccess"]))) &&
      showErrors
    ) {
      this.alertService.showErrorNotification(
        this.translateService.instant("CHECK_DISTRIBUTION")
      );
      this.distributionInvalid = true;
      return false;
    } else {
      this.distributionInvalid = false;
    }
    return true;
  }

  reopenAgreement() {
    this.yearAgreementService
      .reopenAgreement(
        this.selectedDealerInformation.dealer_code,
        this.accountAgreement.id
      )
      .subscribe((apiResponse: ApiResponse) => {
        if (apiResponse && apiResponse.success) {
          this.alertService.showInfoNotification(
            this.translateService.instant("AGREEMENT_REOPENED")
          );
          this.getAgreement(this.accountAgreement.id);
          this.selectedTab = "dealerInformation";
        } else {
          this.alertService.showErrorNotification(
            apiResponse.result
              ? apiResponse.result
              : this.translateService.instant("ERROR")
          );
        }
      });
  }

  revokeAgreement() {
    this.yearAgreementService
      .revokeAgreement(
        this.accountAgreement.id
      )
      .subscribe((apiResponse: ApiResponse) => {
        if (apiResponse && apiResponse.success) {
          this.alertService.showInfoNotification(
            this.translateService.instant("AGREEMENT_REVOKED")
          );
          this.getAgreement(this.accountAgreement.id);
          this.selectedTab = "dealerInformation";
        } else {
          this.alertService.showErrorNotification(
            apiResponse.result
              ? apiResponse.result
              : this.translateService.instant("ERROR")
          );
        }
      });
  }

  getAffiliates() {
    return new Promise((approve, reject) => {
      this.yearAgreementService
        .getAccountAgreementAffiliates()
        .subscribe((apiResponse: ApiResponse) => {
          if (this.helperService.checkResponse(apiResponse)) {
            this.affiliatesList = apiResponse.result["affiliate"];
            this.mainAffiliate = String(apiResponse.result["main_relation"]);
            this.affiliates = _.pluck(
              apiResponse.result["affiliate"],
              "relation_id"
            );
            approve(true);
          }
        });
    });
  }
}
