import { Component, OnInit } from "@angular/core";
import { CmsService } from "src/app/services/cms.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { ApplicationService } from "src/app/services/application.service";
import { CompanyPage } from "src/app/interfaces/cms/company-page";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogComponent } from "src/app/components/modal-components/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-company-page",
  templateUrl: "./company-page.component.html",
  styleUrls: ["./company-page.component.scss"]
})
export class CompanyPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  pageId: string;
  page: CompanyPage;
  constructor(
    private cmsService: CmsService,
    private router: Router,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private translateService: TranslateService
  ) {}

  openCompanyPage() {}

  ngOnInit() {
    // this.router.events.subscribe((data: any) => {
    //   if (data instanceof NavigationEnd) {
    //     this.pageId = String(data["pageId"]);
    //   }
    // })

    this.route.params.subscribe(params => {
      if (params["pageId"]) {
        this.pageId = params["pageId"];
      } else {
        const url = window.location.href;
        const urlData = url.split("#");
        const pageId = urlData[urlData.length - 1];
        this.pageId = pageId.replace("Accelerate/", "");
      }

      if (!this.pageId) {
        this.notFound();
      }
    });
  }

  notFound() {
    this.router.navigateByUrl(
      this.applicationService.getSelectCompanyGroupCode() + "/notfound"
    );
  }
}
