import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ApplicationService } from './application.service';
import { environment } from 'src/environments/environment';
import { LeaseRequest } from '../interfaces/lease/lease-request';
import { LeaseSerialNumbers } from '../interfaces/lease/lease-serial-numbers';
import { Subject } from 'rxjs';
import { LeasePrice } from '../interfaces/lease/lease-price';
import { CompanyDetails } from '../interfaces/lease/company-details';
import { ClaimInput } from '../interfaces/lease/claim-input';

@Injectable({
  providedIn: 'root'
})
export class LeaseService {
  public emitChanges = new Subject<any>();

  constructor(private http: HttpClient, private applicationService: ApplicationService,) { }

  public parsePrices(leasePrices: LeasePrice[], selectedDuration: string) {
    const priceData = {
      parsedPricesList: [],
      parsedPrices: {},
      selectedDuration: ""
    }
    const parsedPricesList = [];
    const parsedPrices = {}
    if (leasePrices) {
      for (let priceRange of leasePrices) {
        const priceListObject = priceRange["prices"]["LEASE"]["default"];
        parsedPrices[String(priceRange.volume)] = priceListObject;
        priceListObject["price"] = priceListObject;
        priceListObject["volume"] = String(priceRange.volume);
        priceListObject["selected"] = String(priceRange.volume) === selectedDuration;
        parsedPricesList.push(priceListObject);
      }

      // If 1 price - select this
      if (parsedPricesList.length === 1) {
        selectedDuration = parsedPricesList[0]["volume"]
      }
    }
    priceData["parsedPricesList"] = parsedPricesList;
    priceData["parsedPrices"] = parsedPrices;
    return priceData["parsedPricesList"];
  }

  // Service message commands
  leaseUpdated(change: boolean) {
    this.emitChanges.next(change);
  }


  // Get pending applications
  pendingRequests() {
    return this.http
    .get(`${environment.apiendpoint}lease/applications/pending`
    );
  }

  getLeasePartners() {
    return this.http
    .get(`${environment.apiendpoint}lease/leasepartners`
    );
  }

  getLeaseSettings() {
    return this.http
    .get(`${environment.apiendpoint}lease/settings`
    );
  }

  // new request
  addRequest(leaseRequest: LeaseRequest) {
    return this.http
    .post(`${environment.apiendpoint}lease/applications`, leaseRequest
    );
  }

  // update request
  updateRequest(leaseRequest: LeaseRequest) {
    return this.http
    .put(`${environment.apiendpoint}lease/applications/${leaseRequest.id}`, leaseRequest
    );
  }

  submitRequest(leaseRequest: LeaseRequest) {
    return this.http
    .post(`${environment.apiendpoint}lease/applications/${leaseRequest.id}/submit`, {}
    );
  }

  cancelRequest(leaseRequest: LeaseRequest) {
    return this.http
    .post(`${environment.apiendpoint}lease/applications/${leaseRequest.id}/cancel`, {}
    );
  }

  deleteRequest(leaseRequest: LeaseRequest) {
    return this.http
    .delete(`${environment.apiendpoint}lease/applications/${leaseRequest.id}`, {}
    );
  }

  getLeaseApplications() {
    return this.http
    .get(`${environment.apiendpoint}lease/applications`
    );
  }

  getLeaseApplication(id: Number) {
    return this.http
    .get(`${environment.apiendpoint}lease/applications/${id}`
    );
  }

  // Update lease numbers
  updateLeaseSerialNumbers(leaseSerialNumbers: LeaseSerialNumbers[]) {
    return this.http
    .post(`${environment.apiendpoint}lease/applications/${leaseSerialNumbers[0].lease_application_id}/updateserials`, leaseSerialNumbers
    );
  }

  // Confirm and finalize the lease request
  activateLeaseApplication(leaseRequest: LeaseRequest) {
    return this.http
    .post(`${environment.apiendpoint}lease/applications/${leaseRequest.id}/activate`, {}
    );
  }

  // Close rejected lease application
  closeRejectedLeaseApplication(leaseRequest: LeaseRequest) {
    return this.http
    .post(`${environment.apiendpoint}lease/applications/${leaseRequest.id}/close`, {}
    );
  }

  // Gets the bicycle details needed for a warranty request
  getLeaseBicycleDetails(framenumber: string) {
    return this.http
    .get(`${environment.apiendpoint}warranties/${framenumber}/lease/information`
    );
  }

  saveLeaseEmployer(companyDetails: CompanyDetails) {
    return this.http
    .post(`${environment.apiendpoint}lease/companyapplication`, companyDetails
    );
  }

  sendClaim(claimInput: ClaimInput) {
    return this.http
    .post(`${environment.apiendpoint}lease/abroadclaim`, claimInput
    );
  }

  public uploadClaimFile(fileName: string, fileData: string) {
    return this.http.post(
      `${environment.apiendpoint}lease/uploadclaimfile`,
      {
        file_name: fileName,
        file_data: fileData
      }
    );
  }
}
