<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'ADD' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
</div>
<div class="modal-body" *ngIf="articles && articles.length">
  <div class="articleContainer row h-100 p-2">
    <ng-container *ngFor="let article of articles">
      <app-add-all-article [article]="article"
        class="card d-flex col-lg-4 col-xl-4 col-xs-12 col-sm-6 col-md-6 align-items-stretch"></app-add-all-article>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col-8 d-flex align-items-end">
      <app-select-basket [basket]="basketId" #selectBasket (basketSelected)="basketId = $event"></app-select-basket>
    </div>
    <div class="col-4 d-flex align-items-end justify-content-end"><button class="btn btn-success"(click)="!selectBasket.baskets.length ? createBasket(selectBasket) : save()"
      [disabled]="adding">{{ 'ADD' | translate}}</button>
    </div>
  </div>
</div>
