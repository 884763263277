<span [style.display]="!showroomMode ? 'block' :'none'">
  <ng-content></ng-content>
  <ng-container *ngIf="price && price.value !== 0">
    <ng-content></ng-content><ng-container *ngIf="showLabel && !hasDifferentPrices">{{ 'NETPRICE' | translate }} </ng-container>
    <ng-container *ngIf="hasDifferentPrices || showFrom">{{ 'FROM_MIN_PRICE' | translate }} </ng-container>
    <span [innerHTML]="price.value |  currencyConvert: price.currency"></span>
    <small class="d-block" *ngIf="vatText">{{vatText}}</small>
  </ng-container>
  <div class="spinner-border text-primary spinner-border-sm" role="status" *ngIf="!price && loading">
    <span class="visually-hidden">Loading...</span>
  </div>
</span>
