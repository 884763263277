import { Component, OnInit } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/app/services/helper.service';
import { WarrantyService } from 'src/app/services/warranty.service';
import { AccountService } from 'src/app/services/account.service';
import { AuthenticateService } from 'src/app/services/authenticate.service';

@Component({
  selector: 'app-downloader',
  templateUrl: './downloader.component.html',
  styleUrls: ['./downloader.component.scss']
})
export class DownloaderComponent implements OnInit {

  imageUrl: string | null = null;
  fileName: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private warrantyService: WarrantyService,
    private accountService: AccountService,
    private authenticationService: AuthenticateService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {

    // If dealer go to login page first (this page is only for HLP/INT users)
    if (this.accountService.isDealer()) {
      this.authenticationService.logout(true);
    }

    this.route.params.subscribe(params => {
      const id = params['id'];
      this.route.queryParams.subscribe(queryParams => {
        const file = queryParams['file'];
        if (file) {
          this.fileName = file;
          const fileType = this.helperService.getFileType(file);
          this.loadImage(id, file, fileType);
        }
      });
    });
  }

  private loadImage(id: string, fileName: string, fileType: string) {
    this.warrantyService.downloadFile(Number(id), fileName).subscribe(response => {
      const blob = new Blob([response], { type: fileType });
      this.imageUrl = window.URL.createObjectURL(blob);
    });
  }

  public downloadImage() {
    if (this.imageUrl && this.fileName) {
      const a = document.createElement('a');
      a.href = this.imageUrl;
      a.download = this.fileName;
      a.click();
      window.URL.revokeObjectURL(this.imageUrl);
    }
  }
}
