import { Component, OnInit } from "@angular/core";
import { AccountService } from "src/app/services/account.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Profile } from "src/app/interfaces/profile";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertService } from "src/app/services/alert.service";
import { ConfirmDialogComponent } from "../../modal-components/confirm-dialog/confirm-dialog.component";
import { EditProfileModalComponent } from "./edit-profile-modal/edit-profile-modal.component";
import { ProfileAccess } from "src/app/interfaces/profile-access";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { HelperService } from "src/app/services/helper.service";
@Component({
  selector: "app-profiles",
  templateUrl: "./profiles.component.html",
  styleUrls: ["./profiles.component.scss"]
})
export class ProfilesComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  profiles: Profile[];
  model: any;
  selectedIndex: number;
  profileAccess: ProfileAccess[];
  constructor(
    private accountService: AccountService,
    private translateService: TranslateService,
    private helperService: HelperService,
    private modalService: NgbModal,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.profiles = Array<Profile>();
    this.getProfiles();
    this.getProfilesAccess();
  }

  getProfiles() {
    this.accountService.getProfiles().subscribe((apiResponse: ApiResponse) => {
      if (this.helperService.checkResponse(apiResponse)) {
        this.profiles = apiResponse.result;
      }
    });
  }

  getProfilesAccess() {
    this.accountService
      .getProfileAccess()
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          const parseProfileAccessList = Array<ProfileAccess>();
          const profileAccessList = apiResponse.result;

          for (const key in profileAccessList) {
            if (profileAccessList[key]) {
              parseProfileAccessList.push(
                new ProfileAccess(key, profileAccessList[key])
              );
            }
          }

          this.profileAccess = parseProfileAccessList;
        }
      });
  }

  newProfile() {
    const modalRef = this.showModal();
    modalRef.componentInstance.profile = null;
    modalRef.componentInstance.availableProfileAccess = this.profileAccess;
    modalRef.componentInstance.profileId = -1;
    modalRef.componentInstance.title = this.translateService.instant(
      "CREATE_PROFILE"
    );
    modalRef.componentInstance.profileUpdated.subscribe(() => {
      this.getProfiles();
      modalRef.dismiss();
    });
    event.preventDefault();
    return false;
  }

  editProfile(profile: Profile) {
    const modalRef = this.showModal();
    modalRef.componentInstance.availableProfileAccess = this.profileAccess;
    modalRef.componentInstance.profileId = profile.profile_id;
    modalRef.componentInstance.profile = profile;
    modalRef.componentInstance.title = this.translateService.instant(
      "EDIT_PROFILE"
    );
    modalRef.componentInstance.profileUpdated.subscribe(() => {
      this.getProfiles();
      modalRef.dismiss();
    });
    event.preventDefault();
    return false;
  }

  showModal() {
    const modalRef = this.modalService.open(EditProfileModalComponent, {
      centered: true,
      size: "lg",
      windowClass: "medium",
      container: "#modalContainer"
    });
    return modalRef;
  }

  deleteProfile(profile, index) {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      centered: true,
      size: "sm",
      windowClass: "confirmDialog"
    });
    modalRef.componentInstance.isConfirm = true;
    modalRef.componentInstance.title =
      this.translateService.instant("DELETE_PROFILE") + "?";
    modalRef.componentInstance.confirmClicked.subscribe(() => {
      this.accountService
        .deleteProfile(profile)
        .subscribe((response: ApiResponse) => {
          if (this.helperService.checkResponse(response)) {
            this.alertService.showInfoNotification(
              this.translateService.instant("PROFILE_DELETED")
            );
            this.getProfiles();
          } else {
            this.alertService.showDangerNotification(response.result);
          }
        });
    });
    event.preventDefault();
    return false;
  }
}
