<app-open-orders-filters (searchSubmitted)="getFilters($event)" [shippingAddressGroup]="shippingAddressGroup">
</app-open-orders-filters>
<div class="mb-4 mt-1 tableContainer customScrollbar">
  <table class="table">
    <thead>
      <tr class="financial-item" *ngIf="results && results.length">
        <td style="max-width: 30px">

        </td>
        <td>
          {{'DESCRIPTION'|translate}}
        </td>
        <td class="text-center">
          {{'QUANTITY'|translate}}
        </td>
        <td class="text-center">
          {{'DELIVERY'|translate}}
        </td>
        <td>
          {{'ORDERNUMBER'|translate}}
        </td>
        <td *ngIf="applicationService.getSelectCompanyGroupCode() !== 'LAP' && applicationService.getSelectCompanyGroupCode() !== 'GHO'">
          {{'REFERENCE'|translate}}
        </td>
        <td>
          {{'AMOUNT'|translate}}
        </td>
        <td *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'">
          {{'REMARKS'|translate}}
        </td>
        <td *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'">
          {{'SOLD'|translate}}
        </td>
        <td>
          {{'DELIVERY_WEEK'|translate}}
        </td>
        <td colspan="3"></td>
      </tr>
      <tr *ngIf="loaded && results && !results.length && loaded">
        <td class="border-0">
            <app-no-results></app-no-results>
        </td>
      </tr>
    </thead>
    <tbody>
      <ng-container  *ngFor="let item of results; let rowIndex = index">
        <app-open-order-line class="w-100 d-block d-table-row small financial-item"
          [shippingAddressGroup]="shippingAddressGroup"
          [attr.data-index]="(rowIndex + 1 + ((pageData.page - 1) * 50))" [orderLine]="item" [image]="item.image" *ngIf="rowIndex < showIndex"
          ></app-open-order-line>
        </ng-container>
    </tbody>
  </table>
  <button class="btn btn-dark btn-block" (click)="showMore()" *ngIf="(keyWord && results && results.length > showIndex) || page < totalPages">{{"SHOW_MORE"|translate}}</button>
</div>

<div class="row" *ngIf="results && results.length && applicationService.getSelectCompanyGroupCode() !== 'WG'">
  <div class="col-md-10">
    <!-- Number of results -->
    <div class="numResults">
      <app-num-results-open-orders *ngIf="pageData.numResults['openorders']"
        [numResults]="pageData.numResults['openorders']"></app-num-results-open-orders>
    </div>
  </div>
</div>
<ngx-skeleton-loader *ngIf="initial" count="20" appearance="line" [theme]="{height: '80px' }">
</ngx-skeleton-loader>

<!-- Paginator -->
<app-financial-paginator [(pageData)]="pageData" (navigation)="navigationChanged($event)" *ngIf="results && results.length">
  <app-open-orders-options class="w-100 d-inline-block" [(pageData)]="pageData" [filters]="openOrdersFilters" [items]="results">
  </app-open-orders-options>
</app-financial-paginator>
