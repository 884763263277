  <div class="row widgetContainer" [ngClass]="widgets && widgets.length ? 'hasWidgets' : ''"
    (click)="refresh($event)">
    <ng-container *ngFor="let widget of widgets; let widgetIndex = index">
      <app-widget [widget]="widget" class="d-flex col-3"></app-widget>
    </ng-container>
  </div>
  <ng-container>
    <ngx-skeleton-loader *ngIf="!loaded" class="d-flex flex-wrap w-100" count="4" appearance="line"
      [theme]="{height: '200px', width: '24%', margin: '0.5%' }">
    </ngx-skeleton-loader>
  </ng-container>
