import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { Article } from "src/app/interfaces/article";
import { ApplicationService } from "src/app/services/application.service";
import { ArticleService } from "src/app/services/article.service";
import { SearchService } from "src/app/services/search.service";

@Component({
  selector: "app-recent-searched",
  templateUrl: "./recent-searched.component.html",
  styleUrls: ["./recent-searched.component.scss"]
})
export class RecentSearchedComponent implements OnInit {
  @Output() getResult: EventEmitter<string> = new EventEmitter<string>();
  @Output() gotoItem: EventEmitter<Article> = new EventEmitter<Article>();
  recentKeyWords: string[];
  selectedCompanyGroup: string;
  show = false;
  articles: Article[] = [];

  constructor(
    private applicationService: ApplicationService,
    private searchService: SearchService,
    private articleService: ArticleService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getKeyWords(false);
    this.getRecentArticles();
  }

  getKeyWords(show) {
    this.selectedCompanyGroup = this.applicationService.getSelectCompanyGroupCode();
    this.recentKeyWords = this.searchService.getRecentSearched();

    if (show && this.recentKeyWords.length) {
      this.show = true;
    }
  }

  gotoSearch(keyWord) {
    this.getResult.next(keyWord);
  }

  removeRecentItem(keyWord) {
    this.recentKeyWords = this.searchService.removeRecentItem(keyWord);
    this.show = true;
    return false;
  }

  close(event) {
    if (event && event.target && !event.target.classList.contains("clear")) {
      this.show = false;
    }
  }

  getRecentArticles(show?: boolean) {
    const articles = this.articleService.getRecentlyViewed();
    if (articles && articles.length) {
      this.articles = articles.slice(0, 5);
      this.searchService.getNettPrices(this.articles);

      if (show) {
        this.show = true;
      }
    }
  }

  gotoArticle(article) {
    this.gotoItem.emit(article)
  }
}
