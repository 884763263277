import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BasketService } from 'src/app/services/basket.service';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { Article } from 'src/app/interfaces/article';
import { Basket } from 'src/app/interfaces/basket';
import * as _ from 'underscore';
import { SearchService } from 'src/app/services/search.service';
import { FavoritesService } from 'src/app/services/favorites.service';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../../modal-components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-wishlist-modal',
  templateUrl: './wishlist-modal.component.html',
  styleUrls: ['./wishlist-modal.component.scss']
})
export class WishlistModalComponent implements OnInit {
  basketSubscription: Subscription;
  articles: Article[];
  subscription: Subscription;
  loading: boolean;
  hasAddedArticles: boolean = false;
  @Input() preorderType = "Bicycles";
  collectionSize: number;
  page: number = 1;
  pageSize: number = 24;
  sorting: string = "8";

  constructor(public modal: NgbActiveModal, private applicationService: ApplicationService, private basketService: BasketService, private favoritesService: FavoritesService, private modalService: NgbModal, private translateService: TranslateService) { }

  ngOnInit() {
    this.getWishlist();

    this.subscription = this.favoritesService.changeEmitted$.subscribe(() => {
      this.getWishlist();
    })

    this.basketSubscription = this.basketService.changeEmitted$.subscribe(
      (response) => {
        switch (response["type"]) {

          case "addArticle":
            this.hasAddedArticles = true;
            break;
          }
        })
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.basketSubscription) {
      this.basketSubscription.unsubscribe();
    }
  }

  getWishlist() {
    const inputData = {
      page_size: this.pageSize,
      page: this.page,
      sort_column: this.sorting,
      type: `wishlist_${this.preorderType.toLowerCase()}`,
      pre_order: true,
    };
    this.applicationService.showLoader(true);
    this.favoritesService.getFavorites(inputData).subscribe((apiResponse: ApiResponse) => {
      this.applicationService.hideLoader();
      if (apiResponse && apiResponse.result) {
        this.articles = Object.values(apiResponse.result["results"]);
        this.collectionSize = apiResponse.result["count"];
        window.scrollTo(0, 0);
      }
    })
  }

  onPager(pageNumber: number) {
    this.page = pageNumber;
    this.getWishlist();
  }

  closeModal() {
    if (this.hasAddedArticles) {
      this.modal.close();
    } else if (this.articles && this.articles.length) {
      const modalRef = this.modalService.open(ConfirmDialogComponent, {
        centered: true,
        size: "sm",
        windowClass: "confirmDialog",
        container: "#modalContainer"
      });
      modalRef.componentInstance.isConfirm = true;
      modalRef.componentInstance.title = this.translateService.instant("CAUTION");
      modalRef.componentInstance.body = this.translateService.instant("PREORDER_CLOSE_WISHLIST");
      modalRef.componentInstance.confirmClicked.subscribe(() => {
        this.modal.close();
      });
    } else {
      this.modal.close();
    }
  }

}
