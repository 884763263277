<div class="row px-3">
  <div class="col-md-12 px-0">
    <h1><app-header-icon iconName="perm_contact_calendar"></app-header-icon>{{ 'MANAGEPROFILES' | translate}}</h1>
    <div class="mb-3 row seachBar mx-0 p-3">
      <label class="col-sm-1 col-form-label">{{ 'SEARCH'|translate }}</label>
      <div class="col-sm-9 ps-1">
        <input type="text" class="form-control" placeholder="" [(ngModel)]="model" (blur)="selectedIndex = -1"
          (keyup)="selectedIndex = -1">
      </div>
      <div class="col-sm-2 mt-3 mt-md-0">
        <button class="btn btn-primary " (click)="newProfile()"
          [ngClass]="!profileAccess ? 'disabled' : ''">{{ 'CREATE_PROFILE' | translate }}</button>
      </div>
    </div>
    <ul class=" list-group light">
      <app-profile-item *ngFor="let profile of profiles| SearchUserPipe:'profile_name':model; let i = index"
        [profile]="profile" (profileDelete)="deleteProfile($event, i)" (profileEdit)="editProfile($event)">
      </app-profile-item>
    </ul>
  </div>
</div>
