<ng-container *ngIf="inlineAlerts; else notificationAlerts">
  <h3 class="mb-4" *ngIf="alerts && alerts.length">{{'ALERTS'|translate}}</h3>
  <!-- <app-preorder-alert></app-preorder-alert> -->
  <ng-container *ngFor="let alert of alerts">
    <app-alert-item [alert]="alert" class="d-block clickable withOpacity" (showNews)="showNewsItem($event)"
      [isPreview]="isPreview">
    </app-alert-item>
  </ng-container>
</ng-container>

<ng-template #notificationAlerts>
  <div class="p-3">
    <h4 class="mb-4" *ngIf="alerts && alerts.length">{{'ALERTS'|translate}}</h4>
    <ng-container *ngFor="let alert of alerts">
      <app-alert-item [alert]="alert" class="d-block clickable withOpacity" (showNews)="showNewsItem($event)"
        [isPreview]="isPreview">
      </app-alert-item>
    </ng-container>
  </div>
</ng-template>
