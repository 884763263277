<div class="card-body ms-3">
  <h5 class="card-title" *ngIf="!onlyAffiliateOption">{{option.sub_group_type.toUpperCase() |translate}}</h5>
  <app-year-agreement-tab-section [id]="type" [yearAgreementModel]="yearAgreementModel" [accountAgreement]="accountAgreement"
    [subGroupId]="option.sub_group_type" (selectionChanged)="percentageChange($event)"  [isMain]="isMain" [onlyAffiliateOption]="onlyAffiliateOption" [affiliate]="affiliate"
    [sectionData]="option.sub_group_options" [showCalculation]="showCalculation">
  </app-year-agreement-tab-section>
</div>
<div class="card-footer" *ngIf="yearAgreementModel.showMargins && !onlyAffiliateOption && showCalculation">
  {{'TOTAL'|translate}} <app-input-label class=""
    [percentage]="yearAgreementModel[type]['percentages'][option.sub_group_type]"></app-input-label>
</div>
