import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-select-financial-type',
  templateUrl: './select-financial-type.component.html',
  styleUrls: ['./select-financial-type.component.scss'],
})
export class SelectFinancialTypeComponent implements OnInit {
  @Input() type: string;
  @Output() typeChange: EventEmitter<string> = new EventEmitter<string>();
  showroomMode: boolean;
  constructor(
    public applicationService: ApplicationService,
    private location: Location
  ) {}

  ngOnInit() {
    this.showroomMode = this.applicationService.getShowroomMode(false);

    this.applicationService.changeEmitted$.subscribe((response) => {
      this.showroomMode = this.applicationService.getShowroomMode(false);
    });
  }

  tabChange(data) {
    this.type = data.nextId;
    this.typeChange.next(this.type);
    this.location.go(
      this.applicationService.getSelectCompanyGroupCode() +
        '/financial/' +
        this.type
    );
  }
}
