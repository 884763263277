<div class="news-grid">
  <ng-container *ngIf="dataLoaded">
    <ng-container *ngFor="let newsItem of newsItems">
      <app-news-item
        class="d-block"
        [newsItem]="newsItem"
        [useLazyLoad]="true"
      ></app-news-item>
    </ng-container>
  </ng-container>
</div>
<a
  class="
    btn btn-dark
    rounded
    clickable
    withOpacity
    fw-bold
    my-2
    mb-5
    mx-auto
  "
  *ngIf="dataLoaded && newsItems && newsItems.length && totalItems > 10"
  [routerLink]="'/' + selectedCompanyGroup + '/news'"
  >{{ "SHOW_MORE" | translate }}</a
>
