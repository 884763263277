import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatStringYear'
})
export class FormatStringYearPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const currentYear = new Date().getFullYear();
      let formatted = value.replace("[year]", currentYear);
      formatted = formatted.replace("[year_plus_one]", (currentYear + 1));
      return formatted;
    }
    return null;
  }

}
