export interface PackingSlipsSelectedFilters {
  article_type: string;
  order_by: string;
  only_new_order_lines: false;
  workshop: false;
  accentry_id: string;
  keyWord: string;
  keyWordOption: string;
  invoice_type: string;
  date_from: string | Object;
  date_to: string | Object;
  shippingAddress: string;
  companyFilter: string;
}

export class PackingSlipsSelectedFilters {
  constructor(articleType, orderBy, onlyNewLines, keyWord, keyWordoption) {
    this.article_type = articleType;
    this.only_new_order_lines = onlyNewLines;
    this.order_by = orderBy;
    this.accentry_id = "";
    this.keyWord = keyWord;
    this.keyWordOption = keyWordoption;
    this.invoice_type = "all";
    this.companyFilter = "";
  }
}
