<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container px-3">
  <div class="row">
    <div class="col-12">
      <h1 class="mb-0 mb-sm-2">
        <app-header-icon iconName="label"></app-header-icon>{{ "PRICELABELS" | translate }}
      </h1>
      <div class="row">
        <div class="col-12 col-lg-8 mb-5 py-4">
          <div class="my-pricecards-heading d-flex justify-content-between mb-3 flex-column flex-md-row">
            <h4 class="mb-3">{{ "MY_PRICELABELS" | translate }}</h4>
            <div>
              <button class="btn btn-dark btn-lg me-1 d-inline-flex align-items-center" id="addArticle" (click)="scanBarcode()">
                {{ "SCAN" | translate }}
                <app-bar-code-icon class="d-inline-flex ms-1" style="width: 32px"></app-bar-code-icon>
              </button>
              <button class="btn btn-dark btn-lg" id="addArticle" (click)="addArticle()">
                {{ "ADD" | translate }}
              </button>
            </div>
          </div>
          <ng-container *ngIf="pricelabels && pricelabels.length < 1">{{
            "NO_RESULTS" | translate
            }}</ng-container>
          <ng-container *ngIf="pricelabels && pricelabels.length">
            <ng-container *ngFor="let pricelabel of pricelabels; let i = index">
              <div *ngIf="pricelabel && pricelabel.article_details" class="pricelabel py-3 d-md-none">
                <div class="pricelabel-amount">
                  <input type="number" class="form-control" (blur)="updateLabel(pricelabel)"
                    [(ngModel)]="pricelabel.pricelabel_amount" />
                </div>
                <div class="pricelabel-article-image">
                  <app-article-image class="d-block" [imageUrl]="
                      pricelabel.article_details.thumbs
                        ? pricelabel.article_details.thumbs[0]
                        : ''
                    " [useLazyLoad]="false">
                  </app-article-image>
                </div>
                <div class="pricelabel-data">
                  <span contenteditable="true" (input)="
                      updateDescription($event.target.innerHTML, pricelabel)
                    ">{{
                    pricelabel.pricelabel_description
                    ? pricelabel.pricelabel_description
                    : pricelabel.article_details.description
                    }}
                  </span>
                  <small class="d-block withOpacity">{{
                    pricelabel.article_code
                    }}</small>
                  <hr />
                  <div class="pricelabel-actions d-flex justify-content-between align-items-center">
                    <ng-container>
                      <span class="me-2"> {{ "PRICE" | translate }}: </span>
                      <span class="me-2" *ngIf="pricelabel.article_details.retail_price">{{
                        pricelabel.article_details.retail_price.currency.replace(
                        "EUR",
                        "&euro;"
                        )
                        }}</span>
                      <input type="number" class="form-control d-inline-block me-3" (blur)="updateLabel(pricelabel)"
                        [(ngModel)]="pricelabel.pricelabel_price" [value]="
                          pricelabel.pricelabel_price
                            ? pricelabel.pricelabel_price
                            : pricelabel.article_details.retail_price
                            ? pricelabel.article_details.retail_price.value
                            : 0
                        " />
                    </ng-container>
                    <a class="d-md-none">
                      <i class="material-icons text-danger  clickable withOpacity"
                        (click)="deleteLabel(pricelabel.article_code)">delete_forever</i>
                    </a>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngFor="let pricelabel of pricelabels; let i = index">
              <div *ngIf="pricelabel && pricelabel.article_details"
                class="pricelabel p-3 d-none d-md-flex justify-content-between align-items-center">
                <div class="pricelabel-amount">
                  <input type="number" class="form-control" (blur)="updateLabel(pricelabel)"
                    [(ngModel)]="pricelabel.pricelabel_amount" />
                </div>
                <div class="pricelabel-article-image">
                  <app-article-image class="d-block" [imageUrl]="
                      pricelabel.article_details.thumbs
                        ? pricelabel.article_details.thumbs[0]
                        : ''
                    " [useLazyLoad]="false">
                  </app-article-image>
                </div>
                <div class="pricelabel-text">
                  <span contenteditable="true" (input)="
                      updateDescription($event.target.innerHTML, pricelabel)
                    ">{{
                    pricelabel.pricelabel_description
                    ? pricelabel.pricelabel_description
                    : pricelabel.article_details.description
                    }}
                  </span>
                  <small class="d-block withOpacity">{{
                    pricelabel.article_code
                    }}</small>
                </div>
                <ng-container>
                  <div class="pricelabel-retail-price d-flex align-items-center">
                    <span class="me-2"> {{ "PRICE" | translate }}: </span>
                    <span class="me-2">{{
                      pricelabel.article_details.retail_price
                      ? pricelabel.article_details.retail_price.currency.replace(
                      "EUR",
                      "&euro;"
                      )
                      : ""
                      }}</span>
                    <input type="number" class="form-control me-3 pricelabel-retail-price-input px-1"
                      (blur)="updateLabel(pricelabel)" [(ngModel)]="pricelabel.pricelabel_price" [value]="
                        pricelabel.pricelabel_price
                          ? pricelabel.pricelabel_price
                          : pricelabel.article_details.retail_price
                          ? pricelabel.article_details.retail_price.value
                          : 0
                      " />
                  </div>
                </ng-container>
                <a class="text-danger d-none d-md-inline-block" (click)="deleteLabel(pricelabel.article_code)">{{
                  "DELETE" | translate }}</a>
              </div>
            </ng-container>
          </ng-container>
          <div *ngIf="pricelabels && pricelabels.length"
            class="bottom-button-container mt-4 d-md-flex d-none justify-content-between">
            <button class="btn btn-outline-danger btn-lg me-3" id="deleteArticles" (click)="deleteAllLabels()">
              {{ "DELETE_LABELS" | translate }}
            </button>
            <button (click)="printLabels()" class="btn btn-primary btn-lg" id="printLabels">
              {{ "PRINT" | translate }}
            </button>
          </div>
        </div>
        <div id="priceLabelOptions" class="col-12 col-lg-4">
          <div class="pricelabel-options p-4">
            <h4 class="mb-4">{{ "EDIT_LAYOUT" | translate }}</h4>
            <div class="mb-3">
              <h5 class="mt-3">{{ "PAPERTYPE" | translate }}</h5>
              <div class="form-check px-0" *ngFor="let format of formats">
                <input [(ngModel)]="settings.format_id" class="me-2" type="radio" name="format_id" [value]="format.id" [id]="format.id" />
                <label [for]="format.id">{{format.name}}</label>
              </div>
            </div>
            <div class="mb-3 px-0">
              <h5 class="mt-3">{{ "INCLUDE_ON_LABEL" | translate }}</h5>
              <div class="form-check px-0">
                <input type="checkbox" class="me-2" name="include_price" id="include_price" value="price"
                  [(ngModel)]="settings.include_price" />
                <label for="include_price">{{ "PRICE" | translate }}</label>
              </div>
              <div class="form-check px-0">
                <input type="checkbox" class="me-2" name="include_description" id="include_description"
                  value="description" [(ngModel)]="settings.include_description" />
                <label for="include_description">{{
                  "DESCRIPTION" | translate
                  }}</label>
              </div>
              <div class="form-check px-0">
                <input type="checkbox" class="me-2" name="include_barcode" id="include_ean" value="ean"
                  [(ngModel)]="settings.include_barcode" />
                <label for="include_ean">{{ "BARCODE" | translate }}</label>
              </div>
              <div class="form-check px-0">
                <input type="checkbox" class="me-2" name="include_logo" id="include_logo" value="logo"
                  [(ngModel)]="settings.include_logo" />
                <label for="include_logo">{{ "LOGO" | translate }}</label>
              </div>
            </div>
            <div class="mb-3 px-0">
              <h5 class="mt-3">{{ "LOGO" | translate }}</h5>
              <select (change)="setLogo()" id="logo" class="form-control mb-2 col-8 col-md-6"
                [(ngModel)]="settings.logo_accentry" name="logo_accentry">
                <option [value]="0">{{ "LOGO_OWN" | translate }}</option>
                <option *ngFor="let logo of logos" [value]="logo.logo_id">
                  {{ logo.logo_name }}
                </option>
              </select>
              <img class="img-responsive mt-2" id="logoPreview" [attr.src]="logosrc" />

              <div class="col-md-12 px-0" id="logoSelect">
                <label for="fileinput" class="btn btn-outline-primary">{{
                  "SELECT_IMAGE" | translate
                  }}</label>
                <input id="fileinput" type="file" (change)="uploadLogo($event)"
                  accept="application/pdf, image/jpeg, image/png" style="visibility: hidden" />
                <input type="hidden" value="" id="logoData" />
              </div>
            </div>
            <div class="mb-3">
              <h5 class="mt-3">{{ "CURRENCY_SIGN" | translate }}</h5>
              <input type="text" class="form-control col-3" name="currency" [(ngModel)]="settings.currency" />
            </div>
            <div class="mb-3">
              <h5 class="mt-3">{{ "STARTPOSITION" | translate }}</h5>
              <input type="number" min="1" max="99" step="1" class="form-control col-3" name="start_position"
                [(ngModel)]="settings.start_position" />
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-button-container mt-4 d-flex justify-content-between d-md-none">
        <button class="btn btn-outline-danger btn-lg me-3" id="deleteArticles" (click)="deleteAllLabels()">
          {{ "DELETE_LABELS" | translate }}
        </button>
        <button (click)="printLabels()" class="btn btn-primary btn-lg" id="printLabels">
          {{ "PRINT" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
