<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ (newUser ? "NEW" : "EDIT") | translate }}
    {{ ("USER" | translate).toLowerCase() }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss()"
  >
    </button>
</div>

<div class="modal-body p-3" style="min-height: 540px">
  <nav ngbNav #nav="ngbNav" [destroyOnHide]="false"  [(activeId)]="active" class="nav-pills">
    <ng-container [ngbNavItem]="1">
      <a ngbNavLink>{{ "COMMON" | translate }}</a>
      <ng-template ngbNavContent>
        <form
          name="userForm"
          class="needs-validation"
          [formGroup]="userForm"
          *ngIf="userForm"
        >
          <div class="form-row my-2">
            <div class="mb-3 col-md-12">
              <h5 class="d-block my-2 pt-2">{{ "USERNAME" | translate }}</h5>
              <input
                type="text"
                class="form-control"
                formControlName="username"
                name="username"
                [readonly]="!newUser"
                [ngClass]="
                  userForm.get('username').invalid && user_wasValidated
                    ? 'is-invalid'
                    : ''
                "
                [placeholder]="
                  newUser && usersOverview.okta ? ('EMAIL' | translate) : ''
                "
              />
              <div
                class="invalid-feedback"
                *ngIf="userForm.get('username').invalid && user_wasValidated"
              >
                {{ "INVALID" | translate }}
              </div>
            </div>
            <div class="mb-3 col-md-12">
              <h5 class="d-block my-2">{{ "FIRST_NAME" | translate }}</h5>
              <input
                type="text"
                class="form-control"
                formControlName="first_name"
                name="first_name"
                [ngClass]="
                  userForm.get('first_name').invalid && user_wasValidated
                    ? 'is-invalid'
                    : ''
                "
              />
              <div
                class="invalid-feedback"
                *ngIf="userForm.get('first_name').invalid && user_wasValidated"
              >
                {{ "INVALID" | translate }}
              </div>
            </div>
            <div class="mb-3 col-md-4">
              <h5 class="d-block my-2">{{ "MIDDLENAME" | translate }}</h5>
              <input
                type="text"
                class="form-control"
                formControlName="middle_name"
                name="middle_name"
                [ngClass]="
                  userForm.get('middle_name').invalid && user_wasValidated
                    ? 'is-invalid'
                    : ''
                "
              />
              <div
                class="invalid-feedback"
                *ngIf="userForm.get('middle_name').invalid && user_wasValidated"
              >
                {{ "INVALID" | translate }}
              </div>
            </div>
            <div class="mb-3 col-md-8">
              <h5 class="d-block my-2">{{ "LASTNAME" | translate }}</h5>
              <input
                type="text"
                class="form-control"
                formControlName="last_name"
                name="last_name"
                [ngClass]="
                  userForm.get('last_name').invalid && user_wasValidated
                    ? 'is-invalid'
                    : ''
                "
              />
              <div
                class="invalid-feedback"
                *ngIf="userForm.get('last_name').invalid && user_wasValidated"
              >
                {{ "INVALID" | translate }}
              </div>
            </div>
            <ng-container *ngIf="!okta && newUser">
              <div class="mb-3 col-md-1">
                <h5 class="d-block my-2">{{ "PASSWORD" | translate }}</h5>
              </div>
              <div class="mb-3 col-md-12">
                <app-change-password-container
                  #passwordContainer
                  [changePasswordContainer]="setPasswordContainer"
                  [hideCurrentPassword]="true"
                ></app-change-password-container>
              </div>
            </ng-container>
            <div class="mb-3 col-md-12">
              <h5 class="d-block my-2 pt-2">{{ "MAINUSER" | translate }}</h5>
              <div class="form-text">
                <input type="checkbox" formControlName="owner" />
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="2" *ngIf="!isOwner">
      <a ngbNavLink>{{ "PROFILE" | translate }}</a>
      <ng-template ngbNavContent *ngIf="profileForm">
        <form name="profileForm" class="needs-validation">
          <div class="form-row">
            <div class="mb-3 col-md-6" [formGroup]="profileForm">
              <h5 class="my-2">{{ "PROFILE" | translate }}</h5>
              <select
                class="form-control"
                formControlName="profile"
                name="profile"
                formControlName="profile"
                [ngClass]="
                  profileForm &&
                  profileForm.get('profile').invalid &&
                  user_wasValidated
                    ? 'is-invalid'
                    : ''
                "
                required
              >
                <option [value]="''"></option>
                <ng-container *ngFor="let profile of profiles">
                  <option [value]="profile.profile_id">
                    {{ profile.profile_name }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>
        </form>
        <ng-container
          *ngIf="
            accountType === 'INT' && companyGroup === 'ANL' && user.ebst_access
          "
        >
          <div class="mb-3 col-md-6 px-0">
            <h5 class="d-block my-2 py-2">{{ "EBST_ACCESS" | translate }}</h5>
            <div class="d-flex justify-content-around">
              <label for="ebst_prod" class="checkbox-inline">
                <input
                  type="checkbox"
                  class="ebst setAccessData"
                  name="ebst_live"
                  [(ngModel)]="user.ebst_access.ebst"
                  value="prod"
                  id="ebst_prod"
                />
                EBST live
              </label>
              <label for="ebst_dev" class="checkbox-inline">
                <input
                  type="checkbox"
                  class="ebst setAccessData"
                  name="ebst_dev"
                  [(ngModel)]="user.ebst_access.ebst_dev"
                  value="dev"
                  id="ebst_dev"
                />
                EBST Development
              </label>
              <label for="ebst_test" class="checkbox-inline">
                <input
                  type="checkbox"
                  class="ebst setAccessData"
                  name="ebst_test"
                  [(ngModel)]="user.ebst_access.ebst_test"
                  value="test"
                  id="ebst_test"
                />
                EBST Test
              </label>
            </div>
            <h5 class="d-block my-2 py-2">
              {{ "EBST_PERMISSIONS" | translate }}
            </h5>
            <div class="d-flex justify-content-around">
              <label for="ebst_training" class="checkbox-inline">
                <input
                  type="checkbox"
                  class="ebst"
                  name="ebst_training"
                  [(ngModel)]="user.ebst_permissions.training"
                  value="TRAINING"
                  id="ebst_training"
                />
                Training
              </label>
              <label for="ebst_trainer" class="checkbox-inline">
                <input
                  type="checkbox"
                  class="ebst"
                  name="ebst_trainer"
                  [(ngModel)]="user.ebst_permissions.trainer"
                  value="TRAINER"
                  id="ebst_trainer"
                />
                Trainer
              </label>
              <label for="ebst_service" class="checkbox-inline">
                <input
                  type="checkbox"
                  class="ebst"
                  name="ebst_service"
                  [(ngModel)]="user.ebst_permissions.service"
                  value="SERVICE"
                  id="ebst_service"
                />
                Service
              </label>
              <label for="ebst_patches" class="checkbox-inline">
                <input
                  type="checkbox"
                  class="ebst"
                  name="ebst_patches"
                  [(ngModel)]="user.ebst_permissions.patches"
                  value="PATCHES"
                  id="ebst_patches"
                />
                Service patches
              </label>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="3" *ngIf="usersOverview && usersOverview.use_rayon">
      <a ngbNavLink>{{ "RAYON" | translate }}</a>
      <ng-template ngbNavContent>
        <h5 class="d-block my-2 pt-2" *ngIf="!newUser">
          {{ "RAYON" | translate }}
        </h5>
        <label class="text-primary">
          {{ "ENTER_RAYON" | translate }}
        </label>
        <div class="row mx-0">
          <select
            [(ngModel)]="selectedRayonGroup"
            class="form-control block"
            *ngIf="rayonKeys?.length > 2"
          >
            <option *ngFor="let rkey of rayonKeys" [value]="rkey">
              {{ "COMPANY_" + rkey | translate }}
            </option>
          </select>
          <ng-container *ngFor="let rayonGroup of rayonCodes; let r = index">
            <div
              ngbDropdown
              class="col rayon_codes_dropdown ps-0"
              [autoClose]="false"
              [hidden]="
                selectedRayonGroup !== rayonKeys[r] && rayonKeys?.length > 2
              "
              [ngClass]="rayonKeys?.length === 2 ? 'max-width' : 'col-12'"
              [open]="true"
            >
              <div *ngIf="rayonKeys?.length <= 2">
                {{ "COMPANY_" + rayonKeys[r] | translate }}
              </div>
              <div
                class="dropdown-form px-4 py-3 col-12"
                ngbDropdownMenu
                aria-labelledby="dropdownForm1"
              >
                <div class="mb-3">
                  <input
                    name="search"
                    autocomplete="off"
                    [(ngModel)]="searchRayon[rayonKeys[r]]"
                    (blur)="selectedIndex = -1"
                    (keyup)="selectedIndex = -1"
                    type="search"
                    class="form-control"
                    placeholder="{{ 'SEARCH' | translate }}"
                  />
                </div>
                <ng-container *ngIf="selectedRayonCodes[rayonKeys[r]]">
                  <div
                    class="form-check"
                    *ngFor="
                      let rayoncode of rayonGroup
                        | SearchUserPipe: '':searchRayon[rayonKeys[r]];
                      let i = index
                    "
                  >
                    <a>
                      <label
                        class="form-check-label checkbox"
                        for="{{ rayoncode + '' + rayonKeys[r] }}"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input checkbox"
                          id="{{ rayoncode + '' + rayonKeys[r] }}"
                          [(ngModel)]="
                            selectedRayonCodes[rayonKeys[r]][rayoncode]
                          "
                        />{{ rayoncode }}
                      </label>
                    </a>
                  </div>
                </ng-container>
              </div>
              <ng-container
                *ngIf="
                  selectedRayonCodes[rayonKeys[r]] &&
                  selectedRayonCodes[rayonKeys[r]].length
                "
              >
                <p class="mt-3 mb-1">{{ "SELECTED_RAYONCODES" | translate }}</p>
                <ul>
                  <ng-container
                    *ngFor="
                      let selectedRayonCode of selectedRayonCodes[rayonKeys[r]]
                    "
                  >
                    <li>
                      {{ selectedRayonCode }}
                    </li>
                  </ng-container>
                </ul>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="4" *ngIf="companyGroup === 'ANL'">
      <a ngbNavLink>{{ "ACCELLACADEMY" | translate }}</a>
      <ng-template ngbNavContent>
        <h5 class="d-block my-2 pt-2">{{ "ACCELLACADEMY" | translate }}</h5>
        {{ "ACCESS_ACADEMY" | translate }}
        <form
          name="academy_form"
          class="needs-validation"
          [formGroup]="academyForm"
        >
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="has_access"
              type="radio"
              name="has_access"
              id="academy_access_yes"
              [value]="true"
            />
            <label class="form-check-label" for="academy_access_yes">
              {{ "YES" | translate }}
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              formControlName="has_access"
              type="radio"
              name="has_access"
              id="academy_access_no"
              [value]="false"
            />
            <label class="form-check-label" for="academy_access_no">
              {{ "NO" | translate }}
            </label>
          </div>
          <div
            class="form-row my-2"
            *ngIf="academyForm.get('has_access').value"
          >
            <div class="mb-3 col-md-12">
              <label class="d-block my-2 pt-2">{{ "EMAIL" | translate }}</label>
              <input
                type="text"
                class="form-control"
                formControlName="email_new"
                name="email_new"
                [ngClass]="
                  academyForm.get('email_new').invalid && acc_wasValidated
                    ? 'is-invalid'
                    : ''
                "
              />
              <div
                class="invalid-feedback"
                *ngIf="academyForm.get('email_new').invalid && acc_wasValidated"
              >
                {{ "INVALID" | translate }}
              </div>
            </div>
            <div class="mb-3 col-md-12">
              <label class="d-block my-2 pt-2">{{
                "CURRENT_ELEARNING_ACCOUNT" | translate
              }}</label>
              <select
                class="form-control"
                name="current_account"
                formControlName="current_account"
              >
                <option
                  *ngFor="let account of academy_accounts"
                  [value]="account.member_id"
                >
                  {{ account.member_name }}
                </option>
              </select>
            </div>
          </div>
        </form>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="5">
      <a ngbNavLink>{{ "SHIPPINGADDRESSES" | translate }}</a>
      <ng-template ngbNavContent>
        <h5 class="d-block my-2 pt-2" *ngIf="!newUser">
          {{ "CURRENT" | translate }}
        </h5>

        <div
          *ngFor="let addressGroup of shippingAddressGroups"
          class="row my-4"
        >
          <div class="col-md-5">
            <b>{{ "COMPANY_" + addressGroup.company_id | translate }}</b> <br />

            <div class="form-check ps-0">
              <input
                class="form-check-input"
                [(ngModel)]="addressGroup.address_access"
                type="radio"
                id="default_{{ addressGroup.company_id }}"
                value="{{ addressGroup.company_id }}"
                [name]="'address_access_' + addressGroup.company_id"
                hidden
              />

              <label
                class="form-check-label"
                for="default_{{ addressGroup.company_id }}"
              >
                {{ "DEFAULTSHIPPINGADDRESS" | translate }}</label
              >
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                [(ngModel)]="addressGroup.access_only_address"
                type="checkbox"
                id="access_only_address_{{ addressGroup.company_id }}"
                value="{{ addressGroup.company_id }}"
                [name]="'access_only_address_' + addressGroup.company_id"
              />

              <label
                class="form-check-label"
                for="access_only_address_{{ addressGroup.company_id }}"
              >
                {{ "FIXED_SHIPPINGADDRESS" | translate }}</label
              >
            </div>
          </div>
          <div class="col-md-7 pt-2">
            <app-select-shipping-address
              [shippingAddresses]="addressGroup"
              [showAllCompanies]="true"
              [showBlank]="true"
              [showSelected]="true"
            >
            </app-select-shipping-address>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container [ngbNavItem]="6" *ngIf="!newUser">
      <a ngbNavLink>{{ "OPTIONS" | translate }}</a>
      <ng-template ngbNavContent>
        <h5 class="mt-2">{{ "RESET_PASSWORD" | translate }}</h5>

        <button
          class="btn btn-primary"
          *ngIf="
            user.email_address === '';
            else reset
          "
          (click)="resendNewActivation()"
        >
          {{ "ACTIVATION.RESEND_ACTIVATION" | translate }}
        </button>
        <ng-template #reset>
          <button class="btn btn-sm btn-danger" (click)="resetPassword()">
            {{ "RESET" | translate }}
          </button>
        </ng-template>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<div class="modal-footer">
  <span class="col-md-12 p-0">
    <div
      class="alert alert-danger d-inline-block me-1"
      role="alert"
      *ngIf="
        acc_wasValidated &&
        !academyForm.valid &&
        academyForm.get('has_access').value
      "
    >
      Academy invalid
    </div>
    <div
      class="alert alert-danger d-inline-block me-1"
      role="alert"
      *ngIf="user_wasValidated && !userForm.valid && newUser"
    >
      {{ "ERROR_PARAMETER" | translate }}
    </div>
    <button
      class="btn btn-success btn-lg d-flex align-items-center gap-2"
      (click)="save()"
      [disabled]="saving ? 'disabled' : undefined"
    >
    <span
        *ngIf="saving"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <span>{{ "SAVE" | translate }}</span>
    </button>
  </span>
</div>
