import { Component, OnInit, Sanitizer, SecurityContext } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PackingSlip } from "src/app/interfaces/financial/packingslips/packing-slip";
import { FinancialService } from "src/app/services/financial.service";
import { ApplicationService } from "src/app/services/application.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { saveAs } from "file-saver";
@Component({
  selector: "app-packing-slip-viewer",
  templateUrl: "./packing-slip-viewer.component.html",
  styleUrls: ["./packing-slip-viewer.component.scss"]
})
export class PackingSlipViewerComponent implements OnInit {
  pdfSrc = "";
  loaded: boolean;
  packingSlip: PackingSlip;
  pdfBlob: any;
  ttUrl: SafeResourceUrl;
  constructor(
    public modal: NgbActiveModal,
    private financialService: FinancialService,
    private applicationService: ApplicationService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.getPackingSlip();
  }

  // Get pdf from API
  getPackingSlip() {
    if (typeof this.packingSlip !== "undefined") {
      this.applicationService.showLoader(true);
      this.financialService
        .getPackingSlip(this.packingSlip.packing_slip_number)
        .subscribe((blob: Blob) => {
          this.applicationService.hideLoader();
          this.generateUrl(blob);
        });
    }
  }

  // Generate pdf
  generateUrl(blob) {
    const file = new Blob([blob], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    this.pdfSrc = fileURL;
    this.pdfBlob = file;
    this.loaded = true;
  }

  // Download file
  download() {
    saveAs(
      this.pdfBlob,
      `packingslip-${this.packingSlip.packing_slip_number}.pdf`
    );
  }
}
