<ng-container>
  <td class="py-2 px-0" style="max-width: 30px;">

  </td>
  <td class="py-2">
    {{invoice.invoice_number}}
  </td>
  <td class="py-2">
    {{invoice.invoice_date}}
  </td>
  <td class="py-2">
    <span class="badge bg-success ms-1"
      *ngIf="invoice.amount_paid === invoice.invoice_amount">{{'PAID'|translate}}</span>
    <ng-container *ngIf="invoice.amount_paid !== invoice.invoice_amount">{{invoice.due_date}}</ng-container>
  </td>
  <td class="py-2">
    {{numberFormat.formatDot(invoice.invoice_amount)}}<br />
    <small>{{'DISCOUNT_AMOUNT' | translate}}: {{numberFormat.formatDot(invoice.discount_amount)}}</small>
  </td>
  <td class="py-2">
    {{numberFormat.formatDot(invoice.open_amount)}}<br />
    <small>{{'AMOUNT_PAID' | translate}}: {{numberFormat.formatDot(invoice.amount_paid)}}</small>
  </td>
  <td class="py-2">
    {{invoice.discount_day}}
  </td>
  <td class="py-2">
    <b (click)="viewInvoice()" class="clickable withOpacity" *ngIf="invoice.pdf_url"><i class="material-icons">
      picture_as_pdf
    </i> {{invoice.invoice_number}}</b>
  </td>
  <td class="py-2">
    {{invoice.accentry_id}}
  </td>
</ng-container>
