import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter
} from "@angular/core";
import { SearchService } from "src/app/services/search.service";
import { Article } from "src/app/interfaces/article";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { ApplicationService } from "src/app/services/application.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { AccountService } from "src/app/services/account.service";

@Component({
  selector: "app-instant-result",
  templateUrl: "./instant-result.component.html",
  styleUrls: ["./instant-result.component.scss"]
})
export class InstantResultComponent implements OnInit {
  @Input() searchTerm = "";
  @Input() instantArticle: Article;
  @Output() showArticle: EventEmitter<any> = new EventEmitter<any>();
  display = false;
  showBom = true;

  constructor(
    private searchService: SearchService,
    public applicationService: ApplicationService,
    private router: Router,
    private accountService: AccountService,
  ) {}

  ngOnInit() {
    this.applicationService.emitModalOpened$.subscribe(response => {
      this.display = false;
    });

  }

  getResult() {
    if (
      this.searchTerm &&
      this.searchTerm.length > 3 &&
      (!this.instantArticle ||
        (this.instantArticle &&
          this.instantArticle.single_article_code !== this.searchTerm))
    ) {
      this.instantArticle = null;
      this.searchService.searchInstant(this.searchTerm).subscribe((response: ApiResponse) => {
        if (response && response.result && response.result.length) {
          this.instantArticle = response.result[0];
          if (this.instantArticle.single_article_code !== this.searchTerm) {
            this.instantArticle.frame_number = this.searchTerm;
          }
          this.display = true;
          const self = this;
          self.searchService.getNettPrices([self.instantArticle]);
          this.showArticle.next(true);
          this.showBom = this.accountService.getAccountType() !== "DLR" || this.applicationService.getSelectCompanyGroupCode() !== "WG";
        }
      });
    } else if (this.instantArticle) {
      this.display = true;
    }
  }

  showArticleDetails(article: Article) {
    this.display = false;
    let url = `${this.applicationService.getSelectCompanyGroupCode()}/articleDetails/${
      article.id
    }`;

    if (article.single_article_code) {
      url = `${url}/${article.single_article_code}`;
    }

    if (this.searchTerm && (!article.single_article_code)) {
      url = `${url}/${this.searchTerm}`;
    }

    if (article.frame_number) {
      url = `${url}?framenumber=${article.frame_number}`;
    }

    this.router.navigateByUrl(url);
  }
}
