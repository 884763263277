<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'REGISTRATION_SUCCESSFUL' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
</div>
<div class="modal-body p-0">
  <div class="row mx-0">
    <div class="col-md-12 p-4">
      <ngb-alert *ngIf="type === 'registerSale'" type="success" [dismissible]="false">{{'REGISTER_SALE_SUCCESSFUL'|translate}}</ngb-alert>
      <ngb-alert *ngIf="type === 'registerSalePrepare'" type="success" [dismissible]="false">{{'EBIKE_PREPARED_FOR_USE'|translate}}</ngb-alert>
      <ngb-alert *ngIf="type === 'ezyRider'" type="success" [dismissible]="false">{{'XTRA_REGISTERED'|translate}}</ngb-alert>
      <ngb-alert *ngIf="type === 'service'" type="success" [dismissible]="false">{{'REGISTER_SERVICE_SUCCESSFUL'|translate}}</ngb-alert>
      <button class="btn btn-success btn-lg mb-2 d-block" (click)="downloadPdf()"
      *ngIf="pdfSrc">{{'DOWNLOAD'|translate}} PDF</button>
      <div class="scrollable customScrollbar pdfContainer d-block overflow-auto" style="max-height: 60vh"
        *ngIf="type === 'registerSalePrepare' || type === 'ezyRider'">
        <div class="m-2 spinner-border text-primary spinner-border" role="status" *ngIf="loading">
          <span class="visually-hidden">{{'LOADING'|translate}}</span>
        </div>

        <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" *ngIf="pdfSrc"
          class="border d-block w-100">
        </pdf-viewer>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <span class="col-md-12 p-0">
    <button class="btn btn-dark " (click)="modal.dismiss('cancel click')">{{'CLOSE' | translate}}</button>
  </span>
</div>
