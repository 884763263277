import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { ApiResponse } from '../interfaces/api-response';
import { AccountService } from '../services/account.service';
import { AlertService } from '../services/alert.service';
import { HelperService } from '../services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from '../services/application.service';

@Injectable({
  providedIn: 'root'
})
export class ServicePortalGuard implements CanActivate {

  constructor(
    private accountService: AccountService,
    private helperService: HelperService,
    private alertService: AlertService,
    private translationService: TranslateService,
    private applicationService: ApplicationService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    this.applicationService.showLoader(true);


    return this.accountService.portalSignin().pipe(
      map((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          window.location.href = apiResponse.result;
          return false; // Prevent navigation as redirect happens
        } else {
          this.alertService.showPopup(
            apiResponse ? apiResponse.result : this.translationService.instant('ERROR'),
            'error',
            0
          );
          return false; // Prevent navigation due to error
        }
      }),
      catchError((error) => {
        this.alertService.showPopup(
          this.translationService.instant('ERROR'),
          'error',
          0
        );
        return of(false); // Return observable of false to prevent navigation
      }),
      finalize(() => {
        this.applicationService.hideLoader();
      })

    );
  }
}
