import { Component, OnInit, Input } from "@angular/core";
import { ApiResponse } from "src/app/interfaces/api-response";
import { CmsService } from "src/app/services/cms.service";
import { Widget } from "src/app/interfaces/cms/widget";
import { ApplicationService } from "src/app/services/application.service";
import { HelperService } from "src/app/services/helper.service";
import { CacheService } from "src/app/services/cache.service";
import { CacheType } from "src/app/interfaces/cache-tracker";

@Component({
  selector: "app-widgets",
  templateUrl: "./widgets.component.html",
  styleUrls: ["./widgets.component.scss"]
})
export class WidgetsComponent implements OnInit {
  @Input() autoload: boolean;
  widgets: Widget[];
  loaded = false;
  constructor(
    private cmsService: CmsService,
    private applicationService: ApplicationService,
    private helperService: HelperService,
    private cacheService: CacheService
  ) {}

  ngOnInit() {
    if (this.autoload) {
      this.getWidgets();
    }
  }

  refresh(event) {
    if (event.altKey) {
      this.getWidgets();
      event.preventDefault();
      return false;
    }
  }

  getWidgets() {
    if (this.cacheService.needRefresh(CacheType.widgets)) {
      this.cmsService.getWidgets().subscribe((apiResponse: ApiResponse) => {
        this.loaded = true;
        if (this.helperService.checkResponse(apiResponse)) {
          if (apiResponse.result.items && apiResponse.result.items.length) {
            this.widgets = apiResponse.result.items;
            sessionStorage.setItem(
              "widgets-" + this.applicationService.getSelectCompanyGroupCode(),
              JSON.stringify(this.widgets)
            );
          }
          this.cacheService.dataLoaded(CacheType.widgets);
        } else {
          this.widgets = [];
        }
      });
    }
  }
}
