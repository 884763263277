import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-search-dealer-modal",
  templateUrl: "./search-dealer-modal.component.html",
  styleUrls: ["./search-dealer-modal.component.scss"]
})
export class SearchDealerModalComponent implements OnInit {
  redirectUrl = "";
  constructor(public modal: NgbActiveModal) {}

  ngOnInit() {}
}
