import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ApiResponse } from "src/app/interfaces/api-response";
import { YearAgreementBase } from "src/app/interfaces/year-agreement-base";
import { YearAgreementPAndA } from "src/app/interfaces/year-agreement-p-and-a";
import { environment } from "src/environments/environment";
import { HelperService } from "../../helper.service";
import { ApplicationService } from "../../application.service";
import { AlertService } from "../../alert.service";

@Injectable({
  providedIn: "root",
})
export class YearAgreementPAndAService {
  private emitChangeSource = new Subject<any>();
  brandChangeEmitted$ = this.emitChangeSource.asObservable();
  agreementModel: YearAgreementPAndA;
  maxPartBrandSelection: number;

  constructor(private http: HttpClient, private helperService: HelperService, private applicationService: ApplicationService, private alertService: AlertService) {}

  // Get the Parts agreement
  agreement(agreement: YearAgreementBase) {
    return new Promise((resolve) => {
      this.http
        .get(
          `${
            environment.apiendpoint
          }yearagreementsanlparts/${agreement.id.toString()}`
        )
        .subscribe((apiResponse: ApiResponse) => {
          if (this.helperService.checkResponse(apiResponse)) {
            this.agreementModel = Object.assign(agreement, apiResponse.result);
            this.agreementModel.selected_dealer_level = Number(this.agreementModel.selected_dealer_level);
            resolve(true);
          } else {
            this.applicationService.hideLoader();
            this.alertService.showErrorNotification("ERROR");
          }
        });
    });
  }

  // Save the Parts agreement
  saveAgreement(yearAgreement: YearAgreementPAndA) {
    return this.http.put(
      `${environment.apiendpoint}yearagreementsanlparts/${yearAgreement.year_agreement_base.id}`,
      yearAgreement
    );
  }

  setDealerSettings() {
    const level = this.agreementModel.dealer_levels.find((b) => {
      return b.id.toString() == this.agreementModel.selected_dealer_level.toString();
    });

    const index = this.agreementModel.dealer_levels.findIndex((b) => {
      return b.id.toString() == this.agreementModel.selected_dealer_level.toString();
    });

    this.agreementModel.selected_dealer_level_index = index;

    if (level) {
      this.maxPartBrandSelection = level.brands;
    }

    if (
      Number(this.agreementModel.selected_dealer_level) >= 0 &&
      (typeof this.agreementModel.selected_bike_dealer_ship === "undefined" || this.agreementModel.selected_bike_dealer_ship)
    ) {
      if (level) {
        this.agreementModel.dropshipment = level.dropshipment;

        this.agreementModel.shipping_cost = level.shipping_cost;

        this.agreementModel.shipping_schedule = level.shipping_schedule;

        this.agreementModel.brands?.forEach((b) => {
          b.value = b.selected ? b.discount : null;
        });
      }
    }
  }

  brandSelected(brandId: string, groupId: number) {
    this.emitChangeSource.next({
      brandId: brandId,
      groupId: groupId,
    });
  }
}
