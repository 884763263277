import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { Article } from "src/app/interfaces/article";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { BasketService } from "src/app/services/basket.service";
import { AlertService } from "src/app/services/alert.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Basket } from "src/app/interfaces/basket";
import { BasketArticle } from "src/app/interfaces/basketArticle";
import * as _ from "underscore";
import { ApplicationService } from "src/app/services/application.service";
import { PreorderSettings } from "src/app/interfaces/settings/preorder-settings";
import { PreorderScheduleItem } from "src/app/interfaces/preorder/preorder-schedule-item";
import { AddToBasketModalComponent } from "../../modal-components/add-to-basket-modal/add-to-basket-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MatrixOption } from "src/app/interfaces/matrixOption";
import { ArticleMatrix } from "src/app/interfaces/article-matrix";
import { PreorderService } from "src/app/services/preorder.service";
import { PreorderInputCollection } from "src/app/interfaces/preorder/preorder-input-collection";
import { PreorderSelectedArticle } from "src/app/interfaces/preorder/preorder-selected-article";
import { FavoritesService } from "src/app/services/favorites.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { TrackingService } from "src/app/services/tracking.service";

@Component({
  selector: "app-article-weeks-table",
  templateUrl: "./article-weeks-table.component.html",
  styleUrls: ["./article-weeks-table.component.scss"],
})
export class ArticleWeeksTableComponent implements OnInit {
  @Input() article: Article;
  @Input() basketArticles: BasketArticle[];
  @Input() edit: boolean;
  @Input() type: string;
  @Input() showAdditionalItems: boolean;
  @Input() hideSaveButton: boolean;
  @Input() preorderBasketId: number;
  @Input() useTimeSlots: boolean;
  @Input() monthSelectionOnly: boolean;
  @Input() bico: boolean;
  @Input() basketArticleId: number;
  @Input() additionalBasketSections;
  @Input() schedule: PreorderScheduleItem[];
  @Output() qtyChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("preorderTableContainer") preorderTableContainer: ElementRef;
  @ViewChild("container") el: ElementRef;
  monthDescriptions = [];
  @Input() selectedArticles = {};
  @Input() countryCode: string;
  addedToBasket = false;
  availableTimeSlots = []; // Todo, setup via CMS?
  addEnabled = false;
  loading = false;
  demo = {
    week: 0,
    month: 0,
    type: "demo",
  };
  repair = {
    week: 0,
    month: 0,
    type: "repair",
  };
  loading_wishlist: boolean;
  startYear: number;
  currentYear: number;
  touchscreen: boolean;
  repairMaxRetailPrice = 3500;
  demoMaxRetailPrice = 9500;
  unsaved: boolean;
  onlyFlex: boolean;

  // DELIVERYNOTICE2022
  deliveryDateNotice2022: string = "";
  companyGroupCode: string = "";
  matrixOptions: MatrixOption[] = [];

  setMatrixOptions() {
    if (!this.article.matrix || !this.article.matrix.options.length) {
      const matrix = new ArticleMatrix();
      matrix.options = [];
      const option = new MatrixOption();
      option.description = "";
      option.value = [this.article];
      matrix.options.push(option);
      this.matrixOptions = matrix.options;
    } else {
      this.matrixOptions = this.article.matrix.options;
    }
  }

  get showDemoRepair() {
    return (
      this.additionalBasketSections &&
      ((this.companyGroupCode === "GHO" && !["25", "6"].includes(this.article.company_id)) || this.companyGroupCode !== "GHO")
    );
  }

  constructor(
    private translationService: TranslateService,
    private basketService: BasketService,
    private alertService: AlertService,
    private applicationService: ApplicationService,
    private modalService: NgbModal,
    private preorderService: PreorderService,
    private favoritesService: FavoritesService,
    private translateService: TranslateService,
    private deviceDetector: DeviceDetectorService,
    private trackingService: TrackingService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["useTimeSlots"]) {
      // Todo use setting in preorderSettings to determine months or timeslots!
      if (this.useTimeSlots) {
        this.setTimeSlotDescriptions();
      } else {
        this.setDescriptions();
      }
    }
  }

  ngOnInit() {
    // Bico settings
    if (this.article.bico && this.bico) {
      this.availableTimeSlots = this.preorderService.availableTimeSlotsBico;
      this.additionalBasketSections = false;
    } else {
      if (this.applicationService.getSelectCompanyGroupCode() === "GHO") {
        this.availableTimeSlots = this.preorderService.availableTimeSlots_GHO;
      }
      else if (this.applicationService.getSelectCompanyGroupCode() === "WG") {
        this.availableTimeSlots = this.preorderService.availableTimeSlots_WG;
      }
      else {
        this.availableTimeSlots = this.preorderService.availableTimeSlots;
      }
    }

    if (this.countryCode !== "DE") {
      this.additionalBasketSections = false;
    }

    this.currentYear = Number(moment().format("YYYY"));
    this.startYear = this.preorderService.startYear;
    this.touchscreen =
      this.deviceDetector.isMobile() || this.deviceDetector.isTablet();

    // Models of currentyear can only be ordered with Flexdelivery
    // if (this.applicationService.getSelectCompanyGroupCode() === "WG") {
    //   this.onlyFlex =
    //     this.article.carry_over ||
    //     Number(this.article.season) <= this.currentYear;
    // }

    // if (this.applicationService.getSelectCompanyGroupCode() === "GHO") {
    //   this.onlyFlex = Number(this.article.season) <= this.currentYear;
    // }

    if (!this.article.matrix) {
      this.article.matrix = new ArticleMatrix();
      this.article.matrix.options = [];
    }

    if (!this.article.matrix.options.length) {
      const option = new MatrixOption();
      option.description = "";
      option.value = [this.article];
      this.article.matrix.options.push(option);
    }

    if (this.basketArticles && this.basketArticles.length) {
      this.basketArticles.forEach((basketArticle: BasketArticle) => {
        let key = "";
        if (basketArticle.order_line_group) {
          const groupKey =
            basketArticle.order_line_group.indexOf("repair") >= 0
              ? "repair"
              : "demo";
          key = `${basketArticle.article_code}${groupKey}`;
        } else {
          key = basketArticle.asap_delivery
            ? `${basketArticle.article_code}`
            : `${basketArticle.article_code}${Number(
              this.applicationService.getTimeslotFromMonth(
                String(
                  this.applicationService.getMonthFromWeek(
                    basketArticle.delivery_week,
                    basketArticle.delivery_year
                  )
                )
              )
            )}${basketArticle.delivery_year}`;
        }

        if (this.selectedArticles[key]) {
          this.selectedArticles[key]["amount"] += basketArticle.amount;
        } else {
          this.selectedArticles[key] = basketArticle;
        }
      });
    }
    // DELIVERYNOTICE2022
    this.deliveryDateNotice2022 = this.translateService.instant(
      "DELIVERY_DATE_NOTICE_2022"
    );
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();

    this.setMatrixOptions();
  }

  // set the month descriptions
  setDescriptions() {
    for (let i = 1; i <= 12; i++) {
      this.monthDescriptions.push(
        this.translationService.instant(`MONTH_${i < 10 ? "0" + i : i}`)
      );
    }
  }

  setTimeSlotDescriptions() {
    for (let i = 1; i <= 12; i++) {
      this.monthDescriptions.push(
        this.translationService.instant(`MONTH_${i < 10 ? "0" + i : i}`) +
        " - " +
        this.translationService.instant(
          `MONTH_${i + 1 < 10 ? "0" + (i + 1) : i + 1}`
        )
      );
    }
  }

  addToBasket() {
    const parent = this.el.nativeElement;
    let preorderInputCollection: PreorderInputCollection =
      this.preorderService.collectInput(parent, this.basketArticleId);

    const promise = new Promise((resolve, reject) => {
      if (!this.edit) {
        resolve(true);
      } else {
        if (!this.article.has_mandatory_item || !preorderInputCollection.articleList?.length) {
          this.basketService
            .deletePreorderModel(this.article.id, this.type)
            .subscribe((apiResponse: ApiResponse) => {
              if (!preorderInputCollection.articleList.length) {
                this.alertService.showInfoNotification(
                  this.translationService.instant("DELETED")
                );
                this.resetForm();
                this.basketService.removePreorderItemAction();
              }
              resolve(true);
            });
        }
      }
    });

    if (
      preorderInputCollection.articleList &&
      preorderInputCollection.articleList.length
    ) {
      this.loading = true;
      if (this.article.has_mandatory_item) {
        this.showMandatoryModal(preorderInputCollection.totalQuantity);
        this.resetForm();
        this.addedToBasket = true;
        this.loading = false;
      } else {
        promise.then(() => {
          this.basketService
            .addArticlesToBasket(
              preorderInputCollection.articleList,
              this.preorderBasketId
            )
            .then((responseData: ApiResponse) => {
              this.loading = false;

              if (responseData.success) {
                this.alertService.showInfoNotification(responseData.result);
                this.saved.emit(true);
                this.addedToBasket = true;
                this.resetForm();
              } else {
                this.alertService.showErrorNotification(
                  this.translationService.instant(
                    responseData.result ? responseData.result : "ERROR_500"
                  )
                );
              }
            });
        });
      }
    }
  }

  showMandatoryModal(quantity: number) {
    const modalRef = this.modalService.open(AddToBasketModalComponent, {
      centered: true,
      windowClass: "modal-" + this.article.id,
      container: "#modalContainer",
      size: "lg",
    });
    modalRef.componentInstance.article = { ...this.article };
    modalRef.componentInstance.totalQtyPreorderItems = quantity;
    modalRef.componentInstance.quantity = 0;
    modalRef.componentInstance.preOrderType = this.type;
    modalRef.componentInstance.basket = this.preorderBasketId;
    const parent = this.el.nativeElement;
    modalRef.componentInstance.mainArticle = [this.article];
    modalRef.componentInstance.selectedArticles =
      this.getMainArticleSelection();
    modalRef.componentInstance.onlyMandatory = true;
  }

  private getMainArticleSelection() {
    const parent = this.el.nativeElement;
    const articles: PreorderInputCollection =
      this.preorderService.collectInput(parent);
    const selectedArticles = {};
    if (articles && articles.articleList) {
      articles.articleList.forEach((article: PreorderSelectedArticle) => {
        const key = article.asap_delivery
          ? `${article.single_article_code}`
          : `${article.single_article_code}${Number(
            `${this.applicationService.getMonthFromWeek(
              article.delivery_week.substr(4, 6),
              article.delivery_week.substr(0, 4)
            )}${article.delivery_week.substr(0, 4)}`
          )}`;
        selectedArticles[key] = {
          amount: article.quantity,
        };
      });
    }
    return selectedArticles;
  }

  resetForm() {
    const parent = this.el.nativeElement;
    const inputs = Array.from(parent.getElementsByClassName("enabledInput"));
    inputs.forEach((el: HTMLInputElement) => {
      el.value = "";
    });
  }

  checkQuantity() {
    const parent = this.el.nativeElement;
    const inputs = Array.from(parent.getElementsByClassName("enabledInput"));
    const sum = inputs.reduce((s, f) => {
      return Number(s) + Number(f["value"]);
    }, 0);
    this.qtyChanged.emit(sum as number);
    this.addEnabled = Boolean(sum);
    this.preorderService.unsaved = true;
  }

  addToWishList() {
    this.loading_wishlist = true;
    this.favoritesService
      .addFavorite(this.article.article_codes[0], `wishlist_${this.type.toLowerCase()}`)
      .subscribe((apiResponse: ApiResponse) => {
        this.trackingService.favorite(this.article, "Add");
        this.alertService.showInfoNotification(
          this.translateService.instant("FAVORITE_ADDED")
        );
        this.article.is_favorite = true;
        this.loading_wishlist = false;
      });
  }

  removeFromWishList() {
    this.favoritesService
      .removeFavorite(
        this.article.article_codes[0],
        `wishlist_${this.type.toLowerCase()}`
      )
      .subscribe((apiResponse: ApiResponse) => {
        this.trackingService.favorite(this.article, "Remove");
        this.alertService.showInfoNotification(
          this.translateService.instant("FAVORITE_REMOVED")
        );
        this.article.is_favorite = false;
        this.favoritesService.emitChange({
          type: "remove",
          id: this.article.id,
        });
      });
  }

  // DELIVERYNOTICE2022
  showDeliveryDateNotice2022(article) {
    if (
      article.stock_year >= 2022 &&
      article.stock_year < 9999 &&
      (this.companyGroupCode === "WG" || this.companyGroupCode === "GHO") &&
      article.article_type &&
      article.article_type.startsWith("F")
    ) {
      return this.deliveryDateNotice2022;
    }
  }
}
