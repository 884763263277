<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="row">
  <div class="container ps-3 pe-2 col-md-12 px-md-0">
    <app-basket-skeleton-loader *ngIf="!basket"/>
    <ng-container *ngIf="basket">
      <div class="articleCollection">
        <div class="px-2 mx-0 mb-3 row">
          <div class="pt-3 mb-2 col-12 order-text">
            <div class="row h5">
              <div class="col-8 col-sm-6">
                <p *ngIf="showSentMessage" class="my-2">
                  {{ "ORDER_PROCESS_MESSAGE" | translate }}
                </p>
                <strong>
                  {{ "BASKET_ORDER" | translate }} #{{
                    basket ? basket.order_number : ""
                  }}
                  <ng-container *ngIf="!basket.pre_order">
                    {{ basket.name }}
                  </ng-container>
                </strong>
                <br />
                <div class="d-flex align-items-center">
                  {{ basket.last_edit }}
                </div>

                <span
                  class="px-2 py-1 mt-2 me-1 badge bg-warning badge-pill"
                  *ngIf="basket.pre_order"
                  >{{ "PREORDER" | translate }}</span
                >
                <small class="pt-3 d-block" *ngIf="basket.shipping_address && groupedArticles &&
                groupedArticles['bicycles'] &&
                groupedArticles['bicycles'].items.length">
                  {{ "SHIPPINGADDRESS" | translate }}<ng-container *ngIf="basket.shipping_address_parts && groupedArticles &&
                  groupedArticles['parts'] &&
                  groupedArticles['parts'].items.length && (basket.shipping_address_parts?.address !== basket.shipping_address?.address)"> ({{ "BICYCLES" | translate }}):</ng-container><br />
                  {{ basket.shipping_address.address }}<br />
                  {{ basket.shipping_address.zip }}
                  {{ basket.shipping_address.city }}
                </small>
                <small class="pt-3 d-block" *ngIf="basket.shipping_address_parts && groupedArticles &&
                groupedArticles['parts'] &&
                groupedArticles['parts'].items.length && (basket.shipping_address_parts?.address !== basket.shipping_address?.address || !groupedArticles['bicycles'].items.length)">
                  {{ "SHIPPINGADDRESS" | translate }}:<br />
                  {{ basket.shipping_address_parts.address }}<br />
                  {{ basket.shipping_address_parts.zip }}
                  {{ basket.shipping_address_parts.city }}
                </small>
                <button
                  class="my-2 btn btn-dark btn-sm d-block"
                  (click)="gotoOrder()"
                >
                  {{ "TO_OPEN_ORDERS" | translate }}
                </button>
              </div>
              <div class="col-4 col-sm-6 align-items-end d-flex">
                <img
                  src="https://a.storyblok.com/f/118996/400x249/5c269985f1/truck_order.png"
                  class="w-100 ms-auto"
                  style="max-width: 324px"
                />
              </div>
            </div>
          </div>
        </div>
        <h5 class="ps-3 text-success">{{ "ORDERED_ARTICLES" | translate }}:</h5>
        <div class="row px-3">
          <div class="ps-3 col-md-9" *ngIf="basket && !ordered">
            <app-grouped-articles
              *ngIf="
                groupedArticles &&
                groupedArticles['parts'] &&
                groupedArticles['parts'].items.length
              "
              (contentChanged)="calcTotal(basket.items)"
              [groupedArticles]="groupedArticles['parts']"
              [currency]="basket.total_price?.currency"
              [header]="!basket.pre_order ? 'PARTS' : ''"
              [sum]="basketSum['parts']"
              [compact]="false"
              [basketId]="basket.id"
              [checkout]="true"
              [readonly]="true"
              [preorder]="pre_order"
            ></app-grouped-articles>
            <app-grouped-articles
              *ngIf="
                groupedArticles &&
                groupedArticles['bicycles'] &&
                groupedArticles['bicycles'].items.length
              "
              (contentChanged)="calcTotal(basket.items)"
              [groupedArticles]="groupedArticles['bicycles']"
              [currency]="basket.total_price?.currency"
              [header]="!basket.pre_order ? 'BICYCLES' : ''"
              [sum]="basketSum['bicycles']"
              [compact]="false"
              [basketId]="basket.id"
              [checkout]="true"
              [readonly]="true"
              [preorder]="pre_order"
            ></app-grouped-articles>

            <div
              *ngIf="basketSum['total'] === '0.00' && !basket.items?.length"
              class="mt-3 mb-5"
            >
              <p>{{ "NO_RESULTS" | translate }}</p>
            </div>
          </div>
          <div class="col-3 px-3">
            <h3>&nbsp;</h3>
            <div class="d-block sum totalSum p-3 mt-1">
              <ng-container *ngIf="basketSum['total'] !== '0.00'">
                <div class="h3">
                  {{ "TOTAL" | translate }}
                  <span
                    [innerHTML]="
                      basketSum.totalWithShipping
                        | currencyConvert : basket.total_price?.currency
                    "
                  ></span>
                </div>

                <ng-template #shippingCostTemplate let-item="item" let-title="title">
                  <div class="d-flex flex-column text-end" *ngIf="item?.length && !showroomMode">
                    <b>{{ 'SHIPPINGCOST' | translate }} ({{ title | translate }})</b>
                    <span [innerHTML]="item[0]?.shipping_costs?.value | currencyConvert:item[0]?.shipping_costs?.currency"></span>
                  </div>
                </ng-template>

                <ng-container *ngTemplateOutlet="shippingCostTemplate; context: { item: basket?.selected_shipping_costs?.Parts, title: 'PARTS_ACCESSORIES' }"></ng-container>
                <ng-container *ngTemplateOutlet="shippingCostTemplate; context: { item: basket?.selected_shipping_costs?.Bicycles, title: 'BICYCLES' }"></ng-container>

              </ng-container>
            </div>
          </div>
        </div>
        <div
          class="articleCollection"
          *ngIf="unOrderedArticles != null &&
            (unOrderedArticles['parts'].items.length ||
            unOrderedArticles['bicycles'].items.length)
          "
        >
          <h5 class="ps-3 text-danger">
            {{ "UNORDERED_ARTICLES" | translate }}:
          </h5>
          <div class="ps-3 col-md-9" *ngIf="basket && !ordered">
            <app-grouped-articles
              *ngIf="
                unOrderedArticles &&
                unOrderedArticles['parts'] &&
                unOrderedArticles['parts'].items.length
              "
              [groupedArticles]="unOrderedArticles['parts']"
              [header]="!basket.pre_order ? 'PARTS' : ''"
              [sum]="basketSumUnordered['parts']"
              [compact]="false"
              [basketId]="basket.id"
              [checkout]="true"
              [preorder]="pre_order"
            ></app-grouped-articles>
            <app-grouped-articles
              *ngIf="
                unOrderedArticles &&
                unOrderedArticles['bicycles'] &&
                unOrderedArticles['bicycles'].items.length
              "
              [groupedArticles]="unOrderedArticles['bicycles']"
              [header]="!basket.pre_order ? 'BICYCLES' : ''"
              [sum]="basketSumUnordered['bicycles']"
              [compact]="false"
              [basketId]="basket.id"
              [checkout]="true"
              [preorder]="pre_order"
            ></app-grouped-articles>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<app-bottom-navbar [fixed]="true" *ngIf="basket">
  <ng-container *ngTemplateOutlet="options"></ng-container>
</app-bottom-navbar>
<ng-template #options>
  <nav class="px-0 navbar navbar-light bg-light w-100">
    <div class="container">
      <div class="row w-100 ">
        <div class="px-0 col-md-12 d-flex justify-content-between" style="margin-top: -10px">
          <button class="float-left btn btn-lg btn-dark" (click)="back()">
            {{ "BACK" | translate }}
          </button>
          <button
            class="float-left ms-2 btn btn-lg btn-outline-dark mt-md-0"
            (click)="basketOptionsPopup()"
          >
            {{ "OPTIONS" | translate }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</ng-template>
