import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  AgreementOption,
  AgreementOptionSubGroup,
} from "src/app/interfaces/yearAgreement/agreement-option";
import * as _ from "underscore";
import { YearAgreementService } from "src/app/services/year-agreement.service";
import { Observable, Subscription } from "rxjs";
import { AccountAgreement } from "src/app/interfaces/yearAgreement/account-agreement";

@Component({
  selector: "app-year-agreement-group",
  templateUrl: "./year-agreement-group.component.html",
  styleUrls: ["./year-agreement-group.component.scss"],
})
export class YearAgreementGroupComponent implements OnInit {
  @Input() option: AgreementOption;
  @Input() yearAgreementModel: any;
  @Input() accountAgreement: AccountAgreement;
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() type: string;
  @Input() onlyAffiliateOption: boolean;
  @Input() showCalculation: boolean = true;
  @Input() affiliate: string;
  @Input() isMain: boolean = false;
  dealerTypeSubscription: Subscription;
  percentages = {};
  totalPercentage = 0;
  constructor(private yearAgreementService: YearAgreementService) {}

  ngOnInit() {
    // Update bonus when dealer type changes
    if (this.option.sub_group_type === "Bonus" && this.type === "brands") {
      this.dealerTypeSubscription =
        this.yearAgreementService.changeEmitted$.subscribe(
          (selected: string) => {
            let percentage = 0;
            this.option.sub_group_options.forEach((subgroupOptions: any) => {
              subgroupOptions.options.forEach(
                (subGroup: AgreementOptionSubGroup) => {
                  if (
                    subGroup.option_type === "DealerTypeBonus" &&
                    subGroup.option_value === selected
                  ) {
                    percentage = subGroup.percentage;
                  }
                }
              );
            });

            if (
              typeof this.yearAgreementModel[this.type]["section_percentages"][
                this.option.sub_group_type
              ] === "undefined"
            ) {
              this.yearAgreementModel[this.type]["section_percentages"][
                this.option.sub_group_type
              ] = {};
            }
              this.yearAgreementModel[this.type]["section_percentages"][
                this.option.sub_group_type
              ]["DealerTypeBonus"] = percentage;
            this.calcPercentage();
          }
        );
    }
  }

  ngOnDestroy() {
    if (this.dealerTypeSubscription) {
      this.dealerTypeSubscription.unsubscribe();
    }
  }

  percentageChange(data) {
    if (
      !this.yearAgreementModel[this.type]["section_percentages"][
        this.option.sub_group_type
      ]
    ) {
      this.yearAgreementModel[this.type]["section_percentages"][
        this.option.sub_group_type
      ] = {};
    }

    this.yearAgreementModel[this.type]["section_percentages"][
      this.option.sub_group_type
    ][data["type"]] = data["percentage"];
    this.calcPercentage();
  }

  calcPercentage() {
    this.totalPercentage = _.reduce(
      Object.values(
        this.yearAgreementModel[this.type]["section_percentages"][
          this.option.sub_group_type
        ]
      ),
      function (memo: number, num: number) {
        return memo + num;
      },
      0
    );

    this.yearAgreementModel[this.type]["percentages"][
      this.option.sub_group_type
    ] = this.totalPercentage;

    this.selectionChanged.emit({
      type: this.option.sub_group_type,
      percentage: this.totalPercentage,
    });
  }
}
