

<td style="min-width: 3vw;">
  <app-article-image
    [useLazyLoad]="article.article_details ? false : true" [width]="'50px'"
    [imageUrl]="
      article.article_details ? article.article_details.thumbs[0] : ''
    "
    [articleType]="
      article.article_details ? article.article_details.article_type : 'P'
    "
    [hidden]="!article.article_details"
    [description]="''"
  ></app-article-image>
</td>
<td>
  <b>{{ article.quantity }} &times;</b>
  {{ article.article_code ? article.article_code : "" }}
</td>
<td class="text-wrap clickable" [articleQuickView]="article.article_code" style="min-width: 22.5vw;">
  {{ article.article_description ? article.article_description : ""
  }}<small class="d-block">{{ article.remarks }}</small>
  <small *ngIf="article.battery_kind" class="d-block">{{
    article.battery_kind.toString().toUpperCase() | translate
  }}</small>
</td>
<td >
  <a [articleQuickView]="article.frame_number">{{ article.frame_number }}
  </a>
  <ng-container *ngIf="article.mileage"><br/>{{article.mileage}} Km</ng-container>
</td>
<td>
  <span *ngIf="article.employee_name">{{ article.employee_name }}</span>
</td>
<td>
  {{ descriptions[article.reason_type] | translate }}<br />
  <small *ngIf="article.flow_type && article.flow_type !== 'regular'">{{
    article.flow_type.toUpperCase() | translate
  }}</small>
</td>
<td class="actions text-end" *ngIf="showControls">
  <a
    (click)="editArticle()"
    class="btn btn-sm btn-dark"
    >{{'EDIT' | translate}}</a> |
  <a class="text-danger"
    (click)="deleteArticle()"
    >{{'DELETE' | translate}}</a>
</td>
