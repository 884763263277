<div class="container h-100 d-flex align-items-center">

  <div class="row mt-4 d-flex px-3 pb-3">
    <ng-content></ng-content>

    <div class="col-md-12" >
      <app-search-bar style="width: 80%;"></app-search-bar>
    </div>
    <div class="col-md-12 mt-3 align-self-end">
      <app-widgets [autoload]="true" class="d-block w-100"></app-widgets>
    </div>
  </div>
</div>
