<ul
  class="px-2 pt-0 border-0 nav nav-tabs justify-content-center justify-content-lg-start d-none d-lg-flex"
>
  <li class="nav-item">
    <a
      class="pb-2 nav-link"
      href="#"
      (click)="setTab($event, 'search')"
      [ngClass]="activeTab === 'search' ? 'active border-dark' : ''"
      ><i class="material-icons">pedal_bike</i>
      {{ "CATALOG" | translate }}</a
    >
  </li>
  <li class="nav-item">
    <a
      class="pb-2 nav-link"
      href="#"
      (click)="setTab($event, 'imagebank')"
      [ngClass]="activeTab === 'imagebank' ? 'active border-dark' : ''"
      ><i class="material-icons">image</i>
      {{ "IMAGEBANK" | translate }}</a
    >
  </li>
  <li class="nav-item">
    <a
      class="pb-2 nav-link"
      href="#"
      (click)="setTab($event, 'favorites')"
      [ngClass]="activeTab === 'favorites' ? 'active border-dark' : ''"
      ><i class="material-icons">favorite</i>
      {{ "FAVORITES" | translate }}</a
    >
  </li>
  <li class="nav-item" *ngIf="preorderEnabled">
    <a
      class="pb-2 nav-link"
      href="#"
      (click)="setTab($event, 'preorder')"
      [ngClass]="activeTab === 'preorder' ? 'active border-dark' : ''"
      ><i class="material-icons">bookmark_manager</i>
      {{ "PREORDER" | translate }}</a
    >
  </li>
  <li
    class="nav-item"
    *ngIf="applicationService.getSelectCompanyGroupCode() === 'ANL' && showXl"
  >
    <a
      class="pb-2 nav-link"
      href="#"
      (click)="setTab($event, 'replenishment')"
      [ngClass]="activeTab === 'replenishment' ? 'active border-dark' : ''"
      ><i class="material-icons">refresh</i>
      {{ "ACCENTRY_REPLENISHMENT" | translate }}</a
    >
  </li>
</ul>
<div class="px-2 col-12">
  <select
    class="my-2 form-control d-block d-lg-none"
    [(ngModel)]="selectedTabMobile"
    (change)="setTabMobile()"
  >
    <option [value]="'search'">{{ "CATALOG" | translate }}</option>
    <option [value]="'imagebank'">{{ "IMAGEBANK" | translate }}</option>
    <option [value]="'favorites'">{{ "FAVORITES" | translate }}</option>
    <option *ngIf="preorderEnabled" [value]="'preorder'">
      {{ "PREORDER" | translate }}
    </option>
    <option
      *ngIf="applicationService.getSelectCompanyGroupCode() === 'ANL'"
      [value]="'replenishment'"
    >
      {{ "ACCENTRY_REPLENISHMENT" | translate }}
    </option>
  </select>
</div>
