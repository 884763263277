<div class="mb-3 row mt-2">
  <label class="col-sm-4 col-form-label">{{
    "DELIVERY_WEEK" | translate
  }}</label>
  <div class="col-sm-8">
    <select
      class="form-control"
      (change)="selectWeek($event)"
      [(ngModel)]="selectedWeek"
    >
      <ng-container *ngFor="let weeks of monthList; let m = index">
        <optgroup
          [label]="getMonthDescription(weeks[0])"
          *ngIf="weeks.length && weeks[1].length"
        >
          <ng-container
            *ngFor="let week of weeks[1]; let i = index; trackBy: weekValue"
          >
            <option [value]="week.value">{{ week.text }}</option>
          </ng-container>
        </optgroup>
      </ng-container>
    </select>
  </div>
</div>
