import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BrandSelectorLogo } from 'src/app/interfaces/brand-selector-logo';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-brand-promo-widget',
  templateUrl: './brand-promo-widget.component.html',
  styleUrls: ['./brand-promo-widget.component.scss'],
  animations: [
    trigger(
      'scale',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, transform: "scale(0)" }),
            animate('300ms 200ms ease-out',
                    style({   opacity: 1, transform:  "scale(1)" }))
          ]
        ),
        transition(
          ':leave',
          [
            style({  opacity: 1, "z-index": 0 }),
            animate('200ms ease-in',
                    style({opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class BrandPromoWidgetComponent implements OnInit {
  @Input() filters: any;
  brand: BrandSelectorLogo;

  constructor(private applicationService: ApplicationService) {

  }

  ngOnInit() {
    this.getBrand();
  }

  ngOnChanges(changes: SimpleChanges) {
      this.getBrand()
  }

   getBrand() {
    const queryString = window.location.href;
    const urlParams = new URLSearchParams(queryString);
    const urlBrand = urlParams.get("brands");
    const articleType = urlParams.get("article_type") ?? "";
    if (urlBrand) {
      const brands = this.applicationService.getSelectedCompanyGroupBrands(this.applicationService.getSelectCompanyGroupCode());
      const brand = brands.find((b) => {
        return b.name.toLowerCase() === urlBrand.toLowerCase() && (articleType === "" || b.type === articleType.toLowerCase())
      });

      if (brand) {
        this.brand = brand;
      } else {
        this.brand = undefined;
      }
    } else {
      this.brand = undefined;
    }
  }
}
