export interface UpdateStockInput {
  company_group_code: string;
  owner_code: string;
  article_code: string;
  stock: number;
  min_stock: number;
  max_stock: number;
  battery_article_code: string;
  article_description: string;
  model_code: string;
  stock_status: number;
}

export class UpdateStockInput implements UpdateStockInput {
  constructor(
    company_group_code: string,
    owner_code: string,
    article_code: string,
    min_stock: number,
    max_stock: number,
    battery_article_code: string,
  ) {
    this.company_group_code = company_group_code;
    this.owner_code = owner_code;
    this.article_code = article_code;
    this.min_stock = min_stock;
    this.max_stock = max_stock;
    this.battery_article_code = battery_article_code;
  }
}
