import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Article } from 'src/app/interfaces/article';
import { ThreeDeeViewerComponent } from '../three-dee-viewer.component';

@Component({
  selector: 'app-three-dee-viewer-modal',
  templateUrl: './three-dee-viewer-modal.component.html',
  styleUrls: ['./three-dee-viewer-modal.component.scss']
})
export class ThreeDeeViewerModalComponent implements OnInit {
  src: string = "";
  article: Article;
  @ViewChild("threedeeviewer") threeDeeViewerComponent: ThreeDeeViewerComponent;
  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.threeDeeViewerComponent.showIframe();
  }

}
