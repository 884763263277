<div class="form-container p-5" [ngClass]="active ? 'active' : ''">
  <div class="w-100 text-center">
    <img src="/assets/images/Accentry-logo.svg" style="width: 300px">
    <h1>{{"PLEASE_REGISTER"|translate}}</h1>
  </div>
  <form class="form-signin" id="form-register" ngNativeValidate *ngIf="!success">
    <div class="form-signin-heading mb-4">
      <p>{{'REGISTER_INFO'|translate}}</p>
    </div>

    <div class="mb-3 mt-3" [formGroup]="formGroup">
      <label for="firstname">{{'FIRSTNAME'|translate}}</label>
      <input type="text" class="form-control" name="firstname" id="firstname" formControlName="firstname" required>
    </div>

    <div class="mb-3 mb-4" [formGroup]="formGroup">
      <label for="firstname">{{'LASTNAME'|translate}}</label>
      <input type="text" class="form-control" name="lastname" id="lastname" formControlName="lastname" required>
    </div>

    <div class="mb-3" [formGroup]="formGroup">
      <label for="username">{{'EMAIL'|translate}}</label>
      <small class="small d-block">* {{'IS_USERNAME'|translate}}</small>
      <input type="email" class="form-control" name="username" autofocus="" id="username" formControlName="username"
        required>
    </div>
    <div class="mb-3 mb-2" [formGroup]="formGroup">
      <label for="username" class="mt-1">{{'CONFIRM_EMAIL'|translate}}</label>
      <input type="email" class="form-control" name="usernameconfirm" id="usernameconfirm" [ngClass]="userNameDiffers ? 'border-danger' : ''"
        formControlName="usernameconfirm" required>
    </div>

    <div [formGroup]="formGroup">
      <input type="submit" [attr.disabled]="loading ? 'disabled' : undefined" class="btn btn-lg btn-block btn-success" [value]="'SEND'|translate" (click)="submit()">
    </div>

    <a [routerLink]="'/login'" class="btn text-primary forgot btn-link ps-0 mt-3">
      {{ 'BACK_TO_LOGIN' | translate}}</a>
  </form>
  <app-success-check-icon *ngIf="success">
    <b class="mt-2">{{"ACTIVATE_TITLE"|translate}}</b>
    <p>{{successText}}</p>
    <u><a [routerLink]="'/login'" class="d-block btn text-primary forgot btn-link ps-0 mt-3">
      {{ 'BACK_TO_LOGIN' | translate}}</a></u>
  </app-success-check-icon>
</div>
