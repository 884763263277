import { Component, OnInit, Input } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { ApplicationService } from "src/app/services/application.service";
import { NumberFormatPipe } from "src/app/pipes/number-format.pipe";

@Component({
  selector: "app-highlight-badge",
  templateUrl: "./highlight-badge.component.html",
  styleUrls: ["./highlight-badge.component.scss"],
  providers: [NumberFormatPipe]
})
export class HighlightBadgeComponent implements OnInit {
  @Input() article: Article;
  showroomMode: boolean;

  constructor(private applicationService: ApplicationService, public numberFormat: NumberFormatPipe) {
    this.getShowroomMode();
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  ngOnInit() {
    this.applicationService.changeEmitted$.subscribe(response => {
      this.getShowroomMode();
    });
  }
}
