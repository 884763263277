import { Component, OnInit, Input } from "@angular/core";
import { WarrantyService } from "src/app/services/warranty.service";
import { Article } from "src/app/interfaces/article";

@Component({
  selector: "app-select-for-warranty-button",
  templateUrl: "./select-for-warranty-button.component.html",
  styleUrls: ["./select-for-warranty-button.component.scss"]
})
export class SelectForWarrantyButtonComponent implements OnInit {
  @Input() article: Article;
  @Input() bomQuantity: number;
  constructor(private warrantyService: WarrantyService) {}

  ngOnInit() {}

  select() {
    this.article.quantity = this.bomQuantity;
    this.warrantyService.partSelected(this.article);
  }
}
