import { Component, OnInit } from '@angular/core';
import { FinancialService } from 'src/app/services/financial.service';
import { AccountService } from 'src/app/services/account.service';
import { ApplicationService } from 'src/app/services/application.service';
import { CompanyGroup } from 'src/app/interfaces/company-group';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-discount-sheet-page',
  templateUrl: './discount-sheet-page.component.html',
  styleUrls: ['./discount-sheet-page.component.scss']
})
export class DiscountSheetPageComponent implements OnInit {
  accountType: string;
  companyGroupCode: string;
  companySettings: CompanyGroup;
  companies: any = [];

  constructor(
    private financialService: FinancialService,
    private accountService: AccountService,
    private applicationService: ApplicationService,
    public router: Router
  ) {

  }

  ngOnInit() {
    this.accountType = this.accountService.getAccountType() ?? ""
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode() as string;
    this.companySettings = this.applicationService.getSettingsForCompanyGroup(this.companyGroupCode) as CompanyGroup
    for (let companyID of this.companySettings.companies) {
      if (companyID === 101) {
        this.getFileUrl('WS')
      } else if (companyID === 102) {
        this.getFileUrl('WBP')
      }
    }
  }

  downloadPdf(companyID: string) {
    this.financialService.getDiscountSheetPdfFile(companyID).subscribe((blob: Blob) => {
      const file = new Blob([blob], { type: "application/pdf" });
      saveAs(file, "discountsheet.pdf");
    });
  }

  getFileUrl(companyID: string) {
    this.applicationService.showLoader(true);
    this.financialService.getDiscountSheetPdfFile(companyID).subscribe((blob: Blob) => {
      const file = new Blob([blob], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const company = {
        companyID: companyID,
        fileURL: fileURL
      }
      this.companies.push(company)
      this.applicationService.showLoader(false);
    });
  }
}
