<div class="row">
  <div class="col-md-12 card border-0">
    <div class="card-body">

      <h5>{{(overviewType === 'distribution' ? 'PURCHASE_DISTRIBUTION' : 'PLANNING')|translate}}</h5>
      <div class="row mx-0">
        <ng-container *ngFor="let type of (overviewType === 'planning' ? ['brands'] : ['brands', 'specials'])">
          <div class="col-md-12 px-0 mt-4" *ngIf="yearAgreementModel[type]['brands'].length && ((overviewType === 'planning' && backorderData && backorderData.length) || overviewType === 'distribution')">
            <h5>{{sections[type].toUpperCase()|translate}} - {{turnOverFields[type].toUpperCase()|translate}}
              <ng-container *ngIf="!yearAgreementModel.cmDealer || type !== 'brands'; else DummyTurnover ">
                  ({{yearAgreementModel[type]['manualTurnover']?yearAgreementModel[type]['manualTurnover']:yearAgreementModel[type]['selected'][turnOverFields[type]]}}) <button *ngIf="yearAgreementModel[type]['selected'][turnOverFields[type]] === '500000'" class="btn btn-sm btn-dark" (click)="editSum(type)">{{'EDIT'|translate}}</button></ng-container>
                <ng-container *ngIf="overviewType === 'planning'" > - {{planningPercentage}}%</ng-container>
                  <ng-template #DummyTurnover>({{yearAgreementModel.dummyTurnover}})</ng-template>
            </h5>
            <app-purchase-distribution-table class="d-inline-block w-50" [tab]="tab" [type]="type" *ngIf="overviewType === 'distribution'" [planningPercentage]="planningPercentage"
              [yearAgreementModel]="yearAgreementModel" [readonly]="true" [year]="yearAgreementModel.year-2">
            </app-purchase-distribution-table>
            <app-purchase-distribution-table class="d-inline-block w-50" [tab]="tab" [type]="type" *ngIf="overviewType === 'distribution'" [overviewType]="overviewType" [planningPercentage]="planningPercentage"
              [yearAgreementModel]="yearAgreementModel" [readonly]="true" [year]="yearAgreementModel.year-1">
              * <small><i>{{'TODAY_MINUS_YEAR'|translate}}</i></small>
            </app-purchase-distribution-table>
            <app-purchase-distribution-table class="d-inline-block w-100" [tab]="tab" [type]="type" [overviewType]="overviewType" [backorderData]="backorderData" [planningPercentage]="planningPercentage" [totalOfType]="!yearAgreementModel.cmDealer || type !== 'brands' ? yearAgreementModel[type]['selected'][turnOverFields[type]] : yearAgreementModel.dummyTurnover"
              [yearAgreementModel]="yearAgreementModel" [readonly]="false" [year]="yearAgreementModel.year"
              (selectionChanged)="calcTotal()">
            </app-purchase-distribution-table>
          </div>
        </ng-container>
        <div class="col-md-12 pe-0 mt-2 text-end" *ngIf="overviewType === 'distribution'">
          <h3 class="d-inline-block" [ngClass]="" style="border-top: double 4px #000" *ngIf="overviewType === 'distribution'">{{'TOTAL'|translate}}
            {{totalSum | numberFormat: '&euro;'}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>
