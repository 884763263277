<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'DESCRIPTION' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close(modal)">
    </button>
</div>
<div class="modal-body p-0">
  <div>
    <div class="p-3">
      <div class="form-row">
        <div class="mb-3 col-md-12">
          <label>{{'NAME' | translate }}</label>
          <input type="text" class="form-control" [(ngModel)]="basket.name" maxlength="50">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <span class="col-md-12 p-0">
    <button class="btn btn-success btn-lg " (click)="save()" [disabled]="loading">{{
      'SAVE'
      | translate}} <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
  </span>
</div>
