import { Component, OnInit, Input } from "@angular/core";
import { SearchService } from "src/app/services/search.service";
import { Article } from "src/app/interfaces/article";
import { ApiResponse } from "src/app/interfaces/api-response";

@Component({
  selector: "app-replacement-article",
  templateUrl: "./replacement-article.component.html",
  styleUrls: ["./replacement-article.component.scss"]
})
export class ReplacementArticleComponent implements OnInit {
  @Input() articleCode = "";
  @Input() article: any = {};
  constructor(private searchSerice: SearchService) {}

  ngOnInit() {
    this.getArticle();
  }

  getArticle() {
    this.searchSerice
      .articleDetails(null, this.articleCode)
      .subscribe((response: ApiResponse) => {
        if (response) {
          this.article = response.result;
        }
      });
  }
}
