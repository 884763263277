import { Component, OnInit } from "@angular/core";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import { PricelabelService } from "src/app/services/pricelabel.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { PricelabelFormat, PricelabelPage } from "src/app/interfaces/pricelabels/pricelabel-page";
import { PricelabelSettings } from "src/app/interfaces/pricelabels/pricelabel-settings";
import { PricelabelLogo } from "src/app/interfaces/pricelabels/pricelabel-logo";
import * as _ from "underscore";
import { Pricelabel } from "src/app/interfaces/pricelabels/pricelabel";
import { ArticleFinderModalComponent } from "src/app/components/search-components/article-finder-modal/article-finder-modal.component";
import { Article } from "src/app/interfaces/article";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HelperService } from "src/app/services/helper.service";
import { BarCodeScannerComponent } from "src/app/components/general-components/bar-code-scanner/bar-code-scanner.component";
import { saveAs } from 'file-saver';
import { BarcodeScanResult } from "src/app/interfaces/barcode-scan-result";
import { AlertService } from "src/app/services/alert.service";
import { environment } from "src/environments/environment";
@Component({
  selector: 'app-pricelabel-page',
  templateUrl: './pricelabel-page.component.html',
  styleUrls: ['./pricelabel-page.component.scss'],
})
export class PricelabelPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  pricelabelPage: PricelabelPage;
  pricelabels: Pricelabel[];
  settings: PricelabelSettings = new PricelabelSettings();
  logos: PricelabelLogo[];
  logosrc: string;
  formats: PricelabelFormat[];
  constructor(
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private pricelabelService: PricelabelService,
    private modalService: NgbModal,
    private helperService: HelperService,
    private alertService: AlertService
  ) {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant('HOME'),
        this.applicationService.getSelectCompanyGroupCode() + '/home'
      ),
      new Breadcrumb(this.translateService.instant('SERVICE'), '#'),
      new Breadcrumb(this.translateService.instant('PRICELABELS'), '#'),
    ];
  }

  ngOnInit() {
    this.getLabelsettings();
  }

  setLogo() {
    const isV2 = true;
    const selectedLogo = _.findWhere(this.logos, {
      logo_id: Number(this.settings.logo_accentry),
    }) as PricelabelLogo;
    const el = document.getElementById('logoSelect');
    if (Number(this.settings.logo_accentry) === 0) {
      this.logosrc = isV2 ? this.settings.logo_custom_url : this.settings.logo_custom_filename;
      this.settings.logo_own = true;
      if (el) {
        el.style.display = 'inline';
      }
    } else if (selectedLogo) {
      if (isV2) {
        this.logosrc = `${environment.blobStorageUrl}pricelabels/defaultlabels/${selectedLogo.logo_filename}`;
      } else {
        this.logosrc =
        "https://www.accentry.com/Images/Static/Logos/" +
        selectedLogo.logo_filename.replace("png","gif")
      }
      this.settings.logo_own = false;
      if (el) {
        el.style.display = 'none';
      }
    }
  }

  uploadLogo(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.pricelabelService
          .uploadLogo(String(reader.result))
          .subscribe((apiResponse: ApiResponse) => {
            if (this.helperService.checkResponse(apiResponse)) {
              this.logosrc = apiResponse.result;
            }
          });
      };
    }
  }

  printLabels() {
    this.updateSettings().then((apiResponse: ApiResponse) => {
      this.pricelabelService.printPricelabels().subscribe((blob: Blob) => {
        const file = new Blob([blob], { type: 'application/pdf' });
        saveAs(file, 'labels.pdf');
      });
    });
  }

  updateSettings() {
    const promise = new Promise((resolve) => {
      this.pricelabelService
        .updateSettings(this.settings)
        .subscribe((apiResponse: ApiResponse) => {
          resolve(apiResponse);
        });
    });
    return promise;
  }

  getLabelsettings() {
    this.pricelabelService
      .getPricelabels()
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          const page = apiResponse.result;
          this.pricelabelPage = page;
          this.logos = this.pricelabelPage.logos;
          this.formats = this.pricelabelPage.formats;
          this.loadSettings();
        }
      });
  }

  loadSettings() {
    this.settings = this.pricelabelPage.settings;
    this.pricelabels = this.pricelabelPage.pricelabels;
    this.pricelabels.forEach((pricelabel) => {
      if (
        Number(pricelabel.pricelabel_price) === 0 &&
        pricelabel.article_details &&
        pricelabel.article_details.retail_price
      ) {
        pricelabel.pricelabel_price = String(
          pricelabel.article_details.retail_price.value
        );
      }
    });
    this.setLogo();
  }

  updateLabel(pricelabel: Pricelabel) {
    this.pricelabelService
      .updatePricelabel(pricelabel)
      .subscribe((apiResponse: ApiResponse) => {});
  }

  updateDescription(description: string, pricelabel: Pricelabel) {
    pricelabel.pricelabel_description = description;
    this.updateLabel(pricelabel);
  }

  deleteLabel(articleCode: string) {
    if (confirm(this.translateService.instant('DELETE_ARTICLE'))) {
      this.pricelabelService
        .deletePricelabel(articleCode)
        .subscribe((apiResponse: ApiResponse) => {
          this.pricelabelService.removeLabel(this.pricelabels, articleCode);
        });
    }
  }

  deleteAllLabels() {
    if (confirm(this.translateService.instant('DELETE_ARTICLE'))) {
      this.pricelabelService
        .deleteAllPricelabel()
        .subscribe((apiResponse: ApiResponse) => {
          this.getLabelsettings();
        });
    }
  }

  addArticle() {
    const modalRef = this.modalService.open(ArticleFinderModalComponent, {
      centered: true,
      container: '#modalContainer',
      backdrop: 'static',
    });
    modalRef.componentInstance.articleSelected$.subscribe(
      (articleCode: string) => {
        if (articleCode) {
          this.pricelabelService
            .addArticles([articleCode])
            .subscribe((apiResponse: ApiResponse) => {
              if (this.helperService.checkResponse(apiResponse)) {
                this.getLabelsettings();
              }
            });
        }
      }
    );
  }

  scanBarcode() {
    const modalRef = this.modalService.open(BarCodeScannerComponent, {
      centered: true,
      container: '#modalContainer',
      backdrop: 'static',
    });
    modalRef.componentInstance.articleSelected$.subscribe(
      (scanData: BarcodeScanResult) => {
        if (scanData.article_code) {
          this.pricelabelService
            .addArticles([scanData.article_code])
            .subscribe((apiResponse: ApiResponse) => {
              if (this.helperService.checkResponse(apiResponse)) {
                this.getLabelsettings();
                this.alertService.showInfoNotification(
                  this.translateService.instant(apiResponse.result)
                );
              }
            });
        }
      }
    );
  }
}
