export interface LotteryRegistration {
  frame_number: string;
  order_number: string;
}

export class LotteryRegistration implements LotteryRegistration {
  constructor(frame_number: string, order_number: string) {
    this.frame_number = frame_number;
    this.order_number = order_number;
  }
}
