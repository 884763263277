import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FinancialPaginatorData } from "src/app/interfaces/financial/financial-paginator-data";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-financial-paginator",
  templateUrl: "./financial-paginator.component.html",
  styleUrls: ["./financial-paginator.component.scss"]
})
export class FinancialPaginatorComponent implements OnInit {
  @Input() pageData: FinancialPaginatorData;
  @Output() navigation: EventEmitter<string> = new EventEmitter<string>();

  constructor(public applicationService: ApplicationService) {}

  ngOnInit() {}

  previous() {
    this.pageData.loading = true;
    this.pageData.page--;
    this.navigation.emit(this.pageData.acePreviousPage);
  }

  next(pageId: string) {
    this.pageData.loading = true;
    this.pageData.page++;
    this.navigation.emit(this.pageData.aceNextPage);
  }
}
