import { Component, OnInit, Input } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';

@Component({
  selector: 'app-spec-list',
  templateUrl: './spec-list.component.html',
  styleUrls: ['./spec-list.component.scss'],
})
export class SpecListComponent implements OnInit {
  @Input() articleCode: string;
  @Input() specs: any;
  specCount: any = {};
  numOfArticles = 0;

  constructor(public articleService: ArticleService) {}

  ngOnInit() {}

  highlightSpec(specCode: string) {
    this.articleService.selectedArticleSpecCode = specCode;
  }


}
