
  import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SearchService } from 'src/app/services/search.service';
import { ApplicationService } from 'src/app/services/application.service';
import { Location } from '@angular/common';
import { DefaultFilters } from 'src/app/interfaces/default-filters';
import { Subject, Subscription } from 'rxjs';
import { ArticleGroup } from 'src/app/interfaces/articleGroup';
import { FilterGroup } from 'src/app/interfaces/filterGroup';
import { FilterOption } from 'src/app/interfaces/filter-option';
import * as appGlobal from 'src/app/globals';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { CompanyPromotion } from 'src/app/interfaces/company-promotion';
import { PriceRangeFilter } from 'src/app/interfaces/price-range-filter';
import { TrackingService } from 'src/app/services/tracking.service';
import { HelperService } from 'src/app/services/helper.service';
import { SideBarFilterOption } from 'src/app/interfaces/side-bar-filter-option';
import { filter } from 'underscore';
import * as _ from 'underscore';
import { StockweekFilter } from 'src/app/interfaces/stockweek-filter';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../../modal-components/confirm-dialog/confirm-dialog.component';
import { SaveFiltersComponent } from './save-filters/save-filters.component';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/services/account.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { SearchFilter } from "src/app/interfaces/search-filter";
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  @Input() priceRangeData: PriceRangeFilter;
  @Input() stockweekData: StockweekFilter;
  @Input() availableFilters: [FilterGroup];
  @Input() showStockFilter: boolean = true;
  @Input() collapseAll: boolean = true;
  @Input() numResults = 0;
  @Input() showControls = true;
  @Output() filtersUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() groupsUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() stockweeksUpdated: EventEmitter<StockweekFilter> =
    new EventEmitter<StockweekFilter>();
  @Output()
  filterWithKeyword: EventEmitter<string> = new EventEmitter<string>();
  @Input() groups: [ArticleGroup];
  @Input() sidebarTop: number;
  @Input() selectedFilterList = [];
  @Input() ts: number;
  @Input() globalFilterKeyword: string = null;
  @ViewChild("searchFilter")
  searchFilter: SearchFilterComponent;

  hasBom = false;
  onlyAvailable: boolean;
  onlyNew: boolean;
  filtersSelected: boolean;
  filtersChanged: boolean;
  searchText = '';
  promotions: CompanyPromotion[][];
  selectedFilters: DefaultFilters = {
    COMMON: {
      CMN_AVAIL: false,
      CMN_IN_STOCK: false,
      CMN_NEW: false,
      CMN_PROM: false,
      CMN_OLD: false,
      CMN_BOM: false,
    },
  };
  defaultFilters: DefaultFilters = {
    COMMON: {
      CMN_AVAIL: false,
      CMN_IN_STOCK: false,
      CMN_NEW: false,
      CMN_PROM: false,
      CMN_OLD: false,
      CMN_BOM: false,
    },
  };
  testOptions: any = {};
  minValue = 0;
  maxValue = 9999;
  options: Options = {
    floor: 0,
    ceil: 9999,
    step: 5,
    animate: false,
    hideLimitLabels: true,
  };
  sideBarActive = true;
  commonFilterSet: boolean;
  lastLocation: string;
  filterKeywords: object = {};
  showGeneralFilters = !this.applicationService.isPartnerPortal();
  initSideBarWidth = 0;
  deliveryWeekCollapsed = true;
  priceCollapsed = true;
  priceInit = false;
  deliveryWeekInit = false;
  isMobile: boolean;
  filterModalOpen: any;
  events: Subscription[] = [];
  modalFilter: any;
  showModal = false;
  filterDialogCollapsed = false;
  expandedFilters: string[] = [];

  get promotionCode() {
    const promoCode = this.selectedFilters['HOMEPROMO']
      ? Object.keys(this.selectedFilters['HOMEPROMO'])[0]
      : null;
    const companyId = this.selectedFilters['COMPANY']
      ? Object.keys(this.selectedFilters['COMPANY'])[0]
      : null;
    return promoCode && companyId ? `${promoCode}_${companyId}` : '';
  }

  set promotionCode(value) {}

  get sliderOptions() {
    const options: Options = {
      floor: 0,
      ceil: 65,
      step: 1,
      animate: false,
    };
    return options;
  }

  constructor(
    private searchService: SearchService,
    public applicationService: ApplicationService,
    private trackingService: TrackingService,
    private helperService: HelperService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private accountService: AccountService,
    private deviceDetector: DeviceDetectorService
  ) {}

  ngOnInit() {
    if (this.deviceDetector.isMobile()) {
      this.sideBarActive = false;
      this.isMobile = true;
    }

    this.hasBom =
      appGlobal.companyGroupSettings &&
      appGlobal.companyGroupSettings &&
      appGlobal.companyGroupSettings.bom_companies?.length > 0;

    this.searchService.changeEmitted$.subscribe((text) => {
      if (
        text !== this.searchText ||
        this.lastLocation !== window.location.href
      ) {
        this.removeFilters(false);
        this.searchText = text;
        this.lastLocation = window.location.href;
      }
    });
    const sidebarWrapper = document.getElementById('sidebar-wrapper');
    this.initSideBarWidth = sidebarWrapper.getBoundingClientRect().width;
  }

  ngAfterViewInit() {
    this.setSideBarWidth();
    window.addEventListener('resize', this.setSideBarWidth);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.setSideBarWidth);
  }

  setSideBarWidth() {
    setTimeout(() => {
      // Set sidebar width based on it's parent
      const sidebar = document.getElementById('side-bar-container');
      const sidebarWrapper = document.getElementById('sidebar-wrapper');
      if (sidebar && sidebarWrapper) {
        const sidebarWrapperWidth =
          sidebarWrapper.getBoundingClientRect().width;
        sidebar.style.width = `${
          sidebarWrapperWidth > 0 ? sidebarWrapperWidth : this.initSideBarWidth
        }px`;
      }
    }, 0);
  }

  groupSelected(data) {
    this.groupsUpdated.emit(data);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    if (this.availableFilters || this.priceRangeData) {
      this.setFilterSelection();
    }

    this.filtersChanged = true;
    this.setSideBarWidth();

    // Dialog selected filters
    if (changes['ts']) {
      this.selectedFilterList.forEach((f: any) => {
        if (typeof this.selectedFilters[f['group']] === 'undefined') {
          this.selectedFilters[f['group']] = {};
        }
        this.selectedFilters[f['group']][f['id']] = 'true';
      });
    }
  }

  resetFilters() {
    this.selectedFilters = this.defaultFilters;
  }

  encodeFilter(filterId: string) {
    let filterEncoded = filterId.replace(/\"/g, 'inch');
    return filterEncoded;
  }

  setFilterSelection() {
    let lowest = 0;
    let highest = 9999;
    if (this.availableFilters) {
      for (let i = 0; i < this.availableFilters.length; i++) {
        const filter = this.availableFilters[i];
        if (filter['id'] !== 'Price') {
          if (!this.selectedFilters[filter['specification_code']]) {
            this.selectedFilters[filter['specification_code']] = [];
          }

          if (filter.options?.length === 1) {
            const onlyOption = filter['options'][0];
            if (!this.selectedFilters[onlyOption['group']]) {
              this.selectedFilters[onlyOption['group']] = {};
            }
            this.selectedFilters[onlyOption['group']][onlyOption['id']] = true;
          }
        }
      }
      if (this.priceRangeData) {
        this.minValue =
          this.priceRangeData.selected_min_price?.value ??
          this.priceRangeData.min_price.value;
        this.maxValue =
          this.priceRangeData.selected_max_price?.value ??
          this.priceRangeData.max_price.value;
        lowest = this.priceRangeData.min_price.value;
        highest = this.priceRangeData.max_price.value;
      }
    }

    if (lowest > this.minValue) {
      this.minValue = lowest;
    }

    if (highest < this.maxValue) {
      this.maxValue = highest;
    }

    this.setPriceOptions(lowest, highest);
  }

  // Render a list of selected filters
  renderSelectedFilters(availableFilters) {
    if (availableFilters && availableFilters.length) {
      this.selectedFilterList = [];

      availableFilters.forEach((filterGroup) => {
        if (filterGroup.options?.length) {
          const selectedFilterList = filterGroup.options.filter(
            (option: FilterOption) => {
              return option.value === 'checked';
            }
          );

          if (this.expandedFilters.includes(filterGroup.name)) {
            filterGroup.expanded = true;
            filterGroup.collapse = false;
          }

          selectedFilterList.forEach((filter) => {
            filter.group = filterGroup?.specification_code;
            filter.name = filterGroup.name;
          });

          this.selectedFilterList =
            this.selectedFilterList.concat(selectedFilterList);
        }
      });
    }
    let filterCount = {};
    this.selectedFilterList.forEach((f: FilterOption) => {
      if (typeof filterCount[f.group] === 'undefined') {
        filterCount[f.group] = 0;
      }
      filterCount[f.group]++;
    });
    let selectedFilterList = this.selectedFilterList;
    for (const [key, value] of Object.entries(filterCount)) {
      if (Number(value) >= 5) {
        selectedFilterList = selectedFilterList.filter((s: FilterOption) => {
          return s.group !== key;
        });

        const filter = _.findWhere(this.selectedFilterList, {
          group: key,
        }) as FilterOption;

        selectedFilterList.push({
          id: key,
          description: '',
          name: filter?.name,
          count: value,
          long_list: true,
        });
      }
    }

    if (this.searchService.brands.length) {
      selectedFilterList.push({
        id: 'brands',
        description: `${this.searchService.brands.join(',')}` +
          (typeof this.searchService.articleType !== 'undefined'
            ? `(${this.translateService.instant(this.searchService.articleType.toUpperCase())})`
            : ''),
        name: this.translateService.instant('BRAND'),
        count: 0,
        long_list: false,
      });
    }

    this.selectedFilterList = selectedFilterList;
  }

  removeFilter(filter, index) {
    this.selectedFilterList.splice(index, 1);

    if (filter.id !== 'type' && filter.id !== 'brands') {
      this.filterKeywords[filter.group] = '';
      delete this.selectedFilters[filter.group][decodeURI(filter.id)];
    }

    if (filter.id === 'type') {
      this.searchService.articleType = '';
      this.searchService.brands = [];
    }

    if (filter.id === 'brands') {
      this.searchService.brands = [];
      this.searchService.articleType = '';
    }

    this.selectFilter();
  }

  toggleFilter(filterGroup: FilterGroup) {
    let filters = [...this.expandedFilters];
    filterGroup.expanded = !filterGroup.expanded;
    filterGroup.collapse = !filterGroup.collapse;
    if (filterGroup.expanded) {
      filters.push(filterGroup.name);
      var unique = _.uniq(filters, function (x) {
        return x;
      });
      filters = unique;
    } else {
      filters = _.without(filters, filterGroup.name)
    }
    this.expandedFilters = filters;
  }

  setPriceOptions(floor, ceil) {
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.floor = floor;
    newOptions.ceil = ceil;
    this.options = newOptions;
  }

  updateFilters(priceRangeData) {
    this.minValue = priceRangeData['min_price'].value;
    this.maxValue = priceRangeData['max_price'].value;
  }

  selectFilter() {
    this.filtersSelected = true;
    this.filtersChanged = true;
    this.filtersUpdated.emit({
      filters: this.selectedFilters,
      updateResults: true,
    });
  }

  setPromotionCode(target) {
    const value = target.srcElement.value;
    const valueData = value.split('_');
    const promoCode = valueData[0];
    const company = valueData[1];
    this.selectedFilters['HOMEPROMO'] = {};
    this.selectedFilters['COMPANY'] = {};
    this.selectedFilters['HOMEPROMO'][promoCode] = promoCode;
    this.selectedFilters['COMPANY'][company] = company;
    this.selectFilter();
  }

  getSwitch(event, name) {
    if (!this.selectedFilters.COMMON) {
      this.selectedFilters.COMMON = {
        CMN_AVAIL: false,
        CMN_IN_STOCK: false,
        CMN_NEW: false,
        CMN_PROM: false,
        CMN_OLD: false,
        CMN_BOM: false,
      };
    }

    if (event) {
      this.selectedFilters.COMMON[name] = event;
    } else {
      this.selectedFilters.COMMON[name] = false;
    }
    this.selectFilter();
  }

  getCommonFilter(data) {
    if (!this.selectedFilters.COMMON) {
      this.selectedFilters.COMMON = {
        CMN_AVAIL: false,
        CMN_IN_STOCK: false,
        CMN_NEW: false,
        CMN_PROM: false,
        CMN_OLD: false,
        CMN_BOM: false,
      };
    }

    this.selectedFilters.COMMON[data['name']] = data['value'];

    if (data['value']) {
      this.filtersSelected = true;
    }

    if (!this.selectedFilters.COMMON['CMN_PROM']) {
      this.selectedFilters['COMPANY'] = {};
      this.selectedFilters['HOMEPROMO'] = {};
    }

    this.commonFilterSet =
      this.selectedFilters.COMMON['CMN_AVAIL'] ||
      this.selectedFilters.COMMON['CMN_IN_STOCK'] ||
      this.selectedFilters.COMMON['CMN_NEW'] ||
      this.selectedFilters.COMMON['CMN_PROM'] ||
      this.selectedFilters.COMMON['CMN_OLD'] ||
      this.selectedFilters.COMMON['CMN_BOM']
        ? true
        : false;
    this.selectFilter();
  }

  onUserChange(changeContext: ChangeContext): void {
    this.commonFilterSet = true;
    this.setPriceFilter(changeContext.value, changeContext.highValue);
    this.trackingService.filterSearchPrice(
      changeContext.value,
      changeContext.highValue
    );
    this.filtersUpdated.emit({
      filters: this.selectedFilters,
      updateResults: true,
    });
  }

  // Range filter changed by user
  rangeChange(
    changeContext: ChangeContext,
    filterGroup: SideBarFilterOption
  ): void {
    this.selectedFilters[filterGroup?.specification_code] = {};
    filterGroup.options.forEach((o) => {
      if (
        changeContext.value <= Number(o.id) &&
        changeContext.highValue >= Number(o.id)
      ) {
        this.selectedFilters[filterGroup?.specification_code][o.id] = true;
      }
    });
    this.filtersUpdated.emit({
      filters: this.selectedFilters,
      updateResults: true,
    });
  }

  // Stockweek Range filter changed by user
  stockweekRangeChange(changeContext: StockweekFilter): void {
    this.stockweeksUpdated.emit(changeContext);
  }

  resetStock() {
    this.stockweeksUpdated.emit(null);
  }

  setPriceFilter(min: number, max: number) {
    this.selectedFilters['PRICE'] = {
      options: [
        {
          id: 'PRC_MIN',
          value: min,
        },
        {
          id: 'PRC_MAX',
          value: max,
        },
      ],
    };
  }

  removeFilters(updateResults: boolean) {
    const self = this;
    this.filtersSelected = false;
    this.selectedFilterList = [];

    // Reset filters
    Object.keys(self.selectedFilters).forEach(function (key) {
      self.selectedFilters[decodeURI(key)] = {};
    });

    // Set price filter on default
    this.minValue = 0;
    this.maxValue = 9999;
    this.setPriceFilter(this.minValue, this.maxValue);

    setTimeout(() => {
      this.selectedFilters.COMMON = {
        CMN_AVAIL: false,
        CMN_IN_STOCK: false,
        CMN_NEW: false,
        CMN_PROM: false,
        CMN_OLD: false,
        CMN_BOM: false,
      };
      this.searchService.resetFilters('');
    }, 0);
    this.commonFilterSet = false;

    if (this.searchFilter) {
      this.searchFilter.keepFilters = false;
    }

    this.filtersUpdated.emit({
      filters: self.selectedFilters,
      updateResults: updateResults,
      reset: true,
    });
  }

  filterGroupName(index, filterGroup) {
    return filterGroup.name;
  }

  filterId(index, filter) {
    return filter.id;
  }

  hideFilters() {
    this.sideBarActive = false;
    this.applicationService.scrollToTop(0);
  }

  toTop() {
    const sidebar = document.getElementById('side-bar-container');
    sidebar.scrollTop = 0;
  }

  searchBomChange(event) {
    if (event.value === false) {
      this.selectedFilters['COMMON']['CMN_OLD'] = false;
    } else if (event.value === true) {
      this.selectedFilters['COMMON']['CMN_OLD'] = true;
    }
    return false;
  }

  getPromotions() {
    if (this.selectedFilters['COMMON']['CMN_PROM'] && !this.promotions) {
      this.searchService.getPromotions().then((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.promotionCode = '';
          this.promotions = Object.values(apiResponse.result);
        }
      });
    }
  }

  track(description: string, state: boolean) {
    this.trackingService.filterSearchToggle(state, 'Selection', description);
  }

  filterList(keyword: string, filterGroupId: string) {
    if (typeof keyword !== 'undefined') {
      this.filterKeywords[filterGroupId] = keyword.toLowerCase();
    }
  }

  matchesSearch(filterGroup: FilterGroup, filterDescription: string) {
    let hasMatch = false;

    if (
      !this.filterKeywords[filterGroup?.specification_code] &&
      this.globalFilterKeyword === null
    ) {
      return true;
    } else {
      if (
        this.globalFilterKeyword !== null ||
        (this.showControls &&
          this.filterKeywords[filterGroup?.specification_code])
      ) {
        const formattedDescription = filterDescription.toLowerCase();
        const keywords =
          this.globalFilterKeyword !== null
            ? this.globalFilterKeyword.toLowerCase().split(' ')
            : this.filterKeywords[filterGroup?.specification_code].split(' ');

        keywords.forEach((k) => {
          if (formattedDescription.indexOf(k) >= 0) {
            hasMatch = true;
          }
        });
      }
    }

    if (hasMatch && this.globalFilterKeyword) {
      filterGroup.collapse = false;
    }

    return hasMatch;
  }

  selectFilters(filterGroup: FilterGroup, filters: Array<FilterOption>) {
    filters.forEach((f) => {
      if (this.matchesSearch(filterGroup, f.id)) {
        this.selectedFilters[filterGroup?.specification_code][f.id] = this
          .selectedFilters[filterGroup?.specification_code][f.id]
          ? false
          : true;
      }
    });
    this.selectFilter();
  }

  viewFilter(filter: FilterOption) {
    let selectedFilter = _.findWhere(this.availableFilters, {
      specification_code: filter.id,
    });
    if (selectedFilter) {
      selectedFilter.show_checkboxes = true;
      const element = document.getElementById(`filter-${filter.id}`);
      element.scrollIntoView();
    }
  }

  saveFilters() {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      windowClass: 'medium',
      container: '#modalContainer',
    });

    const type = 'save';

    modalRef.componentInstance.setContent(SaveFiltersComponent, 'type', type);
  }

  loadFilter() {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      windowClass: 'medium',
      container: '#modalContainer',
    });

    const type = 'load';

    modalRef.componentInstance.setContent(SaveFiltersComponent, 'type', type);
  }

  updateResults(numResults: number) {
    const filters = [...this.availableFilters];
    filters.forEach((filter: FilterGroup) => {
      filter.collapse = filters.length < 5 ? false : true;
    });
    this.modalFilter.priceRangeData = this.priceRangeData;
    this.modalFilter.stockweekData = this.stockweekData;
    this.modalFilter.availableFilters = filters;
    document.getElementById(
      'numResultsFilterDialog'
    ).innerHTML = `(${numResults})`;
  }

  toggleVisibleOptions(filterGroup: SearchFilter) {
    if (!filterGroup.show_all) {
      filterGroup.show_all = true;
    } else {
      filterGroup.show_all = false;
    }
  }
}
