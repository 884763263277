import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Price } from 'src/app/interfaces/price';
import * as _ from 'underscore';
import { LeasePrice } from 'src/app/interfaces/lease/lease-price';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-lease-price',
  templateUrl: './lease-price.component.html',
  styleUrls: ['./lease-price.component.scss'],
})
export class LeasePriceComponent implements OnInit {
  @Input() leasePrices: LeasePrice[];
  @Input() selectedDuration: string;
  @Input() articleCode: string;
  @Input() showTable = false;
  @Input() hideLabel: boolean;
  @Input() monthlyExtraCost: number;
  @Input() batteryCosts: number;
  parsedPrices: any = {};
  parsedPricesList = [];
  @Input() lowestPrice: Price;
  @Input() leasePrice: Price;

  constructor(private applicationService: ApplicationService) {}

  ngOnInit() {
    if (this.leasePrices && this.leasePrices.length) {
      this.parsePrices();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['leasePrices']) {
      this.parsePrices();
    }
  }

  private parsePrices() {
    this.parsedPricesList = [];
    if (this.leasePrices && this.leasePrices.length) {
      for (let priceRange of [...this.leasePrices]) {
        const priceListObject = priceRange['prices']['LEASE']['default'];
        this.parsedPrices[String(priceRange.volume)] = priceListObject;
        priceListObject['volume'] = priceRange.volume;
        this.parsedPricesList.push(priceListObject);
      }

      // If 1 price - select this
      if (this.parsedPricesList.length === 1) {
        //  this.selectedDuration = this.parsedPricesList[0]["volume"]
      }

      this.getLeasePrice();
    } else {
      this.leasePrice = null;
    }
  }

  calcTotalPrice() {}

  getLeasePrice() {
    const lowest = _.min(this.parsedPrices, function (p: Price) {
      return p.value;
    });

    this.leasePrice = lowest;
  }
}
