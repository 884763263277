import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
//import { BarcodeScannerLivestreamComponent } from "ngx-barcode-scanner";
import { Subject } from "rxjs";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Article } from "src/app/interfaces/article";
import { BarcodeScanResult } from "src/app/interfaces/barcode-scan-result";
import { AlertService } from "src/app/services/alert.service";
import { HelperService } from "src/app/services/helper.service";
import { SearchService } from "src/app/services/search.service";

@Component({
  selector: "app-bar-code-scanner",
  templateUrl: "./bar-code-scanner.component.html",
  styleUrls: ["./bar-code-scanner.component.css"],
})
export class BarCodeScannerComponent implements AfterViewInit {
  //@ViewChild(BarcodeScannerLivestreamComponent)
  //barcodeScanner: BarcodeScannerLivestreamComponent;
  public articleSelected$ = new Subject();
  barcodeValue;
  autoClose = true;
  noCamera: boolean;
  qty = 1;
  article: Article = null;
  constructor(
    public modal: NgbActiveModal,
    private searchService: SearchService,
    private helperService: HelperService,
    private alertService: AlertService
  ) { }

  ngAfterViewInit() {
    // this.barcodeScanner.start();
  }

  onValueChanges(barcode) {
    console.log(barcode)
    if (barcode !== this.barcodeValue) {
      this.barcodeValue = barcode;
      this.searchService
        .getArticlesByCodes([this.barcodeValue])
        .subscribe((apiResponse: ApiResponse) => {
          if (this.helperService.checkResponse(apiResponse)) {
            const result = Object.values(apiResponse.result);
            if (result.length) {
              const article = result[0] as Article;
              this.article = article;
            } else {
              this.article = null;
            }
          }
        });
    }
  }

  onStarted(started) {
    console.log(started);
  }

  camerasNotFound() {
    alert(
      "No camera found"
    );
    this.noCamera = true;
  }

  add() {
    if (this.article !== null) {
      this.articleSelected$.next({
        article_code: this.article.single_article_code
          ? this.article.single_article_code
          : this.article.article_codes[0],
        quantity: this.qty,
        article_data: this.article
      } as BarcodeScanResult
      );
    }
  }
}
