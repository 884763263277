import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-focus-model",
  templateUrl: "./focus-model.component.html",
  styleUrls: ["./focus-model.component.scss"]
})
export class FocusModelComponent implements OnInit {
  @Input() size = "";
  constructor() {}

  ngOnInit() {}
}
