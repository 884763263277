import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationService } from "src/app/services/application.service";
import * as appGlobal from "src/app/globals";
import { AccountInformation } from "src/app/interfaces/account-information";
import { AccountService } from "src/app/services/account.service";

@Component({
  selector: "app-dropshipment-counter",
  templateUrl: "./dropshipment-counter.component.html",
  styleUrls: ["./dropshipment-counter.component.scss"],
})
export class DropshipmentCounterComponent implements OnInit {
  count = 0;
  loading = false;
  hasDropshipment: boolean;
  companyGroupHasDropshipment: boolean;
  constructor(
    private router: Router,
    public applicationService: ApplicationService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.getCompanyGroupSettings();
    this.applicationService.emitChangeCompanyGroupChanged$.subscribe(() => {
      this.getAccountInformation();
      this.getCompanyGroupSettings();
    });
  }

  getAccountInformation() {
    this.accountService
      .getAccountInformation(true)
      .then((accountInformation: AccountInformation) => {
        if (accountInformation) {
          this.hasDropshipment = accountInformation.can_access_dropshipment;
          this.applicationService.hasDropshipment = true;
        }
      });
  }

  getCompanyGroupSettings() {
    if (!appGlobal.companyGroupSettings) {
      this.applicationService.getSettingsForCompanyGroup(
        this.applicationService.getSelectCompanyGroupCode()
      );
    }
    if (appGlobal && appGlobal.companyGroupSettings) {
      this.companyGroupHasDropshipment =
        appGlobal.companyGroupSettings.dropshipment;
    }
  }

  gotoDropshipmentBasket() {
    this.router.navigateByUrl(
      `${this.applicationService.getSelectCompanyGroupCode()}/dropShipmentBasket`
    );
  }
}
