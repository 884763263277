import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-select-article",
  templateUrl: "./select-article.component.html",
  styleUrls: ["./select-article.component.scss"]
})
export class SelectArticleComponent implements OnInit {
  @Input() availableArticles: any = {};
  @Input() selected: any = [];
  @Input() id = "";
  @Input() index: number;
  selectedArticle: any;
  selectedForCompare: any = [];
  @Output() articleSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(private applicationService: ApplicationService) {}

  ngOnInit() {
    this.selectedForCompare = this.applicationService.getCompareListItems();
    this.selectedArticle = this.id;
  }

  selectArticle(event) {
    const articleId = parseFloat(event.target.value);
    this.selectedArticle = articleId;
    this.selected[this.index] = articleId;
    this.articleSelected.emit(articleId);
  }
}
