import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from 'src/app/interfaces/user';
import { AccountService } from 'src/app/services/account.service';
import { UpdateUserInput } from 'src/app/interfaces/update-user-input';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { OktaRegisterInput } from 'src/app/interfaces/okta-register-input';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from 'src/app/services/application.service';
import { AlertService } from 'src/app/services/alert.service';
import { AccountSettings } from 'src/app/interfaces/account-settings';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-okta',
  templateUrl: './register-okta.component.html',
  styleUrls: ['./register-okta.component.scss']
})
export class RegisterOktaComponent implements OnInit {
  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  formGroup: FormGroup;
  active: boolean;
  success: boolean;
  userNameDiffers: boolean;
  successText: string;
  loading: boolean;
  constructor(private router: Router, private applicationService: ApplicationService, private accountService: AccountService, private translateService: TranslateService, private alertService: AlertService) { }

  ngOnInit() {
    this.buildForm();
      // get account settings
    this.accountService
    .getAccountSettings()
    .subscribe((apiResponse: ApiResponse) => {
      if (apiResponse && apiResponse.result) {
        const accountSettings = apiResponse.result as AccountSettings;
        if (
          (accountSettings.okta && accountSettings.registered) ||
          !accountSettings.okta
        ) {
          this.router.navigateByUrl("/login");
        }
      }
   })
  }

  buildForm() {
    let EMAILPATTERN = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    this.formGroup = new FormGroup({
      firstname: new FormControl("", [Validators.required]),
      lastname: new FormControl("", [Validators.required]),
      username: new FormControl("", [Validators.required, Validators.pattern(EMAILPATTERN)]),
      usernameconfirm: new FormControl("", [Validators.required, Validators.pattern(EMAILPATTERN)]),
    });

    this.formGroup.valueChanges.subscribe(() => {
      if (this.formGroup.get("username").value !== this.formGroup.get("usernameconfirm").value) {
        this.userNameDiffers = false;
      }
    })
    this.active = true;
  }

  submit() {
    if (this.formGroup.valid) {
      const user  = new OktaRegisterInput(this.formGroup.get("firstname").value, this.formGroup.get("lastname").value, this.formGroup.get("username").value);
      if (user.username !== this.formGroup.get("usernameconfirm").value) {
        this.userNameDiffers = true;
      } else {
        this.loading = true;
        this.applicationService.showLoader(true);
        // subscribe to call
        this.accountService.register(user).subscribe((apiResponse: ApiResponse) => {
          this.loading = false;
          this.applicationService.hideLoader();
          if (apiResponse.success) {
            this.successText = this.translateService.instant("ACTIVATE_P1").replace("{0}", this.formGroup.get("username").value);
            this.success = true;
          } else {
            this.alertService.showPopup(this.translateService.instant("ERROR"), "error");
          }
        })
      }
    }
  }

}
