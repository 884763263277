import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject, map } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiResponse } from "../interfaces/api-response";
import { Article } from "../interfaces/article";
import { UpdateStockInput } from "../interfaces/replenishment/update-stock-input";
import { AlertService } from "./alert.service";
import { ApplicationService } from "./application.service";
import { ReplenishmentSettings } from "../interfaces/replenishment-settings";

@Injectable({
  providedIn: "root",
})
export class ReplenishmentService {
  selectionLoaded = new Subject<boolean>();
  public stockList = {};
  loading: boolean;
  replenishmentSettings: ReplenishmentSettings;
  constructor(
    private http: HttpClient,
    private applicationService: ApplicationService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  getSettings() {
    return this.http.get(environment.apiendpoint + "replenish/settings").pipe(
      map((response: ApiResponse) => {
        this.replenishmentSettings = response.result;
        return response;
      })
    );
  }

  getCurrentStock() {
    this.loading = true;
    this.getArticles().then((articles: UpdateStockInput[]) => {
      this.loading = false;
      articles.forEach((a) => {
        this.stockList[a.article_code] = a;
      });
      this.selectionLoaded.next(true);
    });
  }

  getArticles() {
    return new Promise((resolve) => {
      this.http
        .get(environment.apiendpoint + "replenish/replenishments")
        .subscribe((apiResponse: ApiResponse) => {
          this.loading = false;
          if (apiResponse && apiResponse.result) {
            const articles = apiResponse.result as UpdateStockInput[];
            resolve(articles);
          } else {
            resolve([]);
          }
        });
    });
  }

  deleteStockLevel(articleCode: string) {}

  // Save the selected stock quantities
  updateStock(articles: Article[]) {
    return new Promise((resolve) => {
      let updateInputList = new Array<UpdateStockInput>();

      articles.forEach((a) => {
        a.matrix.options.forEach((o) => {
          const articles = o.value as Article[];
          const article = articles[0];
          if (article.min_dealer_stock >= 0 || article.max_dealer_stock > 0) {
            const updateData = new UpdateStockInput(
              this.applicationService.getSelectCompanyGroupCode(),
              localStorage.getItem("accountCode"),
              article.single_article_code,
              article.min_dealer_stock ? article.min_dealer_stock : 0,
              article.max_dealer_stock ? article.max_dealer_stock : 99,
              article.battery_article_code
            );
            updateInputList.push(updateData);
          }
        });
      });
      if (updateInputList.length) {
        this.applicationService.showLoader(true);
        this.http
          .post(
            environment.apiendpoint + "replenish/replenishments",
            updateInputList
          )
          .subscribe((apiResponse: ApiResponse) => {
            this.applicationService.hideLoader();
            if (apiResponse && apiResponse.success) {
              this.alertService.showPopup(
                this.translateService.instant("SAVED"),
                "success"
              );
              // Update the stocklist so results are updated to the user
              updateInputList.forEach((a) => {
                this.stockList[a.article_code] = a;
              });

              resolve(true);
            }
          });
      }
    });
  }

  removeFromList(updateStockInput: UpdateStockInput) {
    return new Promise((resolve) => {
      this.applicationService.showLoader(true);
      this.http
        .delete(environment.apiendpoint + `replenish/replenishments/${updateStockInput.article_code}`)
        .subscribe((apiResponse: ApiResponse) => {
          this.applicationService.hideLoader();
          if (apiResponse && apiResponse.success) {
            this.alertService.showPopup(
              this.translateService.instant("DELETED"),
              "success"
            );

            resolve(true);
          }
        });
    });
  }
}
