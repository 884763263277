import { Component, OnInit, Input } from "@angular/core";
import { ApplicationService } from "src/app/services/application.service";
import * as _ from "underscore";
import { Widget } from "src/app/interfaces/cms/widget";
import { Observable } from "rxjs";
import { CacheService } from "src/app/services/cache.service";
import { LanguageDefinition } from "src/app/interfaces/cms/brand-widget";
import { WidgetLanguageItem } from "src/app/interfaces/cms/widget-language-item";
import { SearchService } from "src/app/services/search.service";
import { CompanyPromotion } from "src/app/interfaces/company-promotion";
import { ApiResponse } from "src/app/interfaces/api-response";

@Component({
  selector: "app-promo-widget",
  templateUrl: "./promo-widget.component.html",
  styleUrls: ["./promo-widget.component.scss"]
})
export class PromoWidgetComponent implements OnInit {
  widget: WidgetLanguageItem;
  startPosX = 50;
  startPosY = 50;
  @Input() observer: Observable<number>;
  numResults: number;
  carouselImage: boolean;

  constructor(private applicationService: ApplicationService, private cacheService: CacheService, private searchService: SearchService) {}

  ngOnInit() {
    this.observer.subscribe((numResults: number) => {
      this.checkUrl();
      this.numResults = numResults;
    });
  }

  checkUrl() {
    if (window.location.href.indexOf("widgetId") >= 0) {
      const urlData = window.location.href.split("=");
      const widgetId = urlData[urlData.length - 1];
      this.getWidget(widgetId);
    } else if (window.location.href.includes("HOMEPROMO")) {
      this.getPromotionWidget();
    }
    else {
      this.widget = null;
    }
  }

  getWidget(widgetId: string) {
    this.carouselImage = false;
    const cachedWidgets = sessionStorage.getItem(
      "widgets-" + this.applicationService.getSelectCompanyGroupCode()
    );
    const cachedBrandWidgets = sessionStorage.getItem(
      "brandwidgets-" + this.applicationService.getSelectCompanyGroupCode()
    );

    const carouselSlides = this.cacheService.carouselSlides;

    if (cachedWidgets || cachedBrandWidgets || carouselSlides) {
      const widgets = JSON.parse(cachedWidgets);
      const widget = _.findWhere(widgets, { id: widgetId }) as Widget;
      if (widget?.language_definitions?.length) {
        this.widget = widget.language_definitions[0];
      }

      if (!this.widget) {

        const brandWidgets = JSON.parse(cachedBrandWidgets);
        if (brandWidgets) {
         const widget = _.findWhere(brandWidgets, { id: widgetId });
        }
        if (widget?.language_definitions?.length) {
          this.widget = widget.language_definitions[0];
        }
      }

      if (!this.widget) {
        if (carouselSlides) {
          this.widget = _.findWhere(carouselSlides, { id: Number(widgetId) });
          this.carouselImage = true;
        }
      }
    }
  }

  getPromotionWidget() {
    const queryString = window.location.href;
    const urlParams = new URLSearchParams(queryString);
    const promoId = urlParams.get("HOMEPROMO");
    const companyId = urlParams.get("COMPANY");
    const cache = this.searchService.cachedPromotions[this.applicationService.getSelectCompanyGroupCode()] as ApiResponse;
    if (cache) {
      let promotions = [];
      for (let i = 0; i <  Object.values(cache?.result).length; i++) {
        promotions = promotions.concat(Object.values(cache?.result)[i] as Array<CompanyPromotion>)
      }

      const widgetData = (promotions.find((p: CompanyPromotion) => {
        return p.promotioncode === promoId && p.company_id === companyId
      }))

      if (widgetData) {
        this.widget = new WidgetLanguageItem();

        if (widgetData.image) {
          this.widget.image_url = !widgetData.image.endsWith("/_t.jpg") ? widgetData.image : "";
        } else {
          this.widget.image_url = "";
        }

        this.widget.title = widgetData.title ? widgetData.title : widgetData.description;
      }
    }
  }
}
