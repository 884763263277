<nav class="navbar fixed-bottom navbar-light bg-light">
  <div class="container">
    <ng-container
      *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'"
    >
      <div class="page-indicator">
        <p class="mb-0">{{ "PAGE" | translate }} {{ pageData.page }}</p>
      </div>
      <div class="navigation-buttons">
        <button
          class="btn btn-primary d-inline-block me-2"
          [ngClass]="pageData.loading || pageData.page === 1 ? 'disabled' : ''"
          [disabled]="pageData.loading || pageData.page === 1"
          (click)="previous(pageData['acePreviousPage'])"
          *ngIf="pageData['aceNextPage'] || pageData['acePreviousPage']"
        >
          {{ "PREVIOUS" | translate }}
        </button>
        <button
          class="btn btn-primary d-inline-block"
          [ngClass]="
            pageData.loading || !pageData['hasNextPage'] ? 'disabled' : ''
          "
          [disabled]="pageData.loading || !pageData['hasNextPage']"
          (click)="next(pageData['aceNextPage'])"
          *ngIf="pageData['aceNextPage'] || pageData['acePreviousPage']"
        >
          {{ "NEXT" | translate }}
        </button>
      </div>
    </ng-container>
    <div class="pdf-button mt-2" [ngClass]="{'ms-auto': applicationService.getSelectCompanyGroupCode() === 'WG'}">
      <ng-content> </ng-content>
    </div>
  </div>
</nav>
