import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { BasketArticle } from "src/app/interfaces/basketArticle";

@Component({
  selector: "app-shop-type-selection",
  templateUrl: "./shop-type-selection.component.html",
  styleUrls: ["./shop-type-selection.component.scss"]
})
export class ShopTypeSelectionComponent implements OnInit {
  @Output() shopTypeChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() article: BasketArticle;
  shopType: string = "";
  constructor() {}

  ngOnInit() {
    if (this.article) {
      if (this.article.sold) {
        this.shopType = "X";
      }
      if (this.article.workshop) {
        this.shopType = "W";
      }
      if (!this.article.workshop && !this.article.sold) {
        this.shopType = "S";
      }
    }
  }

  shopTypeChange(data) {
    this.shopTypeChanged.emit(this.shopType);
  }
}
