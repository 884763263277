import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SetItemsComponent } from "../../article-components/set-items/set-items.component";
import { ConfirmDialogComponent } from "../../modal-components/confirm-dialog/confirm-dialog.component";
import { SetItemModalComponent } from "../../article-components/set-items/set-item-modal/set-item-modal.component";

@Component({
  selector: "app-set-item-button",
  templateUrl: "./set-item-button.component.html",
  styleUrls: ["./set-item-button.component.scss"]
})
export class SetItemButtonComponent implements OnInit {
  @Input() article: Article;
  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  composeSetitem() {
    const modalRef = this.modalService.open(SetItemModalComponent, {
      centered: true,
      size: "lg",
      container: "#modalContainer"
    });
    modalRef.componentInstance.article = this.article;
  }
}
