import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { trigger, keyframes, animate, transition } from "@angular/animations";
import { Article } from "src/app/interfaces/article";
import { ApplicationService } from "src/app/services/application.service";
import * as kf from "src/app/interfaces/keyframes";
import { timer } from "rxjs";
import { ArticleModalComponent } from "../modal-components/article-modal/article-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FavoritesService } from "src/app/services/favorites.service";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { ApiResponse } from "src/app/interfaces/api-response";

@Component({
  selector: "app-fav-swiper",
  templateUrl: "./fav-swiper.component.html",
  styleUrls: ["./fav-swiper.component.scss"],
  animations: [
    trigger("cardAnimator", [
      transition("* => swiperight", animate(750, keyframes(kf.swiperight))),
      transition("* => swipeleft", animate(750, keyframes(kf.swipeleft))),
    ]),
  ],
})
export class FavSwiperComponent implements OnInit {
  @Input() articles: Article[];
  @Output() loadMoreArticles: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  index = 0;
  animationState: string;
  rotation: number = 0;
  distanceX: number = 0;

  get article(): Article {
    return this.articles[this.index];
  }

  constructor(
    private applicationService: ApplicationService,
    private modalService: NgbModal,
    private favoritesService: FavoritesService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  swipeleft() {
    if (this.articles.length - 1 >= this.index) {
      this.startAnimation("swipeleft");
      timer(400).subscribe(() => {
        this.index++;
      });
    } else {
      this.applicationService.showLoader(true);
      this.loadMoreArticles.next(true);
      this.index = 0;
    }
  }

  swiperight(article: Article) {
    this.favoritesService
      .addFavorite(
        article.single_article_code
          ? article.single_article_code
          : article.article_codes[0]
      )
      .subscribe((response: ApiResponse) => {
        this.alertService.showInfoNotification(
          this.translateService.instant("FAVORITE_ADDED")
        );
      });

    if (this.articles.length - 1 >= this.index) {
      this.startAnimation("swiperight");
      timer(400).subscribe(() => {
        this.index++;
      });
    } else {
      this.applicationService.showLoader(true);
      this.loadMoreArticles.next(true);
      this.index = 0;
    }
  }

  startAnimation(state) {
    if (!this.animationState) {
      this.animationState = state;
    }
  }
  resetAnimationState(state) {
    this.animationState = "";
     this.rotation = 0;
     this.distanceX = 0;
  }

  openModal(article) {
    const modalRef = this.modalService.open(ArticleModalComponent, {
      size: "lg",
      container: "#modalContainer",
    });
    modalRef.componentInstance.article = article;
    modalRef.componentInstance.modelId = article["id"];
    modalRef.componentInstance.showDetailed = true;
    modalRef.componentInstance.showInModal = true;
    modalRef.componentInstance.selectedArticle = article["single_article_code"];
    modalRef.componentInstance.article.img = article["images"];
  }

  close() {
    this.closeModal.next(true);
  }

  onPanMove(data) {
    if (data.direction === 2 || data.direction === 4) {
      this.rotation = (data.direction === 2 ? -1 : 1) * (data.distance / 5);
      this.distanceX = (data.direction === 2 ? -1 : 1) * (data.distance / 2);
    }
  }

  onPanUp(data) {
    timer(0).subscribe(() => {
      if (!this.animationState) {
        this.rotation = 0;
        this.distanceX = 0;
      }
    });
  }
}
