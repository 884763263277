<div class="d-flex align-items-center p-2 justify-content-between" *ngIf="brand" [@scale]>
  <img
    class="d-block clickable withOpacity"
    [ngClass]="'logo_' + brand.name"
    height="48px"
    [src]="'./assets/images/logos/brands/' + brand.logo"
  />
  <h2 *ngIf="brand.type === 'bicycles'" class="mb-0 pb-0">
    {{ brand.bike_count }}
    {{
      (brand.bike_count === 1 ? "MODEL" : "MODELS") | translate | lowercase
    }}&nbsp;
  </h2>
</div>
