import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class FavoritesService {
  private emitChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  constructor(private http: HttpClient) { }

  // Service message commands
  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  // Get list of favorites of the user
  getFavorites(inputData) {
    return this.http.post(
      environment.apiendpoint + "favorites/search",
      inputData
    );
  }

  removeFavorite(articleCode: string, type: string = "main") {
    return this.http.delete(
      `${environment.apiendpoint}favorites/${articleCode}/${type}`
    );
  }

  addFavorite(articleCode: string, type: string = "main") {
    return this.http.post(
      `${environment.apiendpoint}favorites/${articleCode}/${type}`,
      {
        articleCode: articleCode
      }
    );
  }
}
