import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountInformation } from 'src/app/interfaces/account-information';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { BrandSelectorLogo } from 'src/app/interfaces/brand-selector-logo';
import { AccountService } from 'src/app/services/account.service';
import { ApplicationService } from 'src/app/services/application.service';
import { GeneralService } from 'src/app/services/general.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-juncker-loyalty-count',
  templateUrl: './juncker-loyalty-count.component.html',
  styleUrls: ['./juncker-loyalty-count.component.scss'],
  animations: [
    trigger('inAnimation', [
      transition(':enter', [
        style({ opacity: 0.2 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
    ])
  ]
})


export class JunckerLoyaltyCountComponent {
  points: string = "";
  @Input() compact: true;
  hasLoyalty: boolean;
  showCounter: boolean;

  constructor(private applicationService: ApplicationService, private translateService: TranslateService, private generalService: GeneralService, private helperService: HelperService, private accountService: AccountService) {

  }

  ngOnInit() {
    const brands = this.applicationService.getSelectedCompanyGroupBrands("ANL");
    const hasJuncker = (brands.findIndex((f: BrandSelectorLogo) => {
      return f.name === "Juncker"
    })) >= 0;

    if (hasJuncker) {
      this.getAccountInformation().then(() => {
        if (this.hasLoyalty ) {
          this.getDealerPoints();
        }
      })
    }
  }

  private getAccountInformation() {
    return this.accountService
      .getAccountInformation(false)
      .then((accountInformation: AccountInformation) => {
        this.hasLoyalty = accountInformation.can_access_loyalty_program
      });
  }


  getDealerPoints() {
    this.generalService.loyaltyPoints().subscribe((apiResponse: ApiResponse) => {
      if (this.helperService.checkResponse(apiResponse)) {
        this.points = String(apiResponse.result);
        this.showCounter = true;
      } else {
        this.showCounter = false;
      }
    })
  }

  get pointsText() {
    return this.points || this.points == "0" ? this.translateService.instant("JUNCKER_LOYALTY_TOOLTIP_TEXT").replace("[points]", this.points) : "";
  }

  refreshPoints() {
    if (this.hasLoyalty ) {
      this.getDealerPoints();
    }
  }
}
