<div class="notification d-flex flex-row p-3 mb-2 shadow position-relative" *ngIf="showNotification">
  <div>
    <app-article-image [imageUrl]="article.thumbs[0]" [useLazyLoad]="false" [width]="'32px'" [articleType]="article.article_type"></app-article-image>
  </div>
  <div class="d-flex flex-column align-items-start px-2">
    <h5 class="mb-0" [innerHTML]="article.description"></h5>
    <small class="mb-2" [innerHTML]="article.caption"></small>
    <p>
      {{ "SN_ARTICLE_BACK_IN_STOCK" | translate}}
    </p>
    <button class="btn btn-primary btn-sm" (click)="gotoItem()">
      {{ "VIEW" | translate }}
    </button>
  </div>
  <span class="position-absolute close" (click)="close()"> &times; </span>
</div>
