<div [ngClass]="parentClass">
  <div class="row" *ngIf="options">
    <ng-container *ngFor="let option of options">
      <app-year-agreement-group class="bg-transparent border-0 card" *ngIf="option.sub_group_options?.length" [ngClass]="cardClass" [type]="type" [accountAgreement]="accountAgreement"
        [option]="option" [yearAgreementModel]="yearAgreementModel" [isMain]="isMain" (selectionChanged)="calcPercentage()" [onlyAffiliateOption]="onlyAffiliateOption" [affiliate]="affiliate" [showCalculation]="showCalculation">
      </app-year-agreement-group>
    </ng-container>
    <ng-content></ng-content>
  </div>
  <span class="my-3 text-end d-block w-100 totals" *ngIf="!onlyAffiliateOption">
    <h3 class="me-3" *ngIf="yearAgreementModel.showMargins && showCalculation"><span class="me-5">{{'MARGIN_BEFORE_BONUS'|translate}}
        {{totalPercentageWithoutBonus}}%</span>
      {{'MARGIN'|translate}} {{totalPercentage}}%
    </h3>
    <button  class="btn btn-dark" *ngIf="showMarginButton"
      (click)="yearAgreementModel.showMargins = true; calcPercentage()">{{'CALCULATE_MARGINS'|translate}}</button>
  </span>
</div>
