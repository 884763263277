<div class="row">
  <ng-container *ngTemplateOutlet="
      list;
      context: {
        listBrands: getBrands(brands, 1),
        bonusOptions: bonusOptions,
      }
    "></ng-container>

  <ng-container *ngIf="rows === 2">
    <ng-container *ngTemplateOutlet="
        list;
        context: {
          listBrands: getBrands(brands, 2),
          bonusOptions: bonusOptions,
        }
      "></ng-container>
  </ng-container>

  <ng-template #list let-items="listBrands" let-bonusOptions="bonusOptions">
    <div class="bg-transparent" [ngClass]="rows === 2 ? 'col-6' : 'col-12'">
      <div class="card-body section">
        <h4 *ngIf="title">{{ title }}</h4>
        <div class="row py-3" *ngIf="group?.has_meters">
          <div class="col-6 d-flex gap-2 align-items-center">
            <input type="number" min="0" class="form-control p-2" [min]="minimumMeters" [(ngModel)]="group.meters" style="max-width: 54px" (change)="checkMeters()">
            <label>{{"YA_P_AND_A_METERS"|translate}}</label>
          </div>
        </div>
        <p *ngIf="group?.description">{{ group?.description }}</p>
        <div *ngIf="
            exactSelectionRequired &&
            !yearAgreementPAndAService.agreementModel.year_agreement_base
              .locked &&
            yearAgreementService.maxPartBrandSelection > 0
          " class="mb-3">
          <ngb-alert type="warning">{{ "SELECT_MAX" | translate }}:
            {{ yearAgreementService.maxPartBrandSelection }}</ngb-alert>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item py-0 border-0 ps-1 pe-0" style="background: none">
            <div class="row mx-0">
              <div class="col-1 switch-container">{{ label1 }}</div>
              <div [ngClass]="hasPlanning ? 'col-3' : 'col-5'">
                {{ label2 }}
              </div>
              <div class="col-2">{{ label4 }}</div>
              <div class="col-2">{{ label3 }}</div>
              <div class="col-2">
                <ng-container *ngIf="showTarget">
                  {{ "REVENUE_TARGET" | translate }}
                </ng-container>
              </div>
              <div class="col-2 text-end" [hidden]="!hasPlanning">
                {{
                (group?.preorder_discount_levels
                ? "TYRE_PLANNING"
                : "PLANNING"
                ) | translate
                }}
              </div>
            </div>
          </li>
        </ul>
        <ng-container *ngTemplateOutlet="
        options;
        context: {
          listBrands: items,
          type: 'regular'
        }
      "></ng-container>
        <ng-container *ngIf="bonusOptions">
          <ng-container *ngTemplateOutlet="
              options;
              context: {
                listBrands: bonusOptions,
                type: 'bonus'
              }
            "></ng-container>
        </ng-container>
        <div class="form-check form-switch" *ngIf="group?.has_group_planning" style="font-size: 1rem;">
          <input type="checkbox" class="form-check-input" [id]="'group_planning_' + group.id"
            [(ngModel)]="group.group_planning_selected" />
          <label class="form-check-label" [for]="'group_planning_' + group.id"></label>
          {{"PLANNING"|translate}}
        </div>
      </div>
    </div>
  </ng-template>
</div>
<ng-template #options let-listBrands="listBrands" let-type="type">
  <ul class="list-group mb-2">
    <li class="list-group-item py-1 ps-1" *ngFor="let brand of listBrands">
      <div class="row mx-0 align-items-center">
        <div class="col-1 switch-container">
          <div class="form-check form-switch" *ngIf="showToggle">
            <input type="checkbox" class="form-check-input" [disabled]="
                brand.parent_id ||
                yearAgreementPAndAService.agreementModel
                  .year_agreement_base.locked ||
                (hasMaxBrandSelection &&
                  matchesSelection() &&
                  !brand.selected) ||
                (brand.parent_id &&
                  !connectedBrandSelected(brand.parent_id))
              " [id]="'brand_' + groupId + '_' + brand.id" [(ngModel)]="brand.selected" (change)="
                checkSelection();
                brandsChange.emit();
                setValues(brand, true, type);
                triggerChange(brand)
              " />
            <label class="form-check-label" [for]="'brand_' + groupId + '_' + brand.id"></label>
          </div>
        </div>
        <div class="d-flex align-items-center" [ngClass]="hasPlanning ? 'col-3' : 'col-5'">
          <label class="clickable withOpacity" [for]="'brand_' + groupId + '_' + brand.id"
            [innerHTML]="brand.description"></label>
        </div>
        <div class="col-2">
          <select class="form-control required px-1" *ngIf="showTier && brand.tiers?.length"
            style="min-width: 50px" [disabled]="!brand.selected || brand.parent_id"
            (change)="setValues(brand, true); setPlanning(brand)" [(ngModel)]="brand.tier"
            [ngClass]="{ 'no-value': !brand.tier }">
            <option [value]="''">
              {{ "MAKE_A_CHOICE" | translate }}
            </option>
            <option *ngFor="let tier of brand.tiers" [value]="tier.value">
              {{ tier.description ? tier.description : tier.value }}
            </option>
          </select>
        </div>
        <div class="col-2">
          <ng-container *ngIf="showDiscount">
            <input type="number" min="0" class="form-control form-control-sm" placeholder="{{
                brand.selected ? ('REQUIRED' | translate) : ''
              }}" *ngIf="brand.discount_editable" [(ngModel)]="brand.discount"
              [readonly]="!brand.discount_editable || !brand.selected" />
            <input type="number" min="0" class="form-control form-control-sm" *ngIf="!brand.discount_editable"
              [value]="brand.selected ? brand.discount : ''"
              [readonly]="!brand.discount_editable || !brand.selected" />
          </ng-container>
        </div>
        <div class="col-3">
          <small *ngIf="brand.planning && group?.preorder_discount_levels">
            {{ "EXTRA_MARGIN" | translate }}
            {{
            group.preorder_discount_levels[
            yearAgreementPAndAService.agreementModel
            .selected_dealer_level_index
            ]
            }}%
            <br />
            {{ "PRE_ORDER_VALUE_TYRES" | translate }}: &euro;
            {{
            group.preorder_target_levels[
            yearAgreementPAndAService.agreementModel
            .selected_dealer_level_index
            ]
            }}
          </small>
          <input type="number" min="0" class="form-control form-control-sm" [ngModel]="brand.target"
            *ngIf="showTarget && !targetEditable && brand.has_target" [readonly]="!targetEditable" />
        </div>

        <div class="col-1 switch-container">
          <div class="form-check form-switch" *ngIf="showToggle && brand.has_planning">
            <input type="checkbox" class="form-check-input" [disabled]="
                !brand.selected ||
                (brand.parent_id &&
                  !connectedBrandSelected(brand.parent_id)) ||
                (!brand.tier && brand.tiers && brand.tiers.length)
              " [id]="'planning_' + groupId + '_' + brand.id" [(ngModel)]="brand.planning" />
            <label class="form-check-label" [for]="'planning_' + groupId + '_' + brand.id"></label>
          </div>
          <div class="form-check form-switch" *ngIf="group?.preorder_discount_levels?.length && showToggle">
            <input type="checkbox" class="form-check-input" [disabled]="!brand.selected"
              [id]="'planning_' + groupId + '_' + brand.id" [(ngModel)]="brand.planning" />
            <label class="form-check-label" [for]="'planning_' + groupId + '_' + brand.id"></label>
          </div>
        </div>
      </div>
    </li>
  </ul>
</ng-template>
