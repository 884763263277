import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { OpenOrdersFilters } from "src/app/interfaces/financial/openorders/open-orders-filters";
import { OpenOrdersFilterOptions } from "src/app/interfaces/financial/openorders/open-orders-filter-options";
import { ShippingAddressGroup } from "src/app/interfaces/shipping-address-group";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { OpenOrdersSelectedFilters } from "src/app/interfaces/financial/openorders/open-orders-selected-filters";
import { ApplicationService } from "src/app/services/application.service";
import * as appGlobal from "src/app/globals";
import { FinancialService } from "src/app/services/financial.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { BelongsOptions } from "src/app/interfaces/financial/belongs-options";
import { ActivatedRoute } from "@angular/router";
import { HelperService } from "src/app/services/helper.service";
@Component({
  selector: "app-open-orders-filters",
  templateUrl: "./open-orders-filters.component.html",
  styleUrls: ["./open-orders-filters.component.scss"]
})
export class OpenOrdersFiltersComponent implements OnInit {
  @Output() searchSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Input() shippingAddressGroup: ShippingAddressGroup;
  companies: number[];
  filters: OpenOrdersSelectedFilters;
  filterOptions: OpenOrdersFilterOptions;
  fromDate: NgbDate;
  toDate: NgbDate;
  maxDate: Date;
  bicycleBrands: BelongsOptions[];
  constructor(
    public applicationService: ApplicationService,
    private financialService: FinancialService,
    private activatedRouter: ActivatedRoute,
    private helperService: HelperService
  ) {
    this.filters = new OpenOrdersSelectedFilters(
      "all",
      "all",
      "",
      "Description"
    );
    this.filterOptions = new OpenOrdersFilterOptions(this.applicationService.getSelectCompanyGroupCode() !== 'WG');
    this.maxDate = new Date();
  }

  ngOnInit() {
    let getResults = false;
    this.activatedRouter.params.subscribe(params => {
      if (params["id"] && params["type"] === "openorders" && this.filters.keyWord !== params["id"]) {
        this.filters.keyWord = params["id"];
        getResults = true;
      }
    });
    this.searchSubmitted.next({ filters: this.filters, getResults: getResults });
    this.getBicycleBrands();

    this.applicationService.emitChangeCompanyGroupChanged$.subscribe(() => {
      this.getBicycleBrands();
    });
  }

  getCompanies() {
    this.applicationService.getSettingsForCompanyGroup(
      this.applicationService.getSelectCompanyGroupCode()
    );
    this.companies = Object.values(appGlobal.companyGroupSettings.companies);
  }

  submit() {
    this.searchSubmitted.next({ filters: this.filters, getResults: true });
  }

  resetFilters() {
    this.filters = new OpenOrdersSelectedFilters(
      "all",
      "all",
      "",
      "Description"
    );
    this.filters.orderDate = null;
    this.submit();
  }

  setShippingAddress(shippingAddressId: string) {
    this.filters["shippingAddress"] = shippingAddressId;
  }

  getBicycleBrands() {
    this.financialService
      .getBicycleBrands(this.applicationService.getSelectCompanyGroupCode())
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.bicycleBrands = apiResponse.result;
        }
      });
  }
}
