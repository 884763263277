import { Injectable } from "@angular/core";
import { Article } from "../interfaces/article";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationService } from "./application.service";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ArticleCollectorService {
  private emitCountChange = new Subject<any>();
  changeEmitted$ = this.emitCountChange.asObservable();
  selectedForBasketCount = 0;
  articles: Article[];
  selectedForBasket: any = {};

  constructor(private applicationService: ApplicationService) {}

  // Service message commands
  emitChange() {
    this.emitCountChange.next(true);
  }

  countSelected(article) {
    let count = this.selectedForBasketCount;
    const selectedArticles = this.selectedForBasket;
    const key = article.single_article_code
      ? article.single_article_code
      : article.article_codes[0];

    if (article.selected) {
      selectedArticles[key] = article;
    } else {
      delete selectedArticles[key];
    }
    this.selectedForBasket = selectedArticles;
    count = Object.keys(this.selectedForBasket).length;
    this.selectedForBasketCount = count;
    this.emitChange();
    return count;
  }

  addAll() {}

  deselectAll() {
    this.articles = Array<Article>();
    this.selectedForBasket = {};
    this.selectedForBasketCount = 0;
    this.emitChange();
  }

  compareAddAll() {
    const self = this;
    Object.keys(this.selectedForBasket).forEach(function (key) {
      // Make a copy - no reference
      const article = Object.assign({}, self.selectedForBasket[key]);
      self.applicationService.addOrRemoveToCompareList(article);
    });
    this.deselectAll();
  }
}
