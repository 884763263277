import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { SearchService } from "src/app/services/search.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { SuggestTermResult } from "src/app/interfaces/suggest-term-result";
import { ApplicationService } from "src/app/services/application.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Article } from "src/app/interfaces/article";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-suggest-terms",
  templateUrl: "./suggest-terms.component.html",
  styleUrls: ["./suggest-terms.component.scss"],
})
export class SuggestTermsComponent implements OnInit {
  @Input() searchTerm: string;
  @Input() showTerms: boolean;
  @Output() getResult: EventEmitter<any> = new EventEmitter<any>();
  results: SuggestTermResult[] = null;
  loaded: boolean;
  constructor(
    private searchService: SearchService,
    private applicationService: ApplicationService,
    private location: Location,
    private helperService: HelperService,
    private router: Router,
  ) {}

  ngOnInit() {}

  getInstant(redirect = false) {
    this.results = Array<SuggestTermResult>();
    const promise = new Promise((resolve, reject) => {
      if (this.searchTerm) {
        this.searchService
          .searchInstant(this.searchTerm)
          .subscribe((response: ApiResponse) => {
            if (response && response.success && response.result && response.result.length) {
              const article = response.result[0] as Article;
              const framenumber =
                this.searchTerm &&
                this.searchTerm !== article.single_article_code
                  ? this.searchTerm
                  : "";
              this.router.navigateByUrl(
                `${this.applicationService.getSelectCompanyGroupCode()}/articleDetails/${
                  article.id
                }/${
                  article.single_article_code ? article.single_article_code : ""
                }${framenumber ? `?framenumber=${framenumber}` : ""}`
              );
            } else {
              resolve(true);
            }
          });
      }
    });
    return promise;
  }

  getSuggestions(loadInstantResult = false) {
    this.results = Array<SuggestTermResult>();
    if (this.searchTerm) {
      this.searchService
        .getTerms(this.searchTerm)
        .subscribe((response: ApiResponse) => {
          this.loaded = true;
          if (this.helperService.checkResponse(response) && response.result.length) {
            this.results = response.result;
          } else if (loadInstantResult) {
            this.getInstant(true);
          }
        });
    }
  }

  resultSelected(text) {
    this.location.go(
      this.applicationService.getSelectCompanyGroupCode() + `/search/${text}`
    );
    this.showTerms = false;
    this.getResult.emit(text);
  }
}
