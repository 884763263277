import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { timer } from 'rxjs';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
  animations: [
    trigger(
      'scale',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, transform: "scale(0)" }),
            animate('300ms ease-out',
                    style({   opacity: 1, transform:  "scale(1)" }))
          ]
        ),
        transition(
          ':leave',
          [
            style({  opacity: 1, "z-index": 0 }),
            animate('200ms ease-in',
                    style({opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class NoResultsComponent implements OnInit {
  show = false;
  @Input() title = "NO_SEARCHRESULTS"
  @Input() icon = "search"

  constructor() { }

  ngOnInit(): void {
    timer(0).subscribe(() => {
      this.show = true
    })
  }

}
