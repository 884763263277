import { Component, Input } from '@angular/core';
import { PartnerPortalSettings } from 'src/app/interfaces/partner-portal/partner-portal-settings';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-partner-portal-logo',
  templateUrl: './partner-portal-logo.component.html',
  styleUrl: './partner-portal-logo.component.scss'
})
export class PartnerPortalLogoComponent {
  @Input() isLink: boolean;
  customLogoSrc: string;

  constructor(private settingsService: SettingsService) {

  }

  ngOnInit() {
    this.settingsService.getSettings().subscribe((settings: PartnerPortalSettings) => {
      this.customLogoSrc = settings.store_logo_url
    })
  }
}
