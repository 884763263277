export interface LanguageItem {
    description: string;
    code: string;
}

export class LanguageItem implements LanguageItem {
    constructor(code: string, description: string) {
        this.code = code;
        this.description = description;
    }
}
