<div class="d-flex balance-data" *ngIf="numResults.balance_due || numResults.balance_due == 0 ">
  <div>
    <div class="data-header">
      {{ 'BALANCE_EXPIRED' | translate }}
    </div>
    <div class="data-value">
      {{ numResults.balance_expired }}
    </div>
  </div>
  <div>
    <div class="data-header">
      {{ 'BALANCE_DUE' | translate }}
    </div>
    <div class="data-value">
      {{ numResults.balance_due }}
    </div>
  </div>
  <div *ngIf="numResults.limit">
    <div class="data-header">
      {{ 'CREDIT_LIMIT' | translate }}
    </div>
    <div class="data-value">
      {{ numResults.limit }}
    </div>
  </div>
</div>
