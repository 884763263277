<div class="row">
  <div class="col-lg-2">
    <div class="mb-3 mt-2" marginTop="120">
      <label>{{'SEARCH' | translate}}</label>
      <input type="search" class="form-control" [(ngModel)]="filters.search_text" (keyup.enter)="getNews(true)"
        (click)="detectClear()">
    </div>
  </div>
  <div class="col-lg-10 row px-0 mx-0 mx-lg-0">
    <ng-container *ngIf="totalResults > 0; else noResults">
      <ng-container *ngFor="let newsItem of newsItems">
        <app-news-item  [newsItem]="newsItem" [useLazyLoad]="true" *ngIf="newsItem" [setLocation]="true"
          [active]="newsItem.id == newsId" class="col-md-6 col-lg-4 px-lg-2">{{newsItem.id}} {{newsId}}
        </app-news-item>
      </ng-container>
    </ng-container>
    <ng-template #noResults>
      <p #noResults>
        <app-no-results *ngIf="!loading"></app-no-results>
      </p>
    </ng-template>
    <button class="btn btn-block btn-dark clickable fw-bold my-2 mb-5" (click)="showMore()" [disabled]="loading"
      [ngClass]="loading ? 'disabled' : ''" *ngIf="totalResults > newsItems.length">{{'SHOW_MORE'|translate}}</button>
  </div>
</div>
