<div *ngIf="article">
  <app-article-image class="d-inline-block mt-2 w-100 text-center thumbnail" [articleType]="article.article_type"
    [imageUrl]="thumbnail" [useLazyLoad]="false" [description]="article.description"
    (imageClick)="gotoItem($event, article)" [classList]="''"></app-article-image>
  <div class="card-body">
    <a href="#" (click)="gotoItem($event, article)">
      <h5 class="card-title">{{article.description}}
        <small
          [innerHTML]="(article.caption ? article.caption : '') + ' ' + (article.single_article_code?article.single_article_code:'')"></small>
        <app-highlight-badge [article]="article"
        *ngIf="article.stock_status < 5 || article.setitem || article.is_lease" class="absolute"></app-highlight-badge>
      </h5>
    </a>
    <div class="card-text">
      <div>
        <app-inline-matrix *ngIf="article.matrix" [article]="article" [options]="matrixOptions"></app-inline-matrix>
      </div>
      <h4 *ngIf="article.retail_price">
        <app-retail-price [price]="article.retail_price" [showLabel]="true"></app-retail-price>
      </h4>
      <h5 [style.display]="article.gross_price ? '' : 'none'">
        <app-gross-price [price]="article.gross_price" [showLabel]="true"></app-gross-price>
      </h5>
      <h4 *ngIf="!article.hide_nett_price">
        <app-nett-price [price]="article.net_price" [hasDifferentPrices]="article.has_different_prices">
        </app-nett-price>
      </h4>
      <div class="form-row">
        <div class="mb-3 col-md-12">
          <label>{{'REMARKS' | translate }}</label>
          <input type="text" class="form-control" [(ngModel)]="article['remarks']">
        </div>
      </div>
      <div class="form-row d-flex">
        <div class="mb-3 col-md-8">
          <label>{{'QUANTITY' | translate }}</label>
          <input type="number" class="form-control"  [min]="article.min_order_amount ? article.min_order_amount : 0" max="9999"  [(ngModel)]="article['quantity']" style="max-width: 80px;">
        </div>
        <div class="mb-3 col-md-4">
          <label>{{'SOLD' | translate }}</label>
          <div class="form-check form-switch mt-1">
            <input type="checkbox" class="form-check-input form-check-" [id]="'sold_' + article.id"
              [(ngModel)]="article.sold">
            <label class="form-check-label" [for]="'sold_' + article.id"></label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
