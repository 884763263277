import { Component, Input, OnInit } from '@angular/core';
import { SbUrl } from 'src/app/interfaces/storyblok/sb-url';

@Component({
  selector: "app-link-button",
  templateUrl: "./link-button.component.html",
  styleUrls: ["./link-button.component.css"],
})
export class LinkButtonComponent implements OnInit {
  @Input() _uid: string;
  @Input() component: any;
  @Input() text: string;
  @Input() type: string = "primary";
  @Input() link_target: string = "";
  @Input() link: SbUrl;
  @Input() _editable: any;
  isInternal = false;
  redirect = false;
  constructor() {}

  ngOnInit(): void {
    this.checkIfIsInternal();
    this.checkIfIsRedirect();
  }

  checkIfIsInternal() {
    if (!this.link.url.includes("http") && !this.link.url.includes("mailto")) {
      this.isInternal = true;
    }
  }

  checkIfIsRedirect() {
    if (this.link.url.includes("/external")) {
      this.redirect = true;
    }
  }

  redirectToUrl() {
    window.location.href = decodeURI(this.link.url);
  }
}
