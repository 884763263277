import { Component, OnInit } from "@angular/core";
import { GroupedWarrantyArticles } from "src/app/interfaces/warranty/grouped-warranty-articles";
import { ApplicationService } from "src/app/services/application.service";
import { WarrantyService } from "src/app/services/warranty.service";
import { WarrantyArticle } from "src/app/interfaces/warranty/warranty-article";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AccountService } from "src/app/services/account.service";
import { AccountInformation } from "src/app/interfaces/account-information";
import { saveAs } from 'file-saver';
@Component({
  selector: "app-warranty-labels",
  templateUrl: "./warranty-labels.component.html",
  styleUrls: ["./warranty-labels.component.scss"],
})
export class WarrantyLabelsComponent implements OnInit {
  pdfBlob: any;
  pdfSrc: string;
  regularLogo = "dhl.svg";
  id: number;
  groupedArticles: GroupedWarrantyArticles;
  constructor(
    private applicationService: ApplicationService,
    private warrantyService: WarrantyService,
    private accountService: AccountService,
    public modal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.getAccountInformation();
  }

  showLabels(type: string) {
    this.getLabel(type);
  }

  getAccountInformation() {
    this.accountService
      .getAccountInformation()
      .then((accountInformation: AccountInformation) => {
        if (accountInformation.country_code === "DK") {
          this.regularLogo = "jetpak.png";
        } else {
          this.regularLogo = "dhl.svg";
        }
      });
  }

  showArticleLabel(type: string, article: WarrantyArticle) {
    this.applicationService.showLoader(true);
    this.warrantyService
      .getArticleLabel(type, this.id, article.warranty_article_id)
      .subscribe((blob: Blob) => {
        this.applicationService.hideLoader();
        this.generateUrl(blob);
      });
  }

  // Get pdf from API
  getLabel(type: string) {
    this.applicationService.showLoader(true);
    this.warrantyService.getLabel(type, this.id).subscribe((blob: Blob) => {
      this.applicationService.hideLoader();
      this.generateUrl(blob);
    });
  }

  // Generate pdf
  generateUrl(blob) {
    const file = new Blob([blob], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    this.pdfSrc = fileURL;
    this.pdfBlob = file;
    this.download();
  }

  // Download file
  download() {
    saveAs(this.pdfBlob, `label-${this.id}.pdf`);
  }
}
