import { Injectable, NgModule } from "@angular/core";
import { EMPTY } from "rxjs";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpUserEvent,
  HttpResponse,
  HttpProgressEvent,
  HttpHeaderResponse,
  HttpSentEvent,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AuthenticateService } from "../services/authenticate.service";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import {
  catchError,
  switchMap,
  finalize,
  filter,
  take,
  tap,
} from "rxjs/operators";
import { ApplicationService } from "../services/application.service";
import { AlertService } from "../services/alert.service";
import { nanoid } from "nanoid";
import Bugsnag from "@bugsnag/js";
import "./polyfills.js";
import { environment } from "src/environments/environment";
import { ApiResponse } from "../interfaces/api-response";
import { TokenContainer } from "../interfaces/tokencontainer";
import { StoryblokService } from "../services/storyblok.service";
import { TranslateService } from "@ngx-translate/core";
@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
  bugsnagClient: any;
  mode = "";
  isRefreshing: any;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private authService: AuthenticateService,
    private applicationService: ApplicationService,
    private alertService: AlertService,
    private storyblokService: StoryblokService,
    private translateService: TranslateService,
  ) {}

  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  // Intercept all http requests and refresh on 401
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    if (request !== null) {
      const formattedRequest = this.formatRequest(request, false);
      const requestId =
        formattedRequest && formattedRequest.headers
          ? formattedRequest.headers.get("fe-request-id")
          : "";
      return next.handle(formattedRequest).pipe(
        catchError((httpError) => {
          if (httpError instanceof HttpErrorResponse) {
            this.applicationService.hideLoader();
            let errorMessage = "Error";
            if (httpError.message) {
              errorMessage = httpError.message;
            } else if (httpError.error.result.title) {
              errorMessage = httpError.error.result.title;
            } else if (httpError.error.result) {
              errorMessage = httpError.error.result;
            }

            switch ((<HttpErrorResponse>httpError).status) {
              case 401:
                if (request.url.includes("refresh")) {
                  this.authService.logout();
                  return EMPTY;
                } else {
                  return this.handle401Error(request, next);
                }

              case 404:
                  if (request.url.includes("pdf")) {""
                    this.alertService.showPopup(this.translateService.instant("ERROR_DOWNLOADING"), 'error', 0);
                    return EMPTY;
                  }


              case 500:
                // Display error
                this.alertService.showErrorNotification(errorMessage);
                this.applicationService.emitRequestError.next({
                  error: httpError.error,
                  url: request.url,
                });

                return Promise.reject(errorMessage);
              default:
                if (httpError && httpError.status) {
                  Bugsnag.notify(
                    new Error(
                      `XHR error:  ${
                        httpError.status + " - Message: " + errorMessage
                      } Account: ${localStorage.getItem("accountCode")} url: ${
                        httpError.url
                      }`
                    ),
                    (event) => {
                      if (requestId) {
                        event.addMetadata("fe-request-id", {
                          value: requestId,
                        });
                      }
                    }
                  );
                  event.preventDefault();
                  EMPTY;
                }
            }
          } else {
            // Log the error
            // return throwError(httpError);
          }
        })
      );
    } else {
      return null;
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      this.applicationService.showLoader();
      const refreshContainer = new TokenContainer();
      refreshContainer.bearer_token = localStorage.getItem("bearerToken");
      refreshContainer.refresh_token = localStorage.getItem("refreshToken");

      if (refreshContainer.refresh_token) {
        return this.authService.refreshToken(refreshContainer).pipe(
          switchMap((json: ApiResponse) => {
            this.isRefreshing = false;
            this.applicationService.hideLoader();
            if (json && json.result && json.result.bearer_token) {
              const tokenContainer = json.result as TokenContainer;

              this.authService.saveTokens(tokenContainer);
              this.refreshTokenSubject.next(tokenContainer.bearer_token);
              this.applicationService.checkForNewVersion(
                tokenContainer.version
              );

              return next.handle(this.formatRequest(request, true));
            } else {
              this.authService.logout();
            }
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.applicationService.hideLoader();
            this.authService.logout();
            return throwError(err);
          })
        );
      } else {
        this.authService.logout();
        this.applicationService.hideLoader();
      }
    }
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.formatRequest(request, true)))
    );
  }

  // Formats the request with parameters, tokens and other stuff
  formatRequest(
    request: HttpRequest<any>,
    afterError: boolean
  ): HttpRequest<any> {
    request = this.setDefaultRequest(request);
    if (
      !request.url.includes(environment.apiendpoint) ||
      this.checkApiInclusion(request, "/refresh") ||
      (this.checkApiInclusion(request, "/authorize") &&
        !this.checkApiInclusion(request, "/authorizelinkedaccount")) ||
      (this.checkApiInclusion(request, "/reset") &&
        !this.checkApiInclusion(request, "/user") &&
        !this.checkApiInclusion(request, "/resetokta")) ||
      this.checkApiInclusion(request, "forgotpassword") ||
      this.checkApiInclusion(request, "/connect") ||
      this.checkApiInclusion(request, "/changepassword") ||
      this.checkApiInclusion(request, "/canactivate") ||
      this.checkApiInclusion(request, "/version") ||
      this.checkApiInclusion(request, "/migrateaccount") ||
      this.checkApiInclusion(request, "/updateactivationusername") ||
      this.checkApiInclusion(request, "/activate?nh=true") ||
      this.checkApiInclusion(request, "/linkaccount") ||
      this.checkApiInclusion(request, "/translations") ||
      request.url.includes("nh=true")
    ) {
      return request;
    }

    // Which token to use
    const token =
      !environment.production &&
      this.storyblokService.isInEditorMode() &&
      this.storyblokService.isOnPreviewPage()
        ? environment.storyblokAccentryPreviewToken
        : `Bearer ${this.authService.getToken()}`;

    if (token != null) {
      request = this.addTokenToRequest(request, token, afterError);
      request = this.addCompanyGroupCodeToRequest(request);
      request = this.addRequestId(request);

      return request;
    } else {
      this.authService.logout();
    }
  }

  checkApiInclusion(request: HttpRequest<any>, keyWord: string) {
    const url = request.url;
    if (url) {
      const baseUrl = url.split("?")[0];
      if (baseUrl.includes(keyWord)) {
        return true;
      } else {
        return false;
      }
    }
  }

  // Removes the credentials from the request
  setDefaultRequest(request: HttpRequest<any>): HttpRequest<any> {
    const headers = {
      version:
      localStorage.getItem("version") !== "undefined" &&
      localStorage.getItem("version") !== null
          ? localStorage.getItem("version")
          : "unknown",
    };

    return request.clone({
      withCredentials: false,
      setHeaders: headers,
    });
  }

  // Adds the company group code as querystring parameter
  addCompanyGroupCodeToRequest(request: HttpRequest<any>): HttpRequest<any> {
    const companyGroupCode =
      this.applicationService.getSelectCompanyGroupCode();
    const languageCode = this.applicationService.getSelectedLanguage();

    var url = request.url;
    if (companyGroupCode != null && !request.url.includes("companyGroupCode")) {
      const token = url.indexOf("?") > -1 ? "&" : "?";

      url = url + `${token}companyGroupCode=${companyGroupCode}`;
    }

    if (languageCode != null) {
      const token = url.indexOf("?") > -1 ? "&" : "?";

      url = url + `${token}languageCode=${languageCode}`;
    }

    return request.clone({
      url: url,
    });
  }

  // Add an unique Id to each api request
  private addRequestId(request: HttpRequest<any>): HttpRequest<any> {
    const headers = {
      "fe-request-id": nanoid(),
    };

    return request.clone({
      setHeaders: headers,
    });
  }

  // Add the authentication token to the header
  private addTokenToRequest(
    request: HttpRequest<any>,
    token: string,
    afterError: boolean
  ): HttpRequest<any> {
    let accept = "application/json";
    let responseType = "json";
    let ignoreLoader: boolean;
    let basketVersion = 1;

    if (request.url.endsWith("t=excel")) {
      accept =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      responseType = "blob";
    }

    if (request.url.endsWith("fileType=pdf")) {
      accept = "application/octet-stream";
      responseType = "blob";
    }

    const headers = {
      Authentication: token,
      accept: accept,
      "x-mode": this.mode,
    };

    if (
      (request.url.includes("v=2.0") ||
        request.url.includes("articles/") ||
        (request.url.includes("/search") && !request.url.includes("/financial")) ||
        (request.url.includes("/models") && !request.url.includes("/baskets")) ||
        request.url.includes("/images") ||
        request.url.includes("/articles") ||
        request.url.includes("/promotions") ||
        request.url.includes("/favorites/") ||
        request.url.includes("/pricelabels") ||
        request.url.includes("/notifications/") ||
        request.url.includes("/models/netprices")) &&
      !request.url.includes("v=1.0")
    ) {
      headers["X-version"] = "2.0";
    }

    // Test V2 baskets
    if (this.applicationService.isV2Basket() && (request.url.includes("/baskets") || request.url.includes("/dropshipment"))) {
      headers["X-version"] = "2.0";
      basketVersion = 2;
    }

    // Safer to implement this backend side (via a property)
    if (window.location.href.includes("/kiosk")) {
      headers["x-mode"] = "kiosk";
    }

    if (localStorage.getItem("showroomMode") == "true") {
      headers["x-showroom-mode"] = "true";
    }

    // Exclude some api's from showing the loader
    const excludeLoading = [
      "/netprices",
      "/items",
      "/search",
      "/models",
      "/articles",
      "/translations",
      "/promotions",
    ];
    if (excludeLoading.some((el) => this.checkApiInclusion(request, el))) {
      headers["ignoreLoadingBar"] = "";
    }

    if (request.url.includes("rt=1")) {
      headers["retryRequest"] = "1";
    }


    return request.clone({
      setHeaders: headers,
      responseType: responseType === "blob" ? "blob" : "json",
      url: afterError
        ? request.url + (request.url.includes("?") ? "&" : "?") + "rt=1"
        : request.url,
    });
  }
}

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
  ],
})
export class InterceptorModule {}
