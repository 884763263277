<div>
  <ng-container>
    <ng-container *ngIf="(!showSelected)">
      <div class="row mx-0 mb-1 border" [ngClass]="[group.articles.length === 1 ? 'preselected' : '']">
        <div class="col-md-1 group-code pe-1">
          {{group.code}}
        </div>

        <!-- Groups with no selected article -->
        <div class="col-md-11 px-0">
          <ul class="list-group list-group-flush">
            <ng-container *ngIf="(!showSelected && group.selectedArticle)">
              <li class="list-group-item py-0 fw-bold ps-1 pe-0"> {{ (group.articles.length > 1 ? 'SELECTED' :
                'PRESELECTED') |
                translate}}
                <small class=" me-1 btn btn-sm btn-dark clickable withOpacity"
                  *ngIf="group.articles.length > 1" (click)="removeCheckArticle(group.selectedArticle)">{{'RESET'
                  | translate}}</small>
              </li>
            </ng-container>
            <ng-container *ngIf="(!showSelected && !group.selectedArticle)">
              <li *ngFor="let article of articles; let itemIndex = index" class="list-group-item py-0 px-0">
                <app-tree-view-item [showPrices]="true" [article]="article" [showArticleCode]="true" [index]="itemIndex" [imageSize]="48" [showCheckbox]="isDach || group.articles.length > 1"
                  (checkArticle)="selectArticle($event)"></app-tree-view-item>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- Groups with a selected article -->
  <ng-container *ngIf="(showSelected && group.selectedArticle) ">
    <div class="row mx-0 mt-b border selected-articles" [ngClass]="[group.articles.length === 1 ? 'preselected' : '']">
      <div class="col-md-1 group-code pe-1">
        {{group.code}}
      </div>
      <div class="col-md-11 px-0">
        <ul class="list-group list-group-flush">
          <li class="list-group-item py-0 px-0">
            <div class="row mx-0">
              <div class="col-md-12 px-0">
                <app-tree-view-item [showPrices]="true" [showArticleCode]="true" [article]="group.selectedArticle" [index]="0" [showCheckbox]="isDach || group.articles.length > 1"
                  (checkArticle)="removeCheckArticle($event, group.selectedArticle)"></app-tree-view-item>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
</div>
