import { BasketArticles } from "./basket-articles";

export interface GroupedBasketArticlesByMonth {
  1: BasketArticles;
  2: BasketArticles;
}

export class GroupedBasketArticlesByMonth
  implements GroupedBasketArticlesByMonth {
  constructor() {}
}
