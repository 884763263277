import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ArticleGroup } from 'src/app/interfaces/articleGroup';
import { FilterOption } from 'src/app/interfaces/filter-option';
import { FilterGroup } from 'src/app/interfaces/filterGroup';
import { PriceRangeFilter } from 'src/app/interfaces/price-range-filter';
import { StockweekFilter } from 'src/app/interfaces/stockweek-filter';
import * as _ from 'underscore';
import { SideBarComponent } from '../side-bar/side-bar.component';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
})
export class FilterDialogComponent {
  @Input() priceRangeData: PriceRangeFilter;
  @Input() stockweekData: StockweekFilter;
  @Input() availableFilters: [FilterGroup];
  @Input() showStockFilter: boolean = true;
  @Input() numResults = 0;
  @Input() selectedFilterList = [];

  @Output() filtersUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() groupsUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() stockweeksUpdated: EventEmitter<StockweekFilter> =
    new EventEmitter<StockweekFilter>();
  @Output()
  filterWithKeyword: EventEmitter<string> = new EventEmitter<string>();
  @Output() showResults: EventEmitter<any> = new EventEmitter<any>();
  @Input() groups: [ArticleGroup];
  @Input() sidebarTop: number;
  filters: FilterGroup[];
  collapsed: boolean;
  ts: number = 0;
  keyword: string = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['availableFilters']) {
      this.filters = _.map(this.availableFilters, _.clone);
      this.collapsed = false;
      this.collapseFilters(true);
      this.ts++;
    }
  }

  public filterUpdate($event) {
    this.filtersUpdated.emit($event);
  }

  ngOnInit() {
    this.setFilters();
  }

  setFilters() {
    const filters = _.map(this.availableFilters, _.clone);
    this.filters = filters;
    this.collapsed = false;
    this.collapseFilters(true);
    this.ts++;
  }

  collapseFilters(onlyWithNoneSelected = false) {
    if (!this.collapsed) {
      let filters = [];
      if (onlyWithNoneSelected) {
        filters = _.map(this.availableFilters, _.clone);
        filters.forEach((filter: FilterGroup) => {
          let hasSelectedOption = false;
          filter.options.forEach((o: FilterOption) => {
            if (typeof o.value !== 'undefined') {
              hasSelectedOption = true;
            }
          });
          filter.collapse = !hasSelectedOption;
        });
      } else {
        filters = _.map(this.availableFilters, _.clone);
        filters.forEach((filter: FilterGroup) => {
          filter.collapse = true;
        });
      }

      this.filters = filters;
    } else {
      this.collapsed = false;
    }
  }

  checkKeyword() {
   // if (!this.keyword) {
      this.filters.forEach((fg: FilterGroup) => {
        fg.collapse = true;
      });
  //  }
  }
}
