import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ApplicationService } from "src/app/services/application.service";
import { BasketService } from "src/app/services/basket.service";
import { Basket } from "src/app/interfaces/basket";
import { BasketAction } from "src/app/interfaces/basket-action";
import moment from "moment";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-basket-item",
  templateUrl: "./basket-item.component.html",
  styleUrls: ["./basket-item.component.scss"],
})
export class BasketItemComponent implements OnInit {
  @Input() basket: Basket;
  @Input() preorder: boolean;
  @Input() deleted: boolean;
  @Input() basePage: string = "baskets";
  @Output() basketDeleted: EventEmitter<any> = new EventEmitter<any>();
  companyGroupCode = "";
  showroomMode: boolean;

  get basketUrl() {
    let base = "";
    if (this.basketService.basketHasBeenOrdered(this.basket)) {
      base = "/order/"
    } else {
      if (this.basket.pre_order) {
        base = "/preorderBasket/"
      } else {
        base = "/basket/"
      }
    }

    if (this.basket.pre_order && !this.basketService.basketHasBeenOrdered(this.basket)) {
      base += `${this.basket.preorder_type === "Parts" ? "Parts" : "Bikes"}/`
    }

    return `${base}${this.basket.id}/`
  }

  constructor(
    private applicationService: ApplicationService,
    private basketService: BasketService,
    private helperService: HelperService
  ) {
     this.getShowroomMode();
  }


  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  ngOnInit() {
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
    this.applicationService.changeEmitted$.subscribe((response) => {
      this.getShowroomMode();
    });
  }

  deleteBasket() {
    this.basketService.deleteBasket(this.basket.id).subscribe((response) => {
      if (this.basket.active) {
        localStorage.removeItem("activeBasketId");
        const basketAction = new BasketAction();
        basketAction.type = "deleteBasket";
        this.basketService.emitChange(basketAction);
      }
      this.basketDeleted.emit(this.basket);
    });
  }

  unlockBasket() {
    this.basketService.unlockBasket(this.basket.id);
    this.basket.locked = false;
  }

  getLastEditTime(unformattedDateTime: string) {
    return this.helperService.getLocalDateTime(unformattedDateTime);
  }

  checkIfOrdered() {
    return this.basketService.basketHasBeenOrdered(this.basket);
  }
}
