import { Component, OnInit } from "@angular/core";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-error-page",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.scss"]
})
export class ErrorPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  constructor(
    private translateService: TranslateService,
    private applicationService: ApplicationService
  ) {}

  ngOnInit() {
    this.setBreadcrumbs();
  }

  setBreadcrumbs() {
    // Set breadcrumbs
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("NOT_FOUND"),
        this.applicationService.getSelectCompanyGroupCode() + "/notfound"
      )
    ];
  }
}
