<div class="sum totalSum" *ngIf="hasLimit">
  <ul class="pe-2 list-group list-group-flush col-md-12 px-2">
    <li class="px-2 border-0 list-group-item px-md-0 bg-transparent">
      <div class="row text-start px-2 opacity-50">
        <i class="d-block"
          >{{ "BALANCE_EXPIRED" | translate }}:
          <span innerHTML="{{ balanceDue | currencyConvert : currency }}"></span
        ></i>
        <i class="d-block"
          >{{ "CREDIT_LIMIT" | translate }}:
          <span
            innerHTML="{{ creditLimitAmount | currencyConvert : currency }}"
          ></span>
        </i>
        <i class="d-block"
          >{{ "AVAILABLE_CREDIT" | translate }}:
          <span
            innerHTML="{{ creditLimitAvailable | currencyConvert : currency }}"
          ></span
        ></i>
      </div>
    </li>
  </ul>
</div>
