import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-select-for-warranty-shipment",
  templateUrl: "./select-for-warranty-shipment.component.html",
  styleUrls: ["./select-for-warranty-shipment.component.scss"]
})
export class SelectForWarrantyShipmentComponent implements OnInit {
  @Input() name: string;
  @Input() selectedValue: boolean;
  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit() {}

  valueChanged(element) {
    this.changed.emit(this.selectedValue);
  }
}
