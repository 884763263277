import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { AvailableAgreementTypes } from "../interfaces/yearAgreement/available-agreement-types";
import { AgreementOption } from "../interfaces/yearAgreement/agreement-option";
import { AccountAgreement } from "../interfaces/yearAgreement/account-agreement";
import { environment } from "../../environments/environment";
import { Observable, Subject } from "rxjs";
import { ApiResponse } from "../interfaces/api-response";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { YearAgreementParts } from "../interfaces/yearAgreement/year-agreement-parts";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "./alert.service";
import { YearAgreementDach } from "../interfaces/yearAgreement/year-agreement-dach";
import { HelperService } from "./helper.service";
@Injectable({
  providedIn: "root",
})
export class YearAgreementService {
  agreementOptions = {};
  private emitDealerTypeChangeSource = new Subject<string>();
  private emitTurnoverChangeSource = new Subject<boolean>();
  changeEmitted$ = this.emitDealerTypeChangeSource.asObservable();
  changeTurnoverEmitted$ = this.emitTurnoverChangeSource.asObservable();
  cached: any = {};
  modelCache: any = {};
  public maxPartBrandSelection = null;
  public dealerPartnerSettingsForm: FormGroup;
  yearAgreementParts: YearAgreementParts;
  yearAgreementDach: YearAgreementDach;
  discount: number;
  locked: boolean;
  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private translateService: TranslateService,
    private helperService: HelperService
  ) {}

  createAgreement(accountAgreement: AccountAgreement) {
    return this.http.post(
      `${environment.apiendpoint}yearagreements`,
      accountAgreement
    );
  }

  dealerTypeChange(selected: string) {
    this.emitDealerTypeChangeSource.next(selected);
  }

  turnoverChange() {
    this.emitTurnoverChangeSource.next(true);
  }

  updateAgreement(accountAgreement: AccountAgreement) {
    return this.http.put(
      `${environment.apiendpoint}yearagreements`,
      accountAgreement
    );
  }

  collectAgreementOptions(
    type: AvailableAgreementTypes,
    options: AgreementOption[]
  ) {
    this.agreementOptions[type] = options;
  }

  getAccountAgreements() {
    return this.http.get(`${environment.apiendpoint}yearagreements`);
  }

  getAccountOpenAgreements(type: string = "") {
    return this.http.get(
      `${environment.apiendpoint}yearagreements${
        type !== "bikes" ? type : ""
      }/open`
    );
  }

  getAccountAgreement(id: number) {
    return this.http.get(`${environment.apiendpoint}yearagreements/${id}`);
  }

  getAccountAgreementAffiliates() {
    return this.http.get(`${environment.apiendpoint}yearagreements/affiliates`);
  }

  getAgreementTexts(year: string) {
    return this.http.get(
      `${environment.apiendpoint}yearagreements/texts/${year}`
    );
  }

  getGroups(type: AvailableAgreementTypes, year: number) {
    const key = `${type}${year}`;
    if (typeof this.cached[key] === "undefined") {
      const xhr = new Promise((resolve) => {
        this.http
          .get(
            `${environment.apiendpoint}yearagreements/groups/${type}/${year}`
          )
          .subscribe((apiResponse: ApiResponse) => {
            resolve(apiResponse);
          });
      });
      this.cached[key] = xhr;
    }
    return this.cached[key];
  }

  getAgreementBikeModels(type: string, year: number) {
    const key = `${type}_${year}}`;
    if (typeof this.modelCache[key] === "undefined") {
      this.modelCache[key] = new Promise((resolve) => {
        this.http
          .get(`${environment.apiendpoint}yearagreements/${type}/${year}`)
          .subscribe((apiResponse: ApiResponse) => {
            resolve(apiResponse);
          });
      });
    }
    return this.modelCache[key];
  }

  getAgreementPdf(id: number) {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: "application/pdf",
      }),
      responseType: "blob" as "json",
    };

    return this.http.get(
      `${environment.apiendpoint}yearagreements/${id}/pdf?fileType=pdf`,
      HTTPOptions
    );
  }

  // Sales person submits the agreement to the dealer
  sendAgreement(id: number) {
    return this.http.post(
      `${environment.apiendpoint}yearagreements/${id}/send?t=hlp`,
      {
        id: id,
      }
    );
  }

  // The dealer signs the agreement
  signAgreement(
    id: number,
    dealerName: string,
    contractEmail: string,
    type: string = ""
  ) {
    return this.http.post(
      `${environment.apiendpoint}yearagreements${
        type !== "bikes" ? type : ""
      }/${id}/sign`,
      {
        id: id,
        dealer_name: dealerName,
        contract_email: contractEmail,
      }
    );
  }

  reopenAgreement(accountCode: string, id: number) {
    return this.http.post(
      `${environment.apiendpoint}yearagreements/account/${accountCode}/${id}/reopen?t=hlp`,
      {
        id: id,
      }
    );
  }

  // Revoke the agreement so the dealer doesn't get to see it anymore
  revokeAgreement(id: number) {
    return this.http.post(
      `${environment.apiendpoint}yearagreements/${id}/revoke`,
      {
        id: id,
      }
    );
  }

  resetPartsAgreement() {
    this.yearAgreementParts = new YearAgreementParts();
  }

  // Parts agreements (open)
  getPartsOpenAgreements(accountCode: string) {
    return this.http.get(`${environment.apiendpoint}yearagreementsparts/open`);
  }

  // Parts agreements (all)
  getPartsAgreements() {
    return this.http.get(`${environment.apiendpoint}yearagreementsparts`);
  }

  // Parts get agreement by id
  getPartsAgreement(agreementId: number) {
    return this.http
      .get(`${environment.apiendpoint}yearagreementsparts/${agreementId}`)
      .subscribe((response: ApiResponse) => {
        if (response && response.result) {
          this.yearAgreementParts = response.result;
          this.setDealerSettings();
        }
      });
  }

  newPartsAgreement(accountCode: string) {
    const yearAgreementParts = new YearAgreementParts();
    yearAgreementParts.account_code = accountCode;
    this.createPartsAgreement(yearAgreementParts);
  }

  // Create parts agreement
  createPartsAgreement(yearAgreementParts: YearAgreementParts) {
    return this.http
      .post(`${environment.apiendpoint}yearagreementsparts`, yearAgreementParts)
      .subscribe((response: ApiResponse) => {
        if (response && response.success && response.result) {
          const yearAgreement = response.result;
          this.yearAgreementParts = yearAgreement;
        } else {
          this.yearAgreementParts = new YearAgreementParts();
          this.alertService.showPopup(
            this.translateService.instant("ERROR_CREATING_AGREEMENT"),
            "error",
            4000
          );
        }
      });
  }

  // Update parts agreement by id
  updatePartsAgreement(yearAgreementParts: YearAgreementParts) {
    return new Promise((resolve) => {
      this.http
        .put(
          `${environment.apiendpoint}yearagreementsparts`,
          yearAgreementParts
        )
        .subscribe((response: ApiResponse) => {
          if (response && response.success) {
            resolve(true);
          } else {
            this.alertService.showErrorNotification(
              `${this.translateService.instant("ERROR")}`
            );
            resolve(true);
          }
        });
    });
  }

  getAgreementPdfParts(id: number) {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: "application/pdf",
      }),
      responseType: "blob" as "json",
    };

    return this.http.get(
      `${environment.apiendpoint}yearagreementsparts/${id}/pdf?fileType=pdf`,
      HTTPOptions
    );
  }

  // Sales person submits the agreement to the dealer
  sendAgreementParts(id: number) {
    return this.http.post(
      `${environment.apiendpoint}yearagreementsparts/${id}/send?t=hlp`,
      {
        id: id,
      }
    );
  }

  getPartsBrands(dealerLevel: string, dealerShip: string) {
    return this.http.get(
      `${environment.apiendpoint}yearagreementsparts/brands/${dealerLevel}/${dealerShip}`
    );
  }

  reopenPartsAgreement(accountCode: string, id: number) {
    return this.http.post(
      `${environment.apiendpoint}yearagreementsparts/account/${accountCode}/${id}/reopen?t=hlp`,
      {
        id: id,
      }
    );
  }

  setDealerSettings() {
    const level = this.yearAgreementParts.dealer_levels.find((b) => {
      return b.id == this.yearAgreementParts.commercial_agreements.dealer_level;
    });

    if (level) {
      this.maxPartBrandSelection = level.brands;
    }

    if (
      this.yearAgreementParts.commercial_agreements.dealer_level || this.yearAgreementParts.commercial_agreements.dealer_level === 0 &&
      this.yearAgreementParts.commercial_agreements.bike_dealer_ship
    ) {
      this.getPartsBrands(
        String(this.yearAgreementParts.commercial_agreements.dealer_level),
        this.yearAgreementParts.commercial_agreements.bike_dealer_ship
      ).subscribe((response: ApiResponse) => {
        const brands = response.result;

        if (level) {
          this.yearAgreementParts.commercial_agreements.dropshipment =
            level.dropshipment;
          this.yearAgreementParts.commercial_agreements.shipping_cost =
            level.shipping_cost;
          this.yearAgreementParts.commercial_agreements.shipping_schedule =
            level.shipping_schedule;

          this.discount = level.discount;
          this.yearAgreementParts.brands.forEach((b) => {
            const brand = brands.find((x) => {
              return x.id === b.id;
            });

            if (brand) {
              b.discount = brand.discount;
              b.value = b.selected ? brand.discount : null;
            }
          });
        }
      });
    }
  }

  //DACH
  newDachAgreement(accountCode: string, year: number) {
    const yearAgreementDach = new YearAgreementDach(accountCode, year);
    return this.createDachAgreement(yearAgreementDach);
  }

  // Create dach agreement
  createDachAgreement(yearAgreementDach: YearAgreementDach) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.apiendpoint}yearagreementsdach`, yearAgreementDach)
        .subscribe((apiResponse: ApiResponse) => {
          if (this.helperService.checkResponse(apiResponse)) {
            resolve(apiResponse.result as YearAgreementDach);
          } else {
            reject(apiResponse);
          }
        });
    });
  }

  updateDachAgreement(
    yearAgreementDach: YearAgreementDach
  ): Promise<ApiResponse> {
    return new Promise((resolve) => {
      this.http
        .put(`${environment.apiendpoint}yearagreementsdach`, yearAgreementDach)
        .subscribe((response: ApiResponse) => {
          if (response && response.success) {
            resolve(response);
          } else {
            this.alertService.showErrorNotification(
              `${this.translateService.instant("ERROR")}`
            );
            resolve(response);
          }
        });
    });
  }

  getAgreementPdfDach(id: number): Observable<any> {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: "application/pdf",
      }),
      responseType: "blob" as "json",
    };

    return this.http.get(
      `${environment.apiendpoint}yearagreementsdach/${id}/pdf?fileType=pdf`,
      HTTPOptions
    );
  }

  // Sales person submits the agreement to the dealer
  sendAgreementDach(id: number): Observable<any> {
    return this.http.post(
      `${environment.apiendpoint}yearagreementsdach/${id}/send?t=hlp`,
      {
        id: id,
      }
    );
  }

  reopenDachAgreement(accountCode: string, id: number): Observable<any> {
    return this.http.post(
      `${environment.apiendpoint}yearagreementsdach/account/${accountCode}/${id}/reopen?t=hlp`,
      {
        id: id,
      }
    );
  }

  getDachAgreements() {
    return this.http.get(`${environment.apiendpoint}yearagreementsdach`);
  }

  getDachAgreement(id: number) {
    return this.http.get(`${environment.apiendpoint}yearagreementsdach/${id}`);
  }
}
