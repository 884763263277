import { Component, OnInit } from "@angular/core";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import { HelpdeskService } from "src/app/services/helpdesk.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogComponent } from "src/app/components/modal-components/confirm-dialog/confirm-dialog.component";
import { AlertService } from "src/app/services/alert.service";
import { ApiResponse } from "src/app/interfaces/api-response";

@Component({
  selector: 'app-unmerge-dealer-page',
  templateUrl: './unmerge-dealer-page.component.html',
  styleUrls: ['./unmerge-dealer-page.component.scss']
})
export class UnmergeDealerPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  dealerID: string;
  isValidForUnmerge: boolean = false;
  confirm_body: string;
  errorMessages: [];
  submittedSuccessfully: boolean;
  constructor(
    private modalService: NgbModal,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private alertService: AlertService,
    private helpdeskService: HelpdeskService,
  ) {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/home"
      ),
      new Breadcrumb(this.translateService.instant("UNMERGE_DEALER"), "#")
    ];
  }

  ngOnInit() {}

  checkIfDealerIsValid() {
    // Logic for checking if dealer is valid for unmerging into 2 separate accounts
    this.isValidForUnmerge = false
    this.submittedSuccessfully = false;
    this.errorMessages = []
    this.confirm_body = this.translateService.instant("UNMERGE_WARNING");
    this.helpdeskService.getUnmergeInfo(this.dealerID)
      .subscribe((response: ApiResponse) => {
        if(response && response.result) {
          if(response.result.success) {
            this.isValidForUnmerge = true
            this.showConfirmDialog()
          } else {
            if(response.result.details) {
              this.alertService.showErrorNotification(this.translateService.instant("UNMERGE_UNSUCCESSFUL"))
              this.errorMessages = response.result.details
            }
          }
        }
    })
  }

  showConfirmDialog() {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      size: "lg",
      windowClass: "medium",
      container: "#modalContainer"
    });

    // Set dialog content
    this.confirm_body =  this.confirm_body.replace("[USER]", this.dealerID)
    modalRef.componentInstance.title = this.translateService.instant("UNMERGE_DEALER");
    modalRef.componentInstance.body = `<p>${this.confirm_body}</p>`
    modalRef.componentInstance.isConfirm = true;

    // Unmerge dealer
    modalRef.componentInstance.confirmClicked.subscribe(() => {
      this.helpdeskService.unMergeDealer(this.dealerID, this.isValidForUnmerge).subscribe((apiResponse: ApiResponse) => {
        if (apiResponse.success) {
          this.submittedSuccessfully = true;
          this.alertService.showInfoNotification(this.translateService.instant("UNMERGE_SUCCESSFUL"));
        } else {
          this.alertService.showErrorNotification(this.translateService.instant("SOMETHING_WENT_WRONG"))
        }
      })
    });
  }
}
