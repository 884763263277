import { PickupAddress } from "./pickup-address";

export interface SendWarrantyRequest {
  id: number;
  pickup_date: string;
  pickup_date_batteries: string;
  pickup_date_motor: string;
  weight: number;
  colli: number;
  ignore_article_types: string[];
  pickup_address: PickupAddress;
  pickup_address_batteries: PickupAddress;
  pickup_address_motor: PickupAddress;
}

export class SendWarrantyRequest implements SendWarrantyRequest {}
