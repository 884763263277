import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApplicationService } from "src/app/services/application.service";
import { AccountService } from "src/app/services/account.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { AuthenticateService } from "src/app/services/authenticate.service";
import { HelpdeskService } from "src/app/services/helpdesk.service";
import { TokenContainer } from "src/app/interfaces/tokencontainer";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { User } from "src/app/interfaces/user";
import { timer } from "rxjs";
import "url-search-params-polyfill";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})

/*
  Landing page to check if user is signed in
*/
export class LandingComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private authenticateService: AuthenticateService,
    private accountService: AccountService,
    private helpdeskService: HelpdeskService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const key = urlParams.get("key")
      ? urlParams.get("key")
      : this.route.snapshot.queryParamMap.get("key");

    if (key) {
      this.authenticateService.clearUserData();
      this.authenticateService
        .connect(key)
        .subscribe((tokenContainer: TokenContainer) => {
          if (tokenContainer) {
            // If internal / helpdesk account select the dealer first
            const promise = new Promise((resolve, reject) => {
              if (
                tokenContainer.account_type !== "DLR" &&
                tokenContainer.selected_dealer
              ) {
                const user = new User();
                user.user_id = tokenContainer.selected_userid;
                user.account_code = tokenContainer.selected_dealer;
                this.helpdeskService
                  .logInAsUser(user)
                  .then((response: ApiResponse) => {
                    if (this.helperService.checkResponse(response)) {
                      this.accountService
                        .getAccountSettings()
                        .subscribe((apiResponse: ApiResponse) => {
                          this.applicationService.hideLoader();
                          if (apiResponse.success) {
                            this.applicationService.saveAccountSettings(
                              apiResponse.result
                            );
                            resolve(true);
                          } else {
                            this.alertService.showErrorNotification(
                              this.translateService.instant("ERROR")
                            );
                            reject();
                            this.errorHandler();
                          }
                        });
                    } else {
                      this.errorHandler();
                    }
                  });
              } else {
                resolve(true);
              }
            });
            promise.then(() => {
              if (
                tokenContainer.language &&
                !localStorage.getItem("language")
              ) {
                this.applicationService.saveSelectedLanguage(
                  tokenContainer.language
                );
                this.translateService.use(tokenContainer.language);
              }

              if (tokenContainer.account_type) {
                localStorage.setItem(
                  "accountType",
                  tokenContainer.account_type
                );
              }

              this.accountService
                .getAvailableCompanyGroups()
                .subscribe((response: ApiResponse) => {
                  if (this.helperService.checkResponse(response)) {
                    this.applicationService.saveCompanyGroups(response.result);
                    const redirectUrl = this.route.snapshot.queryParamMap.get(
                      "redirectUrl"
                    )
                      ? this.route.snapshot.queryParamMap.get("redirectUrl")
                      : this.applicationService.getSelectCompanyGroupCode() +
                        "/home";
                    this.router.navigateByUrl(redirectUrl);
                  } else {
                    this.errorHandler();
                  }
                });
            });
          } else {
            this.alertService.showErrorNotification(
              this.translateService.instant("ERROR")
            );
            timer(500).subscribe(() => {
              this.errorHandler();
            });
          }
        });
    } else {
      if (!localStorage.getItem("bearerToken")) {
        const redirectUrl =
        this.route.snapshot.queryParamMap.get('redirectUrl');
        this.authenticateService.logout(redirectUrl ? true : false);
      } else {
        this.authenticateService.validLogin();

        const redirectUrl =
          this.route.snapshot.queryParamMap.get('redirectUrl');

        if (redirectUrl) {
          this.router.navigateByUrl(redirectUrl);
        } else {
          this.router.navigateByUrl(
            this.applicationService.getSelectCompanyGroupCode() +
              (this.accountService.getAccountType() === 'DLR' ||
              this.helpdeskService.getSelectedDealer() !== null
                ? '/home'
                : '/helpdesk')
          );
        }

      }
    }
  }

  private errorHandler() {
    this.authenticateService.clearUserData();
    this.authenticateService.logout();
  }
}
