<div class="row mx-0">
  <div class="col-md-6">
    <table class="table">
      <thead>
        <tr>
          <th class="border-top-0" colspan="2"> {{ 'TOTAL_ORDERS' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'BICYCLES' | translate}}</td>
          <td>{{ numResults.total_bicycles_lines}}</td>
        </tr>
        <tr>
          <td>{{ 'PARTS' | translate}}</td>
          <td>{{ numResults.total_parts_lines}}</td>
        </tr>
        <tr>
          <td>{{ 'SERVICE' | translate}}</td>
          <td>{{ numResults.total_service_lines}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-6">
    <table class="table">
      <thead>
        <tr>
          <th class="border-top-0" colspan="2"> {{ 'TOTAL_OPEN' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'BICYCLES' | translate}}</td>
          <td>{{ numResults.total_bicycles}}</td>
        </tr>
        <tr>
          <td>{{ 'PARTS' | translate}}</td>
          <td>{{ numResults.total_parts}}</td>
        </tr>
        <tr>
          <td>{{ 'SERVICE' | translate}}</td>
          <td>{{ numResults.total_service}}</td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
