<label class="d-block">{{'PICKUP_DATE'|translate}}</label>
<form class="form-inline">
  <div class="mb-3">
    <div class="input-group">
      <input class="form-control bg-white" [ngClass]="[disabled ? 'disabled' : '', !disabled && !selectedDate ? 'border-danger' : '']" placeholder="dd-mm-yyyy" readonly name="dp" [disabled]="disabled" (click)="!disabled ? d.toggle() : null"
        [(ngModel)]="selectedDate" ngbDatepicker #d="ngbDatepicker" [minDate]="minDate" [markDisabled]="isDisabled" [maxDate]="maxDate" (change)="dateChanged()">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" [ngClass]="disabled ? 'disabled' : ''"  (click)="!disabled ? d.toggle() : null" type="button">
          <i class="material-icons" style="font-size: 17px">date_range</i>
        </button>
      </div>
    </div>
  </div>
</form>
