import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NewsItem } from "src/app/interfaces/cms/news-item";
import { AlertItem } from "src/app/interfaces/cms/alert-item";
import { Router } from "@angular/router";
import { ConfirmDialogComponent } from "src/app/components/modal-components/confirm-dialog/confirm-dialog.component";
import { NewsDetailsComponent } from "src/app/components/cms/news-details/news-details.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { trigger, transition, style, animate } from "@angular/animations";
import { CmsService } from "src/app/services/cms.service";

@Component({
  selector: "app-alert-item",
  templateUrl: "./alert-item.component.html",
  styleUrls: ["./alert-item.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({ transform: "scale(0)", opacity: 0 }),
        animate("650ms", style({ transform: "scale(1)", opacity: 1 }))
      ]),
      transition(":leave", [
        style({ transform: "scale(1)", opacity: 1 }),
        animate(
          "350ms",
          style({ transform: "scale(0)", opacity: 0, height: 0 })
        )
      ])
    ])
  ]
})
export class AlertItemComponent implements OnInit {
  @Input() alert: AlertItem;
  @Input() isPreview: boolean;
  @Output() showNews: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private cmsService: CmsService
  ) {}

  ngOnInit() {}

  showAlert() {
    let internalUrl = false;
    const url = this.alert.language_definitions[0].link_url;
    let formattedUrl = url;

    if (
      (url && url.indexOf("accentry.com") >= 0) ||
      this.alert.language_definitions[0].url_type === 2
    ) {
      internalUrl = true;
      formattedUrl = url.replace("https://www.accentry.com/", "");
      formattedUrl = formattedUrl.replace("https://shop.accentry.com/", "");
    }

    if (internalUrl) {
      if (url.indexOf("news#details") >= 0) {
        const urlData = url.split("/");
        this.showNewsItem(urlData[urlData.length - 1]);
      } else if (this.alert.language_definitions[0].url_type === 2) {
        this.showNewsItem(this.alert.language_definitions[0].link_url);
      } else {
        formattedUrl = formattedUrl.replace("results", "search");
        this.router.navigateByUrl(formattedUrl);
      }
    } else if (formattedUrl) {
      window.open(formattedUrl, "_new");
    }
  }

  showNewsItem(newsId) {
    this.showNews.next(Number(newsId));
  }

  closeAlert() {
    this.cmsService.saveAlertClosed(this.alert);
    this.alert.closed = true;
  }
}
