import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Profile } from "src/app/interfaces/profile";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-profile-item",
  templateUrl: "./profile-item.component.html",
  styleUrls: ["./profile-item.component.scss"]
})
export class ProfileItemComponent implements OnInit {
  @Input() profile: Profile;
  @Output() profileDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() profileEdit: EventEmitter<any> = new EventEmitter<any>();
  constructor(private translateService: TranslateService) {}

  ngOnInit() {}

  deleteProfile() {
    this.profileDelete.next(this.profile);
  }

  editProfile() {
    this.profile.profile_name = this.profileTranslation(this.profile.profile_name);
    this.profileEdit.next({...this.profile});
  }

  profileTranslation(key: string) {
    const toTranslate = ["stagiair / leerling","werkplaats","winkel"];
    const translations = ["TRAINEE","WORKSHOP","PROFILE_SHOP"];
    if (toTranslate.indexOf(key) >= 0) {
      return this.translateService.instant(translations[toTranslate.indexOf(key)]);
    } else {
      return key;
    }
  }
}
