import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompareArticlesComponent } from '../../compare-articles-components/compare-articles.component';
import { ApplicationService } from 'src/app/services/application.service';
import { AuthenticateService } from 'src/app/services/authenticate.service';
import { Router } from "@angular/router";
import { ApiResponse } from "src/app/interfaces/api-response";


@Component({
  selector: 'app-show-compare-button',
  templateUrl: './show-compare-button.component.html',
  styleUrls: ['./show-compare-button.component.scss']
})
export class ShowCompareButtonComponent implements OnInit {
  selectedForCompareCount = 1;
  modalActive = false;
  classicLink: string;
  constructor(
    private modalService: NgbModal,
    private applicationService: ApplicationService,
    private authenticateService: AuthenticateService,
    private router: Router

    ) { }

  ngOnInit() {
     // Compare article count
     this.selectedForCompareCount = this.applicationService.getCompareListItems().length;

     this.applicationService.emitCompareChanged$.subscribe(responseData => {
       this.selectedForCompareCount = this.applicationService.getCompareListItems().length;
     });
  }

  showCompared() {
    const containerElement = document.getElementById("comparedArticles");
    if (!containerElement) {
      this.modalActive = true;
      const modalRef = this.modalService.open(CompareArticlesComponent, {
        size: "lg",
        container: "#modalContainer"
      });
    }
  }
}
