<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'LEAFLET' | translate}} {{article.description}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
</div>
<div class="modal-body p-0">
  <div class="row">
    <div class="col-md-8">
      <div class="scrollable customScrollbar pdfContainer">
        <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" class="border d-block w-100">
        </pdf-viewer>
      </div>
      <div class="d-flex justify-content-center py-2">
        <button type="button" class="btn btn-dark btn-outline" (click)="download()" *ngIf="loaded">{{'DOWNLOAD' |
          translate}}</button>
      </div>
    </div>
    <div class="col-md-4 pe-5 ps-md-0 ps-5">
      <form name="leafletForm" [formGroup]="leafletForm" #ulf="ngForm" novalidate
        (ngSubmit)="ulf.form.valid && sendPdf()">
        <div class="mb-3 pt-3">
          <label for="name">{{'NAME'|translate}}</label>
          <input type="text" class="form-control" id="name" formControlName="name" name="name"
            aria-describedby="nameHelp">
        </div>
        <div class="mb-3">
          <label for="email">{{'EMAIL'|translate}}</label>
          <input type="email" class="form-control" formControlName="email_address" id="email_address"
            name="email_address" aria-describedby="emailHelp">
          <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
          <div class="invalid-feedback" *ngIf="leafletForm.get('email_address').invalid">
            <div [hidden]="!leafletForm.get('email_address').invalid">{{ 'VALIDATION_EMAIL' | translate }}</div>
          </div>
        </div>
        <div class="mb-3">
          <label for="remarks">{{'REMARKS'|translate}}</label>
          <textarea class="form-control" id="remarks" formControlName="remarks" name="remarks"
            aria-describedby="remarkHelp"></textarea>
        </div>
        <button type="submit" class="btn btn-primary mb-2" [ngClass]="router.url.includes('kiosk') ? 'btn-lg' : ''">{{'SEND' | translate}}</button><br />
        <ngb-alert [dismissible]="true" type="success" *ngIf="leafletSent" class="mt-3">
          {{'LEAFLET_SENT' | translate }}
        </ngb-alert>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <span class="col-md-12 p-0">

  </span>
</div>
