import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: "app-dealer-stock-token-container",
  templateUrl: "./dealer-stock-token-container.component.html",
  styleUrls: ["./dealer-stock-token-container.component.scss"],
})
export class DealerStockTokenContainerComponent implements OnInit {
  tokenData: string = "";
  constructor(private helperService: HelperService, private alertService: AlertService, private translateService: TranslateService) {}

  ngOnInit(): void {}

  copy() {
    this.helperService.copyTextToClipboard(this.tokenData);
    this.alertService.showInfoNotification(this.translateService.instant("COPIED"))
  }
}
