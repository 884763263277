import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  pdfSrc: Blob;
  pdfUrl: string;
  loaded = false;
  showDownload = false;
  fileName: string;
  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
    this.getUrl();
  }

  getUrl() {
    const file = new Blob([this.pdfSrc], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    this.pdfUrl = (fileURL)
    timer(300).subscribe(() => {
      this.loaded = true;
    })
  }

  download() {
    saveAs(this.pdfSrc, this.fileName);
  }

}
