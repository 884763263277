import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { IsDealerGuard } from 'src/app/guards/is-dealer.guard';
import { SharedModule } from '../shared/shared.module';
import { EBSCComponent } from 'src/app/pages/ebsc-page/ebsc.component';
import { EbikeRegistrationsComponent } from 'src/app/components/ebsc/ebike-registrations/ebike-registrations.component';
import { BatteryDetailsComponent } from 'src/app/ebsc-components/battery-details/battery-details.component';

const routes: Routes = [
  {
    path: "",
    component: EBSCComponent,
    canActivate: [IsDealerGuard]
  },
  {
    path: "battery-details",
    component: BatteryDetailsComponent,
    canActivate: [IsDealerGuard]
  }]

@NgModule({
  imports: [
    CommonModule,RouterModule.forChild(routes), SharedModule
  ],
  declarations: [EBSCComponent, EbikeRegistrationsComponent, BatteryDetailsComponent]
})
export class EbscModule { }
