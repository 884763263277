import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerPortalLogoComponent } from 'src/app/components/partner-portal/partner-portal-logo/partner-portal-logo.component';
import { RouterModule } from '@angular/router';
import { PartnerStoreFooterComponent } from 'src/app/components/partner-store/partner-store-footer/partner-store-footer.component';
import { ResetPasswordComponent } from 'src/app/components/login-components/reset-password/reset-password.component';
import { PartnerStoreQuicklinksComponent } from 'src/app/components/partner-store/partner-store-quicklinks/partner-store-quicklinks.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { createTranslateLoader } from 'src/app/translationLoader';
import { HttpClient } from '@angular/common/http';

@NgModule({
  declarations: [
    PartnerPortalLogoComponent,
    PartnerStoreFooterComponent,
    PartnerStoreQuicklinksComponent
  ],
  imports: [
    RouterModule, CommonModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
      },
  }),
  ],
  exports: [
    PartnerPortalLogoComponent,
    PartnerStoreFooterComponent,
    PartnerStoreQuicklinksComponent
  ]
})
export class PartnerPortalSharedModule { }
