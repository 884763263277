<ng-container *ngIf="article.item_details">
<div
  class="row compact-article clickable withOpacity"
  [class.border-bottom]="!article.children"
  *ngIf="
    article &&
    !previewItem &&
    ((!article.parent_id || !article.parent_found) || isChild || preorder) &&
    compact
  "
  (click)="expanded = !expanded"
>
  <div class="col-6 overflow-hidden text-nowrap pt-1">
    <input
      type="checkbox"
      class="checkout"
      [(ngModel)]="article.checkout"
      *ngIf="
        !isChild &&
          showCheckoutOption &&
          (article.item_details?.stock_status < 3 || showDealerOptions);
        else noCheck
      "
      (change)="updateCheckoutStatus($event)"
    />
    <i *ngIf="isChild" class="material-icons d-inline" style="font-size: 16px"
      >battery_charging_full</i
    >
    {{ article.item_details?.single_article_code }}
    {{ article.item_details?.description }}
    <ng-container *ngIf="article.item_details?.caption"> - </ng-container>
    {{ article.item_details?.caption }}
  </div>
  <div class="col-1 position-relative d-flex justify-content-end">
    <app-stock-status
      [showText]="false"
      *ngIf="
        !article.item_details?.setitem &&
        !basketLocked &&
        (!article.warehouse || article.item_details?.stock_status < 3)
      "
      [article]="article.item_details"
    ></app-stock-status>
    <span
      class="d-block spinner-border spinner-border-sm position-absolute start-0 top-0"
      role="status"
      aria-hidden="true"
      *ngIf="article.updating_delivery"
    ></span>
  </div>
  <div class="col-2 text-end">
    <span class="d-inline-block" *ngIf="article.amount">{{ article.amount }} &times;&nbsp;</span>
    <app-nett-price
      *ngIf="article.price"
      [price]="article.price"
      class="price d-inline-block"
    ></app-nett-price>
  </div>
  <div class="col-3 d-flex justify-content-end pe-0">
    <app-article-sum
      class="price  d-inline-block"
      [netPrice]="article.price"
      [retailPrice]="article.item_details?.retail_price"
      [amount]="article.amount"
    ></app-article-sum>
  </div>
</div>

<div
  class="w-100 d-flex pt-2 pt-sm-1 basketArticle editable row rounded position-relative"
  (click)="selectArticle($event)"
  *ngIf="
    article &&
    !previewItem &&
    ((!article.parent_id || !article.parent_found) || isChild || preorder) &&
    (!compact || expanded)
  "
  [ngClass]="[
    article.checkout || !showCheckoutOption ? 'selected' : 'notSelected',
    'article-' + article?.article_code
  ]"
  [ngbTooltip]="article.children ? htmlContent : undefined"
  [placement]="'right'"
>
<ng-template #htmlContent>
  <span [innerHTML]="
  article.children
  ? ('TOTAL' | translate) +
    ' ' +
    (!showroomMode ? (article.price?.value + article.children[0].price?.value
      | currencyConvert : article.price?.currency) : (article.item_details?.retail_price?.value + article.children[0].item_details?.retail_price?.value
      | currencyConvert : article.price?.currency))
  : undefined"></span>
</ng-template>

  <div
    class="col col-2 px-sm-2 d-md-flex d-block first justify-content-center align-md-self-center align-xs-self-start"
  >
    <div
      class="d-block text-end"
      style="min-width: 24px; padding-left: 0.75rem"
      *ngIf="!basketLocked"
    >
      <input
        type="checkbox"
        class="checkout"
        [(ngModel)]="article.checkout"
        *ngIf="
          !article.is_mandatory &&
            showCheckoutOption &&
            (article.item_details?.stock_status < 3 || showDealerOptions);
          else noCheck
        "
        (change)="updateCheckoutStatus($event)"
      />
    </div>
    <ng-template #noCheck>
      <input
        type="checkbox"
        class="mx-1 checkout"
        *ngIf="showCheckoutOption"
        [(ngModel)]="article.checkout"
        disabled="disabled"
      />
    </ng-template>
    <span class="imgContainer h-100 text-center" (click)="openModal($event)">
      <app-article-image
        [imageUrl]="article.item_details?.thumbs[0]"
        [articleType]="article.item_details?.article_type"
        [useLazyLoad]="true"
        [description]="article.item_details?.description"
        [classList]="'img-thumbnail'"
      ></app-article-image>
    </span>
  </div>
  <div
    class="col-md-3 col-lg-3 col-xl-4 col-10 details clickable withOpacity"
    (click)="openModal($event)"
    placement="left"
  >
    <span
      class="ps-2 d-inline-block fw-bold"
      [innerHTML]="article?.item_details?.description"
    ></span>
    <br />
    <small
      class="ps-2 d-block art-code"
      [innerHTML]="
        article?.article_code +
        ' ' +
        (article?.item_details?.caption
          ? '- ' + article.item_details?.caption
          : '')
      "
    ></small>
    <small
      class="d-flex align-items-center indication ms-1"
      *ngIf="article.is_mandatory"
    >
      <i class="material-icons">warning</i> {{ "REQUIRED_ITEM" | translate }}
    </small>
    <small
      *ngIf="
        article.has_mandatory_item &&
        !article.children &&
        !preorder &&
        !basketLocked
      "
      class="text-danger-hover d-block ps-2"
    >
      {{ "REQUIRED_ITEM" | translate }}
      <u
        class="btn btn-sm btn-outline-danger-hover editMandatoryArticle py-0 mb-1"
        (click)="selectMandatory(article)"
        >{{ "ADD" | translate }}</u
      >
    </small>
    <small
      *ngIf="article.has_mandatory_item && !article.children && preorder"
      class="text-danger-hover d-block ps-2"
      >{{ article.warning }}</small
    >

    <small
      *ngIf="article.parent_id && !article.parent_found && !article.warning"
      class="text-danger d-block ps-2"
      >{{ "MANDATORY_USE_WIZARD" | translate }}</small
    >

    <div class="row d-sm-none">
      <app-stock-status
        class="col-2 mt-1"
        [showText]="false"
        *ngIf="
          !article?.item_details?.setitem &&
          !basketLocked &&
          (!article?.warehouse || article?.item_details?.stock_status < 3)
        "
        [article]="article?.item_details"
        [ngbTooltip]="article?.item_details | statusText"
      ></app-stock-status>

      <app-delivery-week
        class="col-4 my-2 px-0 deliveryWeek"
        *ngIf="
          !article?.item_details?.setitem"
        [article]="article"
        (weekSelected)="weekSelected($event)"
        [lockedDeliveryWeeks]="true"
      ></app-delivery-week>
      <span
        class="d-block spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
        *ngIf="article.updating_delivery"
      ></span>
      <span class="col-6 ps-0 pe-3 pe-sm-0 text-end"
        >
        <span class="d-inline-block pt-1 mt-1">{{ article.amount }} &times;&nbsp;</span>
        <app-nett-price
          *ngIf="article.price"
          [price]="article.price"
          class="price d-inline-block pt-1 mt-1"
        ></app-nett-price
        >
        <app-article-sum
          [netPrice]="article.price"
          [retailPrice]="article.item_details?.retail_price"
          class="price d-inline-block pt-1 mt-1"
          [amount]="article.amount"
        ></app-article-sum>
      </span>
      <span class="col-2">
        <i
          class="material-icons mb-3 mt-2 position-absolute editArticle"
          style="top: 0; right: 5px"
          *ngIf="!preorder && !basketLocked"
        >
          edit
      </i>
      </span>
    </div>
  </div>
  <div class="col-1 d-none d-sm-block" style="max-width: 50px">
    <app-stock-status
      [showText]="false"
      *ngIf="
        !article.item_details?.setitem &&
        !basketLocked &&
        !preorder &&
        (!article.warehouse || article.item_details?.stock_status < 3)
      "
      [article]="article.item_details"
    >
    </app-stock-status>
  </div>
  <div class="col-md-4 col-xl-3 px-0 d-none d-sm-flex me-1">
    <div class="col-8 position-relative">
      <app-delivery-week
        *ngIf="(!preorder || (preorder && type === 'Parts')) && !basketLocked && !article.item_details?.setitem"
        [article]="article"
        (weekSelected)="weekSelected($event)"
        [lockedDeliveryWeeks]="lockedDeliveryWeeks"
        [deliveryWeek]="article.delivery_week"
        [deliveryYear]="article.delivery_year"
        [forceDeliveryWeekSelect]="
          (preorder && type === 'Parts') ||
          (applicationService.getSelectCompanyGroupCode() === 'WG' &&
            article.item_details?.company_id === '102')
        "
      ></app-delivery-week>
      <ng-container *ngIf="article.item_details?.setitem">
        <small class="d-block mb-1">{{ "SETITEM" | translate }}</small>
        <button class="btn btn-sm btn-dark" (click)="openModal($event)">
          {{ "EDIT" | translate }}
        </button>
      </ng-container>
      <small
        class="d-flex align-items-center indication"
        *ngIf="article.backorder"
      >
        <i class="material-icons">check</i> {{ "BACKORDER_HEADER" | translate }}
      </small>

      <small class="d-flex align-items-center indication" *ngIf="article.sold">
        <i class="material-icons">check</i> {{ "SOLD" | translate }}
      </small>
      <small
        class="d-flex align-items-center indication text-truncate"
        *ngIf="article.remarks"
      >
        <i
          class="material-icons"
          placement="top"
          [ngbTooltip]="article.remarks"
        >
          chat_bubble_outline
        </i>
        <span class="text-truncate d-inline-block" style="max-width: 150px">{{
          article.remarks
        }}</span>
      </small>
      <ng-container *ngIf="basketLocked && article.delivery_year != '9999'"
        >{{ article.delivery_week }} {{ article.delivery_year }}</ng-container
      >

      <span
        class="d-block spinner-border spinner-border-sm position-absolute" style="top: 20%; left: 40%"
        role="status"
        aria-hidden="true"
        *ngIf="article.updating_delivery"
      ></span>
    </div>
    <ng-container *ngIf="!basketLocked; else locked">
      <div class="col-4">
        <div
          class="hideForShowroomMode d-none d-sm-flex justify-content-end align-items-start pt-1"
        >
          <p-inputNumber
            type="number"
            numberOnly
            [allowDecimals]="false"
            [(ngModel)]="article.amount"
            [min]="1"
            [max]="999"
            numberOnly
            [allowDecimals]="false"
            [showButtons]="true"
            (onInput)="checkQty(article); articleChanged()"
            (click)="
              article.item_type === 'Bicycles' ? editPreorderQty() : undefined
            "
            *ngIf="!isChild"
            [inputStyleClass]="'ps-2 pe-1'"
          />
          <span class="d-block mt-1" *ngIf="isChild">
            {{ article.amount }}
          </span>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    class="col-md-3 hideForShowroomMode text-end d-none d-sm-flex ms-auto px-lg-1 justify-content-between"
  >
    <span class="d-inline-block">
      <ng-container *ngIf="(article.price && !showroomMode) || (article.item_details?.retail_price && showroomMode)">&times; </ng-container>
      <app-nett-price
        *ngIf="article.price"
        [price]="article.price"
        class="price d-inline-block"
      ></app-nett-price>
      <app-retail-price
        [hideLabel]="true"
        *ngIf="article.item_details?.retail_price && showroomMode"
        [price]="article.item_details?.retail_price"
        class="price"
      ></app-retail-price>
    </span>

    <span class="d-flex">
      <span class="hideForShowroomMode pe-2" [ngClass]="isChild ? 'me-1' : ''">
        <app-article-sum
          [netPrice]="article.price"
          [retailPrice]="article.item_details?.retail_price"
          class="price"
          [amount]="article.amount"
        ></app-article-sum>
      </span>
      <i
        class="material-icons delete-icon clickable withOpacity"
        (click)="deleteArticle()"
        style="margin-right: -15px; transform: translateY(5px)"
        [style.visibility]="
           basketLocked || (article.is_mandatory && article.parent_found)? 'hidden' : 'visible'
        "
      >
        delete
      </i>
    </span>
  </div>

  <ng-template #locked>
    <div
      class="col-md-2 d-none d-sm-block hideForShowroomMode text-end px-md-0 px-lg-0"
    >
      {{ article["amount"] }}
      <ng-container *ngIf="!article.item_details?.setitem && article.price"
        >&times;
      </ng-container>
      <app-nett-price [price]="article['price']" class="price"></app-nett-price>
      <app-retail-price
        [hideLabel]="true"
        [price]="article.item_details['retail_price']"
        *ngIf="article.item_details?.retail_price && showroomMode"
        class="price"
      ></app-retail-price>
    </div>
  </ng-template>

  <div
    class="basket-article-options row mt-2 mb-4 p-2"
    *ngIf="expanded"
    [@inOutAnimation]
  >
    <div class="col col-1"></div>
    <div
      class="col-md-6 d-none d-sm-block ps-3 ms-1"
      placement="top"
      *ngIf="!preorder && !basketLocked"
    >
      <div class="d-flex flex-column">
        <label class="form-label ps-1">{{ "REMARKS" | translate }}</label>
        <textarea
          class="form-control remark d-inline-block w-75 ps-2 mt-0 ms-1"
          [(ngModel)]="article.remarks"
          (change)="articleChanged()"
          maxlength="100"
        ></textarea>
      </div>
    </div>
    <div class="col-md-2 d-none d-sm-block pt-1" *ngIf="basketLocked">
      <i class="material-icons small" *ngIf="article.remarks">
        chat_bubble_outline
      </i>
      {{ article.remarks }}
    </div>
    <div class="col-md-2 d-none d-sm-block mt-2">
      <div
        *ngIf="
          !preorder && !showShopDropdown && !basketLocked && showDealerOptions
        "
        class="form-check form-switch"
      >
        <input
          type="checkbox"
          class="form-check-input"
          [id]="'sold_' + article.id"
          (change)="articleSwitchChanged(article.sold, 'sold')"
          [(ngModel)]="article.sold"
        />
        <label class="form-check-label" [for]="'sold_' + article.id">{{
          "SOLD" | translate
        }}</label>
      </div>

      <div
        *ngIf="!preorder && !basketLocked && showDealerOptions"
        class="form-check form-switch"
        [style.display]="
          article.article_type === 'P' || article.article_type === 'OT1'
            ? ''
            : 'none'
        "
      >
        <input
          type="checkbox"
          class="form-check-input"
          [ngClass]="'state-' + (article.backorder ? 'active' : 'inActive')"
          [id]="'back_order_' + article.id"
          (change)="articleSwitchChanged(article.backorder, 'backorder')"
          [(ngModel)]="article.backorder"
        />
        <label class="form-check-label" [for]="'back_order_' + article.id">{{
          "BACKORDER_HEADER" | translate
        }}</label>
      </div>
      <app-shop-type-selection
        *ngIf="!preorder && !basketLocked && showShopDropdown"
        (shopTypeChanged)="shopTypeChanged($event)"
        [article]="article"
      >
      </app-shop-type-selection>


    </div>
    <div class="col-md-2 col-lg-3 d-none d-sm-block mt-2 px-0">
      <small
      *ngIf="article.is_mandatory && !preorder && !basketLocked"
      class="text-danger d-block pt-2 mb-2 ps-2"
    >
      {{ "REQUIRED_ITEM" | translate }}
      <u
        class="btn btn-sm btn-outline-danger editMandatoryArticle py-0"
        (click)="selectMandatory()"
        >{{ "EDIT" | translate }}</u
      >
      </small>
        <small
        *ngIf="
          article.has_mandatory_item &&
          !article.children &&
          !preorder &&
          !basketLocked
        "
        class="text-danger d-block ps-2 mb-2"
      >
        {{ "REQUIRED_ITEM" | translate }}
        <u
          class="btn btn-sm btn-outline-danger editMandatoryArticle py-0"
          (click)="selectMandatory(article)"
          >{{ "ADD" | translate }}</u
        >
      </small>
      <small
        *ngIf="article.has_mandatory_item && !article.children && preorder"
        class="text-danger-hover d-block ps-2"
        >{{ article.warning }}</small
      >
    </div>
  </div>

  <div *ngIf="!preorder"
    class="d-none d-sm-flex col-8 justify-content-center show-options-bar position-absolute bottom-0 end-0 mx-auto mb-1 clickable withOpacity" (click)="expanded = !expanded"
  >
      <i class="material-icons " style="opacity: 1">{{
        expanded ? "expand_less" : "expand_more"
      }}</i> {{(!expanded ? "SHOW_MORE" : "SHOW_LESS")|translate}}
  </div>
  <ng-content></ng-content>
</div>
<div
  class="child-article position-relative ps-1"
  [class.compact]="compact"
  [class.px-0]="!compact"
  [class.row]="compact"
  *ngIf="!previewItem && article.children"
>
  <app-basket-article
    [article]="article.children[0]"
    (articleSelectionChanged)="articleChanged()"
    [parentArticle]="article"
    [previewItem]="false"
    [isChild]="true"
    [basketId]="basketId"
    [compact]="compact"
  ></app-basket-article>
</div>

<div
  class="w-100 d-flex basketPreviewArticle bg-white px-2 pt-0"
  [ngClass]="isChild ? 'child' : ''"
  *ngIf="previewItem && ((!article.parent_id || !article.parent_found) || isChild)"
>
  <div class="col-md-1 px-0 d-flex align-items-center img-container">
    <app-article-image
      [imageUrl]="article.item_details?.thumbs[0]"
      (click)="openModal($event)"
      [articleType]="article.item_details?.article_type"
      [useLazyLoad]="true"
      [description]="article.item_details?.description"
      [classList]="'img-thumbnail'"
    ></app-article-image>
  </div>
  <div class="col-4 col-md-3 ps-2 clickable withOpacity" (click)="openModal($event)">
    {{ article.item_details["description"] }} <br />
    <small
      class="me-1"
      *ngIf="article.item_details?.caption"
      [innerHTML]="article.item_details['caption']"
    ></small>
    <b [innerHTML]="article.item_details['single_article_code']"></b>
    <i
      class="material-icons small ms-2"
      *ngIf="article.remarks"
      placement="top"
      [ngbTooltip]="article.remarks"
    >
      chat_bubble_outline
    </i>
  </div>
  <div class="col-md-1" placement="left">
    <app-stock-status
      [showText]="false"
      [article]="article.item_details"
      *ngIf="!article.warehouse || article.item_details?.stock_status < 3"
    ></app-stock-status>
  </div>
  <div class="col-md-2 hideForShowroomMode">
    <input
      type="number"
      numberOnly
      [allowDecimals]="false"
      [(ngModel)]="article.amount"
      min="1"
      max="999"
      [attr.readonly]="isChild || preorder ? 'readonly' : undefined"
      class="form-control form-control-sm w-75"
      (change)="articleChanged(true); checkQty(article)"
      oninput="this.value|=0"
    />
  </div>
  <ng-content select="[inputAddition]"></ng-content>
  <div class="col-md-3 hideForShowroomMode d-none d-sm-inline-block" *ngIf="!quickorder">
    <span class="d-inline-block pe-1" *ngIf="article.price">&times; </span>
    <app-nett-price
      [price]="article.price"
      *ngIf="article.price"
      class="d-inline-block"
    ></app-nett-price>
    <app-retail-price
      [hideLabel]="true"
      *ngIf="article.item_details?.retail_price && showroomMode"
      [price]="article.item_details?.retail_price"
      class="d-inline-block"
    ></app-retail-price>
  </div>
  <div class="col-md-2" *ngIf="quickorder"></div>
  <div class="col-3 col-md-2 hideForShowroomMode pe-3 pe-sm-4 text-end">
    <app-article-sum
      [netPrice]="article.price"
      [retailPrice]="article.item_details?.retail_price"
      [amount]="article.amount"
    ></app-article-sum>
  </div>
</div>
<div class="w-100 d-block mb-2 px-0" *ngIf="previewItem && article.children">
  <app-basket-article
    [article]="article.children[0]"
    (articleSelectionChanged)="articleChanged()"
    [parentArticle]="article"
    [previewItem]="true"
    [isChild]="true"
    [basketId]="basketId"
  ></app-basket-article>
</div>
</ng-container>
