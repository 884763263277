<div class="carousel-content">
    <h1 class="mb-3 mt-0">{{ slide.title }}</h1>
    <div class="container ps-0">
      <div *ngIf="slide && slide.content && !html">
        <ndc-dynamic
          [ndcDynamicComponent]="components['page']"
          [ndcDynamicInputs]="text"
        >
        </ndc-dynamic>
      </div>
      <div class="content" [hidden]="!html"></div>
    </div>
  </div>
