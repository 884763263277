import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TokenContainer } from "../interfaces/tokencontainer";
import { Router } from "@angular/router";
import { AccountSettings } from "../interfaces/account-settings";
import { BehaviorSubject, Observable } from "rxjs";
import { ApplicationService } from "./application.service";
import { ApiResponse } from "../interfaces/api-response";
import { User } from "../interfaces/user";
import { ChangePasswordContainer } from "../interfaces/change-password-container";
import { VerifyPasswordContainer } from "../interfaces/verify-password-container";
import { HelperService } from "./helper.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthenticateService {
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}
  constructor(
    private http: HttpClient,
    private router: Router,
    private applicationService: ApplicationService,
    private helperService: HelperService
  ) {}

  public login(username, password) {
    const requestData = {
      username: username,
      password: password,
    };

    return this.http.post(`${environment.apiendpoint}authorize`, requestData);
  }

  // Autologin from external page
  public connect(key: string): Observable<TokenContainer> {
    return this.http.get(environment.apiendpoint + 'connect/' + key).pipe(
      map((apiResponse: ApiResponse) => {
        let tokenContainer = new TokenContainer();
        if (apiResponse.success) {
          tokenContainer = apiResponse.result;
          this.saveTokens(tokenContainer);
          this.validLogin();
          return tokenContainer;
        } else {
          return null;
        }
      })
    );
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  public validLogin() {
    this.loggedIn.next(true);
  }

  public clearUserData() {
    const clearFields = [
      "accountType",
      "activeBasketId",
      "cgc",
      "companyGroups",
      "refreshToken",
      "bearerToken",
      `preordersettings_${this.applicationService.getSelectCompanyGroupCode()}`,
      "expires",
      "dealerInformation",
      "is_admin",
    ];
    for (const key in localStorage) {
      if (key.substring(0, 11) == "snapinsPage") {
        localStorage.removeItem(key);
      }
    }
    clearFields.forEach((key: string) => {
      localStorage.removeItem(key);
    });
  }

  checkAuthorisation() {
    return new Promise((resolve) => {
      if (this.checkToken()) {
        resolve(false);
      } else {
        const refreshContainer = new TokenContainer();
        refreshContainer.bearer_token = localStorage.getItem("bearerToken");
        refreshContainer.refresh_token = localStorage.getItem("refreshToken");

        if (refreshContainer.refresh_token)
          this.refreshToken(refreshContainer).subscribe((json: ApiResponse) => {
            let tokenContainer = new TokenContainer();

            if (json && json.result && json.result.bearer_token) {
              tokenContainer = json.result;
              this.saveTokens(tokenContainer);
              resolve(tokenContainer.version ? true : false);
            } else {
              this.logout();
            }
          });
      }
    });
  }



  public isAuthorized() {
    return localStorage.getItem("bearerToken") ? true : false;
  }

  public isAdmin() {
    return localStorage.getItem("is_admin") !== null; // Todo better check
  }

  public logout(redirect: boolean = false) {
    this.clearUserData();
    this.loggedIn.next(false);
    const landingPage = redirect
      ? `${
          window.location.origin
        }/login?redirectUrl=${this.helperService.getCurrentPath()}`
      : `${window.location.origin}/login`;

    const accountCode = localStorage.getItem("accountCode");
    if (
      accountCode === null ||
      accountCode === environment.previewUser.username || this.applicationService.isPartnerPortal()
    ) {
      window.location.href = landingPage;
    } else {
      const signoutUrl = `${
        environment.oktaUrl
      }signout?fromURI=${encodeURIComponent(landingPage)}`
      window.location.href = signoutUrl;
    }
  }

  public refresh() {
    this.http.get(environment.apiendpoint + "responses/401").subscribe();
  }

  public badRequest() {
    this.http.get(environment.apiendpoint + "responses/400").subscribe();
  }

  public checkToken() {
    const savedDate = new Date(localStorage.getItem("expires"));
    const d = new Date();
    const n = d.getTimezoneOffset();
    const expires = new Date(savedDate.getTime() + n);

    return expires > d;
  }

  public getToken() {
    return localStorage.getItem("bearerToken");
  }

  // Refreshes the bearer token
  refreshToken(tokenContainer: TokenContainer) {
    return this.http.post(environment.apiendpoint + "refresh", tokenContainer);
  }

  deleteTokens() {
    const clearFields = [
      "accountType",
      "activeBasketId",
      "cgc",
      "companyGroups",
      "refreshToken",
      "bearerToken",
      "expires",
      "selectedDealer",
      "selectedUser",
      `preordersettings_${this.applicationService.getSelectCompanyGroupCode()}`,
    ];
    clearFields.forEach((key: string) => {
      localStorage.removeItem(key);
    });
  }

  // Saves the tokens in the local storage
  saveTokens(tokenContainer: TokenContainer) {
    // Save bearer token
    if (tokenContainer.bearer_token) {
      localStorage.setItem("bearerToken", tokenContainer.bearer_token);
    }

    if (tokenContainer.refresh_token) {
      localStorage.setItem("refreshToken", tokenContainer.refresh_token);
    }

    if (tokenContainer.expires) {
      localStorage.setItem("expires", tokenContainer.expires);
    }

    if (tokenContainer.account_type) {
      localStorage.setItem("accountType", tokenContainer.account_type);
    }

    if (tokenContainer.version) {
      localStorage.setItem("version", tokenContainer.version);
    }

    if (tokenContainer.editor) {
      localStorage.setItem("is_admin", "1");
    }

    this.saveFeatures(tokenContainer.features);
  }

  // Changes the password after users changes this from settings page
  updatePassword(changePasswordContainer: ChangePasswordContainer) {
    return this.http.post(
      environment.apiendpoint + "password/change",
      changePasswordContainer
    );
  }

  resetPassword(username: string) {
    return this.http.get(
      `${environment.apiendpoint}forgotpassword/${username}`
    );
  }

  verifyPasswordFromLink(verifyPasswordContainer: VerifyPasswordContainer) {
    return this.http.post(
      `${environment.apiendpoint}accounts/verifyforgotpasswordlink/`,
      verifyPasswordContainer
    );
  }

  changePasswordFromLink(verifyPasswordContainer: VerifyPasswordContainer) {
    return this.http.post(
      `${environment.apiendpoint}accounts/changepassword/`,
      verifyPasswordContainer
    );
  }

  resetOkta(user: User) {
    return this.resetPassword(user.username);
  }

  getExternalKey(url: string) {
    const keys = [
      "EBSC",
      "SPARTA",
      "CUSTOMKOGA",
      "CUSTOMVANNICHOLAS",
      "CUSTOMLAPIERRE",
      "EBST",
      "EBSTTEST",
      "EBSTDEV",
    ];
    const checkKeys = [
      "site=ebsc",
      "sparta",
      "brand=KOGA",
      "brand=VANNICHOLAS",
      "lapierre",
      "ebst",
      "ebsttest",
      "ebstdev",
    ];
    let externalKey = "";
    for (let index in checkKeys) {
      if (url.indexOf(checkKeys[index]) >= 0) {
        externalKey = keys[index];
      }
    }
    return externalKey;
  }

  loginExternalSite(key: string) {
    return this.http.get(environment.apiendpoint + "redirect/" + key);
  }

  connectToClassic(openInNewTab: boolean) {
    this.http
      .get(environment.apiendpoint + "classic")
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          const newTab = !navigator.vendor.match(/apple/i) && openInNewTab;
          const url = `${environment.classicUrl}${apiResponse.result}`;
          window.location.href = url;
        }
      });
  }

  authorizeLinkedAccount(accountCode: string) {
    return this.http.get(
      environment.apiendpoint + "authorizelinkedaccount/" + accountCode
    );
  }

  redirectToOkta(tokens: TokenContainer, oktaReturnUrl: string) {
    const signOutUrl = "signout?fromURI=";
    const signInUrl = `${environment.oktaUrl}sessionCookieRedirect?redirectUrl=[redirectUrl]&token=`;
    const oktaUrl = `${environment.oktaUrl}${signOutUrl}${encodeURIComponent(
      signInUrl.replace("[redirectUrl]", oktaReturnUrl)
    )}${tokens.session_token}`;
    window.location.href = oktaUrl;
  }

  saveFeatures(features: string[]) {
    this.applicationService.deleteStoredItems("feature_");

    if (features) {
      features.forEach((feature: string) => {
        localStorage.setItem(`feature_${feature}`, "1");
      })
    }
  }
}
