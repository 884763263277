import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LinkedAccount } from "src/app/interfaces/linked-account";
import { AccountService } from "src/app/services/account.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { UnlinkAccount } from "src/app/interfaces/unlink-account";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { UsernameValidator } from "src/app/validators/email-username-validator.directive";
import { Subject } from "rxjs";
import { linkedDealerInfo } from "src/app/interfaces/account-information";

@Component({
  selector: "app-unlink-popup",
  templateUrl: "./unlink-popup.component.html",
  styleUrls: ["./unlink-popup.component.scss"],
})
export class UnlinkPopupComponent implements OnInit {
  unlinkObserver = new Subject<any>();
  unlinkObserverEmitted$ = this.unlinkObserver.asObservable();
  unlinkForm: FormGroup;
  linkedAccount: linkedDealerInfo;
  closed = true;
  alert: string;
  newEmail: string;
  constructor(
    public modal: NgbActiveModal,
    public formBuilder: FormBuilder,
    private accountService: AccountService,
    public usernameValidator: UsernameValidator
  ) {}

  ngOnInit() {
    this.unlinkForm = this.formBuilder.group({
      newEmail: new FormControl(
        this.newEmail,
        [Validators.required]
      ),
    });
  }

  unlink() {
    const unlinkAccount = new UnlinkAccount();
    unlinkAccount.account_code = this.linkedAccount.dealer_code;
    unlinkAccount.user_id = String(this.linkedAccount.user_id);
    unlinkAccount.email_address = this.newEmail;
    this.accountService
      .unlinkAccount(unlinkAccount)
      .subscribe((accresponse: ApiResponse) => {
        if (!accresponse.success) {
          this.closed = false;
          this.alert = accresponse.result;
        } else {
          this.closed = true;
          this.unlinkObserver.next("");
          this.modal.dismiss();
        }
      });
  }
}
