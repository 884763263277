import { Component, OnInit, Output, EventEmitter, ViewChild} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { timer } from "rxjs";
import { TrackingService } from "src/app/services/tracking.service";
import { QrScannerInputComponent } from "../qr-scanner-input/qr-scanner-input.component";

@Component({
  selector: "app-qr-scanner",
  templateUrl: "./qr-scanner.component.html",
  styleUrls: ["./qr-scanner.component.scss"]
})
export class QrScannerComponent implements OnInit {
  @Output() stopped: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(QrScannerInputComponent) input: QrScannerInputComponent;
  active = true;
  constructor(public modal: NgbActiveModal, private trackingService: TrackingService) { }

  ngOnInit() {
    this.trackingService.startQrScanner();
  }

  reset() {
    this.active = false;
    timer(300).subscribe(() => {
      this.active = true;
    });
  }

  closeModal() {
    this.input.stopVideo();
    this.stopped.emit(true);
    this.modal.close();
  }
}
