<app-bread-crumbs [(breadcrumbs)]="breadcrumbs" *ngIf="!hideBreadcrumbs"></app-bread-crumbs>
<div class="container px-3 px-md-2 px-lg-3 px-xl-2 mb-3" *ngIf="article">
  <h1 [innerHTML]="article.description"></h1>
  <h2 [innerHTML]="article.caption"></h2>
  {{"ARTICLE_NO"|translate}}: {{article.article_codes?.join(',')}}

  <div class="row px-2">
    <div
      *ngFor="let image of article.img; let imageIndex = index"
      class="card d-flex col-lg-4 col-xl-4 col-xs-12 col-sm-6 col-md-6 align-items-stretch imageBankImg border-0"
      style="padding: 5px"
    >
      <div class="card-body border" style="text-align: center">
        <img [src]="image" />
        <button pRipple
          class="btn btn-primary imageBankDownloadButton"
          (click)="downloadImage(imageIndex)"
        >
          {{ "DOWNLOAD" | translate }}
        </button>
      </div>
    </div>
    <div
      class="d-flex mb-2 col-lg-4 col-xl-4 col-xs-12 col-sm-6 col-md-6 align-items-stretch imageBankImg"
    >
      <div class="my-4" style="text-align: center">
        <button pRipple
          class="btn btn-dark btn-lg imageBankDownloadButton"
          (click)="downloadZip(article.id)"
        >
          {{ "DOWNLOAD" | translate }} {{ "ALL" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
