import { Component, OnInit, Input } from "@angular/core";
import { WarrantyRequest } from "src/app/interfaces/warranty/warranty-request";
import { Route, ActivatedRoute } from "@angular/router";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-warranty-request",
  templateUrl: "./warranty-request.component.html",
  styleUrls: ["./warranty-request.component.scss"]
})
export class WarrantyRequestComponent implements OnInit {
  @Input() warrantyRequest: WarrantyRequest;
  companyGroupCode: string;
  constructor(applicationService: ApplicationService) {
    this.companyGroupCode = applicationService.getSelectCompanyGroupCode();
  }

  ngOnInit() {}
}
