import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AccountService } from "../services/account.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SearchDealersComponent } from "../components/helpdesk/search-dealers/search-dealers.component";
import { SearchDealerModalComponent } from "../components/helpdesk/search-dealer-modal/search-dealer-modal.component";
import { HelpdeskService } from "../services/helpdesk.service";
import { AuthenticateService } from "../services/authenticate.service";
import { ApplicationService } from "../services/application.service";

@Injectable({
  providedIn: "root",
})
export class IsDealerGuard implements CanActivate {
  constructor(
    private accountService: AccountService,
    private applicationService: ApplicationService,
    private authenticateService: AuthenticateService,
    private modalService: NgbModal,
    private helpdeskService: HelpdeskService,
    private router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {

    let roles = typeof next.data['roles'] !== "undefined" ? next.data['roles'] as Array<string> : [];
    if (roles.includes("partnerPortal") && this.applicationService.isPartnerPortal()) {
      return true;
    } else if (this.applicationService.isPartnerPortal()) {
      this.router.navigateByUrl(`/${this.applicationService.getSelectCompanyGroupCode()}/home`);
    }

    if (
      this.accountService.getAccountType() === null ||
      sessionStorage.getItem("unverified")
    ) {
      this.authenticateService.logout(true);
    }

    if (
      this.accountService.getAccountType() &&
      this.accountService.getAccountType() !== "DLR" &&
      this.helpdeskService.getSelectedDealer() === null &&
      !roles.includes("Helpdesk")
    ) {
      const modal = this.modalService.open(SearchDealerModalComponent, {
        size: "lg",
        container: "#modalContainer",
        backdrop: "static",
        windowClass: "large",
      });
      modal.componentInstance.redirectUrl = next.data['redirectUrl'] ? next.data['redirectUrl'] : state.url;
    } else {
      return true
    }

    return (
      (this.accountService.getAccountType() === "DLR" ||
      this.helpdeskService.getSelectedDealer() !== null) && !this.applicationService.isPartnerPortal()
    );
  }
}
