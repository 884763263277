import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { CmsService } from "src/app/services/cms.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { ApplicationService } from "src/app/services/application.service";
import { Router } from "@angular/router";
import { Quicklink } from "src/app/interfaces/cms/quicklinks";
import { trigger, transition, query, style, stagger, animate } from "@angular/animations";

@Component({
  selector: "app-quick-links",
  templateUrl: "./quick-links.component.html",
  styleUrls: ["./quick-links.component.scss"],
  animations: [
    trigger("pageAnimations", [
      transition(":enter", [
        query(".ani-item", [
          style({ opacity: 0, transform: "translateY(50px)" }),
          stagger(30, [
            animate(
              "400ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({ opacity: 1, transform: "none" })
            ),
          ]),
        ], { optional: true }),
      ]),
    ]),
  ]
})
export class QuickLinksComponent implements OnInit {
  @Output() linkClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() showQuickLinks: boolean;
  @Input() homepageQuicklinks: boolean;
  @Input() quickLinkClass: string = "";
  selectedCompanyGroup: string;
  quicklinks: Quicklink[];
  constructor(
    private cmsService: CmsService,
    private applicationService: ApplicationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.selectedCompanyGroup = this.applicationService.getSelectCompanyGroupCode();
    this.getQuickLinks();
    this.applicationService.emitChangeCompanyGroupChanged$.subscribe(
      response => {
        this.selectedCompanyGroup = response;
        this.getQuickLinks();
      }
    );
  }

  getQuickLinks() {
    this.cmsService
      .getQuickLinks(this.selectedCompanyGroup)
      .then((quickLinks: Quicklink[]) => {
        if (quickLinks) {
          this.quicklinks = quickLinks;
        }
      });
  }

  gotoLink(quickLink, event) {
    event.preventDefault();
    this.linkClicked.emit(true);
    let internalUrl = false;
    const url = quickLink.language_definitions[0].quicklink_url;
    const urlType = quickLink.language_definitions[0].url_type;
    let formattedUrl = url;

    if (url && url.indexOf("shop.accentry.com") >= 0) {
      window.open(formattedUrl, "_new");
      return false;
    }

    if (
      (url && url.indexOf("accentry.com") >= 0) ||
      urlType === 2
    ) {
      internalUrl = true;
      formattedUrl = url.replace("https://www.accentry.com/", "");
    }

    if (urlType === 1) {
      formattedUrl = `/${this.applicationService.getSelectCompanyGroupCode()}/companyPage/${url}`;
      this.router.navigateByUrl(formattedUrl);
      return false;
    }

    if (internalUrl) {
      if (url.indexOf("news#details") >= 0) {
        const urlData = url.split("/");
      } else if (urlType === 2) {
      } else {
        formattedUrl = formattedUrl.replace("results", "search");
        this.router.navigateByUrl(formattedUrl);
      }
    } else {
      window.open(formattedUrl, "_new");
    }
  }
}
