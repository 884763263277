import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticateService } from "../services/authenticate.service";

@Injectable({
  providedIn: "root",
})
export class IsVerifiedGuard implements CanActivate {
  constructor(private authService: AuthenticateService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const unverified = sessionStorage.getItem("unverified");
    if (unverified) {
      this.authService.logout();
    } else {
      return true;
    }
  }
}
