import { Component, OnInit } from "@angular/core";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import { AccountService } from "src/app/services/account.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-helpdesk-page",
  templateUrl: "./helpdesk-page.component.html",
  styleUrls: ["./helpdesk-page.component.scss"]
})
export class HelpdeskPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  constructor(
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private accountService: AccountService,
    private router: Router
  ) {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HELPDESK"),
        this.applicationService.getSelectCompanyGroupCode() + "/helpdesk"
      ),
      new Breadcrumb(this.translateService.instant("SEARCH_DEALERS"), "#")
    ];
  }

  ngOnInit() {
    if (!this.accountService.isHelpdesk()) {
      this.router.navigateByUrl(this.applicationService.getSelectCompanyGroupCode());
    }
  }
}
