import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-accept-conditions-and-privacy',
  templateUrl: './accept-conditions-and-privacy.component.html',
  styleUrl: './accept-conditions-and-privacy.component.scss'
})
export class AcceptConditionsAndPrivacyComponent {
  @Input() privacy_policy: string;
  @Input() terms_and_conditions: string;
  @Output() checkboxChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  agreeTerms: boolean;
  textHtml = "";

  constructor(private translateService: TranslateService) {

  }

  ngOnInit() {
    this.setTextAndLinks();
  }

  setTextAndLinks() {
    let text = this.translateService.instant("AGREE_TERMS_AND_PRIVACY_POLICY");
    text = text.replace("[1]", `<a href="/${this.terms_and_conditions}" target="_new">${this.translateService.instant("GENERAL_TERMS_AND_CONDITIONS")}</a>`);
    text = text.replace("[2]", `<a href="/${this.privacy_policy}" target="_new">${this.translateService.instant("PRIVACY_STATEMENT")}</a>`);
    this.textHtml = text;
  }
}
