export interface ZipLocation {
  zip: string;
  house_number: string;
  country_code: string;
  street: string;
  city: string;
}

export class ZipLocation implements ZipLocation {
  constructor(zip: string, house_number: string, country_code: string) {
    this.zip = zip;
    this.house_number = house_number;
    this.country_code = country_code;
  }
}
