<h1>{{(warrantyOptions.indexOf(type) >= 0 ? 'RETURNS' : applicationService.getSelectCompanyGroupCode() + '_AFTERSALES')|translate}}</h1>
<div class="row mx-0 mb-4">
  <div class="col-md-8 p-4 formContainer" *ngIf="!formSend">
    <form ngNativeValidate [ngClass]="validated ? 'validated' : ''">
      <div class="mb-3 row" *ngIf="warrantyOptions.indexOf(type) >= 0">
        <label class="col-sm-4 col-form-label">{{"WARRANTY_REQUEST_TYPE"|translate}} *</label>
        <div class="col-md-8">
          <ul class="list-unstyled">
            <li class="clickable withOpacity" (click)="setType('warranty'); cmsContent.getContent(this.pageIds[type])">
              <input type="radio" [checked]="type === 'warranty'">
              {{'WARRANTY'|translate}}</li>
            <li class="clickable withOpacity" (click)="setType('returns'); cmsContent.getContent(this.pageIds[type])">
              <input type="radio" [checked]="type === 'returns'"> {{'RETURN'|translate}}
            </li>
            <li class="clickable withOpacity" *ngIf="applicationService.getSelectCompanyGroupCode() === 'LAP'" (click)="setType('returns_no_warranty'); cmsContent.getContent(this.pageIds[type])">
              <input type="radio" [checked]="type === 'returns_no_warranty'"> {{'RETURN_NO_WARRANTY'|translate}}
            </li>
          </ul>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="framenumber" class="col-sm-4 col-form-label">{{(applicationService.getSelectCompanyGroupCode() === 'LAP' ? "FRAMENUMBER" : "SERIALNO")|translate}}  *</label>
        <div class="col-md-8">
          <div class="input-group" [formGroup]="formGroup">
            <input type="text" class="form-control form-control-lg description required" required name="framenumber"
              formControlName="framenumber" (paste)="framenumberPaste($event)" (framenumberChange)="framenumberChange($event)">
            <div class="input-group-append">
              <button class="btn btn-dark" (click)="getBicycle(this.formGroup.get('framenumber').value)" type="button">
                <i class="material-icons">search</i>
              </button>
            </div>
          </div>
          <input type="checkbox" class="me-2 mt-2" [checked]="unknownArticles.bicycle" id="u_b"
            (change)="unknownArticles.bicycle = !unknownArticles.bicycle">
          <label for="u_b">{{'BIKE_UNKNOWN'|translate}}</label>
        </div>
      </div>
      <div class="mb-3 row" *ngIf="companyGroup && brands[companyGroup] && brands[companyGroup].length">
        <label for="warranty_brand" class="col-sm-4 col-form-label">{{"BRAND"|translate}} *</label>
        <div class="col-md-8" [formGroup]="formGroup">
          <select class="form-control required" required name="warranty_brand"
            formControlName="warranty_brand">
              <option value="">{{"MAKE_A_CHOICE"|translate}}</option>
              <option *ngFor="let brand of brands[companyGroup]" [value]="brand['brandId']">{{brand['description']}}</option>
            </select>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="description" class="col-sm-4 col-form-label">{{"MODEL_DESCRIPTION"|translate}} *</label>
        <div class="col-md-8" [formGroup]="formGroup">
          <input type="text" class="form-control description required" required name="description"
            formControlName="description">
        </div>
      </div>
      <div class="mb-3 row" *ngIf="type !== 'aftersales'">
        <label for="caption" class="col-sm-4 col-form-label">{{'ARTICLE_SPECIFICATION'|translate}} <ng-container *ngIf="type !== 'returns_no_warranty' || applicationService.getSelectCompanyGroupCode() !== 'LAP'">*</ng-container></label>
        <div class="col-md-8" [formGroup]="formGroup">
          <input type="text" class="form-control caption required" required name="caption" formControlName="caption">
        </div>
      </div>
      <div class="mb-3 row" *ngIf="type !== 'aftersales'">
        <label for="season" class="col-sm-4 col-form-label">{{'MODEL_YEAR'|translate}} *</label>
        <div class="col-md-2" [formGroup]="formGroup">
          <input type="number" min="2008" class="form-control required season" required name="season" formControlName="season">
        </div>
      </div>

      <div class="mb-3 row" *ngIf="type !== 'aftersales'">
        <label for="part" class="col-sm-4 col-form-label">{{'DAMAGED_PART'|translate}} *</label>
        <div class="col-md-8">
          <div [formGroup]="formGroup">
            <input type="text" class="form-control required" required name="damaged_article"
            formControlName="damaged_article">
          </div>
        </div>
      </div>

      <div class="mb-3 row" *ngIf="applicationService.getSelectCompanyGroupCode() !== 'LAP'">
        <label for="damage" class="col-sm-4 col-form-label">{{'DAMAGE_DESCRIPTION'|translate}} *</label>
        <div class="col-md-8" [formGroup]="formGroup">
          <textarea class="form-control required" required name="damage_description"
            formControlName="damage_description"></textarea>
        </div>
      </div>

      <div class="mb-3 row">
        <div class="col-sm-4">
          <label>{{'PURCHASE_DATE'|translate}}</label>
        </div>
        <div class="col-sm-4">
          <div class="input-group">
            <input class="form-control bg-white" placeholder="dd-mm-yyyy" type="text" name="dp"
              (click)="d.toggle()" [(ngModel)]="selectedInvoiceDate"
              [maxDate]="maxDate" ngbDatepicker #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                <i class="material-icons" style="font-size: 17px">date_range</i>
              </button>
            </div>
          </div>
          <!-- <ngb-alert *ngIf="validDate === false">{{'INVOICE_DATE_NOT_CORRECT'|translate}}</ngb-alert> -->
        </div>
      </div>
      <div class="mb-3 row">
        <label for="image" class="col-sm-4 col-form-label">{{'INVOICE'|translate}} <ng-container *ngIf="type !== 'returns_no_warranty' || applicationService.getSelectCompanyGroupCode() !== 'LAP'">*</ng-container></label>
        <div class="col-md-8">
          <input type="file" class="invoice" name="invoice" (change)="getImageData($event, 'invoice_file')" [attr.required]="(type !== 'returns_no_warranty' || applicationService.getSelectCompanyGroupCode() !== 'LAP') ? 'required' : undefined"
            accept="application/pdf, image/jpeg, image/png">
        </div>
      </div>
      <div class="mb-3 row" *ngIf="type !== 'aftersales'">
        <label for="image" class="col-sm-4 col-form-label">{{'IMAGE_OF_PROBLEM'|translate}} <ng-container *ngIf="type !== 'returns_no_warranty' || applicationService.getSelectCompanyGroupCode() !== 'LAP'">*</ng-container></label>
        <div class="col-md-8">
          <input type="file" class="image" name="image" (change)="getImageData($event, 'file')"  [attr.required]="(type !== 'returns_no_warranty'  || applicationService.getSelectCompanyGroupCode() !== 'LAP') ? 'required' : undefined"
            accept="application/pdf, image/jpeg, image/png">
        </div>
      </div>
      <div class="mb-3 row">
        <label for="remarks" class="col-sm-4 col-form-label">{{'REMARKS'|translate}} <ng-container *ngIf="applicationService.getSelectCompanyGroupCode() === 'GHO'">*</ng-container></label>
        <div class="col-md-8" [formGroup]="formGroup">
          <textarea class="form-control" name="remarks" formControlName="remarks" [attr.required]="applicationService.getSelectCompanyGroupCode() === 'GHO' ? 'required' : undefined"></textarea>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="part" class="col-sm-4 col-form-label">{{'EMPLOYEE'|translate}} *</label>
        <div class="col-md-4" [formGroup]="formGroup">
          <input type="text" class="form-control required" required name="contact_person" value=""
            formControlName="contact_person">
        </div>
      </div>
      <div class="mb-3 row">
        <label for="part" class="col-sm-4 col-form-label">{{'EMAIL'|translate}} *</label>
        <div class="col-md-8" [formGroup]="formGroup">
          <input type="email" class="form-control required" required name="email_address" value=""
            formControlName="email_address">
        </div>
      </div>
      <div class="mb-3 row">
        <label for="part" class="col-sm-4 col-form-label">{{'PHONE'|translate}} *</label>
        <div class="col-md-8" [formGroup]="formGroup">
          <input type="text" class="form-control required" required name="phone_number" value=""
            formControlName="phone_number">
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-6" [formGroup]="formGroup">
          <input type="submit" class="btn btn-lg btn-success" type="submit" [value]="'SEND'|translate"
            (click)="submit()">
        </div>
      </div>
    </form>
  </div>

  <div class="col-md-8 px-0" *ngIf="formSend">
    <ngb-alert type="success" [innerHTML]="'WARRANTYREQUEST_SEND'|translate"></ngb-alert>
    <button class="btn btn-dark d-block mt-2" (click)="formSend = false; formGroup.reset(); selectedInvoiceDate = null">{{'NEW'|translate}}</button>
  </div>

  <!-- Selected articles and intro text -->
  <div class="col-md-4">
    <app-cms-page-content #cmsContent [pageId]="pageIds[type]"></app-cms-page-content>
    <h3 *ngIf="bicycleArticle">{{'BICYCLE'|translate}}</h3>
    <app-article-card class="d-block w-100 mb-2" *ngIf="bicycleArticle" [article]="bicycleArticle"
      [useLazyLoad]="false"></app-article-card>
      <ng-container *ngIf="damagedArticle">
        <h3 class="mt-2">{{'RETURN_ARTICLE'|translate}}</h3>
        <app-article-card class="d-block w-100" [article]="damagedArticle" [useLazyLoad]="false">
        </app-article-card>
      </ng-container>
  </div>
</div>
