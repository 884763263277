import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Basket } from "src/app/interfaces/basket";
import { BasketService } from "src/app/services/basket.service";
import { ApiResponse } from "src/app/interfaces/api-response";

@Component({
  selector: "app-edit-basket-name",
  templateUrl: "./edit-basket-name.component.html",
  styleUrls: ["./edit-basket-name.component.scss"]
})
export class EditBasketNameComponent implements OnInit {
  @Input() basket: Basket;
  initialName: string;
  loading: boolean;
  constructor(
    public modal: NgbActiveModal,
    private basketService: BasketService
  ) {}

  ngOnInit() {
    this.initialName = this.basket.name;
  }

  close(modal) {
    this.basket.name = this.initialName;
    modal.dismiss("");
  }

  save() {
    this.loading = true;
    this.basketService
      .updateBasket(this.basket)
      .subscribe((apiResponse: ApiResponse) => {
        this.loading = false;
        this.modal.dismiss();
      });
  }
}
