import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { FinancialService } from "src/app/services/financial.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { PackingSlipsFilters } from "src/app/interfaces/financial/packingslips/packingslips-filters";
import { PackingSlipsOptionInput } from "src/app/interfaces/financial/packingslips/packingslips-option-input";
import { PackingSlip } from "src/app/interfaces/financial/packingslips/packing-slip";
import { FinancialPaginatorData } from "src/app/interfaces/financial/financial-paginator-data";
import { ShippingAddressGroup } from "src/app/interfaces/shipping-address-group";
import { PackingSlipsFilterOptions } from "src/app/interfaces/financial/packingslips/packingslips-filter-options";
import { PackingSlipsSelectedFilters } from "src/app/interfaces/financial/packingslips/packingslips-selected-filters";
import { ApplicationService } from "src/app/services/application.service";
import { AlertService } from "src/app/services/alert.service";
import * as _ from "underscore";

@Component({
  selector: "app-packing-slips",
  templateUrl: "./packing-slips.component.html",
  styleUrls: ["./packing-slips.component.scss"]
})
export class PackingSlipsComponent implements OnInit {
  @Input() type: string;
  packingSlips: PackingSlip[];
  packingSlipsFilters: PackingSlipsFilters;
  pageData: FinancialPaginatorData;
  loaded = false;
  cachedResults: any = {};
  dateFrom: any = {
    year: '',
    month: '',
    day: ''
  };
  dateTo: any = {
    year: '',
    month: '',
    day: ''
  };
  formattedDateFrom: string;
  formattedDateTo: string;

  @Input() shippingAddressGroup: ShippingAddressGroup;
  hideStatus: boolean;
  constructor(
    private financialService: FinancialService,
    private applicationService: ApplicationService,
    private alertService: AlertService,
  ) {
    this.pageData = new FinancialPaginatorData();
    this.pageData.page = 1;
    this.packingSlipsFilters = new PackingSlipsFilters();
    this.packingSlipsFilters.options = new PackingSlipsOptionInput();
    this.packingSlipsFilters.options.article_type = "all";
    this.packingSlipsFilters.options.order_by = "deliveryweek";
    this.packingSlipsFilters.options.only_new_order_lines = false;
    this.packingSlipsFilters.options.workshop = false;
    this.packingSlipsFilters.options.accentry_id = "";
  }

  ngOnInit() {}

  getFilters(data) {
    this.cachedResults = {};
    const filters: PackingSlipsSelectedFilters = data["filters"];
    this.packingSlipsFilters.options = new PackingSlipsOptionInput();

    // Date from
    const dateFrom =
      typeof filters.date_from !== "string"
        ? this.financialService.getDate(filters.date_from)
        : this.financialService.formatDate(filters.date_from);
    this.packingSlipsFilters.options.date_from = dateFrom

    this.dateFrom = {
      year: dateFrom.substring(0, 4),
      month: dateFrom.substring(4, 6),
      day: dateFrom.substring(6, 8),
    }
    this.formattedDateFrom = `${this.dateFrom.day}-${this.dateFrom.month}-${this.dateFrom.year}`

    // Date to
    const dateTo =
      typeof filters.date_to !== "string"
        ? this.financialService.getDate(filters.date_to)
        : this.financialService.formatDate(filters.date_to);
    this.packingSlipsFilters.options.date_to = dateTo

    this.dateTo = {
      year: dateTo.substring(0, 4),
      month: dateTo.substring(4, 6),
      day: dateTo.substring(6, 8),
    }
    this.formattedDateTo = `${this.dateTo.day}-${this.dateTo.month}-${this.dateTo.year}`

     // Winora company filter
     this.packingSlipsFilters.options.company_id_filter = Number(filters["companyFilter"]) ? Number(filters["companyFilter"]) : null;;

    // Keyword
    if (filters.keyWord) {
      switch (filters["keyWordOption"]) {
        case "PackSlipNumber": {
          this.packingSlipsFilters.options.packing_slip_number =
            filters.keyWord;
          break;
        }
        case "OrderNumber": {
          this.packingSlipsFilters.options.ace_id = filters.keyWord;
          break;
        }
        case "OrderNumber_Accentry": {
          this.packingSlipsFilters.options.accentry_id = filters.keyWord;
          break;
        }
        case "OrderReference_Accentry": {
          this.packingSlipsFilters.options.remarks = filters.keyWord;
          break;
        }
        case "ArticleNo": {
          this.packingSlipsFilters.options.article_code = filters.keyWord;
          break;
        }
      }
    }

    this.packingSlipsFilters.next_page_reference = "";
    this.pageData.page = 1;

    if (data["getResults"] || ((this.packingSlips && !this.packingSlips.length) || !this.packingSlips)) {
      this.getPackingSlips();
    }
  }

  getPackingSlips() {
    if (this.cachedResults[this.pageData.page]) {
      this.packingSlips = this.cachedResults[this.pageData.page];

      if (this.cachedResults[this.pageData.page + 1]) {
        this.pageData.hasNextPage = true;
      }
      this.pageData.loading = false;
    } else {
      this.applicationService.showLoader(true);
      this.financialService
        .getPackingSlips(this.packingSlipsFilters)
        .subscribe((apiResponse: ApiResponse) => {
          this.loaded = true;
          this.applicationService.hideLoader();
          if (
            apiResponse &&
            apiResponse.success &&
            apiResponse.result &&
            apiResponse.result.length
          ) {
            const items = _.flatten(_.pluck(apiResponse.result, "items"));
            if (items.length > 0 && apiResponse.result.length) {
              this.packingSlips = items;
              const status = this.packingSlips.filter((p) => {
                p.order_status_text !== ""
              });

              if (!status.length) {
                this.hideStatus = true;
              }
              
              this.cachedResults[this.pageData.page] = items;

              // Page references
              this.pageData.hasNextPage = apiResponse.result[0]["ace_next_page"]
                ? true
                : false;

              this.pageData.acePreviousPage = items[0].packing_slip_number;
              this.pageData.aceNextPage = apiResponse.result[0]["ace_next_page"]
                ? this.packingSlips[this.packingSlips.length - 1]
                    .packing_slip_number
                : null;

              this.pageData.loading = false;
              window.scrollTo(0, 0);
            } else {
              this.packingSlips = [];
              this.cachedResults[this.pageData.page] = [];
              this.pageData.hasNextPage = false;
              this.pageData.loading = false;
              window.scrollTo(0, 0);
            }
          } else {
            this.alertService.showErrorNotification(apiResponse.result);
          }
        });
    }
  }

  navigationChanged(data) {
    this.packingSlipsFilters.next_page_reference = data;
    this.getPackingSlips();
  }
}
