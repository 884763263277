import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HelperService } from '../services/helper.service';

@Injectable({
  providedIn: 'root'
})
export class IsAuthorizedGuard implements CanActivate {

  constructor(private router: Router, private helperService: HelperService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const valid = localStorage.getItem("bearerToken") ? true : false;
    const hasKey = window.location.href.includes("key=");
    if (!valid && !hasKey) {
      this.router.navigateByUrl(
        `/login?redirectUrl=${this.helperService.getCurrentPath()}`
      );
    } else {
      return true;
    }
  }
}
