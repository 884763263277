import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { AuthenticateService } from "src/app/services/authenticate.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { VerifyPasswordContainer } from "src/app/interfaces/verify-password-container";
import { ChangePasswordContainerComponent } from "../../user-components/user-settings/change-password-container/change-password-container.component";
import { ApplicationService } from "src/app/services/application.service";
import 'url-search-params-polyfill';
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild(ChangePasswordContainerComponent) passwordContainer: ChangePasswordContainerComponent;

  username = "";
  type = "manual"
  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  resetted = false;
  password: string;
  passwordCheck: string;
  error: string;
  message: string;
  constructor(
    private authenticateService: AuthenticateService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    if (window.location.href.indexOf("user=") >= 0) {
      this.type = "verify";
      this.verifyData();
    } else {
      this.type = "manual";
    }
  }

  _createVerifyContainer():VerifyPasswordContainer {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userHash = urlParams.get('user');
    const passwordHash = urlParams.get('hash');
    const culture = urlParams.get('culture');
    if (userHash && passwordHash && culture) {
      return  new VerifyPasswordContainer(userHash, passwordHash, culture)
    } else {
      return null;
    }
  }

  verifyData() {
    const container = this._createVerifyContainer();
    this.applicationService.showLoader(true);
    this.authenticateService.verifyPasswordFromLink(container).subscribe((apiResponse: ApiResponse) => {
      this.applicationService.hideLoader();
      if (apiResponse && apiResponse.success) {
        this.username = apiResponse.result;
      } else {
        this.error = apiResponse.result;
        this.alertService.showErrorNotification(
          apiResponse && apiResponse.result
            ? apiResponse.result
            : this.translateService.instant("ERROR")
        );
      }
    })
  }

  resetPassword() {
    this.applicationService.showLoader(true);
    this.authenticateService
      .resetPassword(this.username)
      .subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        if (apiResponse && apiResponse.success) {
          this.message = this.translateService.instant("PASSWORD_LINK_SEND").replace("{0}",this.username);
          this.alertService.showInfoNotification(this.message);
          this.resetted = true;
        } else if (apiResponse) {
          this.alertService.showErrorNotification(apiResponse.result);
        } else {
          this.alertService.showErrorNotification(this.translateService.instant("ERROR"));
        }
      });
  }

  sendNewPassword(event) {
    if (this.passwordContainer.checkPasswordRequirements()) {
      this.applicationService.showLoader(true);
      const container = this._createVerifyContainer();
      container.password1 = this.passwordContainer.changePasswordContainer.new_password;
      container.password2 = this.passwordContainer.changePasswordContainer.confirm_password;
      this.authenticateService.changePasswordFromLink(container).subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        if (this.helperService.checkResponse(apiResponse)) {
          this.type = "verify_complete";
        } else {
          this.alertService.showErrorNotification(
            apiResponse && apiResponse.result
              ? apiResponse.result
              : this.translateService.instant("ERROR")
          );
        }
      })
    }
    event.preventDefault();
    return false;
  }
}
