export interface ServiceInput {
  "frame_number": string;
  "service_type": string;
  "service_date": string;
}

export class ServiceInput implements ServiceInput {
  constructor(frameNumber: string, serviceType: string, serviceDate: string) {
    this.frame_number = frameNumber;
    this.service_type = serviceType;
    this.service_date = serviceDate;
  }
}
