import { Component, OnInit, Input } from '@angular/core';
import { ChangePasswordContainer } from 'src/app/interfaces/change-password-container';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticateService } from 'src/app/services/authenticate.service';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { AlertService } from 'src/app/services/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password-container',
  templateUrl: './change-password-container.component.html',
  styleUrls: ['./change-password-container.component.scss']
})
export class ChangePasswordContainerComponent implements OnInit {
  @Input() modal: NgbModalRef;
  @Input() changePasswordContainer: ChangePasswordContainer;
  @Input() hideCurrentPassword: boolean;
  passwordMeetsRequirements: boolean = false;
  newPasswordValid: boolean;
  constructor(private authenticationService: AuthenticateService, private alertService: AlertService, private translateService: TranslateService) { }

  ngOnInit() {
    if (!this.changePasswordContainer) {
      this.changePasswordContainer = new ChangePasswordContainer();
    }
  }

  checkCurrentPassword() {
    if (this.hideCurrentPassword) {
      return true;
    } else {
      if (this.changePasswordContainer && !this.changePasswordContainer.old_password) {
        return false;
      }
    }
    return true;
  }

  checkPasswordRequirements() {
    const passwordRegex = new RegExp(
      "^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    let valid = true;
    this.newPasswordValid = false;
    if (this.changePasswordContainer.new_password.length < 8 || this.changePasswordContainer.new_password.length > 20) {
      valid = false;
    }
    if (!passwordRegex.test(this.changePasswordContainer.new_password)) {
      valid = false;
      this.passwordMeetsRequirements = false;
    } else {
      this.newPasswordValid = true;
      this.passwordMeetsRequirements = true;
    }

    if (this.changePasswordContainer.new_password && (!this.changePasswordContainer.confirm_password || (this.changePasswordContainer.confirm_password !== this.changePasswordContainer.new_password))) {
      valid = false;
      this.passwordMeetsRequirements = valid;
    }

    return valid;
  }

  submit() {
    return new Promise((approve, reject) => {
      if (
        this.checkCurrentPassword() && this.checkPasswordRequirements() &&
        this.changePasswordContainer.new_password ===
          this.changePasswordContainer.confirm_password
      ) {
        this.authenticationService
          .updatePassword(this.changePasswordContainer)
          .subscribe((apiResponse: ApiResponse) => {
            if (apiResponse.success) {
              if (this.modal) {
                this.alertService.showPopup(
                  this.translateService.instant("SAVED"),
                  "success"
                );
                this.modal.close();
              }
              if (this.modal) {
                this.alertService.showPopup(
                  this.translateService.instant("SAVED"),
                  "success"
                );
                this.modal.close();
              }
              approve(true);
            } else {
              this.alertService.showPopup(
                this.translateService.instant("ERROR"),
                "error"
              );
              approve(false);
            }
          });
      } else {
        approve(false);
      }
    })

  }
}
