
<ng-container *ngIf="selectedDealer && selectedDealerInformation">
  <div class="row">
    <div class="col-md-12 card border-0">
      <div class="card-body">
        <h4>{{'DEALER_SELECTED'|translate}}</h4>
        <div class="row">
          <div class="col-12">
            <h5 class="d-block">{{'INFORMATION'|translate}}</h5>
            {{selectedDealerInformation.name}}<br />
            {{selectedDealerInformation.address}}<br />
            {{selectedDealerInformation.zip}} {{selectedDealerInformation.city}}<br />
            {{selectedDealer.username}}<br />
            {{selectedDealer.account_code!==selectedDealer.username?selectedDealer.account_code:''}}
            <ng-container *ngIf="accountAgreement && accountAgreement.cm_dealer">
              <br/>
              <i class="material-icons small">check_circle</i> CM Dealer
            </ng-container>
            <button class="btn btn-sm btn-dark d-block mt-3" (click)="selectDealer()">{{'SELECT_DEALER'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
