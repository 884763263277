import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ViewChild,
} from "@angular/core";
import { ApplicationService } from "src/app/services/application.service";
import { Router } from "@angular/router";
import { KioskService } from "src/app/services/kiosk.service";
import { timer } from "rxjs";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-kiosk-mode-checker",
  templateUrl: "./kiosk-mode-checker.component.html",
  styleUrls: ["./kiosk-mode-checker.component.scss"],
})
export class KioskModeCheckerComponent implements OnInit {
  @Input() logoutModal: boolean;
  @Input() initial: boolean;
  @Input() description: string;
  @Output() onExitKioskMode = new EventEmitter<boolean>();
  @Output() kioskCheckerActive = new EventEmitter<boolean>();
  @ViewChild("ngOtpInput") ngOtpInputRef: any; //Get reference using ViewChild and the specified hash

  kioskMode: boolean;
  active: boolean;
  numbers: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 0];
  pinCode: number[] = [];

  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: true,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "50px",
      height: "50px",
    },
  };

  otp: string;
  showOtpComponent = true;
  @ViewChild("ngOtpInput") ngOtpInput: any;
  valid: boolean;

  constructor(
    private applicationService: ApplicationService,
    private router: Router,
    private kioskService: KioskService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.getKioskMode();
    this.valid = false;
    this.active = true;
    timer(400).subscribe(() => {
      this.resetInputs();
    });
  }

  getKioskMode() {
    this.kioskMode = this.applicationService.getKioskMode(this.kioskMode);
  }

  disableKioskMode() {
    if (!this.kioskService.getPinCode()) {
      this.applicationService.saveKioskMode(false);
      this.router.navigateByUrl(this.applicationService.getSelectCompanyGroupCode())
    } else {
      this.onExitKioskMode.emit(true);
    }
  }

  exitKioskMode() {
    if (this.valid && this.logoutModal) {
      this.kioskMode = false;
      this.applicationService.saveKioskMode("0");
      if (this.router.url.includes("kiosk")) {
        this.router.navigateByUrl("/");
      }
    } else if (typeof this.logoutModal !== "undefined") {
      this.alertService.showErrorNotification(
        this.translateService.instant("ACTIVATION.PIN-INVALID")
      );
      timer(400).subscribe(() => {
        this.resetInputs();
      });
    }
    if (this.valid) {
      this.onExitKioskMode.emit(true);
    }
  }

  onOtpChange(otp) {
    this.otp = otp;
    if (otp) {
      this.pinCode = otp.split("");
    } else {
      this.pinCode = [];
    }
    this.valid =
      this.otp === this.kioskService.getPinCode() ||
      (this.initial && this.otp.length === 5);
  }

  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  onConfigChange() {
    this.showOtpComponent = false;
    this.otp = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }

  updatePincode(selectedNumber: number) {
    this.pinCode.push(selectedNumber);
    this.ngOtpInputRef.setValue(this.pinCode.join(""));
  }

  backspace() {
    this.pinCode.splice(-1, 1);
    this.ngOtpInputRef.setValue(this.pinCode.join(""));
  }

  resetInputs() {
    this.pinCode = [];
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].type.toLowerCase() === "password") {
        inputs[i].value = "";
      }
    }
  }
}
