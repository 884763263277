import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { ShippingCosts } from '../interfaces/basket';

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  getInvoicePaymentUrl(invoiceNumber: string) {
    return this.http.get(
      environment.apiendpoint + `payments/invoices/${invoiceNumber}`
    );
  }

  getInvoicesPaymentUrl(invoiceNumbers: string[]) {
    return this.http.post(
      environment.apiendpoint + `payments/invoices`,
        invoiceNumbers
    );
  }

  getCheckoutPaymentUrl(basketId: number, selectedShippingIdBicycles: string, selectedShippingIdParts: string) {
    return this.http.post(
      environment.apiendpoint + `payments/baskets/${basketId}`,  {
        Bicycles: selectedShippingIdBicycles
          ? selectedShippingIdBicycles
          : undefined,
        Parts: selectedShippingIdParts ? selectedShippingIdParts : undefined,
      }
    );
  }
}
