export interface OpenOrdersResultsCount {
  total_bicycles: number;
  total_bicycles_lines: number;
  total_parts: number;
  total_parts_lines: number;
  total_service: number;
  total_service_lines: number;
}

export class OpenOrdersResultsCount implements OpenOrdersResultsCount {
  constructor() {
    this.total_bicycles = 0;
    this.total_bicycles_lines = 0;
    this.total_parts = 0;
    this.total_parts_lines = 0;
    this.total_service = 0;
    this.total_service_lines = 0;
  }
}
