import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { ApplicationService } from 'src/app/services/application.service';
import { HelperService } from 'src/app/services/helper.service';
import { PreorderService } from 'src/app/services/preorder.service';
@Component({
  selector: 'app-article-qr-modal',
  standalone: true,
  imports: [ QRCodeModule],
  templateUrl: './article-qr-modal.component.html',
  styleUrl: './article-qr-modal.component.scss'
})
export class ArticleQrModalComponent {
  articleCode: string;
  url: string;
  constructor(public modal: NgbActiveModal, private preorderService: PreorderService) {
  }

  ngOnInit() {
    this.url = this.preorderService.preOrderUrl(this.articleCode);
  }
}
