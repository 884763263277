import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { PricelabelSettings } from "../interfaces/pricelabels/pricelabel-settings";
import { Article } from "../interfaces/article";
import { Pricelabel } from "../interfaces/pricelabels/pricelabel";

@Injectable({
  providedIn: "root",
})
export class PricelabelService {
  constructor(private http: HttpClient) {}

  /**
   * Retrieves all pricelabels from the API
   */
  getPricelabels() {
    return this.http.get(
      environment.apiendpoint + "pricelabels/getpricelabels"
    );
  }

  /**
   * Get pricelabels in PDF format
   */
  printPricelabels() {
    return this.http.get(
      environment.apiendpoint + "pricelabels/printlabels?fileType=pdf"
    );
  }

  /**
   * Updates pricelabel settings
   * @param settings The new pricelabel settings to update
   */
  updateSettings(settings: PricelabelSettings) {
    return this.http.put(
      environment.apiendpoint + "pricelabels/updatelabelsettings",
      settings
    );
  }

  /**
   * Adds new articles for pricelabels
   * @param articleCodes Array of article codes to add
   */
  addArticles(articleCodes: string[]) {
    return this.http.post(
      environment.apiendpoint + "pricelabels/addpricelabels",
      articleCodes
    );
  }

  /**
   * Uploads a custom logo for pricelabels
   * @param fileData The file data of the custom logo
   */
  uploadLogo(fileData: string) {
    return this.http.post(environment.apiendpoint + "pricelabels/customlogo", {
      file_data: fileData,
    });
  }

  /**
   * Updates a specific pricelabel
   * @param label The pricelabel to update
   */
  updatePricelabel(label: Pricelabel) {
    return this.http.put(environment.apiendpoint + "pricelabels", label);
  }

  /**
   * Deletes a pricelabel by article code
   * @param articleCode The article code of the pricelabel to delete
   */
  deletePricelabel(articleCode: string) {
    return this.http.delete(
      environment.apiendpoint + "pricelabels/" + articleCode
    );
  }

  /**
   * Removes a specific label from a collection
   * @param collection The collection of labels
   * @param articleCode The article code of the label to remove
   */
  removeLabel(collection, articleCode) {
    for (let i = 0; i < collection.length; i++) {
      const item = collection[i];

      if (item.article_code === articleCode) {
        collection.splice(i, 1);
      }
    }
  }

  /**
   * Deletes all pricelabels
   */
  deleteAllPricelabel() {
    return this.http.delete(environment.apiendpoint + "pricelabels");
  }
}
