<ng-container *ngIf="article.setitem">
  <div class="p-3 d-flex justify-content-center" *ngIf="!groups || (groups && !groups.length)">
    <button class="btn btn-default text-primary" type="button" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      {{ 'LOADING_SETITEMS'|translate}}...
    </button>
  </div>
  <div class="p-3 mx-0 row" *ngIf="groups && groups.length" [ngClass]="showDetailed ? 'detailed' : 'compact'">
    <div class="ps-0 col-md-6 article-list">
      <h4>{{'AVAILABLE_ARTICLES' | translate }}</h4>
      <!-- Available groups -->
      <div class="available_groups scrollable customScrollbar">
        <app-set-item-group *ngFor="let group of groups" [group]="group" [showSelected]="false" [companyId]="companyId"
          (selectionChanged)="calcTotal()"></app-set-item-group>
      </div>
    </div>
    <div class="px-0 col-md-6 article-list">

      <!-- Selected articles -->
      <h4>{{'SELECTED_ARTS' | translate }}</h4>
      <app-set-item-group *ngFor="let group of groups" [group]="group" [showSelected]="true"
        (selectionChanged)="calcTotal()"></app-set-item-group>
      <app-article-sum class="mx-1 mt-1" [amount]="1" [netPrice]="nettPriceSum" *ngIf="!showroomMode"
        [retailPrice]="retailPriceSum"></app-article-sum>
      <span *ngIf="!showroomMode" class="mt-1" style="float: right;"> / </span>
      <app-article-sum class="mt-1 me-1" [amount]="1" [netPrice]="null" [retailPrice]="retailPriceSum">
      </app-article-sum>

      <!-- Basket controls -->
      <div class="px-2 pt-2 pb-3 mx-0 mt-5 col-md-12 row add-container">
        <div class="col-12 p-2 info my-2">
          <input type="checkbox" class="me-2" [(ngModel)]="incomplete" id="incomplete">
          <label for="incomplete" class="mb-0">{{"SETITEM_ORDER_INCOMPLETE"|translate}}</label>
        </div>
        <div class="col-md-7">
          <ng-container *ngIf="!preSelectedArticles">
            <label class="basketControls">{{'BASKET'|translate}}</label>
            <app-select-basket [basket]="basketId" (basketSelected)="basketSelected($event)"></app-select-basket>
          </ng-container>
        </div>
        <div class="pt-4 col-md-5">
          <div class="btn-toolbar justify-content-end" role="toolbar" aria-label="Add to basket" placement="top">
            <div class="input-group" [ngbTooltip]="tooltipText | currencyConvert:tooltipCurrencyText"
              [autoClose]="false" #t="ngbTooltip">
              <input type="number" [(ngModel)]="quantity" (mousedown)="quantityChange(t)" (change)="quantityChange(t)"
                class="pe-1 form-control form-control-lg" style="max-width: 64px" aria-label="Input group example"
                aria-describedby="btnGroupAddon" [min]="minOrderAmount" max="9999">
            </div>
            <div class="btn-group" role="group" aria-label="Add">
              <button class="btn btn-success addButton" [disabled]="!valid && !incomplete"
                (click)="addToBasket(preSelectedArticles ? true : false)">{{ (preSelectedArticles ? 'SAVE' : 'ADD') |
                translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
