import { Component, ElementRef, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { StoryblokComponents } from "src/app/components/storyblok/storyblok-components";
import { ApiResponse } from "src/app/interfaces/api-response";
import { CompanyPage } from "src/app/interfaces/cms/company-page";
import { ApplicationService } from "src/app/services/application.service";
import { CmsService } from "src/app/services/cms.service";
import { HelperService } from "src/app/services/helper.service";
import { StoryblokService } from "src/app/services/storyblok.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-storyblok-page",
  templateUrl: "./storyblok-page.component.html",
  styleUrls: ["./storyblok-page.component.scss"],
})
export class StoryblokPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  story = { content: null, name: "", html: false };
  components = StoryblokComponents;
  pageId: string;
  folders: string;

  constructor(
    private cmsService: CmsService,
    private helperService: HelperService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private elem: ElementRef,
    private storyblokService: StoryblokService
  ) { }

  ngOnInit() {
    this.folders = "";
    const urlParams = new URLSearchParams(window.location.search);
    this.route.params.subscribe((params) => {
      if (params["id"]) {
        const pageId = params["id"];

        if (urlParams.get('folders')) {
          this.folders = urlParams.get('folders');
        }

        this.getContent(pageId);
        this.pageId = pageId;
      }
    });

    // On storyblok content change
    this.storyblokService.changeEmitted$.subscribe(() => {
      this.getContent(this.pageId);
    });
  }

  getContent(pageId: string) {
    this.cmsService.getPage(`${pageId}${this.folders.length ? ('?folder=' + this.folders) : ''}`).subscribe((apiResponse: ApiResponse) => {
      if (this.helperService.checkResponse(apiResponse)) {
        this.story.html = false;
        const result = apiResponse.result as CompanyPage;

        try {
          const story = JSON.parse(result.language_definitions[0].content);
          if (typeof story.content !== "undefined") {
            this.story = story;
          } else {
            // render plain HTML (old CMS layout)
            this._setHtmlContent(story);
          }
        } catch (err) {
          this._setHtmlContent(result.language_definitions[0].content);
        }

        this.setBreadcrumbs(result.language_definitions[0].title);
      } else {
        this._setHtmlContent(this.translateService.instant("NOT_FOUND"));
      }
    });
  }

  setBreadcrumbs(title: string) {
    // Set breadcrumbs
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/home"
      ),
      new Breadcrumb(title, "#"),
    ];
  }

  _setHtmlContent(content: string) {
    this.story.html = true;
    const elements = this.elem.nativeElement.getElementsByClassName(`content`);
    if (elements && elements.length) {
      elements[0].innerHTML = this.storyblokService.formatContent(content);
    }
  }
}
