import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-warranty-label-card',
  templateUrl: './warranty-label-card.component.html',
  styleUrls: ['./warranty-label-card.component.scss']
})
export class WarrantyLabelCardComponent implements OnInit {
  @Input() logo: string;
  @Input() articleCount: number;
  constructor() { }

  ngOnInit() {
  }

}
