import { Component, OnInit } from "@angular/core";
import { FinancialService } from "src/app/services/financial.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Article } from "src/app/interfaces/article";
import { PackingSlipDetails } from "src/app/interfaces/financial/packingslips/packing-slip-details";
import { Subject } from "rxjs";
import { ApplicationService } from "src/app/services/application.service";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-packing-slip-article-list",
  templateUrl: "./packing-slip-article-list.component.html",
  styleUrls: ["./packing-slip-article-list.component.scss"]
})
export class PackingSlipArticleListComponent implements OnInit {
  packingSlipArticleSelected = new Subject<any>();
  articles: PackingSlipDetails[];
  packingSlipNumber: string;
  articleCode: string;
  constructor(
    public modal: NgbActiveModal,
    private financialService: FinancialService,
    private applicationService: ApplicationService,
    private helperService: HelperService
  ) {}

  ngOnInit() {}

  getPackSlipArticles() {
    this.applicationService.showLoader(true);
    this.financialService
      .getPackingSlipArticles(this.packingSlipNumber)
      .subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        if (this.helperService.checkResponse(apiResponse)) {
          this.articles = apiResponse.result;
        }
      });
  }

  select(articleCode: string, article: Article) {
    this.packingSlipArticleSelected.next({
      articleCode: articleCode,
      article: article
    });
    this.modal.close();
  }
}
