import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { LotteryRegistrationService } from "src/app/services/lottery-registration.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { LotteryRegistration } from "src/app/interfaces/lottery-registration";

@Component({
  selector: "app-lottery-registration",
  templateUrl: "./lottery-registration.component.html",
  styleUrls: ["./lottery-registration.component.scss"]
})
export class LotteryRegistrationComponent implements OnInit {
  formGroup: FormGroup;
  validated: boolean;
  registrationResponse = "";

  constructor(private lotteryRegistrationService: LotteryRegistrationService) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.formGroup = new FormGroup({
      frame_number: new FormControl(
        "",
        Validators.compose([Validators.minLength(9), Validators.maxLength(9)])
      ),
      order_number: new FormControl(
        "",
        Validators.compose([Validators.minLength(7), Validators.maxLength(9)])
      )
    });
  }

  submit() {
    this.validated = true;
    if (this.formGroup.valid) {
      const lotteryRegistration: LotteryRegistration = new LotteryRegistration(
        this.formGroup.get("frame_number").value,
        this.formGroup.get("order_number").value
      );

      this.lotteryRegistrationService
        .registerLotteryClaim(lotteryRegistration)
        .subscribe((apiResponse: ApiResponse) => {
          this.registrationResponse = apiResponse.success
            ? "success"
            : "danger";
          if (apiResponse.success) {
            this.formGroup.reset();
            this.validated = false;
          }
        });
    }
  }

  resetValidation() {
    this.validated = false;
  }
}
