export interface AgreementTypes {
  brands: boolean;
  sports: boolean;
  specials: boolean;
  bonus: boolean;
}

export class AgreementTypes implements AgreementTypes {
  constructor(
    brands: boolean,
    sports: boolean,
    specials: boolean,
    bonus: boolean
  ) {
    this.brands = brands;
    this.sports = sports;
    this.specials = specials;
    this.bonus = bonus;
  }
}
