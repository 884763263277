import {
  Component,
  OnInit,
  Input,
  ViewChild,
  HostListener,
  Output,
  EventEmitter,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { ArticleDetailsComponent } from "src/app/components/article-components/article-details/article-details.component";
import { Observable, of, Subject, Subscription, fromEvent } from "rxjs";
import {
  map,
  filter,
  debounceTime,
  distinctUntilChanged,
} from "rxjs/operators";
import { ArticleModalComponent } from "src/app/components/modal-components/article-modal/article-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-tree-view",
  templateUrl: "./tree-view.component.html",
  styleUrls: ["./tree-view.component.scss"],
})
export class TreeViewComponent implements OnInit {
  @Input() articles: Article[] = [];
  @Input() selectedArticle: Article;
  @Input() observer: Observable<{}[]>;
  @Input() selectForWarrantyMode = false;
  @Input() leaseRequest: boolean;
  @Input() isBom: boolean;
  @ViewChild(ArticleDetailsComponent) articleDetails: ArticleDetailsComponent;
  @Output() showArticleDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() articleSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() articleCheckedChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() articleExpanded: EventEmitter<any> = new EventEmitter<any>();
  selectedIndex = 0;
  imageSize = 56;
  textCollapsed = true;
  showDetails = true;
  checkAllState = false;
  keydown: Subscription;
  keyup: Subscription;

  get modalActive() {
    const modals = document.getElementsByClassName("modal");
    return modals.length ? true : false;
  }

  constructor(
    private ngbModal: NgbModal,
    private cdRef: ChangeDetectorRef,
    private applicationService: ApplicationService) {}

  ngOnInit() {
    this.imageSize = Number(localStorage.getItem("imageSize") ?? 56);
  }

  ngAfterViewInit() {
    this.selectFirst();

    if (this.observer) {
      this.observer.subscribe((initial) => {
        if (initial) {
          this.selectFirst();
        }
      });
    }

    this.setKeyEvents();

    this.applicationService.searchMenuActive.subscribe((open) => {
      if (open) {
        this.keyup.unsubscribe();
        this.keydown.unsubscribe();
      } else {
        this.setKeyEvents();
      }
    });
  }

  setKeyEvents() {
    // Otherwise artice details is not rendered
    this.keydown = fromEvent(document.body, "keydown").subscribe((e) => {
      if (!this.modalActive) {
        const keyCode = String(e["code"]).toLowerCase();
        if (keyCode === "arrowdown" || keyCode === "arrowup") {
          this.navigate(e);
        }
        if (keyCode === "numpadadd" && this.imageSize < 72) {
          this.imageSize += 8;
        }
        if (keyCode === "numpadsubtract" && this.imageSize > 48) {
          this.imageSize -= 8;
        }
        localStorage.setItem("imageSize", this.imageSize.toString());
      }
    });

    this.keyup = fromEvent(document.body, "keyup").subscribe((e) => {
      if (!this.modalActive) {
        const keyCode = String(e["code"]).toLowerCase();
        if (keyCode === "arrowdown" || keyCode === "arrowup") {
          this.selectArticle(
            {
              article: this.selectedArticle,
              index: this.selectedIndex,
              event: e,
            },
            this.selectedArticle
          );
          this.showDetails = true;
          e.stopPropagation();
        }

        if (keyCode === "arrowleft" || keyCode === "arrowright") {
          this.selectedIndex = 0;
        }
      }
    });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["articles"]) {
      this.checkAllState = false;
    }
  }

  navigate(event) {
    switch (String(event["code"]).toLowerCase()) {
      // Down
      case "arrowdown":
        if (this.selectedIndex < this.articles.length - 1) {
          this.selectedIndex++;
        }
        break;

      // up
      case "arrowup":
        if (this.selectedIndex > 0) {
          this.selectedIndex--;
        }
        break;
    }
    this.showDetails = false;
    this.selectedArticle = this.articles[this.selectedIndex];
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  selectFirst() {
    this.selectArticle(
      {
        article: this.articles[0],
        index: 0,
        event: null,
      },
      this.articles[0]
    );
  }

  selectArticle(data, article) {
    const checkBoxClicked =
      data.event &&
      data.event.srcElement &&
      data.event.srcElement.classList &&
      data.event.srcElement.classList.value &&
      data.event.srcElement.classList.value.indexOf("selectForAdd") >= 0;
    this.showDetails = true;
    // Select for add
    if (data.event && (data.event.ctrlKey || checkBoxClicked)) {
      article.selected = article && article.selected ? false : true;
      this.articleSelected.emit(data.article);
    }

    if (!checkBoxClicked && data.article) {
      this.selectedArticle = data.article;
      this.articleDetails.article = data.article;
      this.articleDetails.modelId = data.article.id;
      this.articleDetails.showBreadCrumbs = false;
      this.articleDetails.textCollapsed = true;
      this.selectedIndex = data.index;

      if (this.articleDetails) {
        this.articleDetails.showDetailed = false;
        this.articleDetails.article = data.article;
        this.articleDetails.modelId = data.article.id;
        this.articleDetails.showBreadCrumbs = false;
        this.articleDetails.textCollapsed = true;
        this.articleDetails.bomQuantity = data.article.quantity;

        if (window.innerWidth > 992) {
          this.articleDetails.showInstant();
        }
      }
    }
  }

  articleChecked($event) {
    this.articleCheckedChange.next($event);
  }

  openModal(article) {
    const modalRef = this.ngbModal.open(ArticleModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.article = article;
    modalRef.componentInstance.modelId = article["id"];
    modalRef.componentInstance.article.img = article["images"];
    modalRef.componentInstance.showInstant();
  }

  gotoArticleDetails(article) {
    this.showArticleDetails.emit(article);
    event.preventDefault();
    return false;
  }

  articleId(index, article) {
    return article.id;
  }

  expandArticle(article) {
    // get set items
    this.articleExpanded.emit(article);
  }

  checkAll() {
    this.articles.forEach((article: Article) => {
      if (article.single_article_code) {
        if (article.authorized_to_place_in_basket) {
          article.selected = this.checkAllState;
        }
        this.articleCheckedChange.next(article);
        if (article.bom_articles && article.bom_articles.length) {
          article.bom_articles.forEach((bom_article: Article) => {
            if (
              bom_article.single_article_code &&
              bom_article.authorized_to_place_in_basket
            ) {
              bom_article.selected = this.checkAllState;
              this.articleCheckedChange.next(bom_article);
            }
          });
        }
      }
    });
  }

  setImageSize(delta: number) {
    this.imageSize += delta;
    localStorage.setItem("imageSize", String(this.imageSize));
  }
}
