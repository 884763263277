<div class="table-responsive">
  <table class="table text-nowrap table-striped" *ngIf="articles && articles.length">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">{{'QUANTITY' | translate}}</th>
        <th scope="col">{{'DESCRIPTION' | translate}}</th>
        <th scope="col">{{'FRAMENUMBER' | translate}}</th>
        <th scope="col">{{'EMPLOYEE' | translate}}</th>
        <th scope="col">{{'TYPE' | translate}}</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let article of articles; let articleIndex = index">
        <app-warranty-details-article
          [article]="article"
          [showControls]="showControls"
          (deleted)="deleteArticle(articleIndex)"
          (added)="added.emit(true)"
          class="d-table-row"
          *ngIf="article"
        >
        </app-warranty-details-article>
      </ng-container>
    </tbody>
  </table>
</div>
