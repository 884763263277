import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "shippingAddressText"
})
export class ShippingAddressTextPipe implements PipeTransform {
  transform(item: {}, args?: any): any {
    let description = "";
    if (item["shipping_address_line_1"]) {
      description += item["shipping_address_line_1"] + " ";
      description += item["shipping_address_line_2"] + " ";
      description += item["shipping_address_line_3"] + " ";
      description += item["shipping_address_line_4"] + " ";
      description += item["shipping_address_line_5"];
    }

    return description;
  }
}
