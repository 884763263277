import { Component, Input, OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
  @Input() text: string;
  @Input() class: string;
  @Input() companyGroups: string[];
  @Input() companyId: number;
  @Input() companyIds: number[] = [];

  get formattedCompanyId() {
    return Number(this.companyId);
  }

  constructor(public applicationService: ApplicationService) { }

  ngOnInit() {

  }

}
