<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" [innerHTML]="title"></h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss()"
  >
    </button>
</div>

<div class="modal-body p-3" style="top: 0">
  <swiper-container
    [navigation]="true"
    [slidesPerView]="1"
    [zoom]="true"
    class="large mb-2 lightbox-carousel image-carousel"
    init="false"
    #swiper
    [thumbs]="{ swiper: thumbsSwiper }"
    *ngIf="articleImages.length > 0"
    thumbs-swiper=".myLightboxSwiper"
    >
    <ng-container *ngFor="let img of articleImages; index as i">
      <swiper-slide>
          <div class="image-wrapper" style="overflow: hidden">
            <div class=" swiper-zoom-container">
            <img [src]="img.big" class="img" loading="lazy" />
          </div>
        </div>
      </swiper-slide>
    </ng-container>
  </swiper-container>
  <swiper-container
    (swiper)="thumbsSwiper = $event"
    [spaceBetween]="10"
    [slidesPerView]="4"
    [freeMode]="true"
    [watchSlidesProgress]="true"
    class="myLightboxSwiper d-flex swiper-thumbs"
    *ngIf="articleImages.length > 1"
  >
    <ng-container *ngFor="let img of articleImages; index as i">
      <swiper-slide
        ><img [src]="img.small" class="img clickable withOpacity" loading="lazy"
      /></swiper-slide>
    </ng-container>
  </swiper-container>
</div>
