<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "REPLENISHMENT_SELECTION" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    </button>
</div>
<div class="modal-body">
  <table class="table" *ngIf="loaded">
    <thead>
      <tr class="fw-bold">
        <td>{{ "ARTICLE_NO" | translate }}</td>
        <td>{{ "BATTERY" | translate }}</td>
        <td>{{ "DESCRIPTION" | translate }}</td>
        <td>{{ "STATUS" | translate }}</td>
        <td style="max-width: 50px">{{ "CURRENT_STOCK" | translate }}</td>
        <td style="min-width: 120px">{{ "CURRENT_MIN_STOCK" | translate }}</td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let article of articles; let i = index">
        <tr *ngIf="article.min_stock > 0">
          <td class="py-1">
            <span [articleQuickView]="article.article_code">{{
              article.article_code
            }}</span>
          </td>
          <td class="py-1">
            <span
              class="battery-indication"
              [articleQuickView]="article.battery_article_code"
              *ngIf="article.battery_article_code"
            >
              {{ article.battery_article_code }}
            </span>
          </td>
          <td class="py-1">{{ article.article_description }}</td>
          <td class="py-1">
            <app-stock-status [article]="article"></app-stock-status>
          </td>
          <td class="py-1">{{ article.stock }}</td>
          <td class="py-1" style="max-width: 50px">{{ article.min_stock }}</td>
          <td class="py-1 d-flex justify-content-end" style="min-width: 120px">
            <button
              class="text-danger me-2 btn btn-default"
              (click)="remove(article, i)"
              *ngIf="!readonly"
            >
              {{ "DELETE" | translate }}
            </button>
            <button
              class="btn btn-sm btn-primary"
              (click)="edit(article.article_code)"
              *ngIf="!readonly"
            >
              {{ "EDIT" | translate }}
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div
    *ngIf="!loaded"
    class="spinner-grow text-primary spinner-loader"
    role="status"
  >
    <span class="visually-hidden">{{ "LOADING" | translate }}...</span>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-dark" (click)="modal.dismiss('Close')">
    {{ "CLOSE" | translate }}
  </button>
</div>
