import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BasketService } from "src/app/services/basket.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";

@Component({
  selector: "app-new-basket",
  templateUrl: "./new-basket.component.html",
  styleUrls: ["./new-basket.component.scss"]
})
export class NewBasketComponent implements OnInit {
  @Output() basketCreated: EventEmitter<any> = new EventEmitter<any>();
  name = "";

  constructor(
    public modal: NgbActiveModal,
    private basketService: BasketService,
    private translateService: TranslateService
  ) {
    this.name = `${this.translateService.instant("BASKET")} ${moment().format(
      "DD-MM-YYYY"
    )}`;
  }

  ngOnInit() {}

  save() {
    this.basketService
      .createBasket(this.name)
      .subscribe((response: ApiResponse) => {
        this.basketCreated.emit(response.result["id"]);
        this.modal.dismiss();
      });
  }
}
