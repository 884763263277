<div class="row">
  <div class="col-12">
    <app-preorder-container *ngIf="articles && articles.length && !scanning" [articles]="articles" [showButtons]="false">
    </app-preorder-container>
    <div id="media-container" class="w-100 position-relative">
      <video id="video"></video>
      <canvas id="canvas" hidden></canvas>
    </div>
    <p class="p-2 text-center p-4" (click)="reset()" [hidden]="scanning">
      <img src="/assets/images/scan.svg" class="bg-success qr-code p-2 me-1 mt-2 border rounded-circle" height="120px">
    </p>
    <ngb-alert type="danger" class="m-3" *ngIf="noResults">{{"NO_RESULTS"|translate}}</ngb-alert>
  </div>
</div>
