import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { BasketArticles } from 'src/app/interfaces/basket-articles';
import { MultipleUpdateResult } from 'src/app/interfaces/basket/multiple-update-result';
import { BasketArticle } from 'src/app/interfaces/basketArticle';
import { ApplicationService } from 'src/app/services/application.service';
import { BasketService } from 'src/app/services/basket.service';
import { HelperService } from 'src/app/services/helper.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-select-parts-delivery-week',
  templateUrl: './select-parts-delivery-week.component.html',
})
export class SelectPartsDeliveryWeekComponent implements OnInit {
  @Input() groupedArticles: BasketArticles;
  @Input() basketId: number;
  @Output() updating: EventEmitter<boolean> = new EventEmitter<boolean>();
  monthList = [];
  currentDate: Date;
  selectedWeek: string;

  constructor(
    public applicationService: ApplicationService,
    private translateService: TranslateService,
    private basketService: BasketService,
    private helperService: HelperService
  ) {
    this.currentDate = new Date();
  }

  ngOnInit() {
    this.renderWeeks();
  }

  renderWeeks() {
    let monthList = {};
    const currentYear = moment().year();

    const canBeOrderedThisYear = currentYear === currentYear;
    monthList = Object.assign(
      monthList,
      this.addYear(moment().isoWeek(), 0, null)
    );
    this.monthList = this.addYear(1, 1, monthList);
    this.monthList = this.parseMonthList(monthList);
  }

  parseMonthList(obj: Object) {
    if (obj) {
      let ownProps = Object.keys(obj),
        i = ownProps.length,
        resArray = new Array(i); // preallocate the Array
      while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

      return resArray;
    } else {
      return [];
    }
  }

  addYear(startWeek, yearDelta, currentList) {
    let monthList = currentList === null ? {} : currentList;
    const currentYear = Number(moment().format('YYYY'));

    for (
      let i = startWeek;
      i <= (yearDelta === 0 ? 52 : moment().isoWeek());
      i++
    ) {
      const key = this.applicationService
        .getDateOfWeek(currentYear + yearDelta, i)
        .format('YYYYMM');

      if (!monthList[key]) {
        monthList[key] = [];
      }

      // Add the week to the month list
      if (
        Number(currentYear + yearDelta) > this.currentDate.getFullYear() ||
        (Number(currentYear) === this.currentDate.getFullYear() &&
          i >= moment().isoWeek())
      ) {
        monthList[key].push({
          value: String(currentYear + yearDelta) + this.getConvertedMonth(i),
          month: this.applicationService
            .getDateOfWeek(currentYear + yearDelta, i)
            .format('MM'),
          text:
            i === moment().isoWeek() && currentYear + yearDelta === currentYear
              ? this.translateService.instant('DIRECT')
              : i +
                ' ' +
                (currentYear + yearDelta) +
                ' (' +
                this.applicationService
                  .getDateOfWeek(currentYear + yearDelta, i)
                  .format('DD-MM-YYYY') +
                ')',
        });
      }
    }
    return monthList;
  }

  getConvertedMonth(weekNumber) {
    const stringMonth = String(weekNumber);
    return stringMonth.length < 2 ? '0' + stringMonth : stringMonth;
  }

  getMonthDescription(month) {
    const monthYear = month.substr(0, 4);
    const monthIndex = Number(month.substr(4, 6));
    return (
      this.translateService.instant(
        `MONTH_${monthIndex < 10 ? '0' + monthIndex : monthIndex}`
      ) +
      ' ' +
      monthYear
    );
  }

  getMonthDescriptionShort(month) {
    const monthDescription = month.substr(4, 6);
    return monthDescription;
  }

  selectWeek(data) {
    let isV2 = this.applicationService.isV2Basket();
    const deliveryWeek = this.selectedWeek.substr(4, 2);
    const deliveryYear = this.selectedWeek.substr(0, 4);

    if (!isV2) {
      this.groupedArticles.items.forEach((b: BasketArticle) => {
        const article = { ...b };
        article.delivery_week = deliveryWeek;
        article.delivery_year = deliveryYear;
        b.updating_delivery = true;
        if (
          Number(b.delivery_week) !== Number(deliveryWeek) ||
          Number(b.delivery_year) !== Number(deliveryYear)
        ) {
            const article = { ...b };
            article.delivery_week = deliveryWeek;
            article.delivery_year = deliveryYear;
            this.basketService
              .updateArticle(this.basketId, article)
              .subscribe((apiResponse: ApiResponse) => {
                b.updating_delivery = false;

                if (this.helperService.checkResponse(apiResponse)) {
                  const responseArticle = apiResponse.result[
                    'item'
                  ] as BasketArticle;
                  b.delivery_week = responseArticle.delivery_week;
                  b.delivery_year = responseArticle.delivery_year;
                }
              });
          }
          else {
            b.updating_delivery = false;
          }
        })
    } else {

      this.groupedArticles.items.forEach((b: BasketArticle) => {
        b.delivery_week = deliveryWeek;
        b.delivery_year = deliveryYear;
        b.updating_delivery = true;
      })

      this.updating.emit(true);
      this.basketService
        .updateMultipleArticles(this.basketId, this.groupedArticles.items)
        .subscribe((apiResponse: ApiResponse) => {
          this.updating.emit(false);
          this.groupedArticles.items.forEach((b: BasketArticle) => {
            b.updating_delivery = false;
          })

          if (this.helperService.checkResponse(apiResponse)) {
            const updateFeedback = apiResponse.result as MultipleUpdateResult;
            const failedIds = _.pluck(updateFeedback.failed_items, 'id');
            const succesIds = _.pluck(updateFeedback.items, 'id');

            this.groupedArticles.items.forEach((b: BasketArticle) => {
              if (failedIds.includes(b.id)) {
                b.warning = this.translateService.instant(
                  'UPDATE_BASKET_ARTICLE_FAILED'
                );
              }

              b.updating_delivery = false;
            });
          }
        });
    }
  }

  weekValue(index, week) {
    return week.value;
  }
}
