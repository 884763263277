import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: "app-accentry-logo",
  templateUrl: "./accentry-logo.component.html",
  styleUrls: ["./accentry-logo.component.css"],
})
export class AccentryLogoComponent implements OnInit {
  active: boolean
  customLogo = false;
  customLogoSrc = "";

  constructor(private applicationService: ApplicationService) {}

  ngOnInit(): void {
    if (this.applicationService.isPartnerPortal()) {
      this.customLogo = true;
      this.customLogoSrc = "assets/Partner_store.png"
    }
    setTimeout(() => {
      this.active = true;
    }, 50)
  }
}
