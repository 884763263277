import { Component, OnInit, Input } from '@angular/core';
import { BasketSumByBrand } from 'src/app/interfaces/basket/basket-sum-by-brand';

@Component({
  selector: 'app-basket-sum-by-brand',
  templateUrl: './basket-sum-by-brand.component.html',
  styleUrls: ['./basket-sum-by-brand.component.scss']
})
export class BasketSumByBrandComponent implements OnInit {
  @Input() basketArticlesByBrand: BasketSumByBrand[];
  constructor() { }

  ngOnInit() {
  }

  getTotalQuantity() {
    let total = 0;
    for (const brand of this.basketArticlesByBrand) {
      total += brand.quantity;
    }
    return total;
  }

  getTotalAmount() {
    let total = 0;
    for (const brand of this.basketArticlesByBrand) {
      total += brand.sum;
    }
    return total;
  }

}
