<app-set-item-button [article]="article" class="mb-1 d-inline-block" *ngIf="article.setitem"></app-set-item-button>

<div class="btn-toolbar align-items-stretch" role="toolbar" aria-label="Add to basket">
  <ng-container *ngIf="!article.setitem && article.authorized_to_order && article.authorized_to_place_in_basket">
    <div class="input-group" *ngIf="hasInput">
      <div class="input-group-prepend btn-succes d-flex"
        *ngIf="article.authorized_to_place_in_basket"
        (click)="article.single_article_code ? quickAdd($event) : addToBasket($event)">
        <div class="ps-0 input-group-text" id="btnGroupAddon">
          <i class="material-icons clickable withOpacity quickAdd bg-success"
            [title]="'QUICK_ADDTOBASKET' | translate " *ngIf="!loading">add_shopping_cart</i>
          <div class=" spinner-border text-primary" role="status" *ngIf="loading">
            <span class="visually-hidden">{{'LOADING'|translate}}</span>
          </div>
        </div>
      </div>
      <p-inputNumber inputId="integeronly" type="number" numberOnly [allowDecimals]="false" [(ngModel)]="quantity"
        [inputStyle]="{'width': '56px'}" [styleClass]="'me-1'" [inputStyleClass]="'me-1 ps-2 pe-0'"
        aria-label="Input group example" aria-describedby="btnGroupAddon" [min]="article.min_order_amount ? article.min_order_amount : 1" max="9999"  ngDefaultControl [showButtons]="true"></p-inputNumber>
    </div>
    <div class="me-0 btn-group me-md-2 me-lg-2 me-xl-2" role="group">
      <button class="btn addButton" pRipple (click)="addToBasket($event)"
        [disabled]="!article.authorized_to_place_in_basket"
        [ngClass]="[!article.authorized_to_place_in_basket ? 'disabled btn-default' :'btn-success', 'btn-' + size]">{{'ORDER'
      | translate}}</button>

      <button class="pt-2 btn btn-dark addButton addToDropshipment ms-1" [disabled]="!article.single_article_code"
        *ngIf="article.dropshipment" ngbTooltip="{{'DROPSHIPMENT_BASKET'|translate}}" placement="bottom"
        (click)="addToDropShipmentBasket()">
        <i class="material-icons">home</i>
      </button>
    </div>
  </ng-container>
  <ng-content></ng-content>
</div>
