export interface SetitemBasketArticle {
  basket_id: number;
  article_code: string;
  set_items: string[];
  quantity: number;
  remarks: string;
  sold: boolean;
  delivery_year_week: string;
  checkout: boolean;
  basket_item_id: number;
  update: boolean;
}
export class SetitemBasketArticle implements SetitemBasketArticle {}
