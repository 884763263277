import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-check-icon',
  templateUrl: './success-check-icon.component.html',
  styleUrls: ['./success-check-icon.component.scss']
})
export class SuccessCheckIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
