import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import { HelpdeskService } from "../services/helpdesk.service";

@Injectable({
  providedIn: "root"
})
export class IsInternalUserGuard implements CanActivate {
  constructor(private helpdeskService: HelpdeskService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const selectedDealer = this.helpdeskService.getSelectedDealer();
    return selectedDealer ? true : false;
  }
}
