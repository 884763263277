import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-warranty-complain-description-inputs",
  templateUrl: "./warranty-complain-description-inputs.component.html",
  styleUrls: ["./warranty-complain-description-inputs.component.scss"]
})
export class WarrantyComplainDescriptionInputsComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() tabSelected: string;
  @Input() hideComplaintCode: boolean;
  @Input() maxQuantity: number = 25;
  framenumber: string;



  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.maxQuantity < this.formGroup.get("quantity").value) {
      this.formGroup.get("quantity").setValue(1)
    }
  }

  ngOnInit() {
    this.framenumber = this.formGroup.get("framenumber").value;

    if (this.tabSelected === "battery" && !this.formGroup.get("batteryType").value) {
      this.formGroup.get("batteryType").enable();
      this.formGroup.get("batteryType").setValidators([Validators.required]);
      this.formGroup.get("batteryType").updateValueAndValidity();
    }
  }

  checkQty(event) {
    if (event.srcElement.value === "") {
      this.formGroup.get("quantity").setValue(1);
    }
    if (Number(event.srcElement.value) > this.maxQuantity) {
      this.formGroup.get("quantity").setValue(this.maxQuantity);
    }
  }
}
