<div class="card p-3 border-0">
  <div class="text-center border-0 card-body" [ngClass]="{ deleted: deleted }">
    <div class="img-wrapper d-flex align-items-center justify-content-center">
      <img *ngIf="fileType === 'image'" [src]="image.cdn_name + '?width=200'" class="img-responsive" loading="lazy" />
      <i class="material-icons" *ngIf="fileType === 'pdf'">picture_as_pdf</i>
      <i class="material-icons" *ngIf="fileType === 'other_image'">image</i>
      <i class="material-icons" *ngIf="fileType === 'video'">movie</i>
    </div>
  </div>
  <div class="border-0">
    <table class="table table-sm">
      <tr>
        <th>{{ "BRAND" | translate }}:</th>
        <td>{{ image.brand_description }}</td>
        <td rowspan="2">
          <button class="btn btn-sm btn-primary imageBankDownloadButton" *ngIf="!deleted"
            (click)="downloadImage(image.cdn_name, image.cdn_name.substring(this.image.cdn_name.lastIndexOf('/') + 1))">
            {{ "DOWNLOAD" | translate }}
          </button>
        </td>
        <td></td>
      </tr>
      <tr>
        <th>{{ "FILESIZE" | translate }}:</th>
        <td>{{ image.file_size }} Kb</td>
        <td></td>
      </tr>
      <tr>
        <th>{{ "NAME" | translate }}:</th>
        <td colspan="2">
          {{ image.cdn_name.substring(this.image.cdn_name.lastIndexOf("/") + 1) }}
        </td>
      </tr>
      <tr *ngIf="image.cdn_name.includes('PDF') && !deleted">
        <th class="pt-3">Url:</th>
        <td colspan="2" class="pt-3">
          {{ image.cdn_name }}
          <span class="material-icons clickable withOpacity" (click)="copy(image.cdn_name)">
            content_copy
          </span>
        </td>
      </tr>
      <tr *ngIf="showDeleteButton && !deleted">
        <th colspan="2">
          <button class="ps-0 btn btn-link text-danger px-2" (click)="deleteImage(image.id)">
            {{ "DELETE" | translate }}
          </button>
        </th>
      </tr>
      <tr *ngIf="showDeleteButton && deleted">
        <th colspan="2">
          <span class="text-warning">{{ "DELETED" | translate }}</span>
        </th>
      </tr>
    </table>
  </div>
</div>
