import { Component, OnInit, SimpleChanges, Input } from "@angular/core";
import { CmsService } from "src/app/services/cms.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { NewsItem } from "src/app/interfaces/cms/news-item";
import { NewsFilters } from "src/app/interfaces/cms/news-filters";
import { ApplicationService } from "src/app/services/application.service";
import * as appGlobal from "src/app/globals";
import { ActivatedRoute } from "@angular/router";
import { HelperService } from "src/app/services/helper.service";
import { ConfirmDialogComponent } from "../../modal-components/confirm-dialog/confirm-dialog.component";
import { NewsDetailsComponent } from "../news-details/news-details.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { StoryblokService } from "src/app/services/storyblok.service";
@Component({
  selector: "app-news-overview",
  templateUrl: "./news-overview.component.html",
  styleUrls: ["./news-overview.component.scss"],
})
export class NewsOverviewComponent implements OnInit {
  page: number;
  filters: NewsFilters;
  @Input() searchText: string;
  companyGroup: string;
  newsItems: NewsItem[];
  totalResults = 0;
  loading = false;
  newsId: number;
  activeFound: boolean;
  id: any;
  folders: string = "";
  constructor(
    private cmsService: CmsService,
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private helperService: HelperService,
    private modalService: NgbModal,
    private storyblokService: StoryblokService
  ) {}

  ngOnInit() {
    this.companyGroup = this.applicationService.getSelectCompanyGroupCode();
    if (!appGlobal.companyGroupSettings) {
      this.applicationService.getSettingsForCompanyGroup(this.companyGroup);
    }
    this.filters = new NewsFilters();
    this.filters.company_ids = appGlobal.companyGroupSettings
      ? appGlobal.companyGroupSettings.companies
      : [Number(this.companyGroup)];
    this.newsItems = Array<NewsItem>();
    this.filters.page = 1;
    this.filters.page_size = 24;
    this.getNews(true);


    const urlParams = new URLSearchParams(window.location.search);
    this.route.params.subscribe((params) => {
      if (params["id"]) {
        const id = params["id"];
        this.getNewsItem(id);
        this.id = id;
      }

      if (urlParams.get('folders')) {
        this.folders = urlParams.get('folders');
      }
    });
    // On storyblok content change
    this.storyblokService.changeEmitted$.subscribe(() => {
      this.getNewsItem(this.id);
    });
  }

  detectClear() {
    const lastSearch = this.filters.search_text;
    setTimeout(() => {
      if (
        !this.filters.search_text &&
        lastSearch !== this.filters.search_text
      ) {
        this.getNews(true);
      }
    }, 0);
  }

  getNews(reset) {
    if (reset) {
      this.totalResults = 0;
      this.filters.page = 1;
      this.newsItems = Array<NewsItem>();
    }
    this.loading = true;
    this.cmsService.getNews(this.filters).subscribe((apiResponse: ApiResponse) => {
      if (this.helperService.checkResponse(apiResponse)) {
        this.totalResults = apiResponse.result.total_items;
        this.newsItems = this.newsItems.concat(apiResponse.result.items);
        this.loading = false;
        this.applicationService.hideLoader();
      }
    });
  }

  // Get news item details
  getNewsItem(newsId: String) {
    this.loading = true;
    this.cmsService.getNewsItem(`${newsId}${this.folders.length ? ('?folder=companyPage/' + this.folders) : ''}`).subscribe((apiResponse: ApiResponse) => {
      if (this.helperService.checkResponse(apiResponse)) {
        const modalRef = this.modalService.open(ConfirmDialogComponent, {
          size: "lg",
          windowClass: "medium",
          container: "#modalContainer",
        });

        const newsItem = Object.assign({}, apiResponse.result);
        newsItem.detailled = true;
        modalRef.componentInstance.isConfirm = false;

        modalRef.componentInstance.setContent(
          NewsDetailsComponent,
          "newsItem",
          newsItem
        );
        this.loading = false;
      }
    });
  }

  showMore() {
    this.filters.page++;
    this.getNews(false);
  }
}
