<!-- <div *ngIf="formGroup.get('framenumber').value && !manualSelect">
  <div class=" mb-3 row"> <label for="guaranteeDate"
      class="col-md-4 control-label text-start">{{'WARRANTY'|translate}}</label>
    <div class="col-md-4">
      <i class="material-icons text-success" *ngIf="hasGuarantee">check</i>
      <ng-container *ngIf="!hasGuarantee">
        <i class="material-icons text-danger">clear</i> {{'NO_GUARANTEE_FOR_ARTICLE'|translate}}
      </ng-container>
    </div>
  </div>
</div> -->
<div [hidden]="!formGroup.get('articleCode').value">
  <div [style.display]="manualSelect ? '' : 'none'">
    <h3 class="mb-1">{{'GUARANTEE_INFORMATION'|translate}}</h3>

    <div class="mb-3 row"
      [style.display]="manualSelect && ((warrantyPathCode !== 'C' && warrantyPathCode !== 'D') || formGroup.get('framenumber').value) ? '' : 'none'">
      <label for="guaranteeDate" class="col-md-4 control-label text-start">{{'PURCHASE_DATE'|translate}}</label>
      <div class="col-md-5">
        <div class="input-group position-relative">
          <div [formGroup]="formGroup">
            <input class="form-control" name="guaranteeDate" (click)="d.toggle(); selectedDate = startDate"
              formControlName="guaranteeDate" placeholder="dd-mm-YYYY" [(ngModel)]="guaranteeDate">
          </div>
          <input type="text" class="d-none form-control" (click)="d.toggle(); selectedDate = startDate"
            (ngModelChange)="formatDate($event)" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="selectedDate"
            ngbDatepicker #d="ngbDatepicker" [startDate]="startDate">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle(); selectedDate = startDate"
              type="button">
              <i class="material-icons" style="font-size: 16px">date_range</i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3 row" *ngIf="manualSelect"> <label for="guaranteeDate"
        class="col-md-4 control-label text-start">{{'INVOICE'|translate}}</label>
      <div class="col-md-4" [formGroup]="formGroup">
        <app-warranty-file-upload [formGroup]="formGroup" [warrantyId]="warrantyId"></app-warranty-file-upload>
      </div>
    </div>

    <ngb-alert [dismissible]="false" *ngIf="!articleInDealerHistory && this.tabSelected === 'separate_part'">
      {{'WARRANTY_ARTICLE_NO_HISTORY'|translate}}
    </ngb-alert>
  </div>
  <div class="mb-3 row"> <label for="returnCondition"
      class="col-md-4 control-label text-start">{{'RETURN_CONDITION'|translate}}</label>
    <div class="col-md-6" [formGroup]="formGroup">
      <select class="form-control" formControlName="returnCondition" name="returnCondition"
        (change)="checkArticleGuarantee()">
        <option [value]=""></option>
        <option [value]="1">{{"RETURN_CONDITION_MALFUNCTION"|translate}}</option>
        <option [value]="2">{{"RETURN_CONDITION_RUST"|translate}}</option>
        <option [value]="3">{{"RETURN_CONDITION_BROKEN"|translate}}</option>
      </select>
    </div>
  </div>

  <!-- PATHCODE !== E -->
  <ngb-alert [dismissible]="false"
    [type]="!warrantyCompensation.compensation_amount ? 'danger' : warrantyPathMessages[warrantyPathCode]['status']"
    *ngIf="warrantyPathMessages[warrantyPathCode] && warrantyCompensation && warrantyPathCode !== 'E'">

    <ng-container
      *ngIf="warrantyCompensation && warrantyCompensation.compensation_amount">

      <i class="material-icons text-success" style="transform: translateY(5px);">check</i> &euro; {{numberFormat.formatDot(warrantyCompensation.compensation_amount)}}
    </ng-container>

    <ng-container *ngIf="warrantyCompensation.compensation_amount">
      {{warrantyPathMessages[warrantyPathCode]['msg']|translate}}
    </ng-container>

    <ng-container *ngIf="!warrantyCompensation.compensation_amount">
      {{"NO_COMPENSATION"|translate}}
    </ng-container>

    <ng-container *ngIf="warrantyPathCode === 'C' && this.tabSelected === 'separate_part'">
      <a [href]="warrantyCompensation.manufacturer_url" target="_new">{{'SEND_INSTRUCTIONS_URL'|translate}}</a>
    </ng-container>
  </ngb-alert>

  <!-- PATHCODE +== E -->
  <ngb-alert [dismissible]="false" *ngIf="warrantyPathCode === 'E'" type="warning">

    <div class="my-2" *ngIf="warrantyCompensation && warrantyCompensation.compensation_amount">
      <i class="material-icons text-success" style="transform: translateY(5px);">check</i> &euro; {{numberFormat.formatDot(warrantyCompensation.compensation_amount)}}
      {{"COMPENSATION"|translate}}
    </div>

    <div class="my-2" *ngIf="warrantyCompensation && !warrantyCompensation.compensation_amount">
      {{"NO_COMPENSATION"|translate}}
    </div>

    <strong>{{"DISPOSE_ARTICLE"|translate}}</strong>
  </ngb-alert>
</div>
