import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SearchService } from "src/app/services/search.service";
import { Observable, of, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  map,
  tap
} from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-article-finder-modal",
  templateUrl: "./article-finder-modal.component.html",
  styleUrls: ["./article-finder-modal.component.scss"]
})
export class ArticleFinderModalComponent implements OnInit {
  model: any;
  searching = false;
  searchFailed = false;
  showUnknown = true;
  placeholder = "";
  assetsPath = "assets/images/";
  @ViewChild('searchText') input: ElementRef;
  autocompleteVisible: boolean = true;
  showSuggestions = true;
  resultCount = 0;
  focus: boolean = false;
  articleCodeHasBeenSelected: boolean = false;
  public articleSelected$ = new Subject();

  constructor(
    public modal: NgbActiveModal,
    private searchService: SearchService,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.placeholder = this.assetsPath + "part.svg";
  }

  ngAfterViewInit() {
    this.renderer.selectRootElement(this.input["nativeElement"]).focus();
  }

  selectArticle(article, code) {
    if (this.router.url.includes('pricelabels')) {
      this.articleSelected$.next(code);
    } else {
      if (typeof article.single_article_code === "undefined") {
        article.single_article_code = code;
      }
      this.articleSelected$.next(article);

    }
    this.modal.dismiss();
  }

  unknownArticle() {
    this.articleSelected$.next(null);
    this.modal.dismiss();
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap(term =>
        this.searchService.searchArticleSuggestions(term).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );

  formatter = (x: { single_article_code: string }) => x.single_article_code;

  onSelect($event) {
    $event.preventDefault();
    return false;
  }

  detectChange(event) {
    this.showSuggestions = event.key !== "Enter";
    event.preventDefault();
    return false;
  }
}
