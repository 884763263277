import { InvoicesOptionInput } from "./invoices-option-input";

export interface InvoicesFilters {
  options: InvoicesOptionInput;
  invoice_state_filter: string;
  invoice_type: string;
  next_page_reference: string;
}

export class InvoicesFilters {}
