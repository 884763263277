<div class="row mx-0 p-2">
  <!-- Main groups -->
  <ng-content></ng-content>
  <ul
    class="col group group-1 list-group browseGroups ps-3 ps-sm-0"
    [ngClass]="[
      productGroups.length || showPromotions
        ? isMobile
          ? 'd-none d-sm-flex'
          : 'col'
        : 'col',
      listClass
    ]"
    *ngIf="!hideBrowseGroups"
  >
    <li
      class="list-group-item"
      *ngFor="let item of browseGroups"
      [ngClass]="{
        active: browseGroupItem && browseGroupItem.name === item.name
      }"
      (click)="
        browseGroupItem = item;
        showPromotionsBikes = false;
        item.sub_items && item.sub_items.length
          ? setProductGroup(item)
          : gotoToSearch()
      "
    >
      {{ item.description }}
    </li>
    <li
      class="list-group-item promotions_item"
      *ngIf="
        showDealerOptions &&
        companyGroupsSettings &&
        companyGroupsSettings.promotion_companies?.length &&
        ['WG', 'GHO'].includes(applicationService.getSelectCompanyGroupCode())
      "
      [ngClass]="{ active: showPromotionsParts }"
      (click)="showBikesPromotions()"
    >
      <ng-container *ngIf="!['WG', 'GHO'].includes(applicationService.getSelectCompanyGroupCode())">{{ "PROMOTIONS" | translate }}</ng-container
      >
      <ng-container *ngIf="['WG', 'GHO'].includes(applicationService.getSelectCompanyGroupCode())">{{ "PROMOTIONS_BIKES" | translate }}</ng-container
      >
    </li>
    <li
      class="list-group-item promotions_item"
      *ngIf="
        showDealerOptions &&
        companyGroupsSettings &&
        companyGroupsSettings.promotion_companies?.length
      "
      [ngClass]="{ active: showPromotions }"
      (click)="applicationService.getSelectCompanyGroupCode() == 'WG' ? (showPromoGroups()) : (applicationService.getSelectCompanyGroupCode() == 'GHO'  ? gotoToSearch('CMN_PROM&HOMEPROMO=ACCOF1&COMPANY=25') : gotoToPromotion())"
    >
      <ng-container
        *ngIf="
          !['WG', 'GHO'].includes(
            applicationService.getSelectCompanyGroupCode()
          )
        "
      >
        {{ "PROMOTIONS" | translate }}</ng-container
      >
      <ng-container
        *ngIf="['WG'].includes(applicationService.getSelectCompanyGroupCode())"
      >
        {{ "PROMOTIONS_PARTS" | translate }}</ng-container
      ><ng-container
        *ngIf="['GHO'].includes(applicationService.getSelectCompanyGroupCode())"
      >
        {{ "PROMOTIONS" | translate }} | Gear and Apparel</ng-container
      >
    </li>

    <li
      class="list-group-item"
      *ngIf="
        showDealerOptions &&
        companyGroupsSettings &&
        companyGroupsSettings.bom_companies &&
        companyGroupsSettings.bom_companies.length
      "
      (click)="gotoToSearch('CMN_BOM')"
    >
      {{ "BOM" | translate }}
    </li>
  </ul>
  <ng-template #carousel>
    <ng-content select="[carousel]"></ng-content>
  </ng-template>
  <!-- Product groups -->
  <ul
    class="col group group-2 list-group productGroups scrollable customScrollbar"
    [ngClass]="listClass"
    *ngIf="!subGroups.length && productGroups.length; else carousel"
    [@inAnimation]
    [@pageAnimations]
  >
    <li class="list-group-item d-flex d-sm-none">
      <span
        class="btn btn-primary btn-rounded d-flex"
        (click)="productGroups = []; hideBrowseGroups = false"
      >
        {{ "BACK" | translate }}</span
      >
    </li>
    <ng-container *ngFor="let item of productGroups; let i = index">
      <li
        class="list-group-item first"
        (click)="
          productGroupItem = item;
          item.sub_items && item.sub_items.length > 1
            ? (subGroups = item.sub_items)
            : gotoToSearch()
        "
      >
        {{ item.description }}
      </li>
    </ng-container>
    <li class="list-group-item all" (click)="gotoToSearch()">
      <span class="material-icons"> arrow_forward_ios </span
      >{{ "VIEW_ALL" | translate }}
    </li>
  </ul>

  <!-- Sub groups -->
  <ul
    class="col group group-3 list-group subGroups scrollable customScrollbar"
    [ngClass]="listClass"
    *ngIf="subGroups && subGroups.length && !variationGroups.length"
    [@inAnimation]
  >
    <li
      class="list-group-item d-flex border-bottom"
      (click)="subGroups = []; subGroupItem = null"
    >
      <span class="btn btn-primary d-flex btn-rounded">
        {{ "BACK" | translate }}</span
      >
      <span class="d-flex btn btn-text back-item text-start">{{
        productGroupItem.description
      }}</span>
    </li>
    <li
      class="list-group-item"
      *ngFor="let item of subGroups"
      (click)="
        subGroupItem = item;
        item.sub_items ? (variationGroups = item.sub_items) : gotoToSearch()
      "
    >
      {{ item.description }}
    </li>
    <li class="list-group-item all" (click)="gotoToSearch()">
      <span class="material-icons"> arrow_forward_ios </span>
      {{ "VIEW_ALL" | translate }}
    </li>
  </ul>
  <!-- Variation groups -->
  <ul
    class="col group group-3 list-group subGroups scrollable customScrollbar"
    [ngClass]="listClass"
    *ngIf="variationGroups && variationGroups.length"
    [@inAnimation]
  >
    <li
      class="list-group-item d-flex border-bottom"
      (click)="variationGroups = []; variationGroupItem = null"
    >
      <span class="btn btn-primary d-flex btn-rounded">
        {{ "BACK" | translate }}</span
      >
      <span class="d-flex btn btn-text back-item text-start">{{
        subGroupItem.description
      }}</span>
    </li>
    <li
      class="list-group-item"
      *ngFor="let item of variationGroups"
      (click)="variationGroupItem = item; gotoToSearch()"
    >
      {{ item.description }}
    </li>
    <li class="list-group-item all" (click)="gotoToSearch()">
      <span class="material-icons"> arrow_forward_ios </span>
      {{ "VIEW_ALL" | translate }}
    </li>
  </ul>

  <!-- Promotion groups -->
  <ul
    class="col group group-3 list-group scrollable customScrollbar"
    [ngClass]="listClass"
    *ngIf="showPromotions"
    [@inAnimation]
  >
    <li class="list-group-item d-flex d-sm-none">
      <span
        class="btn btn-primary d-flex d-sm-none btn-rounded"
        (click)="showPromotions = false; hideBrowseGroups = false"
      >
        {{ "BACK" | translate }}</span
      >
    </li>
    <ng-container *ngIf="promotions && promotions.length">
      <ng-container *ngFor="let groupedPromotions of promotions">
        <li
          class="list-group-item"
          *ngFor="let item of groupedPromotions"
          (click)="
            gotoToSearch(
              'CMN_PROM&HOMEPROMO=' +
                item.promotioncode +
                '&COMPANY=' +
                +item.company_id,
              $event
            )
          "
        >
          {{ item.description ? item.description : item.title }}
        </li>
      </ng-container>
    </ng-container>
    <li
      class="list-group-item"
      *ngIf="!promotionsLoaded && !productGroups.length"
      [@inAnimation]
    >
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </li>
    <li
      class="list-group-item all"
      *ngIf="promotionsLoaded"
      (click)="
        gotoToSearch(
          promotionPageUrl[applicationService.getSelectCompanyGroupCode() + '_PARTS']
        )
      "
      [@inAnimation]
    >
      <span class="material-icons"> arrow_forward_ios </span>
      {{ "VIEW_ALL" | translate }}
    </li>
  </ul>

  <ul
      class="col group group-3 list-group scrollable customScrollbar"
      [ngClass]="listClass"
      *ngIf="showPromotionsBikes"
      [@inAnimation]
      >
    <li class="list-group-item d-flex d-sm-none">
      <span
            class="btn btn-primary d-flex d-sm-none btn-rounded"
            (click)="showPromotionsBikes = false; hideBrowseGroups = false"
            >
          {{ "BACK" | translate }}</span
>
    </li>
    <li class="list-group-item all"
        *ngIf="['GHO'].includes(applicationService.getSelectCompanyGroupCode())"
        (click)="gotoToSearch('CMN_PROM&HOMEPROMO=ACCOF2&COMPANY=25')"
        [@inAnimation]>
      {{ "OFFERS" | translate }} | GHOST
    </li>
    <li class="list-group-item all"
        *ngIf="['GHO'].includes(applicationService.getSelectCompanyGroupCode())"
        (click)="gotoToSearch('CMN_PROM&HOMEPROMO=ACCOF3&COMPANY=25')"
        [@inAnimation]>
      {{ "OFFERS" | translate }} | LAPIERRE
    </li>
    <li class="list-group-item all"
        *ngIf="['GHO'].includes(applicationService.getSelectCompanyGroupCode())"
        (click)="gotoToSearch('CMN_PROM&HOMEPROMO=ACCOF4&COMPANY=25&pageSize=24')"
        [@inAnimation]>
      {{ "OFFERS" | translate }} | BLACKSALE
    </li>
    <li class="list-group-item all"
        *ngIf="['WG'].includes(applicationService.getSelectCompanyGroupCode())"
        (click)="
        gotoToSearch(
          promotionPageUrl[applicationService.getSelectCompanyGroupCode() + '_BIKES']
        )
      "
        [@inAnimation]>
      {{ "PROMOTIONS_BIKES" | translate }}
    </li>
  </ul>
</div>
