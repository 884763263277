<div id="articleWarrantyDescriptionContainer">
  <h3 class="mb-2">{{ "COMPLAINT_DESCRIPTION" | translate }}</h3>

  <ng-content></ng-content>
  <div class="mb-3 row" *ngIf="formGroup.get('remarks').enabled">
    <label for="remarks" class="col-md-4 control-label text-start"
      >{{ "DESCRIPTION" | translate }} *</label
    >
    <div class="col-md-6" [formGroup]="formGroup">
      <input
        type="text"
        class="form-control"
        id="remarks"
        name="remarks"
        formControlName="remarks"
        maxlength="60"
      />
    </div>
  </div>
  <ng-container>
    <div class="mb-3 row" *ngIf="!hideComplaintCode">
      <label for="complaintCode" class="col-md-4 control-label text-start">{{
        "COMPLAINTCODE" | translate
      }}</label>
      <div class="col-md-6" [formGroup]="formGroup">
        <input
          type="text"
          class="form-control"
          id="complaintCode"
          name="complaintCode"
          formControlName="complaintCode"
          maxlength="10"
          size="20"
        />
      </div>
    </div>
    <div class="mb-3 row" *ngIf="tabSelected !== 'battery'">
      <label for="number" class="col-md-4 control-label text-start"
        >{{ "QUANTITY" | translate }} *</label
      >
      <div class="col-md-2" [formGroup]="formGroup">
        <input
          type="number"
          min="1"
          [max]="maxQuantity"
          step="1"
          class="form-control"
          id="quantity"
          name="quantity"
          formControlName="quantity"
          value="1"
          (change)="checkQty($event)"
        />
        <span *ngIf="maxQuantity < 25 && formGroup.get('articleCode').value" class="ms-1">{{"MAX"|translate}}: {{maxQuantity}}</span>
      </div>
    </div>
  </ng-container>
</div>
