<div *ngIf="sectionData && sectionData.length">
  <ng-container *ngFor="let subSection of sectionData; let i = index">
    <ng-container *ngIf="subSection.options.length === 1">
      <div class="mt-2 form-check form-switch" *ngIf="yearAgreementModel[id]">
        <input type="checkbox"
          [disabled]="(yearAgreementModel['cmDealer'] && subSection.options[0].option_type !== 'TurnoverBrands' && id === 'brands') || subSection.options[0].read_only || (!yearAgreementModel[id]['selected'][subSection.options[0].option_type + mainAffiliateKey] && modelKey !== mainAffiliateKey) || (accentryXlDisabled(subSection.options[0].option_type, yearAgreementModel[id]['selected']['ShareStock' + modelKey]) && yearAgreementModel.year < 2024)"
          class="form-check-input agreementCheckbox" [id]="id + '_' + subSection.options[0].id + '_' + (affiliate ? affiliate : mainAffiliate)"
          [(ngModel)]="yearAgreementModel[id]['selected'][subSection.options[0].option_type + modelKey]"
          [attr.data-id]="subSection.options[0].id" [value]="subSection.options[0].id" [attr.data-affiliate]="affiliate ? affiliate : mainAffiliate" [attr.data-ismain]="isMain"
          (change)="change(i, subSection.options[0].option_type + modelKey)">
        <label class="form-check-label w-100" [for]="id + '_' + subSection.options[0].id + '_' + (affiliate ? affiliate : mainAffiliate)">
          <i class="material-icons" *ngIf="yearAgreementModel.texts['YA_' + subSection.options[0].option_type.toUpperCase()]" [attr.data-key]="subSection.options[0].option_type.toUpperCase()" [ngbTooltip]="yearAgreementModel.texts['YA_' + subSection.options[0].option_type.toUpperCase()]">info</i> <span class="clickable withOpacity">{{subSection.options[0].option_type.toUpperCase() | translate }}</span>
          <small class="percIndication" *ngIf="!subSection.options[0].hide_percentage && showCalculation">({{subSection.options[0].percentage}}%)</small>
          <span class="" [ngClass]="id + '_' + subGroupId">
            <app-input-label [hidden]="!yearAgreementModel.showMargins || !showCalculation"
              [percentage]="yearAgreementModel[id]['selected'][subSection.options[0].option_type]?subSection.options[0].percentage:'0'">
              +
            </app-input-label>
          </span>
        </label>
      </div>
    </ng-container>

    <ng-container *ngIf="subSection.options.length > 1">
      <div class="mt-2 mb-3">
        <ng-container *ngIf="!onlyAffiliateOption">
          <label class="w-100"
            [for]="id + '_' + subSection.options[0].id">
            <ng-template #tipContent><div class="p-2 text-start" [innerHTML]="yearAgreementModel.texts['YA_' + subSection.options[0].option_type.toUpperCase()]"></div></ng-template>
            <i class="material-icons" [hidden]="yearAgreementModel['cmDealer'] && subSection.options[0].option_type === 'TurnoverBrands'" *ngIf="yearAgreementModel.texts['YA_' + subSection.options[0].option_type.toUpperCase()]" [ngbTooltip]="tipContent">info</i> {{subSection.options[0].option_type.toUpperCase() | translate }}
            <span class="" [ngClass]="id + '_' + subGroupId">
              <app-input-label [hidden]="!yearAgreementModel.showMargins || !showCalculation"
                [percentage]="getPerc(i, subSection.options[0].option_type)">+
              </app-input-label>
            </span>
          </label>
          <select
            [hidden]="yearAgreementModel['cmDealer'] && subSection.options[0].option_type === 'TurnoverBrands'"
            [disabled]="yearAgreementModel['cmDealer'] && subSection.options[0].option_type !== 'TurnoverBrands' && id === 'brands' || (subSection.options[0].option_type === 'DealerTypeBonus' && id === 'brands')"
            class="form-control agreementSelect" [attr.data-tab]="id" [id]="id + '_' + subSection.options[0].id"
            (change)="change(i, subSection.options[0].option_type)"
            [attr.data-modelid]="id + '_' + subSection.options[0].option_type"
            [attr.data-ismain]="isMain"
            [(ngModel)]="yearAgreementModel[id]['selected'][subSection.options[0].option_type]">
            <ng-container *ngFor="let option of subSection.options| sortBy:'percentage':'asc'">
              <option *ngIf="!option.country || option.country === yearAgreementModel['countryCode']"
                [ngClass]="id + '_' + option.option_value" [attr.data-id]="option.id" [attr.data-affiliate]="affiliate ? affiliate : mainAffiliate" [attr.data-ismain]="isMain"
                [disabled]="id === 'brands' && option.option_type === 'DealerTypeBonus' && (yearAgreementModel[id]['selected']['DealerType'] !== option.option_value && option.option_value !== 'None')"
                [value]="option.option_value" [hidden]="option.option_value.indexOf('CM') >= 0 && !yearAgreementModel['cmDealer']">
                {{option.option_value.toUpperCase() | translate }}
                <ng-container *ngIf="!option.hide_percentage">({{option.percentage}}%)</ng-container>
              </option>
            </ng-container>
          </select>
        </ng-container>
        <ng-container *ngIf="onlyAffiliateOption && subSection.options[0].affiliate_option">
            <label class="mt-2">{{subSection.options[0].option_type.toUpperCase() | translate }}</label>
            <select
            class="form-control agreementSelect" [attr.data-tab]="id" [id]="id + '_' + subSection.options[0].id" [attr.data-ismain]="isMain"
            >
              <ng-container *ngFor="let option of subSection.options| sortBy:'percentage':'asc'">
                <option *ngIf="!option.country || option.country === yearAgreementModel['countryCode']"
                [ngClass]="id + '_' + option.option_value" [attr.data-id]="option.id" [attr.data-affiliate]="affiliate" [attr.data-ismain]="isMain"
                [value]="option.option_value" [selected]="getAffiliateSelected(option.id, affiliate)">
                {{option.option_value.toUpperCase() | translate }}
                </option>
              </ng-container>
            </select>
        </ng-container>
        <!-- <ng-container *ngIf="onlyAffiliateOption && subSection.options[0].affiliate_option">
           <label class="mb-2">{{ "RENT" | translate }}</label>
                  <div class="form-check form-switch">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="rent"
                      [attr.data-affiliate]="affiliate"
                      [(ngModel)]="yearAgreementModel.rent"
                    />
                    <label class="form-check-label" for="rent">{{
                      "DEALER_RENT" | translate
                    }}</label>
                  </div>
        </ng-container> -->

        <input *ngIf="!onlyAffiliateOption && yearAgreementModel['cmDealer'] && subSection.options[0].option_type === 'TurnoverBrands'" [attr.data-ismain]="isMain"
          type="number" class="form-control" step="10000" min="0" [(ngModel)]="yearAgreementModel.dummyTurnover" (change)="saveCMTurnover()">
      </div>

    </ng-container>

  </ng-container>

  <ng-container *ngIf="showExplanation">
    <small id="ebikeGrowthExplanation">
      {{'EBIKE_GROWTH_EXPLANATION' | translate }}
    </small>
  </ng-container>
</div>
