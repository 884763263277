<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'YEARAGREEMENT'|translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
    </button>
</div>

<div class="modal-body p-3">
  <div class="row mx-0">
    <div class="col-12 col-md-5" *ngIf="type !== 'parts'">
      <div class="mb-3">
        <label>{{'YEARAGREEMENT'|translate}}</label>
        <ul class="list-group">
          <li class="list-group-item" *ngFor="let agreement of agreements">{{agreement.year}}
            <i *ngIf="agreement?.locked && (agreement.signed || signed)">{{'SIGNED'|translate}}: {{agreement.signed ? _getSignedDate(agreement.signed) : signDate}}</i>
            <button class="btn btn-sm btn-success" *ngIf="agreements.length > 1" (click)="getPdf(agreement)">{{'VIEW'|translate}}</button>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-12" [ngClass]="{'col-md-7': type !== 'parts'}">
      <pdf-viewer class="pdf customScrollbar block float-left w-100" [src]="pdfSrc" *ngIf="pdfSrc"></pdf-viewer>
      <div *ngIf="pdfSrc">
        <button class="btn btn-dark float-left mt-2" (click)="download()">{{'DOWNLOAD'|translate}}</button>
        <div *ngIf="!selectedAgreement?.locked && show" class="mt-2 block ">
          <div class="mb-3 mt-2" *ngIf="type !== 'parts'">
            <label for="dealerName">{{'DEALER_NAME'|translate}}</label>
            <input type="text" class="form-control" id="dealerName" required [(ngModel)]="dealerName">
          </div>
          <div class="mb-3 mt-2" *ngIf="emailRequired">
            <label for="dealerName">{{'CONTRACT_EMAIL'|translate}}</label>
            <input type="email" class="form-control" id="contractEmail" required  [(ngModel)]="contractEmail" [ngClass]="{'border-danger':emailInvalid}">
          </div>
          <div class="mb-3 mt-2" *ngIf="emailRequired">
            <label for="dealerName">{{'CONTRACT_EMAIL_CHECK'|translate}}</label>
            <input type="email" class="form-control" id="contractEmailCheck" required  [(ngModel)]="contractEmailCheck" [ngClass]="{'border-danger':emailInvalid}">
          </div>
          <div class="form-check mb-2">
              <ng-container *ngIf="type !== 'parts'">
                <input type="checkbox" class="form-check-input" id="signCheck" [(ngModel)]="signed">
                <label class="form-check-label" for="signCheck">{{'DEALER_AGREES'|translate}}</label>
              </ng-container>
          </div>
          <button class="btn btn-success " [disabled]="!signed || !dealerName || sending || emailInvalid"  *ngIf="type !== 'parts'"
            (click)="sign()">{{'SIGN'|translate}}</button>
        </div>
        <div *ngIf="type === 'parts' && show" class="mt-2 block ">
          <button class="btn btn-success " *ngIf="type === 'parts'"
          (click)="sign()">{{'CLOSE'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
