import { Component, OnInit, Input } from "@angular/core";
import { PackingSlip } from "src/app/interfaces/financial/packingslips/packing-slip";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PackingSlipViewerComponent } from "../packing-slip-viewer/packing-slip-viewer.component";
import { FinancialService } from "src/app/services/financial.service";
import { PdfViewerComponent } from "src/app/components/general-components/pdf-viewer/pdf-viewer.component";
import { ApplicationService } from "src/app/services/application.service";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-packing-slip-line",
  templateUrl: "./packing-slip-line.component.html",
  styleUrls: ["./packing-slip-line.component.scss"],
})
export class PackingSlipLineComponent implements OnInit {
  @Input() packingSlip: PackingSlip;
  @Input() hideStatus: boolean;
  constructor(
    private modalService: NgbModal,
    private financialService: FinancialService,
    public applicationService: ApplicationService,
    public helperService: HelperService,
  ) {}

  ngOnInit() {}

  viewPackingSlip() {
    const modalRef = this.modalService.open(PackingSlipViewerComponent, {
      centered: true,
      size: "lg",
      container: "#modalContainer",
    });
    modalRef.componentInstance.packingSlip = this.packingSlip;
    event.preventDefault();
    return false;
  }

  showOpenOrder() {
    this.financialService
      .getOpenOrdersPdfFile([this.packingSlip.ace_id])
      .subscribe((blob: Blob) =>
        this.showPdfDialog(blob, `openorderlines_${this.packingSlip.ace_id}.pdf`)
      );
  }

  showInvoice() {
    this.financialService
      .getOpenOrdersPdfFile([this.packingSlip.ace_id])
      .subscribe((blob: Blob) =>
        this.showPdfDialog(blob, `invoices_${this.packingSlip.invoice_number}.pdf`)
      );
  }

  showPdfDialog(blob: Blob, fileName: string) {
    const modalRef = this.modalService.open(PdfViewerComponent, {
      centered: true,
      size: "lg",
      container: "#modalContainer",
    });
    modalRef.componentInstance.fileName = fileName;
    modalRef.componentInstance.pdfSrc = blob;
    event.preventDefault();
    return false;
  }

  getLogisticsCompany(url: string) {
    return this.helperService.getLogisticsCompany(url)
  }
}
