import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/app/services/application.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-links',
  templateUrl: './admin-links.component.html',
  styleUrls: ['./admin-links.component.scss']
})
export class AdminLinksComponent {
  sidebarVisible = false;
  test = false;
  get cgcRoot() {
    return `/${this.applicationService.getSelectCompanyGroupCode()}`;
  }

  constructor(
    public applicationService: ApplicationService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.test = environment.name !== "production";
    router.events.subscribe(() => {
      this.sidebarVisible = false
    });
  }

}
