<ng-template #rt let-r="result" let-t="term">
  <p (click)="selectArticle(r, t)">
    <app-article-image *ngIf="r.thumbs && r.thumbs.length && r.thumbs[0]" [imageUrl]="r.thumbs[0]" [articleType]="'F'">
    </app-article-image>
    <span class="d-block" [innerHTML]="t"></span>
    <strong [innerHTML]="r.description"></strong><br />
    <button class="btn btn-lg btn-success mt-2">{{'SELECT'|translate}}</button>
  </p>
</ng-template>

<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    <img src="./assets/images/bicycle-solid.svg" height="32px">
    {{'SEARCH'|translate}}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
    </button>
</div>

<div class="modal-body p-3">
  <div class="row mb-4">
    <div class="mb-3 w-100 px-3">
      <label for="typeahead-http-bicycle" class="fw-bold">{{'FRAMENUMBER'|translate}}:</label>
      <input #bibycleSearchText autofocus id="typeahead-http-bicycle" type="text" class="form-control" [resultTemplate]="rt"
        [class.is-invalid]="searchFailed" [(ngModel)]="model" [ngbTypeahead]="search" [inputFormatter]="formatter" />
      <span *ngIf="searching">{{'LOADING'|translate}}...</span>
      <div class="invalid-feedback" *ngIf="searchFailed">{{'NO_RESULTS'|translate}}</div>
    </div>
  </div>
  <button class="btn btn-primary" (click)="bicycleUnknown(model)" *ngIf="showUnknown">{{'BIKE_UNKNOWN'|translate}}</button>
</div>
