<div class="container">
  <div class="content">
    <div class="intro pt-3 text-white">
      <img
        class="accentry-logo mt-2"
        src="./assets/images/Accentry-logo-white.svg"
        alt="Accentry"
        title="Accentry"
      />
      <p class="mt-4 mb-0">{{ "SELECT_BRAND" | translate }}</p>
    </div>
    <app-brand-selector-large></app-brand-selector-large>
  </div>
</div>
