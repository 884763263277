import * as moment from "moment";

export enum CacheType {
  "slideshows" = "slideshows",
  "widgets" = "widgets",
  "carousels" = "carousels",
  "news" = "news",
  "preorder" = "preorder",
}


export interface CacheTracker {
  slideshows: CacheObject;
  widgets: CacheObject;
  carousels: CacheObject;
  news: CacheObject;
  preorder: CacheObject;
}

export class CacheTracker implements CacheTracker {
  constructor() {
    this.slideshows = new CacheObject();
    this.widgets = new CacheObject();
    this.carousels = new CacheObject();
    this.news = new CacheObject();
    this.preorder = new CacheObject();
  }
}

export interface CacheObject {
  timestamp_ANL: any;
  timestamp_GHO: any;
  timestamp_LAP: any;
  timestamp_VAR: any;
  timestamp_COM: any;
  timestamp_WG: any;
  timestamp_RAL: any;
  timestamp_TUN: any;
}
export class CacheObject implements CacheObject {
  constructor() {}
}
