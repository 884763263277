import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from 'src/app/interfaces/bread-crumb';
import { AccountService } from 'src/app/services/account.service';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from 'src/app/services/application.service';
import { AuthenticateService } from 'src/app/services/authenticate.service';

@Component({
  selector: 'app-outdated-browser-page',
  templateUrl: './outdated-browser-page.component.html',
  styleUrls: ['./outdated-browser-page.component.scss']
})
export class OutdatedBrowserPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  constructor(
    private accountService: AccountService,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private authenticateService: AuthenticateService,
  ) { }

  ngOnInit() {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/home"
      ),
      new Breadcrumb(this.translateService.instant("OUTDATED_BROWSER"), "#")
    ];
  }

  goToOldAccentry() {
    this.authenticateService.connectToClassic(false);
  }

}
