import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ApplicationService } from "./application.service";
import { LeafletSendRequest } from "../interfaces/leaflet-send-request";
import { Article } from "../interfaces/article";
import { findIndex } from "underscore";
import * as _ from "underscore";
import { ApiResponse } from "../interfaces/api-response";
import { map } from 'rxjs/operators';
import { HelperService } from "./helper.service";
import { StorageTypes } from "../interfaces/helpers";
import { ArticleMatrix } from "../interfaces/article-matrix";

@Injectable({
  providedIn: "root",
})
export class ArticleService {
  maxRecentlyViewed = 6;
  selectedArticleSpecCode: string;
  get recentlyViewedStorageKey() {
    return `rv-${this.applicationService.getSelectCompanyGroupCode()}`;
  }

  constructor(
    private http: HttpClient,
    private applicationService: ApplicationService,
    private helperService: HelperService
  ) {}

  // Get article bill of material
  public getArticleBom(article) {
    return this.http.get(
      environment.apiendpoint +
        "articles/" +
        article.single_article_code +
        "/bom/"
    );
  }
  // Get articles of the Bom group
  public getBomArticles(group, article) {
    return this.http.get(
      environment.apiendpoint +
        "articles/" +
        article.single_article_code +
        "/bom/" +
        group
    );
  }

  // Get set items
  public getSetItems(article) {
    return this.http.get(
      environment.apiendpoint +
        `articles/${
          article.single_article_code
            ? article.single_article_code
            : article.article_code
        }/setitems/`
    );
  }

  // Get multiple articles by article code
  public getArticleByCodes(articleCodes: String[]) {
    const requestData = this.applicationService.createQueryString(
      articleCodes,
      "c"
    );
    return this.http.get(environment.apiendpoint + "articles/" + requestData);
  }

  // Get leaflet
  public getLeaflet(articleId, articleCode) {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: "application/pdf",
      }),
      responseType: "blob" as "json",
    };

    return this.http.get(
      `${environment.apiendpoint}articles/leaflet/${articleId}/${
        articleCode ? articleCode : ""
      }?fileType=pdf`,
      HTTPOptions
    );
  }

  // Send leaflet to e-mail
  public sendLeaflet(requestInput: LeafletSendRequest) {
    return this.http.post(
      `${environment.apiendpoint}articles/sendleaflet/`,
      requestInput
    );
  }

  // Get batteries for a bicycle
  public getBatteriesAndChargers(articleCode) {
    return this.http
      .get(
        `${environment.apiendpoint}articles/${articleCode}/batteriesandchargers/`
      )
      .pipe(
        map((response: any) => {
          // Sort by retail price
          if (response && response.result["Batteries"]) {
            const batteries = response.result["Batteries"];
            let sortedBatteries = _.sortBy(
              batteries,
              function (battery: Article) {
                return battery.retail_price?.value;
              }
            );
            response.result["Batteries"] = sortedBatteries;
          }
          return response;
        })
      );
  }

  public getDownloadImage(modelId, imageNumber) {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: "application/jpeg",
      }),
      responseType: "blob" as "jpeg",
    };

    return this.http.get(
      `${environment.apiendpoint}articles/downloadimage/${modelId}/${imageNumber}?fileType=pdf`
    );
  }

  public getDownloadZip(modelId) {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: "application/octet-stream",
      }),
      responseType: "blob" as "zip",
    };

    return this.http.get(
      `${environment.apiendpoint}articles/downloadimages/${modelId}?fileType=pdf`
    );
  }

  public preorderArticleDetails(modelId: number) {
    return this.http.get(
      `${environment.apiendpoint}models/preorder/${modelId}`
    );
  }

  public updateRecentlyViewed(article: Article) {
    let recentList = (
      localStorage.getItem(this.recentlyViewedStorageKey)
        ? JSON.parse(localStorage.getItem(this.recentlyViewedStorageKey))
        : []
    ) as Article[];

    // Remove article from list if it's already in there
    const alreadyInListIndex = recentList.findIndex((a: Article) => {
      return a.id === article.id;
    });

    if (alreadyInListIndex >= 0) {
      recentList.splice(alreadyInListIndex, 1);
    }

    // Remove no 20 in the list
    if (recentList.length >= this.maxRecentlyViewed) {
      recentList.splice(this.maxRecentlyViewed - 1, 1);
    }

    // Add article
    recentList.unshift(article);

    // Save in storage
    localStorage.setItem(
      this.recentlyViewedStorageKey,
      JSON.stringify(recentList)
    );
  }

  public getRecentlyViewed() {
    return this.helperService.parseJsonFromStorage(this.recentlyViewedStorageKey, StorageTypes.localstorage)
  }

  // Get all the articles which are on the stock watch list
   // Get all the articles which are on the stock watch list
   public getArticleStockNotificationList(inputData) {

    return this.http.post(
      `${environment.apiendpoint}articles/stock/notifications/details`, inputData
    );
  }

  public getArticleStockNotifications() {
    return this.http.get(
      `${environment.apiendpoint}articles/stock/notifications`
    );
  }

  // Add article to stock notification watch list
  public addToStockNotificationList(
    articleCode: string,
    articleDescription: string
  ) {
    return this.http.post(
      `${environment.apiendpoint}articles/stock/notifications`,
      {
        article_code: articleCode,
        description: articleDescription,
      }
    );
  }

  // Remove article from stock notification watch list
  public removeFromStockNotificationList(articleCode: string) {
    return this.http.delete(
      `${environment.apiendpoint}articles/stock/notifications/${articleCode}`
    );
  }

  // Get multiple articles by article code
  public getStockNotificationArticleData(
    articleCode: string,
    companyGroupCode: string
  ) {
    return this.http.get(
      `${environment.apiendpoint}articles/${articleCode}?companyGroupCode=${companyGroupCode}`
    );
  }

  public getVolumePrice(volumePrice) {
    for (const key in volumePrice) {
      if (volumePrice.hasOwnProperty(key)) {
        const price = volumePrice[key];
        for (const subKey in price) {
          if (price.hasOwnProperty(subKey)) {
            const languageItem = price[subKey];
            for (const lang in languageItem) {
              if (languageItem.hasOwnProperty(lang)) {
                return languageItem[lang];
              }
            }
          }
        }
      }
    }
  }

  getTempUndeliverable(matrix: ArticleMatrix, singleArticleCode: string) {
    let modelTemporarilyUndeliverable = false;
    if (matrix?.options && matrix?.options?.length) {
      let tempCount = 0;
      for (let i = 0; i < matrix.options.length; i++) {
        const articles = matrix.options[i].value as Article[];
        articles.forEach((a: Article) => {
          if (
            a.temporarily_undeliverable &&
            a.single_article_code &&
            a.single_article_code === singleArticleCode
          ) {
            modelTemporarilyUndeliverable = true;
          }

          if (a.temporarily_undeliverable) {
            tempCount++;
          }
        });
      }

      // If all articles are temporarily_undeliverable then always show not available
      if (tempCount === matrix?.options?.length) {
        modelTemporarilyUndeliverable = true;
      }

      return modelTemporarilyUndeliverable;
    }
  }
}

