import { Component, OnInit, Input } from "@angular/core";
import { OpenOrdersResultsCount } from "src/app/interfaces/financial/openorders/open-orders-results-count";

@Component({
  selector: "app-num-results-open-orders",
  templateUrl: "./num-results-open-orders.component.html",
  styleUrls: ["./num-results-open-orders.component.scss"]
})
export class NumResultsOpenOrdersComponent implements OnInit {
  @Input() numResults: OpenOrdersResultsCount;

  constructor() {}

  ngOnInit() {}
}
