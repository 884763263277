import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationService } from "src/app/services/application.service";
import { ArticleCollectorService } from "src/app/services/article-collector.service";
import { ArticleModalComponent } from "../../modal-components/article-modal/article-modal.component";
import { Location } from "@angular/common";
import { BasketArticle } from "src/app/interfaces/basketArticle";
import * as _ from "underscore";
import { ArticleMatrix } from "src/app/interfaces/article-matrix";
import { MatrixOption } from "src/app/interfaces/matrixOption";

@Component({
  selector: "app-article-list-item",
  templateUrl: "./article-list-item.component.html",
  styleUrls: ["./article-list-item.component.scss"],
})
export class ArticleListItemComponent implements OnInit {
  @Input() article: Article;
  @Input() basketArticles: BasketArticle[];
  @Input() selected: boolean;
  @Input() edit: boolean;
  @Input() showAdditionalItems: boolean;
  @Input() type: string;
  @Input() schedule: any;
  @Input() preorderBasketId: number;
  @Input() hideSaveButton: boolean;
  @Input() useTimeSlots: boolean;
  @Input() monthSelectionOnly: boolean;
  @Output() showArticleDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() articleSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeFromList: EventEmitter<any> = new EventEmitter<any>();
  @Output() qtyChanged: EventEmitter<number> = new EventEmitter<number>();
  @Input() autoShowDetails = false;
  @Input() useLazyLoad = true;
  @Input() selectedArticles: any = {};
  @Input() specs: any;
  @Input() bico: boolean;
  @Input() basketArticleId: number;
  @Input() countryCode: string;

  matrixOptions: any = [];
  selectedCompanyGroup = "";
  thumbnail = "";
  timer: any;
  hideImg = false;
  placeholder = "assets/images/bicycle-solid.svg";
  unsaved: boolean;
  thumbnailIndex = 0;

  constructor(
    private modalService: NgbModal,
    private location: Location,
    private applicationService: ApplicationService,
    private articleCollectorService: ArticleCollectorService
  ) {}

  ngOnInit() {
    this.selectedCompanyGroup =
      this.applicationService.getSelectCompanyGroupCode();
    this.article.selected = this.selected;

    if (!this.article.matrix) {
      this.article.matrix = new ArticleMatrix();
      this.article.matrix.options = [];
    }

    if (!this.article.matrix.options.length) {
      const option = new MatrixOption();
      option.description = "";
      option.value = [this.article];
      this.article.matrix.options.push(option);
    }

    this.setThumbnail();
  }

  setThumbnail() {
    this.thumbnail = this.article.thumbs ? this.article.thumbs[0] : "";
  }

  openModal(article, event) {
    const modalRef = this.modalService.open(ArticleModalComponent, {
      size: "lg",
      centered: true,
      backdrop: "static",
      container: "#modalContainer",
    });
    modalRef.componentInstance.article = article;
    modalRef.componentInstance.modelId = article["id"];
    modalRef.componentInstance.showDetailed = true;
    modalRef.componentInstance.preOrder = true;
    modalRef.componentInstance.showInModal = true;
    modalRef.componentInstance.showQrCode = true;
    modalRef.componentInstance.selectedArticle = article["single_article_code"];
    modalRef.componentInstance.article.img = article["images"];
    modalRef.componentInstance.showInstant();
    event.preventDefault();
  }

  quantityChange(data) {
    this.unsaved = true;
    this.qtyChanged.emit(data);
  }

  gotoItem(event, article) {
    this.location.go(
      this.applicationService.getSelectCompanyGroupCode() +
        "/articleDetails/" +
        article.id +
        "/" +
        (article.single_article_code ? article.single_article_code : "")
    );
    this.showArticleDetails.emit(article);
    event.preventDefault();
    return false;
  }

  prevThumb() {
    if (this.thumbnailIndex > 0) {
      this.thumbnailIndex--
    }
  }

  nextThumb() {
      if (this.thumbnailIndex < this.article.thumbs.length -1) {
        this.thumbnailIndex++;
      }
  }
}
