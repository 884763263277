import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { WarrantyArticle } from 'src/app/interfaces/warranty/warranty-article';
import { Article } from 'src/app/interfaces/article';
import { WarrantyPart } from 'src/app/interfaces/warranty/warranty-part';

@Component({
  selector: 'app-warranty-battery-callback',
  templateUrl: './warranty-battery-callback.component.html',
  styleUrls: ['./warranty-battery-callback.component.scss']
})
export class WarrantyBatteryCallbackComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() currentArticle: WarrantyArticle;
  @Input() tabSelected = "";
  @Output() partSelect: EventEmitter<Article> = new EventEmitter<Article>();
  @Output() bicycleSelect: EventEmitter<Article> = new EventEmitter<Article>();
  bicycleUnknown = false;
  constructor() { }

  ngOnInit() {
    
  }

  bicycleSelected(bicycle: Article) {
    this.bicycleUnknown = bicycle ? false : true;
    this.bicycleSelect.next(bicycle);
  }

  partSelected(data: WarrantyPart) {
    this.partSelect.next(data.article);
  }

}
