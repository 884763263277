<div class="row" id="dealerShipsContainer" *ngIf="yearAgreementPAndAService.agreementModel">
  <div class="row mx-0">
    <div class="col-6">
      <ng-container *ngTemplateOutlet="groups;   context: {
        oddEven: 1,
      }"> </ng-container>
    </div>
    <div class="col-6">
      <ng-container *ngTemplateOutlet="groups;   context: {
        oddEven: 0,
      }"> </ng-container>
    </div>
    <div class="col-12 mt-4">
      <app-dealer-parts-bonus-selection *ngIf="yearAgreementPAndAService.agreementModel?.bonus"
        [bonusses]="yearAgreementPAndAService.agreementModel.bonus"></app-dealer-parts-bonus-selection>
    </div>
  </div>
</div>

<ng-template #groups let-oddEven="oddEven">
  <ng-container *ngFor="
  let group of yearAgreementPAndAService.agreementModel.groups;
  let i = index
">
    <div class="col-12 mt-3" *ngIf="i % 2 !== oddEven">
      <app-dealer-parts-brands-selection [brands]="group.options" [bonusOptions]="group.bonus_options" [groupId]="i" [group]="group" [showTier]="true"
        [showToggle]="!group.auto_select" [showTarget]="group.has_target" label2="{{ 'BRAND' | translate }}"
        label3="{{ ('DISCOUNT' | translate) + ' %' }}"
        [label4]="group.metric !== 'singleselect' && group.metric !== 'tires' ? group.metric : ''"
        [metric]="group.metric" [showDiscount]="true" [hasMaxBrandSelection]="false" [hasPlanning]="group.has_planning"
        [title]="group.name"></app-dealer-parts-brands-selection>
    </div>
  </ng-container>
</ng-template>
