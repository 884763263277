import { Component } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ApplicationService } from 'src/app/services/application.service';
import { PartnerUserInfoAddressComponent } from 'src/app/pages/partner-portal/partner-user-info-address/partner-user-info-address.component';
import { PartnerPortalBreadcrumbsComponent } from 'src/app/components/partner-portal/partner-portal-breadcrumbs/partner-portal-breadcrumbs.component';
import { Breadcrumb } from 'src/app/interfaces/bread-crumb';

@Component({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    PartnerUserInfoAddressComponent,
    PartnerPortalBreadcrumbsComponent
  ],
  standalone: true,
  selector: 'app-partner-portal-basket-personaldata-page',
  templateUrl: './partner-portal-basket-personaldata-page.component.html',
  styleUrl: './partner-portal-basket-personaldata-page.component.scss',
})
export class PartnerPortalBasketPersonaldataPageComponent {
  id: string;
  breadcrumbs: Breadcrumb[];
  constructor(
    public applicationService: ApplicationService,
    public translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.id = params['id'];
      }
    });
    this.breadcrumbs = [
      new Breadcrumb(
        translateService.instant('HOME'),
        applicationService.getSelectCompanyGroupCode() + '/home'
      ),
      new Breadcrumb(
        translateService.instant('BASKET'),
        `${applicationService.getSelectCompanyGroupCode()}/basket/${this.id}`
      ),
      new Breadcrumb(translateService.instant('INVOICE_SHIPPING_DETAILS'), '#'),
    ];
  }

  back(form) {
    form.saveForm().then(() => {
      this.router.navigateByUrl(
        `${this.applicationService.getSelectCompanyGroupCode()}/basket/${
          this.id
        }`
      );
    });
  }

  toCheckout(form) {
    form.saveForm().then(() => {
      this.router.navigateByUrl(
        `${this.applicationService.getSelectCompanyGroupCode()}/checkout/${
          this.id
        }`
      );
    });
  }
}
