<div
  class="form-check d-flex h-100 align-items-sm-center pb-2 pe-2 form-check form-switch pt-3 pt-sm-0 me-md-5"
>
  <input
    type="checkbox"
    class="form-check-input me-2"
    [id]="id"
    [(ngModel)]="model"
    (change)="deliveryConditionChanged()"
  />
  <label class="form-check-label" [for]="id">{{
    "DELIVER_ONLY_WHEN_ALL_AVAILABLE" | translate
  }}</label>
</div>
