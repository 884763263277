<div class="container d-flex h-100 w-100">
  <div class="row justify-content-center align-self-center w-100 h-100">
    <app-accentry-logo class="logo"></app-accentry-logo>
    <div class="tagLine">
      <h1>
        <b [@openClose]="isOpen5 ? 'open' : 'closed'">{{tagLine}} </b> <span
          [@openClose]="isOpen ? 'open' : 'closed'">bicycles,
        </span><br />
        <span [@openClose]="isOpen2 ? 'open' : 'closed'">parts</span><b [@openClose]="isOpen3 ? 'open' : 'closed'"> and
        </b><span [@openClose]="isOpen4 ? 'open' : 'closed'">accessories</span>
      </h1>
    </div>

    <div class="col-md-7 bg" [style.background-image]="bg">
    </div>
    <div class="ms-auto me-auto col-md-5 d-flex loginBox h-100" *ngIf="showLoginForm">
      <div class="login-form justify-content-center align-self-center" style="z-index: 999;">
        <div class="main-div row">
          <ng-container [ngSwitch]="viewType">
            <ng-container *ngSwitchCase="'login'">
              <form name="form" class="col-md-12" id="loginForm" (ngSubmit)="f.form.valid && login()" #f="ngForm"
                novalidate>
                <div class="mb-3" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                  <label for="username">{{"USERNAME"|translate}}</label>
                  <input type="text" id="username" class="form-control form-control-lg" name="username" autocomplete="true"
                    [(ngModel)]="model.username" #username="ngModel" />
                  <div *ngIf="f.submitted && !usernameValue" class="help-block">{{"REQUIRED"|translate}}</div>
                </div>
                <div class="mb-3 position-relative" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                  <label for="password">{{"PASSWORD"|translate}}</label>
                  <input [attr.type]="togglePassword ? 'password' : 'text'" id="password" class="form-control form-control-lg" name="password" [(ngModel)]="model.password"
                    #password="ngModel" autocomplete="current-password" />
                    <i class="material-icons position-absolute togglePassword clickable withOpacity" (click)="togglePassword = !togglePassword">{{togglePassword?'visibility':'visibility_off'}}</i>
                  <div *ngIf="f.submitted && !passwordValue" class="help-block">{{"REQUIRED"|translate}}</div>
                </div>
                <div class="ps-0 pe-0 mb-3 col-md-12">
                  <div class="d-grid">
                    <button [disabled]="loading" class="btn btn-block btn-lg btn-primary loginButton">
                      <ng-container *ngIf="!loading">{{"LOGIN"|translate}}</ng-container><span *ngIf="loading"
                        class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                  </div>
                  <span [routerLink]="'/resetpassword'" (click)="viewType = 'resetPassword'"
                    class="ps-0 btn forgot btn-link">{{"FORGOT_PASSWORD"|translate}}</span>
                </div>
              </form>
            </ng-container>
            <ng-container *ngSwitchCase="'resetPassword'">
              <div class="col-md-12">
                <h2>{{"RESET_PASSWORD"|translate}}</h2>
                <app-reset-password (back)="viewType = 'login'"></app-reset-password>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
