import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import SwiperCore, { FreeMode, Navigation, Thumbs, Zoom } from 'swiper';
SwiperCore.use([Zoom, FreeMode, Navigation, Thumbs]);
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-article-image-lightbox',
  templateUrl: './article-image-lightbox.component.html',
  styleUrls: ['./article-image-lightbox.component.scss'],
})
export class ArticleImageLightboxComponent implements OnInit {
  articleImages: any = [];
  thumbsSwiper: any;
  title = "";
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}


  ngAfterViewChecked() {
    register();

    const swiperEl = document.querySelector('.lightbox-carousel');
    if (swiperEl && !swiperEl.classList.contains("swiper-initialized")) {
      const params = {
        injectStylesUrls: ['/assets/carousel.css'],
      };

      Object.assign(swiperEl, params);

      swiperEl['initialize']();
    }
  }

  ngAfterViewInit() {

  }
}
