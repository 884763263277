import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from "@angular/core";
import * as _ from "underscore";
import { AccountAgreement } from "src/app/interfaces/yearAgreement/account-agreement";
import { AvailableAgreementTypes } from "src/app/interfaces/yearAgreement/available-agreement-types";
import { YearAgreementService } from "src/app/services/year-agreement.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { AgreementOption } from "src/app/interfaces/yearAgreement/agreement-option";
import { YearAgreementDataModel } from "src/app/interfaces/yearAgreement/year-agreement-data-model";
import { TurnOverSelection } from "src/app/interfaces/yearAgreement/turn-over-selection";
import * as moment from "moment";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-year-agreement-brand-selection",
  templateUrl: "./year-agreement-brand-selection.component.html",
  styleUrls: ["./year-agreement-brand-selection.component.scss"],
})
export class YearAgreementBrandSelectionComponent implements OnInit {
  @Output() brandSelected: EventEmitter<number> = new EventEmitter<number>();
  @Input() accountAgreement: AccountAgreement;
  @Input() yearAgreementModel: YearAgreementDataModel;
  @Input() index: number;
  @Input() isMain: boolean = false;
  @Input() affiliate: string;
  groups = ["brands", "specials"];
  isCmDealer: boolean;
  brandsGroups: AgreementOption[];
  sections = {
    brands: "BRANDS_YEARAGREEMENT",
    sports: "SPORTS",
    specials: "SPECIALS",
  };
  brands = null;

  get allAffiliates() {
    return this.yearAgreementModel && this.yearAgreementModel.affiliates
      ? this.yearAgreementModel.affiliates.split(",")
      : [];
  }

  get mainAffiliate() {
    return this.allAffiliates && this.allAffiliates.length
      ? this.allAffiliates[0]
      : "";
  }

  get isMainAffiliate() {
    return this.mainAffiliate == this.affiliate || !this.allAffiliates.length;
  }

  constructor(private yearAgreementService: YearAgreementService, private helperService: HelperService) {}

  ngOnInit() {
    this.setBrands();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["accountAgreement"]) {
      this.setBrands();
    }
  }

  setBrands() {
    this.brands = {
      brands: [
        {
          id: "batavus",
          name: "Batavus",
          selected: null,
          type: "brands",
          affiliate: this.affiliate,
        },
        {
          id: "sparta",
          name: "Sparta",
          selected: null,
          type: "brands",
          affiliate: this.affiliate,
        },
        {
          id: "koga",
          name: "KOGA",
          selected: null,
          type: "brands",
          affiliate: this.affiliate,
        }
      ],
      specials: [
        {
          id: "koga_signature",
          name: "KOGA Signature ",
          selected: null,
          type: "specials",
          affiliate: this.affiliate,
        },
        {
          id: "van_nicholas",
          name: "Van Nicholas",
          selected: null,
          type: "specials",
          affiliate: this.affiliate,
        },
      ],
    };

    this.setInitialSelection();
    this.changeCMDealer();
  }

  setInitialSelection() {
    this.groups.forEach((t) => {
    if (this.isMain) {
      this.yearAgreementModel[t]["brands"] = [];
    }

      if (
        typeof this.yearAgreementModel[t]["brands_affiliates"] === "undefined"
      ) {
        this.yearAgreementModel[t]["brands_affiliates"] = [];
      }
      this.brands[t].forEach((b: any) => {
        if (
          this.isMain
            ? _.find(this.accountAgreement.brands, {
                brand: b.id,
                affiliate: "",
              })
            : _.find(this.accountAgreement.brands, {
                brand: b.id,
                affiliate: String(this.affiliate),
              })
        ) {
          b.selected = true;
        }

        if (b.selected === true) {

          if (this.isMain) {
            this.yearAgreementModel[b.type]["hasAccess"] = true;
            this.yearAgreementModel[b.type]["brands"].push(b.id);
          }

          this.yearAgreementModel[b.type]["brands_affiliates"].push(b);
        } else {
        }
      });
    });
    if (this.yearAgreementModel.cmDealer) {
      this.getBrandsOptions();
    }
    this.setTurnover();
    this.setTurnoverHistory();
  }

  setTurnover() {
    for (const section in this.brands) {
      const brandsSelection = this.brands[section];
      brandsSelection.forEach((brand) => {
        const turnoverBrand = this.accountAgreement.turnover.find(
          (b: TurnOverSelection) => {
            return b.brand === brand["id"];
          }
        );
        if (turnoverBrand) {
          this.yearAgreementModel[section]["turnover_selection"][brand["id"]] =
            turnoverBrand.turnover;
        }
      });
    }
  }

  setTurnoverHistory() {
    for (const section in this.brands) {
      this.yearAgreementModel[section]["turnover_history"] = {};
      const brandsSelection = this.brands[section];
      brandsSelection.forEach((brand) => {
        const years = _.uniq(
          _.pluck(this.accountAgreement.turnover_history, "year")
        );
        years.forEach((year: number) => {
          let total = 0;
          const turnoverBrand = this.accountAgreement.turnover_history.find(
            (b: TurnOverSelection) => {
              return b.brand === brand["id"] && b.year === year;
            }
          );
          if (turnoverBrand) {
            if (!this.yearAgreementModel[section]["turnover_history"]) {
              this.yearAgreementModel[section]["turnover_history"] = {};
            }

            if (
              !this.yearAgreementModel[section]["turnover_history"][
                turnoverBrand["year"]
              ]
            ) {
              this.yearAgreementModel[section]["turnover_history"][
                turnoverBrand["year"]
              ] = {};
            }

            this.yearAgreementModel[section]["turnover_history"][
              turnoverBrand["year"]
            ][brand["id"]] = turnoverBrand.turnover;

            if (
              !this.yearAgreementModel[section]["turnover_history"][
                turnoverBrand["year"]
              ]["total"]
            )
              this.yearAgreementModel[section]["turnover_history"][
                turnoverBrand["year"]
              ]["total"] = 0;

            this.yearAgreementModel[section]["turnover_history"][
              turnoverBrand["year"]
            ]["total"] += brand.selected ? turnoverBrand.turnover : 0;
          }
        });
      });
    }
  }

  changeBrand(brand) {
    const type = brand["type"];
    const selected = _.where(this.brands[type], { selected: true });
    const brands = this.yearAgreementModel[type]["brands_affiliates"]
      ? this.yearAgreementModel[type]["brands_affiliates"].filter((b) => {
          return b.affiliate != this.affiliate;
        })
      : [];

    if (this.isMain) {
      this.yearAgreementModel[type]["hasAccess"] = selected.length > 0;
      this.yearAgreementModel[type]["brands"] = _.pluck(selected, "id");
      // When deselected remove from turnover selection
      if (!brand["selected"]) {
        this.yearAgreementModel[type]["turnover_selection"][brand["id"]] = 0;
      }
      this.setTurnoverHistory();
    }

    this.yearAgreementModel[type]["brands_affiliates"] = brands.concat(
      selected
    );
    this.brandSelected.emit(moment().unix());
  }

  changeCMDealer() {
    if (this.yearAgreementModel["cmDealer"]) {
      this.yearAgreementModel["brands"]["selected"]["FocusModels"] = true;
      if (!this.brandsGroups) {
        this.getBrandsOptions();
      } else {
        this.setCmSelection();
      }
    }
  }

  getBrandsOptions() {
    this.yearAgreementService
      .getGroups(AvailableAgreementTypes.Brands, this.accountAgreement.year)
      .then((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.brandsGroups = apiResponse.result;
          this.setCmSelection();
        }
      });
  }

  // Select best option when CM dealer
  setCmSelection() {
    let totalPercentage = 0;
    this.brandsGroups.forEach((g) => {
      this.yearAgreementModel[AvailableAgreementTypes.Brands]["percentages"][
        g.sub_group_type
      ] = 0;

      if (g.sub_group_type !== "Bonus") {
        g.sub_group_options.forEach((s) => {
          let options = s.options;

          if (options.length) {

            if (options[0].option_type === "TurnoverBrands") {
              options = _.sortBy(options, "percentage");
              s.options = options;
            }

            let selectedOption = null;

            if (typeof options[0].option_type !== "undefined" && options[0].option_type !== "DealerType") {
              selectedOption = options[options.length - 1];
            } else {
              selectedOption = options.find((o) => {
                return o.option_value === "DRG CM";
              });
            }

            this.yearAgreementModel[AvailableAgreementTypes.Brands]["selected"][
              selectedOption.option_type
            ] =
              selectedOption.option_value === "yes"
                ? true
                : selectedOption.option_value;
            totalPercentage += selectedOption.percentage;
            this.yearAgreementModel[AvailableAgreementTypes.Brands][
              "percentages"
            ][g.sub_group_type] += selectedOption.percentage;

            if (
              !this.yearAgreementModel[AvailableAgreementTypes.Brands][
                "section_percentages"
              ][g.sub_group_type]
            ) {
              this.yearAgreementModel[AvailableAgreementTypes.Brands][
                "section_percentages"
              ][g.sub_group_type] = {};
            }

            this.yearAgreementModel[AvailableAgreementTypes.Brands][
              "section_percentages"
            ][g.sub_group_type][selectedOption.option_type] =
              selectedOption.percentage;
          }

        });
      } else {
        g.sub_group_options.forEach((s) => {
          if (s.options.length) {
            const option = s.options[0];
            if (option.option_type === "DealerTypeBonus") {
              const selectedOption = s.options.find((o) => {
                return o.option_value === "DRG CM";
              });

              if (
                !this.yearAgreementModel[AvailableAgreementTypes.Brands][
                  "section_percentages"
                ][g.sub_group_type]
              ) {
                this.yearAgreementModel[AvailableAgreementTypes.Brands][
                  "section_percentages"
                ][g.sub_group_type] = {};
              }

              this.yearAgreementModel[AvailableAgreementTypes.Brands][
                "section_percentages"
              ][g.sub_group_type][selectedOption.option_type] =
                selectedOption.percentage;
            } else if (option.option_type === "RealisationBonus") {
              const selectedOption = s.options.find((o) => {
                return o.option_value === "yes";
              });
              if (selectedOption &&  this.yearAgreementModel[AvailableAgreementTypes.Brands][
                "section_percentages"
              ][g.sub_group_type]) {
                this.yearAgreementModel[AvailableAgreementTypes.Brands][
                  "section_percentages"
                ][g.sub_group_type][selectedOption.option_type] =
                  selectedOption.percentage;
              }
            } else if (option.option_type === "PaymentDiscount") {
              const selectedOption = s.options.find((o) => {
                return o.option_value === "yes";
              });
              if (
                this.yearAgreementModel[AvailableAgreementTypes.Brands][
                  "section_percentages"
                ][g.sub_group_type]
              ) {
                this.yearAgreementModel[AvailableAgreementTypes.Brands][
                  "section_percentages"
                ][g.sub_group_type][selectedOption.option_type] =
                  selectedOption.percentage;
              }
            }
          }
        });

        if (
          this.yearAgreementModel[AvailableAgreementTypes.Brands][
            "section_percentages"
          ]["Bonus"]
        ) {
          this.yearAgreementModel[AvailableAgreementTypes.Brands]["selected"][
            "DealerTypeBonus"
          ] = "DRG CM";
          this.yearAgreementModel[AvailableAgreementTypes.Brands]["selected"][
            "RealisationBonus"
          ] = "SquareMeter";
          this.yearAgreementModel[AvailableAgreementTypes.Brands]["selected"][
            "PaymentDiscount"
          ] = true;
        }
      }
    });

    // Add bonus margins to the CM selection (if selected)
    if (
      this.yearAgreementModel[AvailableAgreementTypes.Brands][
        "section_percentages"
      ]["Bonus"]
    ) {
      Object.values(
        this.yearAgreementModel[AvailableAgreementTypes.Brands][
          "section_percentages"
        ]["Bonus"]
      ).forEach((element) => {
        this.yearAgreementModel[AvailableAgreementTypes.Brands]["percentages"][
          "Bonus"
        ] += element;
      });
    }

    this.yearAgreementModel.showMargins = true;
  }
}
