<ul class="list-group">
  <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let agreement of this.agreements">
    {{ agreement.year }}
    <ng-container>
      <i *ngIf="agreement && agreement.signed"
        >{{ "SIGNED" | translate }}: {{ _getSignedDate(agreement.signed) }}</i
      >
      <button
        class=" btn btn-sm btn-success"
        (click)="agreement.signed ? getPdf(agreement) : openPopup(agreement)"
      >
        {{ "VIEW" | translate }}
      </button>
    </ng-container>

    <span *ngIf="!agreement.send" class=" pt-1 me-2">
      {{ "IN_PROCESS" | translate }}
    </span>
  </li>
</ul>
