import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-brand-selector-large",
  templateUrl: "./brand-selector-large.component.html",
  styleUrls: ["./brand-selector-large.component.scss"],
})
export class BrandSelectorLargeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
