<div class="container">
  <div class="row">
    <div class="px-3 col-md-12">
      <ul ngbNav
        #nav="ngbNav"
        justify="fill"
        [activeId]="selectedTab"
        (navChange)="tabChange($event)"
        class="mt-3 nav-pills"
      >
        <ng-container ngbNavItem="registerSale">
          <a ngbNavLink>{{ "REGISTER_SALE" | translate }}</a>
        </ng-container>
        <ng-container ngbNavItem="ezyRider">
          <a ngbNavLink>{{
            (countryCode === "NL" ? "EZY_RIDER" : "XTRA_GUARANTEE") | translate
          }}</a>
        </ng-container>
        <ng-container ngbNavItem="service">
          <a ngbNavLink>{{ "SERVICE" | translate }}</a>
        </ng-container>
        <ng-container ngbNavItem="registrationsOverview">
          <a ngbNavLink>{{ "REGISTER_SALE_OVERVIEW" | translate }}</a>
        </ng-container>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
      <div class="row" [hidden]="selectedTab === 'registrationsOverview'">
        <div class="p-3 col-12 col-md-7 formContainer">
          <form
            *ngIf="formGroup"
            class="p-4"
            ngNativeValidate
            [formGroup]="formGroup"
            [ngClass]="validated ? 'validatedChecked' : ''"
          >
            <h3>
              {{ "BIKE_DETAILS" | translate }}
            </h3>
            <ng-container
              *ngIf="selectedTab === 'ezyRider' && ebikeRegistration"
            >
              <ngb-alert
                type="danger"
                [dismissible]="false"
                *ngIf="
                  !ebikeRegistration.prepared &&
                  ebikeRegistration.xtra_guarantee_applicable
                "
              >
                {{ "NOT_PREPARED" | translate }}
              </ngb-alert>
              <ngb-alert
                type="danger"
                [dismissible]="false"
                *ngIf="!ebikeRegistration.xtra_guarantee_applicable"
              >
                {{
                  (!this.ebikeRegistration.xtraGuaranteeDate
                    ? "XTRA_GUARANTEE_NOT_AVAILABLE"
                    : "ALREADY_XTRA"
                  ) | translate
                }}
              </ngb-alert>

              <ngb-alert
                type="success"
                [dismissible]="false"
                *ngIf="
                  !loading &&
                  ezySwitch === 'check' &&
                  ebikeRegistration &&
                  ebikeRegistration.xtraGuaranteeDate
                "
              >
                {{ "GUARANTEE_ENDDATE" | translate }}:
                {{ getDate(ebikeRegistration.xtraGuaranteeDate) }}
              </ngb-alert>
            </ng-container>
            <ngb-alert
              type="danger"
              [dismissible]="false"
              *ngIf="
                selectedTab === 'registerSale' &&
                ebikeRegistration &&
                ebikeRegistration.preparation_data
              "
            >
              {{ "FRAMENR_ALREADY_PREPARED_FOR_USE" | translate }}
            </ngb-alert>
            <ngb-alert
              type="danger"
              [dismissible]="false"
              *ngIf="
                selectedTab === 'registerSale' &&
                ebikeRegistration &&
                ebikeRegistration.registration_applicable &&
                ebikeRegistration.ion
              "
            >
              {{ "ION_PREPARE_ERROR" | translate }}
            </ngb-alert>
            <ng-container
              *ngTemplateOutlet="
                selectedTab === 'ezyRider' ? ezySwitchInput : empty
              "
            ></ng-container>
            <ng-container
              *ngTemplateOutlet="
                framenumberInput;
                context: {
                  type: 'text',
                  maxLength: '30',
                  inputClass: 'col-sm-7',
                  label: translateService.instant('FRAMENUMBER'),
                  name: 'frame_number'
                }
              "
            >
            </ng-container>

            <ng-container *ngIf="showRegistrationForm">
              <ng-container
                *ngTemplateOutlet="
                  selectedTab === 'registerSale' ||
                  (selectedTab === 'ezyRider' &&
                    ebikeRegistration.xtra_guarantee_applicable &&
                    ezySwitch == 'new')
                    ? invoiceDate
                    : empty;
                  context: {
                    name: 'invoice_date',
                    label: translateService.instant('INVOICE_DATE')
                  }
                "
              >
              </ng-container>
              <ng-container
                *ngIf="
                  (selectedTab === 'ezyRider' &&
                    ebikeRegistration.xtra_guarantee_applicable) ||
                  (selectedTab === 'registerSale' && selectForEzy)
                "
              >
                <ng-container *ngTemplateOutlet="ezyEnddate"> </ng-container>
              </ng-container>
              <ng-container
                *ngIf="
                  selectedTab === 'registerSale' &&
                  ebikeRegistration &&
                  ebikeRegistration.ebike
                "
              >
                <ng-container
                  *ngTemplateOutlet="
                    generalInput;
                    context: {
                      type: 'text',
                      maxLength: '20',
                      inputClass: 'col-sm-3',
                      label: translateService.instant('BATTERY') + ' 1',
                      name: 'battery_one',
                      placeholder: 'BY...'
                    }
                  "
                >
                </ng-container>
                <ng-container
                  *ngTemplateOutlet="
                    generalInput;
                    context: {
                      type: 'text',
                      maxLength: '20',
                      inputClass: 'col-sm-3',
                      label: translateService.instant('BATTERY') + ' 2',
                      name: 'battery_two',
                      placeholder: 'BY...'
                    }
                  "
                >
                </ng-container>
              </ng-container>
              <ng-container
                *ngTemplateOutlet="
                  selectedTab === 'service' ? serviceSwitchInput : empty
                "
              ></ng-container>
              <ng-container
                *ngTemplateOutlet="
                  selectedTab === 'service' ? invoiceDate : empty;
                  context: {
                    type: 'text',
                    maxLength: '30',
                    inputClass: 'col-sm-7',
                    label: translateService.instant('SERVICE_DATE'),
                    name: 'service_date'
                  }
                "
              >
              </ng-container>
              <ng-container
                *ngTemplateOutlet="
                  selectedTab === 'service' ? serviceButton : empty
                "
              ></ng-container>
              <ng-container
                *ngIf="
                  selectedTab === 'registerSale' ||
                  (selectedTab === 'ezyRider' &&
                    ezySwitch == 'new' &&
                    ebikeRegistration.xtra_guarantee_applicable)
                "
              >
                <h3>
                  {{ "DEALER_DETAILS" | translate }}
                </h3>
                <ng-container
                  *ngTemplateOutlet="
                    generalInput;
                    context: {
                      type: 'text',
                      maxLength: '30',
                      inputClass: 'col-sm-7',
                      label: translateService.instant('SALES_PERSON'),
                      name: 'sales_person'
                    }
                  "
                >
                </ng-container>
                <ng-container *ngTemplateOutlet="registerForm"></ng-container>
              </ng-container>
            </ng-container>
          </form>
        </div>

        <div class="pt-2 col-12 col-md-5">
          <app-cms-page-content
            #cmsContent
            [pageId]="pageIds[selectedTab]"
          ></app-cms-page-content>
          <div class="bg-white article">
            <div *ngIf="bicycleArticle && bicycleArticle.description">
              <h3 [innerHTML]="bicycleArticle.description"></h3>
              <h4 [innerHTML]="bicycleArticle.caption"></h4>
              <h5 [articleQuickView]="frameNumber">
                {{ "FRAMENUMBER" | translate }}: {{ frameNumber }}
              </h5>
              <div class="pt-2 my-2 w-100 d-flex justify-content-between">
                <app-article-image
                  *ngIf="
                    bicycleArticle.img &&
                    bicycleArticle.img.length &&
                    bicycleArticle.img[0] !== ''
                  "
                  [imageUrl]="bicycleArticle.img[0]"
                ></app-article-image>
                <app-article-image
                  class="mx-2"
                  *ngIf="bicycleArticle.img && bicycleArticle.img.length > 1"
                  [imageUrl]="bicycleArticle.img[1]"
                ></app-article-image>
                <app-article-image
                  *ngIf="bicycleArticle.img && bicycleArticle.img.length > 2"
                  [imageUrl]="bicycleArticle.img[2]"
                ></app-article-image>
              </div>

              <app-article-links
                #links
                class="float-left mt-2 w-100"
                [article]="bicycleArticle"
              ></app-article-links>

              <span
                *ngIf="selectedTab === 'registerSale' && showRegistrationForm"
                class="d-block"
                [ngClass]="'text-success'"
                ><i class="material-icons">check_circle</i>
                {{ "APPLICABLE_FOR_REGISTRATION" | translate }}</span
              >
              <span
                *ngIf="
                  selectedTab === 'ezyRider' &&
                  ebikeRegistration.xtra_guarantee_applicable
                "
                class="d-block"
                [ngClass]="'text-success'"
                ><i class="material-icons">check_circle</i>
                {{ "APPLICABLE_FOR_REGISTRATION" | translate }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="selectedTab === 'registrationsOverview'">
        <div class="px-2 pt-2">
          <h1>{{ "REGISTER_SALE_OVERVIEW" | translate }}</h1>
          <app-ebike-registrations
            (showPdfClicked)="showPdf($event)"
            (showPdfEzyClicked)="showEzyPdf($event)"
          ></app-ebike-registrations>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Templates -->
<ng-template #registerForm>
  <h3>{{ "CONSUMER_INFORMATION" | translate }}</h3>
  <ng-container *ngIf="selectForEzy || selectedTab === 'ezyRider'">
    <ng-container *ngTemplateOutlet="genderInput"> </ng-container>
    <ng-container
      *ngTemplateOutlet="
        generalInput;
        context: {
          type: 'text',
          maxLength: '5',
          inputClass: 'col-sm-2',
          label: translateService.instant('INITIALS'),
          name: 'initials'
        }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        generalInput;
        context: {
          type: 'text',
          maxLength: '30',
          inputClass: 'col-sm-7',
          label: translateService.instant('FIRST_NAME'),
          name: 'first_name'
        }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        generalInput;
        context: {
          type: 'text',
          maxLength: '20',
          inputClass: 'col-sm-7',
          label: translateService.instant('MIDDLENAME'),
          name: 'middle_name'
        }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        generalInput;
        context: {
          type: 'text',
          maxLength: '30',
          inputClass: 'col-sm-7',
          label: translateService.instant('LASTNAME'),
          name: 'last_name'
        }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        birthDateInput;
        context: {
          type: 'text',
          minLength: '8',
          maxLength: '10',
          inputClass: 'col-sm-4',
          label: translateService.instant('BIRTHDATE'),
          name: 'birthdate'
        }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        generalInput;
        context: {
          type: 'text',
          maxLength: '40',
          inputClass: 'col-sm-7',
          label: translateService.instant('STREETNAME'),
          name: 'street_name'
        }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        generalInput;
        context: {
          type: 'text',
          maxLength: '5',
          inputClass: 'col-sm-2',
          label: translateService.instant('HOUSENUMBER'),
          name: 'house_no'
        }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        generalInput;
        context: {
          type: 'text',
          maxLength: '5',
          inputClass: 'col-sm-7',
          label: translateService.instant('SUFFIX'),
          name: 'suffix'
        }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        generalInput;
        context: {
          type: 'text',
          maxLength: '10',
          inputClass: 'col-sm-2',
          label: translateService.instant('ZIP'),
          name: 'zip'
        }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        generalInput;
        context: {
          type: 'text',
          maxLength: '40',
          inputClass: 'col-sm-7',
          label: translateService.instant('CITY'),
          name: 'city'
        }
      "
    >
    </ng-container>
  </ng-container>
  <ng-container
    *ngTemplateOutlet="
      generalInput;
      context: {
        type: 'email',
        maxLength: '50',
        inputClass: 'col-sm-7',
        label: translateService.instant('EMAIL'),
        name: 'email'
      }
    "
  >
  </ng-container>
  <ng-container *ngTemplateOutlet="countrySelect"></ng-container>
  <ng-container
    *ngTemplateOutlet="selectedTab === 'registerSale' ? ezyOption : empty"
  ></ng-container>
  <div class="row">
    <div class="col-sm-4"></div>
    <div class="col-sm-8">
      <input
        type="submit"
        class="btn btn-primary btn-lg"
        value="{{ 'SEND' | translate }}"
        [disabled]="!sendEnabled || loading"
        [ngClass]="!sendEnabled || loading ? 'disabled' : ''"
        (click)="sendForm()"
      />
    </div>
  </div>
</ng-template>
<ng-template #serviceSwitchInput>
  <div class="mb-3 row">
    <label class="col-sm-4 col-form-label">{{
      "SERVICE_MAINTENANCE" | translate
    }}</label>
    <div class="col-sm-7">
      <input
        type="radio"
        name="serviceSwitch"
        value="service"
        id="xservice"
        [(ngModel)]="serviceSwitch"
      />
      <label class="ms-1" for="xservice">{{
        "FIRST_MAINTENANCE" | translate
      }}</label
      ><br />
      <input
        type="radio"
        name="serviceSwitch"
        value="maintenance"
        id="xmaintenance"
        [(ngModel)]="serviceSwitch"
      />
      <label class="ms-1" for="xmaintenance">{{
        "SERVICE_MAINTENANCE" | translate
      }}</label
      ><br />
    </div>
  </div>
</ng-template>
<ng-template #serviceButton>
  <div class="mb-3 row">
    <div class="col-sm-4"></div>
    <div class="col-sm-7">
      <input
        type="submit"
        class="btn btn-primary btn-lg"
        value="{{ 'SEND' | translate }}"
        [disabled]="!sendServiceEnabled"
        [ngClass]="!sendServiceEnabled ? 'disabled' : ''"
        (click)="sendForm()"
      />
    </div>
  </div>
</ng-template>
<ng-template #countrySelect>
  <div class="mb-3 row">
    <label class="col-sm-4 col-form-label"
      >{{ "COUNTRY" | translate }}
      <ng-container *ngTemplateOutlet="required"></ng-container
    ></label>
    <div class="col-sm-7" [formGroup]="formGroup">
      <select required class="form-control" [formControlName]="'country'">
        <option>{{ "SELECT" | translate }}</option>
        <option *ngFor="let country of countries" [value]="country['code']">
          {{ country["description"] }}
        </option>
      </select>
    </div>
  </div>
</ng-template>
<ng-template #ezySwitchInput>
  <div class="mb-3 row">
    <label class="col-sm-4 col-form-label">{{
      "REGISTRATION" | translate
    }}</label>
    <div class="col-sm-7">
      <input
        type="radio"
        name="ezySwitch"
        value="new"
        id="snew"
        [(ngModel)]="ezySwitch"
      />
      <label class="ms-2" for="snew">{{ "NEW" | translate }}</label
      ><br />
      <input
        type="radio"
        name="ezySwitch"
        value="check"
        id="scheck"
        [(ngModel)]="ezySwitch"
      />
      <label class="ms-2" for="scheck">{{ "CHECK" | translate }}</label
      ><br />
    </div>
  </div>
</ng-template>
<ng-template #genderInput>
  <div class="mb-3 row">
    <label class="col-sm-4 col-form-label">{{ "GENDER" | translate }}</label>
    <div class="col-sm-7" [formGroup]="formGroup">
      <input
        type="radio"
        name="gender"
        value="M"
        id="gmale"
        [formControlName]="'gender'"
      />
      <label class="ms-2" for="gmale">{{ "MALE" | translate }}</label
      ><br />
      <input
        type="radio"
        name="gender"
        value="F"
        id="gfemale"
        [formControlName]="'gender'"
      />
      <label class="ms-2" for="gfemale">{{ "FEMALE" | translate }}</label
      ><br />
    </div>
  </div>
</ng-template>
<ng-template #ezyEnddate>
  <div class="mb-3 row" *ngIf="!noEzyRider || ezySwitch !== 'check'">
    <label class="col-sm-4 col-form-label">{{
      "GUARANTEE_ENDDATE" | translate
    }}</label>
    <div class="col-sm-4">
      <input
        type="text"
        class="form-control"
        readonly
        [ngModel]="guaranteeEnddate"
      />
    </div>
  </div>
  <div *ngIf="noEzyRider && ezySwitch === 'check'">
    <ngb-alert [dismissible]="false">{{ "NO_RESULTS" | translate }}</ngb-alert>
  </div>
</ng-template>
<ng-template #ezyOption>
  <div
    class="mb-3 row"
    *ngIf="
      selectedTab === 'registerSale' &&
      ebikeRegistration.xtra_guarantee_applicable
    "
  >
    <label class="col-sm-4 col-form-label"></label>
    <div class="col-sm-7">
      <div class="form-check form-switch">
        <input
          type="checkbox"
          class="form-check-input"
          id="select_for_ezy"
          name="select_for_ezy"
          (change)="setValidation()"
          [(ngModel)]="selectForEzy"
        />
        <label
          class="form-check-label justify-content-end"
          for="select_for_ezy"
          >{{ "REGISTER_FOR_XTRA" | translate }}</label
        >
      </div>
    </div>
  </div>
</ng-template>
<ng-template #invoiceDate let-label="label" let-name="name">
  <div class="mb-3 row">
    <div class="col-sm-4">
      <label
        >{{ label }} <ng-container *ngTemplateOutlet="required"></ng-container
      ></label>
    </div>
    <div class="col-sm-4">
      <div class="input-group">
        <input
          class="bg-white form-control"
          placeholder="dd-mm-yyyy"
          type="text"
          required="required"
          name="dp"
          (click)="selectedTab !== 'ezyRider' ? d.toggle() : false"
          [(ngModel)]="selectedInvoiceDate"
          (ngModelChange)="dateChanged($event)"
          [attr.readonly]="selectedTab === 'ezyRider' ? 'readonly' : undefined"
          [maxDate]="maxDate"
          [minDate]="minDate"
          ngbDatepicker
          #d="ngbDatepicker"
        />
        <div class="input-group-append" *ngIf="selectedTab !== 'ezyRider'">
          <button
            class="btn btn-outline-secondary"
            (click)="d.toggle()"
            type="button"
          >
            <i class="material-icons" style="font-size: 17px">date_range</i>
          </button>
        </div>
      </div>
      <ngb-alert
        *ngIf="validDate === false && selectedTab === 'registerSale'"
        >{{ "INVOICE_DATE_NOT_CORRECT" | translate }}</ngb-alert
      >
      <ngb-alert
        *ngIf="
          validDate === false &&
          selectedTab === 'ezyRider' &&
          selectedInvoiceDate
        "
        >{{ "DATE_OUT_OF_RANGE" | translate }}</ngb-alert
      >
    </div>
    <div class="px-0 text-start col-sm-1">
      <i
        class="material-icons text-success"
        style="transform: translateY(5px)"
        *ngIf="validDate"
        >check_circle</i
      >
    </div>
  </div>
</ng-template>
<ng-template
  #generalInput
  let-label="label"
  let-name="name"
  let-inputClass="inputClass"
  let-maxLength="maxLength"
  let-minLength="minLength"
  let-placeholder="placeholder"
  let-type="type"
>
  <div class="mb-3 row">
    <label class="col-sm-4 col-form-label"
      >{{ label }}
      <ng-container *ngIf="isRequiredField(name)">
        <ng-container *ngTemplateOutlet="required"></ng-container>
      </ng-container>
    </label>
    <div [ngClass]="inputClass" [formGroup]="formGroup">
      <input
        [type]="type"
        [ngClass]="!formGroup.get(name).valid && validated ? 'is-invalid' : ''"
        class="form-control"
        (click)="validated = false"
        (blur)="inputChange($event, name)"
        [maxlength]="maxLength"
        [minLength]="minLength ? minLength : undefined"
        [attr.required]="
          formGroup.controls[name].validator &&
          !formGroup.get(name).valid &&
          formGroup.controls[name].validator.length
            ? true
            : null
        "
        placeholder="{{
          placeholder
            ? placeholder
            : formGroup.controls[name].validator &&
              !formGroup.get(name).valid &&
              formGroup.controls[name].validator.length
            ? ('REQUIRED' | translate)
            : ''
        }}"
        [formControlName]="name"
      />
    </div>
  </div>
</ng-template>
<ng-template
  #birthDateInput
  let-label="label"
  let-name="name"
  let-inputClass="inputClass"
  let-maxLength="maxLength"
  let-minLength="minLength"
  let-placeholder="placeholder"
  let-type="type"
>
  <div class="mb-3 row">
    <label class="col-sm-4 col-form-label"
      >{{ label }}
      <ng-container *ngIf="isRequiredField(name)">
        <ng-container *ngTemplateOutlet="required"></ng-container>
      </ng-container>
    </label>
    <div [ngClass]="inputClass" [formGroup]="formGroup">
      <input
        [type]="type"
        [ngClass]="!formGroup.get(name).valid && validated ? 'is-invalid' : ''"
        class="form-control"
        (click)="validated = false"
        (blur)="inputChange($event, name)"
        [maxlength]="maxLength"
        [minLength]="minLength ? minLength : undefined"
        [attr.required]="
          formGroup.controls[name].validator &&
          !formGroup.get(name).valid &&
          formGroup.controls[name].validator.length
            ? true
            : null
        "
        placeholder="DD-MM-YYYY"
        [formControlName]="name"
      />
    </div>
  </div>
</ng-template>
<ng-template
  #framenumberInput
  let-label="label"
  let-name="name"
  let-inputClass="inputClass"
  let-maxLength="maxLength"
  let-type="type"
>
  <div class="mb-3 row">
    <label class="col-sm-4 col-form-label"
      >{{ label }} <ng-container *ngTemplateOutlet="required"></ng-container
    ></label>
    <div [ngClass]="inputClass" [formGroup]="formGroup">
      <div class="input-group">
        <input
          [type]="type"
          id="framenumberInput"
          #framenumberInput
          [ngClass]="
            !formGroup.get(name).valid && validated ? 'is-invalid' : ''
          "
          class="form-control form-control-lg"
          (click)="validated = false"
          (keyup)="keyUp($event)"
          [maxlength]="maxLength"
          autofocus
          (paste)="onPaste($event)"
          (change)="inputChange(formGroup.controls[name].value, 'frame_number')"
          [attr.required]="
            formGroup.controls[name].validator &&
            !formGroup.get(name).valid &&
            formGroup.controls[name].validator.length
              ? true
              : null
          "
          placeholder="{{
            formGroup.controls[name].validator &&
            !formGroup.get(name).valid &&
            formGroup.controls[name].validator.length
              ? ('REQUIRED' | translate)
              : ''
          }}"
          [formControlName]="name"
        />
        <div class="input-group-append">
          <button
            class="btn btn-dark h-100"
            (click)="getEbikeDetails(formGroup.get(name).value, true)"
            type="button"
            [disabled]="loading"
          >
            <i class="material-icons">search</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #required
  ><span class="fw-bold" style="font-size: 20px">*</span></ng-template
>
<ng-template #empty></ng-template>
