<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container">
  <div class="row">
    <div class="col-md-12 px-3">
      <h1>{{'NOT_IMPLEMENTED' | translate}}</h1>
      <p>{{'USE_OLDACCENTRY'|translate}}</p>
      <button class="btn btn-primary" (click)="gotoOld()">{{'GOTO_OLDACCENTRY'|translate}}</button>
    </div>
  </div>
</div>
