<div class="d-none">
  <ng-template #rt> </ng-template>
</div>
<div class="d-flex mx-sm-2 mx-md-0 mx-lg-0 mx-xl-0">
  <div class="px-0 col-lg-8 col-md-12">
    <div class="p-3 mx-0 mb-3 row seachBar d-flex align-items-center rounded-2">
      <div class="col-auto">
        <label class="col-sm-1 col-form-label">{{
          "SEARCH" | translate
          }}</label>
      </div>
      <div class="col-sm-8">
        <form (submit)="searchDealers($event)" ngNativeValidate [formGroup]="formGroup">
          <div class="input-group">
            <input type="search" class="form-control form-control-lg" (paste)="onPaste($event)"
              formControlName="searchText" required pattern=".{3,}" placeholder="" (blur)="selectedIndex = -1"
              (keyup)="selectedIndex = -1" />
            <button class="btn btn-dark d-flex align-items-center justify-content-center" style="width: 80px"
              [attr.disabled]="searching ? searching : undefined" (click)="searchDealers($event)">
              <i class="material-icons" [hidden]="searching" style="font-size: 28px;">search</i>
              <div class="spinner spinner-border text-light spinner-border-sm" role="status" *ngIf="searching">
                <span class="visually-hidden">{{ "LOADING" | translate }}</span>
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="dealersTable p-2">
      <table class="table table-striped">
        <tbody>
          <tr *ngFor="let dealer of dealers" (click)="selectDealer(dealer)" class="clickable withOpacity"
            [ngClass]="selectedDealer == dealer ? 'selected' : ''">
            <td class="py-1">
              <div class="row">
                <div class="col-12 col-md-4 description">
                  {{ dealer.name }}<br />
                  <small>{{ dealer.dealer_code }}</small>
                </div>
                <div class="col-5 col-md-3">
                  {{ dealer.address }}
                </div>
                <div class="col-5 col-md-3">
                  {{ dealer.zip }} {{ dealer.city }}
                </div>
                <div class="col-2" *ngIf="dealer.okta">
                  <i class="material-icons"> check </i>
                  <small>Okta</small>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="col-lg-4 col-md-12 selectedDealerContainer p-2">
    <ng-container *ngIf="selectedDealer">
      <h4 class="mb-2">{{ selectedDealer.name }}</h4>
      <p>{{ "DEALER_LASTLOGIN" | translate }}: {{ last_activity }}</p>
    </ng-container>
    <ul class="list-group list-group-flush">
      <li class="py-0 ps-2 pe-0 mx-0 list-group-item" *ngFor="let user of users">
        <div class="row">
          <div class="col-md-7">
            {{ user.username
            }}<ng-container *ngIf="user.username !== user.email_address">
              <br />{{ user.email_address }}</ng-container>
            <span *ngIf="user.linked" [attr.title]="'LINKED_ACCOUNT' | translate"><i class="material-icons"
                style="transform: translateY(7.5px)">link</i>
              <small>{{ user.linked }}</small>
            </span>
            <ng-container *ngIf="user.okta || user.owner || selectedDealer.okta">
              <br />
              <a class="me-1 badge bg-dark clickable withOpacity white-text" (click)="showOptions(user)">{{ "OPTIONS" |
                translate }}</a>
            </ng-container>
            <span class="badge bg-info me-1" *ngIf="
            user.owner
          ">{{ "OWNER" | translate }}</span>
            <span class="badge bg-success" *ngIf="
                user.okta
              ">{{ "OKTA" | translate }}</span>
          </div>
          <div class="col-md-5 d-flex justify-content-end">
            <button class="btn btn-sm btn-primary h-100" (click)="selectUser(user)">
              {{ "SELECT" | translate }}
            </button>
          </div>
        </div>
      </li>
    </ul>
    <span class="d-block d-lg-none btn btn-dark" *ngIf="users && users.length"
      (click)="selectedDealer = null; users = null">{{ "CLOSE" | translate }}</span>
  </div>
</div>
