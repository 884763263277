<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'UNLINK' | translate}}: {{linkedAccount.dealer_code}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
</div>
<div class="modal-body p-0">
  <form name="unlinkForm" class="row mx-0" [formGroup]="unlinkForm" (ngSubmit)="ulf.form.valid && unlink()"
    #ulf="ngForm" novalidate>
    <div class="col-md-12 no-padding-left no-padding-right">
      <p class="py-2">{{'UNLINK_EXPLAIN_3' | translate}} </p>
      <input type="text" [ngClass]="unlinkForm.get('newEmail').invalid  ? 'is-invalid' : ''" class="form-control"
        formControlName="newEmail" name="newEmail" [(ngModel)]="newEmail" placeholder="{{ 'NEW_EMAIL' | translate}}"
        autofocus tabindex="-1" />

      <div class="invalid-feedback" *ngIf="unlinkForm.get('newEmail').invalid">
        <div [hidden]="!unlinkForm.get('newEmail').errors.invalidEmail">{{ 'VALIDATION_EMAIL' | translate }}</div>
      </div>

    </div>
    <div class="modal-footer w-100">
      <span class="d-flex justify-content-between w-100">
        <button class="btn btn-outline-secondary float-left me-1" (click)="modal.dismiss('Cross click')" id="unlinkCancel">{{
        'CANCEL_ACTION' | translate}}</button>
        <button class="btn btn-primary  ms-2" *ngIf="closed">{{ 'OK' | translate}}</button>
      </span>
    </div>
  </form>
  <ngb-alert *ngIf="!closed" type="warning" (close)="(closed=true)">{{ alert }}</ngb-alert>
</div>
