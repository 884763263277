import { Component, OnInit } from "@angular/core";
import { AccountService } from "src/app/services/account.service";
import { MenuItem } from "src/app/interfaces/menuItem";
import { User } from "src/app/interfaces/user";
import { UsersOverview } from "src/app/interfaces/users-overview";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { UserProfile } from "src/app/interfaces/user-profile";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EditUserModalComponent } from "../edit-user-modal/edit-user-modal.component";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import { ConfirmDialogComponent } from "../../modal-components/confirm-dialog/confirm-dialog.component";
import { ApiResponse } from "src/app/interfaces/api-response";
import { AlertService } from "src/app/services/alert.service";
import { AcademyAccount } from "src/app/interfaces/academy-account";
import { AcademyAccess } from "src/app/interfaces/academy-access";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"]
})
export class UsersComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  usersOverview: UsersOverview;
  users: User[];
  profiles: UserProfile[];
  academy_accounts: AcademyAccount[];
  model: any;
  selectedIndex = -1;

  constructor(
    private accountService: AccountService,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private modalService: NgbModal,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/home"
      ),
      new Breadcrumb(this.translateService.instant("USERS"), "#")
    ];
    this.getUsers();
  }

  editUser(user, index) {
    this.selectedIndex = index;
    const modalRef = this.modalService.open(EditUserModalComponent, {
      centered: true,
      size: "lg",
      windowClass: "medium",
      backdrop: "static",
      container: "#modalContainer"
    });
    modalRef.componentInstance.user = user;
    modalRef.componentInstance.usersOverview = this.usersOverview
    modalRef.componentInstance.newUser = false;
    modalRef.componentInstance.profiles = this.profiles;
    modalRef.componentInstance.academy_accounts = this.academy_accounts;
    modalRef.componentInstance.dataSaved.subscribe(() => {
      this.getUsers();
    });
    event.preventDefault();
    return false;
  }

  getUsers() {
    this.users = Array<User>();
    this.accountService.getUsers().subscribe((response: ApiResponse) => {
      if (response) {
        this.usersOverview = response.result;
        const profiles = this.usersOverview.profiles;

        if (this.usersOverview.users != null) {
          for (const user of this.usersOverview.users) {
            const profile = profiles.find(
              p => String(p.profile_id) === String(user.profile_id)
            );
            if (profile) {
              user.profile_name = profile.profile_name;
            }
            this.users.push(user);
          }
        }
        this.profiles = profiles;
        this.academy_accounts = this.usersOverview["ebsc_coursemembers"];
      }
    });
  }

  newUser() {
    const newUser = new User();
    newUser.email_address = "";
    newUser.academy_access = new AcademyAccess();
    const modalRef = this.modalService.open(EditUserModalComponent, {
      centered: true,
      size: "lg",
      windowClass: "medium",
      backdrop: "static",
      container: "#modalContainer"
    });
    modalRef.componentInstance.user = newUser;
    modalRef.componentInstance.usersOverview = this.usersOverview
    modalRef.componentInstance.newUser = true;
    modalRef.componentInstance.okta = this.usersOverview.okta;
    modalRef.componentInstance.profiles = this.profiles;
    modalRef.componentInstance.academy_accounts = this.academy_accounts;
    modalRef.componentInstance.dataSaved.subscribe(() => {
      this.getUsers();
    });
    event.preventDefault();
    return false;
  }

  deleteUser(user, index) {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      centered: true,
      size: "sm",
      windowClass: "confirmDialog",
      container: "#modalContainer"
    });
    modalRef.componentInstance.isConfirm = true;
    modalRef.componentInstance.title =
      this.translateService.instant("DELETE_USER") + "?";
    modalRef.componentInstance.confirmClicked.subscribe(() => {
      this.accountService
        .deleteUser(user)
        .subscribe((response: ApiResponse) => {
          if (response.success) {
            this.users.splice(index, 1);
            this.alertService.showInfoNotification(
              this.translateService.instant("USER_DELETED")
            );
          } else {
            this.alertService.showDangerNotification(response.result);
          }
        });
    });
    event.preventDefault();
    return false;
  }
}
