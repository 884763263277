import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: "app-deliver-when-all-in-stock",
  templateUrl: "./deliver-when-all-in-stock.component.html",
})
export class DeliverWhenAllInStockComponent implements OnInit {
  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() state: boolean;
  @Input() id: string = "deliver_when_all";
  model: boolean;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["state"]) {
      this.model = this.state;
    }
  }

  ngOnInit(): void {
    this.model = this.state;
  }

  deliveryConditionChanged() {
    this.changed.emit(this.model);
  }
}
