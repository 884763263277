import { Component, Input, OnInit } from '@angular/core';
import { SbColor } from 'src/app/interfaces/storyblok/sb-color';
import { StoryblokComponents } from '../storyblok-components';

@Component({
  selector: "app-dynamic-grid",
  templateUrl: "./dynamic-grid.component.html",
  styleUrls: ["./dynamic-grid.component.scss"],
})
export class DynamicGridComponent implements OnInit {
  components = StoryblokComponents;

  @Input() _uid: string;
  @Input() component: any;

  @Input() text: any;

  @Input() border_radius: string;
  @Input() max_blocks_per_row: string;
  @Input() column_gap: string = "0";
  @Input() row_gap: string = "0";
  @Input() _editable: any;
  @Input() background_color: SbColor;
  @Input() transparent_background: boolean;
  @Input() text_color: SbColor;
  @Input() border_width: string;
  @Input() border_color: SbColor;
  blockSize: number;

  get borderRadius() {
    if (this.border_radius) {
      return `${this.border_radius}px`;
    } else {
      return undefined;
    }
  }

  constructor() {}

  ngOnInit(): void {
    this.setMaxPerRow();
  }

  setMaxPerRow() {
    const blockSize = 12 / this.text.length;
    if (this.max_blocks_per_row) {
      const maxBlocksPerRowSize = 12 / Number(this.max_blocks_per_row);
      if (maxBlocksPerRowSize > blockSize) {
        this.blockSize = maxBlocksPerRowSize;
      } else {
        this.blockSize = blockSize;
      }
    } else {
      this.blockSize = blockSize;
    }
  }

  get border() {
    if (this.border_width) {
      return `solid ${this.border_width}px ${this.border_color.color??'#000'}`;
    } else {
      return undefined;
    }
  }
}
