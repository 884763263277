import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ResetPasswordComponent } from 'src/app/components/login-components/reset-password/reset-password.component';
import { LoginPartnerContainerComponent } from 'src/app/components/partner-portal/login-partner-container/login-partner-container.component';
import { ChangePasswordContainerComponent } from 'src/app/components/user-components/user-settings/change-password-container/change-password-container.component';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { PartnerPortalSettings } from 'src/app/interfaces/partner-portal/partner-portal-settings';
import { VerifyPasswordContainer } from 'src/app/interfaces/verify-password-container';
import { PartnerPortalSharedModule } from 'src/app/modules/partner-portal-shared/partner-portal-shared.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { AlertService } from 'src/app/services/alert.service';
import { ApplicationService } from 'src/app/services/application.service';
import { AuthenticateService } from 'src/app/services/authenticate.service';
import { HelperService } from 'src/app/services/helper.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-reset-password-partner-portal',
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, RouterModule, LoginPartnerContainerComponent, PartnerPortalSharedModule, SharedModule],
  templateUrl: './reset-password-partner-portal.component.html',
  styleUrl: './reset-password-partner-portal.component.scss'
})
export class ResetPasswordPartnerPortalComponent {
  @ViewChild(ChangePasswordContainerComponent) passwordContainer: ChangePasswordContainerComponent;

  username = "";
  type = "manual"
  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  resetted = false;
  password: string;
  passwordCheck: string;
  error: string;
  message: string;
  bg: string = "";
  constructor(
    private authenticateService: AuthenticateService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private helperService: HelperService,
    private settingsService: SettingsService,
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }

  ngOnInit() {
    this.getSettings();
    this.applicationService.setLanguage();
    if (window.location.href.indexOf("user=") >= 0) {
      this.type = "verify";
      this.verifyData();
    } else {
      this.type = "manual";
    }
  }

  private getSettings() {
    return new Promise((resolve) => {
      this.settingsService.getSettings(true).subscribe((settings: PartnerPortalSettings) => {
      this.bg = settings.login_bg_url;
        resolve(true);
      });
    })
  }

  _createVerifyContainer():VerifyPasswordContainer {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userHash = urlParams.get('user');
    const passwordHash = urlParams.get('hash');
    const culture = urlParams.get('culture');
    if (userHash && passwordHash && culture) {
      return  new VerifyPasswordContainer(userHash, passwordHash, culture)
    } else {
      return null;
    }
  }

  verifyData() {
    const container = this._createVerifyContainer();
    this.applicationService.showLoader(true);
    this.authenticateService.verifyPasswordFromLink(container).subscribe((apiResponse: ApiResponse) => {
      this.applicationService.hideLoader();
      if (apiResponse && apiResponse.success) {
        this.username = apiResponse.result;
      } else {
        this.error = apiResponse.result;
        this.alertService.showErrorNotification(
          apiResponse && apiResponse.result
            ? apiResponse.result
            : this.translateService.instant("ERROR")
        );
      }
    })
  }

  resetPassword() {
    this.applicationService.showLoader(true);
    this.authenticateService
      .resetPassword(this.username)
      .subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        if (apiResponse && apiResponse.success) {
          this.message = this.translateService.instant("PASSWORD_LINK_SEND").replace("{0}",this.username);
          this.alertService.showInfoNotification(this.message);
          this.resetted = true;
        } else if (apiResponse) {
          this.alertService.showErrorNotification(apiResponse.result);
        } else {
          this.alertService.showErrorNotification(this.translateService.instant("ERROR"));
        }
      });
  }

  sendNewPassword(event) {
    if (this.passwordContainer.checkPasswordRequirements()) {
      this.applicationService.showLoader(true);
      const container = this._createVerifyContainer();
      container.password1 = this.passwordContainer.changePasswordContainer.new_password;
      container.password2 = this.passwordContainer.changePasswordContainer.confirm_password;
      this.authenticateService.changePasswordFromLink(container).subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        if (this.helperService.checkResponse(apiResponse)) {
          this.type = "verify_complete";
        } else {
          this.alertService.showErrorNotification(
            apiResponse && apiResponse.result
              ? apiResponse.result
              : this.translateService.instant("ERROR")
          );
        }
      })
    }
    event.preventDefault();
    return false;
  }
}
