export interface iDefaultFilters {
  COMMON: {
    CMN_AVAIL: boolean;
    CMN_IN_STOCK: boolean;
    CMN_NEW: boolean;
    CMN_PROM: boolean;
    CMN_OLD: boolean;
    CMN_BOM: boolean;
  };
}

export class DefaultFilters implements iDefaultFilters {
  "COMMON": {
    CMN_AVAIL: boolean;
    CMN_IN_STOCK: boolean;
    CMN_NEW: boolean;
    CMN_PROM: boolean;
    CMN_OLD: boolean;
    CMN_BOM: boolean;
  };
}
