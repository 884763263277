import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FinancialService } from "src/app/services/financial.service";
import { AccountService } from "src/app/services/account.service";
import { AccountInformation } from "src/app/interfaces/account-information";
import { ApiResponse } from "src/app/interfaces/api-response";
import { ShippingAddressGroup } from "src/app/interfaces/shipping-address-group";
import { OpenOrdersResultsCount } from "src/app/interfaces/financial/openorders/open-orders-results-count";
import { Subject } from "rxjs";
import { FinancialPaginatorData } from "src/app/interfaces/financial/financial-paginator-data";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-financial",
  templateUrl: "./financial.component.html",
  styleUrls: ["./financial.component.scss"]
})
export class FinancialComponent implements OnInit {
  @Input() type: string;
  @Output() typeChange: EventEmitter<string> = new EventEmitter<string>();
  navigationChange = new Subject<any>();
  navigationChangeEmitted$ = this.navigationChange.asObservable();
  pageData: FinancialPaginatorData;
  shippingAddressGroup: ShippingAddressGroup;
  public onlinePaymentRestrictionLevel: number;
  companyGroupCode: string;


  constructor(
    private financialService: FinancialService,
    private accountService: AccountService,
    private applicationService: ApplicationService
  ) {
    this.pageData = new FinancialPaginatorData();
  }

  ngOnInit() {
    this.getShippingAddresses();
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
  }

  getItems() {}

  typeChanged(data) {
    this.typeChange.next(data);
  }

  // Get shipping addresses
  getShippingAddresses() {
    this.accountService
      .getAccountInformation()
      .then((accountInformation: AccountInformation) => {
        if (accountInformation) {
          const group: ShippingAddressGroup = new ShippingAddressGroup();
          group.company_id = "";
          group.shipping_addresses = accountInformation.shipping_addresses;
          group.address_access = "all";
          this.shippingAddressGroup = group;
          this.onlinePaymentRestrictionLevel = accountInformation.online_payment_restriction_level[this.companyGroupCode] || 0;
        }
      });
  }

  navigationChanged(pageId) {
    this.navigationChange.next(pageId);
  }
}
