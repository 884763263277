<div class="container">
  <div class="row">
    <div class="col-md-12 px-3">
      <br />
      <h1>{{'TURNOVER_STATISTICS' | translate}}</h1>
      <div class="row" *ngIf="companies && companies.length; else noResult">
        <ng-container *ngFor="let company of companies">
          <!-- WS -->
          <div class="col-12 col-sm-6" *ngIf="company.companyID === 'WS'">
            <h2>Winora-Staiger</h2>
            <div class="d-block mb-2">
              <button class="btn btn-dark mb-3" (click)="downloadPdf('WS')">{{'DOWNLOAD_PDF' | translate}}</button>
            </div>
            <div class="d-flex w-100">
              <pdf-viewer *ngIf="company.fileURL" [src]="company.fileURL" class="border border-dark w-100"
                [original-size]="false"
                [autoresize]="true"
                [fit-to-page]="false"
                > </pdf-viewer>
            </div>
          </div>
          <!-- WBP -->
          <div class="col-12 col-sm-6" *ngIf="company.companyID === 'WBP'">
            <h2>E. Wiener Bike Parts </h2>
            <div class="d-block mb-2">
              <button class="btn btn-dark mb-3" (click)="downloadPdf('WBP')">{{'DOWNLOAD_PDF' | translate}}</button>
            </div>
            <div class="d-flex w-100">
              <pdf-viewer *ngIf="company.fileURL" [src]="company.fileURL" class="border border-dark w-100"
                [original-size]="false"
                [autoresize]="true"
                [fit-to-page]="false"
                > </pdf-viewer>
            </div>
          </div>
        </ng-container>
      </div>
      <ng-template #noResult>
          <ng-container *ngIf="">{{ 'NO_RESULTS' | translate }}</ng-container>
      </ng-template>
    </div>
  </div>
</div>
