import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickOrderPageComponent } from 'src/app/pages/quick-order-page/quick-order-page.component';
import { Routes, RouterModule } from '@angular/router';
import { IsDealerGuard } from 'src/app/guards/is-dealer.guard';
import { QuickOrderComponent } from 'src/app/components/quick-order-components/quick-order.component';
import { SharedModule } from '../shared/shared.module';

const routes: Routes = [
  {
    path: "",
    component: QuickOrderPageComponent,
    canActivate: [IsDealerGuard]
  }]

@NgModule({
  imports: [
    CommonModule,RouterModule.forChild(routes), SharedModule
  ],
  declarations: [QuickOrderPageComponent, QuickOrderComponent]
})
export class QuickorderModule { }
