import { Component, Input } from '@angular/core';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { DashboardData } from 'src/app/interfaces/dealerDashboard/dashboard-data';
import { ApplicationService } from 'src/app/services/application.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-credit-limit',
  templateUrl: './credit-limit.component.html',
  styleUrls: ['./credit-limit.component.scss'],
})
export class CreditLimitComponent {
  @Input() currency = 'EUR';
  @Input() creditLimitAmount: number = 0;
  @Input() creditLimitAvailable: number = 0;
  @Input() hasLimit: boolean;
  @Input() balanceDue: number = 0;

  constructor(
    private applicationService: ApplicationService,
    private helperService: HelperService,
    private dashboardService: DashboardService
  ) {
    const countryCode = localStorage.getItem('country');
    if (
      //countryCode !== 'IE' &&
      this.applicationService.getSelectCompanyGroupCode() === 'RAL'
    ) {
      this.currency = '£';
    }

    this.getCreditLimit();
  }

  getCreditLimit() {
    if (
      [ 'RAL'].includes(
        this.applicationService.getSelectCompanyGroupCode()
      )
    ) {
      if (!this.creditLimitAmount) {
        this.dashboardService
          .getDashboardData()
          .subscribe((apiResponse: ApiResponse) => {
            if (this.helperService.checkResponse(apiResponse)) {
              const dashboardData = apiResponse.result as DashboardData;
              this.hasLimit = dashboardData.has_credit_limit;
              this.balanceDue = dashboardData.balance_due;
              this.creditLimitAmount = dashboardData.credit_limit_amount;
              this.creditLimitAvailable =
                dashboardData.credit_limit_amount - dashboardData.balance_open;
            }
          });
      }
    }
  }
}
