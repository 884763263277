import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticateService } from "src/app/services/authenticate.service";
import { TranslateService } from "@ngx-translate/core";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { ApplicationService } from "src/app/services/application.service";
import { ChatService } from "src/app/services/chat.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { TokenContainer } from "src/app/interfaces/tokencontainer";
import { AccountService } from "src/app/services/account.service";
import { Credentials } from "src/app/interfaces/credentials";
import { AccountSettings } from "src/app/interfaces/account-settings";
import { forkJoin } from "rxjs";
import * as _ from "underscore";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertService } from "src/app/services/alert.service";
import { AccountInformation } from "src/app/interfaces/account-information";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { SelectLinkedDealerComponent } from "../select-linked-dealer/select-linked-dealer.component";
import { HelperService } from "src/app/services/helper.service";
import { CompanyGroup } from "src/app/interfaces/company-group";

@Component({
  selector: "app-login",
  styleUrls: ["./login.component.scss"],
  templateUrl: "login.component.html",
  animations: [
    trigger("openClose", [
      // ...
      state(
        "open",
        style({
          opacity: 1,
        })
      ),
      state(
        "closed",
        style({
          opacity: 0,
        })
      ),
      transition("open => closed", [animate("1s")]),
      transition("closed => open", [animate("1.2s")]),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  model: Credentials = new Credentials();
  loading = false;
  isOpen = false;
  isOpen2 = false;
  isOpen3 = false;
  isOpen4 = false;
  isOpen5 = false;
  togglePassword = true;
  tagLineComplete = "Your home for";
  tagLine = "";
  count = 0;
  textInterval: any;
  redirectUrl: string = "";
  bg: SafeUrl = "";
  showLoginForm = false;
  viewType = "login";
  isIE: boolean = false;
  companyGroups: CompanyGroup[] = [];

  get usernameValue() {
    return (document.getElementById("username") as HTMLInputElement).value;
  }

  get passwordValue() {
    const value = (document.getElementById("password") as HTMLInputElement)
      .value;
    if (value && value.length >= 3) {
      return value;
    } else {
      return "";
    }
  }

  constructor(
    private route: ActivatedRoute,
    private authenticateService: AuthenticateService,
    private router: Router,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private accountService: AccountService,
    private chatService: ChatService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.applicationService.setLanguage();
    sessionStorage.clear();

    this.applicationService.getAppVersion(false).then(() => {
      this.showLoginForm = true;

      // Check for prefilled in username after activation
      if (this.accountService.tempLoginUsername) {
        this.model.username = this.accountService.tempLoginUsername;
        this.accountService.tempLoginUsername = "";
      }

      // Reset unverified
      sessionStorage.removeItem("unverified");
      this.applicationService.deleteStoredItem("accountCode");
      this.applicationService.deleteStoredItem("ab-test");

      if (this.route.snapshot.data["type"] === "resetPassword") {
        this.viewType = "resetPassword";
      }

      this.bg = this.formatUrl(this.getBg());

      const userAgent = window.navigator.userAgent;
      if (userAgent) {
        this.isIE =
          userAgent.toLowerCase().indexOf("trident") >= 0 ||
          userAgent.toLowerCase().indexOf("msie") >= 0;
      }

      setInterval(() => {
        const img = new Image();
        const bg = this.getBg();
        img.src = String(bg);
        setTimeout(() => {
          this.bg = this.formatUrl(bg);
        }, 1000);
      }, 5000);

      // reset login status
      this.authenticateService.deleteTokens();
      this.redirectUrl =
        this.route.snapshot.queryParamMap.get("redirectUrl") &&
        this.route.snapshot.queryParamMap.get("redirectUrl").indexOf("Logout") <
          0
          ? `${this.route.snapshot.queryParamMap.get("redirectUrl")}${
              window.location.hash
            }`
          : "";

      if (this.route.snapshot.queryParamMap.get("serviceportal")) {
        this.redirectUrl += "&serviceportal=true";
      }
      // Remove Modals
      this.removeModals();

      setTimeout(() => {
        this.isOpen5 = true;
        this.textInterval = setInterval(() => {
          this.tagLine = this.tagLineComplete.substr(0, this.count);
          this.count++;
          if (this.count > this.tagLineComplete.length) {
            clearInterval(this.textInterval);
            this.showTagLines();
          }
        }, 50);
      }, 200);

      const autologin =
        window.location.search.includes("preview") &&
        this.helperService.inIframe() &&
        !environment.production;

      if (autologin) {
        this.showLoginForm = false;
        this.login(true);
      }

      this.applicationService.emitRequestError$.subscribe(() => {
        this.loading = false;
      });
    });
  }

  removeModals() {
    this.modalService.dismissAll();

    const modalContainer = document.getElementById("modalContainer");
    if (modalContainer) {
      modalContainer.innerHTML = "";
    }
  }

  // Gets the return url from route parameters or default to '/' to return to when the login is complete
  getReturnUrl(companyGroup: string): string {
    let url = "";
    if (this.applicationService.isPartnerPortal()) {
      url = `${companyGroup}/companyPage/partnerPortal`;
    } else {
      const returningVisitor = localStorage.getItem("returning");
      url =
        this.route.snapshot.queryParams["returnUrl"] ||
        (!returningVisitor &&
          !this.accountService.isHelpdesk() &&
          this.companyGroups.length > 1)
          ? "start"
          : `${companyGroup}/home`;
      localStorage.setItem("returning", "1");
    }

    return url;
  }

  showTagLines() {
    this.isOpen = true;

    setTimeout(() => {
      this.isOpen2 = true;
    }, 500);
    setTimeout(() => {
      this.isOpen3 = true;
    }, 1000);
    setTimeout(() => {
      this.isOpen4 = true;
    }, 1500);
  }

  login(autologin: boolean) {
    // Get username and password
    const username = !autologin
      ? this.usernameValue
      : environment.previewUser.username;
    const password = !autologin
      ? this.passwordValue
      : environment.previewUser.password;

    if (username && password) {
      this.loading = true;

      this.authenticateService.login(username, password).subscribe({
        next: (data: ApiResponse) => {
          if (data.success) {
            // Login is succesful
            const tokens: TokenContainer = data.result;
            if (
              !tokens.linked &&
              typeof tokens.okta_registration === "undefined"
            ) {
              this.handleLogin(tokens);
            } else if (tokens.session_token && tokens.okta_registration) {
              const url = tokens.session_token.replace(/^.*\/\/[^\/]+/, "");
              this.router.navigateByUrl(url);
            } else {
              // Save tokens
              this.authenticateService.saveTokens(tokens);

              this.accountService
                .getLinkedDealers()
                .subscribe((apiResponse: ApiResponse) => {
                  if (this.helperService.checkResponse(apiResponse)) {
                    const modalRef = this.modalService.open(
                      SelectLinkedDealerComponent,
                      {
                        centered: true,
                        container: "#modalContainer",
                      }
                    );
                    modalRef.componentInstance.dealers = apiResponse.result;
                    modalRef.componentInstance.dealerSelected.subscribe(
                      (tokenContainer: TokenContainer) => {
                        this.handleLogin(tokenContainer);
                        modalRef.close();
                      }
                    );
                    return false;
                  }
                });
            }
          } else {
            this.alertService.showErrorNotification(
              data.result
                ? data.result
                : this.translateService.instant("INCORRECT_CREDENTIALS")
            );
            this.loading = false;
          }
        },
        error: (err) => (this.loading = false),
      });
    }
  }

  handleLogin(tokens: TokenContainer) {
    // Let application now user is logged in
    this.authenticateService.validLogin();

    // Save tokens
    this.authenticateService.saveTokens(tokens);

    // Get user information
    this.accountService.getAccountSettings().subscribe((response: any) => {
      if (response && response.result) {
        const accountSettings: AccountSettings = response.result;
        
        // Save default cgc for helpdesk
        if (this.accountService.isHelpdesk()) {
          localStorage.setItem("defaultCompanyGroup", accountSettings.company_group);
        }

        this.accountService.saveAccountSettings(accountSettings);
        this.loading = true;

        if (
          (accountSettings.okta && accountSettings.registered) ||
          (!accountSettings.okta && accountSettings.verified)
        ) {
          forkJoin(
            this.accountService.getAvailableCompanyGroups()
          ).subscribe((apiResponse: any[]) => {
            if (apiResponse[0].success) {
              this.companyGroups = apiResponse[0].result;
              this.applicationService.saveCompanyGroups(apiResponse[0].result);
              sessionStorage.setItem("newSettings", "1");

              if (typeof accountSettings.company_group === "undefined") {
                const companyGroups =
                  this.applicationService.getAvailableCompanyGroups();
                accountSettings.company_group = companyGroups[0];
              }

              // AB test check
              // if (this.accountService.isInABTestGroup()) {
              //   localStorage.setItem("ab-test",  "ab-test-b");
              // }

              // Redirect
              if (!this.isIE) {
                const returnUrl = this.redirectUrl
                  ? decodeURIComponent(this.redirectUrl)
                  : decodeURIComponent(
                      this.getReturnUrl(accountSettings.company_group)
                    );

                // Redirect to Okta
                if (tokens.session_token) {
                  // Check if redirect to Salesforce portal needed after signin
                  const hasSalesForceRedirect =
                    this.helperService.hasSalesForceRedirect(this.redirectUrl);

                  let oktaReturnUrl = "";
                  if (this.redirectUrl) {
                    // Internal redirect within Accentry
                    if (this.redirectUrl.includes(window.location.origin)) {
                      oktaReturnUrl = this.redirectUrl;
                    } else if (
                      !this.redirectUrl.includes("https://") &&
                      !hasSalesForceRedirect
                    ) {
                      oktaReturnUrl = encodeURIComponent(
                        `${window.location.origin}/${this.redirectUrl}`
                      );
                    } else {
                      //External redirect
                      if (hasSalesForceRedirect) {
                        this.accountService
                          .portalSignin()
                          .subscribe((apiResponse: ApiResponse) => {
                            this.loading = false;
                            if (this.helperService.checkResponse(apiResponse)) {
                              oktaReturnUrl = encodeURIComponent(
                                apiResponse.result
                              );

                              // Redirect to Salesforce portal
                              this.authenticateService.redirectToOkta(
                                tokens,
                                oktaReturnUrl
                              );
                            } else {
                              this.alertService.showPopup(
                                apiResponse
                                  ? apiResponse.result
                                  : this.translateService.instant("ERROR"),
                                "error",
                                0
                              );
                            }
                          });
                      } else {
                        oktaReturnUrl = encodeURIComponent(
                          this.helperService.externalRedirect(this.redirectUrl)
                        );
                      }
                    }
                  } else {
                    oktaReturnUrl = encodeURIComponent(
                      `${window.location.origin}/${returnUrl}`
                    );
                  }

                  // Redirect to Okta
                  if (!hasSalesForceRedirect) {
                    this.authenticateService.redirectToOkta(
                      tokens,
                      oktaReturnUrl
                    );
                  }
                } else {
                  this.router.navigateByUrl(returnUrl);
                }
              } else {
                localStorage.setItem("isIE", "1");
                alert('Your browser is outdated. Please use a modern browser like Google Chrome or Firefox!');
              }
            } else {
              this.alertService.showErrorNotification(
                this.translateService.instant("ERROR_500")
              );
              this.loading = false;
            }
          });
        } else {
          sessionStorage.setItem("unverified", "1");
          if (accountSettings.okta && !accountSettings.registered) {
            this.router.navigateByUrl(`/register`);
          }
          if (!accountSettings.okta && !accountSettings.verified) {
            this.router.navigateByUrl(`/verify`);
          }
        }
        // Set up chat window
        this.chatService.initChat();
      }
    });
  }

  getBg() {
    const base = "../../../../assets/images/";
    const bgs = [
      base + "loginBG.jpg",
      base + "loginBG-2.jpg",
      base + "loginBG-3.jpg",
    ];
    return String(_.sample(_.without(bgs, String(this.bg))));
  }

  formatUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustStyle(`url('${url}')`);
  }
}
