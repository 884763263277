import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FinancialService } from "src/app/services/financial.service";
import { PackingSlipsFilters } from "src/app/interfaces/financial/packingslips/packingslips-filters";
import { PackingSlipsOptionInput } from "src/app/interfaces/financial/packingslips/packingslips-option-input";
import * as moment from "moment";
import { PackingSlip } from "src/app/interfaces/financial/packingslips/packing-slip";
import { ApiResponse } from "src/app/interfaces/api-response";
import { PackingSlipArticleListComponent } from "../packing-slip-article-list/packing-slip-article-list.component";
import { Subject } from "rxjs";
import { ApplicationService } from "src/app/services/application.service";
import { Article } from "src/app/interfaces/article";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-packing-slip-finder",
  templateUrl: "./packing-slip-finder.component.html",
  styleUrls: ["./packing-slip-finder.component.scss"]
})
export class PackingSlipFinderComponent implements OnInit {
  packingSlips: PackingSlip[];
  packingSlipArticleSelected = new Subject<any>();
  articleCode: string;
  selectedArticleCode: string;
  hasNextPage: boolean;
  constructor(
    public modal: NgbActiveModal,
    private financialService: FinancialService,
    private modalService: NgbModal,
    private applicationService: ApplicationService,
  ) {}

  ngOnInit() {
    this.getPackingSlips(false);
  }

  startSearch(event) {
    if (event.key === "Enter") {
      const el = document.getElementById("packingSlipSearch") as HTMLInputElement;
      this.articleCode = el.value;
      this.searchByArticleCode();
    }
  }

  searchByArticleCode() {
    this.hasNextPage = false;
    this.selectedArticleCode = this.articleCode;
    this.getPackingSlips(false);
  }

  getPackingSlips(useArticleCode: boolean) {
    this.applicationService.showLoader(true);
    const packingSlipFilters: PackingSlipsFilters = new PackingSlipsFilters();
    packingSlipFilters.next_page_reference = "";
    packingSlipFilters.options = new PackingSlipsOptionInput();
    packingSlipFilters.options.date_from = moment()
      .add(-30, "days")
      .format("YYYYMMDD");
    packingSlipFilters.options.date_to = moment().format("YYYYMMDD");
    packingSlipFilters.options.accentry_id = "";
    packingSlipFilters.options.article_type = "all";
    packingSlipFilters.options.only_new_order_lines = false;
    packingSlipFilters.options.order_by = "deliveryweek";

    if (this.articleCode) {
      if (useArticleCode) {
        packingSlipFilters.options.article_code = String(this.articleCode).trim();
      } else {
        packingSlipFilters.options.packing_slip_number = String(this.articleCode).trim();
      }
    }

    packingSlipFilters.next_page_reference = this.hasNextPage
      ? this.packingSlips[this.packingSlips.length - 1].packing_slip_number
      : null;
    this.financialService
      .getPackingSlips(packingSlipFilters)
      .subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        if (apiResponse.success && apiResponse.result && apiResponse.result.length && apiResponse.result[0] !== null && apiResponse.result[0].items && apiResponse.result[0].items.length) {
          this.hasNextPage = apiResponse.result[0].ace_next_page === "X";

          // First page else merge with current results
          if (!packingSlipFilters.next_page_reference) {
            this.packingSlips = apiResponse.result[0].items;
          } else {
            this.packingSlips = this.packingSlips.concat(
              apiResponse.result[0].items
            );
          }
        } else if (!useArticleCode) { // When no results with packinslipnumber check for articlecode
          this.getPackingSlips(true);
        } else {
          this.packingSlips = [];
        }
      });
  }

  select(packingSlipNumber: string, description: string) {

      const modalRef = this.modalService.open(PackingSlipArticleListComponent, {
        centered: true,
        container: "#modalContainer",
        backdrop: "static",
        size:"lg"
      });
      modalRef.componentInstance.packingSlipNumber = packingSlipNumber;
      modalRef.componentInstance.getPackSlipArticles();
      modalRef.componentInstance.articleCode = this.articleCode;
      modalRef.componentInstance.packingSlipArticleSelected.subscribe(
        (packingSlip: any) => {
          this.selectArticle(packingSlip["articleCode"], packingSlipNumber, packingSlip["article"], description);
        }
      );
    }

  selectArticle(articleCode: string, packingSlipNumber: string, article: Article, description: string) {
    this.packingSlipArticleSelected.next({
      packingSlipNumber: packingSlipNumber,
      article: article,
      articleCode: articleCode,
      description: description
    });
  }
}
