import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StoryblokComponents } from 'src/app/components/storyblok/storyblok-components';
import { SlideshowLanguageItem } from 'src/app/interfaces/cms/slideshow-language-item';
import { StoryblokService } from 'src/app/services/storyblok.service';

@Component({
  selector: "app-slide-content",
  templateUrl: "./slide-content.component.html",
  styleUrls: ["./slide-content.component.scss"],
})
export class SlideContentComponent implements OnInit {
  iframe_html: any;
  @Input() slide: SlideshowLanguageItem;
  components = StoryblokComponents;
  isVideo: boolean;
  video_url: any;
  html: boolean;
  text: any;
  constructor(
    private sanitizer: DomSanitizer,
    private elem: ElementRef,
    private storyblokService: StoryblokService
  ) {}

  ngOnInit() {
    this.setContent();
  }

  setContent() {
    this.html = false;
    try {
      const text = JSON.parse(this.slide.content);
      if (typeof text.content !== "undefined") {
        this.text = text;
      } else {
        // render plain HTML (old CMS layout)
        this._setHtmlContent(text);
      }
    } catch (err) {
      this._setHtmlContent(this.slide.content);
    }
  }

  _setHtmlContent(content: string) {
    this.html = true;
    const elements = this.elem.nativeElement.getElementsByClassName(`content`);
    if (elements && elements.length) {
      elements[0].innerHTML = this.storyblokService.formatContent(content);
    }
  }
}
