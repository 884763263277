<app-partner-portal-breadcrumbs [(breadcrumbs)]="breadcrumbs"></app-partner-portal-breadcrumbs>
<app-partner-user-info-address #infoform>
  <div class="col-12 px-0 py-3 d-flex justify-content-around">
    <button class="me-auto btn btn-lg btn-dark"
      (click)="back(infoform)">
      {{ "BACK" | translate }}
    </button>
    <button class="btn btn-lg btn-success" (click)="infoform.toCheckout()">
      {{ "CHECKOUT" | translate }}
    </button>
  </div>
</app-partner-user-info-address>
