import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ShippingAddress } from "src/app/interfaces/shipping-address";
import * as _ from "underscore";
import { ShippingAddressGroup } from "src/app/interfaces/shipping-address-group";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-select-shipping-address",
  templateUrl: "./select-shipping-address.component.html",
  styleUrls: ["./select-shipping-address.component.scss"],
})
export class SelectShippingAddressComponent implements OnInit {
  @Output() shippingAddressSelected: EventEmitter<number> =
    new EventEmitter<number>();
  @Input() shippingAddresses: ShippingAddressGroup;
  @Input() selectedShippingAddress: number;
  @Input() showAllCompanies: boolean;
  @Input() showBlank: boolean;
  @Input() selectBlank: boolean;
  @Input() showSelected: boolean;
  @Input() type = "select";

  get selectedAddress() {
    const selected = this.shippingAddresses.shipping_addresses.find((s: ShippingAddress) => {
      return s.id === this.shippingAddresses.selected
    })
    return selected;
  }

  constructor(public applicationService: ApplicationService) {}

  ngOnInit() {
    if (this.shippingAddresses && !this.showSelected) {
      delete this.shippingAddresses.selected;
      const defaultAddress: ShippingAddress = _.find(
        this.shippingAddresses.shipping_addresses,
        {
          default_address: true,
        }
      );
      if (defaultAddress && !this.selectedShippingAddress && !this.showBlank) {
        this.selectedShippingAddress = defaultAddress.id;
      }
    }
    if (
      this.shippingAddresses &&
      !this.shippingAddresses.selected &&
      !this.showBlank
    ) {
      this.shippingAddresses.selected = this.selectedShippingAddress;
    }
  }

  selectShippingAddress(shippingAddress) {
    this.selectBlank = false;
    this.showBlank = false;
    this.shippingAddressSelected.emit(this.shippingAddresses.selected);
  }
}
