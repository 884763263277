<app-balance-filters
  [shippingAddressGroup]="shippingAddressGroup"
  (searchSubmitted)="getFilters($event)"
>
</app-balance-filters>
<div class="row" *ngIf="items && items.length">
  <div class="col-md-12">
    <!-- Number of results -->
    <div class="numResults">
      <app-invoice-balance-overview *ngIf="filters.options.company_id_filter || ( (applicationService.checkIfDealerHasCompany(101) && !applicationService.checkIfDealerHasCompany(102)) || (!applicationService.checkIfDealerHasCompany(101) && applicationService.checkIfDealerHasCompany(102)))"
        [numResults]="balanceData"
      ></app-invoice-balance-overview>
    </div>
  </div>
</div>
<div class="mb-4 mt-1 tableContainer customScrollbar">
  <span *ngIf="items && items.length">{{ 'SHOWS_INVOICES_FROM' | translate}}: <strong>{{formattedDateFrom}}</strong> {{ 'TILL' | translate }} <strong>{{formattedDateTo}}</strong></span>
  <table class="table">
    <thead>
      <tr class="financial-item" *ngIf="items && items.length">
        <td style="max-width: 30px;"></td>
        <td>{{ "INVOICE_NUMBER" | translate }}</td>
        <td>{{ "INVOICE_DATE" | translate }}</td>
        <td>{{ "DUE_DATE" | translate }}</td>
        <td>{{ "INVOICE_AMOUNT" | translate }}</td>
        <td>{{ "AMOUNT_DUE" | translate }}</td>
        <td>{{ "DISCOUNT_DUE_DATE" | translate }}</td>
        <td>{{ "INVOICE_PDF" | translate }}</td>
        <td>{{ "ORDERNUMBER" | translate }} Accentry</td>
      </tr>
      <tr *ngIf="items && !items.length">
        <td class="border-0">
          <app-no-results></app-no-results>
        </td>
      </tr>
    </thead>
    <tbody>
      <app-balance-line
        class="w-100 d-block d-table-row small financial-item"
        *ngFor="let invoice of items; let rowIndex = index"
        [invoice]="invoice"
        [attr.data-index]="rowIndex + 1"
        [ngbTooltip]="invoice | shippingAddressText"
        (invoiceSelectionChange)="getSelection()"
      >
      </app-balance-line>
    </tbody>

    <ngx-skeleton-loader *ngIf="!loaded" count="20" appearance="line" [theme]="{height: '80px' }">
    </ngx-skeleton-loader>
  </table>
  <button class="btn btn-dark btn-block" (click)="showMore()" *ngIf="page < totalPages && !keyWord">{{"SHOW_MORE"|translate}}</button>
</div>
