import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-select-article-type",
  templateUrl: "./select-article-type.component.html",
  styleUrls: ["./select-article-type.component.scss"]
})
export class SelectArticleTypeComponent implements OnInit {
  @Output() visibleArticleType: EventEmitter<string> = new EventEmitter<
    string
  >();
  selected = "";
  constructor() {}

  ngOnInit() {}

  setVisibleType(selected) {
    this.selected = selected;
    this.visibleArticleType.emit(selected);
  }
}
