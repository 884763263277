import { Component, OnInit, Input } from "@angular/core";
import { Observable } from "rxjs";
import { WarrantyService } from "src/app/services/warranty.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { FormGroup, Validators } from "@angular/forms";
import { HttpHeaders } from "@angular/common/http";
import { ApplicationService } from "src/app/services/application.service";
import { HelperService } from "src/app/services/helper.service";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from 'file-saver';
@Component({
  selector: "app-warranty-file-upload",
  templateUrl: "./warranty-file-upload.component.html",
  styleUrls: ["./warranty-file-upload.component.scss"],
})
export class WarrantyFileUploadComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() warrantyId: number;
  constructor(
    private warrantyService: WarrantyService,
    private applicationService: ApplicationService,
    private helperService: HelperService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this._enableFileupload();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this._disableFileupload();
  }

  _enableFileupload() {
    this.formGroup.get("fileUrl").enable();
    this.formGroup.setValidators([Validators.required]);
    this.formGroup.updateValueAndValidity();
  }

  _disableFileupload() {
    this.formGroup.get("fileUrl").disable();
    this.formGroup.clearValidators();
    this.formGroup.updateValueAndValidity();
  }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    this.applicationService.showLoader(true);
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const formData: FormData = new FormData();
      const reader = new FileReader();
      const filesize = Number((file.size / 1024 / 1024).toFixed(4)); // MB
      if (filesize <= 10) {
        reader.readAsDataURL(file);
        reader.onload = () => {

          let warrantyId = this.warrantyId;

          if (!warrantyId) {
            const regex = /\/warranty\/(\d+)/;
            const match = window.location.href.match(regex);

            if (match && match[1]) {
              warrantyId = Number(match[1]);
            }
          }

          this.warrantyService
            .uploadFile(warrantyId, String(reader.result))
            .subscribe((apiResponse: ApiResponse) => {
              if (this.helperService.checkResponse(apiResponse)) {
                this.formGroup.get("fileUrl").setValue(apiResponse.result);
                this.formGroup.get("fileUrl").enable();
                this.formGroup.get("guaranteeDateValid").setValue("1");
              } else {
                this.alertService.showPopup(
                  apiResponse?.result ?? this.translateService.instant("ERROR"),
                  "error",
                  0
                );
                this.formGroup.get("fileUrl").setValue("");
              }
              this.applicationService.hideLoader();
            });
        };
      } else {
        this.applicationService.hideLoader();
        this.alertService.showErrorNotification(
          `${this.translateService.instant("FILE_SIZE_LIMIT")}: ${file["name"]}`
        );
      }
    } else {
      this.applicationService.hideLoader();
    }
  }

  downloadFile() {
    this.warrantyService
      .downloadFile(this.warrantyId, this.formGroup.get("fileUrl").value)
      .subscribe((blob: Blob) => {
        saveAs(blob, this.formGroup.get("fileUrl").value);
      });
  }
}
