import { Article } from "./article";
import { BasketArticles } from "./basket-articles";

export interface GroupedBasketArticles {
  parts: BasketArticles;
  bicycles: BasketArticles;
}

export class GroupedBasketArticles implements GroupedBasketArticles {
  constructor() {
    this.bicycles = new BasketArticles();
    this.parts = new BasketArticles();
  }
}
