export interface NewsFilters {
  company_ids: number[];
  group_by_company: boolean;
  info_item_type: number;
  sort_by_option: number;
  sort_by_direction: number;
  page_size: number;
  page: number;
  search_text: string;
  category: string;
  type: string;
  ignore_alerts_shown: boolean;
  show_accentry_items: boolean;
}

export class NewsFilters implements NewsFilters {
}
