<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'RESET_PASSWORD' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
    </button>
</div>

<div class="modal-body p-3">
  <div class="col-md-12 px-0">
    <app-change-password-container #passwordContainer [modal]="modal"></app-change-password-container>
  </div>
</div>

<div class="modal-footer">
  <span class="col-md-12 p-0">
    <button class="btn btn-success btn-lg me-auto" (click)="passwordContainer.submit()">{{'SAVE'|translate}}</button>
  </span>
</div>
