import { AbstractControl, ValidatorFn } from '@angular/forms';

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
    if (!dateRegex.test(control.value)) {
      return { invalidDate: { value: control.value } };
    }

    const [day, month, year] = control.value.split('-').map(Number);

    // Check the validity of the date
    const isValidDate = (d: number, m: number, y: number) => {
      const date = new Date(y, m - 1, d);
      return date.getFullYear() === y && date.getMonth() === m - 1 && date.getDate() === d;
    };

    if (!isValidDate(day, month, year)) {
      return { invalidDate: { value: control.value } };
    }

    return null;
  };
}
