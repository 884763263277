export class BatteryDetails {
    battery1: Battery[] = [];
    battery2: Battery[] = [];
    frame_number: string = '';
    id: number = 0;
    model: string = '';

    constructor() {
        // Initialize properties if needed
    }
}

export interface Battery {
    battery_number: string;
    sys_add: string; 
}

export interface BatteryUpdate {
    battery1: string;
    battery2: string;
    frame_number: string;
}