import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ApiResponse } from "../interfaces/api-response";
import { User } from "../interfaces/user";
import { AuthenticateService } from "./authenticate.service";
import { Subject } from "rxjs";
import { ApplicationService } from "./application.service";
import { TokenContainer } from "../interfaces/tokencontainer";
import { MergeInfo } from "../interfaces/merge-info";
import { SearchService } from "./search.service";
import { HelperService } from "./helper.service";
import { ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class HelpdeskService {
  private emitChangeSource = new Subject<User>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  constructor(
    private http: HttpClient,
    private applicationService: ApplicationService,
    private searchService: SearchService,
    private authenticationService: AuthenticateService,
    private helperService: HelperService,
    private route: ActivatedRoute,
  ) { }

  emitChange(user: User) {
    this.emitChangeSource.next(user);
  }

  saveLastSearch(keyWord: string) {
    localStorage.setItem("lastDealerSearch", keyWord);
  }
  getLastSearch(value?: string) {
    return value ? value : localStorage.getItem("lastDealerSearch")
      ? localStorage.getItem("lastDealerSearch")
      : ""
  }

  searchDealers(keyWord: string) {
    keyWord = encodeURIComponent(keyWord);
    return this.http
      .get(
        environment.apiendpoint +
          `search/dealers?text=${encodeURI(keyWord)}&t=hlp&v=1.0`
      )
      .pipe(map((response: ApiResponse) => response.result));
  }

  getUsers(dealerCode: string) {
    return this.http.get(
      environment.apiendpoint + `accounts/${encodeURI(dealerCode)}/users?t=hlp`
    );
  }

  getSelectedDealer() {
    return localStorage.getItem("selectedDealer")
      ? JSON.parse(localStorage.getItem("selectedDealer"))
      : null;
  }

  // Log in as selected user
  logInAsUser(user: User) {
    localStorage.removeItem("selectedDealer")
    const promise = new Promise((approve, reject) => {
      const data = {
        selected_dealer: user.account_code,
        user_id: user.user_id,
        username: user.username
      };
      this.http
        .post(environment.apiendpoint + `loginasuser?t=hlp`, data)
        .subscribe((apiResponse: ApiResponse) => {
          if (this.helperService.checkResponse(apiResponse)) {
            const tokenContainer: TokenContainer = apiResponse.result;
            this.authenticationService.saveTokens(tokenContainer);
            this.searchService.clearPromotions();
            if (tokenContainer.account_type === "HLP" || tokenContainer.account_type === "INT") {
              this.applicationService.saveSetting(
                "selectedDealer",
                JSON.stringify(user)
              );
            }
            localStorage.removeItem("companyGroups");

            approve(apiResponse);
          } else {
            reject();
          }
        });
    });

    return promise;
  }

  getMergeInfo(userId) {
    return this.http.get(environment.apiendpoint + `dealers/${userId}/mergeinfo`);
  }

  getUnmergeInfo(userId) {
    return this.http.get(environment.apiendpoint + `dealers/${userId}/unmergeinfo`)
  }

  mergeDealers(fromDealerInfo: MergeInfo, toDealerInfo: MergeInfo) {
    return this.http.post(`${environment.apiendpoint}dealers/merge`, {
      "from":  {
        dealer_code: fromDealerInfo.dealerCode,
        success: fromDealerInfo.success,
        winora: fromDealerInfo.winora,
        ace: fromDealerInfo.ace
      },
      "to":  {
        dealer_code: toDealerInfo.dealerCode,
        success: toDealerInfo.success,
        winora: toDealerInfo.winora,
        ace: toDealerInfo.ace
      }
    })
  }

  unMergeDealer(dealerID: string, success: boolean) {
    return this.http.post(`${environment.apiendpoint}dealers/unmerge`, {
      "account": {
        dealer_code: dealerID,
        success: success
      }
    })
  }
}
