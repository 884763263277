<div>
  <app-article-image class="d-inline-block mt-2 w-100 text-center thumbnail" [articleType]="article.article_type"
    [imageUrl]="article.images && article.images.length ? article.images[0] : ''" [useLazyLoad]="false"
    [description]="article.description" [productGroup]="article.product_group_codes"
    (imageClick)="gotoItem($event, article)" [classList]="''"></app-article-image>
  <div class="card-body">
    <div *ngIf="hasImage">
      <h5 class="card-title" (click)="gotoItem($event, article)"><span [innerHTML]="article.description"></span>
        <small class="d-block" (click)="gotoItem($event, article)"
          [innerHTML]="(article.caption?article.caption:'') + ' ' + (article.single_article_code?article.single_article_code:'')"></small>
        <small class="small d-block" *ngIf="article.season && !['RAL','ANL'].includes(applicationService.getSelectCompanyGroupCode())">{{article.season}}</small>
      </h5>
      <button class="btn btn-primary d-flex align-items-center" pRipple
        (click)="downloadZip(article.id)">{{ 'DOWNLOAD_IMAGES' | translate }} <i class="material-icons">image</i>
        {{article.thumbs.length}} </button>
    </div>
  </div>
</div>
