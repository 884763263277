import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ArticleDetailsComponent } from 'src/app/components/article-components/article-details/article-details.component';
import { AlertService } from 'src/app/services/alert.service';
import { Article } from 'src/app/interfaces/article';
import { ApplicationService } from 'src/app/services/application.service';
@Component({
  selector: "app-article-modal",
  templateUrl: "./article-modal.component.html",
  styleUrls: ["./article-modal.component.scss"],
})
export class ArticleModalComponent implements OnInit {
  article: Article;
  modelId: string;
  selectedArticle: string = null;
  showDetailed: boolean;
  preOrder: boolean;
  showInModal: boolean;
  showQrCode: boolean;
  currentRate = 0;

  @ViewChild("details", { static: true, read: ArticleDetailsComponent })
  details: ArticleDetailsComponent;

  constructor(
    public modal: NgbActiveModal,
    private alertService: AlertService,
    private applicationService: ApplicationService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.showInstant();
  }

  content() {}

  save() {
    this.modal.dismiss();
    this.alertService.showDangerNotification("Article added");
  }

  showInstant() {
    this.details.modelId = this.modelId;
    this.details.selectedArticle = this.selectedArticle;
    this.details.article = this.article;
    this.details.showBreadCrumbs = false;
    this.details.preOrder = this.preOrder;
    this.details.showInModal = true;
    this.details.showQrCode = this.showQrCode;
    this.details.showInstant(true);
    this.applicationService.emitModalChange(true);
  }
}
