import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-showroom-blocker',
  templateUrl: './showroom-blocker.component.html',
  styleUrls: ['./showroom-blocker.component.scss']
})
export class ShowroomBlockerComponent {
  pageChangeEvent: Subscription;

  constructor(private applicationService: ApplicationService, private modalService: NgbModal, private router: Router) {

  }

  ngOnInit() {
    this.toggleOverlay(true);
    this.pageChangeEvent = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.viewPage();
      }
    })
  }

  ngOnDestroy() {
    if (typeof this.pageChangeEvent !== "undefined") {
      this.pageChangeEvent.unsubscribe();
    }
  }

  toggleOverlay = (show: boolean) => {
    const wrapper = document.getElementById("wrapper");
    if (show) {
      wrapper.classList.add("block-content");
    } else {
      wrapper.classList.remove("block-content");
    }
  }

  viewPage() {
    this.toggleOverlay(false);
    this.applicationService.checkShowroomMode(false);
    this.modalService.dismissAll();
  }

  toHomepage() {
    this.toggleOverlay(true);
    this.viewPage();
    this.router.navigateByUrl("/");
  }
}
