
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'SHIPMENTLABELS'|translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
    </button>
</div>

<div class="modal-body p-3">
  <div class="d-flex">
    <div *ngIf="groupedArticles.regular && groupedArticles.regular.length" class="col">
      <h3>{{'REGULAR_ARTICLES' | translate}}</h3>
      <ngb-alert type="warning" [dismissible]="false">
        <app-cms-page-content [pageId]="'960421911'" [contentId]="'regular'"></app-cms-page-content>
      </ngb-alert>
      <app-warranty-label-card class="mb-1" [articleCount]="groupedArticles.regular.length" [logo]="regularLogo">
          <button class="btn btn-primary" (click)="showLabels('regular')">{{'PRINT'|translate}}</button>
      </app-warranty-label-card>
    </div>
    <div *ngIf="groupedArticles.batteries && groupedArticles.batteries.length" class="col">
      <h3>{{'BATTERIES_AND_CHARGERS' | translate}}</h3>
      <ngb-alert type="warning" [dismissible]="false">
        <app-cms-page-content [pageId]="'960421912'" [contentId]="'battery'"></app-cms-page-content>
      </ngb-alert>
      <app-warranty-label-card class="mb-1" *ngFor="let article of groupedArticles.batteries" [articleCount]="1" [logo]="'mikropakket.jpg'">
        <span article-details>: {{article.frame_number}} {{article.article_description}}</span>
        <button class="btn btn-primary" (click)="showArticleLabel(article.article_type, article)">{{'PRINT'|translate}}</button>
    </app-warranty-label-card>
    </div>
    <div *ngIf="groupedArticles.motors && groupedArticles.motors.length" class="col">
        <h3>{{'MOTORS' | translate}}</h3>
        <ngb-alert type="warning" [dismissible]="false">
          <app-cms-page-content [pageId]="'960421912'" [contentId]="'motors'"></app-cms-page-content>
        </ngb-alert>
        <app-warranty-label-card class="mb-1" *ngFor="let article of groupedArticles.motors" [articleCount]="1" [logo]="'gls.png'">
          <span article-details>: {{article.frame_number}} {{article.article_description}}</span>
          <button class="btn btn-primary" (click)="showArticleLabel(article.article_type, article)">{{'PRINT'|translate}}</button>
      </app-warranty-label-card>
      </div>
  </div>

</div>
<div class="modal-footer">
  <span class="col-md-12 p-0">
      <button class="btn btn-dark " (click)="modal.dismiss('cancel click')">{{'CLOSE' | translate}}</button>
  </span>
</div>
