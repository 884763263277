import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  EventEmitter
} from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ArticleModalComponent } from "src/app/components/modal-components/article-modal/article-modal.component";
import { Location } from "@angular/common";
import { SearchService } from "src/app/services/search.service";
import { ActivatedRoute } from "@angular/router";
import { ApplicationService } from "src/app/services/application.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-add-all-article",
  templateUrl: "./add-all-article.component.html",
  styleUrls: ["./add-all-article.component.scss"]
})
export class AddAllArticleComponent implements OnInit {
  @Input() article: Article;
  @Input() selected: boolean;
  @Output() showArticleDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() articleSelected: EventEmitter<any> = new EventEmitter<any>();
  matrixOptions: any = [];
  selectedCompanyGroup = "";
  thumbnail = "";
  timer: any;
  placeholder = "assets/images/Accentry-logo-black.svg";

  constructor(
    private modalService: NgbModal,
    private location: Location,
    private searchService: SearchService,
    private activatedRoute: ActivatedRoute,
    private applicationService: ApplicationService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.selectedCompanyGroup = this.applicationService.getSelectCompanyGroupCode();
    this.article.selected = this.selected;
    this.thumbnail = this.article.thumbs ? this.article.thumbs[0] : "";
    this.article.quantity = this.article.min_order_amount
      ? this.article.min_order_amount
      : 1;
  }

  openModal(article) {
    const modalRef = this.modalService.open(ArticleModalComponent, {
      size: "lg",
      container: "#modalContainer"
    });
    modalRef.componentInstance.article = article;
    modalRef.componentInstance.modelId = article["id"];
    modalRef.componentInstance.article.img = article["images"];
    modalRef.componentInstance.showInstant();
  }

  gotoItem(event, article) {
    const modalRef = this.modalService.open(ArticleModalComponent, {
      size: "lg",
      container: "#modalContainer"
    });
    modalRef.componentInstance.article = article;
    modalRef.componentInstance.modelId = article["id"];
    modalRef.componentInstance.article.img = article["images"];
    modalRef.componentInstance.showInstant();
    event.preventDefault();
    return false;
  }

  getStockStatus() {
    return (
      "Status: " + this.article.stock_status + " " + this.article.stock_date
    );
  }
}
