import { Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { timer } from "rxjs";
import { ApiResponse } from "src/app/interfaces/api-response";
import { CompanyPromotion } from "src/app/interfaces/company-promotion";
import { MenuItem } from "src/app/interfaces/menuItem";
import { ApplicationService } from "src/app/services/application.service";
import { HelperService } from "src/app/services/helper.service";
import { SearchService } from "src/app/services/search.service";

@Component({
  selector: "app-catalogue-navbar",
  templateUrl: "./catalogue-navbar.component.html",
  styleUrls: ["./catalogue-navbar.component.scss"],
})
export class CatalogueNavbarComponent {
  @Input() browseGroups: MenuItem[];
  @Input() showBom: boolean;
  @Output() groupSelected: EventEmitter<any> = new EventEmitter<any>();
  showItems = true;
  promotionsLoaded: boolean;
  promotions: CompanyPromotion[][] = [];
  showPromotions: boolean;
  constructor(private applicationService: ApplicationService, private searchService: SearchService, private helperService: HelperService, private router: Router, private _element:ElementRef) {

  }

  ngOnInit() {
  }

  selectGroup(productGroup: string, articleGroup: string, articleSubGroup: string, articleSubSubGroup: string) {
    this.showItems = false;

    this.groupSelected.emit({
      productGroup: productGroup,
      articleGroup: articleGroup,
      articleSubGroup: articleSubGroup,
      articleSubSubGroup: articleSubSubGroup,
    });
  }

  getPromotions() {
    this.promotionsLoaded = false;
    this.searchService.getPromotions().then((apiResponse: ApiResponse) => {
      this.promotionsLoaded = true;
      this.showPromotions = true;

      if (this.helperService.checkResponse(apiResponse)) {
        this.promotions = Object.values(apiResponse.result);
      }
    });
  }

  gotoSearchPage(filter, event) {
    this.showItems = false;
    this.showPromotions = false;
    this.router.navigateByUrl(
      `${this.applicationService.getSelectCompanyGroupCode()}/search#filter/COMMON=${filter}`
    );
    setTimeout(() => {
      this.searchService.setGroups("");
    }, 0);
    event.preventDefault();
    return false;
  }
}
