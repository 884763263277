import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { ApiResponse } from "src/app/interfaces/api-response";
import { YearAgreementBase } from "src/app/interfaces/year-agreement-base";
import { YearAgreementType } from "src/app/interfaces/year-agreement-type";
import { environment } from "src/environments/environment";
import { AlertService } from "../alert.service";
import { HelperService } from "../helper.service";

@Injectable({
  providedIn: "root",
})
export class YearAgreementBaseService {
  private emitChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  constructor(
    private http: HttpClient,
    private helperService: HelperService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  updated() {
    this.alertService.showInfoNotification(
      this.translateService.instant("SAVED")
    );
    this.emitChangeSource.next(true);
  }

  agreements(type: YearAgreementType) {
    return this.http.get(
      `${environment.apiendpoint}yearagreementsbase/${type}`
    );
  }

  getAgreements(type: YearAgreementType) {
    return new Promise((resolve, reject) => {
      this.agreements(type).subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          resolve(apiResponse);
        } else {
          reject();
        }
      });
    });
  }

  checkForm() {
    const invalidElements = document.getElementsByClassName("invalid-ya");
    if (invalidElements.length) {
      this.alertService.showErrorNotification(
        `${this.translateService.instant("REQUIRED_FIELDS_TEXT")}`
      );
      return false;
    } else {
      return true;
    }
  }

  openAgreements() {
    return this.http.get(
      `${environment.apiendpoint}yearagreementsbase/open/`
    );
  }

  send(id: number) {
    return this.http.post(
      `${environment.apiendpoint}yearagreementsbase/${id}/send`,
      {}
    );
  }

  sendException(id: number) {
    return this.http.post(
      `${environment.apiendpoint}yearagreementsbase/${id}/sendexception`,
      {}
    );
  }

  sign(id: number, dealerName: string, contractEmail: string) {
    return this.http.post(
      `${environment.apiendpoint}yearagreementsbase/${id}/sign`,
      {
        id: id,
        dealer_name: dealerName,
        contract_email: contractEmail,
      }
    );
  }

  pdf(type: YearAgreementType, id: string) {
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: "application/pdf",
      }),
      responseType: "blob" as "json",
    };

    return this.http.get(
      `${environment.apiendpoint}yearagreementsbase/${id}/pdf?fileType=pdf`,
      HTTPOptions
    );
  }

  reOpenAgreement(id: string) {
    const accountCode = localStorage.getItem("accountCode");
    return new Promise((resolve) => {
      this.http
        .get(
          `${environment.apiendpoint}yearagreementsbase/account/${accountCode}/${id}/reopen`
        )
        .subscribe((apiResponse: ApiResponse) => {
          if (this.helperService.checkResponse(apiResponse)) {
            this.alertService.showInfoNotification(
              this.translateService.instant("AGREEMENT_REOPENED")
            );
          } else {
            this.alertService.showErrorNotification(
              apiResponse.result
                ? apiResponse.result
                : this.translateService.instant("ERROR")
            );
          }

          resolve(true);
        });
    });
  }
}
