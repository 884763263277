import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
  AbstractControl,
} from "@angular/forms";
import { ValidateEmail } from "src/app/validators/customvalidators";
import { Article } from "src/app/interfaces/article";
import { CmsService } from "src/app/services/cms.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { CompanyPage } from "src/app/interfaces/cms/company-page";
import { AddWarrantyArticleRequest } from "src/app/interfaces/warranty/add-warranty-article-request";
import { WarrantyService } from "src/app/services/warranty.service";
import * as moment from "moment";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { WarrantyArticle } from "src/app/interfaces/warranty/warranty-article";
import { SearchService } from "src/app/services/search.service";
import { ApplicationService } from "src/app/services/application.service";
import { AccountService } from "src/app/services/account.service";
import { AccountInformation } from "src/app/interfaces/account-information";
import { Location } from "@angular/common";
import { WarrantyLeasingComponent } from "./warranty-leasing/warranty-leasing.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-warranty-wizard",
  templateUrl: "./warranty-wizard.component.html",
  styleUrls: ["./warranty-wizard.component.scss"],
})
export class WarrantyWizardComponent implements OnInit {
  @ViewChild(WarrantyLeasingComponent)
  leasingComponent: WarrantyLeasingComponent;
  currentArticles: WarrantyArticle[] = [];
  articledAdded$ = new Subject();
  tabTypeSelected = "";
  warrantyId: number;
  formGroup: FormGroup;
  bicycle: Article;
  part: Article;
  validated: boolean;
  currentArticle: WarrantyArticle;
  framenumber = "";
  descriptions = {
    GAR: "WARRANTY",
    WDL: "RETURN",
    REC: "CALLBACK",
    LEASE: "LEASE",
    REC_BATT: "RECALL_BATTERY",
  };

  cmsPages = {
    // todo
    intro: "960421910",
    GAR: "960421914",
    WDL: "960421915",
    REC: "960421916",
    LEASE: "960422040",
    REC_BATT: "960421916",
  };
  explanation: string;
  reasonId: number;
  cached: any = {};
  hasLease: boolean;
  loading: boolean;
  pageId: string;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private cmsService: CmsService,
    private warrantyService: WarrantyService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private searchService: SearchService,
    private applicationService: ApplicationService,
    private accountService: AccountService,
    private location: Location
  ) {}

  ngOnInit() {
    this.getAccountInformation();
    this.buildForm();

    if (this.tabTypeSelected !== "LEASE") {
      this.pageId = this.cmsPages.intro;
    } else {
      this.pageId = this.cmsPages.LEASE;
    }

    if (this.framenumber) {
      this.formGroup.get("framenumber").setValue(this.framenumber);
      this.location.go(
        `${this.applicationService.getSelectCompanyGroupCode()}/warranty/${
          this.warrantyId
        }`
      );
      this.searchService
        .searchInstant(this.framenumber)
        .subscribe((apiResponse: ApiResponse) => {
          if (
            apiResponse &&
            apiResponse.success &&
            apiResponse.result &&
            apiResponse.result.length
          ) {
            const article = apiResponse.result[0] as Article;
            if (article && article.article_type.indexOf("F") >= 0) {
              this.bicycle = article;
              this.formGroup.get("framenumber").setValue(this.framenumber);
            } else {
              this.formGroup.get("framenumber").setValue("");
            }
          } else {
            this.formGroup.get("framenumber").setValue("");
          }
        });
    }

    if (this.currentArticle) {
      this.formGroup
        .get("articleCode")
        .setValue(this.currentArticle.article_code);
      this.formGroup.get("remarks").setValue(this.currentArticle.remarks);

      this.formGroup
        .get("complaintCode")
        .setValue(this.currentArticle.complaint_code);
      this.formGroup
        .get("returnCondition")
        .setValue(this.currentArticle.complaint_code);
      this.formGroup.get("quantity").setValue(this.currentArticle.quantity);
      this.formGroup
        .get("serialNumber")
        .setValue(this.currentArticle.serial_number);
      this.formGroup.get("fileUrl").setValue(this.currentArticle.file_url);
      this.formGroup
        .get("guaranteeDate")
        .setValue(this.currentArticle.guarantee_date);
      this.formGroup
        .get("batteryType")
        .setValue(this.currentArticle.article_type);
      this.formGroup
        .get("batteryKind")
        .setValue(this.currentArticle.battery_kind);
      this.formGroup
        .get("employeeName")
        .setValue(this.currentArticle.employee_name);
      this.formGroup
        .get("framenumber")
        .setValue(this.currentArticle.frame_number);
      this.formGroup
        .get("packingSlipNumber")
        .setValue(this.currentArticle.packing_slip_number);

      this.formGroup
        .get("returnCondition")
        .setValue(this.currentArticle.retour_condition);
      this.formGroup.get("mileage").setValue(this.currentArticle.mileage);

      if (this.currentArticle.flow_type === "lease") {
        this.tabTypeSelected = "LEASE";
      }

      if (this.currentArticle.frame_number) {
        this.searchService
          .searchInstant(this.currentArticle.frame_number)
          .subscribe((apiResponse: ApiResponse) => {
            if (
              apiResponse &&
              apiResponse.success &&
              apiResponse.result &&
              apiResponse.result.length
            ) {
              this.bicycle = apiResponse.result[0];
            }
          });
      }
    }
  }

  getAccountInformation() {
    this.accountService
      .getAccountInformation()
      .then((accountInformation: AccountInformation) => {
        this.hasLease = accountInformation.is_lease_dealer;
      });
  }

  returnConditionValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const value = control ? control.value : "";
    if (this.tabTypeSelected === "GAR") {
      return { returnCodeRequired: true };
    }
    return null;
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      employeeName: new FormControl("", Validators.required),
      articleCode: new FormControl("", Validators.required),
      framenumber: new FormControl("", Validators.required),
      remarks: new FormControl("", Validators.required),
      complaintCode: new FormControl(""),
      returnCondition: new FormControl(""),
      quantity: new FormControl("1", Validators.required),
      guaranteeDate: new FormControl("", Validators.required),
      articleDescription: new FormControl("", Validators.required),
      articleValid: new FormControl("1", Validators.required),
      packingSlipNumber: new FormControl(""),
      serialNumber: new FormControl(""),
      guaranteeDateValid: new FormControl("", Validators.required),
      batteryType: new FormControl("", Validators.required),
      batteryKind: new FormControl("", Validators.required),
      mileage: new FormControl(""),
      uploadFile: new FormControl(""),
      warrantyPathCode: new FormControl(""),
      articleCodeOem: new FormControl(""),
      fileUrl: new FormControl("", Validators.required),
    });

    this.formGroup.get("articleDescription").disable();
    this.formGroup.get("fileUrl").disable();
    this.formGroup.get("batteryType").disable();
    this.formGroup.get("batteryKind").disable();
    this.formGroup.get("guaranteeDate").disable();
    this.formGroup.get("guaranteeDateValid").disable();
    this.formGroup.get("packingSlipNumber").disable();
  }

  _findInvalidControls() {
    const invalid = [];
    const controls = this.formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  setReasonId(reasonId: number) {
    this.reasonId = reasonId;
  }

  checkForm(addAnotherWithFramenumber?: boolean) {
    this.validated = true;
    let valid = true;
    if (this.formGroup.valid) {
      const request = new AddWarrantyArticleRequest();
      request.article_code = this.formGroup.get("articleCode").value;
      request.article_description = this.formGroup.get(
        "articleDescription"
      ).value;
      request.battery_kind = this.formGroup.get("batteryKind").value
        ? this.formGroup.get("batteryKind").value
        : "";

      request.article_type = this.formGroup.get("batteryType").value
        ? this.formGroup.get("batteryType").value
        : "regular";

      if (this.tabTypeSelected !== "LEASE") {
        request.flow_type = "regular";
      } else {
        request.flow_type = "lease";
      }

      request.characteristic = "";
      request.complaint_code = this.formGroup.get("complaintCode").value;
      request.employee_name = this.formGroup.get("employeeName").value;
      request.extra_warranty = false;
      request.file_url = this.formGroup.get("fileUrl").value;
      request.frame_article_code = this.bicycle
        ? this.bicycle.article_codes[0]
        : null;
      request.frame_number = this.formGroup.get("framenumber").value;

      // Check if article is already added (with same framenumber)
      if (request.frame_number && this.formGroup.get("articleCode").value) {
        this.currentArticles.find((a: WarrantyArticle) => {
          if (a.article_code === this.formGroup.get("articleCode").value && a.frame_number === request.frame_number) {
            this.alertService.showPopup(this.translateService.instant("ARTICLE_FRAMENUMBER_ALREADY_ADDED"), "error");
            valid = false;
          }
        })
      }

      if (!valid) {
        return false;
      }

      // request type
      if (this.tabTypeSelected === "LEASE") {
        request.reason_type =
          this.leasingComponent.leaseType === "lease_bike_part" ? "GAR" : "SER";
        if (this.leasingComponent.leaseType === "lease_service") {
          request.article_type = "regular_lease";
          this.reasonId = 20;
        } else {
          this.reasonId = 8;
        }
      } else {
        request.reason_type = this.tabTypeSelected;
      }

      // Guarantee date
      if (
        request.reason_type === "GAR" &&
        this.formGroup.get("guaranteeDate").enabled
      ) {
        request.guarantee_date = this.formGroup.get("guaranteeDate")
          ? String(
              moment(
                this.formGroup.get("guaranteeDate").value,
                "DD-MM-YYYY"
              ).format("YYYY-MM-DD")
            )
          : null;
      }
      request.quantity = this.formGroup.get("quantity").value;
      request.reason_id = this.reasonId;

      request.remarks = this.formGroup.get("remarks").value;
      request.serial_number = this.formGroup.get("serialNumber").value;
      request.warranty_article_id = null;
      request.retour_condition = this.formGroup.get("returnCondition").value;
      request.packing_slip_number = this.formGroup.get("packingSlipNumber").value;
      request.warranty_path_code =
        request.flow_type === "lease"
          ? "E"
          : this.formGroup.get("warrantyPathCode").value;
      request.warranty_id = this.warrantyId;
      request.mileage = this.formGroup.get("mileage").value;
      request.article_code_oem = this.formGroup.get("articleCodeOem").value;
      this.applicationService.showLoader(true);
      this.loading = true;
      if (!this.currentArticle && valid) {
        this.warrantyService
          .addArticle(this.warrantyId, request)
          .subscribe((apiResponse: ApiResponse) => {
            this.applicationService.hideLoader();
            this.loading = false;
            if (apiResponse.success) {
              this.alertService.showPopup(
                this.translateService.instant("ARTICLE_ADDED"),
                "success"
              );
              this.articledAdded$.next(true);

              if (!addAnotherWithFramenumber) {
                this.modal.close();
              } else {
                const frame_number = request.frame_number;
                this.buildForm();
                this.formGroup.get("framenumber").setValue(frame_number);
              }
            } else {
              this.alertService.showErrorNotification(
                apiResponse.result ? apiResponse.result : this.translateService.instant("ERROR")
              );
            }
          });
      } else {
        if (!request.reason_id) {
          request.reason_id = this.currentArticle.reason_id;
        }
        request.warranty_article_id = this.currentArticle.warranty_article_id;
        this.warrantyService
          .updateArticle(
            this.warrantyId,
            this.currentArticle.warranty_article_id,
            request
          )
          .subscribe((apiResponse: ApiResponse) => {
            this.applicationService.hideLoader();
            if (apiResponse.success) {
              this.alertService.showInfoNotification(
                this.translateService.instant("SAVED")
              );
              this.articledAdded$.next(true);
              this.modal.close();
            } else {
              this.alertService.showErrorNotification(
                apiResponse.result ? apiResponse.result : this.translateService.instant("ERROR")
              );
            }
          });
      }
    } else {
      const invalid = [];
      const controls = this.formGroup.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      if (environment.name === "development") {
        console.log(invalid)
      }
    }
  }

  switch(type) {
    this.tabTypeSelected = type;
    const pageId = this.cmsPages[this.tabTypeSelected];
    this.formGroup.get("articleCode").setValue("");
    this.formGroup.get("framenumber").setValue("");
    this.formGroup.get("warrantyPathCode").setValue("");
    switch (this.tabTypeSelected) {
      case "GAR":
        this.formGroup.get("remarks").setValidators([Validators.required]);
        this.formGroup.get("remarks").updateValueAndValidity();
        this.formGroup.get("articleValid").setValidators([Validators.required]);
        this.formGroup
          .get("returnCondition")
          .setValidators([Validators.required]);
        this.formGroup.get("articleValid").updateValueAndValidity();
        this.formGroup.get("guaranteeDate").enable();
        this.formGroup.get("guaranteeDate").updateValueAndValidity();
        this.formGroup.get("packingSlipNumber").clearValidators();
        this.formGroup.get("packingSlipNumber").updateValueAndValidity();
        break;
      case "WDL":
        this.formGroup.get("remarks").setValidators([Validators.required]);
        this.formGroup.get("remarks").updateValueAndValidity();
        this.formGroup.get("packingSlipNumber").updateValueAndValidity();
        this.formGroup.get("articleValid").clearValidators();
        this.formGroup.get("articleValid").updateValueAndValidity();
        this.formGroup.get("returnCondition").clearValidators();
        this.formGroup.get("returnCondition").updateValueAndValidity();
        break;
      case "LEASE":
        this.formGroup.get("guaranteeDate").disable();
        this.formGroup.get("guaranteeDate").updateValueAndValidity();
        this.formGroup.get("articleValid").setValidators([Validators.required]);
        this.formGroup.get("articleValid").updateValueAndValidity();
        this.setReasonId(8);
        break;
      case "REC":
        this.formGroup.get("remarks").clearValidators();
        this.formGroup.get("remarks").updateValueAndValidity();
        this.formGroup.get("articleValid").clearValidators();
        this.formGroup.get("articleValid").updateValueAndValidity();
        this.formGroup.get("returnCondition").clearValidators();
        this.formGroup.get("returnCondition").updateValueAndValidity();
        this.formGroup.get("packingSlipNumber").clearValidators();
        this.formGroup.get("packingSlipNumber").updateValueAndValidity();
        this.setReasonId(15);
        break;

      case "REC_BATT":
        this.formGroup.get("packingSlipNumber").clearValidators();
        this.formGroup.get("packingSlipNumber").updateValueAndValidity();
        this.formGroup.get("remarks").clearValidators();
        this.formGroup.get("remarks").updateValueAndValidity();
        this.formGroup.get("guaranteeDate").disable();
        this.formGroup.get("guaranteeDate").updateValueAndValidity();
        this.formGroup.get("framenumber").enable();
        this.formGroup.get("framenumber").updateValueAndValidity();
        this.formGroup.get("articleValid").clearValidators();
        this.formGroup.get("articleValid").updateValueAndValidity();
        this.formGroup.get("serialNumber").setValue("");
        this.formGroup.get("articleCode").setValue("41122189");

        this.formGroup.get("batteryType").enable();
        this.formGroup.get("batteryType").setValue("liion_battery");
        this.formGroup.get("batteryType").updateValueAndValidity();

        this.formGroup.get("batteryKind").enable();
        this.formGroup.get("batteryKind").setValue("main_battery");
        this.formGroup.get("batteryKind").updateValueAndValidity();

        this.formGroup.get("returnCondition").clearValidators();
        this.formGroup.get("returnCondition").updateValueAndValidity();
        break;
    }

    this.currentArticle = null;
    this.pageId = pageId;
  }

  getExplanation(pageId: string) {
    this.cmsService.getPage(pageId).subscribe((apiResponse: ApiResponse) => {
      if (apiResponse.success) {
        let cmsData: CompanyPage;
        cmsData = apiResponse.result;
        const content = cmsData.language_definitions[0].content;
        this.explanation = cmsData.language_definitions[0].content;
        this.cached[pageId] = content;
      }
    });
  }

  // Edit current article
  edit(article: WarrantyArticle) {
    let reasonType = "";
    switch (article.reason_type) {
      case "GAR":
      case "WDL":
      case "REC":
        reasonType = article.reason_type;
        break;
      case "WOR":
        reasonType = "WDL";
        break;
      case "DAM":
        reasonType = "WDL";
        break;
    }

    if (article.article_details) {
      this.part = article.article_details;
    }
    this.currentArticle = article;
    if (!article.article_type) {
      this.tabTypeSelected = reasonType;
    } else if (article.article_type === "lease") {
      this.tabTypeSelected = "LEASE";
    } else {
      this.tabTypeSelected = reasonType;
    }
  }

  selectPart(selectedPart: any) {
    this.part = selectedPart.article;
  }
}
