
<ng-container *ngIf="redirect">
  <a class="btn text-white" [ngClass]="'btn-' + type" (click)="redirectToUrl()" [appStoryblok]="_editable">{{text}}</a>
</ng-container>
<ng-container *ngIf="!redirect">
  <ng-container *ngIf="isInternal">
    <a class="btn text-white" [ngClass]="'btn-' + type" [target]="link_target ? link_target : '_self'" [routerLink]="isInternal ? link.url : undefined" [appStoryblok]="_editable">{{text}}</a>
  </ng-container>
  <ng-container *ngIf="!isInternal">
    <a class="btn text-white" [ngClass]="'btn-' + type" [href]="link.url" [target]="link_target ? link_target : '_self'" [appStoryblok]="_editable">{{text}}</a>
  </ng-container>
</ng-container>
