<div class="row">
  <div class="col-md-10 col-10 col-md-10">
    <select [(ngModel)]="basket" class="form-control" (change)="selectBasket($event)">
      <ng-container *ngFor="let basket of baskets">
        <option *ngIf="!basket.pre_order && !basket.locked" [value]="basket.id">{{basket.name}}</option>
      </ng-container>
    </select>
  </div>
  <div class="col-md-2 col-2 col-md-2 ps-0 pt-2 d-flex align-items-center">
    <i class="material-icons clickable withOpacity" (click)="newBasket()">add_circle</i>
  </div>
</div>
