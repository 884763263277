import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MenuItem } from "src/app/interfaces/menuItem";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-mobile-navigation",
  templateUrl: "./mobile-navigation.component.html",
  styleUrls: ["./mobile-navigation.component.scss"],
})
export class MobileNavigationComponent implements OnInit {
  @Output()
  gotoItemEvent: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();
  @Output()
  closeMenuEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() browseGroups: MenuItem[];
  @Input() menuItems: MenuItem[];
  @Input() settingsItems: MenuItem[];
  isPartnerPortal: boolean;

  constructor(private applicationService: ApplicationService) {
    this.isPartnerPortal = this.applicationService.isPartnerPortal();
  }

  ngOnInit(): void {}

  gotoItem(menuItem: MenuItem) {
    this.gotoItemEvent.emit(menuItem);
  }

  closeMenu() {
    this.closeMenuEvent.emit(true);
  }
}
