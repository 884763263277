<app-bread-crumbs [(breadcrumbs)]="breadcrumbs" *ngIf="showBreadCrumbs"></app-bread-crumbs>
<span class="spinner-border spinner-border-sm mb-2" role="status" aria-hidden="true" *ngIf="loading"></span>
<div *ngIf="story.content">
  <ndc-dynamic
    [ndcDynamicComponent]="components['page']"
    [ndcDynamicInputs]="story"
  >
  </ndc-dynamic>
</div>
<div class="content" *ngIf="!story.content">
</div>
