<div
  *ngIf="(recentKeyWords && recentKeyWords.length) || articles?.length"
  class="start-0 dropdown-menu col-md-12"
  [ngClass]="show ? 'show' : ''"
>
  <h5 class="px-3 pt-3 pb-1" *ngIf="recentKeyWords?.length">{{ "RECENTLY_SEARCHED" | translate }}</h5>
  <ng-container
    *ngFor="let keyWord of recentKeyWords; let keywordIndex = index"
  >
    <p
      class="dropdown-item clickable withOpacity m-0 p-2 ps-3"
      (click)="gotoSearch(keyWord)"
      *ngIf="keywordIndex < 10"
    >
      {{ keyWord }}
      <i class="material-icons clear" (click)="removeRecentItem(keyWord)"
        >clear</i
      >
    </p>
  </ng-container>
  <h5 class="px-3 pt-3 pb-1" *ngIf="articles?.length">{{ "RECENTLY_VIEWED" | translate }}:</h5>
  <ng-container *ngFor="let article of articles">
    <p
      class="dropdown-item clickable withOpacity m-0 p-2 ps-3 d-flex align-items-center"
      (click)="gotoArticle(article)"
    >
      <span>
        <img class="d-inline me-2" [src]="article?.thumbs[0]" style="" />
      </span>
      <span class="d-flex flex-column">
        <span>{{ article.description }}</span>
        <small *ngIf="article.caption" [innerHTML]="article.caption"></small>
      </span>
    </p>
  </ng-container>
</div>
