import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { WarrantyRequest } from "src/app/interfaces/warranty/warranty-request";
import { WarrantyArticle } from "src/app/interfaces/warranty/warranty-article";
import * as _ from "underscore";

@Component({
  selector: "app-warranty-requests-container",
  templateUrl: "./warranty-requests-container.component.html",
  styleUrls: ["./warranty-requests-container.component.scss"]
})
export class WarrantyRequestsContainerComponent implements OnInit {
  @Input() articles: WarrantyArticle[];
  @Input() showControls: boolean;
  @Output() added: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}

  deleteArticle(index: number) {
    this.articles.splice(index, 1);
  }
}
