import { Component, Input, OnInit } from "@angular/core";
import { Bonus } from "src/app/interfaces/yearAgreement/year-agreement-parts";
import { YearAgreementPAndAService } from "src/app/services/year-agreements/year-agreement/year-agreement-p-and-a.service";

@Component({
  selector: "app-dealer-parts-bonus-selection",
  templateUrl: "./dealer-parts-bonus-selection.component.html",
  styleUrls: ["./dealer-parts-bonus-selection.component.scss"],
})
export class DealerPartsBonusSelectionComponent implements OnInit {
  @Input() bonusses: Bonus[];
  constructor(public yearAgreementPAndAService: YearAgreementPAndAService) {}

  get totalBonusPercentage() {
    let total = 0;
    this.bonusses.forEach((b) => {
      if (b.selected) {
        b.options.forEach((o) => {
          if (o.selected) {
            total += o.bonus_percentage;
          }
        });
      }
    });
    return total;
  }

  ngOnInit() {}

  setSelection(bonus: Bonus) {
    if (!bonus.selected) {
      bonus.planning = false;
      bonus.options.forEach((b) => {
        b.selected = b.default_value;
      });
    }
  }
}
