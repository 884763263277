import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-users-page",
  templateUrl: "./users-page.component.html",
  styleUrls: ["./users-page.component.scss"]
})
export class UsersPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
