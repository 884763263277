import { Component, Input, OnInit } from '@angular/core';
import { SbColor } from 'src/app/interfaces/storyblok/sb-color';
import { SbGridContent } from 'src/app/interfaces/storyblok/sb-grid2x1';

@Component({
  selector: "app-grid-block",
  templateUrl: "./grid-block.component.html",
  styleUrls: ["./grid-block.component.scss"],
})
export class GridBlockComponent implements OnInit {
  @Input() _uid: string;
  @Input() component: any;
  @Input() text: SbGridContent;
  @Input() background_color: SbColor;
  @Input() text_color: SbColor;
  @Input() border_radius: string;
  @Input() border_width: string;
  @Input() border_color: SbColor;
  @Input() alignment_vertical: string;
  @Input() text_block_alignment: string;
  @Input() anchor_id: string;
  @Input() _editable: any;

  get divId() {
    return typeof this.anchor_id !== "undefined" ? this.anchor_id : undefined
  }

  get alignmentClass() {
    let classAppend = "start";
    switch (this.alignment_vertical) {
      case "top":
        classAppend = "start";
        break;
      case "center":
        classAppend = "center";
        break;
      case "end":
        classAppend = "end";
        break;
    }

    return `align-items-${classAppend}`;
  }

  get textBlockAlignmentClass() {
    let classAppend = "start";
    switch (this.text_block_alignment) {
      case "top":
        classAppend = "start";
        break;
      case "center":
        classAppend = "center";
        break;
      case "end":
        classAppend = "end";
        break;
    }

    return `text-${classAppend}`;
  }

  get borderRadius() {
    if (this.border_radius) {
      return `${this.border_radius}px`;
    } else {
      return undefined;
    }
  }


  get border() {
    if (this.border_width) {
      return `solid ${this.border_width}px ${this.border_color.color??'#000'}`;
    } else {
      return undefined;
    }
  }

  constructor() {}

  ngOnInit(): void {}
}
