import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { HelperService } from 'src/app/services/helper.service';
import { YearAgreementBaseService } from 'src/app/services/year-agreements/year-agreement-base.service';

@Component({
  selector: "app-agreement-alert",
  templateUrl: "./agreement-alert.component.html",
  styleUrls: ["./agreement-alert.component.css"],
})
export class AgreementAlertComponent implements OnInit {
  @Input() signed: boolean;
  @Input() sent: boolean;
  @Input() signedDate: string;
  @Input() sendDate: string;
  constructor(private helperService: HelperService) {}

  ngOnInit(): void {}

  _getSignedDate(value) {
    return this.helperService.formatDate(value);
  }
}
