<div class="btn-group btn-group-toggle d-flex py-2 gap-2">
  <button class="btn" (click)="setTab('wrong_delivered')" [ngClass]="tabSelected === 'wrong_delivered' ? 'btn-dark' : 'btn-primary'">
     {{'WRONGLY_DELIVERED'|translate}}
  </button>
  <button class="btn" (click)="setTab('damaged')" [ngClass]="tabSelected === 'damaged' ? 'btn-dark' : 'btn-primary'">
    {{'PART_DAMAGED'|translate}}
  </button>
  <button class="btn" (click)="setTab('wrong_ordered')" [ngClass]="tabSelected === 'wrong_ordered' ? 'btn-dark' : 'btn-primary'">
     {{'PART_WRONG_ORDERED'|translate}}
  </button>
  <button class="btn" (click)="setTab('battery')" [ngClass]="tabSelected === 'battery' ? 'btn-dark' : 'btn-primary'">
   {{'BATTERIES_AND_MOTORS'|translate}}
  </button>
</div>
<div [style.display]="tabSelected ? '' : 'none'">
  <app-warranty-general-inputs [formGroup]="formGroup" [currentArticle]="currentArticle" [tabSelected]="tabSelected" [type]="'returns'"
    (selectPackingSlip)="showPackingSlipFinder()">

    <div class="mb-3 row" *ngIf="tabSelected !== 'battery'"> <label for="name"
        class="col-md-4 control-label text-start">{{'PACKINGSLIP_NUMBER'|translate}}
        *</label>
      <div class="col-md-6" [formGroup]="formGroup"> <input type="text" formControlName="packingSlipNumber"
          class="form-control packingSlipNumber bg-white" (change)="showPackingSlipFinder()" name="packingSlipNumber"
          (click)="showPackingSlipFinder()">
      </div>
    </div>
  </app-warranty-general-inputs>


  <app-warranty-battery [formGroup]="formGroup" *ngIf="tabSelected === 'battery'"></app-warranty-battery>

  <app-warranty-complain-description-inputs [formGroup]="formGroup">
    <div class="mb-3 row" *ngIf="showSerial && tabSelected !== 'battery'">
      <label class="col-md-4 control-label text-start">{{'SERIALNO'|translate}} {{(formGroup.get("batteryKind").value === "motor" ? "MOTOR" : 'BATTERY')|translate|lowercase}} *</label>
      <div class="col-md-6" [formGroup]="formGroup">
        <input type="text" class="form-control" formControlName="serialNumber">
      </div>
    </div>
    <ng-container *ngIf="tabSelected === 'damaged'">
      <div class="mb-3 row">
        <label for="name" class="col-md-4 control-label text-start">
          {{'PHOTO'|translate}}
          *</label>
        <app-warranty-file-upload class="col-md-6" [warrantyId]="warrantyId" [formGroup]="formGroup">
        </app-warranty-file-upload>
      </div>
    </ng-container>
    <ng-container *ngIf="tabSelected === 'battery'">
      <app-ezy-rider-invoice [warrantyId]="warrantyId" [formGroup]="formGroup"></app-ezy-rider-invoice>
    </ng-container>
  </app-warranty-complain-description-inputs>
</div>
