import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { SearchService } from "src/app/services/search.service";

@Component({
  selector: "app-common-filter",
  templateUrl: "./common-filter.component.html",
  styleUrls: ["./common-filter.component.scss"]
})
export class CommonFilterComponent implements OnInit {
  @Input() name: string;
  @Input() translation: string;
  @Input() selectedValue: boolean;

  @Output()
  updated: EventEmitter<any> = new EventEmitter<any>();

  constructor(private searchService: SearchService) { }

  ngOnInit() {
    this.searchService.resetFiltersEmitted$.subscribe(article => {
      this.selectedValue = false;
    });
  }

  updateSwitch() {
    this.selectedValue = !this.selectedValue;
    this.getSwitch(this.selectedValue);
  }

  getSwitch(event) {
    const data = {};
    data["name"] = this.name;
    data["value"] = event;
    this.updated.emit(data);
  }


}
