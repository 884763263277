import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ApiResponse } from "src/app/interfaces/api-response";
import { AccountService } from "src/app/services/account.service";

function validateEmail(email) {
    const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return EMAIL_REGEXP.test(email);
}

@Injectable()
export class UsernameValidator {

    debouncer: any;
    constructor(public accountService: AccountService) {

    }

    checkUsername(control: FormControl): any {

        clearTimeout(this.debouncer);

        return new Promise(resolve => {
            if (!validateEmail(control.value)) {
                resolve({ "invalidEmail": true });
                return;
            }
            this.debouncer = setTimeout(() => {
                this.accountService.checkUniqueEmail(control.value).subscribe((response: ApiResponse) => {
                    if (response.success) {
                        resolve(null);
                    } else {
                        resolve({ "usernameInUse": true });
                    }
                }, (err) => {
                    resolve({ "error": true });
                });

            }, 1000);

        });
    }
}
