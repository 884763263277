import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ZipLocation } from "../interfaces/zip-location";
import { Subject, map } from "rxjs";
import { ApiResponse } from "../interfaces/api-response";

@Injectable({
  providedIn: "root",
})
export class GeneralService {
  private emitJunckerLoyaltyPointsUpdated = new Subject<number>();
  junckerLoyaltyPointsUpdatedEmitted$ = this.emitJunckerLoyaltyPointsUpdated.asObservable();
  junckerPoints: number = null;

  constructor(private http: HttpClient) {}

  // Function to get an address based on ZipLocation
  getAddress(zipLocation: ZipLocation) {
    return this.http.post(environment.apiendpoint + `address`, zipLocation);
  }

  // Function to fetch loyalty points and update Juncker loyalty points
  loyaltyPoints() {
    return this.http.get(`${environment.apiendpoint }accounts/loyalty/points`).pipe(map((response: ApiResponse) => {
      this.emitJunckerLoyaltyPointsUpdated.next(response.result);
      this.junckerPoints = response.result;
      return response;
    }));
  }

  // Function to generate a loyalty URL with an optional returnUrl parameter
  loyaltyUrl(returnUrl: string = "") {
    if (returnUrl) {
      returnUrl = `?returnUrl=${returnUrl}`
    }
    return this.http.get(`${environment.apiendpoint}accounts/loyalty/url${returnUrl}`);
  }

}
