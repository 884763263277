import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-list-item",
  templateUrl: "./list-item.component.html",
  styleUrls: ["./list-item.component.scss"]
})
export class ListItemComponent implements OnInit {
  @Input() article: Article;
  @Input() index: number;
  @Output() openModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectArticle: EventEmitter<any> = new EventEmitter<any>();
  imageSize = 100;
  showGrossPrice: any;

  constructor(private applicationService: ApplicationService) {}

  ngOnInit() {
    this.getShowGrossPrice();
    this.applicationService.changeEmitted$.subscribe(response => {
      this.getShowGrossPrice();
    });
  }

  articleSelected(index, event) {
    this.selectArticle.emit({
      article: this.article,
      event: event,
      index: index
    });
  }

  getShowGrossPrice() {
    this.showGrossPrice = this.applicationService.getSetting(
      "showGrossPrice",
      this.showGrossPrice
    );
  }
}
