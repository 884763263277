import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LotteryRegistration } from '../interfaces/lottery-registration';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LotteryRegistrationService {

  constructor(private http: HttpClient) { }

  registerLotteryClaim(lotteryRegistration: LotteryRegistration) {
    return this.http.post(`${environment.apiendpoint}lottery/claim`, lotteryRegistration);
  }
}
