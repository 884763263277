import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { YearAgreementService } from "src/app/services/year-agreement.service";
import { YearAgreementPAndAService } from "src/app/services/year-agreements/year-agreement/year-agreement-p-and-a.service";

@Component({
  selector: "app-dealer-commercial-partner-settings",
  templateUrl: "./dealer-commercial-partner-settings.component.html",
  styleUrls: ["./dealer-commercial-partner-settings.component.scss"],
})
export class DealerCommercialPartnerSettingsComponent implements OnInit {
  @Input() minimalTurnover: number = 0;
  constructor(public yearAgreementPAndAService: YearAgreementPAndAService) {}

  ngOnInit() {}
}
