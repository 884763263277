<div class="btn-group btn-group-toggle py-2 d-flex gap-2">
  <button class="btn" (click)="typeChanged('bike_part')"  [ngClass]="tabSelected === 'bike_part' ? 'btn-dark' : 'btn-primary'">
    {{'BIKE_PART'|translate}}
  </button>
  <button class="btn"  (click)="typeChanged('separate_part')"  [ngClass]="tabSelected === 'separate_part' ? 'btn-dark' : 'btn-primary'">
    {{'SEPERATE_PART'|translate}}
  </button>
  <button class="btn"  (click)="typeChanged('battery')"  [ngClass]="tabSelected === 'battery' ? 'btn-dark' : 'btn-primary'">
    {{'BATTERIES_AND_MOTORS'|translate}}
  </button>
</div>

<div [style.display]="tabSelected ? '' : 'none'">

  <app-warranty-general-inputs #generalArticleData (partSelected)="partSelected($event)" [type]="'guarantee'" (bicycleSelected)="bicycleSelected($event)" [tabSelected]="tabSelected" [bicycle]="bicycle" [bicycleUnknown]="bicycleUnknown"
    [formGroup]="formGroup" [currentArticle]="currentArticle" [tabSelected]="tabSelected">
    <div class="mb-3 row" *ngIf="tabSelected === 'lease_bike_part'">
      <div class="col-md-4">
        <label>{{"MILEAGE"|translate}}</label>
      </div>
      <div class="col-md-4">
        <input type="number" class="form-control" formControlName="mileage" name="mileage" maxlength="5">
      </div>
    </div>
    <app-warranty-article-description-input *ngIf="tabSelected !== 'battery'" [formGroup]="formGroup">
    </app-warranty-article-description-input>
  </app-warranty-general-inputs>

  <app-warranty-guarantee-date  [warrantyId]="warrantyId" [bicycleUnknown]="bicycleUnknown" [tabSelected]="tabSelected"
    [formGroup]="formGroup" [framenumber]="formGroup.get('framenumber').value"
    [articleCode]="formGroup.get('articleCode').value"
    *ngIf="tabSelected === 'bike_part' || tabSelected === 'separate_part' || tabSelected === 'lease_bike_part'">
  </app-warranty-guarantee-date>

  <app-warranty-battery [formGroup]="formGroup" *ngIf="tabSelected === 'battery'"></app-warranty-battery>
  <app-warranty-complain-description-inputs *ngIf="formGroup.get('articleValid').valid" [formGroup]="formGroup" [tabSelected]="tabSelected" [maxQuantity]="generalArticleData.part?.quantity ? generalArticleData.part?.quantity : (companyId === 4 ? 25 : 1)"></app-warranty-complain-description-inputs>
</div>
