import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { Article } from "src/app/interfaces/article";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { WeekDay } from "@angular/common";
import { ApplicationService } from "src/app/services/application.service";
import { CompanyGroupLayout } from "src/app/interfaces/company-group-layout";
import { CompanyGroup } from "src/app/interfaces/company-group";

@Component({
  selector: "app-select-week",
  templateUrl: "./select-week.component.html",
  styleUrls: ["./select-week.component.scss"],
})
export class SelectWeekComponent implements OnInit {
  @Input() article: Article;
  @Input() deliveryWeek: string;
  @Input() type = "dropdown";
  @Output() weekSelected: EventEmitter<any> = new EventEmitter<any>();
  weeks: any = [];
  monthList: any = [];
  currentDate: Date;
  stockMonth: number;
  @Input() triggerUpdate = true;
  lockedWeeksBikes: boolean;
  lockedWeeksParts: boolean;
  lockedWeeks: boolean;

  constructor(
    private translateService: TranslateService,
    public applicationService: ApplicationService
  ) {
    this.currentDate = new Date();
  }

  ngOnInit() {
    this.renderWeeks();
    this.getCompanyGroupSettings();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes["deliveryWeek"] &&
      this.applicationService.getSelectCompanyGroupCode() !== "WG"
    ) {
      this.renderWeeks();
    }
  }

  getCompanyGroupSettings() {
    const companyGroupSettings = this.applicationService.getSettingsForCompanyGroup(
      this.applicationService.getSelectCompanyGroupCode()
    ) as CompanyGroup;
    this.lockedWeeks = (companyGroupSettings.locked_delivery_week_bikes && this.article.article_type !== "P") || (companyGroupSettings.locked_delivery_week_parts  && this.article.article_type === "P") || companyGroupSettings.locked_delivery_week_all;

    // LAP only for 2024 models
    if (this.applicationService.getSelectCompanyGroupCode() === "LAP" && this.article.season !== "2024") {
      this.lockedWeeks = true;
    }
  }

  renderWeeks() {
    let monthList = {};
    this.monthList = [];
    if (this.article.stock_status < 4 && this.article.single_article_code) {
      const currentYear = moment().year();

      if (this.article.stock_year < currentYear) {
        this.article.stock_year = currentYear;
        this.article.stock_week = moment().isoWeek();
      }

      const canBeOrderedThisYear = currentYear === this.article.stock_year;

      // Add all remaining weeks from the current year
      monthList = Object.assign(
        monthList,
        this.addYear(
          canBeOrderedThisYear
            ? this.type === "dropdown"
              ? this.article.stock_week
              : moment().isoWeek()
            : this.article.stock_week,
          0,
          null,
          currentYear + 1
        )
      );

      // ANL - LAP has a max order date
      // before 31-08-YYYY max week 52 current year
      // after 31-08-YYYY max week 52 next year
      if (this.applicationService.getSelectCompanyGroupCode() === "ANL" || this.applicationService.getSelectCompanyGroupCode() === "LAP") {
        const today = moment().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const onlyOrderThisYearDate = moment().set({
          month: 7,
          date: 31,
        });

        if (today.isAfter(onlyOrderThisYearDate)) {
          this.monthList = this.addYear(1, 1, monthList, currentYear + 1);
        } else if (this.article.stock_year > currentYear) {
          monthList = {};
          const stockweek = String(this.article.stock_week).length < 2
          ? "0" + this.article.stock_week
          : this.article.stock_week;

          if (this.deliveryWeek !== `${this.article.stock_year}${stockweek}`)
            this.weekSelected.next(`${this.article.stock_year}${this.article.stock_week}`);
          }
      } else {
        this.monthList = this.addYear(1, 1, monthList, currentYear + 1);
      }
      this.monthList = this.parseMonthList(monthList);
    }

    this.article.out_of_delivery = this.monthList.length === 0;
  }

  parseMonthList(obj: Object) {
    if (obj) {
      let ownProps = Object.keys(obj),
        i = ownProps.length,
        resArray = new Array(i); // preallocate the Array
      while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

      return resArray;
    } else {
      return [];
    }
  }

  addYear(startWeek, yearDelta, currentList, maxYear?) {
    let monthList = currentList === null ? {} : currentList;
    const currentYear = moment().format("YYYY");

    for (
      let i = startWeek;
      i <= (yearDelta === 0 ? 52 : moment().isoWeek());
      i++
    ) {
      const key = this.applicationService
        .getDateOfWeek(this.article.stock_year + yearDelta, i)
        .format("YYYYMM");

      if (!monthList[key]) {
        monthList[key] = [];
      }

      // Add the week to the month list
      if (
        (this.article.stock_year + yearDelta <= maxYear) &&
        Number(this.article.stock_year + yearDelta) >
          this.currentDate.getFullYear() ||
        (Number(this.article.stock_year) === this.currentDate.getFullYear() &&
          i >= moment().isoWeek())
      ) {
        monthList[key].push({
          value:
            String(this.article.stock_year + yearDelta) +
            this.getConvertedMonth(i),
          month: this.applicationService
            .getDateOfWeek(this.article.stock_year + yearDelta, i)
            .format("MM"),
          text:
            i === moment().isoWeek() &&
            this.article.stock_year + yearDelta === currentYear
              ? this.translateService.instant("DIRECT")
              : i +
                " " +
                (this.article.stock_year + yearDelta) +
                ` (${['WG', 'GHO'].includes(this.applicationService.getSelectCompanyGroupCode()) ? this.translateService.instant('IN_WEEK_OF') + ' ' : ''}` +
                this.applicationService
                  .getDateOfWeek(this.article.stock_year + yearDelta, i)
                  .format("DD-MM-YYYY") +
                ")",
        });
      }
    }
    return monthList;
  }

  getConvertedMonth(weekNumber) {
    const stringMonth = String(weekNumber);
    return stringMonth.length < 2 ? "0" + stringMonth : stringMonth;
  }

  getMonthDescription(month) {
    const monthYear = month.substr(0, 4);
    const monthIndex = Number(month.substr(4, 6));

    return (
      this.translateService.instant(
        `MONTH_${monthIndex < 10 ? "0" + monthIndex : monthIndex}`
      ) +
      " " +
      monthYear
    );
  }

  getMonthDescriptionShort(month) {
    const monthDescription = month.substr(4, 6);
    return monthDescription;
  }

  selectWeek(data) {
    this.deliveryWeek = data.target.value;
    this.weekSelected.next(data.target.value);
  }

  weekValue(index, week) {
    return week.value;
  }
}
