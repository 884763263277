<div class="mb-1" [class.col-10]="activeTab === 'atmospheric'" [class.ms-auto]="activeTab === 'atmospheric'">
  <ul class="nav nav-tabs border-0 justify-content-center gap-2">
    <li class="nav-item">
      <a
        class="nav-link pb-2"
        href="#"
        (click)="setTab($event, 'articles')"
        [ngClass]="activeTab === 'articles' ? 'px-2 active' : 'ps-0'"
        >{{ "ARTICLES" | translate }}</a
      >
    </li>

    <li class="nav-item">
      <a
        class="nav-link pb-2 px-2"
        href="#"
        (click)="setTab($event, 'atmospheric')"
        [ngClass]="activeTab === 'atmospheric' ? 'px-2 active' : 'ps-0'"
        >{{ "ATMOSPHERIC" | translate }}</a
      >
    </li>
  </ul>
</div>
<div>
  <ng-container *ngIf="activeTab === 'articles'">
    <div class="col-md-12 px-0">
      <div class="articleContainer row hasArticles px-0">
        <app-image-bank-article
          class="card d-flex col-lg-4 col-xl-4 col-xs-12 col-sm-6 col-md-6 align-items-stretch"
          *ngFor="let article of articles"
          [article]="article"
        >
        </app-image-bank-article>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab === 'atmospheric'">
    <div class="row mx-2">
      <div
        class="col-md-3 col-lg-2 col-xl-2 col-sm-4 pe-md-0 ps-md-2 px-0 d-sm-block"
      >
        <div class="mb-3">
          <label class="filterHeader">{{ "SEARCH" | translate }}
            <input class="form-control" type="search" (change)="search()"  [(ngModel)]="keyword"  />
          </label>
        </div>
        <div class="mb-3 overflow-hidden">
          <label class="filterHeader">{{ "BRANDS" | translate }}</label>
          <div>
            <label
              ><input
                type="radio"
                [value]="0"
                checked
                name="brand_id"
                [(ngModel)]="selectedbrand"
                (change)="
                  selectedyear = ''; selectedFileType = ''; filter('brand'); getYears(filteredImages); getFiletypes(filteredImages)
                "
              /><span style="margin-left: 10px" class="clickable withOpacity"
                >{{ "ALL" | translate }} ({{ brandsCountAll }})</span
              ></label
            >
          </div>
          <div *ngFor="let brand of brands">
            <label
              ><input
                [value]="brand"
                type="radio"
                name="brand_id"
                [(ngModel)]="selectedbrand"
                (change)="
                  selectedyear = ''; selectedFileType = ''; filter('brand'); getYears(filteredImages);  getFiletypes(filteredImages)
                "
              /><span class="clickable withOpacity" style="margin-left: 10px"
                >{{ brand }} ({{ brandsCount[brand] }})</span
              ></label
            >
          </div>
        </div>

        <div class="mb-3 overflow-hidden">
          <label class="filterHeader">{{
            "COLLECTION_YEAR" | translate
          }}</label>
          <div>
            <label
              ><input
                type="radio"
                [value]="''"
                name="year"
                [(ngModel)]="selectedyear"
                (change)="selectedFileType = ''; filter('year'); getFiletypes(filteredImages)"
              /><span class="clickable withOpacity" style="margin-left: 10px"
                >{{ "ALL" | translate }} ({{ yearsCountAll }})</span
              ></label
            >
          </div>
          <div *ngFor="let year of years">
            <label
              ><input
                [value]="year"
                type="radio"
                name="year"
                [(ngModel)]="selectedyear"
                (change)="selectedFileType = ''; filter('year'); getFiletypes(filteredImages)"
              /><span class="clickable withOpacity" style="margin-left: 10px"
                >{{ year }} ({{ yearsCount[year] }})</span
              ></label
            >
          </div>
        </div>

        <div class="mb-3 overflow-hidden">
          <label class="filterHeader">{{
            "FILE_TYPE" | translate
          }}</label>
          <div>
            <label
              ><input
                type="radio"
                [value]="''"
                name="selectedFileType"
                [(ngModel)]="selectedFileType"
                (change)="filter('year')"
              /><span class="clickable withOpacity" style="margin-left: 10px"
                >{{ "ALL" | translate }}</span
              ></label
            >
          </div>
          <div *ngFor="let fileType of fileTypes">
            <label
              ><input
                [value]="fileType"
                type="radio"
                name="selectedFileType"
                [(ngModel)]="selectedFileType"
                (change)="filter('fileType')"
              /><span class="clickable withOpacity" style="margin-left: 10px"
                >{{ fileType }} ({{ fileTypesCount[fileType] }})</span
              ></label
            >
          </div>
        </div>
      </div>
      <div class="col-xs-12 row p-0 m-0 col-md-9 col-sm-8 col-lg-10 col-xl-10">
        <div class="col-md-12 px-0">
          <button class="btn btn-primary ms-2" *ngIf="showConditions" (click)="showConditionsModal()">{{"GENERAL_TERMS_AND_CONDITIONS"|translate}}</button>
          <div class="articleContainer row hasArticles">
            <ng-container
              *ngFor="
                let image of filteredImages;
                let i = index;
                trackBy: trackByFunction(image)
              "
            >
              <div
                class="d-flex px-0 p-1 col-lg-4 col-xl-4 col-xs-12 col-sm-6 col-md-6 align-items-stretch gap-2"
              >
                <app-image-bank-atmospheric
                  [image]="image"
                  [showDeleteButton]="showDeleteButton"
                  class="d-flex w-100 px-1"
                >
                </app-image-bank-atmospheric>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
