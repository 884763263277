import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { ApplicationService } from 'src/app/services/application.service';
import { AuthenticateService } from 'src/app/services/authenticate.service';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss'],
})
export class AdminSidebarComponent {
  isAdmin = false;
  sidebarVisible = false;

  get cgcRoot() {
    return `/${this.applicationService.getSelectCompanyGroupCode()}`;
  }

  constructor(
    public applicationService: ApplicationService,
    public authenticateService: AuthenticateService,
    private router: Router
  ) {

    this.isAdmin = this.authenticateService.isAdmin();

    router.events.subscribe(() => {
      this.sidebarVisible = false
    });
  }
}
