<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container">
  <div class="row">
    <app-basket-skeleton-loader *ngIf="!basket"/>
    <div class="ps-3 pe-2 col-md-12 px-md-3">
      <h1>{{ "DELETED_BASKET" | translate }} #{{ basket ? basket.id : "" }}</h1>
      <ng-container *ngIf="basket">
        <h2 class="pb-4 subHeader">{{ basket.name }}</h2>
        <div class="articleCollection row px-3">
          <div class="ps-0 col-md-9" *ngIf="basket">
            <app-grouped-articles
              *ngIf="
                groupedArticles &&
                groupedArticles['parts'] &&
                groupedArticles['parts'].items.length
              "
              (contentChanged)="calcTotal(basket.items)"
              [groupedArticles]="groupedArticles['parts']"
              [currency]="basket.total_price?.currency"
              [header]="!basket.pre_order ? 'PARTS' : ''"
              [sum]="basketSum['parts']"
              [compact]="false"
              [basketId]="basket.id"
              [checkout]="true"
              [readonly]="true"
            ></app-grouped-articles>
            <app-grouped-articles
              *ngIf="
                groupedArticles &&
                groupedArticles['bicycles'] &&
                groupedArticles['bicycles'].items.length
              "
              (contentChanged)="calcTotal(basket.items)"
              [groupedArticles]="groupedArticles['bicycles']"
              [currency]="basket.total_price?.currency"
              [header]="!basket.pre_order ? 'BICYCLES' : ''"
              [sum]="basketSum['bicycles']"
              [compact]="false"
              [basketId]="basket.id"
              [checkout]="true"
              [readonly]="true"
            ></app-grouped-articles>
          </div>

          <div class="col-3 px-3">
            <ng-container *ngIf="basketSum['total'] !== '0.00'">
              <div class="row sum totalSum px-2 mt-1">
                <div class="col-md-3 my-3">

                </div>

                <div class="pe-0 col-md-12 h3">
                  {{ "TOTAL" | translate }}
                  <span
                    [innerHTML]="
                      basketSum.totalWithShipping
                        | currencyConvert : basket.total_price?.currency
                    "
                  ></span>
                </div>
                <div class="col-md-3"></div>
              </div>
            </ng-container>
          </div>
          <div *ngIf="basketSum['total'] === '0.00'" class="mt-3 mb-5">
            <p>{{ "NO_RESULTS" | translate }}</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <app-bottom-navbar [fixed]="true" *ngIf="basket">
    <ng-container *ngTemplateOutlet="options"></ng-container>
  </app-bottom-navbar>

  <ng-template #options>
    <nav class="px-0 navbar navbar-light bg-light w-100">
      <div class="container">
        <div class="row w-100">
          <div
            class="px-0 col-md-12 d-flex justify-content-between"
            style="margin-top: -10px"
          >
            <button class="float-left btn btn-lg btn-dark" (click)="back()">
              {{ "BACK" | translate }}
            </button>
            <button
              class="btn btn-lg btn-success"
              (click)="restore()"
              *ngIf="basket"
            >
              {{ "RESTORE_BASKET" | translate }}
            </button>
          </div>
        </div>
      </div>
    </nav>
  </ng-template>
</div>
