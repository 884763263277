import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { WarrantyDetails } from "src/app/interfaces/warranty/warranty-details";
import { GroupedWarrantyArticles } from "src/app/interfaces/warranty/grouped-warranty-articles";
import { WarrantyService } from "src/app/services/warranty.service";
import { ApplicationService } from "src/app/services/application.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { SelectedForWarrantyShipment } from "src/app/interfaces/warranty/selected-for-warranty-shipment";
import { SendWarrantyRequest } from "src/app/interfaces/warranty/send-warranty-request";
import { SelectPickupDateComponent } from "./select-pickup-date/select-pickup-date.component";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { Location } from "@angular/common";
import { AlertService } from "src/app/services/alert.service";
import { PickupAddressSelectComponent } from "../pickup-address-select/pickup-address-select.component";
import { DealerService } from "src/app/services/dealer.service";
import { PickupAddress } from "src/app/interfaces/warranty/pickup-address";
import { HelperService } from "src/app/services/helper.service";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-warranty-send-details",
  templateUrl: "./warranty-send-details.component.html",
  styleUrls: ["./warranty-send-details.component.scss"],
})
export class WarrantySendDetailsComponent implements OnInit {
  @Input() id: number;
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  warrantyDetails: WarrantyDetails;
  groupedArticles: GroupedWarrantyArticles;
  selectedForShipment: SelectedForWarrantyShipment;

  // Addresses
  @ViewChild("pickupAddressSelectComponent")
  pickupAddressSelectComponent: PickupAddressSelectComponent;

  @ViewChild("pickupAddressSelectMotorComponent")
  pickupAddressSelectMotorComponent: PickupAddressSelectComponent;

  @ViewChild("pickupAddressSelectBatteryComponent")
  pickupAddressSelectBatteryComponent: PickupAddressSelectComponent;

  // Dates
  @ViewChild("selectPickupDateLeaseComponent")
  selectPickupDateLeaseComponent: SelectPickupDateComponent;
  @ViewChild("selectPickupDateComponent")
  selectPickupDateComponent: SelectPickupDateComponent;
  @ViewChild("selectPickupDateBatteriesComponent")
  selectPickupDateBatteriesComponent: SelectPickupDateComponent;
  @ViewChild("selectPickupDateMotorsComponent")
  selectPickupDateMotorsComponent: SelectPickupDateComponent;
  companyGroupCode: string;
  addresses: PickupAddress[];
  loading: boolean;

  get sendDisabled() {
    let addressValid = true;

    // Pickup addresses (regular)
    if (this.pickupAddressSelectComponent && this.selectedForShipment.regular) {
      if (this.pickupAddressSelectComponent.selectedIndex === null) {
        addressValid = false;
      }
    }

    // Pickup addresses (motor)
    if (
      this.pickupAddressSelectMotorComponent &&
      this.selectedForShipment.motors
    ) {
      if (this.pickupAddressSelectMotorComponent.selectedIndex === null) {
        addressValid = false;
      }
    }

    // Pickup addresses (battery)
    if (
      this.pickupAddressSelectBatteryComponent && this.selectedForShipment.batteries
    ) {
      if (this.pickupAddressSelectBatteryComponent.selectedIndex === null) {
        addressValid = false;
      }
    }

    return (
      ((!this.selectedForShipment ||
        (!this.selectedForShipment.lease &&
          !this.selectedForShipment.regular &&
          !this.selectedForShipment.batteries &&
          !this.selectedForShipment.motors) ||
        (this.selectedForShipment.lease &&
          this.groupedArticles.lease.length &&
          this.selectPickupDateLeaseComponent &&
          !this.selectPickupDateLeaseComponent.selectedDate) ||
        (this.selectedForShipment.regular &&
          this.groupedArticles.regular.length &&
          this.selectPickupDateComponent &&
          (!this.selectPickupDateComponent.selectedDate ||
            !this.warrantyDetails.weight ||
            !this.warrantyDetails.colli)) ||
        (this.selectedForShipment.batteries &&
          this.groupedArticles.batteries.length &&
          this.selectPickupDateBatteriesComponent &&
          !this.selectPickupDateBatteriesComponent.selectedDate) ||
        (this.selectedForShipment.motors &&
          this.groupedArticles.motors.length &&
          this.selectPickupDateMotorsComponent &&
          !this.selectPickupDateMotorsComponent.selectedDate)) &&
        !this.groupedArticles.regular_dispose.length) ||
      !addressValid
    );
  }

  constructor(
    private warrantyService: WarrantyService,
    private applicationService: ApplicationService,
    private alertService: AlertService,
    private dealerService: DealerService,
    private helperService: HelperService,
    private translateService: TranslateService
  ) {
    this.groupedArticles = new GroupedWarrantyArticles();
    this.companyGroupCode = applicationService.getSelectCompanyGroupCode();
  }

  ngOnInit() {
    this.getDetails();
    this.getAddresses();
  }

  private checkSelection() {
    // Check if there are articles for each group
    if (!this.groupedArticles.batteries.length) {
      this.selectedForShipment.batteries = false;
    }

    if (!this.groupedArticles.motors.length) {
      this.selectedForShipment.motors = false;
    }

    if (!this.groupedArticles.batteries.length) {
      this.selectedForShipment.batteries = false;
    }
  }

  getDetails() {
    this.warrantyService
      .getWarrantyDetails(this.id)
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.warrantyDetails = apiResponse.result;
          this.groupedArticles = this.warrantyService.filterArticles(
            this.warrantyDetails.warranty_articles,
            true
          );
           this.selectedForShipment = new SelectedForWarrantyShipment(
             this.id,
             this.groupedArticles.lease.length ? true : false,
             this.groupedArticles.regular.length ? true : false,
             this.groupedArticles.batteries.length ? true : false,
             this.groupedArticles.motors.length ? true : false
           );
          this.checkSelection();

        }
      });
  }
  _formatDateNumber(unformatted: number) {
    return unformatted < 10 ? `0${unformatted}` : unformatted;
  }

  _getPickupDate(pickupdate: NgbDate) {
    return pickupdate
      ? `${this._formatDateNumber(pickupdate.day)}-${this._formatDateNumber(
          pickupdate.month
        )}-${pickupdate.year}`
      : null;
  }

  sendWarranty() {
    const request = new SendWarrantyRequest();
    const tomorrow = moment().add(1, "days").format("DD-MM-YYYY");
    request.id = this.id;
    request.colli = this.warrantyDetails.colli;
    request.weight = this.warrantyDetails.weight;
    request.pickup_date = this._getPickupDate(
      this.selectPickupDateComponent.selectedDate
    );
    request.pickup_date_batteries = this._getPickupDate(
      this.selectPickupDateBatteriesComponent.selectedDate
    );
    request.pickup_date_motor = this._getPickupDate(
      this.selectPickupDateMotorsComponent.selectedDate
    );
    request.ignore_article_types = [];

    if (!this.selectedForShipment.lease) {
      request.ignore_article_types.push("regular_lease");
    }
    if (!this.selectedForShipment.regular) {
      request.ignore_article_types.push("regular");
    } else {
      const selectedDay = moment(request.pickup_date, "DD-MM-YYYY").day();

      // On Sunday a dealer is not allowed to select Monday as picking day
      if (moment().day() === 0 && selectedDay === 1) {
        this.alertService.showPopup(
          this.translateService.instant("MONDAY_NOT_ALLOWED"),
          "error"
        );
        this.selectPickupDateComponent.selectedDate = null;
        this.selectPickupDateComponent.setDates();
        return false;
      }

      // Check if selected pickup date is after tomorrow when it's after 16:00
      if (Number(moment().format("HH")) >= 16 && request.pickup_date === tomorrow) {
        this.alertService.showPopup(
          this.translateService.instant("PICKUPDATE_ERROR"),
          "error"
        );
        this.selectPickupDateComponent.selectedDate = null;
        this.selectPickupDateComponent.setDates();
        return false;
      }
    }

    if (!this.selectedForShipment.batteries) {
      request.ignore_article_types.push("batteries");
      request.ignore_article_types.push("liion_battery");
      request.ignore_article_types.push("nimh_battery");
    } else {

      const selectedDay = request.pickup_date_batteries;

      // Check if selected pickup date is after tomorrow when it's after 16:00
      if (Number(moment().format("HH")) >= 16 && selectedDay === tomorrow) {
        this.alertService.showPopup(
          this.translateService.instant("BATTERY_PICKUPDATE_ERROR"),
          "error"
        );
        this.selectPickupDateBatteriesComponent.selectedDate = null;
        this.selectPickupDateBatteriesComponent.setDates();
        return false;
      }
    }

    if (!this.selectedForShipment.motors) {
      request.ignore_article_types.push("motor");
    }

    // Pickup addresses (regular)
    if (this.pickupAddressSelectComponent) {
      request.pickup_address =
        this.pickupAddressSelectComponent.addresses[
          this.pickupAddressSelectComponent.selectedIndex
        ];
    }

    // Pickup addresses (motor)
    if (this.pickupAddressSelectMotorComponent) {
      request.pickup_address_motor =
        this.pickupAddressSelectMotorComponent.addresses[
          this.pickupAddressSelectMotorComponent.selectedIndex
        ];
    }

    // Pickup addresses (battery)
    if (this.pickupAddressSelectBatteryComponent) {
      request.pickup_address_batteries =
        this.pickupAddressSelectBatteryComponent.addresses[
          this.pickupAddressSelectBatteryComponent.selectedIndex
        ];
    }
    // Submit the warranty
    this.loading = true;
    this.applicationService.showLoader(true);
    this.warrantyService
      .submit(request)
      .subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        this.loading = false;
        if (this.helperService.checkResponse(apiResponse)) {
          if (apiResponse.success) {
            this.submitted.emit(true);
          } else {
            this.alertService.showErrorNotification(apiResponse.result);
          }
        } else {
          this.alertService.showErrorNotification("ERROR");
        }
      });
  }

  // Get pickup addresses
  getAddresses() {
    this.dealerService.getAddresses().subscribe((apiResponse: ApiResponse) => {
      if (apiResponse && apiResponse.success)
        this.addresses = apiResponse.result as PickupAddress[];
    });
  }
}
