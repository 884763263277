<ng-container *ngIf="!showroomMode && parsedPrices.length">
  <h6>{{ 'VOLUME_PRICES' | translate }}</h6>
  <table class="table mt-2">
    <thead>
      <tr class="opacity-75">
        <td class="ps-2">{{ headerLeft | translate }}</td>
        <td class="ps-2">{{ headerRight | translate }}</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="regularPrice">
        <td class="ps-2">1 &times;</td>
        <td class="ps-2" [innerHTML]="regularPrice?.value | currencyConvert: regularPrice?.currency"></td>
      </tr>
      <tr *ngFor="let priceData of parsedPrices">
        <td class="ps-2">{{priceData.volume}} &times;</td>
        <td class="ps-2" [innerHTML]="priceData.price.value | currencyConvert: priceData.price.currency"></td>
      </tr>
    </tbody>
  </table>
</ng-container>

