import { Component, OnInit, Input } from "@angular/core";
import { ApplicationService } from "src/app/services/application.service";
import { Price } from "src/app/interfaces/price";

@Component({
  selector: "app-gross-price",
  templateUrl: "./gross-price.component.html",
  styleUrls: ["./gross-price.component.scss"]
})
export class GrossPriceComponent implements OnInit {
  @Input() price: Price;
  showroomMode = false;
  showGrossPrice = false;
  @Input() showLabel = false;
  constructor(private applicationService: ApplicationService) {
    this.getShowroomMode();
    this.getShowGrossPrice();
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  getShowGrossPrice() {
    this.showGrossPrice = this.applicationService.getSetting(
      "showGrossPrice",
      this.showGrossPrice
    ) && this.applicationService.getSelectCompanyGroupCode() !== 'RAL'
  }

  ngOnInit() {
    this.applicationService.changeEmitted$.subscribe(response => {
      this.getShowroomMode();
      this.getShowGrossPrice();
    });
  }
}
