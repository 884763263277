<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'SEARCH'|translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
    </button>
</div>

<div class="modal-body p-3">
  <div class="row mb-4">
    <table class="table table-striped" *ngIf="articles && articles.length">
      <tr *ngFor="let article of articles" [ngClass]="articleCode === article.packing_slip_article.article_code ? 'bg-success text-light' : ''">
        <td>
          {{article.packing_slip_article.article_code}}
        </td>
        <td>
          {{article.article_details.description}}
        </td>
        <td>
          {{article.packing_slip_article.remark}}
        </td>
        <td>
          <button class="btn btn-primary btn-sm text-white"
            (click)="select(article.packing_slip_article.article_code, article.article_details)">{{'SELECT'|translate}}</button>
        </td>
      </tr>
    </table>
  </div>
</div>
