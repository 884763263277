<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "SCAN" | translate }} EAN
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss()"
  ></button>
</div>

<div class="modal-body pt-0">
  <div class="row">
    <zxing-scanner
      (scanSuccess)="onValueChanges($event)"
      [formats]="['QR_CODE', 'EAN_13', 'CODE_128', 'DATA_MATRIX']"
      (camerasNotFound)="camerasNotFound()"
    ></zxing-scanner>
    <div class="d-flex align-items-center gap-2 flex-wrap">
    <div [hidden]="!barcodeValue">
      {{ barcodeValue }}
      <div *ngIf="article" class="d-block"  [articleQuickView]="article.single_article_code">
        {{article.description}} - {{article.single_article_code}}
      </div>
    </div>
    <ng-container *ngIf="article !== null">
      <p-inputNumber [(ngModel)]="qty" *ngIf="!autoClose" [showButtons]="true"></p-inputNumber>
      <button class="btn btn-primary" (click)="add()">{{"ADD"|translate}}</button>
    </ng-container>
  </div>
    <div *ngIf="noCamera" class="text-center">
    <i class="material-icons d-block" style="font-size: 4rem;"> no_photography </i>
      No camera found
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-dark" (click)="modal.dismiss('Close')">
    {{ "CLOSE" | translate }}
  </button>
</div>
