<app-bread-crumbs
  [(breadcrumbs)]="breadcrumbs"
  *ngIf="!ordered"
></app-bread-crumbs>
<div class="container px-0">
  <div class="px-3">
    <h1>{{ "PREORDER_CONDITIONS" | translate | formatStringYear }}</h1>

    <div class="mt-4" *ngIf="(!bico && !zeg) && applicationService.getSelectCompanyGroupCode() !== 'GHO'">{{ "PAYCOND_EXPLAIN" | translate }}</div>
    <div class="mt-4" *ngIf="applicationService.getSelectCompanyGroupCode() === 'GHO'">{{ "PAYCOND_EXPLAIN_GHOST" | translate }}</div>

    <div *ngIf="accountLoaded">
      <div class="px-2 mx-0 row odd" *ngIf="groupId === 'direct' && showFlex">
        <div class="px-0 mt-2 col-4">
          <strong>{{ "FLEX_CONDITIONS" | translate }}</strong>
        </div>
        <div class="mt-2 col-8">
          <div class="d-block">
            <input
              class="me-2"
              type="checkbox"
              name="flex_discount"
              value="true"
              id="flex_8"
              [(ngModel)]="flex_discount"
              (change)="checkSelection()"
            />
            <label
              for="flex_8"
              [innerHTML]="
                'PAYCOND_FLEX' + translationKey | translate | formatStringYear
              "
            ></label>
          </div>
        </div>
        <div class="px-3 col-md-12" *ngIf="ordered">
          <app-sent-basket-overview></app-sent-basket-overview>
        </div>
      </div>

      <div class="px-2 mx-0 row odd" *ngIf="groupId === 'Lapierre'">
        <div class="px-0 mt-2 col-4">
          <strong>{{ "FLEX_CONDITIONS" | translate }}</strong>
        </div>
        <div class="mt-2 col-8">
          <div class="mt-2 col-8">
            <input
              class="me-2"
              type="radio"
              name="LapierreStandard"
              value="4"
              id="lapierre_1"
              [(ngModel)]="selected"
              (change)="checkSelection()"
            />
            <label
              for="lapierre_1"
              [innerHTML]="
                'PAYCOND_FLEX_1' + translationKey | translate | formatStringYear
              "
            ></label>
            <div class="d-block">
              <input
                class="me-2"
                type="radio"
                name="LapierreStandard"
                value="5"
                id="lapierre_2"
                [(ngModel)]="selected"
                (change)="checkSelection()"
              />
              <label
                for="lapierre_2"
                [innerHTML]="
                  'PAYCOND_FLEX_2' + translationKey | translate | formatStringYear
                "
              ></label>
              <div class="d-block">
                <input
                  class="me-2"
                  type="radio"
                  name="LapierreStandard"
                  value="6"
                  id="lapierre_3"
                  [(ngModel)]="selected"
                  (change)="checkSelection()"
                />
                <label
                  for="lapierre_3"
                  [innerHTML]="
                    'PAYCOND_FLEX_3' + translationKey | translate | formatStringYear
                  "
                ></label>
              </div>
            </div>
          </div>
          <div class="px-3 col-md-12" *ngIf="ordered">
            <app-sent-basket-overview></app-sent-basket-overview>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!bico && !zeg">
        <div class="px-2 mx-0 row odd" [ngClass]="{'hideDetails':groupId === 'all'}">
          <div class="px-0 mt-2 col-4">
            <strong
              >{{ "STANDARD_CONDITIONS" | translate | formatStringYear }}<ng-container *ngIf="groupId !== 'all'">***</ng-container></strong
            >
          </div>
          <div class="mt-2 col-8">
            <input
              class="me-2"
              type="radio"
              name="Standard"
              value="0"
              id="standard_1"
              [(ngModel)]="selected"
              (change)="checkSelection()"
            />
            <label
              for="standard_1"
              [innerHTML]="
                'PAYCOND_FLEX_STANDARD_1' + translationKey
                  | translate
                  | formatStringYear
              "
            ></label>
            <div class="d-block">
              <input
                class="me-2"
                type="radio"
                name="Standard"
                value="1"
                id="standard_4"
                [(ngModel)]="selected"
                (change)="checkSelection()"
              />
              <label
                for="standard_4"
                [innerHTML]="
                  'PAYCOND_FLEX_STANDARD_2' + translationKey
                    | translate
                    | formatStringYear
                "
              ></label>
            </div>
          </div>
        </div>
        <div class="px-2 mx-0 row odd" *ngIf="groupId === '00002'">
          <div class="px-0 mt-2 col-4">
            <strong
              >{{ "CONDITIONS" | translate }} ({{
                "TIMESLOT_02" | translate
              }})</strong
            ><br />
          </div>
          <div class="mt-2 col-8">
            <div class="d-block">
              <input
                class="me-2"
                type="radio"

                name="Winter1"
                value="0"
                id="winter1_3"
                [(ngModel)]="selected"
                (change)="checkSelection()"
              />
              <label
                for="winter1_3"
                [innerHTML]="
                  'PAYCOND_FEBMRT_1' + translationKey | translate | formatStringYear
                "
              ></label>
            </div>
            <div class="d-block">
              <input
                class="me-2"
                type="radio"
                name="Winter1"
                value="1"
                id="winter1_7"
                [(ngModel)]="selected"
                (change)="checkSelection()"
              />
              <label
                for="winter1_7"
                [innerHTML]="
                  'PAYCOND_FEBMRT_2' + translationKey | translate | formatStringYear
                "
              ></label>
            </div>
          </div>
        </div>

        <div class="px-2 mx-0 row odd" *ngIf="groupId === '004'">
          <div class="px-0 mt-2 col-4">
            <strong
              >{{ "CONDITIONS" | translate }} ({{
                "TIMESLOT_04" | translate
              }})</strong
            ><br />
          </div>
          <div class="mt-2 col-8">
            <div class="d-block">
              <input
                class="me-2"
                type="radio"
                name="Winter2_2"
                value="0"
                id="winter2_2"
                [(ngModel)]="selected"
                (change)="checkSelection()"
              />
              <label
                for="winter2_2"
                [innerHTML]="
                  'PAYCOND_APRMAY_1' + translationKey | translate | formatStringYear
                "
              ></label>
            </div>
            <div class="d-block">
              <input
                class="me-2"
                type="radio"
                name="Winter2_6"
                value="1"
                id="winter2_6"
                [(ngModel)]="selected"
                (change)="checkSelection()"
              />
              <label
                for="winter2_6"
                [innerHTML]="
                  'PAYCOND_APRMAY_2' + translationKey | translate | formatStringYear
                "
              ></label>
            </div>
          </div>
        </div>

        <div
          class="px-2 mx-0 row odd"
          *ngIf="groupId === 'DEMO' || groupId === 'REPAIR'"
        >
          <div class="px-0 mt-2 col-4" *ngIf="groupId === 'DEMO'">
            <strong>{{ "DEMO_CONDITIONS" | translate }}</strong>
          </div>
          <div class="px-0 mt-2 col-4" *ngIf="groupId === 'REPAIR'">
            <strong>{{ "REPAIR_CONDITIONS" | translate }}</strong>
          </div>
          <div class="mt-2 col-8">
            <div class="d-block">
              <input
                class="me-2"
                type="checkbox"
                name="Demodiscount"
                value="3"
                id="flex_8_c"
                [(ngModel)]="selected"
                (change)="checkSelection()"
              />
              <label for="flex_8_c">{{ "PAYCOND_DEMO" | translate }}</label>
            </div>
          </div>
          <div class="px-3 col-md-12" *ngIf="ordered">
            <app-sent-basket-overview></app-sent-basket-overview>
          </div>
        </div>
      </ng-container>
      <div
        class="px-2 mx-0 row odd"
        *ngIf="zeg || bico"
      >
        <div class="px-0 my-2 col-4">
          <strong *ngIf="bico">{{ "BICO_CONDITIONS_APPLIED" | translate }}</strong>
          <strong *ngIf="zeg">{{ "ZEG_CONDITIONS_APPLIED" | translate }}</strong>
        </div>
        <div class="px-3 col-md-12" *ngIf="ordered">
          <app-sent-basket-overview></app-sent-basket-overview>
        </div>
      </div>
    </div>
  </div>
  <app-bottom-navbar [fixed]="true" *ngIf="basket">
    <ng-container *ngTemplateOutlet="options"></ng-container>
  </app-bottom-navbar>
  <ng-template #options>
    <div
      class="ps-0 pe-2 mb-2 col-md-12"
      *ngIf="basket && groupId !== 'Lapierre' && !bico && !zeg && groupId !== 'all'"
    >
      <ng-container *ngIf="applicationService.getSelectCompanyGroupCode() === 'GHO'">{{ "PAYCOND_NOTES_TWO" | translate }}</ng-container>
    </div>
    <div class="ps-0 pe-2 col-md-12 d-flex justify-content-between" *ngIf="basket">
      <button class="float-left btn btn-lg btn-dark" (click)="back()">
        {{ "BACK" | translate }}
      </button>
      <button
        class=" btn btn-lg btn-success"
        (click)="checkout()"
        [disabled]="
          !basket.ordering_allowed || !valid || loading ? 'disabled' : ''
        "
        [ngClass]="
          !basket.ordering_allowed || !valid || loading ? 'disabled' : ''
        "
      >
        {{ "SEND" | translate }}
      </button>
    </div>
  </ng-template>
</div>
