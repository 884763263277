import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { AgreementModelSelectionType } from "src/app/interfaces/yearAgreement/agreement-model-selection-type";
import { YearAgreementService } from "src/app/services/year-agreement.service";
import { AccountAgreement } from "src/app/interfaces/yearAgreement/account-agreement";
import { AgreementBikeModel } from "src/app/interfaces/yearAgreement/agreement-bike-model";
import { ApiResponse } from "src/app/interfaces/api-response";
import * as _ from "underscore";
import { YearAgreementDataModel } from "src/app/interfaces/yearAgreement/year-agreement-data-model";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-year-agreement-models-selection",
  templateUrl: "./year-agreement-models-selection.component.html",
  styleUrls: ["./year-agreement-models-selection.component.scss"],
})
export class YearAgreementModelsSelectionComponent implements OnInit {
  @Input() accountAgreement: AccountAgreement;
  @Input() modelType: string;
  @Input() selectedTab: string;
  @Input() affiliate: string;
  @Input() selectedAffiliate: string;
  @Input() index: number;
  @Input() changeTs: number;
  @Input() yearAgreementModel: YearAgreementDataModel;
  @Input() isMain: boolean = false;
  selectedYear: number = null;
  models: AgreementBikeModel[];
  selectedModels: AgreementBikeModel[];
  groupedModels = [];
  selectedBrands = [];
  pageIds = {
    focusmodels: "960422097",
    demomodels: "960422098",
  };

  constructor(
    private yearAgreementService: YearAgreementService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.selectedYear = this.accountAgreement.year;
    this.getModels();
    this.filterAvailableModels();
  }

  ngOnChanges(changes: SimpleChanges) {
    const tab =
      changes["selectedTab"] && changes["selectedTab"].currentValue
        ? changes["selectedTab"].currentValue
        : "";
    if (changes["selectedAffiliate"] && this.selectedTab === "affiliates") {
      this.filterAvailableModels();
    }

    if (changes["changeTs"] && this.selectedTab === "affiliates") {
      this.filterAvailableModels();
    }

    if (changes["selectedTab"] && tab === "affiliates") {
      this.filterAvailableModels();
    }

    if (
      changes["selectedTab"] &&
      (tab === "demomodels" || tab === "focusmodels")
    ) {
      this.filterAvailableModels();
    }

    if (changes["accountAgreement"]) {
      if (
        this.selectedYear &&
        this.selectedYear !== this.accountAgreement.year
      ) {
        this.getModels();
        this.filterAvailableModels();
        this.setSelection();
        this.selectedYear = this.accountAgreement.year;
      }
    }
  }

  getModels() {
    this.yearAgreementModel.disabled = true;
    this.yearAgreementService
      .getAgreementBikeModels(this.modelType, this.accountAgreement.year)
      .then((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.models = apiResponse.result;
          this.selectedModels = [...this.models];
          this.filterAvailableModels();
          this.setSelection();
          this.yearAgreementModel.disabled = false;
        }
      });
  }

  // Show only models based on the dealer brand selection
  filterAvailableModels() {
    const selectedBrands = _.pluck(
      Object.values(this.yearAgreementModel),
      "brands_affiliates"
    );

    const allBrands = _.flatten(
      selectedBrands.filter((b) => {
        return typeof b === "object";
      })
    );

    const affiliateBrands = allBrands.filter((b) => {
      return b.affiliate === this.affiliate;
    });

    if (affiliateBrands && affiliateBrands.length) {
      this.selectedBrands = _.pluck(affiliateBrands, "id");
    } else {
      this.selectedBrands = [];
    }
  }

  // Selected models
  setSelection() {
    this.selectedModels = [];
    const selectedIds =
      this.modelType === "focusmodels"
        ? _.pluck(
            this.accountAgreement.focus_model_selection.filter((m) => {
              return (this.isMain ? m.affiliate === "" : m.affiliate == this.affiliate);
            }),
            "id"
          )
        : _.pluck(
            this.accountAgreement.demo_model_selection.filter((m) => {
              return this.isMain
                ? m.affiliate === ""
                : m.affiliate == this.affiliate;
            }),
            "id"
          );

    if (this.models && this.models.length) {
      this.models.forEach((m: AgreementBikeModel) => {
        const model = { ...m };
        model.affiliate = (this.isMain ? "" : this.affiliate);
        model.selected = selectedIds.indexOf(model.id) >= 0;
        this.selectedModels.push(model);
      });
    }
  }

  // Save selection in main model
  selectionChanged() {
    const selection = [];
    selection.concat(
      (this.yearAgreementModel[this.modelType] = this.yearAgreementModel[
        this.modelType
      ].filter((m) => {
        return m.affiliate !== this.affiliate;
      }))
    );

    setTimeout(() => {
      const currentSelection = this.selectedModels.filter((m) => {
        return m.selected;
      });

      this.yearAgreementModel[this.modelType] =
        selection.concat(currentSelection);
    }, 0);
  }
}
