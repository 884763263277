<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'WISHLIST' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body pt-0 px-0">
  <div class="d-flex justify-content-between p-2">
  <a class="btn btn-primary m-1" (click)="modal.dismiss('Cross click')" *ngIf="preorderContainer.basketId"
    [routerLink]="'/' + preorderContainer.companyGroupCode + '/basket/' + preorderContainer.basketId">
    <i class="material-icons me-1" style="font-size: 18px; transform: translateY(2.5px);"> shopping_basket</i>
    {{
    'PREORDER_BASKET' |
    translate }}</a>
     <!-- <select
     [(ngModel)]="sorting"
     style="max-width: 220px"
     name="sorting"
     class="me-md-1 form-control sorting"
     (change)="getWishlist()"
   >
     <option value="3">{{ "PRICE" | translate }} 0-9</option>
     <option value="4">{{ "PRICE" | translate }} 9-0</option>
     <option value="8">
       {{ "MARKET_BASKET2ARTICLEID" | translate }}
     </option>
   </select> -->
  </div>
  <app-preorder-container #preorderContainer [articles]="articles" [type]="'wishlist'"></app-preorder-container>
  <div class="p-2 d-flex justify-content-center">
    <ngb-pagination class="searchPagination" *ngIf="collectionSize > 0" [collectionSize]="collectionSize"
      [(page)]="page" [boundaryLinks]="false" [pageSize]="pageSize" [maxSize]="8" [rotate]="true" [boundaryLinks]="true"
      (pageChange)="onPager($event)" [boundaryLinks]="true" [ellipses]="false">
    </ngb-pagination>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-dark" (click)="closeModal()">{{ 'CLOSE' | translate }}</button>
</div>
