import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Breadcrumb } from 'src/app/interfaces/bread-crumb';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: 'app-financial-page',
  templateUrl: './financial-page.component.html',
  styleUrls: ['./financial-page.component.scss'],
})
export class FinancialPageComponent implements OnInit {
  public type = 'openorders';
  breadcrumbs: Breadcrumb[];
  navigationSubscription: any;

  constructor(
    private activatedRouter: ActivatedRoute,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private router: Router
  ) {}

  ngOnInit() {

    this.activatedRouter.params.subscribe((params) => {
      if (params['type']) {
        this.type = params['type'];
      }
    });

    this.updateBreadcrumbs(this.type);

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.getType();
      }
    });
  }

  getType() {
    const url = window.location.href;

    // Parse old Accentry url
    if (url.indexOf('#type') > 0) {
      const type = url.split('/').pop().toLowerCase();

      this.type = type === 'openorderlines' ? 'openorders' : type;
    } else {
      this.activatedRouter.params.subscribe((params) => {
        if (params['type']) {
          this.type = params['type'];
        }
      });
    }
  }

  updateBreadcrumbs(selectedType) {
    // Set breadcrumbs
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant('HOME'),
        this.applicationService.getSelectCompanyGroupCode() + '/home'
      ),
      new Breadcrumb(this.translateService.instant('FINANCIAL'), '#'),
      new Breadcrumb(
        this.translateService.instant(selectedType.toUpperCase()),
        '#'
      ),
    ];
  }
}
