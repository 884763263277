import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SbImage } from "src/app/interfaces/storyblok/sb-image";
import { SbUrl } from "src/app/interfaces/storyblok/sb-url";
import { StoryblokService } from "src/app/services/storyblok.service";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.css"],
})
export class BannerComponent implements OnInit {
  @Input() _uid: string;
  @Input() component: any;
  @Input() image: SbImage;
  @Input() url: SbUrl;
  @Input() _editable: any;
  constructor(
    private router: Router,
    private storyblokService: StoryblokService
  ) {}

  ngOnInit(): void {}

  gotoUrl() {
    if (this.url?.url) {
      this.storyblokService.gotoUrl(this.url.url);
    }
  }
}
