import { Component, OnInit, Input } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BasketService } from "src/app/services/basket.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { BasketArticle } from "src/app/interfaces/basketArticle";
import * as _ from "underscore";
import { SearchService } from "src/app/services/search.service";
import { ApplicationService } from "src/app/services/application.service";
import { PreorderEditItem } from "src/app/interfaces/preorder/preorder-edit-item";
import { Subscription } from "rxjs";

@Component({
  selector: "app-edit-preorder-basket",
  templateUrl: "./edit-preorder-basket.component.html",
  styleUrls: ["./edit-preorder-basket.component.scss"]
})
export class EditPreorderBasketComponent implements OnInit {
  @Input() articles: Article[];
  @Input() basketArticles: PreorderEditItem[];
  type: string; // Wishlist or basket
  private _subscription: Subscription;
  constructor(
    public modal: NgbActiveModal,
    private basketService: BasketService,
    private searchService: SearchService,
    private applicationService: ApplicationService
  ) { }

  ngOnInit() {
    if (!this.basketArticles) {
      this.getModels();
    }

    this._subscription = this.basketService.changeEmitted$.subscribe(
      (response) => {
        this.getModels();
      })
  }

  ngOnDestroy() {
    if (typeof this._subscription !== 'undefined') {
      this._subscription.unsubscribe();
    }
  }

  showArticleDetails(data) { }

  getModels() {
    this.basketService
      .getPreorderBasketModels(this.type)
      .subscribe((apiResponse: ApiResponse) => {
        if (apiResponse && apiResponse.success) {
          const articles = apiResponse.result as PreorderEditItem[];
          this.basketArticles = articles;
          const temp = _.pluck(this.basketArticles, "search_result");
          this.applicationService.hideLoader();
          this.searchService.getNettPrices(temp);
        }
      });
  }

  getBasketArticles(articleCode: string, basketArticles: BasketArticle[]) {
    const selectedBasketArticles = _.findWhere(basketArticles, {
      article_code: articleCode
    });
    return selectedBasketArticles;
  }

  confirm() { }
}
