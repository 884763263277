import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Article } from "src/app/interfaces/article";
import { MatrixOption } from "src/app/interfaces/matrixOption";
import { AccountService } from "src/app/services/account.service";
import { ArticleService } from "src/app/services/article.service";
import { HelperService } from "src/app/services/helper.service";
import { ReplenishmentService } from "src/app/services/replenishment.service";
import { SearchService } from "src/app/services/search.service";

@Component({
  selector: "app-replenishment-inputs",
  templateUrl: "./replenishment-inputs.component.html",
  styleUrls: ["./replenishment-inputs.component.scss"],
})
export class ReplenishmentInputsComponent implements OnInit {
  @Input() article: Article;
  @Input() selectionByModel: boolean;
  @Output() inputChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  batteries: Article[] = [];
  loading = true;
  isInternal = this.accountService.isInternal();
  constructor(
    public replenishmentService: ReplenishmentService,
    private articleService: ArticleService,
    private helperService: HelperService,
    private searchService: SearchService,
    private accountService: AccountService
  ) {}

  ngOnInit() {}

  getNettPrice(articleCode: string) {
    const price = this.article.nett_prices.find((n) => {
      return n.single_article_code === articleCode;
    });
    return price ? price.net_price : null;
  }

  checkMinInput(optionArticle: Article) {
    if (optionArticle.min_dealer_stock > optionArticle.max_dealer_stock) {
      optionArticle.min_dealer_stock = optionArticle.max_dealer_stock;
    }
  }

  checkMaxInput(optionArticle: Article) {
    if (optionArticle.max_dealer_stock < optionArticle.min_dealer_stock) {
      optionArticle.max_dealer_stock = optionArticle.min_dealer_stock;
    }
  }

  getBatteries() {
    if (!this.batteries.length && this.article.has_mandatory_item) {
      this.replenishmentService.loading = true;
      this.loading = true;
      this.articleService
        .getBatteriesAndChargers(this.article.article_codes[0])
        .subscribe((apiResponse: ApiResponse) => {
          this.loading = false;
          if (this.helperService.checkResponse(apiResponse)) {
            this.batteries = apiResponse.result["Batteries"];
            this.searchService.getNettPrices(this.batteries);
            this.setBatterySelection();
          }
          this.replenishmentService.loading = false;
        });
    }
  }

  private setBatterySelection() {
    this.article.matrix.options.forEach((o) => {
      o.value.forEach((a) => {
        if (
          typeof this.replenishmentService.stockList[a.single_article_code] !==
          "undefined"
        ) {
          a.battery_article_code =
            this.replenishmentService.stockList[a.single_article_code][
              "battery_article_code"
            ];
        }
      });
    });
  }

  inputChange() {
    this.inputChanged.emit(true);
  }

  resetArticle() {

    this.article.matrix.options.forEach((option: MatrixOption) => {
      option.value.forEach((article: Article) => {
        article.max_dealer_stock = undefined;
        article.min_dealer_stock = undefined;
      })
    })
  }
}
