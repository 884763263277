import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ComponentFactoryResolver,
  ɵComponentType,
  ComponentFactory,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalContentDirective } from "src/app/directives/modal-content.directive";
import { NewsDetailsComponent } from "../../cms/news-details/news-details.component";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent implements OnInit {
  @ViewChild(ModalContentDirective) modalContentHost: ModalContentDirective;
  @Output() confirmClicked: EventEmitter<any> = new EventEmitter<any>();
  isConfirm: boolean;
  @Input() title: string;
  @Input() body: string;
  @Input() cancelText: string = "CANCEL_ACTION";
  @Input() confirmText: string = "CONFIRM";
  @Input() buttonText: string = "";
  @Input() footerText: string = "";
  @Input() buttonLink: string = "";
  inputSelector: string;
  component: any;
  data: any;

  constructor(
    public modal: NgbActiveModal,
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.showContent();
  }

  confirm() {
    this.confirmClicked.next(this.inputSelector ? this.inputSelector : true);
    this.modal.close();
  }

  setContent(component: any, inputSelector: string, data: any) {
    this.component = component;
    this.inputSelector = inputSelector;
    this.data = data;
  }

  showContent() {
    if (this.component) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(this.component);
      const viewContainerRef = this.modalContentHost.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
      componentRef.instance[this.inputSelector] = this.data;
      this.inputSelector = componentRef.instance[this.inputSelector];
    }
  }

  gotoLink() {
    this.router.navigateByUrl(this.buttonLink);
    this.modalService.dismissAll();
  }
}
