import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Article } from "../interfaces/article";
import { ArticleGroup } from "../interfaces/articleGroup";
import { BasketArticle } from "../interfaces/basketArticle";
import { Price } from "../interfaces/price";
import { SearchFilter } from "../interfaces/search-filter";
import { Campaign } from "../interfaces/tracking/campaign";
import { SearchType } from "../interfaces/tracking/searchAction";
import { AccountService } from "./account.service";
import { ApplicationService } from "./application.service";

@Injectable({
  providedIn: "root",
})
export class TrackingService {
  trackingEnabled = true;
  constructor(private accountService: AccountService, private applicationService: ApplicationService) {
    if (this.applicationService.isPartnerPortal()) {
      this.trackingEnabled = false;
    } else {
      this.trackingEnabled = true;
    }
  }

  trackPage(pageType: string) {
    if (this.trackingEnabled && window["dataLayer"]) {
      window["dataLayer"].push({
        event: "virtual_pageview",
        pageType: pageType,
        userId: localStorage.getItem("accountCode"),
        salesPerson: this.accountService.isHelpdesk() ? "Yes" : "No",
      });
      this.showLogging("trackPage");
    }
  }

  // When the user perform's a search from the searchbar
  searchAction(
    searchTerm: string,
    searchType: SearchType,
    numberOfResults?: number,
    itemId?: string,
    itemName?: string,
    itemBrand?: string,
    itemCategory?: string,
    categoryViewed?: string
  ) {
    if (this.trackingEnabled && window["dataLayer"]) {
      window["dataLayer"].push({
        event: "view_search_results",
        search_term: searchTerm,
        search_type: searchType,
        number_of_results: numberOfResults,
        item_id: itemId,
        item_name: itemName,
        item_brand: itemBrand,
        item_category: itemCategory,
        category_name: categoryViewed,
      });
      this.showLogging("searchAction");
    }
  }

  searchResults(
    articles: Array<Article>,
    companyGroupCode: string,
    groups?: ArticleGroup[],
    filters?: SearchFilter[]
  ) {
    if (this.trackingEnabled && window["dataLayer"] && typeof groups !== "undefined") {
      let selectedBrands = [];
      const brandFilters = filters.find((f) => {
        return f.id === "MD-MERK";
      });

      if (brandFilters) {
        const checkedBrands = brandFilters.options.filter((b) => {
          return b.value === "checked";
        });
        checkedBrands.forEach((b) => {
          const description = b.description.split(" ");
          description.pop();
          selectedBrands.push(description);
        });
      }

      window["dataLayer"].push({
        ecommerce: {
          impressions: this.getArticles(articles, companyGroupCode),
        },
        event: "ecomProductImpressions",
        filter_category_1:
          groups.length === 1 ? groups[0].description : undefined,
        filter_category_2:
          groups[0]?.article_groups?.length === 1
            ? groups[0].article_groups[0]?.description
            : undefined,
        filter_category_3:
          groups[0]?.article_groups && groups[0]?.article_groups[0]?.article_groups?.length === 1
            ? groups[0].article_groups[0].article_groups[0].description
            : undefined,
        filter_category_4:
          groups[0]?.article_groups && groups[0]?.article_groups[0]?.article_groups[0]?.article_groups
            .length === 1
            ? groups[0].article_groups[0]?.article_groups[0]?.article_groups[0]
              .description
            : undefined,
        filter_brands: selectedBrands.length
          ? selectedBrands.join(",")
          : undefined,
      });

      this.showLogging("searchResults");
    }
  }

  // When on article details page
  articleDetailsView(article: Article, groupsDescriptions: string) {
    if (this.trackingEnabled && window["dataLayer"]) {
      window["dataLayer"].push({
        ecommerce: {
          detail: {
            actionField: {},
            products: [
              {
                name: article.description,
                model_code: String(article.model_code),
                id: article.id,
                article_code: article.single_article_code,
                price: this.getPrice(article),
                brand: article.brand,
                category: groupsDescriptions,
              },
            ],
          },
        },
        event: "ecomProductDetailPage",
      });
      this.showLogging("articleDetailsView");
    }
  }

  // Click on a article card
  articleClick(article: Article, position: number) {
    if (this.trackingEnabled && window["dataLayer"]) {
      window["dataLayer"].push({
        ecommerce: {
          click: {
            actionField: {
              list:
                article.nested_groups &&
                  article.nested_groups.length &&
                  article.nested_groups[0].article_groups
                  ? article.nested_groups[0].article_groups.description
                  : "",
            },
            products: [
              {
                name: article.description,
                model_code: String(article.model_code),
                id: article.id,
                article_code: article.single_article_code,
                price: article.retail_price ? article.retail_price.value : null,
                brand: article.company_id,
                category: this.getCategory(article),
                position: position,
              },
            ],
          },
        },
        event: "ecomProductClick",
      });
      this.showLogging("articleClick");
    }
  }

  // Click on a recently article card
  recentlyArticleClick() {
    if (this.trackingEnabled && window["dataLayer"]) {
      window["dataLayer"].push({
        event: "articleClick",
        eventCategory: "Productcard",
        eventAction: "Click",
        eventLabel: "type:Recently viewed || pagetype:PDP",
      });
      this.showLogging("recentlyArticleClick");
    }
  }

  addToBasket(article: Article, quantity: number) {
    if (this.trackingEnabled && window["dataLayer"]) {
      window["dataLayer"].push({
        ecommerce: {
          add: {
            actionField: { list: "Sportfietsen" },
            products: [
              {
                name: article.description,
                model_code: article.model_code,
                id: article.id,
                article_code: article.single_article_code,
                price: this.getPrice(article),
                brand: article.brand,
                category: this.getCategory(article),
                variant: article.caption,
                quantity: quantity,
              },
            ],
          },
        },
        event: "ecomAddToCart",
      });
      this.showLogging("addToBasket");
    }
  }

  removeFromBasket(article: Article, quantity: number) {
    if (this.trackingEnabled && window["dataLayer"]) {
      window["dataLayer"].push({
        ecommerce: {
          remove: {
            products: [
              {
                name: article.description,
                model_code: article.model_code,
                id: article.id,
                article_code: article.single_article_code,
                price: this.getPrice(article),
                brand: article.brand,
                category: this.getCategory(article),
                variant: article.caption,
                quantity: quantity,
              },
            ],
          },
        },
        event: "ecomRemoveFromCart",
      });
      this.showLogging("removeFromBasket");
    }
  }

  // Track which items are in the checkout basket
  checkout(basketArticles: Array<BasketArticle>) {
    if (this.trackingEnabled && window["dataLayer"]) {
      const products: any[] = this.getBasketArticles(basketArticles);

      window["dataLayer"].push({
        ecommerce: {
          checkout: {
            actionField: { step: 1 },
            products: products,
          },
        },
        event: "ecomCheckout",
      });
      this.showLogging("checkout");
    }
  }

  // Track when a purchase is made
  purchase(
    basketId: string,
    companyGroupCode: string,
    basketArticles: Array<BasketArticle>,
    total: string,
    shippingCost: string
  ) {
    const products: any[] = this.getBasketArticles(basketArticles);
    window["dataLayer"].push({
      ecommerce: {
        purchase: {
          actionField: {
            id: basketId, // required - unique Transaction ID
            affiliation: companyGroupCode, // optional - for multiple 'stores' - Ghost,
            revenue: total, // required - (incl. tax and shipping)
            tax: "0.00", // optional - tax on total order
            shipping: shippingCost, // optional - shipping on total order
          },
          products: products,
        },
      },
      event: "ecomPurchase",
    });
    this.showLogging("purchase");
  }

  private getArticles(articles: Array<Article>, companyGroupCode: string) {
    const products = [];
    let count = 1;
    if (articles.length) {
      articles.forEach((article: Article) => {
        products.push({
          name: article.description,
          model_code: article.model_code,
          id: article.id,
          article_code: article.single_article_code,
          price: this.getPrice(article),
          brand: article.brand,
          category: "Search",
          list: `Search Results: ${companyGroupCode}`,
          position: count,
        });
        count++;
      });
    }
    return products;
  }

  private getPrice(article: Article) {
    if (article.net_price && article.net_price.value) {
      return article.net_price.value;
    } else {
      return "0";
    }
  }

  private getBasketArticles(basketArticles: Array<BasketArticle>) {
    const products = [];
    if (basketArticles && basketArticles.length) {
      basketArticles.forEach((article) => {
        if (article.item_details) {
          products.push({
            name: article.item_details.description,
            model_code: article.item_details.model_code,
            id: article.id,
            article_code: article.single_article_code,
            price: this.getPrice(article.item_details),
            brand: article.item_details.brand,
            category: this.getCategory(article.item_details),
            caption: article.item_details.caption,
            quantity: article.amount,
          });
        }
      });
      this.showLogging("getBasketArticles");
    }
    return products;
  }

  private getCategory(article: Article) {
    return article.nested_groups && article.nested_groups.length
      ? article.nested_groups[0].description
      : "";
  }

  // CRO tracking
  // Track favorite action
  // Action: Add / Remove
  favorite(article: Article, action: string) {
    if (this.trackingEnabled && window["dataLayer"]) {
      window["dataLayer"].push({
        event: "changeFavorite",
        eventCategory: "Favorites",
        eventAction: action,
        eventLabel: `name:${article.description} || id:${article.id}`,
      });
      this.showLogging("favorite");
    }
  }

  // Track which items are in the checkout basket
  // Action: Add / Remove
  hamburger(
    mainCat: string,
    subCat1?: string,
    subCat2?: string,
    subCat3?: string,
    subCat4?: string
  ) {
    if (this.trackingEnabled && window["dataLayer"]) {
      let subCategories = "";
      if (subCat1) {
        subCategories += ` || subCat1:${subCat1}`;
      }

      if (subCat2) {
        subCategories += ` || subCat1:${subCat2}`;
      }

      if (subCat3) {
        subCategories += ` || subCat1:${subCat3}`;
      }

      if (subCat4) {
        subCategories += ` || subCat1:${subCat4}`;
      }

      window["dataLayer"].push({
        event: "navigate",
        eventCategory: "navigation || hamburger",
        eventAction: "click",
        eventLabel: `mainCat:${mainCat}${subCategories}`,
      });
      this.showLogging("hamburger");
    }
  }

  // Click from autosuggest
  // type: item / category
  searchBar(type: string, searchTerm: string) {
    if (this.trackingEnabled && window["dataLayer"]) {
      window["dataLayer"].push({
        event: "search",
        eventCategory: "search",
        eventAction: "click",
        eventLabel: `type: ${type} || searchTerm:${searchTerm}`,
      });
      this.showLogging("searchBar");
    }
  }

  // Filter searchresults - select group
  filterSearchGroup(
    mainCat: string,
    subCat1?: string,
    subCat2?: string,
    subCat3?: string
  ) {
    if (this.trackingEnabled && window["dataLayer"]) {
      let subCategories = "";

      if (mainCat) {
        subCategories += ` || subCat1:${mainCat}`;
      }

      if (subCat1) {
        subCategories += ` || subCat2:${subCat1}`;
      }

      if (subCat2) {
        subCategories += ` || subCat3:${subCat2}`;
      }

      if (subCat3) {
        subCategories += ` || subCat4:${subCat3}`;
      }

      window["dataLayer"].push({
        event: "filter",
        eventCategory: "filter",
        eventAction: "click",
        eventLabel: `name:Groepen || mainCat:Alle groepen${subCategories}`,
      });
      this.showLogging("filterSearchGroup");
    }
  }

  // Filter by price
  filterSearchPrice(priceMin: number, priceMax: number) {
    if (this.trackingEnabled && window["dataLayer"]) {
      window["dataLayer"].push({
        event: "filter",
        eventCategory: "filter",
        eventAction: "click",
        eventLabel: `name:Price || value_min:${priceMin} || value_max:${priceMax}`,
      });
      this.showLogging("filterSearchPrice");
    }
  }

  // Filter by toggle
  filterSearchToggle(state: boolean, category: string, value: string) {
    if (this.trackingEnabled && window["dataLayer"]) {
      const trackData = {
        event: "filter",
        eventCategory: "filter",
        eventAction: `click || ${state ? "on" : "off"} `,
        eventLabel: `name:${category} || value:${value}`,
      };
      window["dataLayer"].push(trackData);
      this.showLogging("filterSearchToggle");
    }
  }

  // Change page
  // Type: next, prev and number
  searchPaginate(linkType: string, pageNumber: number) {
    if (this.trackingEnabled && window["dataLayer"]) {
      window["dataLayer"].push({
        event: "navigate",
        eventCategory: "navigation || pages",
        eventAction: "click",
        eventLabel: `type:${linkType} || value:${pageNumber}`,
      });
      this.showLogging("searchPaginate");
    }
  }

  startQrScanner() {
    if (this.trackingEnabled && window["dataLayer"]) {
      window["dataLayer"].push({
        event: "qr-scanner",
        eventCategory: "navigation || pages",
        eventAction: "click",
      });
      this.showLogging("startQrScanner");
    }
  }

  trackKogaRecallAvailable(framenumber: string, result: boolean) {
    if (this.trackingEnabled && window["dataLayer"]) {
      window["dataLayer"].push({
        event: "koga-recall-bike-valid",
        eventCategory: "other",
        eventAction: "check",
        eventLabel: `framenumber:${framenumber} || bikeValid:${result}`,
      });
      this.showLogging("startQrScanner");
    }
  }

  // Click or view campaign
  // action: promoView / promoClick
  campaign(action: string, promotions: Campaign[]) {
    if (this.trackingEnabled && window["dataLayer"]) {
      const data = {};
      data["ecommerce"] = {};
      data["ecommerce"][`${action}`] = {
        promotions: promotions,
      };
      data["event"] = `ecom${action}`;
      window["dataLayer"].push(data);
      this.showLogging("campaign");
    }
  }

  easterEgg() {
    if (this.trackingEnabled && window["dataLayer"]) {
      const data = {};
      data["event"] = `easterEgg`;
      window["dataLayer"].push(data);
      this.showLogging("easterEgg");
    }
  }

  view3d(article: Article) {
    if (this.trackingEnabled && window["dataLayer"]) {
      window["dataLayer"].push({
        event: "view3d",
        eventCategory: "other",
        eventLabel: `modelId:${article.id} || articleCode:${article.single_article_code ?? ''}`,
      });
      this.showLogging("view3d");
    }
  }

  showLogging(functionName: string) {
    if (environment.name === "development") {
      console.log(functionName);
      console.log(window["dataLayer"][window["dataLayer"].length - 1]);
    }
  }


  // Track e-commerce article clicks
  trackCommerce(article: Article, commerceType: string, position: number) {
    if (this.trackingEnabled && window["dataLayer"]) {
      const category = document.querySelectorAll('.breadcrumb-item:not(:first-child):not(:last-child)');
      const categoryText = Array.from(category).map(item => item.querySelector('a')?.textContent.trim() || '').join(' > ');
      window["dataLayer"].push({
        ecommerce: {
          click: {
            actionField: {
              list: commerceType === "alternatives" ? "Out of stock alternative" : "Cross-sell article"
            },
            products: [{
              name: `${article.description} ${article.caption??''}`,
              model_code: article.model_code,
              id: article.id,
              article_code: article.single_article_code,
              price: article.net_price?.value,
              brand: article.company_id,
              category: categoryText,
              position: position
            }]
          },
        },
        event: "ecomProductClick"
      });
    }
  }

  // Directly add to basket
  trackCommerceAddToBasket(article: Article, commerceType: string, position: number) {
    if (this.trackingEnabled && window["dataLayer"]) {
      const category = document.querySelectorAll('.breadcrumb-item:not(:first-child):not(:last-child)');
      const categoryText = Array.from(category).map(item => item.querySelector('a')?.textContent.trim() || '').join(' > ');
      window["dataLayer"].push({
        ecommerce: {
          add: {
            actionField: {
              list: commerceType === "alternatives" ? "Out of stock alternative" : "Cross-sell article"
            },
            products: [{
              name: `${article.description} ${article.caption??''}`,
              model_code: article.model_code,
              id: article.id,
              article_code: article.single_article_code,
              price: article.net_price?.value,
              brand: article.company_id,
              category: categoryText,
              position: position
            }]
          },
        },
        event: "ecomAddToCart"
      });
    }
  }
}
