import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { PackingSlipsSelectedFilters } from "src/app/interfaces/financial/packingslips/packingslips-selected-filters";
import { PackingSlipsFilterOptions } from "src/app/interfaces/financial/packingslips/packingslips-filter-options";
import { ShippingAddressGroup } from "src/app/interfaces/shipping-address-group";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from "@angular/router";
import { ApplicationService } from "src/app/services/application.service";
import * as moment from "moment";

@Component({
  selector: "app-packing-slips-filters",
  templateUrl: "./packing-slips-filters.component.html",
  styleUrls: ["./packing-slips-filters.component.scss"]
})
export class PackingSlipsFiltersComponent implements OnInit {
  @Output() searchSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Input() shippingAddressGroup: ShippingAddressGroup;
  filters: PackingSlipsSelectedFilters;
  filterOptions: PackingSlipsFilterOptions;
  fromDate: NgbDate;
  toDate: NgbDate;
  maxDate: Date;
  constructor(public applicationService: ApplicationService, private activatedRouter: ActivatedRoute) {
    this.filters = new PackingSlipsSelectedFilters(
      "all",
      "all",
      "",
      "",
      "PackSlipNumber"
    );
    this.filterOptions = new PackingSlipsFilterOptions();
    this.maxDate = new Date();
    this.defaultDateRange()
  }

  ngOnInit() {
    let getResults = false;
    this.activatedRouter.params.subscribe(params => {
      if (params["id"] && params["type"] === "packingslips" && this.filters.keyWord !== params["id"]) {
        this.filters.keyWord = params["id"];
        getResults = true;
      }
    });
    this.searchSubmitted.next({ filters: this.filters, getResults: getResults });

    if(this.applicationService.getSelectCompanyGroupCode() == 'WG') {
      this.filterOptions.keyWordOptions = this.filterOptions.keyWordOptions.filter(option => option.description === 'PACKINGSLIP_NUMBER')
    }
  }

  submit() {
    this.searchSubmitted.next({ filters: this.filters, getResults: true });
  }

  defaultDateRange() {
    // 4 months prior to now
    this.filters['date_from'] = {
      "year": parseInt(moment().subtract(4, 'months').format('YYYY')),
      "month": parseInt(moment().subtract(4, 'months').format('MM')),
      "day": parseInt(moment().subtract(4, 'months').format('DD'))
    };

    // Current date
    this.filters['date_to'] = {
      "year": parseInt(moment().format('YYYY')),
      "month": parseInt(moment().format('MM')),
      "day": parseInt(moment().format('DD'))
    };
  }

  resetFilters() {
    this.filters = new PackingSlipsSelectedFilters(
      "all",
      "all",
      "",
      "",
      "PackSlipNumber"
    );
    this.defaultDateRange()
    this.submit();
  }

  setShippingAddress(shippingAddressId: string) {
    this.filters["shippingAddress"] = shippingAddressId;
  }
}
