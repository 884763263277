
<svg enable-background="new 0 0 231.488 163.551" version="1.1" viewBox="0 0 231.488 163.551" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
	<path d="M21,144v-144m4,144v-144m6,133.2v-133.2" fill="none" stroke="#FFF" stroke-miterlimit="10" stroke-width="1.7"/>
	<line fill="none" stroke="#FFF" stroke-miterlimit="10" stroke-width="3.7" x1="38" x2="38" y1="133.2" y2="0"/>
	<line fill="none" stroke="#FFF" stroke-miterlimit="10" stroke-width="7.7" x1="46" x2="46" y1="133.2" y2="0"/>
	<line fill="none" stroke="#FFF" stroke-miterlimit="10" stroke-width="1.7" x1="53" x2="53" y1="133.2" y2="0"/>
	<line fill="none" stroke="#FFF" stroke-miterlimit="10" stroke-width="5.7" x1="61" x2="61" y1="133.2" y2="0"/>
	<line fill="none" stroke="#FFF" stroke-miterlimit="10" stroke-width="1.7" x1="67" x2="67" y1="133.2" y2="0"/>
	<line fill="none" stroke="#FFF" stroke-miterlimit="10" stroke-width="3.7" x1="72" x2="72" y1="133.2" y2="0"/>
	<path d="M81,133.2v-133.2m10,133.2v-133.2m4,133.2v-133.2m6,133.2v-133.2m8,133.2v-133.2m4,144v-144m4,144v-144m4,133.2v-133.2m6,133.2v-133.2" fill="none" stroke="#FFF" stroke-miterlimit="10" stroke-width="1.7"/>
	<line fill="none" stroke="#FFF" stroke-miterlimit="10" stroke-width="5.7" x1="137" x2="137" y1="133.2" y2="0"/>
	<line fill="none" stroke="#FFF" stroke-miterlimit="10" stroke-width="1.7" x1="143" x2="143" y1="133.2" y2="0"/>
	<line fill="none" stroke="#FFF" stroke-miterlimit="10" stroke-width="5.7" x1="151" x2="151" y1="133.2" y2="0"/>
	<line fill="none" stroke="#FFF" stroke-miterlimit="10" stroke-width="1.7" x1="159" x2="159" y1="133.2" y2="0"/>

</svg>
