<button class="btn btn-default d-inline-flex align-items-center me-1 leftIcon" (click)="goBack()"><i class="material-icons me-1" style="font-size: 20px;">
    search
  </i><b class="me-2">{{'BACK' | translate}}</b></button><button class="btn btn-default d-inline-flex align-items-center mx-1 leftIcon arrow" (click)="prevArticle()"
  [ngClass]="selectedIndex === 0 && page === 1 ? 'disabled' : ''" [disabled]="selectedIndex === 0 && page === 1"><i
    class="material-icons">
    keyboard_arrow_left
  </i><span>{{'PREVIOUS' | translate}}</span></button>
<button class="btn btn-default d-inline-flex align-items-center rightIcon arrow" (click)="nextArticle()"><span>{{'NEXT' | translate}}</span><i
    class="material-icons">
    keyboard_arrow_right
  </i></button>
