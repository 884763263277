<ng-container *ngIf="showCounter">
  <button class="d-flex flex-column align-items-center btn iconWithBadge pe-0 pb-2 me-2 me-sm-4 position-relative" placement="bottom"  [@inAnimation]>
    <img class="clickable withOpacity" [src]="compact ? './assets/images/juncker-loyalty-box.png' : 'https://a.storyblok.com/f/118996/66x65/0b8d525b9f/juncker_plus_doosje_fc.png'"  [style.width]="compact ? '24px' : ''" [ngbPopover]="popContent"
      [popoverTitle]="popTitle" (shown)="refreshPoints()">
      <small class="position-absolute count d-none d-sm-block" *ngIf="compact">{{pointsText}}</small>
      <h3 *ngIf="!compact">{{pointsText}}</h3>
  </button>
  <ng-template #popContent>{{pointsText}}.
    <button class="btn btn-primary mt-2" [routerLink]="'/ANL/juncker-plus'">{{"JUNCKER_LOYALTY_TOOLTIP_BUTTON"|translate}}</button>
  </ng-template>
  <ng-template #popTitle><b>{{"JUNCKER_LOYALTY_TOOLTIP_TITLE"|translate}}</b></ng-template>
</ng-container>
