<div class="mb-3" [formGroup]="formGroup">
  <input type="hidden" formControlName="fileUrl">
  <input type="file" class="form-control-file" id="uploadFile" formControlName="uploadFile" accept="application/pdf, image/jpeg, image/png"
    (change)="fileChange($event)"><br />
  (jpg, png, pdf)
  <ng-container *ngIf="formGroup.get('fileUrl').value">
    <u class="text-underline text-primary clickable withOpacity" (click)="downloadFile()">
      <i class="material-icons">attach_file</i>
      {{formGroup.get("fileUrl").value}}
    </u>
  </ng-container>
</div>
