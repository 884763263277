import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { FinancialService } from "src/app/services/financial.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { ShippingAddressGroup } from "src/app/interfaces/shipping-address-group";
import { OrderLineOptionInput } from "src/app/interfaces/financial/openorders/order-line-option-input";
import { OpenOrdersFilters } from "src/app/interfaces/financial/openorders/open-orders-filters";
import { OrderLine } from "src/app/interfaces/financial/openorders/order-line";
import { Observable, Subject, Subscription } from "rxjs";
import { FinancialPaginatorData } from "src/app/interfaces/financial/financial-paginator-data";
import * as _ from "underscore";
import { SearchService } from "src/app/services/search.service";
import { ApplicationService } from "src/app/services/application.service";
import { HelperService } from "src/app/services/helper.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-open-orders",
  templateUrl: "./open-orders.component.html",
  styleUrls: ["./open-orders.component.scss"],
})
export class OpenOrdersComponent implements OnInit {
  @Input() type: string;
  cachedResults: any = {};
  results: OrderLine[] = [];
  allResults: OrderLine[] = [];
  openOrdersFilters: OpenOrdersFilters;
  articleData: any = {};
  loaded = false;
  initial = true;
  @Input() shippingAddressGroup: ShippingAddressGroup;
  pageData: FinancialPaginatorData;
  @Input() navigationObserver: Observable<string>;
  page = 1;
  pageIncrement = 0;
  subscription: Subscription;
  keyWord: any;
  showIndex = 100;
  resultsPerPage = 100;
  totalPages: number;

  constructor(
    private financialService: FinancialService,
    private searchService: SearchService,
    public applicationService: ApplicationService,
    private helperService: HelperService,
    private route: ActivatedRoute
  ) {
    this.pageData = new FinancialPaginatorData();
    this.pageData.page = 1;
    this.openOrdersFilters = new OpenOrdersFilters();
    this.openOrdersFilters.options = new OrderLineOptionInput();
    this.openOrdersFilters.options.article_type = "all";
    this.openOrdersFilters.options.order_by = "deliveryweek";
  }

  ngOnInit() {
    this.subscription = this.financialService.changeEmitted$.subscribe(() => {
      this.getResults(true);
    });
    this.showIndex =
      this.applicationService.checkIfDealerHasCompany(102) &&
      this.applicationService.checkIfDealerHasCompany(101)
        ? 200
        : 100;
    this.resultsPerPage = this.showIndex;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getFilters(data) {
    this.cachedResults = {};
    const filters = data["filters"];
    this.openOrdersFilters.options = new OrderLineOptionInput();

    // Keyword
    if (filters["keyWord"]) {
      switch (filters["keyWordOption"]) {
        case "Description": {
          this.openOrdersFilters.options.article_description =
            filters["keyWord"];
          break;
        }
        case "ArticleCode": {
          this.openOrdersFilters.options.article_code = filters["keyWord"];
          break;
        }

        case "Id": {
          this.openOrdersFilters.options.ace_id = filters["keyWord"];
          break;
        }

        case "OrderNumber": {
          this.openOrdersFilters.options.ace_id = filters["keyWord"];
          break;
        }

        case "Remarks": {
          this.openOrdersFilters.options.remarks = filters["keyWord"];
          break;
        }
      }
    } else {
      const orderNo = this.route.snapshot.queryParamMap.get("orderNo");
      if (orderNo) {
        this.openOrdersFilters.options.ace_id = orderNo;
      }
      const articleCode = this.route.snapshot.queryParamMap.get("articleCode");
      if (articleCode) {
        this.openOrdersFilters.options.article_code = articleCode;
        filters["keyWordOption"] === "ArticleCode";
      }
    }

    // Winora company filter
    this.openOrdersFilters.options.company_id_filter = Number(
      filters["companyFilter"]
    )
      ? Number(filters["companyFilter"])
      : null;
    // Keyword
    this.keyWord = filters["keyWord"].toLowerCase();
    // OpenOrderStatus
    this.openOrdersFilters.options.order_line_status =
      filters["openOrderStatus"];
    // Order by
    this.openOrdersFilters.options.order_by = "deliveryweek";
    // Only new
    this.openOrdersFilters.options.only_new_order_lines = filters["onlyNew"];
    // Sold
    this.openOrdersFilters.options.sold =
      filters["sold"] !== "all" ? filters["sold"] : "";
    // Article Type
    this.openOrdersFilters.options.article_type = filters["articleType"];

    // Shipping Id
    this.openOrdersFilters.options.shipping_id = filters["shippingAddress"];

    // Shipping type
    if (
      this.shippingAddressGroup &&
      this.shippingAddressGroup.shipping_addresses
    ) {
      if (!this.openOrdersFilters.options.shipping_id) {
        this.openOrdersFilters.options.shipping_id = String(
          this.shippingAddressGroup.selected
        );
      }

      let selected = this.shippingAddressGroup.shipping_addresses.find((s) => {
        return (
          Number(s.id) === Number(this.openOrdersFilters.options.shipping_id)
        );
      });

      if (selected) {
        this.openOrdersFilters.options.shipping_type = selected.address_type;
      }
    }

    // CompanyId
    this.openOrdersFilters.options.company_id = filters["companyFilter"];

    // OrderDate
    this.openOrdersFilters.options.date_from =
      typeof filters.orderDate !== "string"
        ? this.financialService.getDate(filters.orderDate)
        : this.financialService.formatDate(filters.orderDate);

    this.page = 1;
    this.pageData.page = this.page;
    this.openOrdersFilters.options.page = this.page;
    this.openOrdersFilters.next_page_reference = "";

    // Filter results (WG only)
    if (
      this.allResults &&
      filters["keyWord"] &&
      (this.applicationService.getSelectCompanyGroupCode() === "WG" ||
        filters["keyWordOption"] === "OrderNumber")
    ) {
      this.getResults(true).then(() => {
        let filtered = [];

        filtered = this.allResults.filter((i: OrderLine) => {
          if (
            (filters["keyWordOption"] === "Description" &&
              i.article_description.toLowerCase().indexOf(this.keyWord) >= 0) ||
            (filters["keyWordOption"] === "ArticleCode" &&
              i.article_code.toLowerCase().indexOf(this.keyWord) >= 0) ||
            (filters["keyWordOption"] === "OrderNumber" &&
              i.erp_id.toLowerCase().indexOf(this.keyWord) >= 0)
          ) {
            return true;
          }
        });
        this.results = filtered;
        if (
          !this.results.length &&
          this.applicationService.getSelectCompanyGroupCode() !== "WG"
        ) {
          this.getResults();
        }
      });
    } else {
      if (
        data["getResults"] ||
        (this.results && !this.results.length) ||
        !this.results
      ) {
        this.getResults();
      }
    }
  }

  // Get open order lines
  getResults(reload?: boolean) {
    if (
      this.applicationService.getSelectCompanyGroupCode() === "WG" &&
      this.keyWord &&
      this.allResults.length
    ) {
      return new Promise((resolve, reject) => {
        this.totalPages = 1;
        resolve(true);
      });
    }
    return new Promise((resolve, reject) => {
      this.loaded = false;
      if (this.cachedResults[this.pageData.page] && reload === false) {
        this.results = this.cachedResults[this.pageData.page];
        if (this.cachedResults[this.pageData.page + 1]) {
          this.pageData.hasNextPage = true;
        }
        this.pageData.loading = false;
        resolve(true);
      } else {
        this.applicationService.showLoader(true);
        if (!this.keyWord) {
          this.openOrdersFilters.options.page = this.page;
        } else {
          this.openOrdersFilters.options.page = null;
        }
        this.financialService
          .getOpenOrders(this.openOrdersFilters)
          .subscribe((apiResponse: ApiResponse) => {
            this.loaded = true;
            this.initial = false;

            this.applicationService.hideLoader();
            if (
              apiResponse &&
              apiResponse.result &&
              apiResponse.result.items &&
              apiResponse.result.items.length
            ) {
              this.pageData.acePreviousPage =
                this.results && this.results.length
                  ? this.results[0].line_id
                  : "0";

              const items = typeof apiResponse.result !== "undefined" ? apiResponse.result["items"] : []
              this.totalPages = apiResponse.result["total_pages"];

              if (
                this.applicationService.getSelectCompanyGroupCode() === "WG" &&
                this.page > 1
              ) {
                this.results = this.results.concat(items);
              } else {
                this.results = items;

                if (
                  this.applicationService.getSelectCompanyGroupCode() !== "WG"
                ) {
                  this.allResults = items;
                } else if (this.openOrdersFilters.options.page === null) {
                  this.allResults = items;
                }
              }

              this.cachedResults[this.pageData.page] = items;
              this.pageData.aceCurrentPage =
                this.pageData.page === 1
                  ? "0"
                  : this.cachedResults[this.pageData.page - 1][
                      this.cachedResults[this.pageData.page - 1].length - 1
                    ].line_id;

              this.getArticleData();
              this.pageData.numResults.openorders.total_bicycles =
                apiResponse.result.total_bicycles;
              this.pageData.numResults.openorders.total_parts =
                apiResponse.result.total_parts;
              this.pageData.numResults.openorders.total_service =
                apiResponse.result.total_parts;

              this.pageData.numResults.openorders.total_bicycles_lines =
                apiResponse.result.total_bicycles_lines;
              this.pageData.numResults.openorders.total_parts_lines =
                apiResponse.result.total_parts_lines;
              this.pageData.numResults.openorders.total_service_lines =
                apiResponse.result.total_service_lines;

              // Page references
              this.pageData.hasNextPage = apiResponse.result["ace_next_page"]
                ? true
                : false;

              this.pageData.aceNextPage = apiResponse.result["ace_next_page"]
                ? this.results[this.results.length - 1].line_id
                : null;

              resolve(true);

              // Scroll to top (when load more is not used)
              if (
                this.applicationService.getSelectCompanyGroupCode() !== "WG"
              ) {
                window.scrollTo(0, 0);
              }
            } else {
              this.results = [];
              resolve(true);
            }
            this.pageData.loading = false;
          });
      }
    });
  }

  // Get article details of each article of the order line
  getArticleData() {
    const codes = _.pluck(this.results, "article_code");
    const codesOnPage = codes.slice(this.showIndex - this.resultsPerPage, this.showIndex)
    if (codesOnPage.length) {
      this.searchService
      .getArticlesByCodes(
        codesOnPage
      )
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          const articles = apiResponse.result;
          this.results.forEach((item) => {
            const article = articles[item.article_code];
            if (article) {
              if (article.thumbs && article.thumbs.length) {
                item.image = articles[item.article_code].thumbs[0];
              }
              article.hide_nett_price = true;
              item.article = article;
            }
          });
        }
      });
    }
  }

  navigationChanged(data) {
    this.openOrdersFilters.next_page_reference = data;
    this.getResults();
  }

  showMore() {
    this.showIndex += this.resultsPerPage;
    if (this.page < this.totalPages && !this.keyWord) {
      this.page++;
      this.getResults(true).then(() => {
        this.getArticleData();
      });
    } else {
      this.getArticleData();
    }
  }
}
