import { Component } from '@angular/core';

@Component({
  selector: 'app-basket-skeleton-loader',
  templateUrl: './basket-skeleton-loader.component.html',
  styleUrls: ['./basket-skeleton-loader.component.scss']
})
export class BasketSkeletonLoaderComponent {

}
