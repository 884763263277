<div class="dynamic-content" [appStoryblok]="_editable" [ngStyle]="{'background-color': background_color && !transparent_background ? background_color.color : 'transparent', 'color': text_color?.color, 'grid-template-columns': 'repeat(' + max_blocks_per_row + ', 1fr)', 'row-gap': row_gap + 'px', 'column-gap': column_gap + 'px', 'border': border}">
  <div *ngFor="let blok of text" class="col-12" >
    <ndc-dynamic
      *ngIf="blok.component"
      [ndcDynamicComponent]="components[blok.component]"
      [ndcDynamicInputs]="blok"
    >
    </ndc-dynamic>
  </div>
</div>
