import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { BomGroup } from "src/app/interfaces/bom-group";

@Component({
  selector: "app-select-group",
  templateUrl: "./select-group.component.html",
  styleUrls: ["./select-group.component.scss"]
})
export class SelectGroupComponent implements OnInit {
  @Output() groupSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() availableGroups: BomGroup[];
  @Input() selectedGroup: string;

  constructor() {}

  ngOnInit() {}

  selectGroup(data) {
    this.groupSelected.emit(this.selectedGroup);
  }
}
