import { Component, OnInit } from "@angular/core";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import { AuthenticateService } from "src/app/services/authenticate.service";

@Component({
  selector: "app-not-implemented-page",
  templateUrl: "./not-implemented-page.component.html",
  styleUrls: ["./not-implemented-page.component.scss"]
})
export class NotImplementedPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];

  constructor(
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private authenticateService: AuthenticateService
  ) {}

  ngOnInit() {
    this.setBreadcrumbs();
  }

  setBreadcrumbs() {
    // Set breadcrumbs
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("NOT_IMPLEMENTED"),
        this.applicationService.getSelectCompanyGroupCode() + "/notImplemented"
      )
    ];
  }

  gotoOld() {
    this.authenticateService
      .connectToClassic(true);
  }
}
