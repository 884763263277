import { SearchFilter } from "./search-filter";
import { PriceRange } from "./price-range";
import { Article } from "./article";

export interface SearchResults {
  searchtext: string;
  company: number;
  groups: any[];
  bill_of_material: boolean;
  pagesize: number;
  page: number;
  count: number;
  sortcolumn: number;
  results: Article[];
  filters: SearchFilter[];
  pricerange: PriceRange;
}

export class SearchResults implements SearchResults {
  constructor(input: any) {
    this.searchtext = input.searchtext;
    this.company = input.company;
    this.groups = input.groups;
    this.bill_of_material = input.bill_of_material;
    this.pagesize = input.pagesize;
    this.page = input.page;
    this.count = input.count;
    this.sortcolumn = input.sortcolumn;
    this.results = Object.keys(input.results).map(key => input.results[key]);
    this.filters = input.filters;
    this.pricerange = input.pricerange;
  }
}
