<ng-container *ngIf="dealerStockLinkSettings?.has_stock_link">
  <div class="row mx-0">
    <div class="col-12 bg-gray">
      <p class="mt-3" [innerHTML]="'DEALER_LINK_INTRO_TEXT' | translate"></p>
      <div class="my-5" *ngIf="dealerStockLinkSettings.can_create_token">
        <p>{{ "CREATE_DEALER_LINK_TOKEN_TEXT" | translate }}
          <br />
          {{"HAS_GENERATED_TOKEN" | translate}}: {{(dealerStockLinkSettings.has_api_key?"YES":"NO")|translate}}
        </p>
        <button class="d-block btn btn-primary" (click)="createDealerStockLinkToken()">
          {{ "CREATE_DEALER_LINK_TOKEN_TEXT" | translate }}
        </button>
      </div>
      <div class="my-5" *ngIf="dealerStockLinkSettings.can_download_stock_data">
        <p>{{ "DEALER_LINK_DOWNLOAD_STOCK_DATA" | translate }}<br />
          {{"STOCK_COUNT" | translate}}: {{dealerStockLinkSettings.stock_count}}
        </p>
        <button class="d-block btn btn-primary" (click)="downloadStockData()">
          {{ "DOWNLOAD" | translate }}
        </button>
      </div>
      <div class="mt-5" *ngIf="dealerStockLinkSettings.can_create_token && !dealerStockLinkSettings.activated">
        <ngb-alert type="warning" [dismissible]="false">{{ "DEALER_LINK_ACTIVATE_TEXT" | translate }}
          <button class="d-block btn btn-success mt-2" [disabled]="!dealerStockLinkSettings.can_be_activated"
            [class.disabled]="!dealerStockLinkSettings.can_be_activated" (click)="activate()">
            {{ "ACTIVATE" | translate }}
          </button>
        </ngb-alert>
      </div>
      <div class="mt-5" *ngIf="dealerStockLinkSettings.activated">
        <ngb-alert type="warning" [dismissible]="false">{{ "DEALER_LINK_ACTIVE" | translate }}</ngb-alert>
      </div>
    </div>
  </div>
</ng-container>
<span
*ngIf="loading"
class="spinner-border spinner-border-sm"
role="status"
aria-hidden="true"
></span>
