import { Component, OnInit, Input, ElementRef } from "@angular/core";
import { SlideshowLanguageItem } from "src/app/interfaces/cms/slideshow-language-item";
import { DomSanitizer } from "@angular/platform-browser";
import { StoryblokService } from "src/app/services/storyblok.service";

@Component({
  selector: "app-slide",
  templateUrl: "./slide.component.html",
  styleUrls: ["./slide.component.scss"],
})
export class SlideComponent implements OnInit {
  iframe_html: any;
  @Input() slide: SlideshowLanguageItem;
  isVideo: boolean;
  video_url: any;
  html: boolean;
  text: any;
  constructor(private sanitizer: DomSanitizer, private elem: ElementRef, private storyblokService: StoryblokService) {}

  ngOnInit() {
    this.setContent();
  }

  setContent() {
    if (this.slide?.image_url) {
      const url = this.slide.image_url.toLowerCase();
      if (url.includes("youtube")) {
        this.isVideo = true;
        this.video_url = `https://www.youtube.com/embed/${this.youtube_parser(
          this.slide.image_url
        )}`;
      } else if (url.includes("vimeo")) {
        this.isVideo = true;
        this.video_url = `https://player.vimeo.com/video/${this.vimeo_parser(
          this.slide.image_url
        )}`;
      } else {
        this.isVideo = false;
      }
    }
  }

  getSafeUrl() {
    return this.sanitizer.bypassSecurityTrustUrl(this.slide.image_url);
  }

  youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  vimeo_parser(url) {
    var regExp =
      /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    var match = url.match(regExp);
    return match[3];
  }
}
