import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Article } from 'src/app/interfaces/article';

@Component({
  selector: 'app-inline-matrix',
  templateUrl: './inline-matrix.component.html',
  styleUrls: ['./inline-matrix.component.scss']
})
export class InlineMatrixComponent implements OnInit {
  @Input() options: any;
  @Input() article: Article;
  @Output() articleSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {

  }

  selectArticle(selectedArticle) {
    this.article = Object.assign(this.article, selectedArticle.article);
   // this.articleSelected.emit(this.article);
  }

}
