<div class="container">
  <div class="row">
    <div class="col-md-12 px-3" *ngIf="accountType === 'INT' || accountType === 'HLP'">
      <br />
      <h1>{{'DISCOUNT_SHEET' | translate}}</h1>
      <div class="row">
        <ng-container *ngFor="let company of companies">
          <!-- WS -->
          <div class="col-12" *ngIf="company.companyID === 'WS' && router.url.includes('WS')">
            <h2>Winora-Staiger</h2>
            <div class="d-block mb-2">
              <button class="btn btn-dark mb-3" (click)="downloadPdf('WS')">{{'DOWNLOAD_PDF' | translate}}</button>
            </div>
            <div class="d-flex w-100">
              <pdf-viewer *ngIf="company.fileURL" [src]="company.fileURL" class="border border-dark w-100"></pdf-viewer>
            </div>
          </div>
          <!-- WBP -->
          <div class="col-12" *ngIf="company.companyID === 'WBP' && router.url.includes('WBP')">
            <h2>E. Wiener Bike Parts </h2>
            <div class="d-block mb-2">
              <button class="btn btn-dark mb-3" (click)="downloadPdf('WBP')">{{'DOWNLOAD_PDF' | translate}}</button>
            </div>
            <div class="d-flex w-100">
              <pdf-viewer *ngIf="company.fileURL" [src]="company.fileURL" class="border border-dark w-100"></pdf-viewer>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
