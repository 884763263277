<div class="modal-header">
  <h4
    class="modal-title"
    id="modal-basic-title"
    [innerHTML]="
      article.description + ' ' + (article.caption ? article.caption : '')
    "
  ></h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    </button>
</div>
<div class="modal-body">
  <app-article-details
    #details
    [(modelId)]="modelId"
    [showDetailed]="showDetailed"
    [showInModal]="true"
    [showQrCode]="true"
    style="margin-top: 0"
  >
</app-article-details>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-dark" (click)="modal.dismiss('Close')">
    {{ "CLOSE" | translate }}
  </button>
</div>
