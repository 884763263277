import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import { AccountService } from 'src/app/services/account.service';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { MainDealerInfo } from 'src/app/interfaces/mainDealerInfo';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-contact-details-page',
  templateUrl: './contact-details-page.component.html',
  styleUrls: ['./contact-details-page.component.scss']
})
export class ContactDetailsPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  dealer: MainDealerInfo;

  constructor(
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private accountService: AccountService,
    private helperService: HelperService
  ) {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/home"
      ),
      new Breadcrumb(this.translateService.instant("CONTACTDATA"), "#"),
    ];
  }

  ngOnInit() {
    this.getMainDealerInfo()
  }
  getMainDealerInfo() {
    this.accountService
    .getMainDealerInfo()
    .subscribe((apiResponse: ApiResponse) => {
      if (this.helperService.checkResponse(apiResponse)) {
        this.dealer = apiResponse.result;
      }
    });
  }
}
