import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { AccountInformation } from 'src/app/interfaces/account-information';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { DropshipmentAddress } from 'src/app/interfaces/dropshipment/dropshipment-address';
import { DropshipmentConsumer } from 'src/app/interfaces/dropshipment/dropshipment-consumer';
import { ZipLocation } from 'src/app/interfaces/zip-location';
import { AccountService } from 'src/app/services/account.service';
import { ApplicationService } from 'src/app/services/application.service';
import { GeneralService } from 'src/app/services/general.service';
import { HelperService } from 'src/app/services/helper.service';
import * as appGlobal from 'src/app/globals';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InputSwitchModule } from 'primeng/inputswitch';
import { Breadcrumb } from 'src/app/interfaces/bread-crumb';
import { PartnerPortalBreadcrumbsComponent } from 'src/app/components/partner-portal/partner-portal-breadcrumbs/partner-portal-breadcrumbs.component';
import { PersonalAddress } from 'src/app/interfaces/partner-portal/personal-address';
import { PartnerPortalService } from 'src/app/services/partner-portal.service';
import { noWhitespaceValidator } from 'src/app/validators/noWhitespaceValidator';

@Component({
  selector: 'app-partner-user-info-address',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PartnerPortalBreadcrumbsComponent,
    InputSwitchModule,
  ],
  templateUrl: './partner-user-info-address.component.html',
  styleUrl: './partner-user-info-address.component.scss',
})
export class PartnerUserInfoAddressComponent {
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();
  @Input() validated = false;
  customerData: DropshipmentConsumer;
  form: FormGroup;
  shippingForm: FormGroup;
  addressLoader: boolean;
  addressXhr: Subscription;
  countryCode: string;
  showAdditionalAddressFields: boolean;
  breadcrumbs: Breadcrumb[];
  id: any;
  shippingSameAsInvoice = true;
  availableCountries: string;


  get formValid() {
    if (this.form?.valid &&
    (this.shippingSameAsInvoice || this.shippingForm?.valid)) {
      return true;
    } else {
      return false;
    }
  }

  constructor(
    public applicationService: ApplicationService,
    private generalService: GeneralService,
    private accountService: AccountService,
    private helperService: HelperService,
    private translateService: TranslateService,
    private partnerPortalService: PartnerPortalService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.id = params['id'];
      }
    });

  }

  ngOnInit() {
    this.getAccountInformation();
    this.setForm();
  }

  private getAccountInformation() {
    this.accountService
      .getAccountInformation()
      .then((accountInformation: AccountInformation) => {
        this.countryCode = accountInformation.country_code;
      });
  }

  setForm() {
    this.partnerPortalService.getPersonalAddress().subscribe((address: PersonalAddress) => {
      this.availableCountries = address.available_country_codes;
      this.shippingSameAsInvoice = !address.is_shipping_address_different;

      // Form validation
      let EMAILPATTERN =
        /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
      this.form = new FormGroup({
        firstname: new FormControl(address.first_name, [
          Validators.required,
          Validators.maxLength(50),
          noWhitespaceValidator()
        ]),
        lastname: new FormControl(address.last_name, [
          Validators.required,
          Validators.maxLength(50),
          noWhitespaceValidator()
        ]),
        city: new FormControl(address.city, [
          Validators.required,
          Validators.maxLength(50),
          noWhitespaceValidator()
        ]),
        zipcode: new FormControl(address.zip_code, [
          Validators.required,
          Validators.maxLength(8),
          noWhitespaceValidator()
        ]),
        phone_number: new FormControl(address.phone_number, [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(20),
          noWhitespaceValidator()
        ]),
        street: new FormControl(address.address_line_1, [
          Validators.required,
          Validators.maxLength(50),
          noWhitespaceValidator()
        ]),
        district: new FormControl(address.address_line_2, [
          Validators.maxLength(25),
        ]),
        country_code: new FormControl(address.country_code ?? this.availableCountries.length ? this.availableCountries[0] : "", [
          Validators.required,
        ]),
      });

      this.shippingForm = new FormGroup({
        city: new FormControl(address.shipping_city, [
          Validators.required,
          Validators.maxLength(50),
          noWhitespaceValidator()
        ]),
        zipcode: new FormControl(address.shipping_zip_code, [
          Validators.required,
          Validators.maxLength(8),
          noWhitespaceValidator()
        ]),
        street: new FormControl(address.shipping_address_line_1, [
          Validators.required,
          Validators.maxLength(50),
          noWhitespaceValidator()
        ]),
        district: new FormControl(address.shipping_address_line_2, [
          Validators.maxLength(25)
        ]),
        shipping_country_code: new FormControl(address.shipping_country_code ?? this.availableCountries.length ? this.availableCountries[0] : "", [
          Validators.required,
        ]),
      });

      if (this.applicationService.getSelectCompanyGroupCode() !== 'RAL') {
        this.form.get('district').setValidators([]);
        this.shippingForm.get('district').setValidators([]);
        this.showAdditionalAddressFields = false;
      } else {
        this.showAdditionalAddressFields = true;
      }
    });
  }

  private getValue(key: string, subkey: string) {
    if (appGlobal.consumerFormData) {
      return key
        ? appGlobal.consumerFormData[key][subkey]
        : appGlobal.consumerFormData[subkey];
    } else {
      return '';
    }
  }

  public getAddress(form: FormGroup) {
    const zipCode = String(form.get('zipcode').value);

    if (
      zipCode &&
      zipCode.replace(/ /g, '').length === 6 &&
      this.countryCode &&
      this.countryCode === 'NL'
    ) {
      const zipLocation = new ZipLocation(
        zipCode,
        "1",
        this.countryCode
      );

      this.applicationService.showLoader(true);

      // Stop current call
      if (this.addressXhr) {
        this.addressXhr.unsubscribe();
      }

      this.addressXhr = this.generalService
        .getAddress(zipLocation)
        .subscribe((apiResponse: ApiResponse) => {
          this.applicationService.hideLoader();
          if (this.helperService.checkResponse(apiResponse)) {
            const address = apiResponse.result as ZipLocation;
            form.get('street').setValue(address.street);
            form.get('city').setValue(address.city);
          } else {
            form.get('street').setValue('');
            form.get('city').setValue('');
          }
        });
    }
  }

  saveForm() {
    return new Promise((resolve) => {
      const personalAddress = new PersonalAddress();
      personalAddress.first_name = String(
        this.form.get('firstname').value ? this.form.get('firstname').value : ''
      );
      personalAddress.last_name = String(
        this.form.get('lastname').value ? this.form.get('lastname').value : ''
      );
      personalAddress.address_line_1 = this.form.get('street').value;

      if (personalAddress.address_line_1 === " ") {
        personalAddress.address_line_1 = "";
      }

      personalAddress.address_line_2 = `${this.form.get('district').value ?? ""}`;

      personalAddress.zip_code = String(
        this.form.get('zipcode').value ? this.form.get('zipcode').value : ''
      );
      personalAddress.city = String(
        this.form.get('city').value ? this.form.get('city').value : ''
      );
      personalAddress.country_code = String(
        this.form.get('country_code').value ? this.form.get('country_code').value : ''
      );
      personalAddress.phone_number = String(
        this.form.get('phone_number').value ? this.form.get('phone_number').value : ''
      );
      personalAddress.is_shipping_address_different = !this.shippingSameAsInvoice;

      if (!this.shippingSameAsInvoice) {
        personalAddress.shipping_address_line_1 =  this.shippingForm.get('street').value;

        if (personalAddress.shipping_address_line_1 === " ") {
          personalAddress.shipping_address_line_1 = "";
        }

        personalAddress.shipping_address_line_2 = `${
          this.shippingForm.get('district').value ?? ""
        }`;

        personalAddress.shipping_zip_code = String(
          this.shippingForm.get('zipcode').value
            ? this.shippingForm.get('zipcode').value
            : ''
        );
        personalAddress.shipping_city = String(
          this.shippingForm.get('city').value
            ? this.shippingForm.get('city').value
            : ''
        );
        personalAddress.shipping_country_code = String(
          this.shippingForm.get('shipping_country_code').value
            ? this.shippingForm.get('shipping_country_code').value
            : ''
        );
      }
      this.applicationService.showLoader(true);
      this.partnerPortalService
        .savePersonalAddress(personalAddress)
        .subscribe((apiResponse: ApiResponse) => {
          this.applicationService.hideLoader();
          resolve(true);
        });
    });
  }

  toCheckout() {
    this.validated = true;
    if (
      this.form.valid &&
      (this.shippingSameAsInvoice || this.shippingForm.valid)
    ) {
      this.saveForm().then(() => {
        this.router.navigateByUrl(
          `${this.applicationService.getSelectCompanyGroupCode()}/checkout/${
            this.id
          }`
        );
      });
    }
  }
}
