import { Component, OnInit, Input } from "@angular/core";
import { WarrantyService } from "src/app/services/warranty.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { WarrantyRequest } from "src/app/interfaces/warranty/warranty-request";
import { ApplicationService } from "src/app/services/application.service";
import { Router, ActivatedRoute } from "@angular/router";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-warranty-overview",
  templateUrl: "./warranty-overview.component.html",
  styleUrls: ["./warranty-overview.component.scss"],
})
export class WarrantyOverviewComponent implements OnInit {
  @Input() action: string;
  framenumber: string;
  warrantyRequests: WarrantyRequest[];
  language: string = "";

  constructor(
    private warrantyService: WarrantyService,
    private applicationService: ApplicationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.getOpenWarrantyList();
    this.language = this.applicationService.getSelectedLanguage();
    this.activatedRoute.params.subscribe((params) => {
      if (params["framenumber"]) {
        this.framenumber = params["framenumber"];
      }
    });
  }

  getOpenWarrantyList() {
    this.warrantyService
      .getOpenWarrantyList()
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          let warrantyRequests = apiResponse.result;
          if (warrantyRequests.length > 1) {
            warrantyRequests.unshift(warrantyRequests.pop());
          }
          this.warrantyRequests = warrantyRequests;
          if (
            this.warrantyRequests &&
            this.warrantyRequests.length &&
            this.action === "showActive"
          ) {
            this.router.navigateByUrl(
              `${this.applicationService.getSelectCompanyGroupCode()}/warranty/${
                this.warrantyRequests[0].warranty_id
              }/${this.framenumber ? this.framenumber : ""}`
            );
          }

          if (
            this.warrantyRequests &&
            this.warrantyRequests.length &&
            this.action === "lease"
          ) {
            this.router.navigate(
              [
                `${this.applicationService.getSelectCompanyGroupCode()}/warranty/${
                  this.warrantyRequests[0].warranty_id
                }`,
              ],
              { queryParams: { type: "lease" } }
            );
          }
        }
      });
  }
}
