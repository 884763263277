<div class="modal-header py-2" id="search-menu-modal">
  <h5 class="modal-title" id="modal-basic-title">{{ "SEARCH" | translate }}</h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    </button>
</div>
<div class="modal-body px-3">
  <div class="input-group mb-3">
    <input
      type="search"
      class="form-control form-control-lg border-primary"
      [(ngModel)]="searchText"
      (keyup)="keyupSearch($event)"
      (keydown)="navigate($event)"
      (search)="getResults()"
      placeholder="{{ 'PAGES' | translate }} / {{ 'GROUPS' | translate }}"
      #mainInput
    />
  </div>

  <div
    class="scroller mt-2"
    [ngClass]="{ 'pe-2': hasScrollBar }"
    id="search-scroller"
  >
    <h5 class="mb-3" *ngIf="results.length">{{ "PAGES" | translate }}</h5>
    <ul class="list-group">
      <ng-container *ngFor="let item of results; let i = index">
        <ng-container
          *ngTemplateOutlet="
            generalInput;
            context: {
              item: item,
              active: i === itemIndex
            }
          "
        ></ng-container>
      </ng-container>
    </ul>
    <h5 class="my-3" *ngIf="groups.length">{{ "GROUPS" | translate }}</h5>
    <ul class="list-group">
      <ng-container *ngFor="let item of groups; let i = index">
        <ng-container
          *ngTemplateOutlet="
            generalInput;
            context: {
              item: item,
              active: i === itemIndex - results.length
            }
          "
        ></ng-container>
      </ng-container>
    </ul>
    <div
      class="d-flex w-100 h-50 align-items-center h5"
      *ngIf="!results.length && !groups.length"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-100 w-100"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#EEE"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
        />
      </svg>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex w-100 align-items-center" style="color: rgb(112 112 112)">
    <span>
      <span class="material-icons" style="font-size: 13px;">info</span>
      {{ "OPEN_SEARCH_TIP" | translate }}</span
    >
  </div>
</div>
<ng-template #generalInput let-item="item" let-active="active">
  <li
    class="list-group-item rounded d-flex justify-content-between align-items-center pe-2"
    *ngIf="item"
    (click)="item.class === 'page' ? gotoItem(item) : gotoGroup(item)"
    [ngClass]="{
      'active-menu-search selected': active
    }"
  >
    <span>{{ item.description|translate }}</span
    ><span class="material-icons"> chevron_right </span>
  </li>
</ng-template>
