import { ChangeContext, LabelType, Options } from "@angular-slider/ngx-slider";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { SideBarFilterOption } from "src/app/interfaces/side-bar-filter-option";
import { StockweekFilter } from "src/app/interfaces/stockweek-filter";

@Component({
  selector: "app-stockweek-filter",
  templateUrl: "./stockweek-filter.component.html",
  styleUrls: ["./stockweek-filter.component.scss"],
})
export class StockweekFilterComponent implements OnInit {
  @Input() stockweekFilter: StockweekFilter;
  @Output() change: EventEmitter<StockweekFilter> =
    new EventEmitter<StockweekFilter>();
  dateRange: Date[] = this.createDateRange();
  filter: SideBarFilterOption;
  value: number = this.minWeek;
  minValue: number = this.minWeek;
  highValue: number = this.maxWeek;
  options: Options = {
    hideLimitLabels: true,
    animate: false,
    stepsArray: this.dateRange.map((date: Date) => {
      return { value: date.getTime() };
    }),
    translate: (value: number, label: LabelType): string => {
      return `${String(moment(new Date(value)).isoWeek())}/${String(
        moment(new Date(value)).year()
      )}`;
    },
  };
  expanded: boolean = false;

  get minWeek() {
    return this.stockweekFilter
      ? moment()
          .year(this.stockweekFilter.from_year)
          .isoWeek(this.stockweekFilter.from_week)
          .toDate()
          .getTime()
      : this.dateRange[0].getTime();
  }

  get maxWeek() {
    return this.stockweekFilter
      ? moment()
          .year(this.stockweekFilter.to_year)
          .isoWeek(this.stockweekFilter.to_week)
          .toDate()
          .getTime()
      : this.dateRange[this.dateRange.length - 1].getTime();
  }

  ngOnInit() {
    this.value = this.minWeek;
    this.minValue = this.minWeek;
    this.highValue = this.maxWeek;
  }

  createDateRange(): Date[] {
    const dates: Date[] = [];
    let date = moment();
    while (date.year() <= moment().year() + 1) {
      dates.push(date.toDate());
      date.add(1, "weeks");
    }

    return dates;
  }

  changed(changeContext: ChangeContext): void {
    this.emitChange(
    );
  }

  reset() {
    this.highValue = this.dateRange[this.dateRange.length - 1].getTime();
    this.minValue = moment().toDate().getTime();
    this.value = moment().toDate().getTime();
    this.emitChange();
  }

  setRange(weeks: number) {
    this.highValue = moment().add(weeks, "weeks").toDate().getTime();
    this.minValue = moment().toDate().getTime();
    this.value = moment().toDate().getTime();
    this.emitChange();
  }

  emitChange() {
    const filter = new StockweekFilter();
    filter.from_week = moment(this.value).isoWeek();
    filter.from_year = moment(this.value).year();
    filter.to_week = moment(this.highValue).isoWeek();
    filter.to_year = moment(this.highValue).year();
    this.change.emit(filter);
  }
}
