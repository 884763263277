import { Component, Input, OnInit } from "@angular/core";
import { SbUrl } from "src/app/interfaces/storyblok/sb-url";
import { StoryblokService } from "src/app/services/storyblok.service";

@Component({
  selector: "app-image",
  templateUrl: "./image.component.html",
  styleUrls: ["./image.component.css"],
})
export class ImageComponent implements OnInit {
  @Input() _uid: string;
  @Input() component: any;
  @Input() image: string;
  @Input() style: string[] = [];
  @Input() height: string;
  @Input() alt_text: string;
  @Input() _editable: any;
  @Input() url: SbUrl;

  get aligment() {
    if (this.style.includes("ml-3") && !this.style.includes("mr-3")) {
      return "justify-content-end";
    } else if (!this.style.includes("ml-3") && this.style.includes("mr-3")) {
      return "justify-content-start";
    } else {
      return "justify-content-center";
    }
  }

  constructor(private storyblokService: StoryblokService) {}

  ngOnInit(): void {}

  gotoUrl() {
    if (this.url?.url) {
      this.storyblokService.gotoUrl(this.url.url);
    }
  }
}
