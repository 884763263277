import { Injectable } from "@angular/core";
import * as moment from "moment";
import {
  CacheObject,
  CacheTracker,
  CacheType,
} from "../interfaces/cache-tracker";
import { CarouselSlide } from "../interfaces/cms/carousel-slide";
import { Widget } from "../interfaces/cms/widget";
import { PreorderSettings, PreorderSettingsContainer } from "../interfaces/settings/preorder-settings";
import { AccountService } from "./account.service";
import { ApplicationService } from "./application.service";

@Injectable({
  providedIn: "root",
})
export class CacheService {
  cachePeriods = {
    // Cache duration in minutes
    slideshows: 30,
    widgets: 2,
    carousels: 5,
    news: 5,
    preorder: 5,
  };
  cache: CacheTracker = new CacheTracker();
  widgets: Widget[];
  carouselSlides: CarouselSlide[] = [];
  preorderSettings = {}
  allWidgets: Widget[];
  totalNewsItems: any;


  getTimestamp(type: CacheType) {
    let ts = (this.cache[type] as CacheObject)[
      `timestamp_${this.applicationService.getSelectCompanyGroupCode()}`
    ];

    return ts;
  }

  setTimestamp(type: CacheType) {
    (this.cache[type] as CacheObject)[
      `timestamp_${this.applicationService.getSelectCompanyGroupCode()}`
    ] = moment();
  }

  constructor(
    private applicationService: ApplicationService,
    private accountService: AccountService
  ) {
    this.applicationService.emitChangeCompanyGroupChanged$.subscribe(() => {
      this.clearCache();
    })

    this.applicationService.changeEmitted$.subscribe((response) => {
      this.clearCache();
    });
  }

  needRefresh(type: CacheType) {
    // No caching for helpdesk user
    if (this.accountService.isHelpdesk()) {
      return true;
    } else {
      let timestamp = this.getTimestamp(type);
      if (typeof timestamp === "undefined") {
        this.setTimestamp(type);
        return true;
      }
      if (
        timestamp.isAfter(
          moment().subtract(this.cachePeriods[type as string], "minutes")
        )
      ) {
        return false;
      } else {
        this.setTimestamp(type);
        return true;
      }
    }
  }

  dataLoaded(type: CacheType) {
    this.setTimestamp(type);
  }

  clearCache() {
    this.widgets = [];
    this.allWidgets = [];
    this.carouselSlides = [];
    this.cache = new CacheTracker();
    this.widgets = [];
    this.allWidgets = [];
  }
}
