<div class="row">

  <div class="col-md-12 bg-transparent">
    <div class="card-body section">
      <h5>{{"BRANDS"|translate}}</h5>
      <ng-container *ngFor="let type of ['brands', 'specials']">
        <div class="col-sm brandsContainer ps-0 mt-2">
          <strong>{{ (sections[type].toUpperCase()) | translate }}</strong>
          <div *ngFor="let brand of brands[type]">
            <div class="form-check form-switch mt-2">
              <ng-container *ngIf="(brand['id'] !== 'juncker') || (accountAgreement.year > 2022 && brand['id'] === 'juncker')">
                <input
                  type="checkbox"
                  class="form-check-input agreementBrand"
                  [id]="index + '_' + brand['name']"
                  [(ngModel)]="brand['selected']"
                  [attr.data-id]="brand['id']"
                  [attr.data-affiliate]="affiliate"
                  [attr.data-ismain]="isMain"
                  [disabled]="!isMain && yearAgreementModel[type].brands.indexOf(brand['id']) < 0"
                  [value]="true"
                  (change)="changeBrand(brand)"
                />
                <label
                  class="form-check-label clickable withOpacity"
                  [for]="index + '_' + brand['name']"
                  >{{ brand["name"] }}</label
                >
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-content></ng-content>
    </div>
  </div>
</div>
