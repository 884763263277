<div class="logout-modal-wrapper">
  <button (click)="disableKioskMode()" class="close-kiosk-checker-button">
    <i class="material-icons">close</i>
  </button>
  <div class="content">
    <div class="o-c position-relative">
      <p class="a-o-i h3 mb-3">{{ description }}</p>
      <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" *ngIf="showOtpComponent" [config]="config"
        class="mb-5 d-block">
      </ng-otp-input>
      <button class="backspace" (click)="backspace()"><i class="material-icons">backspace</i></button>
      <div class="numpad">
        <button *ngFor="let number of numbers" class="number" (click)="updatePincode(number)">{{number}}</button>
        <button (click)="exitKioskMode()" class="number">✓</button>
      </div>
    </div>
  </div>
</div>
