import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { FinancialService } from "src/app/services/financial.service";
import { InvoicesFilters } from "src/app/interfaces/financial/invoices/invoices-filters";
import { InvoiceLineComponent } from "./invoice-line/invoice-line.component";
import { Invoice } from "src/app/interfaces/financial/invoices/invoice";
import { ApiResponse } from "src/app/interfaces/api-response";
import { InvoicesOptionInput } from "src/app/interfaces/financial/invoices/invoices-option-input";
import { InvoicesFilterOptions } from "src/app/interfaces/financial/invoices/invoices-filter-options";
import { FinancialPaginatorData } from "src/app/interfaces/financial/financial-paginator-data";
import { ShippingAddressGroup } from "src/app/interfaces/shipping-address-group";
import { InvoicesSelectedFilters } from "src/app/interfaces/financial/invoices/invoices-selected-filters";
import * as _ from "underscore";
import { saveAs } from "file-saver";
import { ApplicationService } from "src/app/services/application.service";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { PaymentService } from "src/app/services/payment.service";
import { AccountService } from "src/app/services/account.service";

@Component({
  selector: "app-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.scss"],
})
export class InvoicesComponent implements OnInit {
  @Input() type: string;
  invoicesFilters: InvoicesFilters;
  @Input() shippingAddressGroup: ShippingAddressGroup;
  cachedInvoices: any = {};
  loaded = false;
  invoices: Invoice[];
  pageData: FinancialPaginatorData;
  invoice_numbers: any = [];
  checkAllState = false;
  dateFrom: any = {
    year: "",
    month: "",
    day: "",
  };
  dateTo: any = {
    year: "",
    month: "",
    day: "",
  };
  formattedDateFrom: string;
  formattedDateTo: string;
  @Input() onlinePaymentRestrictionLevel: number;
  results: Invoice[];
  totalPages: number;
  downloading: boolean;
  paymentUrl: string;
  totalSelectedInvoiceAmount: string = "";
  totalSelectedInvoiceAmountNumber: number = 0;
  invoice_numbers_for_payment: string[] = [];
  isHelpdesk: boolean;
  currency: string;

  constructor(
    private financialService: FinancialService,
    public applicationService: ApplicationService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private paymentService: PaymentService,
    private accountService: AccountService
  ) {
    this.pageData = new FinancialPaginatorData();
    this.pageData.page = 1;
    this.pageData.acePreviousPage = "";
    // Default filters
    this.invoicesFilters = new InvoicesFilters();
    this.invoicesFilters.next_page_reference = "";
    this.invoicesFilters.options = new InvoicesOptionInput();
    this.invoicesFilters.options.invoice_type = "all";
    this.invoicesFilters.options.order_by = "deliveryweek";
    this.isHelpdesk = this.accountService.isHelpdesk();
  }

  ngOnInit() {
    this.applicationService.checkShowroomMode(true);
  }

  ngOnDestroy() {
    this.applicationService.checkShowroomMode(false);
  }

  getFilters(data) {
    this.cachedInvoices = {};
    const filters: InvoicesSelectedFilters = data["filters"];
    this.invoicesFilters.options = new InvoicesOptionInput();

    // Date from
    const dateFrom =
      typeof filters.date_from !== "string"
        ? this.financialService.getDate(filters.date_from)
        : this.financialService.formatDate(filters.date_from);
    this.invoicesFilters.options.date_from = dateFrom;

    this.dateFrom = {
      year: dateFrom.substring(0, 4),
      month: dateFrom.substring(4, 6),
      day: dateFrom.substring(6, 8),
    };
    this.formattedDateFrom = `${this.dateFrom.day}-${this.dateFrom.month}-${this.dateFrom.year}`;

    // Date to
    const dateTo =
      typeof filters.date_to !== "string"
        ? this.financialService.getDate(filters.date_to)
        : this.financialService.formatDate(filters.date_to);
    this.invoicesFilters.options.date_to = dateTo;

    this.dateTo = {
      year: dateTo.substring(0, 4),
      month: dateTo.substring(4, 6),
      day: dateTo.substring(6, 8),
    };
    this.formattedDateTo = `${this.dateTo.day}-${this.dateTo.month}-${this.dateTo.year}`;

    // Keyword
    if (filters.keyWord) {
      switch (filters["keyWordOption"]) {
        case "invoicenumber": {
          this.invoicesFilters.options.invoice_number = filters.keyWord;
          break;
        }
        case "accentry_id": {
          this.invoicesFilters.options.accentry_id = filters.keyWord;
          break;
        }
      }
    }

    // Shipping Id
    this.invoicesFilters.options.shipping_id = filters.shippingAddress;

    // Shipping type
    if (
      this.shippingAddressGroup &&
      this.shippingAddressGroup.shipping_addresses
    ) {
      if (!this.invoicesFilters.options.shipping_id) {
        this.invoicesFilters.options.shipping_id = String(
          this.shippingAddressGroup.selected
        );
      }

      let selected = this.shippingAddressGroup.shipping_addresses.find((s) => {
        return (
          Number(s.id) === Number(this.invoicesFilters.options.shipping_id)
        );
      });

      if (selected) {
        this.invoicesFilters.options.shipping_type = selected.address_type;
      }
    }

    // Winora company filter
    this.invoicesFilters.options.company_id_filter = Number(
      filters["companyFilter"]
    )
      ? Number(filters["companyFilter"])
      : null;

    if (data.filters.invoice_type) {
      if (this.applicationService.getSelectCompanyGroupCode() !== "WG") {
        this.invoicesFilters.options.invoice_state_filter =
          data.filters.invoice_type;
      } else {
        this.invoicesFilters.options.invoice_state_filter = "All";
      }

      this.invoicesFilters.options.invoice_type = data.filters.invoice_type;
    }
    this.pageData.page = 1;
    this.invoicesFilters.next_page_reference = "";

    if (
      data["getResults"] ||
      (this.invoices && !this.invoices.length) ||
      !this.invoices
    ) {
      this.getInvoices();
    }
  }

  getInvoices() {
    this.pageData.currentPage = this.pageData.aceNextPage;

    if (this.cachedInvoices[this.pageData.page]) {
      this.invoices = this.cachedInvoices[this.pageData.page];
      if (this.cachedInvoices[this.pageData.page + 1]) {
        this.pageData.hasNextPage = true;
      }
      this.pageData.loading = false;
    } else {
      this.invoicesFilters.options.page = this.pageData.page;
      this.applicationService.showLoader(true);
      this.pageData.loading = true;
      this.financialService
        .getInvoices(this.invoicesFilters)
        .subscribe((apiResponse: ApiResponse) => {
          this.loaded = true;
          this.applicationService.hideLoader();
          if (apiResponse && apiResponse.success) {
            const items = typeof apiResponse.result !== "undefined" ? apiResponse.result["items"] : []

            if (
              this.applicationService.getSelectCompanyGroupCode() === "WG" &&
              this.pageData.page > 1
            ) {
              this.invoices = this.results.concat(items);
            } else {
              this.invoices = items;
            }

            this.cachedInvoices[this.pageData.page] = items;

            // Totals
            this.pageData.numResults.invoices = {
              balance_due: apiResponse.result?.balance_due,
              balance_expired: apiResponse.result?.balance_expired,
            };

            // Page references
            this.pageData.acePreviousPage =
              items && items.length ? items[0].invoice_number : null;
            this.pageData.aceNextPage = apiResponse.result["ace_next_page"]
              ? this.invoices[this.invoices.length - 1]?.invoice_number
              : null;

            // Has next page?
            this.pageData.hasNextPage = apiResponse.result["ace_next_page"]
              ? true
              : false;

            this.pageData.loading = false;
            this.totalPages = apiResponse.result["total_pages"];
            this.results = this.invoices;
            window.scrollTo(0, 0);
          }
        });
    }
  }

  navigationChanged(data) {
    this.invoicesFilters.next_page_reference = data;
    this.getInvoices();
  }

  getSelection() {
    let invoice_numbers = this.invoice_numbers;
    this.invoices.forEach((invoice: Invoice) => {
      if (
        invoice.selected &&
        invoice_numbers.indexOf(invoice.invoice_number) < 0
      ) {
        invoice_numbers.push(invoice.invoice_number);
      } else if (invoice_numbers.indexOf(invoice.invoice_number) >= 0) {
        invoice_numbers = _.without(invoice_numbers, invoice.invoice_number);
      }
    });
    this.invoice_numbers = invoice_numbers;
  }

  checkAll() {
    if (this.invoices && this.invoices.length) {
      this.invoices.forEach((invoice: Invoice) => {
        if (invoice.invoice_number && this.invoice_numbers_for_payment.length < 50) {
          invoice.selected = this.checkAllState;
          this.getSelection();
        }
      });
      this.getSelectionForPayment();
    }
  }

  clearSelection() {
    if (this.invoices && this.invoices.length) {
      this.invoices.forEach((invoice: Invoice) => {
        invoice.selected = false;
      });
      this.invoice_numbers = [];
    }
  }

  printSelection() {
    if (this.invoices && this.invoices.length) {
      const invoice_numbers = [];
      this.applicationService.showLoader(true);
      this.invoices.forEach((invoice: Invoice) => {
        if (invoice.selected) {
          invoice_numbers.push(invoice.invoice_number);
        }
      });

      let type =
        this.invoicesFilters.options.invoice_type === "all" ||
        this.applicationService.getSelectCompanyGroupCode() !== "WG"
          ? "invoices"
          : this.invoicesFilters.options.invoice_type;

      this.invoice_numbers = invoice_numbers;
      this.alertService.showInfoNotification(
        this.translateService.instant("DOWNLOADING_INVOICES")
      );
      this.downloading = true;
      this.downloadInvoice(0, type);
    }
  }

  showMore() {
    if (
      this.pageData.page < this.totalPages &&
      !this.invoicesFilters.options.invoice_number
    ) {
      this.pageData.page++;
      this.getInvoices();
    }
  }

  downloadInvoice(index: number, type: string) {
    this.financialService
      .getInvoicesPdf(this.invoice_numbers[index], type)
      .subscribe((blob: Blob) => {
        this.applicationService.hideLoader();
        if (blob) {
          const file = new Blob([blob], { type: "application/pdf" });
          saveAs(file, `invoice_${this.invoice_numbers[index]}.pdf`);
          if (index < this.invoice_numbers.length - 1) {
            this.downloadInvoice(index + 1, type);
          } else {
            this.alertService.showInfoNotification(
              this.translateService.instant("DOWNLOADING_INVOICES_COMPLETE")
            );
            this.downloading = false;
          }
        }
      });
  }

  getSelectionForPayment() {
    if (this.onlinePaymentRestrictionLevel !== 0 || this.isHelpdesk) {
      let invoice_numbers = this.invoice_numbers_for_payment;
      let totalSelectedInvoiceAmount = this.totalSelectedInvoiceAmountNumber;
      let currency = this.currency;
      this.invoices.forEach((invoice: Invoice) => {
        if (typeof invoice.open_amount !== "undefined") {
          const amountData = invoice.open_amount.split(" ");

          if (invoice.selected) {
            if (
              invoice.date_paid === "00000000" ||
              invoice.invoice_amount !== invoice.amount_paid
            ) {
              if (invoice_numbers.indexOf(invoice.invoice_number) < 0) {
                invoice_numbers.push(invoice.invoice_number);
                totalSelectedInvoiceAmount += Number(amountData[1]);
                currency = amountData[0];
              }
            }
          } else if (invoice_numbers.indexOf(invoice.invoice_number) >= 0) {
            invoice_numbers = _.without(
              invoice_numbers,
              invoice.invoice_number
            );
            totalSelectedInvoiceAmount -= Number(amountData[1]);
          }
        }
      });
      this.currency = currency;
      this.totalSelectedInvoiceAmountNumber = totalSelectedInvoiceAmount;
      this.totalSelectedInvoiceAmount = `${currency} ${totalSelectedInvoiceAmount.toFixed(
        2
      )}`;
      this.invoice_numbers_for_payment = invoice_numbers;
    }
  }

  payInvoices() {
    if (!this.isHelpdesk) {
      this.paymentService
        .getInvoicesPaymentUrl(this.invoice_numbers_for_payment)
        .subscribe((response: ApiResponse) => {
          if (response.success) {
            this.paymentUrl = response.result;
            window.location.href = this.paymentUrl;
          } else {
            this.alertService.showErrorNotification(
              response.result ? response.result : this.translateService.instant(
                "NO_INVOICE_PAYMENT_FOUND"
              )
            );
          }
        });
    } else {
      alert(this.translateService.instant("PAYMENT_NOT_ALLOWED_HELPDESK"));
    }
  }
}
