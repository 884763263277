import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ShippingAddressGroup } from 'src/app/interfaces/shipping-address-group';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BalanceFilters } from 'src/app/interfaces/financial/balance/balance-filters';
import { InvoicesFilterOptions } from 'src/app/interfaces/financial/invoices/invoices-filter-options';
import { ActivatedRoute } from '@angular/router';
import { InvoicesSelectedFilters } from 'src/app/interfaces/financial/invoices/invoices-selected-filters';
import { ApplicationService } from 'src/app/services/application.service';
import * as moment from 'moment';

@Component({
  selector: 'app-balance-filters',
  templateUrl: './balance-filters.component.html',
  styleUrls: ['./balance-filters.component.scss']
})
export class BalanceFiltersComponent implements OnInit {
  @Input() shippingAddressGroup: ShippingAddressGroup;
  @Output() searchSubmitted: EventEmitter<any> = new EventEmitter<any>();
  filters: BalanceFilters;
  filterOptions: InvoicesFilterOptions;
  fromDate: NgbDate;
  toDate: NgbDate;
  maxDate: Date;

  constructor(private activatedRouter: ActivatedRoute, public applicationService: ApplicationService) {
    this.filters = new BalanceFilters(
      "all",
      "",
      ""
    );
    this.filterOptions = new InvoicesFilterOptions();
    this.maxDate = new Date();
    this.filters['invoice_state_filter'] = 'All'

    this.defaultDateRange()
  }

  ngOnInit() {
    let getResults = false;
    this.searchSubmitted.next({ filters: this.filters, getResults: getResults });
  }

  submit() {
    this.searchSubmitted.next({ filters: this.filters, getResults: true });
  }

  defaultDateRange() {
    // 4 months prior to now
    this.filters['date_from'] = {
      "year": parseInt(moment().subtract(4, 'months').format('YYYY')),
      "month": parseInt(moment().subtract(4, 'months').format('MM')),
      "day": parseInt(moment().subtract(4, 'months').format('DD'))
    };

    // Current date
    this.filters['date_to'] = {
      "year": parseInt(moment().format('YYYY')),
      "month": parseInt(moment().format('MM')),
      "day": parseInt(moment().format('DD'))
    };
    this.filters['keyWord'] = "";
  }

  resetFilters() {
    this.defaultDateRange();
    this.submit();
  }

  setShippingAddress(shippingAddressId: string) {
    // this.filters.shippingAddress = shippingAddressId;
  }
}


