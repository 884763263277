<h5>{{ "AFFILIATES" | translate }}</h5>
<div style="max-width: 400px;">
  <div class="list-group">
    <ng-container *ngFor="let affiliate of allAffiliates">
      <div
        class="list-group-item"
        [ngClass]="{ active: selectedSubAffiliate == affiliate['relation_id'] }"
      >
        <div class="form-check form-switch mt-2">
            <input
              type="checkbox"
              class="form-check-input"
              [id]="affiliate['relation_id']"
              [(ngModel)]="affiliate['selected']"
              [value]="true"
              (change)="saveAffiliates()"
            />
            <label
              class="form-check-label clickable withOpacity px-md-1 mb-0"
              [for]="affiliate['relation_id']"
              >{{ affiliate["relation_id"] }} </label
            >
            <label class="px-md-1 mb-0"><ng-container *ngIf="affiliate['is_main']"> ({{"MAIN_AFFILIATE"|translate}})</ng-container> {{ affiliate["relation_name"] }}</label>
            <label class="px-md-1 mb-0">{{ affiliate["relation_city"] }}</label>
            <button
              class="btn btn-primary btn-sm  ms-3"
              (click)="selectAffiliate(affiliate['relation_id'])"
              *ngIf="selectedSubAffiliate != affiliate['relation_id']"
            >
              {{ "SELECT" | translate }}
            </button>
        </div>
        <i class="material-icons text-success checked" *ngIf="hasSelectedModel(affiliate['relation_id'])">check_circle</i>
      </div>
    </ng-container>
    <ng-container
      *ngFor="let customAffiliate of customAffiliates; let i = index"
    >
      <div
        class="mt-1 list-group-item"
        [ngClass]="{ active: selectedSubAffiliate == customAffiliate }"
      >
        <span style="max-width: 150px">{{customAffiliate}}</span>
        <i class="material-icons text-success checked" *ngIf="hasSelectedModel(customAffiliate)">check_circle</i>
        <u
          class="text-danger clickable withOpacity ml-3"
          (click)="customAffiliates.splice(i, 1); saveAffiliates()"
        >
          {{ "DELETE" | translate }}</u
        >
        <button
        class="btn btn-primary btn-sm  ms-3"
          (click)="selectedSubAffiliate = customAffiliate; selectAffiliate(customAffiliate)"
          *ngIf="selectedSubAffiliate != customAffiliate"
        >
          {{ "SELECT" | translate }}
        </button>
      </div>
    </ng-container>
  </div>
</div>
