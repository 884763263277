<ng-container *ngIf="!fullscreen">
  <button
    class="btn border p-2 position-absolute bg-white shadow-sm border-primary clickable withOpacity"
    style="top: 20px; right: 25px; z-index: 99"
    (click)="show ? hideIframe() : showIframe()"
  >
    <i class="material-icons d-block">360</i>
    {{ (show ? "HIDE" : "SHOW") | translate }} 360&ordm;
  </button>
  <button
    class="btn border p-2 position-absolute bg-white shadow-sm border-primary clickable withOpacity"
    *ngIf="!fullscreen && show"
    style="bottom: 0; right: 25px; z-index: 99"
    (click)="showFullscreen()"
  >
    <i class="material-icons d-block">fullscreen</i>
  </button>
</ng-container>
<iframe
  [id]="id"
  [ngClass]="{ 'd-none': !show }"
  class="border-0 w-100 h-100"
></iframe>
