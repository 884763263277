import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { SearchService } from "src/app/services/search.service";
import { Router } from "@angular/router";
import { ArticleService } from "src/app/services/article.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ArticleModalComponent } from "src/app/components/modal-components/article-modal/article-modal.component";
import * as _ from "underscore";
import { CompareArticlesComponent } from "src/app/components/compare-articles-components/compare-articles.component";
import { ApiResponse } from "src/app/interfaces/api-response";
import { timer } from "rxjs";
import { TrackingService } from "src/app/services/tracking.service";

@Component({
  selector: "app-ecommerce-articles",
  templateUrl: "./ecommerce-articles.component.html",
  styleUrls: ["./ecommerce-articles.component.scss"],
})
export class EcommerceArticlesComponent implements OnInit {
  @Output() selectForBasket: EventEmitter<any> = new EventEmitter<any>();
  @Output() noResults: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() qtyChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() batteriesLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() hasDealerSupportArticles: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() mainArticle: Article;
  @Input() showHeader = false;
  @Input() showQuantity = true;
  @Input() compact = false;
  @Input() required = false;
  @Input() shopOption = false;
  @Input() onlyMandatory = false;
  @Input() modifyFromBasket = false;
  @Input() hideArticleOverlay = false;
  @Input() quantityMainArticle = 1;
  @Input() header: "";
  @Input() type = "";
  @Input() ts = 0;
  @Input() basketArticleId = 0;
  @Input() preSelectedArticle: string = null;

  articles: Article[] = [];
  batteryArticles: Article[] = [];
  loaded = false;
  constructor(
    private searchService: SearchService,
    private router: Router,
    private articleService: ArticleService,
    private modalService: NgbModal,
    private trackingService: TrackingService,
  ) {}

  ngOnInit() {
    // todo temp
    if (!this.mainArticle.article_codes) {
      this.mainArticle.article_codes = [];
    }

    if (
      this.mainArticle.single_article_code ||
      (this.mainArticle &&
        this.mainArticle.article_codes &&
        this.mainArticle.article_codes.length)
    ) {
      this.loadArticles();

      if (
        (this.mainArticle.has_flex_batt ||
          this.mainArticle.mandatory_battery) &&
        this.type !== "upsell"
      ) {
        this.getBatteriesAndChargers();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["quantityMainArticle"]) {
      let articlesWithQuantity = [];
      articlesWithQuantity = _.filter(this.batteryArticles, (el: Article) => {
        return el.quantity && el.quantity >= 0;
      });
      if (articlesWithQuantity && articlesWithQuantity.length) {
        this.checkSelection(
          articlesWithQuantity[articlesWithQuantity.length - 1]
        );
      }
    }
    if (changes["ts"]) {
      this.loadArticles();
    }
  }

  loadArticles() {
    // Get the articles
    if (
      (this.mainArticle.alternative_ecommerce &&
        this.type === "alternatives") ||
      (this.mainArticle.cross_sell_ecommerce && this.type === "crosssell") ||
      (this.mainArticle.up_sell_ecommerce && this.type === "upsell")
    ) {
      this.getArticles();
    }
  }

  // regular e-commerce articles
  getArticles() {
    this.searchService
      .getEcommerceArticles(
        this.mainArticle.single_article_code
          ? this.mainArticle.single_article_code
          : this.mainArticle.article_codes[0],
        this.type
      )
      .subscribe((response: ApiResponse) => {
        this.loaded = true;
        if (response && response.success && response.result.length) {
          const newsIds = _.pluck(response.result, "id");
          const currentIds = _.pluck(this.articles, "id");
          if (!_.isEqual(newsIds, currentIds)) {
            this.articles = response.result;
            this.searchService.getNettPrices(this.articles);
          }
        } else {
          this.articles = null;
          switch (this.type) {
            case "alternatives":
              this.mainArticle.alternative_ecommerce = false;
              break;

            case "crosssell":
              if (
                this.mainArticle.has_flex_batt &&
                (!this.batteryArticles || !this.batteryArticles.length)
              ) {
                // this.getBatteriesAndChargers();
              } else {
                this.mainArticle.cross_sell_ecommerce = false;
                this.noResults.next(true);
              }
              break;
          }
        }
      });
  }

  // Batteries and chargers
  getBatteriesAndChargers() {
    this.loaded = false;
    this.articleService
      .getBatteriesAndChargers(
        this.mainArticle.single_article_code
          ? this.mainArticle.single_article_code
          : this.mainArticle.article_codes[0]
      )
      .subscribe((response: ApiResponse) => {
        this.loaded = true;
        if (response && response.success && response.result["Batteries"]) {
          const batteryArticles = response.result["Batteries"];

          this.mainArticle.batteries = batteryArticles;

          // Check if there articles which deliveries are uncertain (BELBIDI)
          const dealerSupportArticles = batteryArticles.filter((b) => {
            return b.call_dealersupport;
          });

          if (dealerSupportArticles && dealerSupportArticles.length) {
            this.hasDealerSupportArticles.emit(true);
          }

          // Preselect article (from open orders)
          if (this.preSelectedArticle) {
            timer(0).subscribe(() => {
              batteryArticles.forEach((b: Article) => {
                if (this.preSelectedArticle === b.single_article_code) {
                  b.selected = true;
                  b.quantity = 1;
                }
              });
            });
          }

          this.batteryArticles = batteryArticles;
          this.searchService.getNettPrices(this.batteryArticles);
          this.batteriesLoaded.emit(true);
        }
      });
  }

  showArticleDetails(article) {
    const modalRef = this.modalService.open(ArticleModalComponent, {
      size: "lg",
      container: "#modalContainer",
    });
    modalRef.componentInstance.article = article;
    modalRef.componentInstance.modelId = article["id"];
    modalRef.componentInstance.article.img = article["images"];
    modalRef.componentInstance.showInstant(true);
  }

  articleSelected(article) {
    article.selected = true;
    article.quantity = article.min_order_amount ? article.min_order_amount : 1;

    this.selectForBasket.next(article);
  }

  articleChecked(checked, selectedArticle, onyOneAllowed: boolean) {
    selectedArticle.selected = selectedArticle.selected ? false : true;
    selectedArticle.quantity = checked
      ? selectedArticle.min_order_amount
        ? selectedArticle.min_order_amount
        : 1
      : 0;
    this.checkSelection(selectedArticle);
    if (!selectedArticle.selected) {
      selectedArticle.quantity = 0;
    }
    this.selectForBasket.next(selectedArticle);
  }

  amountChanged(event, article) {
    this.checkSelection(article);
    article.selected = article.quantity > 0;
    this.selectForBasket.next(article);
  }

  checkSelection(selectedArticle) {
    if (this.batteryArticles && this.mainArticle.mandatory_battery) {
      let totalQuantity = 0;
      this.batteryArticles.forEach((article: Article) => {
        if (article.quantity < 0) {
          article.quantity = 0;
        }
        if (article.quantity) {
          totalQuantity += article.quantity;
        }
      });
      if (totalQuantity > this.quantityMainArticle) {
        selectedArticle.quantity =
          this.quantityMainArticle - (totalQuantity - selectedArticle.quantity);
        if (selectedArticle.quantity === 0) {

          if (!this.preSelectedArticle) {
            setTimeout(() => {
              selectedArticle.selected = false;
            }, 0);
          } else {
            this.batteryArticles.forEach((b: Article) => {
              if (selectedArticle.single_article_code !== b.single_article_code) {
                b.selected = false;
              }
            })

          }
        }
      }
    }

    // Reset selection in addToModal
    //this.selectForBasket.next(null);
  }

  compareArticles() {
    const articleIds = _.pluck(this.articles, "id");
    articleIds.unshift(this.mainArticle.id);
    const modalRef = this.modalService.open(CompareArticlesComponent, {
      size: "lg",
      container: "#modalContainer",
    });
    modalRef.componentInstance.externalIds = articleIds;
  }

  selectArticle($event) {
    console.log($event);
  }

  articleIsShown(article: Article, index: number) {
    this.trackingService.trackCommerce(article, this.type, index);
  }

  addToBasketButtonClicked(article: Article, index: number) {
    this.trackingService.trackCommerceAddToBasket(article, this.type, index);
  }
}
