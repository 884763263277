import { YearAgreementDachBrand } from "./year-agreement-dach-brand";

export interface YearAgreementDach {
  id: number;
  brands: YearAgreementDachBrand[];
  cargo_brands: YearAgreementDachBrand[];
  dealer_type: string;
  planned_total_revenue: YearAgreementPlannedRevenue[];
  rabatt: YearAgreementDachRabatt[];
  younit_rabatt: YearAgreementDachRabatt[];
  scan_app_rabatt: YearAgreementDachScanAppRabatt[];
  discounts: YearAgreementDachDiscountBrand[];
  revenue_selected: string;
  fair_trade_rabatt: string;
  submitted_preorder_rabatt: string;
  rabatt_selected: string;
  click_and_collect: YearAgreementClickAndCollect[];
  click_and_collect_selected: string;
  younit_rabatt_selected: string;
  scan_app_rabatt_selected: string;
  year: number;
  send: string;
  account_code: string;
  dealer_assocation: string;
  contract_email: string;
  turnover_lastyear: string;
  dealer_country: string;
  turnover_target: string;
  locked: boolean;
  texts: AgreementText[];
  winora_dealer: boolean;
  ghost_dealer: boolean;
  signed: any;
  overrule_form_type: string;
  form_types: string[];
  form_type: string;
}

export class YearAgreementDach implements YearAgreementDach {
  constructor(accountCode: string, year: number) {
    this.account_code = accountCode;
    this.year = year;
  }
}

export interface AgreementText {
  key: string;
  value: string;
}


export interface YearAgreementPlannedRevenue {
  id: string;
  amount: number;
  percentage: number;
  association_percentage: number;
}

export interface YearAgreementDachRabatt {
  description: string;
  id: string;
  percentage?: number;
}

export interface YearAgreementDachDiscountBrand {
  id: string;
  discount_selected: string;
  sepa_registered_selected: string;
  payment_term_selected: string;
  sepa_registered: YearAgreementDachDiscount[];
  discount: YearAgreementDachDiscount[];
  payment_term: YearAgreementDachDiscount[];
}

export interface YearAgreementDachDiscount {
  id: string;
  description: string;
}

export interface YearAgreementDachScanAppRabatt {
  id: string;
  description: string;
}

export interface YearAgreementClickAndCollect {
  id: string;
  description: string;
}


