import { PageComponent } from "src/app/pages/page/page.component";
import { ArticleContainerComponent } from "./article-container/article-container.component";
import { BannerComponent } from "./banner/banner.component";
import { DynamicGridComponent } from "./dynamic-grid/dynamic-grid.component";
import { GridBlockComponent } from "./grid-block/grid-block.component";
import { LinkButtonComponent } from "./link-button/link-button.component";
import { VideoComponent } from "./video/video.component";
import { ImageComponent } from "./image/image.component";
import { ArticleCollectionComponent } from "../article-components/article-collection/article-collection.component";

let StoryblokComponents = {
  banner: BannerComponent,
  page: PageComponent,
  article_container: ArticleContainerComponent,
  link_button: LinkButtonComponent,
  dynamic_grid: DynamicGridComponent,
  grid_block: GridBlockComponent,
  video: VideoComponent,
  image: ImageComponent,
  article_collection: ArticleCollectionComponent
};

export { StoryblokComponents };
