import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-affiliate',
  templateUrl: './add-affiliate.component.html',
  styleUrls: ['./add-affiliate.component.scss']
})
export class AddAffiliateComponent implements OnInit {
  name: string;
  public answerObserver = new Subject<any>();
  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

  save() {
    this.answerObserver.next(this.name);
    this.modal.close();
  }

}
