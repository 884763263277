<div class="carousel d-flex">
  <div
    class="carousel-image col-md-6"
    *ngIf="slide.image_url && slide.image_url.indexOf('coming_soon') < 0"
  >
    <img class="w-100" [src]="slide.image_url" *ngIf="!isVideo" />
    <app-slideshow-media-embed
      [slide]="slide"
      *ngIf="slide && isVideo"
    ></app-slideshow-media-embed>
  </div>
  <div>
    <app-slide-content [slide]="slide"></app-slide-content>
  </div>
</div>
