import { PreorderSelectedArticle } from "./preorder-selected-article";

export interface PreorderInputCollection {
  articleList: PreorderSelectedArticle[],
  totalQuantity: number
}

export class PreorderInputCollection implements PreorderInputCollection{
  constructor(totalQuantity: number, articleList: PreorderSelectedArticle[]){
    this.totalQuantity =totalQuantity;
    this.articleList = articleList;
  }
}
