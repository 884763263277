import { ArticleGroup } from "./articleGroup";

export interface GroupCollection {
  product_group_codes: ArticleGroup[];
  article_group_codes: ArticleGroup[];
  article_sub_group_codes: ArticleGroup[];
  variation_group_code: ArticleGroup[];
}

export class GroupCollection implements GroupCollection {}
