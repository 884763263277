<div class="modal-header">
  <h4 class="modal-title">{{ "ADD_AFFILIATE" | translate }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    </button>
</div>
<div class="modal-body p-0">
  <div class="row px-3">
    <div class="col-12">
      <div class="mb-3 pt-3">
        <label for="name">{{ "NAME" | translate }}</label>
        <input type="text" class="form-control" id="name" [(ngModel)]="name" />
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-success" (click)="save()">
    {{ "SAVE" | translate }}
  </button>
</div>
