<div *ngIf="article" class="overflow-hidden rounded px-2" [ngClass]="{'unsaved': unsaved, 'mandatory': article.is_mandatory_item, 'border border-dark': article.is_mandatory_item}" style="min-height: 200px;">
  <div class="card-body pt-0">
    <div class="row">
      <div class="px-0 clickable pt-3" [ngClass]="article.is_mandatory_item ? 'col-md-6' : 'col-md-3'">
        <app-article-image class=" d-inline-block mt-2 w-75 text-center thumbnail position-relative" [articleType]="article.article_type"
          [imageUrl]="article.thumbs && article.thumbs.length ? article.thumbs[thumbnailIndex] : ''" [useLazyLoad]="false" [images]="article.thumbs" [showControls]="true"
          [description]="article.description" (imageClick)="openModal(article, $event)" [classList]="'clickable withOpacity'">
          <span class="bico" *ngIf="bico && article.bico">
            <img src="./assets/images/bico.jpg" title="BICO" alt="BICO">
          </span>
        </app-article-image>

        <h5 class="card-title" (click)="openModal(article, $event)">
          <span [innerHTML]="article.description"></span><br />
          <small
            [innerHTML]="(article.caption?article.caption:'') + ' ' + (article.single_article_code?article.single_article_code:'')"></small>
          <small class="small ms-2" *ngIf="article.season && !['RAL','ANL'].includes(selectedCompanyGroup)">{{article.season}}</small>
          <app-highlight-badge (click)="openModal(article, $event)" [article]="article"
            *ngIf="article.stock_status < 3 || article.setitem || article.new || article.has_mandatory_item"
            class="absolute">
          </app-highlight-badge>

        </h5>

        <div class="card-text" *ngIf="article.retail_price" (click)="openModal(article, $event)">
          <b *ngIf="article.retail_price">
            <app-retail-price [price]="article.retail_price" [showLabel]="true"></app-retail-price>
          </b>
          <b class="d-block" [style.display]="article.gross_price ? '' : 'none'">
            <app-gross-price [price]="article.gross_price" [showLabel]="true"></app-gross-price>
          </b>
          <h5 *ngIf="!article.hide_nett_price">
            <app-nett-price [price]="article.net_price" [showLabel]="true"
              [hasDifferentPrices]="article.has_different_prices">
            </app-nett-price>
          </h5>
        </div>
      </div>
      <div class="px-0"  [ngClass]="article.is_mandatory_item ? 'col-md-6' : 'col-md-9'">
        <app-article-weeks-table [article]="article" [hideSaveButton]="hideSaveButton" [monthSelectionOnly]="monthSelectionOnly"
          [showAdditionalItems]="showAdditionalItems" (qtyChanged)="quantityChange($event)" (saved)="unsaved = false"
          [selectedArticles]="selectedArticles" [useTimeSlots]="useTimeSlots"
          [additionalBasketSections]="useTimeSlots && showAdditionalItems" [preorderBasketId]="preorderBasketId"
          [basketArticles]="basketArticles" [schedule]="schedule" [edit]="edit" [type]="type" [bico]="bico" [basketArticleId]="basketArticleId" [countryCode]="countryCode">
          <p class="px-0 mt-2 mb-0 fw-bold" *ngIf="unsaved && !hideSaveButton">{{"NOT_SAVED_PREORDER"|translate}}</p>
        </app-article-weeks-table>
        <app-stock-status [showText]="true"
          *ngIf="!article.matrix || !article.matrix.options || article.matrix.options.length < 1" [article]="article">
        </app-stock-status>
      </div>
      <small class="note mt-2" *ngIf="['GHO','WG'].includes(selectedCompanyGroup)">*{{"PREORDER_DISCLAIMER"|translate}}</small>
    </div>
  </div>
