import { Component, OnInit, Input } from "@angular/core";
import { NumberFormatPipe } from "src/app/pipes/number-format.pipe";

@Component({
  selector: "app-input-label",
  templateUrl: "./input-label.component.html",
  styleUrls: ["./input-label.component.scss"],
  providers: [NumberFormatPipe]
})
export class InputLabelComponent implements OnInit {
  @Input() id: string;
  @Input() text: string;
  @Input() percentage: string;
  constructor(public numberFormat: NumberFormatPipe) {}

  ngOnInit() {}
}
