import { Component } from '@angular/core';

@Component({
  selector: 'app-style-gho',
  templateUrl: './style-gho.component.html',
  styleUrls: ['./style-gho.component.scss']
})
export class StyleGhoComponent {

}
