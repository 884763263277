import { Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbModal,
  NgbModalConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { Router, NavigationEnd, Event } from "@angular/router";
import { CmsPageContentComponent } from "src/app/components/general-components/cms-page-content/cms-page-content.component";
import { Subscription, timer } from "rxjs";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";
import { Article } from "src/app/interfaces/article";
import { EbscService } from "src/app/services/ebsc.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { EbikeRegistration } from "src/app/interfaces/ebsc/ebike-registration";
import { AlertService } from "src/app/services/alert.service";
import { NgbDateFormatter } from "src/app/ngbFormatter";
import * as moment from "moment";
import { PreparationInput } from "src/app/interfaces/ebsc/preparation-input";
import * as appGlobal from "src/app/globals";
import * as _ from "underscore";
import { ServiceInput } from "src/app/interfaces/ebsc/service-input";
import { SuccessModalComponent } from "src/app/components/ebsc/success-modal/success-modal.component";
import { EbikeRegistrationOverviewItem } from "src/app/interfaces/ebsc/ebike-registration-overview-item";
import { AccountService } from "src/app/services/account.service";
import { AccountInformation } from "src/app/interfaces/account-information";
import { HelperService } from "src/app/services/helper.service";
import { dateValidator } from "src/app/validators/dateValidator";

@Component({
  selector: "app-ebsc",
  templateUrl: "./ebsc.component.html",
  styleUrls: ["./ebsc.component.scss"],
  providers: [
    { provide: NgbDateFormatter, useClass: NgbDateFormatter },
    NgbModalConfig,
  ],
})
export class EBSCComponent implements OnInit {
  @ViewChild(CmsPageContentComponent) cmsContent: CmsPageContentComponent;

  selectedTab = "registerSale";
  routeSubscription: Subscription;
  formGroup: FormGroup;
  bicycleArticle: Article; //The article to be registered
  validated = false;
  selectForEzy = false;
  isEbike = false;
  ezySwitch = "new";
  serviceSwitch = "";
  selectedInvoiceDate: NgbDate;
  userLanguage = "";
  pageIds = {
    registerSale: "",
    ezyRider: "",
    service: "",
    registrationsOverview: "",
  };
  countries = [];
  ebikeRegistration: EbikeRegistration;
  validDate: boolean;
  pdfSrc: string;
  pdfBlob: Blob;
  loading = false;
  showDownload = false;
  frameNumber: string;
  countryCode: string;
  noEzyRider: boolean;

  get formattedDate() {
    return moment(
      this.dateFormatter.format(this.selectedInvoiceDate),
      "DD-MM-YYYY"
    ).format("YYYYMMDD");
  }

  get sendEnabled() {
    return (
      (this.selectedTab === "ezyRider" &&
        this.ebikeRegistration &&
        this.ebikeRegistration.xtra_guarantee_applicable &&
        this.ebikeRegistration.prepared) ||
      (this.selectedTab === "registerSale" &&
        this.ebikeRegistration &&
        this.validDate &&
        (this.countryCode === "NL" || this.countryCode === "BE")) ||
      (this.selectedTab === "registerSale" &&
        this.countryCode !== "NL" &&
        this.countryCode !== "BE" &&
        this.ebikeRegistration.ebike)
    );
  }

  get minDate(): NgbDate {
    switch (this.selectedTab) {
      case "registerSale":
        const minDate = moment().subtract(14, "day");
        return new NgbDate(
          minDate.get("year"),
          minDate.get("month") + 1,
          minDate.get("date")
        );
      default:
        return null;
    }
  }

  get maxDate(): NgbDate {
    switch (this.selectedTab) {
      case "registerSale":
        return new NgbDate(
          moment().get("year"),
          moment().get("month") + 1,
          moment().get("date")
        );
      default:
        return null;
    }
  }

  get showRegistrationForm() {
    return (
      (this.selectedTab === "registerSale" &&
        this.ebikeRegistration &&
        (!this.ebikeRegistration.ebike ||
          (this.ebikeRegistration.ebike &&
            !this.ebikeRegistration.ion &&
            !this.ebikeRegistration.preparation_data))) ||
      (this.selectedTab === "ezyRider" &&
        this.ebikeRegistration &&
        !this.ebikeRegistration.xtraGuaranteeDate &&
        this.ebikeRegistration.xtra_guarantee_applicable) ||
      this.selectedTab === "service"
    );
  }

  get sendServiceEnabled() {
    return (
      this.checkDate() &&
      this.formGroup.get("frame_number") &&
      this.serviceSwitch
    );
  }

  get guaranteeEnddate() {
    // Format invoice date
    return this.selectedInvoiceDate
      ? moment(
          this.dateFormatter.format(this.selectedInvoiceDate),
          "DD-MM-YYYY"
        )
          .add(5, "years")
          .format("DD-MM-YYYY")
      : "";
  }

  private checkDate() {
    return (
      this.selectedInvoiceDate &&
      this.dateFormatter
        .format(this.selectedInvoiceDate)
        .toString()
        .indexOf("undefined") < 0
    );
  }

  private showError(message: string) {
    this.alertService.showPopup(
      message ? message : this.translateService.instant("ERROR"),
      "error"
    );
    this.applicationService.hideLoader();
  }

  constructor(
    private modalService: NgbModal,
    private accountService: AccountService,
    private alertService: AlertService,
    public translateService: TranslateService,
    private dateFormatter: NgbDateFormatter,
    private applicationService: ApplicationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private helperService: HelperService,
    private ebscService: EbscService
  ) {
    this.routeSubscription = router.events.subscribe((data: any) => {
      if (data.url && data instanceof NavigationEnd) {
        this.getType();
      }
    });
  }

  ngOnInit() {
    this.countries = [
      { code: "BE", description: this.translateService.instant("BELGIUM") },
      { code: "DK", description: this.translateService.instant("DENMARK") },
      { code: "DE", description: this.translateService.instant("GERMANY") },
      { code: "FR", description: this.translateService.instant("FRANCE") },
      { code: "GB", description: this.translateService.instant("BRITAIN") },
      { code: "IT", description: this.translateService.instant("ITALY") },
      { code: "LU", description: this.translateService.instant("LUXEMBOURG") },
      { code: "NL", description: this.translateService.instant("NETHERLANDS") },
      { code: "NO", description: this.translateService.instant("NORWAY") },
      { code: "AT", description: this.translateService.instant("AUSTRIA") },
      { code: "PL", description: this.translateService.instant("POLAND") },
      { code: "SE", description: this.translateService.instant("SWEDEN") },
      { code: "CH", description: this.translateService.instant("SWITZERLAND") },
    ];
    this.userLanguage = this.applicationService.getSelectedLanguage();
    this.buildForm();

    this.getAccountInformation().then(
      (accountInformation: AccountInformation) => {
        this.countryCode = accountInformation.country_code;
        this.pageIds["registerSale"] =
          appGlobal.companyGroupSettings.prepare_page_id;
        this.pageIds["ezyRider"] =
          this.countryCode === "BE"
            ? "960421868"
            : appGlobal.companyGroupSettings.xtra_page_id;
        this.pageIds["service"] =
          appGlobal.companyGroupSettings.service_page_id;
        this.setCmsContent();
        this.formGroup.get("country").setValue(this.countryCode);
      }
    );
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  getAccountInformation() {
    return this.accountService.getAccountInformation();
  }

  private getType() {
    if (window.location.href.indexOf("/EBSC#") >= 0) {
      const urlData = window.location.href.split("#");
      const type = urlData[urlData.length - 1];
      switch (type) {
        case "prepare":
          this.selectedTab = "registerSale";
          break;
        case "registrationsOverview":
          this.selectedTab = type;
          break;
        case "xtra":
          this.selectedTab = "ezyRider";
          break;
        default:
          this.selectedTab = "registerSale";
          break;
      }
    }
  }

  private setCmsContent() {
    this.cmsContent.getContent(this.pageIds[this.selectedTab], 300);
  }

  private buildForm() {
    this.formGroup = new FormGroup({
      gender: new FormControl(""),
      invoice_date: new FormControl(""),
      battery_one: new FormControl("", [Validators.required]),
      battery_two: new FormControl(null, []),
      frame_number: new FormControl(""),
      sales_person: new FormControl(""),
      service_date: new FormControl(""),
      initials: new FormControl(""),
      first_name: new FormControl(""),
      last_name: new FormControl(""),
      middle_name: new FormControl(""),
      birthdate: new FormControl(null),
      street_name: new FormControl(""),
      house_no: new FormControl(""),
      suffix: new FormControl(""),
      zip: new FormControl(""),
      city: new FormControl(""),
      country: new FormControl(""),
      email: new FormControl(""),
    });

    this.setValidation();

    // this.formGroup.get("frame_number").valueChanges.subscribe(() => {
    //   this.bicycleArticle = null;
    // })
  }

  setValidation() {
    const required = ["invoice_date", "sales_person", "country"];
    const requiredEzyRider = [
      "initials",
      "first_name",
      "last_name",
      "birthdate",
      "street_name",
      "zip",
      "city",
      "house_no",
    ];

    let EMAILPATTERN = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (
      this.selectedTab !== "service" &&
      this.selectedTab !== "registrationsOverview"
    ) {
      required.forEach((r) => {
        this.formGroup.get(r).setValidators([Validators.required]);
        this.formGroup.get(r).updateValueAndValidity();
      });

      // Register sale / Ezy
      this.formGroup
        .get("email")
        .setValidators([Validators.required, Validators.pattern(EMAILPATTERN)]);
      this.formGroup.get("email").updateValueAndValidity();
      this.formGroup
        .get("sales_person")
        .setValue(
          localStorage.getItem("sales_person")
            ? localStorage.getItem("sales_person")
            : ""
        );
      this.formGroup.get("service_date").clearValidators();
      this.formGroup.get("service_date").updateValueAndValidity();

      // EzyRider
      if (this.selectedTab === "ezyRider" || this.selectForEzy) {
        requiredEzyRider.forEach((r) => {
          this.formGroup.get(r).setValidators([Validators.required]);
          this.formGroup.get(r).updateValueAndValidity();
        });
        this.formGroup.get('birthdate').setValidators([Validators.required,dateValidator()]);
      } else if (!this.selectForEzy) {
        requiredEzyRider.forEach((r) => {
          this.formGroup.get(r).clearValidators();
          this.formGroup.get(r).updateValueAndValidity();
        });
      }
    } else {
      this.formGroup.get("service_date").setValidators([Validators.required]);
      this.formGroup.get("service_date").updateValueAndValidity();

      required.concat(["email"]).forEach((r) => {
        this.formGroup.get(r).clearValidators();
        this.formGroup.get(r).updateValueAndValidity();
      });

      requiredEzyRider.forEach((r) => {
        this.formGroup.get(r).clearValidators();
        this.formGroup.get(r).updateValueAndValidity();
      });
    }

    // Set battery validation
    if (
      this.selectedTab === "registerSale" &&
      this.ebikeRegistration &&
      this.ebikeRegistration.ebike
    ) {
      this.formGroup.get("battery_one").setValidators([Validators.required]);

      this.formGroup.get("battery_one").updateValueAndValidity();
    } else {
      ["battery_one", "battery_two"].forEach((r) => {
        this.formGroup.get(r).clearValidators();
        this.formGroup.get(r).updateValueAndValidity();
      });
    }
  }

  isRequiredField(field: string) {
    const form_field = this.formGroup.get(field);
    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as FormGroup);
    return validator && validator['required'];
  }

  tabChange(tab: any) {
    // Fix weird position of article sticky thing
    if (tab.activeId === "registrationsOverview") {
      document.body.scrollTop = 1;
      const html = document.documentElement;
      if (html) {
        html.scrollTop = 1;
      }
    }
    this.selectedInvoiceDate = null;
    this.selectedTab = tab.nextId;
    this.validDate = false;
    this.pdfSrc = "";
    this.pdfBlob = null;
    this.setCmsContent();
    this.showMessage();
    this.setValidation();

    if (this.formGroup.get("frame_number").value) {
      this.getEbikeDetails(this.formGroup.get("frame_number").value, true);
    }
  }

  inputChange(data: any, fieldName: string) {
    this.showDownload = false;
    switch (fieldName) {
      case "frame_number":
        //this.bicycleArticle = null;
        this.getEbikeDetails(this.formGroup.get("frame_number").value, true);
        break;
    }
  }

  keyUp(e) {
    const keyCode = String(e["code"]).toLowerCase();
    if (keyCode === "enter" || keyCode === "arrowup") {
      this.getEbikeDetails(this.formGroup.get("frame_number").value, true);
    }
  }

  onPaste(event: ClipboardEvent) {
    const el = document.getElementById("framenumberInput") as HTMLInputElement;
    let clipboardData = event.clipboardData || window["clipboardData"];
    this.getEbikeDetails(clipboardData.getData("text"), true);
  }

  dateChanged(date: NgbDate) {
    this.checkInvoiceDate(date);
  }

  checkInvoiceDate(date: NgbDate) {
    const formatted = moment(this.dateFormatter.format(date), "DD-MM-YYYY");
    switch (this.selectedTab) {
      case "service":
        this.validDate = true;
        break;
      case "registerSale":
        this.validDate = moment()
          .subtract(14, "days")
          .isSameOrBefore(formatted, "day");
        break;

      case "ezyRider":
        this.validDate = true;
        break;
    }
  }

  // Get the current EBSC status and model details
  getEbikeDetails(frameNumber: string, checkPreparation?: boolean) {
    this.frameNumber = "";
    if (frameNumber && frameNumber.length > 4) {
      this.loading = true;
      frameNumber = frameNumber.trim();
      this.applicationService.showLoader(true);
      this.bicycleArticle = new Article();
      this.ebscService
        .getEbikeRegistraton(
          frameNumber,
          checkPreparation && this.selectedTab === "registerSale"
        )
        .subscribe((apiResponse: ApiResponse) => {
          this.applicationService.hideLoader();
          this.loading = false;
          if (this.helperService.checkResponse(apiResponse)) {
            this.frameNumber = frameNumber;
            this.ebikeRegistration = apiResponse.result as EbikeRegistration;
            this.bicycleArticle = apiResponse.result["model_detail"];

            // Check if already prepared (NL)
            if (
              checkPreparation &&
              this.ebikeRegistration.prepared &&
              this.selectedTab === "registerSale"
            ) {
              this.alertService.showPopup(
                apiResponse.result["general_message"],
                "error"
              );
              return false;
            }

            // Check if e-bike (non-NL)
            if (
              checkPreparation &&
              !this.ebikeRegistration.ebike &&
              this.countryCode !== "NL" &&
              this.countryCode !== "BE"
            ) {
              this.alertService.showPopup(
                this.translateService.instant("NO_EBIKE"),
                "error"
              );
              return false;
            }

            if (this.selectedTab === "ezyRider") {
              if (this.ebikeRegistration.preparation_data) {
                const invoiceDate = moment(
                  this.ebikeRegistration.preparation_data.invoice_date,
                  "YYYYMMDD"
                );
                this.selectedInvoiceDate = new NgbDate(
                  invoiceDate.get("year"),
                  invoiceDate.get("month") + 1,
                  invoiceDate.date()
                );
                this.formGroup
                  .get("invoice_date")
                  .setValue(
                    this.ebikeRegistration.preparation_data.invoice_date
                  );
                this.checkInvoiceDate(this.selectedInvoiceDate);

                if (
                  this.ebikeRegistration.xtraGuaranteeDate &&
                  this.ebikeRegistration.xtraGuaranteeDate.trim() === "0"
                ) {
                  this.ebikeRegistration.xtraGuaranteeDate = this.ebikeRegistration.preparation_data.guarantee_date;
                }
              } else {
                this.selectedInvoiceDate = null;
                this.validDate = false;
              }
            } else {
              this.selectedInvoiceDate = null;
              this.validDate = false;
            }

            const eSystem = _.filter(
              this.bicycleArticle.specs,
              function (spec) {
                return spec.code === "EBSYS" || spec.code === "UD-MTBRD";
              }
            );

            // Check for ION
            if (eSystem && eSystem.length) {
              eSystem.forEach((element: any) => {
                const values = Object.values(element.values);
                if (values && values.length) {
                  const ion = values.find(
                    (s) => s["value"].toLowerCase() === "ion"
                  );
                  if (ion) {
                    this.ebikeRegistration.ion = true;
                  }
                }
              });
            }

            // Show pdf when EzyRider check
            if (
              this.ebikeRegistration.xtraGuaranteeDate &&
              this.ezySwitch === "check"
            ) {
              this.noEzyRider = false;
              this.getPdf();
            } else {
              this.noEzyRider = true;
            }

            this.setValidation();
            this.showMessage();
          } else {
            this.showError(apiResponse.result);
          }
          return false;
        });
      return false;
    }
    return false;
  }

  private getPdf() {
    if (
      this.ebikeRegistration.prepared &&
      this.selectedTab === "registerSale"
    ) {
      this.showRegisterPdf();
    } else if (this.ebikeRegistration.xtraGuaranteeDate) {
      this.showEzyRiderPdf();
    }
  }

  private showRegisterPdf() {
    this.loading = true;
    this.getRegisterPdf(this).subscribe((blob: Blob) => {
      this.loading = false;
      this.showDownload = true;
      this.createPdfUrl(blob);
    });
  }

  private getRegisterPdf(self) {
    return self.ebscService.getPrepareEbikePdf(
      self.formGroup.get("frame_number").value
    );
  }

  private showEzyRiderPdf() {
    this.loading = true;
    this.getEzyRiderPdf(this).subscribe((blob: Blob) => {
      this.loading = false;
      this.showDownload = true;
      this.createPdfUrl(blob);
    });
  }

  private getEzyRiderPdf(self) {
    return self.ebscService.getEzyRiderEbikePdf(
      self.formGroup.get("frame_number").value.trim(),
      self.formGroup.get("sales_person").value
        ? self.formGroup.get("sales_person").value
        : "-"
    );
  }

  private createPdfUrl(blob: Blob) {
    const file = new Blob([blob], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    this.pdfSrc = fileURL;
    this.pdfBlob = file;
  }

  sendForm() {
    this.validated = true;
    if (this.selectedTab === "service") {
      this.formGroup.get("service_date").setValue(this.formattedDate);
      this.formGroup.get("invoice_date").setValue("");
    } else {
      this.formGroup.get("invoice_date").setValue(this.formattedDate);
      this.formGroup.get("service_date").setValue("");
      localStorage.setItem(
        "sales_person",
        this.formGroup.get("sales_person").value
      );
    }

    if (this.formGroup.valid) {
      this.loading = true;

      switch (this.selectedTab) {
        case "registerSale":
          if (
            (this.countryCode.toLocaleUpperCase() === "NL" ||
              this.countryCode.toLocaleUpperCase() === "BE") &&
            this.bicycleArticle &&
            (this.bicycleArticle.id || this.bicycleArticle.id === 0)
          ) {
            this.applicationService.showLoader(true);
            this.registerSale().subscribe((apiResponse: ApiResponse) => {
              this.applicationService.hideLoader();
              this.loading = false;
              if (
                apiResponse.success ||
                apiResponse.result === "Batterij 2 is reeds gekoppeld" ||
                apiResponse.result === "Batterij 1 is reeds gekoppeld"
              ) {
                this.prepareEbike(true);

              } else {
                this.showError(apiResponse.result);
              }
            });
          } else if (
            this.bicycleArticle &&
            (this.bicycleArticle.id || this.bicycleArticle.id === 0)
          ) {
            this.applicationService.showLoader(true);
            this.loading = false;
            this.prepareEbike(false);
          }
          break;
        case "ezyRider":
          this.registerEzyRider();
          break;
        case "service":
          this.registerService();
          break;
      }
    }
  }

  // Only for NL !
  private registerSale() {
    const preparationInput: PreparationInput = {
      ...this.formGroup.value,
    } as PreparationInput;
    preparationInput.mailing = false;
    preparationInput.model_id = this.bicycleArticle
      ? this.bicycleArticle.id.toString()
      : "";
    preparationInput.company_id = this.bicycleArticle
      ? this.bicycleArticle.company_id.toString()
      : "";
    if (preparationInput.birthdate) {
      preparationInput.birthdate = moment(
        preparationInput.birthdate,
        "YYYY-M-D"
      ).format("YYYYMMDD");
    } else {
      preparationInput.birthdate = moment().format("YYYYMMDD");
    }

    preparationInput.guarantee_date = moment(
      this.dateFormatter.format(this.selectedInvoiceDate),
      "DD-MM-YYYY"
    ).format("YYYYMMDD");

    preparationInput.article_code = this.bicycleArticle.single_article_code
      ? this.bicycleArticle.single_article_code
      : this.bicycleArticle.article_codes[0];

    return this.ebscService.registerBike(preparationInput);
  }

  private prepareEbike(registeredForSale: boolean) {
    if (this.ebikeRegistration.ebike && !this.ebikeRegistration.ion) {
      this.loading = true;
      const preparationInput: PreparationInput = {
        ...this.formGroup.value,
      } as PreparationInput;
      preparationInput.mailing = false;
      preparationInput.model_id =
        this.bicycleArticle && this.bicycleArticle.id
          ? this.bicycleArticle.id.toString()
          : "";
      if (preparationInput.birthdate) {
        preparationInput.birthdate = moment(
          preparationInput.birthdate,
          "YYYY-M-D"
        ).format("YYYYMMDD");
      } else {
        preparationInput.birthdate = moment().format("YYYYMMDD");
      }

      preparationInput.company_id = this.bicycleArticle.company_id;
      preparationInput.guarantee_date = moment(
        this.dateFormatter.format(this.selectedInvoiceDate),
        "DD-MM-YYYY"
      ).format("YYYYMMDD");
      preparationInput.article_code = this.bicycleArticle.single_article_code
        ? this.bicycleArticle.single_article_code
        : this.bicycleArticle.article_codes[0];
      this.applicationService.showLoader(true);
      this.ebscService
        .prepareEbike(preparationInput)
        .subscribe((apiResponse: ApiResponse) => {
          this.loading = false;
          if (this.helperService.checkResponse(apiResponse)) {
            this.getEbikeDetails(preparationInput.frame_number, false);

            // When checked for ezyRider call api
            if (this.selectForEzy) {
              this.registerEzyRider();
            } else {
              this.showRegistrationModal(
                this.getRegisterPdf,
                registeredForSale ? "registerSalePrepare" : "prepare"
              );
            }
          } else {
            this.showError(apiResponse.result);
          }
        });
    } else {
      this.applicationService.hideLoader();
      this.showRegistrationModal(null, "registerSale");
    }
  }

  private registerEzyRider() {
    const preparationInput: PreparationInput = {
      ...this.formGroup.value,
    } as PreparationInput;
    let bicycleArticle = new Article();

    if (this.ebikeRegistration && this.ebikeRegistration.model_detail) {
      this.loading = true;
      bicycleArticle = this.ebikeRegistration.model_detail;
    } else {
      bicycleArticle = this.bicycleArticle;
    }
    preparationInput.mailing = false;
    preparationInput.model_id = bicycleArticle
      ? bicycleArticle.id.toString()
      : "";
    preparationInput.company_id = bicycleArticle.company_id;
    if (preparationInput.birthdate) {
      preparationInput.birthdate = moment(
        preparationInput.birthdate,
        "D-M-YYYY"
      ).format("YYYYMMDD");
    }

    // Set dates
    if (
      this.ebikeRegistration.preparation_data && this.ebikeRegistration
        .preparation_data.guarantee_date
    ) {
      preparationInput.guarantee_date = this.ebikeRegistration.preparation_data.guarantee_date;
    } else {
      preparationInput.guarantee_date = moment(
        this.dateFormatter.format(this.selectedInvoiceDate),
        "DD-MM-YYYY"
      ).format("YYYYMMDD");
    }
    if (this.ebikeRegistration.preparation_data && this.ebikeRegistration.preparation_data.invoice_date) {
      preparationInput.invoice_date = this.ebikeRegistration.preparation_data.invoice_date;
    } else {
      preparationInput.invoice_date = preparationInput.guarantee_date;
    }

    preparationInput.article_code = this.bicycleArticle.single_article_code
      ? bicycleArticle.single_article_code
      : bicycleArticle.article_codes[0];
    this.applicationService.showLoader(true);

    // Ugly checks
    if (
      preparationInput.guarantee_date === "Invalid date" ||
      preparationInput.invoice_date === "Invalid date" ||
      preparationInput.birthdate === "Invalid date"
    ) {
      this.alertService.showPopup(
        this.translateService.instant("ERROR"),
        "error"
      );
      this.applicationService.hideLoader();
      this.loading = false;
      return false;
    }

    this.ebscService
      .registerEzyRider(preparationInput)
      .subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        this.loading = false;
        if (apiResponse.success) {
          this.getEbikeDetails(preparationInput.frame_number, false);
          this.showRegistrationModal(this.getEzyRiderPdf, "ezyRider");
          this.formGroup.get("frame_number").setValue("");
          this.formGroup.get("email").setValue("");
        } else {
          this.showError(apiResponse.result);
        }
      });
    return false;
  }

  private registerService() {
    const serviceInput = new ServiceInput(
      this.formGroup.get("frame_number").value,
      this.serviceSwitch,
      this.formattedDate
    );
    this.ebscService
      .registerService(serviceInput)
      .subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        if (apiResponse.success) {
          this.showRegistrationModal(null, "service");
        } else {
          this.showError(apiResponse.result);
        }
      });
  }

  private showMessage() {
    if (this.ebikeRegistration && this.formGroup.get("frame_number").value) {
      if (
        this.selectedTab === "ezyRider" &&
        this.ebikeRegistration.xtra_guarantee_applicable &&
        !this.ebikeRegistration.prepared
      ) {
        this.alertService.showPopup(
          this.translateService.instant("NOT_PREPARED"),
          "error"
        );
      }
    }
  }

  public showPdf(ebikeRegistration: EbikeRegistrationOverviewItem) {
    this.formGroup.get("frame_number").setValue(ebikeRegistration.frame_number);
    this.showRegistrationModal(this.getRegisterPdf, "registerSalePrepare");
  }

  public showEzyPdf(ebikeRegistration: EbikeRegistrationOverviewItem) {
    this.formGroup.get("frame_number").setValue(ebikeRegistration.frame_number);
    this.showRegistrationModal(this.getEzyRiderPdf, "ezyRider");
  }

  // The modal with feedback and registration pdf
  public showRegistrationModal(pdfFunction: Function, type: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      centered: true,
      container: "#modalContainer",
      size: pdfFunction ? "lg" : null,
      backdrop: "static",
    });
    modalRef.componentInstance.type = type;
    const frameNumber = this.formGroup.get("frame_number").value;

    if (type !== "registerSale" && pdfFunction) {
      modalRef.componentInstance.loading = true;
      pdfFunction(this).subscribe((blob: Blob) => {
        this.createPdfUrl(blob);
        modalRef.componentInstance.pdfSrc = this.pdfSrc;
        modalRef.componentInstance.pdfBlob = this.pdfBlob;
        modalRef.componentInstance.filename = `${this.selectedTab}-${frameNumber}.pdf`;
        modalRef.componentInstance.loading = false;
      });
    }
    //this.bicycleArticle = null;
    this.frameNumber = "";
    this.selectedInvoiceDate = null;
    this.validated = false;
    this.formGroup.reset();
    this.formGroup.get("country").setValue(this.countryCode);
  }

  getDate(rawDate: string) {
    return moment(rawDate, "YYYYMMDD").add(5, "years").format("DD-MM-YYYY");
  }
}
