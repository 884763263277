import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { BatteryDetails, BatteryUpdate } from 'src/app/interfaces/ebsc/battery-details';
import { AlertService } from 'src/app/services/alert.service';
import { EbscService } from 'src/app/services/ebsc.service';
import { HelperService } from 'src/app/services/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from 'src/app/services/application.service';
@Component({
  selector: 'app-battery-details',
  templateUrl: './battery-details.component.html',
  styleUrls: ['./battery-details.component.scss'],
})
export class BatteryDetailsComponent {
  framenumber: string = '';
  batteriesForm!: FormGroup;
  showForm = false;
  showDetails = false;
  showNew = false;
  batteryDetails: BatteryDetails;
  battery1Required: boolean;
  battery2Required: boolean;

  get batteriesFilledIn() {
    const formData = this.batteriesForm.value;
    if (this.battery1Required && !formData.battery1) return false;
    if (this.battery2Required && !formData.battery2) return false;
    if (!formData.battery1 && !formData.battery2) return false;
    return true;
  }

  constructor(private ebscService: EbscService, private fb: FormBuilder, private helperService: HelperService, private alertService: AlertService, private translateService: TranslateService, private applicationService: ApplicationService) {
    this.batteriesForm = this.fb.group({
      battery1: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
      battery2: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
    });
  }

  fetchBatteries() {
    this.applicationService.showLoader();
    this.showNew = false;
    this.batteryDetails = new BatteryDetails();
    this.ebscService.getBatteries(this.framenumber).subscribe(
      (response: ApiResponse) => {
        this.applicationService.hideLoader();
        if (this.helperService.checkResponse(response)) {
          // You can populate fields if API returns initial values
          this.showDetails = true;
          this.batteryDetails = response.result;
          this.setValidators();
        } else {
          this.showDetails = false;
          this.alertService.showPopup(response.result ? response.result : this.translateService.instant('ERROR'), 'error');
        }
      },
      (error) => {
        this.applicationService.hideLoader();
        this.alertService.showPopup(this.translateService.instant('FAILED_FETCH_BATTERIES'), 'error');
      }
    );
  }

  updateValidation() {
    if (this.battery1Required === false && this.battery2Required === false) {
      const battery1Control = this.batteriesForm.get('battery1');
      const battery2Control = this.batteriesForm.get('battery2');

      // If battery1 is filled in 2 is not required (when replacement)
      if (this.batteriesForm.value.battery1) {
        this.setBatteryValidators(false, battery1Control, true);
        battery2Control.clearValidators();
      } 
      
       // If battery2 is filled in 1 is not required (when replacement)
      if (this.batteriesForm.value.battery2) {
        this.setBatteryValidators(false, battery2Control, true);
        battery1Control.clearValidators();
      }
    }
  }

  setBatteryValidators = (required = true, control: any, isRequired: boolean) => {
    if (isRequired) {
      if (required) {
        control.setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(13)]);
      } else {
        control.setValidators([Validators.minLength(10), Validators.maxLength(13)]);
      }

    } else {
      control.clearValidators();
    }
    control.updateValueAndValidity();
  };

  setValidators() {
    const battery1Control = this.batteriesForm.get('battery1');
    const battery2Control = this.batteriesForm.get('battery2');

    if (!this.showNew) {
      this.battery1Required = this.batteryDetails.battery1?.length > 0 && !this.batteryDetails.battery2.length;
      this.setBatteryValidators(false, battery1Control, this.battery1Required);

      this.battery2Required = this.batteryDetails.battery2.length > 0 && !this.batteryDetails.battery1.length;
      this.setBatteryValidators(false, battery2Control, this.battery2Required);
    } else {
      const bothBatteriesEmpty = !this.batteryDetails.battery1?.length && !this.batteryDetails.battery2?.length;
      this.battery1Required = bothBatteriesEmpty;
      this.setBatteryValidators(true, battery1Control, this.battery1Required);

      if (this.batteryDetails.battery1?.length) {
        this.battery2Required = true;
        this.setBatteryValidators(true, battery2Control, this.battery2Required);
        this.battery1Required = false;
        this.setBatteryValidators(true, battery1Control, this.battery1Required);
      } else if (this.batteryDetails.battery2.length) {
        this.battery1Required = true;
        this.setBatteryValidators(true, battery1Control, this.battery1Required);
        this.battery2Required = false;
        this.setBatteryValidators(true, battery2Control, this.battery2Required);
      } else {
        this.battery2Required = false;
        this.setBatteryValidators(true, battery2Control, this.battery2Required);
      }
    }
  }

  checkBatteries() {
    const battery11Exists = this.batteryDetails?.battery1.some(battery => battery.battery_number === this.batteriesForm.value.battery1);
    const battery22Exists = this.batteryDetails?.battery2.some(battery => battery.battery_number === this.batteriesForm.value.battery2);
    const battery12Exists = this.batteryDetails?.battery2.some(battery => battery.battery_number === this.batteriesForm.value.battery1);
    const battery21Exists = this.batteryDetails?.battery1.some(battery => battery.battery_number === this.batteriesForm.value.battery2);


    if (this.batteriesForm.value.battery1 === this.batteriesForm.value.battery2) {
      this.alertService.showPopup(this.translateService.instant('BATTERY_NUMBERS_SAME'), 'warning');
      return;
    } else {
      if (battery11Exists || battery22Exists || battery12Exists || battery21Exists) {
        this.alertService.showPopup(this.translateService.instant('ONE_OR_MORE_BATTERIES_ALREADY_EXISTS'), 'warning');
        return;
      } else {
        return true;
      }
    }
  }

  replaceBatteries() {
    this.showNew = false;
    this.showForm = true;
    this.setValidators();
  }

  save() {
    if (this.batteriesForm.valid) {
      if (this.checkBatteries()) {
        const formData = this.batteriesForm.value;
        let updateData: BatteryUpdate = {
          battery1: formData.battery1 ? formData.battery1 : undefined,
          battery2: formData.battery2 ? formData.battery2 : undefined,
          frame_number: this.framenumber,
        };
        this.ebscService.updateBatteries(updateData).subscribe((response: ApiResponse) => {
          if (this.helperService.checkResponse(response)) {
            this.batteriesForm.reset();
            this.alertService.showPopup(this.translateService.instant("SUCCESS_UPDATE_BATTERIES"), 'success');
            this.showForm = false;
            this.showNew = false;
            this.fetchBatteries();
          } else {
            this.alertService.showPopup(response.result ? response.result : this.translateService.instant("ERROR_UPDATE_BATTERIES"), 'error');
          }
        })
      }

      // Add your save logic here
    } else {
      alert('Please ensure all fields are valid.');
    }
  }
}