import { OpenOrdersResultsCount } from "./openorders/open-orders-results-count";

export interface FinancialPaginatorData {
  numResults: {
    openorders: OpenOrdersResultsCount;
    invoices: {};
    packingslips: {};
  };
  aceNextPage: string;
  acePreviousPage: string;
  currentPage: string;
  aceCurrentPage: string;
  page: number;
  loading: boolean;
  hasNextPage: boolean;
}

export class FinancialPaginatorData implements FinancialPaginatorData {
  constructor() {
    this.numResults = {
      openorders: new OpenOrdersResultsCount(),
      invoices: {},
      packingslips: {}
    };
  }
}
