<div class="row">
    <div class="col-md-12 w-100 row mx-0 border-bottom py-2" *ngFor="let registration of ebikeRegistrations">
        <span class="col-md-2">
            {{formatDate(registration.registration_date)}}
        </span>
        <span class="col-md-2 text-center">
            {{language === 'NL' && registration.xtra_warranty==='X'?('EZY_RIDER'|translate):''}}
            {{language !== 'NL' && registration.xtra_warranty==='X'?('XTRA'|translate):''}}
            <i class="text-success material-icons position-absolute" *ngIf="registration.xtra_warranty" >check_circle</i>
            <i *ngIf="registration.xtra_warranty==='X'" class="material-icons clickable withOpacity position-absolute" style="transform: translateX(25px)"  placement="top" (click)="showEzyPdf(registration)">
              picture_as_pdf
          </i>
        </span>
        <span class="col-md-4" style="text-transform: capitalize;">
            {{registration.brand}} {{registration.model_name}}
        </span>
        <span class="col-md-2">
            <span [articleQuickView]="registration.frame_number">{{registration.frame_number}}</span>
        </span>
        <span class="col-md-2 text-end">
            <i class="material-icons" *ngIf="registration.battery_one"  placement="top" [ngbTooltip]="registration.battery_one">
              battery_charging_full
            </i>
            <i class="material-icons" *ngIf="registration.battery_two"  placement="top" [ngbTooltip]="registration.battery_two">
                battery_charging_full
            </i>
            <i class="material-icons clickable withOpacity"  placement="top" (click)="showPdf(registration)">
                picture_as_pdf
            </i>
        </span>
    </div>
</div>
<ngx-skeleton-loader *ngIf="!loaded" count="20" appearance="line" [theme]="{height: '30px' }"></ngx-skeleton-loader>
