import { Component, OnInit } from '@angular/core';
import { ShippingCostDetails } from 'src/app/interfaces/shipping-cost-details';

@Component({
  selector: 'app-shipping-cost-overview',
  templateUrl: './shipping-cost-overview.component.html',
  styleUrls: ['./shipping-cost-overview.component.scss']
})
export class ShippingCostOverviewComponent implements OnInit {
  shippingCosts: ShippingCostDetails[];
  constructor() { }

  ngOnInit() {
  }

}
