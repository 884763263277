<div class="row mx-0 mb-3">
  <div class="w-100 form-row-group row pb-2" *ngIf="!hideCurrentPassword">
    <div class="col-lg-4">
      <label class="fw-bold">
        {{ "PASSWORD" | translate }}
      </label>
    </div>
    <div class="col-lg-6">
      <input
        class="form-control"
        type="password"
        [(ngModel)]="changePasswordContainer.old_password"
        [attr.required]="!hideCurrentPassword ? 'required' : undefined"
      />
    </div>
    <div class="col-lg-1">
      <i
        class="material-icons text-danger"
        *ngIf="!checkCurrentPassword()"
        style="transform: translateY(5px)"
        >error</i
      >
    </div>
  </div>
  <div class="w-100 form-row-group row pb-4 mt-2">
    <div class="col-lg-12 alertBg">
      {{ "ACCOUNT_FORMAT_PSSWRD" | translate }}
    </div>
  </div>
  <div class="w-100 form-row-group row pb-2">
    <div class="col-lg-4">
      <label class="fw-bold">
        {{ "PASSWORDNEW" | translate }}
      </label>
    </div>
    <div class="col-lg-6">
      <input
        class="form-control"
        type="password"
        [(ngModel)]="changePasswordContainer.new_password"
        [attr.required]="hideCurrentPassword ? 'required' : undefined"
        (keyup)="checkPasswordRequirements()"
      />
    </div>
    <div class="col-lg-1">
      <i
        class="material-icons"
        [hidden]="!changePasswordContainer.new_password"
        [ngClass]="!newPasswordValid ? 'text-danger' : 'text-success'"
        style="transform: translateY(5px)"
        >{{ newPasswordValid ? "check" : "error" }}</i
      >
    </div>
  </div>
  <div class="w-100 form-row-group row pb-2">
    <div class="col-lg-4">
      <label class="fw-bold">
        {{ "PASSWORDREPEAT" | translate }}
      </label>
    </div>
    <div class="col-lg-6">
      <input
        class="form-control"
        type="password"
        [(ngModel)]="changePasswordContainer.confirm_password"
        [attr.required]="
          hideCurrentPassword || changePasswordContainer.new_password
            ? 'required'
            : undefined
        "
        (keyup)="checkPasswordRequirements()"
      />
    </div>
    <div class="col-lg-1">
      <i
        class="material-icons"
        [hidden]="
          !changePasswordContainer.new_password ||
          !changePasswordContainer.confirm_password
        "
        [ngClass]="
          changePasswordContainer.new_password &&
          changePasswordContainer.new_password.length > 2 &&
          changePasswordContainer.new_password ===
            changePasswordContainer.confirm_password
            ? 'text-success'
            : 'text-danger'
        "
        style="transform: translateY(5px)"
        >{{
          changePasswordContainer.new_password &&
          changePasswordContainer.new_password.length > 2 &&
          changePasswordContainer.new_password ===
            changePasswordContainer.confirm_password
            ? "check"
            : "error"
        }}</i
      >
    </div>
  </div>
</div>
