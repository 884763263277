import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ApplicationService } from "src/app/services/application.service";
import { TranslateService } from "@ngx-translate/core";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { LanguageItem } from "src/app/interfaces/language-item";
import { AccountService } from "src/app/services/account.service";
import { CompanyGroup } from "src/app/interfaces/company-group";
import { ApiResponse } from "src/app/interfaces/api-response";
import { User } from "src/app/interfaces/user";
import { AccountSettings } from "src/app/interfaces/account-settings";
import { Credentials } from "src/app/interfaces/credentials";
import { LinkedAccount } from "src/app/interfaces/linked-account";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UnlinkPopupComponent } from "src/app/components/user-components/user-settings/unlink-popup/unlink-popup.component";
import { AuthenticateService } from "src/app/services/authenticate.service";
import { ConfirmDialogComponent } from "src/app/components/modal-components/confirm-dialog/confirm-dialog.component";
import { ChangePasswordModalComponent } from "../change-password-modal/change-password-modal.component";
import { ChangePasswordContainer } from "src/app/interfaces/change-password-container";
import { ChangePasswordContainerComponent } from "../change-password-container/change-password-container.component";
import { Router } from "@angular/router";
import { isNumber } from "underscore";
import { AlertService } from "src/app/services/alert.service";
import { KioskService } from "src/app/services/kiosk.service";
import { HelperService } from "src/app/services/helper.service";
import { CacheService } from "src/app/services/cache.service";
import { linkedDealerInfo } from "src/app/interfaces/account-information";
import { CmsService } from "src/app/services/cms.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-user-settings",
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"],
})
export class UserSettingsComponent implements OnInit {
  @Input() isVerify: boolean;
  @Output() verified: EventEmitter<boolean> = new EventEmitter<boolean>();
  breadcrumbs: Breadcrumb[];
  languages: LanguageItem[];
  companies: CompanyGroup[];
  linkedAccounts: linkedDealerInfo[];
  accountSettings: AccountSettings;
  model: Credentials = new Credentials();
  closed = true;
  languageChanged = false;
  firstSignin = false;
  firstSigninSuccess = false;
  loaded = true;
  alert: string;
  changePasswordContainer: ChangePasswordContainer;
  currentUsername: string;
  usernameChanged: boolean = false;
  usernameFocus: boolean = false;
  @ViewChild(ChangePasswordContainerComponent)
  passwordContainer: ChangePasswordContainerComponent;
  kioskPin: string;
  showDealerOptions = !this.applicationService.isPartnerPortal();

  get emailInvalid() {
    if (this.accountSettings.okta) {
      return !this.helperService.emailRegex.test(
        this.accountSettings?.username
      );
    } else {
      return false;
    }
  }

  constructor(
    public accountService: AccountService,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private modalService: NgbModal,
    private authenticateService: AuthenticateService,
    private router: Router,
    private authenticationService: AuthenticateService,
    private kioskService: KioskService,
    private alertService: AlertService,
    private helperService: HelperService,
    private cacheService: CacheService,
    private cmsService: CmsService,
    private location: Location,
  ) {}

  ngOnInit() {
    this.accountSettings = new AccountSettings();
    this.changePasswordContainer = new ChangePasswordContainer();
    this.firstSignin = this.isVerify;

    this.loadLinkedAccounts();

    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get('success');
    const msg = urlParams.get('msg');

    if (success === 'true') {
      this.alertService.showPopup(msg);
      this.location.go(`/${this.applicationService.getSelectCompanyGroupCode()}/settings`)
    }

    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/home"
      ),
      new Breadcrumb(this.translateService.instant("ACCENTRYSETTINGS"), "#"),
    ];

    // get account settings
    this.accountService
      .getAccountSettings()
      .subscribe((accountResponse: ApiResponse) => {
        if (accountResponse && accountResponse.result) {
          this.loaded = true;
          this.accountSettings = accountResponse.result;
          this.currentUsername = this.accountSettings.username;
          this.firstSignin =
            this.router.url.indexOf("verify") >= 0
              ? !this.accountSettings.verified && !this.accountSettings.okta
              : false;

          if (this.accountSettings.verified && !this.accountSettings.okta) {
            this.verified.next(true);
          }
        }
      });

    // get all available languages
    this.applicationService
      .getLanguages()
      .subscribe((languageResponse: ApiResponse) => {
        const languageList = Array<LanguageItem>();
        const availableLanguages = languageResponse.result;

        // Add all available languages to the select list
        for (const language in availableLanguages) {
          if (availableLanguages.hasOwnProperty(language)) {
            const languageItem = new LanguageItem(
              language,
              availableLanguages[language]
            );
            languageList.push(languageItem);
          }
        }
        this.languages = languageList;
      });

    if (!this.firstSignin) {
      // get all available companies
      this.accountService
        .getAvailableCompanyGroups()
        .subscribe((companyResponse: ApiResponse) => {
          const companyList = Array<CompanyGroup>();
          const availableCompanies = companyResponse.result;
          if (availableCompanies && availableCompanies.length) {
            // Add all available Companies to the select list
            for (const company in availableCompanies) {
              if (availableCompanies.hasOwnProperty(company)) {
                let companyItem = new CompanyGroup();
                companyItem = availableCompanies[company];
                companyList.push(companyItem);
              }
            }
            this.companies = companyList;
          }
        });
    }
  }

  private validateEmail(email) {
    const EMAIL_REGEXP =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return EMAIL_REGEXP.test(email);
  }

  async updateAccountSettings() {
    if (this.currentUsername !== this.accountSettings.username) {
      this.usernameChanged = true;
    }
    if (
      !this.accountSettings.secret_email ||
      (this.accountSettings.secret_email &&
        this.validateEmail(this.accountSettings.secret_email))
      && this.accountSettings.first_name && this.accountSettings.last_name
    ) {
      this.applicationService.showLoader(true);
      const promise = new Promise((resolve, reject) => {
        this.accountService
          .updateAccountSettings(this.accountSettings)
          .subscribe((response: ApiResponse) => {
            if (this.helperService.checkResponse(response)) {
              this.applicationService.hideLoader();
              this.applicationService.saveSetting(
                "userName",
                this.accountSettings.username
              );
              this.applicationService.saveSetting(
                "showShowroomMode",
                String(this.accountSettings.showroom_mode)
              );
              this.applicationService.saveSetting(
                "showShowroomModeDefault",
                String(this.accountSettings.showroom_mode_default)
              );
              this.applicationService.saveShowroomMode(
                String(this.applicationService.getShowroomMode("1"))
              );
              if (this.languageChanged) {
                this.applicationService.saveSelectedCompanyGroupCode(
                  this.applicationService.getSelectCompanyGroupCode()
                );
              }
              if (this.usernameChanged) {
                this.logout();
              }
              resolve(true);
            } else {
              this.alertService.showPopup(response.result ?? "Error", "error");
              this.applicationService.hideLoader();

            }
          });
      });

      promise.then(() => {
        this.applicationService.saveAccountSettings(this.accountSettings);
        this.applicationService.deleteStoredItems("menuItems");

        if (this.passwordContainer) {
          if (this.passwordContainer.passwordMeetsRequirements) {
            this.passwordContainer.submit().then((response) => {
              if (response) {
                this.firstSigninSuccess = true;
              }
            });
          }
        } else {
          if (this.firstSignin) {
            this.firstSigninSuccess = true;
          } else {
            window.location.href = window.location.pathname + `?success=true&msg=${encodeURI(this.translateService.instant('SUCCESS'))}`;
          }
        }
      });
    }
  }

  async updateLanguage() {
    this.applicationService.showLoader(true);
    const promise = new Promise((resolve, reject) => {
      this.cmsService.clearCmsItems(this.applicationService.getSelectCompanyGroupCode());
      this.applicationService
        .updateSelectedLanguage(this.accountSettings.language_code)
        .subscribe((response) => {
          this.applicationService.hideLoader();
          if (this.languageChanged) {
            this.applicationService.saveSelectedCompanyGroupCode(
              this.applicationService.getSelectCompanyGroupCode()
            );
          }
          resolve(true);
        });
    });

    promise.then(() => {
      // Clear translations
      localStorage.removeItem(
        `translations_${this.accountSettings.language_code}_t`
      );
      localStorage.removeItem(
        `translations_${this.accountSettings.language_code}`
      );
      this.applicationService.saveSelectedLanguage(
        this.accountSettings.language_code
      );

      // Use new language
      this.translateService.use(this.accountSettings.language_code);

      // Clears all cache
      this.cacheService.clearCache();
    });
  }

  loadLinkedAccounts() {
    this.linkedAccounts = Array<linkedDealerInfo>();
    this.accountService
      .getLinkedDealers()
      .subscribe((linkedResponse: ApiResponse) => {
        if (this.helperService.checkResponse(linkedResponse)) {
          this.linkedAccounts = linkedResponse.result;
        } else {
          this.linkedAccounts = [];
        }
      });
  }

  link() {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      centered: true,
      size: "sm",
      windowClass: "confirmDialog",
      container: "#modalContainer",
    });
    modalRef.componentInstance.isConfirm = true;
    modalRef.componentInstance.title = this.translateService.instant("CAUTION");
    modalRef.componentInstance.body = this.translateService.instant(
      "LINK_ACCOUNT_USERNAME_REMOVED"
    );
    modalRef.componentInstance.confirmClicked.subscribe(() => {
      this.accountService
        .linkAccount(this.model)
        .subscribe((response: ApiResponse) => {
          if (!response.success) {
            this.closed = false;
            this.alert = response.result;
          } else {
            this.loadLinkedAccounts();
          }
        });
    });
  }

  unlinkPopup(linkedAccount) {
    const modalRef = this.modalService.open(UnlinkPopupComponent, {
      centered: true,
      size: "lg",
      windowClass: "medium",
      container: "#modalContainer",
    });
    modalRef.componentInstance.linkedAccount = linkedAccount;
    modalRef.componentInstance.unlinkObserverEmitted$.subscribe(() => {
      this.loadLinkedAccounts();
    });
  }

  resetOkta() {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      centered: true,
      size: "sm",
      windowClass: "confirmDialog",
      container: "#modalContainer",
    });
    modalRef.componentInstance.isConfirm = true;
    modalRef.componentInstance.title =
      this.translateService.instant("RESET_PASSWORD") + "?";
    modalRef.componentInstance.confirmClicked.subscribe(() => {
      this.authenticateService
        .resetPassword(this.accountSettings.username)
        .subscribe((response: ApiResponse) => {});
    });
  }

  changePassword() {
    const modalRef = this.modalService.open(ChangePasswordModalComponent, {
      centered: true,
      backdrop: "static",
      windowClass: "confirmDialog",
      container: "#modalContainer",
    });

    modalRef.componentInstance.modal = modalRef;
  }

  updateKioskPin() {
    const valid = false;
    if (this.kioskPin.length === 5 && /^\d+$/.test(this.kioskPin)) {
      this.kioskService.updatePinCode(this.kioskPin);
      this.alertService.showInfoNotification(
        this.translateService.instant("UPDATED_KIOSK_PIN")
      );
    }
  }

  logout() {
    this.authenticationService.logout();
    return false;
  }
}
