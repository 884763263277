export interface AddWarrantyArticleRequest {
  warranty_article_id: number;
  warranty_id: number;
  user_id: number;
  frame_number: string;
  article_code: string;
  article_type: string;
  battery_kind: string;
  article_description: string;
  quantity: number;
  reason_id: number;
  reason_type: string;
  characteristic: string;
  remarks: string;
  file_url: string;
  serial_number: string;
  frame_article_code: string;
  extra_warranty: boolean;
  complaint_code: string;
  employee_name: string;
  processed_date: string;
  guarantee_date: string;
  pickup_date: string;
  retour_condition: string;
  battery_type: string;
  article_code_oem: string;
  mileage: string;
  flow_type: string;
  warranty_path_code: string;
  packing_slip_number: string;
}
export class AddWarrantyArticleRequest implements AddWarrantyArticleRequest {
}
