import { Pipe, PipeTransform } from "@angular/core";
import { Article } from "../interfaces/article";
import { TranslateService } from "@ngx-translate/core";
import * as appGlobal from "../globals";
import { AvailableArticleTypes } from "../interfaces/article-type";
import { ApplicationService } from "../services/application.service";
import * as moment from "moment";
@Pipe({
  name: "statusText",
  pure: false
})
export class StatusTextPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private applicationService: ApplicationService
  ) {
    if (!appGlobal.companyGroupSettings) {
      this.applicationService.getSettingsForCompanyGroup(
        this.applicationService.getSelectCompanyGroupCode()
      );
    }
  }

  transform(article: Article, fromModal?: boolean, preOrder?: boolean): any {

    if(!article?.stock_status && article?.stock_status !== 0) {
      return "";
    }

    // Article temporarily not deliverable override
    if (article.temporarily_undeliverable) {
      return this.translateService.instant("TEMPORARILY_UNDELIVERABLE");
    }

    // Fix to show correct stockstatus for WG
    if (this.applicationService.getSelectCompanyGroupCode() === "WG" && article.stock_week === 99) {
      article.stock_week = 0;
    }

    const stockText = this.getStockText(article);
    let weekText = this.getStockWeekText(article);
    const statusText = this.getStockStatusText(
      article,
      stockText,
      fromModal,
      preOrder
    );

    return (
      statusText +
      (statusText && stockText ? " " + stockText : stockText) +
      weekText +
      (article.call_dealersupport
        ? ". " + this.translateService.instant("CALL_DEALERSUPPORT")
        : "")
    );

  }

  // Get stock status
  private getStockStatusText(article: Article, stockText: string, fromModal: boolean, preOrder?: boolean) {
    let statusText = "";

      if (preOrder && article.always_order && article.stock_week === 99) {
        statusText = this.translateService.instant("STATUS_6");
      } else {
        if (fromModal && article.stock_status === 1) {
          statusText = this.translateService.instant("DIRECT_DELIVERY") + " "
        } else {
          if (!fromModal) {
            statusText = article.stock_status >= 3 || !stockText
              ? this.translateService.instant("STATUS" + article.stock_status)
              : "";

            if (article.stock_week === 99) {
              statusText = this.translateService.instant("STATUS4")
            }
            if (article.stock_status === 0) {
              const status0Key = `STATUS0_${article.company_id}`;
              const status0Translation = this.translateService.instant(`STATUS0_${article.company_id}`);
              statusText = status0Translation !== status0Key ? status0Translation + " " : this.translateService.instant("STATUS0");
            }
            else if (article.stock_status !== 1) {
              statusText += " ";
            }
          }
        }
      }

    return statusText;
  }

  // Get stock amount
  private getStockText(article: Article) {
    const stockText =
      appGlobal.companyGroupSettings &&
        article.stock_status < 3 &&
        (((!appGlobal.companyGroupSettings.stock_hide_parts &&
          (article.article_type !== AvailableArticleTypes.F)) ||
          article.article_type === AvailableArticleTypes.F) &&
          article.stock)
        ? (!this.applicationService.isDach() ? (this.translateService.instant("STOCK") + ": " + article.stock + " ") : "")
        : "";

    return stockText;
  }

  // Which week
  private getStockWeekText(article: Article) {
    let weekText = "";

    if (article.stock_status === 3) {
      // Start with "WEEK" and stock week/year
      weekText = this.translateService.instant("WEEK") +
                 " " +
                 `${article.stock_week}/${article.stock_year}`;

      // If stock_date is defined, add it with additional context based on company group
      if (typeof article.stock_date !== "undefined") {
        const groupCode = this.applicationService.getSelectCompanyGroupCode();
        const weekTextPrefix = ["WG","GHO"].includes(this.applicationService.getSelectCompanyGroupCode())
                                ? ' (' + this.translateService.instant("IN_WEEK_OF") + ' '
                                : ' (';

        weekText += weekTextPrefix + article.stock_date + ")";
      }
    }

    return weekText;
  }
}
