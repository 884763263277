import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { YearAgreementBase } from 'src/app/interfaces/year-agreement-base';

@Component({
  selector: 'app-agreement-selector',
  templateUrl: './agreement-selector.component.html',
  styleUrls: ['./agreement-selector.component.css'],
})
export class AgreementSelectorComponent implements OnInit {
  @Input() agreements: Array<YearAgreementBase> = [];
  @Output() agreementSelected: EventEmitter<YearAgreementBase> =
    new EventEmitter<YearAgreementBase>();
  @Input() selectedAgreement: number;
  constructor() {}

  ngOnInit(): void {}

  selected() {
    const agreement = this.agreements.find((a) => {
      return a.id === Number(this.selectedAgreement);
    });
    this.agreementSelected.emit(agreement);
  }
}
