import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { WarrantyArticle } from "src/app/interfaces/warranty/warranty-article";
import { Article } from "src/app/interfaces/article";
import { FormGroup } from "@angular/forms";
import { WarrantyPart } from "src/app/interfaces/warranty/warranty-part";
import { LeaseService } from "src/app/services/lease.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { BicycleLeaseInformation } from "src/app/interfaces/lease/bicycle-lease-information";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { WarrantyService } from "src/app/services/warranty.service";
import { CheckLeaseArticleInput } from "src/app/interfaces/warranty/check-lease-article-input";
import { CheckLeaseArticleFeedback } from "src/app/interfaces/warranty/check-lease-article-feedback";
import { Subscription } from "rxjs";

@Component({
  selector: "app-warranty-leasing",
  templateUrl: "./warranty-leasing.component.html",
  styleUrls: ["./warranty-leasing.component.scss"],
})
export class WarrantyLeasingComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() bicycle: Article;
  @Input() warrantyId: number;
  @Input() currentArticle: WarrantyArticle;
  @Output() bicycleSelect: EventEmitter<Article> = new EventEmitter<Article>();
  @Output() partSelect: EventEmitter<Article> = new EventEmitter<Article>();
  @Output() getContent: EventEmitter<string> = new EventEmitter<string>();
  bicycleUnknown: boolean;
  leaseType = "";
  framenumber: string;
  isLease: boolean;
  approved: boolean;
  subscription: Subscription;
  showApproveMessage: boolean;
  constructor(
    private leaseService: LeaseService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private warrantyService: WarrantyService
  ) {}

  ngOnInit() {
    if (this.formGroup.get("framenumber").value) {
      this.getBicycleDetails(this.bicycle);
    }
    if (this.formGroup.get("complaintCode").value) {
      this.showApproveMessage = false;
    }
    if (
      this.formGroup.get("articleCode").value &&
      !this.formGroup.get("complaintCode").value
    ) {
      this.checkArticleWarranty();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes["currentArticle"] &&
      changes["currentArticle"]["currentValue"]
    ) {
      if (this.currentArticle && this.currentArticle.reason_type === "SER") {
        this.leaseType = "lease_service";
      } else {
        this.leaseType = "lease_bike_part";
      }
    }
  }

  getBicycleDetails(bicycle: Article) {
    if (this.formGroup.get("framenumber").value) {
      this.showApproveMessage = false;

      this.leaseService
        .getLeaseBicycleDetails(this.formGroup.get("framenumber").value)
        .subscribe((apiResponse: ApiResponse) => {
          if (apiResponse && apiResponse.success) {
            const bicycleDate = apiResponse.result as BicycleLeaseInformation;
            if (bicycleDate.lease_bike) {
              this.isLease = true;
              if (bicycleDate.km_registration) {
                this.formGroup.get("mileage").enable();
              } else {
                this.formGroup.get("mileage").disable();
              }
            } else {
              this.alertService.showPopup(
                bicycleDate.message
                  ? bicycleDate.message
                  : this.translateService.instant("NO_LEASE_BICYCLE"),
                "error"
              );
              this.isLease = false;
              this.framenumber = "";
              this.formGroup.get("framenumber").setValue("");
            }
          } else {
            this.alertService.showPopup(
              this.translateService.instant("NO_LEASE_BICYCLE"),
              "error"
            );
            this.isLease = false;
            this.framenumber = "";
            this.formGroup.get("framenumber").setValue("");
          }
        });
    }
  }


  bicycleSelected(bicycle: Article) {
    if (this.bicycle) {
      this.formGroup.get("mileage").disable();
      this.formGroup.get("mileage").setValue("");
      this.formGroup.get("articleCodeOem").setValue("");
      this.formGroup.get("articleCode").setValue("");
    }
    this.bicycleUnknown = bicycle ? false : true;
    this.getBicycleDetails(bicycle);
    this.bicycleSelect.next(bicycle);
  }

  partSelected(data: WarrantyPart) {
    this.formGroup
      .get("articleCodeOem")
      .setValue(data.article.article_code_oem);
    this.formGroup
      .get("articleDescription")
      .setValue(!data.article.article_details ? data.article.description : data.article.article_details.description);
    this.formGroup
      .get("articleCode")
      .setValue(data.article.single_article_code);
    this.partSelect.next(data.article);

    this.checkArticleWarranty();
  }

  // Check if the service article can be claimed
  checkArticleWarranty() {
    if (
      this.formGroup.get("articleCode").value &&
      this.formGroup.get("framenumber").value &&
      ((this.formGroup.get("mileage").enabled &&
        this.formGroup.get("mileage").value) ||
        !this.formGroup.get("mileage").enabled)
    ) {
      const input = new CheckLeaseArticleInput(
        this.formGroup.get("articleCode").value,
        this.formGroup.get("articleCodeOem").value,
        this.formGroup.get("framenumber").value,
        this.formGroup.get("mileage").value,
        this.formGroup.get("complaintCode").value
      );
      this.warrantyService
        .checkLeaseWarrantyArticle(input)
        .subscribe((apiResponse: ApiResponse) => {
          if (apiResponse && apiResponse.success) {
            const feedback = apiResponse.result as CheckLeaseArticleFeedback;
            if (!feedback.approved) {
              this.alertService.showPopup(
                feedback.message
                  ? feedback.message
                  : this.translateService.instant("NOT_APPROVED"),
                "error"
              );
              this.formGroup.get("articleValid").setValue("");
              this.approved = false;
            } else {
              this.formGroup.get("articleValid").setValue(1);
              this.approved = true;
            }
          } else {
            this.alertService.showPopup(
              this.translateService.instant("NOT_APPROVED"),
              "error"
            );
            this.formGroup.get("articleValid").setValue("");
            this.approved = false;
          }
          this.showApproveMessage = true;
        });
    }
  }

  switchTab(type: string) {
    const pageIds = {
      lease_service: "960422069",
      lease_bike_part: "960422068",
    };
    this.leaseType = type;
    this.formGroup.get("articleCode").setValue("");
    this.getContent.emit(pageIds[this.leaseType]);
    this.formGroup.get("mileage").disable();

    if (this.leaseType === "lease_service") {
      this.formGroup.get("articleValid").setValue("");
    } else {
      this.formGroup.get("articleValid").setValue(1);
    }

    if (!this.isLease && this.formGroup.get("framenumber").value) {
      this.bicycleSelected(null);
    }
  }
}
