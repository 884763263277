import { Component, OnInit, Input } from "@angular/core";
import { AtmosphericImage } from "src/app/interfaces/imageBank/atmospheric-image";
import { ImagebankService } from "src/app/services/imagebank.service";
import { ApplicationService } from "src/app/services/application.service";
import { AccountService } from "src/app/services/account.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { HelperService } from "src/app/services/helper.service";
import { saveAs } from 'file-saver';
import { ImageBankFileType } from "src/app/interfaces/image-bank-file-type";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "src/app/services/alert.service";

@Component({
  selector: "app-image-bank-atmospheric",
  templateUrl: "./image-bank-atmospheric.component.html",
  styleUrls: ["./image-bank-atmospheric.component.scss"],
})
export class ImageBankAtmosphericComponent implements OnInit {
  brandid = 1;
  @Input() image: AtmosphericImage;
  @Input() showDeleteButton = false;
  deleted = false;
  fileType: string = "image";
  constructor(
    private imageService: ImagebankService,
    private applicationService: ApplicationService,
    private alertService: AlertService, private translateService: TranslateService,
    private helperService: HelperService
  ) { }

  ngOnInit() {
    const filename = this.image.cdn_name.toLowerCase();
    const extension = filename.split(".").pop();
    let fileType = "";
    switch (extension) {
      case "jpg":
      case "svg":
      case "tif":
        fileType = ImageBankFileType.image;
        break;
      case "png":
      case "ai":
      case "eps":
        fileType = ImageBankFileType.other_image;
        break;
      case "mp4":
        fileType = ImageBankFileType.video;
        break;
      case "pdf":
        fileType = ImageBankFileType.pdf;
        break;
      default:
        fileType = ImageBankFileType.other_image;
        break;
    }
    this.fileType = fileType.toString();
  }

  downloadImage(url, name) {
    saveAs(url, name)
  }

  deleteImage(id) {
    this.applicationService.showLoader(true);
    this.imageService
      .deleteBrandImage(id)
      .subscribe((apiRespponse: ApiResponse) => {
        this.applicationService.hideLoader();
        if (this.helperService.checkResponse(apiRespponse)) {
          this.deleted = true;
        }
      });
  }

  copy(text: string) {
    this.helperService.copyTextToClipboard(text);
    this.alertService.showInfoNotification(this.translateService.instant("COPIED"))
  }
}
