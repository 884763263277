<h1>{{ "RETURNS" | translate }} #{{ id }}</h1>
<ng-container *ngIf="warrantyDetails && selectedForShipment">
  <div
    class="art-container"
    [hidden]="!groupedArticles.lease || !groupedArticles.lease.length"
  >
    <h2 class="mb-0">
      {{ "LEASE" | translate }} ({{ "SERVICE" | translate }} /
      {{ "SERVICE_MAINTENANCE" | translate }})
    </h2>
    <h5>{{ "DISPOSE_ARTICLES" | translate }}</h5>
    <app-select-for-warranty-shipment
      [hidden]="!warrantyDetails.active"
      [name]="'lease_wrt'"
      [selectedValue]="selectedForShipment.lease"
      (changed)="
        selectedForShipment.saveSelection('lease', id, $event);
        selectedForShipment.lease = $event
      "
    >
    </app-select-for-warranty-shipment>
    <app-warranty-requests-container
      [articles]="groupedArticles.lease"
      [showControls]="false"
      [ngClass]="selectedForShipment.lease ? '' : 'inActive'"
    >
    </app-warranty-requests-container>
  </div>

  <div
    class="art-container"
    [hidden]="!groupedArticles.regular || !groupedArticles.regular.length"
  >
    <h2>{{ "REGULAR_ARTICLES" | translate }}</h2>

    <div class="col-4 my-2 px-0" *ngIf="addresses && addresses.length">
      <label>{{ "PICKUP_ADDRESS" | translate }}</label>
      <app-pickup-address-select
        [addresses]="addresses"
        #pickupAddressSelectComponent
      ></app-pickup-address-select>
    </div>

    <div class="row" *ngIf="warrantyDetails.active">
      <div class="mb-3 col-md-4 col-12" style="max-width: 250px">
        <app-select-pickup-date
          #selectPickupDateComponent
          class="mb-2 d-inline-block"
          [type]="'regular'"
          [disabled]="!selectedForShipment.regular"
        ></app-select-pickup-date>
      </div>
      <div class="mb-3 col-md-4 col-12" style="max-width: 250px">
        <label>{{ "COLLI" | translate }}</label>
        <input
          type="number"
          class="form-control"
          min="0"
          [(ngModel)]="warrantyDetails.colli"
          [disabled]="!selectedForShipment.regular"
          [ngClass]="
            selectedForShipment.regular && !warrantyDetails.colli
              ? 'border-danger'
              : ''
          "
        />
      </div>
      <div class="mb-3 col-md-4 col-12" style="max-width: 250px">
        <label>{{ "WEIGHT" | translate }}</label>
        <input
          type="number"
          class="form-control"
          min="0"
          [(ngModel)]="warrantyDetails.weight"
          [disabled]="!selectedForShipment.regular"
          [ngClass]="
            selectedForShipment.regular && !warrantyDetails.weight
              ? 'border-danger'
              : ''
          "
        />
      </div>
    </div>
    <app-select-for-warranty-shipment
      [hidden]="!warrantyDetails.active"
      [name]="'regular_wrt'"
      [selectedValue]="selectedForShipment.regular"
      (changed)="
        selectedForShipment.saveSelection('regular', id, $event);
        selectedForShipment.regular = $event
      "
    >
    </app-select-for-warranty-shipment>
    <app-warranty-requests-container
      [articles]="groupedArticles.regular"
      [showControls]="false"
      [ngClass]="selectedForShipment.regular ? '' : 'inActive'"
    >
    </app-warranty-requests-container>
  </div>

  <div
    class="art-container"
    [hidden]="
      !groupedArticles.regular_dispose ||
      !groupedArticles.regular_dispose.length
    "
  >
    <h2 class="mt-2">{{ "DISPOSE_ARTICLES" | translate }}</h2>
    <app-warranty-requests-container
      [articles]="groupedArticles.regular_dispose"
      [showControls]="false"
    >
    </app-warranty-requests-container>
  </div>

  <div
    class="art-container"
    [hidden]="!groupedArticles.batteries || !groupedArticles.batteries.length"
  >
    <h2 class="mt-2">{{ "BATTERIES_AND_CHARGERS" | translate }}</h2>
    <div class="col-4 my-2 px-0" *ngIf="addresses && addresses.length">
      <label>{{ "PICKUP_ADDRESS" | translate }}</label>
      <app-pickup-address-select
        [addresses]="addresses"
        #pickupAddressSelectBatteryComponent
      ></app-pickup-address-select>
    </div>
    <app-select-pickup-date
      #selectPickupDateBatteriesComponent
      class="mb-2 d-block"
      [type]="'batteries'"
      [disabled]="!selectedForShipment.batteries"
    ></app-select-pickup-date>
    <app-select-for-warranty-shipment
      [hidden]="!warrantyDetails.active"
      [name]="'regular_batteries'"
      [selectedValue]="selectedForShipment.batteries"
      (changed)="
        selectedForShipment.saveSelection('batteries', id, $event);
        selectedForShipment.batteries = $event
      "
    >
    </app-select-for-warranty-shipment>
    <app-warranty-requests-container
      [articles]="groupedArticles.batteries"
      [showControls]="false"
      [ngClass]="selectedForShipment.batteries ? '' : 'inActive'"
    >
    </app-warranty-requests-container>
  </div>

  <div
    class="art-container"
    [hidden]="!groupedArticles.motors || !groupedArticles.motors.length"
  >
    <h2 class="mt-2">{{ "MOTOR" | translate }}</h2>
    <div class="col-4 my-2 px-0" *ngIf="addresses && addresses.length">
      <label>{{ "PICKUP_ADDRESS" | translate }}</label>
      <app-pickup-address-select
        [addresses]="addresses"
        #pickupAddressSelectMotorComponent
      ></app-pickup-address-select>
    </div>
    <app-select-pickup-date
      #selectPickupDateMotorsComponent
      class="mb-2 d-block"
      [type]="'motors'"
      [disabled]="!selectedForShipment.motors"
    ></app-select-pickup-date>
    <app-select-for-warranty-shipment
      [hidden]="!warrantyDetails.active"
      [name]="'regular_mtr'"
      [selectedValue]="selectedForShipment.motors"
      (changed)="
        selectedForShipment.saveSelection('motors', id, $event);
        selectedForShipment.motors = $event
      "
    >
    </app-select-for-warranty-shipment>
    <app-warranty-requests-container
      [articles]="groupedArticles.motors"
      [showControls]="false"
      [ngClass]="selectedForShipment.motors ? '' : 'inActive'"
    >
    </app-warranty-requests-container>
  </div>

  <!-- Buttons -->
  <app-bottom-navbar [fixed]="true">
    <ng-container *ngTemplateOutlet="options"></ng-container>
  </app-bottom-navbar>

  <ng-template #options>
    <div class="col-6 col-md-6 px-0">
      <a
        class="btn btn-dark btn-lg"
        [routerLink]="['/' + companyGroupCode + '/warranty/' + id]"
        >{{ "BACK" | translate }}</a
      >
    </div>
    <div class="col-6 col-md-6 px-0 text-end">
      <button
        class="btn btn-success text-white btn-lg"
        [disabled]="sendDisabled || loading ? 'disabled' : undefined"
        *ngIf="warrantyDetails && warrantyDetails.active"
        (click)="sendWarranty()"
      >
        {{ "SEND" | translate }}
      </button>
    </div>
  </ng-template>
</ng-container>
