<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{"SELECT_ACCOUNT" | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
    </button>
</div>

<div class="modal-body p-3">

  <ul class="list-group">
    <li *ngFor="let dealer of dealers" class="list-group-item">
      {{dealer.dealer_name}} - {{dealer.dealer_code}} <span *ngIf="dealer.active" class="badge bg-success">{{"ACTIVE"|translate}}</span><br/>
      {{dealer.address.street}} {{dealer.address.housenumber}} {{dealer.address.housenumber_addition}}<br/>
      {{dealer.address.zip}} {{dealer.address.city}}<br/>
      <button class="btn btn-sm btn-primary mt-2" (click)="select(dealer)">{{"SELECT"|translate}}</button>
    </li>
  </ul>
</div>

