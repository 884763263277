<div class="position-fixed top-0 bar" *ngIf="basket?.id">
    <div class="groupedArticles customScrollbar pb-5">
        <div class="position-relative">

            <!-- Locked basket overlay -->
            <div class="top-0 basket-locked position-absolute w-100 h-100 d-flex justify-content-center align-items-top"
                *ngIf="basket?.locked">
                <div class="flex mt-5 justify-content-center align-items-center">
                    <span class="me-2 material-icons text-danger">
                        lock
                    </span>
                    <span class="me-3">{{ 'BASKET_LOCKED' | translate }}</span><button
                        class="btn btn-sm btn-outline-primary" (click)="unlockBasket()">{{ 'UNLOCK_BASKET' |
                        translate
                        }}</button>
                </div>
            </div>

            <div *ngIf="!basket?.locked" class="d-flex flex-column">
                <!-- Parts -->
                <!-- Define ng-template outside of the loops -->
                <ng-template #itemTemplate let-article="article">
                    @defer (on viewport) {
                    <div class="article-item text-center px-2 pt-1 my-1 bg-white rounded shadow-sm"
                        [class.has-parent]="article.parent_id">
                        <app-article-image [imageUrl]="article.item_details?.thumbs[0]" (click)="gotoItem(article)"
                            class="clickable withOpacity" [articleType]="article.item_details?.article_type"
                            [useLazyLoad]="true" [description]="article.item_details?.description"
                            [classList]="'img-thumbnail'" [height]="'64'">
                        </app-article-image>
                        <app-stock-status class="col-2 ms-1" [showText]="false" [tooltipPlacement]="'bottom'" *ngIf="
                          !article?.item_details?.setitem &&
                          !basketLocked &&
                          (!article?.warehouse || article?.item_details?.stock_status < 3)
                        " [article]="article?.item_details"></app-stock-status>


                        <p (click)="gotoItem(article)" class="clickable withOpacity"><strong>{{
                                article.item_details?.description }}</strong></p>

                        <div class="d-inline-flex align-items-center">
                            <input type="number" [(ngModel)]="article.amount" placeholder="Enter quantity" class="form-control-sm border pe-0 ps-1"
                                [disabled]="article.parent_id"
                                [min]="article.item_details?.min_order_amount ? article.item_details?.min_order_amount : 1"
                                max="9999" (change)="onQuantityChange(article)" />
                            <i class="material-icons delete-icon clickable withOpacity" (click)="deleteArticle(article)"
                                style="margin-right: -15px; opacity: 0.8;" [style.visibility]="
                               basketLocked || (article.is_mandatory && article.parent_found)? 'hidden' : 'visible'
                            ">
                                delete
                            </i>
                        </div>

                        <div>
                            <app-article-sum [netPrice]="article.price"
                                [retailPrice]="article.item_details?.retail_price"
                                class="price d-inline-block pt-1 mt-1" [amount]="article.amount"></app-article-sum>
                        </div>
                    </div>
                    }@placeholder {
                    <div class="d-flex justify-content-center" style="min-height: 100px;">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </div>
                    }
                </ng-template>
                <span class="bg-dark w-100 px-2 clickable withOpacity header-part-type" (click)="collapseParts = !collapseParts"
                    *ngIf="groupedArticles?.parts?.items?.length">
                    <i class="material-icons" style="font-size: 1rem; vertical-align: middle;">
                        {{ collapseParts ? 'expand_more' : 'chevron_right' }}
                    </i>
                    {{ "PARTS" | translate }}
                </span>
                <div class="px-2" [hidden]="collapseParts">
                    <div *ngFor="let article of groupedArticles?.parts.items">
                        <ng-container *ngTemplateOutlet="itemTemplate; context: { article: article }"></ng-container>
                    </div>
                </div>
                <span class="bg-dark w-100 px-2 clickable withOpacity header-part-type" (click)="collapseBicycles = !collapseBicycles"
                    *ngIf="groupedArticles?.bicycles?.items?.length">
                    <i class="material-icons" style="font-size: 1rem; vertical-align: middle;">
                        {{ collapseBicycles ? 'expand_more' : 'chevron_right' }}
                    </i>
                    {{ "BICYCLES" | translate }}
                </span>
                <div class="px-2" [hidden]="collapseBicycles">
                    <div *ngFor="let article of groupedArticles?.bicycles.items">
                        <ng-container *ngTemplateOutlet="itemTemplate; context: { article: article }"></ng-container>
                    </div>
                </div>
            </div>

        </div>
        <button *ngIf="!basket?.locked" class="btn btn-primary position-absolute mx-2 start-0 end-0 bottom-0 mb-1"
            [routerLink]="selectedCompanyGroup + '/basket/' + basket.id"
            style="z-index: 9999999;">{{"CHECKOUT"|translate}}</button>
    </div>