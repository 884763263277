export interface TokenContainer {
  bearer_token: string;
  refresh_token: string;
  expires: string;
  account_type: string;
  language: string;
  selected_dealer: string;
  session_token: string;
  selected_userid: number;
  linked: boolean;
  okta_registration: boolean;
  editor: boolean;
  version: string;
  features: string[];
}

export class TokenContainer implements TokenContainer {}
