import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { Price } from "src/app/interfaces/price";
import { ApplicationService } from "src/app/services/application.service";
import { ArticleService } from "src/app/services/article.service";

@Component({
  selector: "app-volume-prices",
  templateUrl: "./volume-prices.component.html",
  styleUrls: ["./volume-prices.component.scss"]
})
export class VolumePricesComponent implements OnInit {
  @Input() price: any = [];
  @Input() regularPrice: Price;
  @Input() headerLeft: string;
  @Input() headerRight: string;
  @Input() popoverTitle: string;
  parsedPrices: any = [];
  showroomMode: boolean;

  constructor(private applicationService: ApplicationService, private articleService: ArticleService) {}

  ngOnInit() {
    this.parsePrices();
    this.getShowroomMode();
    this.applicationService.changeEmitted$.subscribe(response => {
      this.getShowroomMode();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["price"]) {
      this.parsePrices();
    }
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  parsePrices() {
    const volumePrices = this.price;
    this.parsedPrices = [];
    if (typeof this.price !== 'undefined') {
      if (volumePrices && volumePrices.length) {
        for (const volumePrice of volumePrices) {
          let hidePrice = false;
          let price = this.articleService.getVolumePrice(volumePrice);

          if (price.value > this.regularPrice?.value) {
            hidePrice = true;
          }

          if (!hidePrice) {
            this.parsedPrices.push({
              volume: volumePrice['volume'],
              price: this.articleService.getVolumePrice(volumePrice),
            });
          }
        }
      }
    }
  }
}
