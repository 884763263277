<div class="row h-100 px-0">
  <div
    class="col-12 col-lg-7 px-0 px-sm-2 itemContainer customScrollbar"
    [ngClass]="selectForWarrantyMode ? 'hideCompare' : ''"
  >
    <div class="custom-checkbox mb-1 d-lg-none ms-4">
      <div class="checkbox">
        <label *ngIf="!selectForWarrantyMode">
          <input
            type="checkbox"
            (click)="checkAll()"
            id="checkAll"
            [(ngModel)]="checkAllState"
            (change)="checkAll()"
          />
          <span class="box"></span>
          {{ "SELECT_ALL" | translate }}
        </label>
      </div>
    </div>

    <div class="d-none d-lg-flex justify-content-around">
      <span>
        <input
          type="checkbox"
          class="d-inline-block"
          (click)="checkAll()"
          id="checkAll"
          [(ngModel)]="checkAllState"
          *ngIf="!selectForWarrantyMode"
          (change)="checkAll()"
        />
        <label
          class="ms-1"
          *ngIf="!selectForWarrantyMode"
          (click)="checkAllState = !checkAllState; checkAll()"
          >{{ "SELECT_ALL" | translate }}</label
        >
      </span>
      <span class="ms-auto d-none d-sm-block">
        <i
          class="material-icons clickable withOpacity zoom me-2"
          [ngClass]="{ disabled: imageSize <= 56 }"
          (click)="setImageSize(-16)"
        >
          list_alt
        </i>
        <i
          class="material-icons clickable withOpacity zoom"
          [ngClass]="{ disabled: imageSize >= 72 }"
          (click)="setImageSize(16)"
        >
          view_agenda
        </i>
      </span>
    </div>
    <ul class="list-group ps-0" (mouseleave)="showDetails = true">
      <ng-container *ngIf="imageSize === 72">
        <app-list-item
          *ngFor="let article of articles; let i = index; trackBy: articleId"
          [ngClass]="selectedIndex === i ? 'selected' : ''"
          [article]="article"
          [index]="i"
          (selectArticle)="selectArticle($event, article)"
          (openModal)="openModal($event)"
          (selectArticle)="selectArticle(article, i, $event)"
        ></app-list-item>
      </ng-container>
      <ng-container *ngIf="imageSize < 72">
        <li
          [ngClass]="article === selectedArticle ? 'active' : ''"
          *ngFor="let article of articles; let i = index; trackBy: articleId"
          class="list-group-item"
        >
          <app-tree-view-item
            [showArticleCode]="true"
            [index]="i"
            [imageSize]="imageSize"
            class="mainItem mb-2 mb-lg-0 border-bottom"
            [listClass]="article === selectedArticle ? 'active' : ''"
            [article]="article"
            [showArticleCode]="selectForWarrantyMode"
            [showPrices]="true"
            (itemLeave)="showDetails = false"
            (expandArticle)="expandArticle(article)"
            (checkArticle)="articleChecked($event)"
            (selectArticle)="selectArticle($event, article)"
          >
            <ng-container *ngFor="let bomarticle of article.bom_articles">
              <app-tree-view-item
                [showArticleCode]="true"
                *ngIf="bomarticle.id !== article.id"
                class="subItem"
                [imageSize]="imageSize"
                [showArticleCode]="selectForWarrantyMode"
                [listClass]="bomarticle === selectedArticle ? 'active' : ''"
                [article]="bomarticle"
                (itemLeave)="showDetails = false"
                (selectArticle)="selectArticle($event, bomarticle)"
                (checkArticle)="articleChecked($event)"
                (expandArticle)="expandArticle(bomarticle)"
              >
                <app-tree-view-item
                  [showArticleCode]="true"
                  *ngFor="let subbomarticle of bomarticle.bom_articles"
                  class="subItem"
                  [showArticleCode]="selectForWarrantyMode"
                  [imageSize]="imageSize"
                  [listClass]="
                    subbomarticle === selectedArticle ? 'active' : ''
                  "
                  [article]="subbomarticle"
                  (itemLeave)="showDetails = false"
                  (selectArticle)="selectArticle($event, subbomarticle)"
                  (checkArticle)="articleChecked($event)"
                >
                </app-tree-view-item>
              </app-tree-view-item>
            </ng-container>
          </app-tree-view-item>
        </li>
      </ng-container>
    </ul>
  </div>
  <div
    class="col-md-5 details h-100 pe-0 ms-2 ms-lg-0"
    style="margin-top: 20px"
  >
    <app-article-details
      #articleDetails
      [style.display]="showDetails ? '' : 'none'"
      [textCollapsed]="textCollapsed"
      [showDetailed]="false"
      class="d-none d-lg-block"
      [modelId]="selectedArticle ? selectedArticle.id : null"
      (showArticleDetails)="gotoArticleDetails($event)"
      [selectForWarrantyMode]="selectForWarrantyMode"
      [leaseRequest]="leaseRequest"
      [openArticleInModal]="isBom"
    >
    </app-article-details>
  </div>
</div>
