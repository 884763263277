<!-- Company groups -->
<div class="company-group-dropdown-wrapper">
  <ul class="company-group-dropdown">
    <ng-container *ngFor="let company of availableCompanyGroups">
      <li
        class="d-flex row mx-0"
      >
        <span
          class="d-flex align-items-center group-logo justify-content-center col-2 py-2 px-1 clickable withOpacity"
          (click)="setCompanyGroup($event, company.company_group_code)"
        >
          <img
            class="dropdown-logo"
            [src]="
              './assets/images/logos/' +
              company.company_group_code.toLowerCase() +
              '.png'
            "
          />
        </span>
        <div
          class="d-flex align-items-center brands justify-content-start col-9 ms-0 pe-1 py-2 text-center"
          [ngClass]="'brands_' + company.company_group_code"
        >
          <div class="brands-container d-flex">
            <ng-container
              *ngFor="let brand of brands[company.company_group_code]"
            >
              <span
                *ngIf="
                  (brand.bike_count > 0 || brand.type === 'parts') && brand.show
                "
                class="d-flex flex-column justify-content-around align-content-around h-100 px-2"
              >
                <span class="brand-logo-wrapper d-flex align-items-center justify-content-center pb-1">
                  <img
                    class="d-block brand-logo px-2 clickable withOpacity"
                    [src]="'./assets/images/logos/brands/' + brand.logo + '?t=2'"
                    (click)="
                      gotoSearch(company.company_group_code, brand.name, $event, brand.type)
                    "
                  />
                </span>
                <small>
                  <ng-container *ngIf="brand.type === 'bicycles'"
                    >{{ brand.bike_count }}
                    {{ (brand.bike_count === 1 ? "MODEL" : "MODELS") | translate | lowercase }}&nbsp;
                  </ng-container>
                </small>
              </span>
            </ng-container>
          </div>
        </div>
        <span
          class="col-1 d-flex align-items-center justify-content-end ps-0 py-2"
        >
          <button
            class="btn btn-sm btn-default d-flex align-items-center"
            (click)="setCompanyGroup($event, company.company_group_code)"
          >
            <i class="material-icons">chevron_right</i>
          </button>
        </span>
      </li>
    </ng-container>
  </ul>
</div>
