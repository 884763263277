<td class="py-2" style="max-width: 30px;">
</td>
<td class="clickable withOpacity fw-medium" (click)="viewPackingSlip()">
  <i class="material-icons text-primary clickable withOpacity me-1" style="transform: translateY(2.5px);" ngbTooltip="{{ 'VIEW_PACKINGSLIP' | translate }}">
    picture_as_pdf
  </i>
  <u>{{packingSlip?.packing_slip_number}}</u>
</td>
<td class="description">
  {{packingSlip.shipment}}
</td>
<td>
  <div class="d-flex flex-column align-items-start">
    <span class="text-end">
      <label>{{'ORDERNUMBER' | translate}}:</label> <app-download-open-order [orderNumber]="packingSlip.ace_id"></app-download-open-order>
    </span>
    <span class="text-end" *ngIf="packingSlip.invoice_number && applicationService.getSelectCompanyGroupCode() === 'WG'">
      <label>{{'PACKAGE_NUMBER' | translate}}:</label> <i>{{packingSlip.invoice_number}}</i>
    </span>
    <span class="text-end" *ngIf="packingSlip.invoice_number && applicationService.getSelectCompanyGroupCode() !== 'WG'">
      <label>{{'INVOICE_NUMBER' | translate}}: </label><b class="clickable withOpacity" (click)="showInvoice(packingSlip)"><i class="material-icons">
        picture_as_pdf
      </i> {{packingSlip.invoice_number}}</b>
    </span>
  </div>
</td>
<td *ngIf="!hideStatus">
  {{packingSlip.order_status_text}}
</td>
<td>
  {{packingSlip.shipment_date}}
</td>
<td>
  {{packingSlip | shippingAddressText}}
</td>
<td>
  <a [href]="packingSlip.track_and_trace_url" target="_new" *ngIf="packingSlip.track_and_trace_url" class="d-inline-block" class="ms-2" ngbTooltip="{{ 'TRACK_ORDER' | translate }}">
    <i class="material-icons text-primary clickable withOpacity">
      local_shipping
    </i>
    <i class="material-icons text-primary clickable withOpacity">
      my_location
    </i> {{getLogisticsCompany(packingSlip.track_and_trace_url)}}
  </a>
</td>
