<app-packing-slips-filters (searchSubmitted)="getFilters($event)" [shippingAddressGroup]="shippingAddressGroup">
</app-packing-slips-filters>
<div class="mb-4 mt-1 tableContainer customScrollbar">
  <span *ngIf="packingSlips && packingSlips.length">{{ 'SHOWS_INVOICES_FROM' | translate}}: <strong>{{formattedDateFrom}}</strong> {{ 'TILL' | translate }} <strong>{{formattedDateTo}}</strong></span>
  <table class="table">
    <thead>
      <tr class="financial-item"  *ngIf="packingSlips && packingSlips.length">
        <td style="max-width: 30px">

        </td>
        <td>
          {{'PACKINGSLIP_NUMBER'|translate}}
        </td>
        <td>
          {{'DESCRIPTION'|translate}}
        </td>
        <td>
          {{'ACEREFERENCEIDS'|translate}}
        </td>
        <td *ngIf="!hideStatus">
          {{'STATUS'|translate}}
        </td>
        <td>
          {{'SHIPMENT_DATE'|translate}}
        </td>
        <td>
          {{'SHIPPINGADDRESS'|translate}}
        </td>
        <td>{{"TRACK_ORDER"|translate}}</td>
      </tr>
      <tr *ngIf="!packingSlips?.length && loaded">
        <td class="border-0">
          <app-no-results></app-no-results>
        </td>
      </tr>
    </thead>
    <tbody>
      <app-packing-slip-line *ngFor="let packingSlip of packingSlips; let rowIndex = index" [packingSlip]="packingSlip" [hideStatus]="hideStatus"
        [attr.data-index]="rowIndex + 1 + ((pageData.page - 1) * 50)"
        class="w-100 d-block d-table-row small financial-item">
      </app-packing-slip-line>
    </tbody>
  </table>

  <ngx-skeleton-loader *ngIf="!loaded" count="20" appearance="line" [theme]="{height: '80px' }">
  </ngx-skeleton-loader>

</div>
<!-- Paginator -->
<app-financial-paginator [(pageData)]="pageData" (navigation)="navigationChanged($event)"  *ngIf="packingSlips && packingSlips.length">
