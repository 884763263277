import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { timer } from "rxjs";
import { AlertService } from "src/app/services/alert.service";
import { ApplicationService } from "src/app/services/application.service";
import { SearchService } from "src/app/services/search.service";

@Component({
  selector: "app-save-filters",
  templateUrl: "./save-filters.component.html",
  styleUrls: ["./save-filters.component.css"],
})
export class SaveFiltersComponent implements OnInit {
  type: string = "";
  name: string = "";
  currentFilters = [];
  storageKey = "savedFilters";
  constructor(
    private alertService: AlertService,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private router: Router,
    private searchService: SearchService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.currentFilters = this.getSavedFilters();
  }

  save() {
    let list = this.getSavedFilters();

    list.unshift({
      location: window.location.hash,
      name: this.name,
    });
    this.alertService.showInfoNotification(
      this.translateService.instant("FILTER_SAVED")
    );

    localStorage.setItem(this.storageKey, JSON.stringify(list.reverse()));
    this.currentFilters = this.getSavedFilters();
    this.name = "";
  }

  private getSavedFilters() {
    const list = localStorage.getItem(this.storageKey);
    if (list !== null) {
      try {
        return JSON.parse(list).reverse();
      } catch (err) {
        return [];
      }
    } else {
      return [];
    }
  }

  gotoFilter(filter) {
    this.router.navigateByUrl(
      `${this.applicationService.getSelectCompanyGroupCode()}/search${
        filter.location
      }`
    );
    timer(0).subscribe(() => {
      this.searchService.setGroups("");
      this.modalService.dismissAll();
    });
  }

  deleteFilter(index: number) {
    this.currentFilters.splice(index, 1);
    localStorage.setItem(this.storageKey, JSON.stringify(this.currentFilters));
  }
}
