export interface PricelabelSettings {
    currency: string;
    include_barcode: boolean;
    include_description: boolean;
    include_logo: boolean;
    include_price: boolean;
    logo_accentry: number;
    logo_custom_filename: string;
    logo_custom_url: string;
    logo_own: boolean;
    format_id: number;
    sizes: number;
    start_position: number;
    logosrc: string;
}

export class PricelabelSettings implements PricelabelSettings { }
