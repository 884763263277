import { Component, OnInit, ViewChild } from "@angular/core";
import {
  NgbActiveModal,
  NgbCarousel,
  NgbCarouselConfig,
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { Slideshow } from "src/app/interfaces/cms/slideshow";
import { NgbSlideEvent } from "@ng-bootstrap/ng-bootstrap/carousel/carousel";
import { SlideshowService } from "src/app/services/cms/slideshow.service";

@Component({
  selector: "app-slideshow-modal",
  templateUrl: "./slideshow-modal.component.html",
  styleUrls: ["./slideshow-modal.component.scss"],
  providers: [NgbCarouselConfig] // add NgbCarouselConfig to the component providers
})
export class SlideshowModalComponent implements OnInit {
  slideshow: Slideshow;
  @ViewChild(NgbCarousel) carousel: NgbCarousel;
  activeSlide = "";

  constructor(public modal: NgbActiveModal, config: NgbCarouselConfig, private modalService: NgbModal, private slideshowService: SlideshowService) {
    config.interval = 0;
    config.keyboard = true;
    config.wrap = false;
    config.showNavigationArrows = false;
    config.showNavigationIndicators = false;
  }

  ngOnInit() {
    this.activeSlide = `${this.slideshow.id}_ngb-slide-0`;

    // Mark slideshow immediately as seen
    this.slideshowService.markSlideshowAsSeen(this.slideshow);
  }

  slideChange(event: NgbSlideEvent) {
    this.activeSlide = event.current;
  }

  prev() {
    this.carousel.prev();
  }

  next() {
    this.carousel.next();
  }

  select(slide) {
    this.carousel.select(`${this.slideshow.id}_ngb-slide-${slide}`);
  }

  openSlideshow(slideshow: Slideshow, checkIfSeen: boolean) {
    if (!checkIfSeen || (checkIfSeen && !this.slideshowService.checkIfSlideshowSeen(slideshow))) {
      const modalRef = this.modalService.open(SlideshowModalComponent, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        windowClass: 'large',
        container: "#modalContainer",
        centered: true
      });

      modalRef.componentInstance.slideshow = slideshow;
    }
  }

  get lastSlide() {
    const lastInfoItemId = this.slideshow.language_definitions.length - 1;
    return `${this.slideshow.id}_ngb-slide-${lastInfoItemId}`;
  }

  closeModal() {
    if (this.slideshow.name && this.slideshow.name.toLowerCase().indexOf("update") >= 0) {
      window.location.reload();
    }
    this.modal.dismiss();
  }
}
