<ng-container *ngIf="homepageQuicklinks; else navbar">
  <ul class="list-group list-group-flush">
    <li
      *ngFor="let quickLink of quicklinks"
      class="list-group-item bg-transparent ps-0 py-1"
    >
      <a
        href="#"
        class="text-decoration-none"
        [ngClass]="quickLinkClass"
        (click)="gotoLink(quickLink, $event)"
      >
        {{ quickLink.language_definitions[0].quicklink_text }}
      </a>
    </li>
  </ul>
</ng-container>

<ng-template #navbar>
  <div  [@pageAnimations]>
  <ul class="list-group list-group-flush navbar-quicklinks ani-item">
    <ng-container *ngFor="let quickLink of quicklinks">
      <li
        class="list-group-item bg-transparent ps-0 py-0"
        (click)="gotoLink(quickLink, $event)"
      >
        <a
          href="#"
          class="text-decoration-none"
          [ngClass]="quickLinkClass"
          (click)="gotoLink(quickLink, $event)"
        >
          {{ quickLink.language_definitions[0].quicklink_text }}
        </a>
      </li>
    </ng-container>
  </ul>
</div>
</ng-template>
