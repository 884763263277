<div class="modal-header">
  <h4
    class="modal-title"
    id="modal-basic-title"
    [innerHTML]="
      article.description + ' ' + (article.caption ? article.caption : '')
    "
  ></h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    </button>
</div>
<div class="modal-body">
  <app-article-details
    #details
    [(modelId)]="modelId"
    [showDetailed]="showDetailed"
    [showInModal]="true"
    style="margin-top: 0"
  >
    <h3>{{"PREORDER"|translate}} <ng-container *ngIf="month.month">{{month.month}} - {{month.year}}</ng-container><ng-container *ngIf="!month.month"> - {{ "DELIVER_WHEN_POSSIBLE" | translate }}</ng-container></h3>
    <table class="table">
      <tr *ngFor="let week of weeksList">
        <ng-container *ngIf="(week.weekNumber >= article.stock_week && article.stock_year <= month.year) || (article.stock_year < month.year) || week.weekNumber === 0">
          <td class="p-0">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" style="width: 180px"
                  >
                  <ng-container *ngIf="week.weekNumber">
                    {{ "WEEK" | translate }} {{ week.weekNumber }} ({{week.date}})
                  </ng-container>
                    <ng-container *ngIf="!week.weekNumber">
                    {{ "QUANTITY" | translate }}
                  </ng-container>
                </span>
              </div>
              <input
                type="number"
                [min]="article.min_order_amount ? article.min_order_amount : 0"
                max="9999"
                class="form-control"
                [(ngModel)]="quantities[week.weekNumber]"
                style="max-width: 70px"
              />
            </div>
          </td>
        </ng-container>
      </tr>
    </table>
    <button class="mb-2 btn btn-success btn-lg" (click)="save()">
      {{ "SAVE" | translate }}
    </button>
  </app-article-details>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-dark" (click)="modal.dismiss('Close')">
    {{ "CLOSE" | translate }}
  </button>
</div>
