import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-warranty-framenumber-input",
  templateUrl: "./warranty-framenumber-input.component.html",
  styleUrls: ["./warranty-framenumber-input.component.scss"]
})
export class WarrantyFramenumberInputComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Output() inputClicked: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit() {}

  inputClick() {
    this.inputClicked.emit(true);
  }
}
