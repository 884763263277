import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "src/app/services/alert.service";
import { ApplicationService } from "src/app/services/application.service";
import * as signalR from "@microsoft/signalr";
import { environment } from "src/environments/environment";
import { StockNotification } from "src/app/interfaces/stockNotifications/stock-notification";
import * as _ from "underscore";

@Component({
  selector: "app-stock-notifications-container",
  templateUrl: "./stock-notifications-container.component.html",
  styleUrls: ["./stock-notifications-container.component.scss"],
})
export class StockNotificationsContainerComponent implements OnInit {
  notifications: StockNotification[] = [];
  connection: signalR.HubConnection;
  constructor(
    private alertService: AlertService,
    private router: Router,
    private applicationService: ApplicationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {

    if (
      typeof fetch !== "undefined" &&
      typeof AbortController === "undefined"
    ) {
      console.warn(
        "Fetch is supported, but not AbortController.  Dropping default fetch so SignalR can override."
      );
      window.fetch = undefined;
    }

    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(environment.socketapiendpoint, {
        accessTokenFactory: () => {
          // Get and return the access token.
          // This function can return a JavaScript Promise if asynchronous
          return "Bearer " + localStorage.getItem("bearerToken");
        },
      })
      .build();

    connection
      .start()
      .then(function () {
        console.log("SignalR Connected!");
        connection.invoke("Hello");
      })
      .catch(function (err) {
        return console.error(err.toString());
      });

    connection.on("ReceiveNotification", (message) => {
      this.showStockNotification(message);
      connection.invoke("ConfirmNotificationReceived", message.id);
    });

    this.connection = connection;
  }

  ngOnDestroy(): void {}

  showStockNotification(message: StockNotification) {
      this.notifications.push(message);
  }


  closeNotification(message: StockNotification) {
    const notifications = [...this.notifications];
    this.notifications = _.without(
      notifications,
      _.findWhere(notifications, {
        id: message.id,
      })
    );
  }

  closeAll() {
    this.notifications = [];
  }
}
