<div #preorderTableContainer>
  <div class="row table-container pt-4">
    <div class="col-6 col-md-4 col-lg-4 text-end px-0" style="max-width: 180px">
      <table class="" *ngIf="article && !article.is_mandatory_item">
        <tr class="tableHeader d-block">
          <td class="text-end">&nbsp;</td>
        </tr>
        <ng-container *ngIf="article && article.matrix">
          <ng-container *ngFor="let option of matrixOptions">
            <ng-container *ngFor="let optionArticle of option.value">
              <tr class="d-block" [ngClass]="'article-' + optionArticle.single_article_code">
                <td class="text-end">
                  <small class="me-2 d-inline-block" [ngClass]="[option.value.length > 1 ? 'multiple' : '']"
                    style="min-width: 65px; transform: translateY(4px)">{{optionArticle.single_article_code}}
                    <ng-container *ngIf="option.value.length > 1"><br />{{option.description}}</ng-container>
                  </small>
                  <button  class="btn btn-dark articleRowLabel" [triggers]="touchscreen?'click':'hover'"
                    placement="top" [ngbTooltip]="optionArticle | statusText:false:true"
                    [ngClass]="[useTimeSlots ? 'timeSlot' : '', optionArticle.single_article_code === article.single_article_code ? 'btn-primary selected' : 'btn-dark', 'company_' + optionArticle.company_id, 'status_' + (optionArticle.always_order && optionArticle.stock_week == 99 ? '6' : (optionArticle.stock_status ? optionArticle.stock_status : 5))]">{{option.value.length > 1 ? optionArticle.value : option.description}}
                    <i></i>
                  </button>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
      </table>

    </div>
    <div class="col-6 col-md-8 col-lg-8 col-xl-9 ps-0 customScrollbar"
      [ngClass]="schedule && schedule.length > 8 ? 'overflow-auto' :''" *ngIf="schedule && schedule.length">
      <table #container class="float-left" [ngClass]="!article.single_article_code ? 'noSelection' : ''">
        <tr class="tableHeader d-block" *ngIf="article && !article.is_mandatory_item">
          <td class="p-0"></td>
          <td>
            <span class="d-inline-block text-center deliverNow" [ngClass]="{'timeSlot':useTimeSlots}" placement="right" triggers="click:blur"
              ngbTooltip="{{'DELIVER_WHEN_POSSIBLE'|translate}}">
              <img src="./assets/images/now.svg">
            </span>
          </td>
          <ng-container *ngIf="!onlyFlex">
            <td class="yearDivider" *ngIf="schedule.length && schedule[0].month > 1">
              {{startYear}} &raquo;
            </td>
            <ng-container>
              <ng-container *ngFor="let month of schedule; let monthIndex = index">
                <td class="yearDivider" *ngIf="month.month === 1">
                  {{startYear + (month.year - startYear)}} &raquo;
                </td>
                <td *ngIf="!useTimeSlots || availableTimeSlots.indexOf(month.month) >= 0">
                  <input type="text" readonly="true" [value]="monthDescriptions[month.month - 1]"
                    [ngClass]="{'timeSlot':useTimeSlots}" class="box border-0 text-center">
                </td>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="showDemoRepair">
            <td>
              <input type="text" readonly="true" [value]="'DEMO'|translate" [ngClass]="{'timeSlot':useTimeSlots}"  *ngIf="article.retail_price && article.retail_price.value < demoMaxRetailPrice"
                class="box border-0 text-center">
            </td>
            <td>
              <input type="text" readonly="true" [value]="'REPAIR'|translate" [ngClass]="{'timeSlot':useTimeSlots}" *ngIf="article.retail_price && article.retail_price.value < repairMaxRetailPrice"
                class="box border-0 text-center">
            </td>
          </ng-container>
        </tr>
        <ng-container *ngIf="article && article.matrix && !article.is_mandatory_item">
          <ng-container *ngFor="let option of matrixOptions">
            <tr class="d-block" *ngIf="option.value.length === 1">
              <ng-container *ngFor="let optionArticle of option.value">
                <td>
                  <app-preorder-month-input [inputIndex]="0" [monthSelectionOnly]="monthSelectionOnly" [type]="type" [month]="{}" [optionArticle]="optionArticle"
                    (checkQuantity)="checkQuantity()" [ngClass]="{'timeSlot':useTimeSlots}" [brand]='article.brand'
                    [value]="selectedArticles[optionArticle.single_article_code] ? selectedArticles[optionArticle.single_article_code]['amount'] : ''">
                  </app-preorder-month-input>
                </td>
                <td class="yearDivider" *ngIf="schedule.length && schedule[0].month > 1 && !onlyFlex">
                  <span>{{startYear}} &raquo;</span>
                </td>
                <ng-container *ngFor="let month of schedule; let monthIndex = index" >
                  <td class="yearDivider" *ngIf="month.month === 1">
                    <span>{{startYear + (month.year - startYear)}} &raquo;</span>
                  </td>
                  <td *ngIf="(!useTimeSlots || availableTimeSlots.indexOf(month.month) >= 0) && !onlyFlex">
                    <app-preorder-month-input [monthSelectionOnly]="monthSelectionOnly" [type]="type" [month]="month" [optionArticle]="optionArticle"
                      [ngClass]="{'timeSlot':useTimeSlots}" [brand]='article.brand' (checkQuantity)="checkQuantity()"
                      [value]="selectedArticles[optionArticle.single_article_code+month.month.toString()+month.deliveryYear.toString()] ? selectedArticles[optionArticle.single_article_code+month.month.toString()+month.deliveryYear.toString()]['amount'] : ''">
                    </app-preorder-month-input>
                  </td>

                </ng-container>
                <ng-container *ngIf="showDemoRepair">
                  <td>
                    <app-preorder-month-input [monthSelectionOnly]="monthSelectionOnly" [type]="type" [month]="demo" [max]="'5'" [optionArticle]="optionArticle" *ngIf="optionArticle.retail_price && optionArticle.retail_price.value < demoMaxRetailPrice"
                      [ngClass]="{'timeSlot':useTimeSlots}" [brand]='article.brand' (checkQuantity)="checkQuantity()"
                      [attr.data-test]="optionArticle.single_article_code+'month'"
                      [value]="selectedArticles[optionArticle.single_article_code+'demo'] ? selectedArticles[optionArticle.single_article_code+'demo']['amount'] : ''">
                    </app-preorder-month-input>
                  </td>
                  <td>
                    <app-preorder-month-input [monthSelectionOnly]="monthSelectionOnly" [type]="type" [month]="repair" [max]="'5'" [optionArticle]="optionArticle" *ngIf="optionArticle.retail_price && optionArticle.retail_price.value < repairMaxRetailPrice"
                      [ngClass]="{'timeSlot':useTimeSlots}" [brand]='article.brand' (checkQuantity)="checkQuantity()"
                      [attr.data-test]="optionArticle.single_article_code+'repair'"
                      [value]="selectedArticles[optionArticle.single_article_code+'repair'] ? selectedArticles[optionArticle.single_article_code+'repair']['amount'] : ''">
                    </app-preorder-month-input>
                  </td>
                </ng-container>
              </ng-container>
            </tr>

            <ng-container *ngIf="option.value.length > 1">
              <tr *ngFor="let optionArticle of option.value" class="d-block">
                <td>
                  <app-preorder-month-input [monthSelectionOnly]="monthSelectionOnly" [type]="type" [month]="{}"  [optionArticle]="optionArticle"
                    (checkQuantity)="checkQuantity()" [ngClass]="{'timeSlot':useTimeSlots}" [brand]='article.brand'
                    [value]="selectedArticles[optionArticle.single_article_code] ? selectedArticles[optionArticle.single_article_code]['amount'] : ''">
                  </app-preorder-month-input>
                </td>
                <ng-container *ngIf="!onlyFlex">
                  <td class="yearDivider inRow" *ngIf="schedule.length && schedule[0].month > 1">
                    <span>{{startYear}} &raquo;</span>
                  </td>
                  <ng-container *ngFor="let month of schedule; let monthIndex = index">
                    <td class="yearDivider" *ngIf="month.month === 1">
                      <span>{{startYear + 1}} &raquo;</span>
                    </td>
                    <td *ngIf="(!useTimeSlots || availableTimeSlots.indexOf(month.month) >= 0)">
                      <app-preorder-month-input [monthSelectionOnly]="monthSelectionOnly" [type]="type" [month]="month" [optionArticle]="optionArticle"
                        [ngClass]="{'timeSlot':useTimeSlots}" [brand]='article.brand' (checkQuantity)="checkQuantity()"
                        [attr.data-test]="optionArticle.single_article_code+month.month.toString()+month.deliveryYear.toString()"
                        [value]="selectedArticles[optionArticle.single_article_code+month.month.toString()+month.deliveryYear.toString()] ? selectedArticles[optionArticle.single_article_code+month.month.toString()+month.deliveryYear.toString()]['amount'] : ''">
                      </app-preorder-month-input>
                    </td>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="showDemoRepair">
                  <td>
                    <app-preorder-month-input [monthSelectionOnly]="monthSelectionOnly" [type]="type" [month]="demo" [max]="'5'" [optionArticle]="optionArticle"  *ngIf="optionArticle.retail_price && optionArticle.retail_price.value < demoMaxRetailPrice"
                      [ngClass]="{'timeSlot':useTimeSlots}" [brand]='article.brand' (checkQuantity)="checkQuantity()"
                      [attr.data-test]="optionArticle.single_article_code+'month'"
                      [value]="selectedArticles[optionArticle.single_article_code+'demo'] ? selectedArticles[optionArticle.single_article_code+'demo']['amount'] : ''">
                    </app-preorder-month-input>
                  </td>
                  <td>
                    <app-preorder-month-input [monthSelectionOnly]="monthSelectionOnly" [type]="type" [month]="repair" [max]="'5'" [optionArticle]="optionArticle" *ngIf="optionArticle.retail_price && optionArticle.retail_price.value < repairMaxRetailPrice"
                      [ngClass]="{'timeSlot':useTimeSlots}" [brand]='article.brand' (checkQuantity)="checkQuantity()"
                      [attr.data-test]="optionArticle.single_article_code+'repair'"
                      [value]="selectedArticles[optionArticle.single_article_code+'repair'] ? selectedArticles[optionArticle.single_article_code+'repair']['amount'] : ''">
                    </app-preorder-month-input>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
         <ng-container *ngIf="article && article.is_mandatory_item">
          <tr>
            <td class="p-2">
              <label *ngIf="article && article.is_mandatory_item" class="pt-2 pe-2">
                {{"QUANTITY"|translate}}:
              </label>
              <app-preorder-month-input [monthSelectionOnly]="monthSelectionOnly" [type]="type" [month]="{}" [optionArticle]="article"
              (checkQuantity)="checkQuantity()" [ngClass]="{'timeSlot':useTimeSlots}" [brand]='article.brand'
              [value]="selectedArticles[article.single_article_code] ? selectedArticles[article.single_article_code]['amount'] : ''">
              </app-preorder-month-input>
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  </div>
  <div class="">
    <button class="btn mt-2 btn-outline-danger me-2" *ngIf="(type === 'wishlist' || article.is_favorite) && !article.is_mandatory_item"
      (click)="removeFromWishList()">{{'REMOVE_FROM_WISHLIST'|translate}}</button>

    <button class="btn mt-2 btn-dark me-2" *ngIf="type !== 'wishlist' && !article.is_favorite && !article.is_mandatory_item"
      [disabled]="loading_wishlist" style="min-width: 150px;" (click)="addToWishList()">
      <ng-container *ngIf="!loading_wishlist">{{'ADD_TO_WISHLIST'|translate}}</ng-container>
      <div class="spinner spinner-border spinner-border-sm text-white " role="status" *ngIf="loading_wishlist">
        <span class="visually-hidden">{{'LOADING'|translate}}</span>
      </div>
    </button>
    <button class="btn mt-2" style="min-width: 100px;" *ngIf="(!addedToBasket || selectedArticles) && !hideSaveButton"
      [disabled]="(!addEnabled && !selectedArticles) || loading"
      [ngClass]="addEnabled || selectedArticles ? 'btn-success' : 'disabled btn-default'" (click)="addToBasket()">
      <ng-container *ngIf="!loading">{{(type === 'wishlist'?'ADD_TO_BASKET':'SAVE_PREORDER')|translate}}</ng-container>
      <div class="spinner spinner-border spinner-border-sm text-white " role="status" *ngIf="loading">
        <span class="visually-hidden">{{'LOADING'|translate}}</span>
      </div>
    </button>
    <ng-content></ng-content>

  </div>
  <div class="" *ngIf="addedToBasket && !selectedArticles && type !== 'wishlist'">
    <button class="btn btn-outline-success d-block mb-1">{{'ADDED'|translate}}</button>
    <span class="text-primary d-block clickable withOpacity"
      (click)="resetForm(); addEnabled = false; addedToBasket = false">{{'RESET'|translate}}</span>
  </div>
</div>
