export interface GuaranteeDate {
  warranty_end_date: string;
  warranty_end_date_converted: string;
}



export class GuaranteeDate implements GuaranteeDate {

}
