import { Component, OnInit, Input } from '@angular/core';
import { Article } from 'src/app/interfaces/article';
import { ApplicationService } from 'src/app/services/application.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-to-lease-button',
  templateUrl: './add-to-lease-button.component.html',
  styleUrls: ['./add-to-lease-button.component.scss']
})
export class AddToLeaseButtonComponent implements OnInit {
  @Input() article: Article;
  @Input() fromLeaseRequest: boolean;
  @Input() disabled: boolean;
  formGroup: FormGroup;
  channel: any;
  @Input() btnClass = "lg";
  constructor(private applicationService: ApplicationService, private router: Router, private modalService: NgbModal) { }

  ngOnInit() {
  }

  addToLease() {

    // close modal (when articledetails is in modal)
    this.modalService.dismissAll();
    const articleCode = this.article.single_article_code ? this.article.single_article_code : this.article.article_codes[0];
    // Close tab and go to lease request
    if (this.fromLeaseRequest && typeof BroadcastChannel !== "undefined") {
      this.channel = new BroadcastChannel("leaseRequestChannel");
      this.channel.postMessage({ articleCode: articleCode });


     this.channel.onmessage = (e) => {
        const message = e.data;
        if (message["closeTab"]) {
            window.close();
            return false;
        }
      }
    }

    this.router.navigateByUrl(
      `${this.applicationService.getSelectCompanyGroupCode()}/leasing/new?articleCode=${articleCode}`
    )
  }

  ngOnDestroy(): void {
    if (this.channel) {
      this.channel.close();
    }
  }
}
