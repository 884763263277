import { Component, OnInit, Input } from "@angular/core";
import { FinancialService } from "src/app/services/financial.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { BalanceFilters } from "src/app/interfaces/financial/balance/balance-filters";
import { ShippingAddressGroup } from "src/app/interfaces/shipping-address-group";
import { BalanceItem } from "src/app/interfaces/financial/balance/balance-item";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-balance",
  templateUrl: "./balance.component.html",
  styleUrls: ["./balance.component.scss"],
})
export class BalanceComponent implements OnInit {
  @Input() shippingAddressGroup: ShippingAddressGroup;
  items: BalanceItem[] = [];
  balanceData: { balance_due: string; balance_expired: string; limit: string };
  loaded = false;
  dateFrom: any = {
    year: "",
    month: "",
    day: "",
  };
  dateTo: any = {
    year: "",
    month: "",
    day: "",
  };
  formattedDateFrom: string;
  formattedDateTo: string;
  page: number = 1;
  totalPages: number = 1;
  keyWord: any;
  filters: any;
  allResultsLoaded: boolean;
  allResults: any[];

  constructor(
    private financialService: FinancialService,
    public applicationService: ApplicationService
  ) {}

  ngOnInit() {
    const filters = new BalanceFilters("all", "", "");
    //this.getFilters(filters);
  }

  getFilters(selectedFilters: any) {
    const filters = new BalanceFilters("all");
    filters.options.article_type = "All";
    this.page = 1;
    if (
      selectedFilters.filters &&
      selectedFilters.filters.invoice_state_filter
    ) {
      filters.options.invoice_state_filter = "All";
      filters.options.open_invoices =
        selectedFilters.filters.invoice_state_filter === "O";

      // Date from
      const dateFrom = this.financialService.getDate(
        selectedFilters.filters.date_from
      );
      filters.options.date_from = dateFrom;

      this.dateFrom = {
        year: dateFrom.substring(0, 4),
        month: dateFrom.substring(4, 6),
        day: dateFrom.substring(6, 8),
      };
      this.formattedDateFrom = `${this.dateFrom.day}-${this.dateFrom.month}-${this.dateFrom.year}`;

      // Date to
      const dateTo = this.financialService.getDate(
        selectedFilters.filters.date_to
      );
      filters.options.date_to = dateTo;

      this.dateTo = {
        year: dateTo.substring(0, 4),
        month: dateTo.substring(4, 6),
        day: dateTo.substring(6, 8),
      };
      this.formattedDateTo = `${this.dateTo.day}-${this.dateTo.month}-${this.dateTo.year}`;

      // Winora company filter
      filters.options.company_id_filter = Number(
        selectedFilters.filters.companyFilter
      )
        ? Number(selectedFilters.filters.companyFilter)
        : null;
      this.keyWord = selectedFilters.filters.keyWord;
      this.filters = filters;
      this.getBalance(filters, selectedFilters.filters.keyWord);
    }
  }

  getBalance(filters: BalanceFilters, keyWord?: string) {
    let items = [];
    if (!keyWord) {
      filters.options.page = this.page;
    } else if (this.allResultsLoaded) {
      // Don't load all when already cached
      this.filterResults(keyWord, this.allResults);
      this.applicationService.hideLoader();
      this.loaded = true;
      return false;
    } else {
      filters.options.page = null;
    }

    this.applicationService.showLoader(true);

    this.financialService
      .getBalance(filters)
      .subscribe((apiResponse: ApiResponse) => {
        this.loaded = true;
        if (
          apiResponse &&
          apiResponse.success &&
          apiResponse.result &&
          apiResponse.result.items
        ) {
          items = apiResponse.result.items;
          this.applicationService.hideLoader();
        } else {
          this.applicationService.hideLoader();
        }

        this.balanceData = {
          balance_due: apiResponse.result?.balance_due,
          balance_expired: apiResponse.result?.balance_expired,
          limit: apiResponse.result?.limit,
        };

        if (keyWord) {
          this.filterResults(keyWord, items);
          this.allResults = items;
          this.allResultsLoaded = true;
        } else {
          this.items = this.page === 1 ? items : this.items.concat(items);
        }

        this.totalPages = apiResponse.result["total_pages"];
        window.scrollTo(0, 0);
      });
  }

  filterResults(keyWord, items) {
    keyWord = keyWord.toUpperCase();
    let filteredItems: BalanceItem[];
    filteredItems = items.filter((i: BalanceItem) => {
      return i.invoice_number.indexOf(keyWord) >= 0;
    });
    items = filteredItems;
    this.items = items;
  }

  getSelection() {}

  showMore() {
    if (this.page < this.totalPages && !this.keyWord) {
      this.page++;

      this.filters.options.page = this.page;
      this.getBalance(this.filters, "");
    }
  }
}
