import { Component, OnInit } from '@angular/core';
import { CompanyGroup } from 'src/app/interfaces/company-group';
import { AccountService } from 'src/app/services/account.service';
import { ApplicationService } from 'src/app/services/application.service';
import { FinancialService } from 'src/app/services/financial.service';
import { saveAs } from 'file-saver';

@Component({
  selector: "app-company-data-page",
  templateUrl: "./company-data-page.component.html",
  styleUrls: ["./company-data-page.component.scss"],
})
export class CompanyDataPageComponent implements OnInit {
  fileURL: string;
  loading: boolean = true;
  accountType: any;
  companyGroupCode: string;
  companies: any = [];
  companySettings: CompanyGroup;

  constructor(
    private financialService: FinancialService,
    private applicationService: ApplicationService,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.accountType = this.accountService.getAccountType();
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
    this.companySettings = this.applicationService.getSettingsForCompanyGroup(
      this.companyGroupCode
    ) as CompanyGroup;
    for (let companyID of this.companySettings.companies) {
      if (companyID === 101) {
        this.getFileUrl("WS");
      } else if (companyID === 102) {
        this.getFileUrl("WBP");
      }
    }
  }

  downloadPdf(companyID: string) {
     this.applicationService.showLoader(true);
    this.financialService
      .getCompanyDataPdfFile(companyID)
      .subscribe((blob: Blob) => {
        const file = new Blob([blob], { type: "application/pdf" });
        saveAs(file, "statistics.pdf");
        this.applicationService.hideLoader();
      });
  }

  getFileUrl(companyID: string) {
    this.applicationService.showLoader(true);
    this.financialService
      .getCompanyDataPdfFile(companyID)
      .subscribe((blob: Blob) => {
        const file = new Blob([blob], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const company = {
          companyID: companyID,
          fileURL: fileURL,
        };
        this.companies.push(company);
        this.applicationService.hideLoader();
      });
  }
}
