<div class="modal-header">
  <h3 class="modal-title" id="modal-basic-title">{{ slideshow.name }}</h3>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  >
    </button>
</div>

<div class="modal-body p-3">
  <ngb-carousel #carousel (slide)="slideChange($event)">
    <ng-template
      ngbSlide
      *ngFor="let slide of slideshow.language_definitions; let i = index"
      [id]="slideshow.id + '_ngb-slide-' + i"
    >
      <app-slide [slide]="slide"></app-slide>
    </ng-template>
  </ngb-carousel>
  <div class="custom-carousel-indicators">
    <div *ngIf="slideshow.language_definitions.length > 1">
      <span
        *ngFor="let slide of slideshow.language_definitions; let i = index"
        class="indicator"
        [class.active]="slideshow.id + '_ngb-slide-' + i == activeSlide"
        (click)="select(i)"
      ></span>
    </div>
  </div>
  <div class="modal-footer mt-3">
    <button
      (click)="prev()"
      [hidden]="activeSlide === slideshow.id + '_ngb-slide-0'"
      class="carousel-navigation-button btn btn-primary me-auto"
    >
      {{ "PREVIOUS" | translate }}
    </button>
    <button
      (click)="next()"
      [hidden]="activeSlide === lastSlide"
      class="carousel-navigation-button btn btn-primary"
    >
      {{ "NEXT" | translate }}
    </button>
    <button
      (click)="closeModal()"
      aria-label="Close"
      [hidden]="activeSlide !== lastSlide"
      class="carousel-navigation-button next-slide-button btn btn-success"
    >
      {{ "CLOSE" | translate }}
    </button>
  </div>
</div>
