<div class="d-flex flex-column">
  <div>
    <button
      class="btn btn-dark d-inline-block ms-1  me-1"
      (click)="getExcelFile()"
      *ngIf="showButtons"
    >
      {{ "EXCEL" | translate }}
    </button>
    <button
      class="btn btn-dark d-inline-block "
      (click)="getPdfFile()"
      *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG' && applicationService.getSelectCompanyGroupCode() !== 'GHO'"
    >
      {{ "PDF" | translate }}
    </button>
  </div>
  <small class="d-block">
    {{ "NO_FILTERS_APPLIED" | translate }}
  </small>
</div>
