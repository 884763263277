import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'minMaxValidationTitle'
})
export class MinMaxValidationTitlePipe implements PipeTransform {

  constructor(public translateService: TranslateService) {}

  transform(minLength: string, maxLength: string): string {
    if (minLength && maxLength && minLength !== maxLength) {
      return this.translateService.instant("VALIDATION_RANGELENGTH").replace("{0}", minLength).replace("{1}", maxLength);
    }
    else if (!maxLength) {
      return this.translateService.instant("VALIDATION_MIN").replace("{0}", minLength);
    }
    else if (!minLength) {
      return this.translateService.instant("VALIDATION_MAX").replace("{0}", maxLength);
    }
    else {
      return this.translateService.instant("VALIDATION_LENGTH").replace("{0}", minLength);
    }
  }

}
