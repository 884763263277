import { Component } from '@angular/core';

@Component({
  selector: 'app-style-anl',
  templateUrl: './style-anl.component.html',
  styleUrls: ['./style-anl.component.scss']
})
export class StyleAnlComponent {

}
