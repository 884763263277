import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { EbscService } from 'src/app/services/ebsc.service';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { EbikeRegistrationOverviewItem } from 'src/app/interfaces/ebsc/ebike-registration-overview-item';
import { timer } from 'rxjs';
import { ApplicationService } from 'src/app/services/application.service';
import * as moment from "moment";
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-ebike-registrations',
  templateUrl: './ebike-registrations.component.html',
  styleUrls: ['./ebike-registrations.component.scss']
})
export class EbikeRegistrationsComponent implements OnInit {
  @Output() showPdfClicked: EventEmitter<EbikeRegistrationOverviewItem> = new EventEmitter<EbikeRegistrationOverviewItem>();
  @Output() showPdfEzyClicked: EventEmitter<EbikeRegistrationOverviewItem> = new EventEmitter<EbikeRegistrationOverviewItem>();
  ebikeRegistrations: EbikeRegistrationOverviewItem[];
  loaded = false;
  language: string;
  constructor(private ebscService: EbscService, private applicationService: ApplicationService, private helperService: HelperService) { }

  ngOnInit() {
    this.language = this.applicationService.getSelectedLanguage();
    this.applicationService.showLoader(true);
    timer(400).subscribe(() => {
      this.ebscService.getEbikeRegistrations("AAN").subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        this.loaded = true;
        if (this.helperService.checkResponse(apiResponse)) {
          this.ebikeRegistrations = apiResponse.result;
        }
      })
    })
  }

  showPdf(registration: EbikeRegistrationOverviewItem) {
    this.showPdfClicked.emit(registration);
  }

  showEzyPdf(registration: EbikeRegistrationOverviewItem) {
    this.showPdfEzyClicked.emit(registration);
  }


  formatDate(date) {
    return moment(date, "YYYYMMDD").format("DD-MM-YYYY");
  }

}
