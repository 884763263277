import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: "app-search-filter-list",
  templateUrl: "./search-filter-list.component.html",
  styleUrls: ["./search-filter-list.component.scss"],
})
export class SearchFilterListComponent implements OnInit {
  @Output() searching: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  triggerFilterSelection: EventEmitter<boolean> = new EventEmitter<boolean>();
  keyword: string;
  constructor() {}

  ngOnInit(): void {}

  search(event) {
    if (event.key === "Enter") {
      this.triggerSelection();
    } else {
      this.searching.emit(this.keyword);
    }
  }

  reset() {
    if (this.keyword === "") {
      this.searching.emit(this.keyword);
    }
  }

  triggerSelection() {
    this.triggerFilterSelection.emit(true);
  }
}
