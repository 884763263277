import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { HelpdeskService } from 'src/app/services/helpdesk.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { DealerCommercialPartnerInformationComponent } from '../dealer-commercial-partner-information/dealer-commercial-partner-information.component';
import { YearAgreementService } from 'src/app/services/year-agreement.service';
import {
  DealerShips,
  YearAgreementParts,
} from 'src/app/interfaces/yearAgreement/year-agreement-parts';
import { DealerPartsBrandsSelectionComponent } from '../dealer-parts-brands-selection/dealer-parts-brands-selection.component';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { Dealer } from 'src/app/interfaces/dealer';
import { ApiResponse } from 'src/app/interfaces/api-response';
import * as moment from 'moment';
import { ApplicationService } from 'src/app/services/application.service';
import { Subscription } from 'rxjs';
import { YearAgreementDealerShipsComponent } from '../year-agreement-dealer-ships/year-agreement-dealer-ships.component';
import { result } from 'underscore';
import { YearAgreementSummaryComponent } from '../../bikes/year-agreement-summary/year-agreement-summary.component';
import { AccountInformation } from 'src/app/interfaces/account-information';

@Component({
  selector: 'app-year-agreement-container-parts',
  templateUrl: './year-agreement-container-parts.component.html',
  styleUrls: ['./year-agreement-container-parts.component.scss'],
})
export class YearAgreementContainerPartsComponent implements OnInit {
  @ViewChild(DealerCommercialPartnerInformationComponent)
  partnerInformation: DealerCommercialPartnerInformationComponent;
  @ViewChild('brands')
  dealerPartsBrandsSelectionComponent: DealerPartsBrandsSelectionComponent;
  @ViewChild('dealerShips')
  dealerShipSelection: YearAgreementDealerShipsComponent;
  @ViewChild('summary') summary: YearAgreementSummaryComponent;
  selectedTab: string;
  selectedDealerInformation: Dealer;
  selectedDealer: any;
  yearAgreement: YearAgreementParts;
  invalid: boolean;
  brandsInvalid: boolean;
  yearAgreements: YearAgreementParts[];
  dealerChangeEvent: Subscription;
  dealerShipInvalid: boolean;
  commercialSettingsInvalid: boolean;
  dealerLevelInvalid: boolean;
  ts: number;
  accountInformation: AccountInformation;
  loading: boolean;
  _moment = null;
  constructor(
    private accountService: AccountService,
    private applicationService: ApplicationService,
    private helpdeskService: HelpdeskService,
    public yearAgreementService: YearAgreementService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this._moment = moment;
    this.getAccountInformation();
    this.getDealerAgreements();
    this.yearAgreementService.resetPartsAgreement();

    // Dealer selected
    this.dealerChangeEvent = this.applicationService.emitChangeCompanyGroupChanged$.subscribe(
      () => {
        this.getDealerAgreements();
        this.summary.pdfSrc = null;
        this.selectedTab = 'dealerInformation';
      }
    );
  }

  ngOnDestroy() {
    if (this.dealerChangeEvent) {
      this.dealerChangeEvent.unsubscribe();
    }
  }

  // Get account helpdesk/internal user
  getAccountInformation() {
    return this.accountService
      .getAccountInformation(true)
      .then((accountInformation: AccountInformation) => {
        this.accountInformation = accountInformation;
      });
  }

  getDealerAgreements() {
    this.selectedDealer = this.helpdeskService.getSelectedDealer();
    this.selectedDealerInformation = this.accountService.getDealerInformation();
    this.getAgreements();
  }

  getAgreements() {
    this.yearAgreementService
      .getPartsAgreements()
      .subscribe((apiResponse: ApiResponse) => {
        if (apiResponse && apiResponse.result && apiResponse.result.length) {
          const currentAgreement = apiResponse.result[0];

          if (currentAgreement) {
            this.yearAgreementService.getPartsAgreement(currentAgreement.id);
          } else {
            this.yearAgreementService.newPartsAgreement(
              this.selectedDealerInformation.dealer_code
            );
          }
        } else {
          if (typeof this.selectedDealerInformation !== "undefined") {
            this.yearAgreementService.newPartsAgreement(
              this.selectedDealerInformation.dealer_code
            );
          } else {
             this.alertService.showErrorNotification(
               `${this.translateService.instant("ERROR")}`
             );
          }
        }
      });
  }

  tabChange(data: NgbNavChangeEvent) {
    this.selectedTab = data.nextId;
    if (this.selectedTab === 'summary') {
     // this.summary.getPreviewPdfParts();
    }
    if (this.selectedTab === 'brands') {
      this.checkBrandSelection();
    }
  }

  checkDealerLevel() {
    this.dealerLevelInvalid = false;
    if (
      !this.yearAgreementService.yearAgreementParts.commercial_agreements
        .dealer_level ||
      !this.yearAgreementService.yearAgreementParts.commercial_agreements
        .retail_formula ||
      !this.yearAgreementService.yearAgreementParts.commercial_agreements
        .bike_dealer_ship
    ) {
      this.dealerLevelInvalid = true;
    }
  }

  checkBrandSelection() {
    this.brandsInvalid = false;
    if (
      this.dealerPartsBrandsSelectionComponent &&
      this.dealerPartsBrandsSelectionComponent.getSelection().length >
        this.yearAgreementService.maxPartBrandSelection
    ) {
      this.brandsInvalid = true;
    }
  }

  checkDealerShipSelection() {
    this.dealerShipInvalid = this.dealerShipSelection.checkSelection() > 0;
  }

  checkCommercialSettings() {
    this.commercialSettingsInvalid = !this.yearAgreementService
      .yearAgreementParts.commercial_agreements.target;
  }

  checkForm() {
    this.invalid = false;
    this.checkDealerLevel();
    this.checkBrandSelection();
    this.checkDealerShipSelection();
    this.checkCommercialSettings();
    if (
      this.dealerLevelInvalid ||
      this.brandsInvalid ||
      this.dealerShipInvalid ||
      this.commercialSettingsInvalid
    ) {
      this.invalid = true;
      this.alertService.showErrorNotification(
        `${this.translateService.instant('REQUIRED_FIELDS_TEXT')}`
      );
    }
    return !this.invalid ? true : false;
  }

  save(hideNotification?: boolean) {
    return new Promise((resolve) => {
      if (this.checkForm()) {
        this.applicationService.showLoader(true);
        this.loading = true;
        this.yearAgreementService
          .updatePartsAgreement(this.yearAgreementService.yearAgreementParts)
          .then(() => {
            if (!hideNotification) {
              this.alertService.showInfoNotification(
                this.translateService.instant('SAVED')
              );
            }

            this.ts = moment().unix();
            this.loading = false;
            resolve(true);
          });
      } else {
        this.loading = false;
        resolve(true);
      }
    });
  }

  send() {
    this.save(true).then((success: boolean) => {
      if (success) {
        this.loading = true;
        this.summary.submit();
      }
    });
  }

  reopenAgreement() {
    this.yearAgreementService
      .reopenPartsAgreement(
        this.selectedDealerInformation.dealer_code,
        this.yearAgreementService.yearAgreementParts.id
      )
      .subscribe((apiResponse: ApiResponse) => {
        if (apiResponse && apiResponse.success) {
          this.alertService.showInfoNotification(
            this.translateService.instant('AGREEMENT_REOPENED')
          );
          this.yearAgreementService.getPartsAgreement(
            this.yearAgreementService.yearAgreementParts.id
          );
          this.selectedTab = 'dealerInformation';
        } else {
          this.alertService.showErrorNotification(
            apiResponse.result
              ? apiResponse.result
              : this.translateService.instant('ERROR')
          );
        }
      });
  }

  agreementSubmitted() {
    this.loading = false;
    this.applicationService.hideLoader();
    this.yearAgreementService.yearAgreementParts.locked = true;
    this.yearAgreementService.yearAgreementParts.send = moment().toString();
    this.alertService.showInfoNotification(
      this.translateService.instant('SENT')
    );
  }
}
