<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'CREATE_BASKET' | translate }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
    </button>
</div>
<div class="modal-body">
  <form>
    <div class="mb-3">
      <label class="fw-bold">{{'NAME' | translate }}</label>
      <input type="text" class="form-control" [(ngModel)]="name" name="name" maxlength="50">
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-success" (click)="save()">{{ 'SAVE' | translate }}</button>
</div>
