<div class="input-group mb-3">
  <div class="col-12">
    <ngb-alert type="warning" [dismissible]="false">
      {{ "CREATE_DEALER_LINK_TOKEN_EXPLANATION" | translate }}
    </ngb-alert>
  </div>
  <input type="text" class="form-control" readonly [ngModel]="tokenData">
  <div class="input-group-append">
    <button class="btn btn-primary" type="button" (click)="copy()">{{"COPY"|translate}}</button>
  </div>
</div>
