import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ArticleFinderModalComponent } from "src/app/components/search-components/article-finder-modal/article-finder-modal.component";
import { Article } from "src/app/interfaces/article";
import { BicycleFinderComponent } from "../../bicycle-finder/bicycle-finder.component";
import { ArticleBomComponent } from "src/app/components/article-components/article-bom-components/article-bom.component";
import { WarrantyService } from "src/app/services/warranty.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { WarrantyPart } from "src/app/interfaces/warranty/warranty-part";
import { WarrantyArticle } from "src/app/interfaces/warranty/warranty-article";
import { HelperService } from "src/app/services/helper.service";
import { ConfirmDialogComponent } from "src/app/components/modal-components/confirm-dialog/confirm-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { WarrantyManfacturerNotifyComponent } from "../../../warranty-manfacturer-notify/warranty-manfacturer-notify.component";
import { ArticleCompensationResult } from "src/app/interfaces/warranty/article-compensation-result";
import moment from "moment";

@Component({
  selector: "app-warranty-general-inputs",
  templateUrl: "./warranty-general-inputs.component.html",
  styleUrls: ["./warranty-general-inputs.component.scss"],
})
export class WarrantyGeneralInputsComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() tabSelected = "";
  @Input() type = ""; //warranty / returns
  @Input() currentArticle: WarrantyArticle;
  @Input() bicycleUnknown: boolean;
  @Input() bicycle: Article;
  @Output() bicycleSelected: EventEmitter<Article> =
    new EventEmitter<Article>();
  @Output() partSelected: EventEmitter<WarrantyPart> =
    new EventEmitter<WarrantyPart>();
  @Output() selectPackingSlip: EventEmitter<any> = new EventEmitter<any>();
  framenumber = "";
  part: Article;
  bomModal: NgbModalRef;
  subscription: any;

  constructor(
    private modalService: NgbModal,
    private warrantyService: WarrantyService,
    private helperService: HelperService,
    private translateService: TranslateService
  ) {
    this.subscription = this.warrantyService.partSelect.subscribe((part: Article) => {
      this.part = part;
      if (!this.checkIfBattery(part)) {
        this.checkIfMotor(part);
      }
      this.partSelected.emit({
        article: part,
      });
      this.formGroup.get("articleCode").setValue(part.single_article_code);
      if (this.bomModal) {
        this.bomModal.close();
      }
    });
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["tabSelected"]) {
      this.part = null;

      if (!this.currentArticle) {
        this.formGroup.get("articleCode").setValue("");
        //   this.formGroup.get("framenumber").setValue("");
      }

      // this.partSelected.emit({
      //   article: this.part
      // });
      this.bicycleSelected.emit(this.bicycle);

      switch (this.tabSelected) {
        case "REC":
        case "lease_service":
          this.formGroup.get("framenumber").enable();
          this.formGroup.get("remarks").disable();
          this.formGroup.get("guaranteeDate").disable();
          this.formGroup.get("articleDescription").disable();
          if (!this.formGroup.get("framenumber").value) {
            this.showBicycleFinder();
          }
          break;

        case "bike_part":
        case "lease_bike_part":
        case "battery":
          this.formGroup.get("framenumber").enable();
          this.formGroup.get("remarks").enable();
          this.formGroup.get("guaranteeDate").enable();

          if (!this.formGroup.get("framenumber").value) {
            this.showBicycleFinder();
          }
          if (this.tabSelected === "battery") {
            this.formGroup.get("articleDescription").disable();
          }
          break;

        case "seperate_part":
          this.bicycle = null;
          this.formGroup.get("remarks").enable();
          this.formGroup.get("guaranteeDate").enable();
          if (!this.formGroup.get("articleCode").value) {
            this.showArticleFinder();
          }
          break;

        case "REC_BATT":
          this.formGroup.get("articleCode").setValue("41122189");
          break;

        default:
          this.formGroup.get("framenumber").disable();
      }
    }
  }

  showArticleFinder() {
    const modalRef = this.modalService.open(ArticleFinderModalComponent, {
      centered: true,
      container: "#modalContainer",
      backdrop: "static",
    });
    modalRef.componentInstance.showUnknown =
      this.tabSelected !== "lease_service" &&
      this.tabSelected !== "lease_bike_part";
    modalRef.componentInstance.articleSelected$.subscribe(
      (article: Article) => {
        if (article && article.single_article_code) {
          // Check if battery or motor
          if (!this.checkIfBattery(article)) {
            this.checkIfMotor(article);
          }

          this.formGroup
            .get("articleCode")
            .setValue(article.single_article_code);
          this.formGroup.get("articleDescription").disable();
          this.partSelected.emit({
            article: article,
          });
        } else {
          this.formGroup.get("articleCode").setValue("999999");
          this.formGroup.get("articleDescription").enable();
        }
      }
    );
  }

  private checkIfBattery(article: Article) {
    // Check if battery
    const articleDescription = article.description.toLowerCase();
    const articlePrice = article.retail_price ? article.retail_price.value : 0;
    const currency = article.retail_price
      ? article.retail_price.currency
      : "EUR";
    const isBattery =
      (articleDescription.indexOf("batterij") >= 0 ||
        articleDescription.indexOf("accu") >= 0) &&
      articleDescription.indexOf("oplader") < 0 &&
      articleDescription.indexOf("tester") < 0 &&
      this.helperService.isBatteryOrMotorPrice(articlePrice, currency);

    if (isBattery) {
      this.formGroup.get("batteryKind").setValue("main_battery");
      this.formGroup.get("batteryType").setValue("liion_battery");
    } else {
      this.formGroup.get("batteryKind").setValue("");
      this.formGroup.get("batteryType").setValue("");
    }

    if (this.type === "guarantee") {
      this.warrantyService
        .checkArticleGuarantee(article.single_article_code, moment().format("YYYYMMDD"), "1")
        .subscribe((apiResponse: ApiResponse) => {
          if (this.helperService.checkResponse(apiResponse)) {
            const warrantyCompensation =
              apiResponse.result as ArticleCompensationResult;

            if (
              warrantyCompensation.path_code &&
              warrantyCompensation.path_code.trim() &&
              warrantyCompensation.path_code === "C"
            ) {
              this.formGroup.get("articleValid").setValue("");

              const modal = this.modalService.open(ConfirmDialogComponent, {
                container: "#modalContainer",
              });

              modal.componentInstance.title = this.translateService.instant(
                "GUARANTEE_VIA_MANUFACTURER"
              );
              modal.componentInstance.setContent(
                WarrantyManfacturerNotifyComponent,
                warrantyCompensation,
                null
              );
            }
          }
        });
    }

    return isBattery;
  }

  private checkIfMotor(article: Article) {
    // Check if motor
    const articleDescription = article.description.toLowerCase();
    const articlePrice = article.retail_price ? article.retail_price.value : 0;
    const currency = article.retail_price
      ? article.retail_price.currency
      : "EUR";
    const isMotor =
      ((article.nested_groups &&
        JSON.stringify(article.nested_groups).indexOf("2R10") >= 0) ||
        (article["article_sub_group_codes"] &&
          article["article_sub_group_codes"].includes("2R10"))) &&
      articleDescription.indexOf("oplader") < 0 &&
      this.helperService.isBatteryOrMotorPrice(articlePrice, currency);

    if (isMotor) {
      this.formGroup.get("batteryKind").setValue("motor");
      this.formGroup.get("batteryType").setValue("");
    } else {
      this.formGroup.get("batteryKind").setValue("");
      this.formGroup.get("batteryType").setValue("");
    }
  }

  showPackingSlipFinder() {
    this.selectPackingSlip.next(true);
  }

  showBom() {
    this.bomModal = this.modalService.open(ArticleBomComponent, {
      centered: true,
      container: "#modalContainer",
      backdrop: "static",
      size: "lg",
    });
    this.bomModal.componentInstance.article = { ...this.bicycle };
    this.bomModal.componentInstance.article.frame_number =
      this.formGroup.get("framenumber").value;
    this.bomModal.componentInstance.selectForWarrantyMode = true;
    this.bomModal.componentInstance.isServiceBom =
      this.tabSelected === "lease_service";
    this.bomModal.componentInstance.isLeaseBom =
      this.tabSelected === "lease_service" ||
      this.tabSelected === "lease_bike_part";
  }

  showBicycleFinder() {
    const modalRef = this.modalService.open(BicycleFinderComponent, {
      centered: true,
      container: "#modalContainer",
      backdrop: "static",
    });
    modalRef.componentInstance.showUnknown =
      this.tabSelected !== "lease_service" &&
      this.tabSelected !== "lease_bike_part";
    modalRef.componentInstance.bicycleSelected$.subscribe((event: any) => {
      this.framenumber = event["framenumber"];
      this.bicycle = event["bicycle"];
      this.formGroup.get("framenumber").setValue(this.framenumber);
      this.bicycleSelected.emit(event["bicycle"]);

      if (this.bicycle && this.tabSelected === "bike_part") {
        this.showBom();
      }
    });
  }
}
