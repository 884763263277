export interface WidgetLanguageItem {
  company_id: string;
  id: number;
  image_url: string;
  info_item_id: number;
  language_code: string;
  link_url: string;
  title: string;
  url_type: number;
}

export class WidgetLanguageItem implements WidgetLanguageItem {
  constructor() {

  }
}
