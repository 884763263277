import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-login-partner-container',
  standalone: true,
  imports: [],
  templateUrl: './login-partner-container.component.html',
  styleUrl: './login-partner-container.component.scss'
})
export class LoginPartnerContainerComponent {
  @Input() bg: string;
}
