import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Article } from 'src/app/interfaces/article';
import { ApplicationService } from 'src/app/services/application.service';
import { ArticleService } from 'src/app/services/article.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ArticleBomComponent } from 'src/app/components/article-components/article-bom-components/article-bom.component';
import { FavoritesService } from 'src/app/services/favorites.service';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { AlertService } from 'src/app/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ViewLeafletComponent } from '../view-leaflet/view-leaflet.component';
import { PricelabelService } from 'src/app/services/pricelabel.service';
import { AccountService } from 'src/app/services/account.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../../modal-components/confirm-dialog/confirm-dialog.component';
import { ImageBankArticleDetailsComponent } from '../../image-bank-components/image-bank-article-details/image-bank-article-details.component';
import { ArticleQrModalComponent } from '../article-qr-modal/article-qr-modal.component';
import { PreorderService } from 'src/app/services/preorder.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-article-links',
  templateUrl: './article-links.component.html',
  styleUrls: ['./article-links.component.scss'],
})
export class ArticleLinksComponent implements OnInit {
  @Input() article: Article;
  @Input() theme = 'light';
  @Output() toggleCompareToggled: EventEmitter<any> = new EventEmitter<any>();
  @Input() mobile: boolean = null;
  @Input() preorder = false;
  @Input() articleDetails: boolean = null;
  showBomIcon: boolean;
  showDealerFunctions = true;
  url: string;

  get preorderUrl() {
    if (this.preorder && this.article.single_article_code) {
      return this.preorderService.preOrderUrl(this.article.single_article_code);
    } else {
      return '';
    }
  }

  constructor(
    private applicationService: ApplicationService,
    private accountService: AccountService,
    private modalService: NgbModal,
    private favoritesServices: FavoritesService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private pricelabelService: PricelabelService,
    private trackingService: TrackingService,
    private preorderService: PreorderService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    if (this.article) {
      this.checkToggle();
    }
    this.applicationService.emitCompareChanged$.subscribe((response) => {
      this.checkToggle();
    });

    this.showDealerFunctions = !this.applicationService.isPartnerPortal();

    this.showBomIcon =
      (this.accountService.getAccountType() !== 'DLR' ||
        this.applicationService.getSelectCompanyGroupCode() !== 'WG') &&
      !this.articleDetails;
  }

  checkToggle() {
    const selectedForCompare = this.applicationService.getCompareListItems();
    this.article.selected_for_compare =
      selectedForCompare.indexOf(this.article.id) >= 0;
  }

  toggleCompare(event) {
    const selected = this.applicationService.addOrRemoveToCompareList(
      this.article
    );

    this.article.selected_for_compare = selected;
    this.toggleCompareToggled.emit({
      article: this.article,
      selected: selected,
    });
    event.preventDefault();
    return false;
  }

  showLeaflet() {
    const modalRef = this.modalService.open(ViewLeafletComponent, {
      centered: true,
      size: 'lg',
      container: '#modalContainer',
    });
    modalRef.componentInstance.article = this.article;
    return false;
  }

  showBom(event) {
    const modalRef = this.modalService.open(ArticleBomComponent, {
      centered: true,
      size: 'lg',
      container: '#modalContainer',
    });
    modalRef.componentInstance.article = { ...this.article };
    event.preventDefault();
    return false;
  }

  addToFavorites(event) {
    this.article.is_favorite = true;

    this.favoritesServices
      .addFavorite(
        this.article.single_article_code
          ? this.article.single_article_code
          : this.article.article_codes[0]
      )
      .subscribe((response: ApiResponse) => {
        this.trackingService.favorite(this.article, 'Add');
        this.alertService.showInfoNotification(
          this.translateService.instant('FAVORITE_ADDED')
        );
      });

    event.preventDefault();
    return false;
  }

  removeFromFavorites(event) {
    this.article.is_favorite = false;

    this.favoritesServices
      .removeFavorite(
        this.article.single_article_code
          ? this.article.single_article_code
          : this.article.article_codes[0]
      )
      .subscribe((response: ApiResponse) => {
        this.trackingService.favorite(this.article, 'Remove');
        this.alertService.showInfoNotification(
          this.translateService.instant('FAVORITE_REMOVED')
        );
      });

    event.preventDefault();
    return false;
  }

  addToPricelabels(event) {
    this.pricelabelService
      .addArticles([
        this.article.single_article_code
          ? this.article.single_article_code
          : this.article.article_codes[0],
      ])
      .subscribe((apiResponse: ApiResponse) => {
        this.alertService.showInfoNotification(
          this.translateService.instant('PRICELABELS_SUCCESS_MESSAGE')
        );
      });

    event.preventDefault();
    return false;
  }

  gotoImageBank(event) {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      container: '#modalContainer',
    });

    modalRef.componentInstance.isConfirm = false;
    modalRef.componentInstance.setContent(
      ImageBankArticleDetailsComponent,
      'modelId',
      this.article.id
    );

    event.preventDefault();
    return false;
  }

  showQrModal(event) {
    const modalRef = this.modalService.open(ArticleQrModalComponent, {
      size: 'sm',
      container: '#modalContainer',
    });

    modalRef.componentInstance.articleCode = this.article.single_article_code;

    event.preventDefault();
    return false;
  }

  copy(text: string) {
    this.helperService.copyTextToClipboard(text);
    this.alertService.showInfoNotification(this.translateService.instant("COPIED"))
  }
}
