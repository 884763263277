<div class="p-2 d-block" *ngIf="showTerms && results && results.length">
  <h4 class="mb-4">{{ "NORESULTS_DID_U_MEAN" | translate }}</h4>
  <ul class="list-group w-100">
    <li
      class="clickable withOpacity list-group-item"
      *ngFor="let result of results"
      (click)="resultSelected(result.text)"
    >
      <span class="pe-2 d-inline-block" style="min-width: 140px">{{
        result.text
      }}</span>
      <span class="p-2 badge bg-success badge-pill"
        >{{ "NUM_RESULTS" | translate }}: {{ result.count }}</span
      >
    </li>
  </ul>
</div>

