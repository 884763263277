import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Article } from "src/app/interfaces/article";
import { UpdateStockInput } from "src/app/interfaces/replenishment/update-stock-input";
import { ApplicationService } from "src/app/services/application.service";
import { ArticleService } from "src/app/services/article.service";
import { HelperService } from "src/app/services/helper.service";
import { ReplenishmentService } from "src/app/services/replenishment.service";
import { SearchService } from "src/app/services/search.service";
import * as _ from "underscore";

@Component({
  selector: "app-replenishment-selection-modal",
  templateUrl: "./replenishment-selection-modal.component.html",
  styleUrls: ["./replenishment-selection-modal.component.scss"],
})
export class ReplenishmentSelectionModalComponent implements OnInit {
  articles: UpdateStockInput[];
  loaded = false;
  companyGroupCode: string;
  readonly = false;

  constructor(
    public modal: NgbActiveModal,
    private replenishmentService: ReplenishmentService,
    private applicationService: ApplicationService,
    private router: Router,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.companyGroupCode = this.applicationService.getSelectCompanyGroupCode();
    this.getArticles();
  }

  // Get all the article where a minimum stocklevel is filled in
  getArticles() {
    this.loaded = false;
    this.replenishmentService
      .getArticles()
      .then((articles: UpdateStockInput[]) => {
        this.articles = articles;
        this.loaded = true;
      });
  }

  // Remove article minimum stock
  remove(updateData: UpdateStockInput, index: number) {
    const data = { ...updateData };
    data.min_stock = 0;
    this.replenishmentService.removeFromList(data).then(() => {
      this.articles.splice(index, 1);
    });
  }

  edit(articleCode: string) {
    // this.router.navigateByUrl(
    //   `/${this.companyGroupCode}/search/${articleCode}#filter/&pageSize=24&replenishment=1`
    // );
    this.searchService.emitFilterChange(articleCode);
    this.modal.close();
  }
}
