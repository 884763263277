export interface VerifyPasswordContainer {
  user_hash: string;
  password_hash: string;
  culture: string;
  password1: string;
  password2: string;
}

export class VerifyPasswordContainer implements VerifyPasswordContainer {
  constructor(userHash, passwordHash, culture) {
    this.user_hash = userHash;
    this.password_hash = passwordHash;
    this.culture = culture;
  }
}
