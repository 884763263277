import { Component, OnInit, Input } from "@angular/core";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Slideshow } from "src/app/interfaces/cms/slideshow";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SlideshowModalComponent } from "../slideshow-modal/slideshow-modal.component";
import { SlideshowService } from "src/app/services/cms/slideshow.service";
import { ActivatedRoute } from "@angular/router";
import { HelperService } from "src/app/services/helper.service";
import { CmsService } from "src/app/services/cms.service";

@Component({
  selector: "app-slideshows-overview",
  templateUrl: "./slideshows-overview.component.html",
  styleUrls: ["./slideshows-overview.component.scss"],
})
export class SlideshowsOverviewComponent implements OnInit {
  slideshows: Slideshow[];
  closeResult: string;
  constructor(
    private slideshowService: SlideshowService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private cmsService: CmsService
  ) {}

  ngOnInit() {
    this.slideshows = Array<Slideshow>();
    this.getSlideshows();
  }

  getSlideshows() {
    this.slideshowService
      .getSlideshows()
      .subscribe((apiResponse: ApiResponse) => {
        if (
          apiResponse &&
          apiResponse.success &&
          apiResponse.result &&
          apiResponse.result.items &&
          apiResponse.result.items.length
        ) {
          let filteredSlides = apiResponse.result.items.filter(
            (slideshow) => slideshow.category !== 29
          );
          this.slideshows = this.slideshows.concat(filteredSlides);
          this.route.params.subscribe((params) => {
            if (params["id"]) {
              this.showSlideshow(params["id"]);
            }
          });
        }
      });
  }

  showSlideshow(id: string) {
    let slideshow = this.slideshows.find(
      (slideshow) => slideshow.id === id
    );
    if (slideshow) {
      this.openSlideshow(slideshow);
    }
  }

  openSlideshow(slideshow: Slideshow) {
    const modalRef = this.modalService.open(SlideshowModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg",
      windowClass: 'large',
      container: "#modalContainer",
      centered: true,
    });

    modalRef.componentInstance.slideshow = slideshow;
  }
}
