export interface CheckLeaseArticleInput {
  "article_code": string;
  "article_code_oem": string;
  "frame_number": string;
  "mileage": number;
  "ticket_number": number;
}

export class CheckLeaseArticleInput implements CheckLeaseArticleInput {
  constructor(articleCode: string, articleCodeOem: string, frameNumber: string, mileage: number, ticketNumber: number) {
    this.article_code = articleCode;
    this.article_code_oem = articleCodeOem;
    this.frame_number = frameNumber;
    this.mileage = mileage;
    this.ticket_number = ticketNumber;
  }
}
