<div
  class="side-bar-container pe-1"
  id="side-bar-container"
  [style.top]="sidebarTop >= 0 ? sidebarTop + 'px' : undefined"
  [class.showControls]="showControls"
  [hidden]="!sideBarActive"
>
  <div class="px-2 px-sm-0 selected-filter-list">
    <ng-content></ng-content>
    <div
      class="d-grid mt-1"
      *ngIf="
        (selectedFilterList && selectedFilterList.length) || commonFilterSet
      "
    >
      <button
        type="button"
        class="btn btn-dark btn-block my-1"
        id="removeFilters"
        data-dismiss="alert"
        aria-label="Close"
        (click)="removeFilters(true)"
      >
        {{ "REMOVE_FILTERS" | translate }}
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <ul class="list-unstyled mb-0" *ngIf="selectedFilterList.length">
      <ng-container *ngFor="let filter of selectedFilterList; index as i">
        <li
          *ngIf="!filter.long_list"
          class="clickable withOpacity border-bottom py-1 text-primary d-flex justify-content-between align-items-start"
          (click)="removeFilter(filter, i)"

        ><span innerHTML="&middot; {{ filter.name }}:  {{
          filter.description
        }}"></span><span class="rounded text-center delete-filter" style="width: 24px">&times;</span>
      </li>
        <li
          *ngIf="filter.long_list"
          class="clickable withOpacity border-bottom py-1 text-primary"
          (click)="viewFilter(filter)"
        >
          &middot; {{ filter.name }}: {{ filter.description }}
          <button class="d-inline btn btn-sm btn-primary py-0 px-1">
            {{ "VIEW" | translate }}
            <ng-container *ngIf="filter.count > 0"
              >({{ filter.count }})</ng-container
            >
          </button>
        </li>
      </ng-container>
    </ul>
    <small class="d-flex align-items-center mt-2 p-2 justify-content-end">
      <span
        (click)="saveFilters()"
        class="d-flex align-items-center clickable withOpacity load_filters"
      >
        {{ "SAVE_LOAD_FILTERS" | translate }}</span
      ></small
    >
  </div>

  <div class="filters" [ngClass]="!sideBarActive ? 'inActive' : ''">
    <div class="mb-3 filter-group">
      <app-search-filter #searchFilter
        (filter)="filterWithKeyword.emit($event)"
      ></app-search-filter>
    </div>
    <div class="mb-3 filter-group">
      <app-groups-tree
        [groups]="groups"
        (groupSelected)="groupSelected($event)"
      ></app-groups-tree>
    </div>
    <div class="mb-3 filter-group">
      <div
        [class.collapsed]="priceCollapsed"
      >
        <label
          class="filterHeader clickable withOpacity w-100"
          (click)="priceCollapsed = !priceCollapsed; priceInit = true"
          >{{ "RETAILPRICE" | translate }}</label
        >
        <ngx-slider
          class="custom-slider d-block"
          [(value)]="minValue"
          [(highValue)]="maxValue"
          [(options)]="options"
          (userChangeEnd)="onUserChange($event)"
          [hidden]="priceCollapsed"
          *ngIf="(!priceCollapsed && !priceInit) || priceInit"
        ></ngx-slider>
      </div>
    </div>
    <div
      class="mb-3 filter-group"
      *ngIf="
        !selectedFilters['COMMON']['CMN_IN_STOCK'] &&
        showStockFilter &&
        showGeneralFilters
      "
    >
      <div
        [class.collapsed]="deliveryWeekCollapsed"
      >
        <label
          class="filterHeader clickable withOpacity w-100"
          (click)="
            deliveryWeekCollapsed = !deliveryWeekCollapsed;
            deliveryWeekInit = true
          "
          >{{ "DELIVERY_WEEK" | translate }}</label
        >

        <app-stockweek-filter
          class="d-block"
          (change)="stockweekRangeChange($event)"
          [stockweekFilter]="stockweekData"
          [hidden]="deliveryWeekCollapsed"
          *ngIf="
            (!deliveryWeekCollapsed && deliveryWeekInit) || deliveryWeekInit
          "
        ></app-stockweek-filter>
      </div>
    </div>
    <div class="mb-3 filter-group" *ngIf="showGeneralFilters">
      <label class="filterHeader">{{ "SELECTION" | translate }}</label>
      <app-common-filter
        [name]="'CMN_AVAIL'"
        [translation]="'AVAILABLE'"
        *ngIf="applicationService.getSelectCompanyGroupCode() !== 'COM'"
        [selectedValue]="selectedFilters['COMMON']['CMN_AVAIL']"
        (updated)="
          getCommonFilter($event);
          track('Available', selectedFilters['COMMON']['CMN_AVAIL'])
        "
      ></app-common-filter>
      <app-common-filter
        [name]="'CMN_IN_STOCK'"
        [translation]="'STATUS12'"
        [selectedValue]="selectedFilters['COMMON']['CMN_IN_STOCK']"
        (updated)="
          getCommonFilter($event);
          track('In stock', selectedFilters['COMMON']['CMN_IN_STOCK']);
          resetStock()
        "
      ></app-common-filter>
      <app-common-filter
        [name]="'CMN_NEW'"
        [translation]="'NEW'"
        [selectedValue]="selectedFilters['COMMON']['CMN_NEW']"
        (updated)="getCommonFilter($event)"
      ></app-common-filter>
      <app-common-filter
        [name]="'CMN_PROM'"
        [translation]="'PROMOTION'"
        [selectedValue]="selectedFilters['COMMON']['CMN_PROM']"
        (updated)="
          getCommonFilter($event);
          track('Promotions', selectedFilters['COMMON']['CMN_PROM'])
        "
      ></app-common-filter>
      <app-common-filter
        [name]="'CMN_OLD'"
        [translation]="'SEARCHALLCOLLECTIONS'"
        [selectedValue]="selectedFilters['COMMON']['CMN_OLD']"
        [ngClass]="
          selectedFilters['COMMON']['CMN_BOM'] === true ? 'disabled' : ''
        "
        (updated)="
          getCommonFilter($event);
          track('Old collection', selectedFilters['COMMON']['CMN_OLD'])
        "
      >
      </app-common-filter>
      <app-common-filter
        [name]="'CMN_BOM'"
        [translation]="'SHOWBOM'"
        *ngIf="hasBom"
        [selectedValue]="selectedFilters['COMMON']['CMN_BOM']"
        (updated)="
          getCommonFilter($event);
          searchBomChange($event);
          track('Bom', selectedFilters['COMMON']['CMN_BOM'])
        "
      ></app-common-filter>
    </div>
    <div
      class="mb-3 filter-group"
      *ngIf="
        selectedFilters['COMMON']['CMN_PROM'] && promotions && promotions.length
      "
    >
      <label class="filterHeader">{{ "PROMOTIONS" | translate }}</label>
      <select
        class="form-control"
        [(ngModel)]="promotionCode"
        (change)="setPromotionCode($event)"
        name="homepromo"
      >
        <option [value]="''">{{ "ALL" | translate }}</option>
        <ng-container *ngFor="let groupedPromotions of promotions">
          <option
            [value]="promotion.promotioncode + '_' + promotion.company_id"
            *ngFor="let promotion of groupedPromotions"
            [innerHTML]="
              promotion.title === '' ? promotion.promotioncode : promotion.title
            "
          ></option>
        </ng-container>
      </select>
    </div>
    <ng-container *ngIf="availableFilters && availableFilters.length">
      <ng-container
        *ngFor="let filterGroup of availableFilters; trackBy: filterGroupName"
      >
        <div
          class="mb-3 filter-group search-related"
          [style.order]="filterGroup.rank"
          [id]="'filter-' + filterGroup.specification_code"
          *ngIf="filterGroup.options?.length >= 1 && filterGroup.id !== 'Price'"
        >

          <div
            [ngClass]="[
              !filterGroup.expanded && !filterKeywords ? 'compact' : 'expanded',
              filterGroup.collapse ? 'collapsed' : ''
            ]"
          >
            <label
              class="filterHeader clickable withOpacity w-100"
              (click)="toggleFilter(filterGroup)"
              [innerHTML]="
                filterGroup.name +
                ((filterGroup.collapse && !globalFilterKeyword)
                  ? ' (' + filterGroup['options'].length + ')'
                  : '')
              "
            >
            </label>
            <div
              class="d-block"
              *ngIf="filterGroup.is_slider && filterGroup.options?.length > 2"
            >
              <app-range-filter [filter]="filterGroup"  [multiRange]="filterGroup.slider_type === 'Range'" (change)="selectedFilters[filterGroup?.specification_code] = $event; selectFilter()"></app-range-filter>
            </div>

            <div
              *ngIf="
                filterGroup.show_checkboxes ||
                !filterGroup.is_slider ||
                filterGroup.options?.length <= 2
              "
            >
              <app-search-filter-list
                *ngIf="filterGroup.options?.length >= 8"
                (searching)="
                  filterList($event, filterGroup?.specification_code)
                "
                (triggerFilterSelection)="
                  selectFilters(
                    filterGroup,
                    filterGroup['options']
                  )
                "
              ></app-search-filter-list>

              <div class="form-check-container" [class.desktop]="!isMobile">
                <ng-container *ngFor="let filter of filterGroup['options']; let i = index; trackBy: filterId">
                  <div
                    class="form-check"
                    [ngClass]="filterGroup?.specification_code + '_' + filter['id']"
                    *ngIf="!filterGroup['max_items'] || (i <= filterGroup['max_items'] || filterGroup['show_all'])"
                  >
                    <ng-container
                      *ngIf="
                        selectedFilters[filterGroup?.specification_code] &&
                        (matchesSearch(
                          filterGroup,
                          filter['description']
                        ) ||
                          selectedFilters[filterGroup?.specification_code][
                            filter['id']
                          ])
                      "
                    >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="{{ filter['id'] }}"
                      (change)="
                        track(
                          filter['id'],
                          selectedFilters[filterGroup?.specification_code][
                            filter['id']
                          ]
                        );
                        selectFilter()
                      "
                      [name]="filter['id']"
                      [checked]="
                        selectedFilters[filterGroup['specification_code']][
                          filter['id']
                        ]
                      "
                      [(ngModel)]="
                        selectedFilters[filterGroup?.specification_code][
                          filter['id']
                        ]
                      "
                    />
                    <span
                      class="color-filter"
                      [ngClass]="'color-' + filter['id']"
                      *ngIf="filterGroup.specification_code === 'COLORBAS'"
                    >
                    </span>
                    <label
                      class="form-check-label clickable withOpacity"
                      (click)="
                        selectedFilters[filterGroup?.specification_code][
                          filter['id']
                        ] =
                          !selectedFilters[filterGroup?.specification_code][
                            filter['id']
                          ];
                        track(
                          filter['id'],
                          selectedFilters[filterGroup?.specification_code][
                            filter['id']
                          ]
                        );
                        selectFilter()
                      "
                      [ngClass]="
                        selectedFilters[filterGroup?.specification_code][
                          filter['id']
                        ]
                          ? 'selected'
                          : ''
                      "
                      [innerHTML]="filter['description']"
                    >
                    </label>
                  </ng-container>
                </div>
              </ng-container>
              <button class="btn btn-sm btn-block btn-outline-dark py-1 w-100"  *ngIf="filterGroup['options'].length > filterGroup['max_items']" (click)="toggleVisibleOptions(filterGroup)">{{(filterGroup['show_all'] ? "SHOW_LESS" : "SHOW_ALL_OPTIONS")|translate}} <ng-container *ngIf="!filterGroup.show_all">({{filterGroup?.options?.length}})</ng-container></button>
            </div>

              <div
                class="w-full text-center"
                *ngIf="filterGroup.is_slider && filterGroup.options?.length > 1"
              >
                <span
                  class="material-icons clickable withOpacity"
                  (click)="filterGroup.show_checkboxes = false"
                >
                  expand_less
                </span>
              </div>
            </div>
            <div
              *ngIf="
                !filterGroup.show_checkboxes && filterGroup.options?.length > 2
              "
            >
              <div class="w-full text-center" *ngIf="filterGroup.is_slider">
                <span
                  class="material-icons clickable withOpacity"
                  (click)="filterGroup.show_checkboxes = true"
                >
                  expand_more
                </span>
              </div>
            </div>
          </div>
          <span
            *ngIf="
              filterGroup.options?.length > 14 &&
              !filterGroup.collapse &&
              !filterKeywords
            "
            class="expand clickable withOpacity"
            (click)="toggleFilter(filterGroup)"
          >
            <i class="material-icons">
              {{
                !filterGroup.expanded
                  ? "keyboard_arrow_down"
                  : "keyboard_arrow_up"
              }}
            </i>
            {{ (!filterGroup.expanded ? "SEE_ALL" : "SEE_LESS") | translate }}
          </span>
        </div>
        <span
          *ngIf="
            filterGroup.options?.length > 14 &&
            !filterGroup.collapse &&
            !filterKeywords
          "
          class="expand clickable withOpacity"
          (click)="toggleFilter(filterGroup)"
        >
          <i class="material-icons">
            {{
              !filterGroup.expanded
                ? "keyboard_arrow_down"
                : "keyboard_arrow_up"
            }}
          </i>
          {{ (!filterGroup.expanded ? "SEE_ALL" : "SEE_LESS") | translate }}
        </span>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="showControls && applicationService.getSelectCompanyGroupCode() !== 'LAP'">
    <div class="position-fixed mx-auto shadow rounded border p-3" id="filterDialogContainer" *ngIf="showControls && showModal"  style="inset: 100px 0 auto 0; z-index: 99;" >
      <div class="container mb-5" style="overflow: auto; max-height: 700px; resize: vertical;">
        <div class="row" (clickOutside)="showModal = false">
          <div class="col-12">
            <app-filter-dialog #filterDialog [availableFilters]="availableFilters" [selectedFilterList]="selectedFilterList" (filtersUpdated)="filtersUpdated.emit($event)" [numResults]="numResults" (showResults)="showModal = false"></app-filter-dialog>
          </div>
        </div>

      </div>
      <div class="position-absolute end-0 bottom-0 mb-2 me-3">
        <div class="container">
          <span *ngIf="filterDialogCollapsed" class="me-2">{{availableFilters?.length}} {{"FILTERS" | translate}}</span>
          <button class="btn btn-dark me-2" id="collapse-filters" [ngClass]="'filters-collapsed-' + filterDialogCollapsed" (click)="filterDialogCollapsed = !filterDialogCollapsed">{{ (!filterDialogCollapsed ? "COLLAPSE" : "EXPAND") | translate }}</button>
          <button class="btn btn-primary" (click)="showModal = false">{{ "CLOSE" | translate }}</button>
        </div>
      </div>
    </div>
    <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100" icon="pi pi-arrow-up"></p-scrollTop>
    <div class="position-fixed mx-0 d-none d-sm-flex ps-2" style="bottom: 87px; transform: translateX(30px)">
      <span class="btn btn-dark d-sm-flex align-items-center" *ngIf="availableFilters?.length > 0" (click)="showModal = !showModal; filterDialogCollapsed = false">
        <i class="material-icons">filter_alt</i> ({{availableFilters?.length}})
      </span>
    </div>
  </ng-container>
</div>
