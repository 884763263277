import { Component, OnInit, Input } from "@angular/core";
import { FinancialService } from "src/app/services/financial.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PdfViewerComponent } from "src/app/components/general-components/pdf-viewer/pdf-viewer.component";

@Component({
  selector: "app-download-open-order",
  templateUrl: "./download-open-order.component.html",
  styleUrls: ["./download-open-order.component.scss"],
})
export class DownloadOpenOrderComponent implements OnInit {
  @Input() orderNumber: string;
  constructor(
    private financialService: FinancialService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}

  downloadOpenOrder() {
    this.financialService
      .getOpenOrdersPdfFile([this.orderNumber])
      .subscribe((blob: Blob) =>
        this.showPdfDialog(blob, `openorderlines_${this.orderNumber}.pdf`)
      );
  }

  showPdfDialog(blob: Blob, fileName: string) {
    const modalRef = this.modalService.open(PdfViewerComponent, {
      centered: true,
      size: "lg",
      container: "#modalContainer",
    });
    modalRef.componentInstance.fileName = fileName;
    modalRef.componentInstance.pdfSrc = blob;
    event.preventDefault();
    return false;
  }
}
