import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { IsDealerGuard } from 'src/app/guards/is-dealer.guard';
import { SharedModule } from '../shared/shared.module';
import { PricelabelPageComponent } from 'src/app/pages/pricelabel-page/pricelabel-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const routes: Routes = [
  {
    path: "",
    component: PricelabelPageComponent,
    canActivate: [IsDealerGuard]
  }]

@NgModule({
  imports: [
    CommonModule,RouterModule.forChild(routes), SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [PricelabelPageComponent]
})
export class PricelabelsModule { }
