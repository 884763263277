export interface BalanceFilters {
  "options":BalanceFilterOptions;
  "next_page_reference": string;
  "companyFilter": string;
}

export interface BalanceFilterOptions {
  "article_type": string;
  "order_by": string;
  "invoice_state_filter": string;
  "company_id_filter": number;
  "open_invoices": boolean;
  "workshop": boolean;
  "accentry_id": string;
  "date_from":	string;
  "date_to": string;
  "page": number;
}

export class BalanceFilterOptions implements BalanceFilterOptions {

};

export class BalanceFilters implements BalanceFilters {
  constructor(invoice_state_filter?: string, date_from?: string, date_to?: string){
    this.options = new BalanceFilterOptions();

    this.options.invoice_state_filter = invoice_state_filter;
    this.options.date_from = date_from;
    this.options.date_to = date_to;
    this.companyFilter = "";
  }
}
