import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import jsQR from "jsqr";
import { SearchService } from "src/app/services/search.service";
import { Article } from "src/app/interfaces/article";
import { ApiResponse } from "src/app/interfaces/api-response";
import { ApplicationService } from "src/app/services/application.service";
import { Router } from "@angular/router";
import { AlertService } from "src/app/services/alert.service";

@Component({
  selector: "app-qr-scanner-input",
  templateUrl: "./qr-scanner-input.component.html",
  styleUrls: ["./qr-scanner-input.component.scss"]
})
export class QrScannerInputComponent implements OnInit {
  @Output() resetModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() stopped: EventEmitter<any> = new EventEmitter<any>();
  articles: Article[];
  anim: number;
  stream: any;
  loading = true;
  scanning = true;
  searchText: string;
  url: string;
  noResults = false;
  constructor(
    private applicationService: ApplicationService,
    private searchService: SearchService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit() {
    this.startScanner();
  }

  ngOnDestroy() {
    this.stopVideo();
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }
  }

  reset() {
    this.articles = null;
    this.stopVideo();
    this.setContainer("");
    this.scanning = true;
    this.startScanner();
  }

  startScanner() {
    const video = document.getElementById("video") as HTMLVideoElement;
    const canvasElement = document.getElementById(
      "canvas"
    ) as HTMLCanvasElement;
    const canvas = canvasElement.getContext("2d");
    const self = this;

    function drawLine(begin, end, color) {
      canvas.beginPath();
      canvas.moveTo(begin.x, begin.y);
      canvas.lineTo(end.x, end.y);
      canvas.lineWidth = 4;
      canvas.strokeStyle = color;
      canvas.stroke();
    }

    // Use facingMode: environment to attemt to get the front camera on phones
    try {
      navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then(function(stream) {
        self.stream = stream;
        video.srcObject = stream;
        video.setAttribute("playsinline", "true"); // required to tell iOS safari we don't want fullscreen
        video.play();
        // self.loading = false;
        self.scanning = true;
        if (!self.anim) {
          self.anim = requestAnimationFrame(tick);
        }
      }).catch((err) => {
        console.log(err);
        this.alertService.showPopup("No webcam / camera available", "error");
      });
    } catch (err) {
      this.alertService.showPopup("No webcam / camera available", "error");
    }


    function tick() {
      if (video.readyState === video.HAVE_ENOUGH_DATA && self.scanning) {
        canvasElement.hidden = false;
        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(
          video,
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        const imageData = canvas.getImageData(
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert"
        });
        if (code) {
          self.setContainer("none");

          if (code.data.includes("/article")) {
            const urlData = code.data.split("/");
            self.router.navigateByUrl(`${self.applicationService.getSelectCompanyGroupCode()}/article/${urlData[urlData.length - 1]}`);
            self.closeModal();
            return false;
          }

          self.getArticles(code.data).then((result) => {
            if (result) {
              self.searchText = code.data;
              self.noResults = false;
              self.searchService.getNettPrices(self.articles);
            } else {
              self.noResults = true;
            }
            self.loading = false;
            self.scanning = false;
          });

          cancelAnimationFrame(self.anim);
          self.stopVideo();
        }
      }
      requestAnimationFrame(tick);
    }
  }

  // Hide the webcamcanvas
  private setContainer(display: string) {
    const container = document.getElementById("media-container") as HTMLElement;
    container.style.display = display;
  }

  // Stop all recording
  public stopVideo() {

    const video = document.getElementById("video") as HTMLVideoElement;
    const canvasElement = document.getElementById(
      "canvas"
    ) as HTMLCanvasElement;

    // clear canvas
    if (canvasElement) {
      const context = canvasElement.getContext("2d");
      context.clearRect(0, 0, canvasElement.width, canvasElement.height);
    }

    this.scanning = false;

    if (video) {
      video.src = "";
      video.pause();
    }
  }

  // Get articles (first try model id else articleCode)
  getArticles(searchText: string) {
    this.applicationService.showLoader(true);
    const mainPromise = new Promise(allApprove => {
      const articleDetails = new Promise(approve => {
        const modelId = Number(searchText);
        if (modelId) {
          this.searchService
            .articleDetails(modelId)
            .subscribe((apiResponse: ApiResponse) => {
              if (
                apiResponse &&
                apiResponse.success &&
                apiResponse.result &&
                apiResponse.result
              ) {
                this.articles = [apiResponse.result];
                approve(true);
              } else {
                approve(false);
              }
            });
        } else {
          approve(false);
        }
      });

      articleDetails.then((result: boolean) => {
        if (!result) {

          this.searchService
            .search(searchText, 1, 10, "1", null, null, "preorder", this.applicationService.getSelectCompanyGroupCode() === "WG")
            .subscribe((apiResponseSearch: ApiResponse) => {
              if (
                apiResponseSearch &&
                apiResponseSearch.success &&
                apiResponseSearch.result &&
                apiResponseSearch.result.results
              ) {
                this.articles = Object.values(apiResponseSearch.result.results);
                allApprove(true);
              } else {
                allApprove(false);
                this.noResults = true;
              }
            });
        } else {
          allApprove(true);
        }
      });
    });

    mainPromise.then(() => {
      this.applicationService.hideLoader();
    });

    return mainPromise;
  }

  closeModal() {
    this.stopped.emit(true);
    this.stopVideo();
  }
}
