<div class="p-2 d-flex justify-content-around">
  <div class="col-6" *ngIf="type === 'save'">
    <h5>{{ "SAVE_FILTER" | translate }}</h5>
    <div class="d-flex mt-3 align-items-end">
      <label class="mb-0 pe-2"
        >{{ "NAME" | translate }}
        <input type="text" class="form-control me-1" [(ngModel)]="name" (keyup.enter)="save()"/>
      </label>
      <button class="btn btn-primary" (click)="save()">
        {{ "SAVE" | translate }}
      </button>
    </div>
  </div>
  <div [ngClass]="type === 'load' ? 'col-12' : 'col-6'">
    <h5 *ngIf="type === 'save'">{{ "LOAD_FILTER" | translate }}</h5>
    <ul class="mt-3 list-group list-group-flush" *ngIf="currentFilters.length">
      <li
        class="list-group-item d-flex justify-content-between align-items-center"
        *ngFor="let filter of currentFilters; let i = index"
      >
        <span
          class="position-absolute clickable withOpacity text-danger"
          style="left: -2.5px"
          (click)="deleteFilter(i)"
          >&times;</span
        >

        <span>{{ filter.name }}</span
        ><button
          class="btn btn-primary btn-sm d-flex align-items-center"
          (click)="gotoFilter(filter)"
        >
          <i class="material-icons clickable withOpacity">arrow_right</i>
        </button>
      </li>
    </ul>
    <span *ngIf="!currentFilters.length">{{"NO_RESULTS"|translate}}</span>
  </div>
</div>
