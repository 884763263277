<div class="clickable withOpacity row col-md-12 w-100 mx-0 px-0"
  *ngIf="widget">
  <div class="col-md-1 px-0 d-flex align-items-center" [class.col-lg-2]="!carouselImage" [class.col-lg-3]="carouselImage" *ngIf="widget.image_url">
    <img class="img-thumbnail p-0 border-0 w-100 ms-2"  [src]="widget.image_url">
  </div>
  <div class="col-md-11 pt-3 title" [class.col-lg-10]="!carouselImage" [class.col-lg-9]="carouselImage" [style.background-position]="startPosX + '%' + startPosY + '%'"
    [style.background-image]="'url(' + widget.image_url + ')'">
    <h2 [innerHTML]="widget.title" class="px-2"></h2>
    <h4 class="px-2" *ngIf="numResults">{{numResults}} {{"ARTICLES"|translate}}</h4>
  </div>
</div>
