import { Component, OnInit, Input } from "@angular/core";
import { User } from "src/app/interfaces/user";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationService } from "src/app/services/application.service";
import { Dealer } from "src/app/interfaces/dealer";
import { YearAgreementDataModel } from "src/app/interfaces/yearAgreement/year-agreement-data-model";
import { AccountAgreement } from "src/app/interfaces/yearAgreement/account-agreement";
import { SearchDealerModalComponent } from "src/app/components/helpdesk/search-dealer-modal/search-dealer-modal.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-dealer-information",
  templateUrl: "./dealer-information.component.html",
  styleUrls: ["./dealer-information.component.scss"]
})
export class DealerInformationComponent implements OnInit {
  @Input() selectedDealer: User;
  @Input() selectedDealerInformation: Dealer;
  @Input() affiliates = [];
  @Input() accountAgreement: AccountAgreement;
  constructor(
    private modalService: NgbModal,
    private applicationService: ApplicationService,
    private route: Router
  ) {}

  ngOnInit() {}

  selectDealer() {
    const modal = this.modalService.open(SearchDealerModalComponent, {
      size: "lg",
      container: "#modalContainer",
      backdrop: "static",
      windowClass: "large"
    });
    modal.componentInstance.redirectUrl = this.route.url;
  }
}
