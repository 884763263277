import { Component, OnInit } from "@angular/core";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import { HelpdeskService } from "src/app/services/helpdesk.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { MergeInfo } from "src/app/interfaces/merge-info";
import { forkJoin } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogComponent } from "src/app/components/modal-components/confirm-dialog/confirm-dialog.component";
import { AlertService } from "src/app/services/alert.service";

@Component({
  selector: "app-merge-dealers-page",
  templateUrl: "./merge-dealers-page.component.html",
  styleUrls: ["./merge-dealers-page.component.scss"],
})
export class MergeDealersPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  mergeInfo: MergeInfo[] = [];
  fromDealerId: string;
  toDealerId: string;
  fromDealerInfo: MergeInfo;
  toDealerInfo: MergeInfo;
  confirm_body_1: string;
  confirm_body_2: string;
  mergeDealersAreBothValid: boolean;
  errorMessage: string = "";
  mergeSuccessMessage: string = "";
  dealerFromInfo: string[];
  dealerToInfo: string[];
  dealerFromError: string = null;
  dealerToError: string = null;

  constructor(
    private modalService: NgbModal,
    private helpdeskService: HelpdeskService,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private alertService: AlertService
  ) {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/home"
      ),
      new Breadcrumb(this.translateService.instant("MERGE_DEALERS"), "#"),
    ];
  }

  ngOnInit() {}

  checkIfDealersAreValid() {
    this.mergeInfo = [];
    this.errorMessage = "";
    this.mergeSuccessMessage = "";
    this.dealerFromError = null;
    this.dealerToError = null;
    this.dealerToInfo = null;
    this.dealerToInfo = null;
    let dealerFrom: MergeInfo = null;
    let dealerTo: MergeInfo = null;

    if (this.fromDealerId === this.toDealerId) {
      this.errorMessage = this.translateService.instant(
        "CANNOT_MERGE_DEALER_WITH_SAME_ID"
      );
      this.mergeDealersAreBothValid = false;
    } else {
      // Get both information from fromDealer and toDealer
      forkJoin(
        this.helpdeskService.getMergeInfo(this.fromDealerId),
        this.helpdeskService.getMergeInfo(this.toDealerId)
      ).subscribe((responses: any[]) => {
        if (responses && responses.length) {
          if (responses[0]) {
            dealerFrom = (responses[0] as ApiResponse).result as MergeInfo;
          }
          if (responses[1]) {
            dealerTo = (responses[1] as ApiResponse).result as MergeInfo;
          }

          this.mergeInfo.push(dealerFrom);
          this.mergeInfo.push(dealerTo);

          this.fromDealerInfo = dealerFrom;
          this.toDealerInfo = dealerTo;
          this.fromDealerInfo.dealerCode = this.fromDealerId;
          this.toDealerInfo.dealerCode = this.toDealerId;

          // Error messages
          if (!dealerFrom.success) {
            this.dealerFromError = " - " + dealerFrom.details.join("<br/> - ");
          }

          if (!dealerTo.success) {
            this.dealerToError = " - " + dealerFrom.details.join("<br/> - ");
          }

          /// check if valid
          if (
            !dealerFrom.success ||
            !dealerTo.success ||
            (dealerFrom.ace && dealerTo.ace)
          ) {
            this.mergeDealersAreBothValid = false;

            if (
              dealerFrom.ace === dealerTo.ace &&
              dealerFrom.winora === dealerTo.winora
            ) {
              this.errorMessage = this.translateService.instant(
                "MAKE_SURE_DEALERS_ARE_OPPOSITE"
              );
            } else {
              this.errorMessage = this.translateService.instant(
                "UNABLE_TO_MERGE_THESE_DEALERS"
              );
            }
          } else {
            this.mergeDealersAreBothValid = true;
            this.dealerFromInfo = dealerFrom.details;
            this.dealerToInfo = dealerTo.details;
            this.showConfirmDialog();
          }
        }
      });
    }
  }

  showConfirmDialog() {
    if (this.mergeDealersAreBothValid) {
      const modalRef = this.modalService.open(ConfirmDialogComponent, {
        size: "lg",
        windowClass: "medium",
        container: "#modalContainer",
      });
      this.confirm_body_1 = this.translateService.instant("MERGE_WARNING_1");
      this.confirm_body_1 = `${this.confirm_body_1
        .replace("[user1]", this.fromDealerId)
        .replace("[user2]", this.toDealerId)}<br/><br/>
        <b>${this.translateService.instant("FROM_USER")}:</b><br/>
        ${this.dealerFromInfo.join("<br/>")}<br/>
        `;

      this.confirm_body_2 = this.translateService.instant("MERGE_WARNING_2");
      this.confirm_body_2 = `
        <b>${this.translateService.instant("TO_USER")}:</b><br/>
        ${this.dealerToInfo.join("<br/>")}<br/><br/>
        ${this.confirm_body_2
          .replace("[user1]", this.fromDealerId)
          .replace("[user2]", this.toDealerId)}

        `;

      modalRef.componentInstance.title =
        this.translateService.instant("MERGE_DEALERS");
      modalRef.componentInstance.body = ` <p>${this.confirm_body_1}</p>
                                          <p>${this.confirm_body_2}</p>`;

      modalRef.componentInstance.isConfirm = true;
      modalRef.componentInstance.confirmClicked.subscribe(() => {
        this.helpdeskService
          .mergeDealers(this.fromDealerInfo, this.toDealerInfo)
          .subscribe((apiResponse: ApiResponse) => {
            if (apiResponse.success) {
              this.alertService.showInfoNotification(
                this.translateService.instant("MERGE_SUCCESSFUL")
              );
              this.mergeSuccessMessage = this.translateService.instant(
                "SUCCESSFULLY_MERGED_USERS"
              );
              this.mergeSuccessMessage = this.mergeSuccessMessage
                .replace("[user1]", this.fromDealerId)
                .replace("[user2]", this.toDealerId);
            } else {
              this.alertService.showErrorNotification(
                this.translateService.instant("SOMETHING_WENT_WRONG")
              );
              this.errorMessage = this.translateService.instant(
                "SOMETHING_WENT_WRONG"
              );
            }
          });
      });
    }
  }
}
