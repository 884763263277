import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { ApiResponse } from "src/app/interfaces/api-response";
import { ReplenishmentSettings } from "src/app/interfaces/replenishment-settings";
import { HelperService } from "src/app/services/helper.service";
import { ReplenishmentService } from "src/app/services/replenishment.service";

@Component({
  selector: "app-replenishment-availability-checker",
  templateUrl: "./replenishment-availability-checker.component.html",
  styleUrls: ["./replenishment-availability-checker.component.css"],
})
export class ReplenishmentAvailabilityCheckerComponent implements OnInit {
  @Output() isAvailable: EventEmitter<boolean> = new EventEmitter<boolean>();
  available: boolean;
  loaded: boolean;

  constructor(
    private replenishmentService: ReplenishmentService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.getReplenishmentSettings();
  }

  getReplenishmentSettings() {
    this.loaded = false;
    this.replenishmentService
      .getSettings()
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.loaded = true;
          const settings = apiResponse.result as ReplenishmentSettings;
          if (
            moment().isSameOrAfter(moment(settings.active_from)) &&
            moment().isSameOrBefore(moment(settings.active_until))
          ) {
            this.available = true;
            this.isAvailable.emit(true);
          } else {
            this.available = false;
            this.isAvailable.emit(false);
          }
        }
      });
  }
}
