<div
  class="h-1 mt-5 text-center d-flex justify-content-center w-100" *ngIf="show" [@scale]
>
  <div class="no-results d-flex justify-content-center flex-column display-6">
    <div class="mx-auto mb-3 icon-container">
      <i class="material-icons">{{icon}}</i>
    </div>
    {{ title | translate }}
  </div>
</div>
