<div class="container text-center">
  <ng-container *ngIf="selectedDealerInformation">
    {{ "DEALER_SELECTED" | translate }}:
    {{ selectedDealerInformation.name }} ({{
      selectedDealerInformation.dealer_code
    }})
  </ng-container>
  <span
    class="custom-btn clickable withOpacity bg-success text-white px-2"
    (click)="selectDealer()"
    >{{ (selectedDealer ? "CHANGE" : "SELECT_DEALER") | translate }}</span
  >
</div>

<app-admin-sidebar></app-admin-sidebar>
