import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";

@Component({
  selector: "app-new-agreement",
  templateUrl: "./new-agreement.component.html",
  styleUrls: ["./new-agreement.component.scss"]
})
export class NewAgreementComponent implements OnInit {
  confirm$ = new Subject();
  nextYear = 2020;
  selectedYear = 2020;
  options = [];
  enabled: boolean;
  constructor(public modal: NgbActiveModal) {

  }

  ngOnInit() {
    this.selectedYear = this.options[this.options.length - 1];
  }

  confirm() {
    this.confirm$.next(this.selectedYear);
    this.modal.close();
  }
}
