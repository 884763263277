import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: 'app-slideshows-overview-page',
  templateUrl: './slideshows-overview-page.component.html',
  styleUrls: ['./slideshows-overview-page.component.scss']
})
export class SlideshowsOverviewPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  constructor(
    private translateService: TranslateService,
    private applicationService: ApplicationService
  ) {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/home"
      ),
      new Breadcrumb(this.translateService.instant("ACTUAL"), "#"),
      new Breadcrumb(this.translateService.instant("SLIDESHOWS"), "#")
    ];
  }

  ngOnInit() {}
}
