import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { ActivatedRoute } from "@angular/router";
import { BreadCrumbsComponent } from "src/app/components/header-components/bread-crumbs/bread-crumbs.component";

@Component({
  selector: "app-basket-page",
  templateUrl: "./basket-page.component.html",
  styleUrls: ["./basket-page.component.scss"]
})
export class BasketPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  constructor(
    translateService: TranslateService,
    route: ActivatedRoute,
    applicationService: ApplicationService
  ) {
    const type = route.snapshot.data["type"];

    if (type !== 'wishlist') {
      this.breadcrumbs = [
        new Breadcrumb(
          translateService.instant("HOME"),
          applicationService.getSelectCompanyGroupCode() + "/home"
        ),
        new Breadcrumb(
          translateService.instant("BASKETS"),
          applicationService.getSelectCompanyGroupCode() + "/baskets"
        ),
        new Breadcrumb(translateService.instant("BASKET"), "#")
      ];
    } else {
      this.breadcrumbs = [
        new Breadcrumb(
          translateService.instant("HOME"),
          applicationService.getSelectCompanyGroupCode() + "/home"
        ),
        new Breadcrumb(
          translateService.instant("WISHLIST"),
          "#"
        )
      ];
    }
  }

  ngOnInit() {}
}
