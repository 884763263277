export interface EbstPermissions {
    training: boolean;
    trainer: boolean;
    patches: boolean;
    service: boolean;
}

export class EbstPermissions implements EbstPermissions {
  constructor() {

  }
}
