<div class="row align-items-stretch">
  <div *ngFor="let slideshow of slideshows" class="col-sm-12 col-md-4 mb-3 mb-lg-4">
    <div class="card h-100" *ngIf="slideshow && slideshow.language_definitions && slideshow.language_definitions.length; else noResults">
      <div class="embed-responsive embed-responsive-16by9" *ngIf="slideshow.language_definitions[0].image_url && slideshow.language_definitions[0].image_url.indexOf('coming_soon') < 0">
        <app-slideshow-media-embed [slide]="slideshow.language_definitions[0]"></app-slideshow-media-embed>
      </div>
      <div class="card-body with-fixed-height">
        <h4 class="card-title">{{ slideshow.name }}</h4>
        <h6 class="card-subtitle mb-2 text-muted">{{ slideshow.start_date }}</h6>
        <app-slide-content [slide]="slideshow.language_definitions[0]"></app-slide-content>
        <div class="text-fade-out"></div>
      </div>
      <div class="card-body">
        <button type="button" class="btn btn-primary" (click)="openSlideshow(slideshow, false)">{{ 'VIEW' | translate }}</button>
      </div>
    </div>
    <ng-template #noResults>{{'NO_RESULTS' | translate}}</ng-template>
  </div>
</div>
