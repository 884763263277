import { WarrantyArticle } from "./warranty-article";

export interface GroupedWarrantyArticles {
  lease: WarrantyArticle[];
  regular: WarrantyArticle[];
  regular_dispose: WarrantyArticle[];
  batteries: WarrantyArticle[];
  motors: WarrantyArticle[];
}
export class GroupedWarrantyArticles implements GroupedWarrantyArticles {
  [x: string]: WarrantyArticle[];
  constructor() {
    this.lease = Array<WarrantyArticle>();
    this.regular = Array<WarrantyArticle>();
    this.regular_dispose = Array<WarrantyArticle>();
    this.batteries = Array<WarrantyArticle>();
    this.motors = Array<WarrantyArticle>();
  }
}
