import { AbstractControl } from "@angular/forms";

export function ValidateEmail(control: AbstractControl) {
  const v = control.value;
  const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const result = EMAIL_REGEXP.test(v);

  return result ? null
    : {
      validEmail: false
    };
}

