import { Pipe, PipeTransform } from "@angular/core";
import { ApplicationService } from "../services/application.service";

@Pipe({
  name: "formatDeliveryWeek"
})
export class FormatDeliveryWeekPipe implements PipeTransform {
  constructor(private applicationService: ApplicationService) {}
  transform(value: string): any {
    if (value) {
      const week = value.substr(4, 6);
      const year = value.substr(0, 4);
      return `${week} ${year} (${this.applicationService
        .getDateOfWeek(Number(year), Number(week))
        .format("DD-MM-YYYY")})`;
    } else {
      return "";
    }
  }
}
