import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";
import * as _ from "underscore";
import * as moment from "moment";
import { Group } from "src/app/interfaces/dealerDashboard/dashboard-data";
import { AgreementBrand } from "src/app/interfaces/yearAgreement/agreement-brand";

@Component({
  selector: "app-purchase-distribution-table",
  templateUrl: "./purchase-distribution-table.component.html",
  styleUrls: ["./purchase-distribution-table.component.scss"],
})
export class PurchaseDistributionTableComponent implements OnInit {
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() yearAgreementModel: any;
  @Input() tab: string;
  @Input() type: string;
  @Input() year: number;
  @Input() readonly: boolean;
  @Input() totalOfType: number = 0;
  @Input() overviewType: string = "distribution";
  @Input() planningPercentage: number;
  @Input() backorderData: Group[];
  backOrder;
  sum = 0;
  groupData: Group;
  distributionBrands: string[] = [];

  get inputValid() {
    let totalOfType = this.totalOfType;

    if (String(totalOfType).toUpperCase().indexOf("K") >= 0) {
      totalOfType = Number(
        String(totalOfType).toUpperCase().replace("K", "000")
      );
    }
    if (!totalOfType) {
      totalOfType = 1;
    }

    let brandZeroFilledIn = false;
    if (this.distributionBrands.length) {
      this.distributionBrands.forEach((brand: string) => {
        if (
          this.yearAgreementModel[this.type]["turnover_selection"][brand] ===
            0 ||
          !this.yearAgreementModel[this.type]["turnover_selection"][brand]
        ) {
          brandZeroFilledIn = true;
        }
      });
    }

    if (brandZeroFilledIn) {
      return false;
    } else {
      return (
        this.sum === Number(totalOfType) ||
        (Number(totalOfType) >= 500000 && this.sum >= 500000)
      );
    }
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.tab === "distribution" || this.tab === "planning") {
      this.calc();
    }
  }

  ngOnInit() {
    this.calc();
  }

  calc() {
    this.getRequiredDistributionBrands();
    this.groupData = _.findWhere(this.backorderData, { group: this.type });
    this.calcTotal();
  }

  calcTotal() {
    this.sum = _.reduce(
      Object.values(this.yearAgreementModel[this.type]["turnover_selection"]),
      function (memo: number, num: number) {
        return memo + num;
      },
      0
    );
    this.selectionChanged.emit(true);
  }

  divide() {
    const history =
      this.yearAgreementModel[this.type]["turnover_history"][moment().year()];
    let total = 1;
    let totalBrands = 0;
    let lastBrand = "";
    let divideEven = false;
    let totalOfType = this.totalOfType;
    this.getRequiredDistributionBrands();
    const brands = this.distributionBrands;

    if (String(totalOfType).toUpperCase().indexOf("K") >= 0) {
      totalOfType = Number(
        String(totalOfType).toUpperCase().replace("K", "000")
      );
    }
    let target = this.yearAgreementModel[this.type]["manualTurnover"]
      ? this.yearAgreementModel[this.type]["manualTurnover"]
      : Number(totalOfType);

    if (!target) {
      target = 1;
    }

    if (history) {
      for (let brand of brands) {
        totalBrands++;
        total += history[brand] ? history[brand] : 0;
        lastBrand = brand;
      }

      for (let brand of brands) {
        // If no history of a selected brand no calculation can be made -> divide evenly
        if (!history[brand]) {
          divideEven = true;
        }
        if (!divideEven) {
          this.yearAgreementModel[this.type]["turnover_selection"][brand] =
            Math.floor((history[brand] / total) * target);
        }
      }

      if (divideEven) {
        for (let brand of brands) {
          this.yearAgreementModel[this.type]["turnover_selection"][brand] =
            Math.floor(target / totalBrands);
        }
      }
    } else {
      totalBrands = brands.length;

      for (let brand of brands) {
        this.yearAgreementModel[this.type]["turnover_selection"][brand] =
          Math.floor(target / totalBrands);
        lastBrand = brand;
      }
    }

    this.calcTotal();

    // Make a correction if the total is not reached because of round off..
    if (this.sum !== target) {
      this.yearAgreementModel[this.type]["turnover_selection"][lastBrand] +=
        target - this.sum;
    }

    this.calcTotal();
  }

  getRequiredDistributionBrands() {
    this.distributionBrands = this.yearAgreementModel[this.type][
      "brands"
    ].filter((b: string) => {
      return b !== "juncker";
    });
  }

  getBrandBackOrder(brand: string) {
    if (this.groupData) {
      for (let company of this.groupData.companies) {
        if (brand.toLowerCase() === company.company_name.toLowerCase()) {
          return typeof company.backorder_year_agreement !== "undefined"
            ? company.backorder_year_agreement
            : company.backorder;
        }
      }
    }
    return 0;
  }

  getTotalBackOrder() {
    let totalBackorder = 0;
    for (let brand of this.yearAgreementModel[this.type]["brands"]) {
      totalBackorder += this.getBrandBackOrder(brand);
    }

    return totalBackorder;
  }

  getBrandTotal(brand: string) {
    let total = 0;

    if (this.groupData) {
      for (let company of this.groupData.companies) {
        if (brand.toLowerCase() === company.company_name.toLowerCase()) {
          total =
            (this.yearAgreementModel[this.type]["turnover_selection"][brand] *
              this.planningPercentage) /
              100 -
            (typeof company.backorder_year_agreement !== "undefined"
              ? company.backorder_year_agreement
              : company.backorder);
        }
      }
      if (!total) {
        total =
          (this.yearAgreementModel[this.type]["turnover_selection"][brand] *
            this.planningPercentage) /
          100;
      }
    }
    return total;
  }

  getTotalMinusBackOrder() {
    return (
      this.sum * (this.planningPercentage / 100) - this.getTotalBackOrder()
    );
  }
}
