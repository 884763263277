<ng-container *ngIf="mobile; else desktop">
  <div ngbDropdown class="d-block" placement="bottom-right">
    <span><i class="options material-icons" ngbDropdownToggle>more_vert</i></span>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="z-index: 9999">
      <a class="dropdown-item d-flex align-items-center" ngbDropdownItem (click)="
          article.is_favorite
            ? removeFromFavorites($event)
            : addToFavorites($event)
        "><i class="material-icons me-2" [ngClass]="article.is_favorite ? 'text-danger' : ''">{{ article.is_favorite ?
          "favorite" : "favorite_border" }}</i>
        {{
        (article.is_favorite ? "REMOVE_FROM_FAVOURITES" : "ADD_TO_FAVOURITES")
        | translate
        }}
      </a>
      <a class="dropdown-item d-flex align-items-center" ngbDropdownItem (click)="addToPricelabels($event)"
        *ngIf="showDealerFunctions"><i class="material-icons me-2">label</i>{{ "ADD_TO_PRICELABEL" | translate }}</a>
      <a class="dropdown-item d-flex align-items-center" ngbDropdownItem (click)="showLeaflet()"
        *ngIf="showDealerFunctions"><i class="material-icons me-2">picture_as_pdf</i>{{ "VIEW_LEAFLET" | translate
        }}</a>
      <a class="dropdown-item d-flex align-items-center" ngbDropdownItem (click)="toggleCompare($event)"><i
          *ngIf="!article.selected_for_compare" class="material-icons me-2">compare_arrows</i><i
          *ngIf="article.selected_for_compare" class="material-icons me-2">check_circle</i>{{
        (article.selected_for_compare
        ? "COMPARE_ADD_ALL"
        : "REMOVE_FROM_COMPARE"
        ) | translate
        }}
      </a>
      <a class="dropdown-item d-flex align-items-center position-relative" ngbDropdownItem (click)="showBom($event)"
        *ngIf="article.bom && showDealerFunctions"><ng-container *ngTemplateOutlet="exploded"> </ng-container>{{ "BOM" |
        translate }}
      </a>
    </div>
  </div>
</ng-container>
<ng-template #desktop>
  <div class="btn-group btn-group-toggle justify-content w-100" data-toggle="buttons" [ngClass]="'theme_' + theme">
    <div class="d-flex align-items-center gap-2 w-100" *ngIf="article.single_article_code && preorder">
      <button class="d-none d-sm-flex btn btn-tertiary" placement="bottom" ngbTooltip="{{ 'QR_CODE' | translate }}"
        (click)="showQrModal($event)">
        <i class="material-icons">
          qr_code_scanner
        </i>
      </button>
      <input type="text" class="form-control w-100" readonly [value]="preorderUrl">
      <span class="material-icons clickable withOpacity" (click)="copy(preorderUrl)">
        content_copy
      </span></div>
    <button class="d-flex btn btn-tertiary" placement="bottom" ngbTooltip="{{ 'ADD_TO_FAVOURITES' | translate }}"
      [ngClass]="article.is_favorite ? 'text-danger' : ''" *ngIf="!preorder" (click)="
        article.is_favorite
          ? removeFromFavorites($event)
          : addToFavorites($event)
      ">
      <i class="material-icons">
        {{ article.is_favorite ? "favorite" : "favorite_border" }}
      </i>
    </button>
    <app-add-to-stock-notification-button
      *ngIf="(article.single_article_code && (article.stock_status > 2 || article.has_stock_notification)) && showDealerFunctions && !preorder"
      [article]="article" [articleCode]="article.single_article_code"></app-add-to-stock-notification-button>
    <button class="d-flex btn btn-tertiary" placement="bottom" ngbTooltip="{{ 'ADD_TO_PRICELABEL' | translate }}"
      *ngIf="showDealerFunctions && !preorder" (click)="addToPricelabels($event)">
      <i class="material-icons"> label </i>
    </button>
    <button class="d-flex btn btn-tertiary" placement="bottom" ngbTooltip="{{ 'VIEW_LEAFLET' | translate }}"
      *ngIf="showDealerFunctions && !preorder" (click)="showLeaflet()">
      <i class="material-icons"> picture_as_pdf </i>
    </button>
    <button class="d-flex btn btn-tertiary" placement="bottom" *ngIf="!preorder"
      ngbTooltip="{{ 'COMPARE_ADD_ALL' | translate }}" (click)="toggleCompare($event)">
      <i class="material-icons" *ngIf="!article.selected_for_compare">
        compare_arrows
      </i>
      <i class="material-icons" *ngIf="article.selected_for_compare">
        check_circle
      </i>
    </button>

    <button class="d-none d-sm-flex btn btn-tertiary" placement="bottom" ngbTooltip="{{ 'IMAGE_BANK' | translate }}"
      *ngIf="showDealerFunctions && !preorder" (click)="gotoImageBank($event)">
      <i class="material-icons">
        image
      </i>

    </button>
    <button class="d-flex btn btn-tertiary position-relative" placement="bottom" ngbTooltip="{{ 'BOM' | translate }}"
      *ngIf="showDealerFunctions" [class.d-none]="!article.bom || !showBomIcon" (click)="showBom($event)">
      <ng-container *ngTemplateOutlet="exploded"> </ng-container>
    </button>
  </div>
</ng-template>
<ng-template #exploded>
  <i class="material-icons me-2"> pedal_bike </i>
  <i class="material-icons position-absolute" style="left: 0px; top: -2.5px; transform: scale(0.6)">
    account_tree
  </i>
</ng-template>
