import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { EcommerceArticlesComponent } from "src/app/components/ecommerce-components/ecommerce-articles/ecommerce-articles.component";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Article } from "src/app/interfaces/article";
import { OrderLine } from "src/app/interfaces/financial/openorders/order-line";
import { AlertService } from "src/app/services/alert.service";
import { FinancialService } from "src/app/services/financial.service";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-edit-open-order-change-battery",
  templateUrl: "./edit-open-order-change-battery.component.html",
  styleUrls: ["./edit-open-order-change-battery.component.scss"],
})
export class EditOpenOrderChangeBatteryComponent implements OnInit {
  @Input() orderLine: OrderLine;
  @Input() article: Article;
  preSelectedBattery: string;
  public batterySelected = new Subject<Article>();
  constructor(
    public modal: NgbActiveModal,
    private financialService: FinancialService,
    private helperService: HelperService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}

  saveOpenOrder(batteriesContainer: EcommerceArticlesComponent) {
    const selectedArticle = batteriesContainer.batteryArticles.find((b) => {
      return b.selected === true;
    }) as Article;

    if (selectedArticle) {
      this.batterySelected.next(selectedArticle);
      this.modal.close();
    } else {
      this.alertService.showPopup(
        this.translateService.instant("SELECT_BATTERY"),
        "error"
      );
    }
  }
}
