<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
    </button>
</div>

<div class="modal-body p-3">
  <form class="needs-validation" [ngClass]="valid ? 'was-validated' : ''" novalidate>
    <div class="mb-3">
      <label>{{'DESCRIPTION'|translate}}</label>
      <input type="text" class="form-control" name="description" [placeholder]="'REQUIRED' | translate"
        [(ngModel)]="description" (keyup)="checkDescription()">
    </div>
  </form>
  <ul class="list-group">
    <li class="list-group-item py-0" *ngFor="let profileAccess of availableProfileAccess">
      <input type="checkbox" [(ngModel)]="profileAccess.checked" id="a_{{profileAccess.key}}" name="profileAccess"
        (change)="checkSelected()" [value]="profileAccess.key" class="me-2"><label for="a_{{profileAccess.key}}"
        class="mb-0">{{profileAccess.description.toUpperCase()|translate}}</label>
    </li>
  </ul>
  <ngb-alert type="danger" [style.visibility]="!selectedAccess.length ? '' : 'hidden'" class="small"
    [dismissible]="false">
    {{'SELECT_AT_LEAST_ONE'|translate}}</ngb-alert>
</div>
<div class="modal-footer">
  <span class="d-flex col-md-12 p-0 justify-content-between">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{'CANCEL_ACTION'
          |
          translate}}</button>
    <button class="btn btn-success " (click)="confirm()"
      [ngClass]="(!valid || !selectedAccess.length) ? 'disabled' : ''">{{'CONFIRM' | translate}}</button>
  </span>
</div>
