<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "GENERAL_TERMS_AND_CONDITIONS" | translate }}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">
    </button>
</div>
<div class="modal-body p-3">
  <div id="scrollBox" class="customScrollbar">
    <app-cms-page-content
      class="d-inline-block"
      *ngIf="pageId"
      [pageId]="pageId"
      (contentLoaded)="checkScrollPosition()"
    ></app-cms-page-content>
  </div>

</div>
<div class="modal-footer">
  <span class="col-md-12 p-0">
    <button
      class="btn btn-success btn-lg "
      (click)="closeModal()"
    >
      {{ "CLOSE" | translate }}
    </button>
  </span>
</div>
