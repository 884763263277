import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef,
  SimpleChanges
} from "@angular/core";
import { Observable, of, Subject, Subscription, fromEvent } from "rxjs";
import {
  map,
  filter,
  debounceTime,
  distinctUntilChanged
} from "rxjs/operators";
import { Article } from "src/app/interfaces/article";
import { SearchService } from "src/app/services/search.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { AccountService } from "src/app/services/account.service";

@Component({
  selector: "app-article-stick-bar",
  templateUrl: "./article-stick-bar.component.html",
  styleUrls: ["./article-stick-bar.component.scss"]
})
export class ArticleStickBarComponent implements OnInit {
  @Input() article: Article;
  state = "hide";
  eventSubscription: any;
  matrixOptions: any;
  private obs;
  isHelpdesk: any;

  constructor(
    public el: ElementRef,
    private searchService: SearchService,
    private deviceDetector: DeviceDetectorService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    let waiting = false;
    let endScrollHandle;
    this.isHelpdesk = this.accountService.isHelpdesk();

    if (!this.deviceDetector.isMobile()) {
      this.obs = fromEvent(document, "scroll").subscribe(e => {
        if (waiting) {
          return;
        }

        waiting = true;

        // clear previous scheduled endScrollHandle
        clearTimeout(endScrollHandle);
        this.onWindowScroll(e);

        setTimeout(() => {
          waiting = false;
        }, 100);

        endScrollHandle = setTimeout(() => {
          this.onWindowScroll(e);
        }, 200);
      });
    }
  }

  ngOnDestroy(): void {
    // Remove scroll listener
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  onWindowScroll(e) {
    if (e.target && e.target.scrollingElement && e.target.scrollingElement.scrollTop > 500) {
      this.state = "show";
    } else {
      this.state = "hide";
    }
  }
}
