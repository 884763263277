<ng-container *ngIf="type !== 'Parts' || monthSelectionOnly; else weekSelection">
  <input
  numberOnly
  [allowDecimals]="false"
  class="articleMonthInput box input"
  [ngClass]="
  optionArticle.stock_status >= 3 &&
  ((optionArticle.stock_week > (month.month === 12 && month.week === 1 ? 52 : month.week) &&
    month.year == optionArticle.stock_year || optionArticle.stock_year === 9999 && !optionArticle.always_order) ||
    optionArticle.stock_year > month.year) &&
  (!optionArticle.always_order || optionArticle.stock_status === 3)
      ? 'disabled'
      : 'enabledInput'
  "
  type="number"
  step="1"
  [min]="optionArticle.is_mandatory ? '1' : '0'"
  [max]="max"
  [value]="value"
  style="padding: 0;"
  placeholder=" "
  [disabled]="
    optionArticle.stock_status >= 3 &&
    ((optionArticle.stock_week > (month.month === 12 && month.week === 1 ? 52 : month.week) &&
      month.year == optionArticle.stock_year || optionArticle.stock_year === 9999 && !optionArticle.always_order) ||
      optionArticle.stock_year > month.year) &&
    (!optionArticle.always_order || optionArticle.stock_status === 3)
  "
  [attr.data-articlecode]="optionArticle.single_article_code"
  [attr.data-month]="month.month"
  [attr.data-brand]="brand"
  [attr.data-week]="month.deliveryWeek"
  [attr.data-year]="month.deliveryYear"
  [attr.data-type]="month.type"
  (change)="quantityChange($event)"
  />
  <span class="d-block unavailable_tooltip" *ngIf="optionArticle.stock_year === 9999 && companyGroup === 'WG' && !optionArticle.always_order"><span>{{"PRE_ORDER_ARTICLE_UNAVAILABLE"|translate}}</span></span>
</ng-container>
<ng-template #weekSelection>
  <table (click)="openModal(optionArticle, $event)">
    <tr *ngIf="quantities.length" style="color: rgb(163, 163, 163)">
      <td>W</td>
      <td>A</td>
    </tr>
    <tr *ngFor="let quantity of quantities">
      <td
        class="articleMonthInput"
        [attr.data-quantity]="quantity.quantity"
        [attr.data-week]="quantity.week != '0' ? quantity.week : undefined"
        [attr.data-year]="quantity.year"
        [attr.data-brand]="brand"
        [attr.data-type]="type"
        [attr.data-articlecode]="optionArticle.single_article_code"
      >
        {{ quantity.week != '0' ?quantity.week : '-' }}
      </td>
      <td>
        {{ quantity.quantity }}
      </td>
    </tr>
  </table>
  <div
    class="text-center edit-week position-relative"
    [ngClass]="disableInputField ? 'disabled' : ''"
    *ngIf="!quantities.length"
  >
    <i class="material-icons" [ngbTooltip]="tipContent" [autoClose]="false" triggers="manual"  #t="ngbTooltip" [placement]="inputIndex == 0 ? 'right' : 'top'" (click)="t.open()">edit</i>
    <ng-template #tipContent>
      <div>
      {{tempQuantities.length ? tempQuantities[0].quantity : ""}}
        <button class="border-0 mx-1" (click)="add()">+</button>
        <button class="border-0 me-2" (click)="decrease()">-</button>
        <button class="border-0 me-1" (click)="openModal(optionArticle, $event)">{{"WEEKS"|translate}}</button>
        <button class="bg-success border-0 me-1" (click)="closeTooltip(t)">{{"SAVE"|translate}}</button>
        <button class="bg-white border-0" (click)="t.close()">&times;</button>
      </div>
    </ng-template>
  </div>
</ng-template>
