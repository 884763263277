import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Article } from 'src/app/interfaces/article';
import { Price } from 'src/app/interfaces/price';
import { AccountService } from 'src/app/services/account.service';
import { ApplicationService } from 'src/app/services/application.service';
import { ArticleService } from 'src/app/services/article.service';

@Component({
  selector: 'app-nett-price',
  templateUrl: './nett-price.component.html',
  styleUrls: ['./nett-price.component.scss'],
})
export class NettPriceComponent implements OnInit {
  @Input() price: Price;
  @Input() article: Article;
  showroomMode = false;
  loading = true;
  @Input() hasDifferentPrices: boolean;
  @Input() showFrom: boolean;
  @Input() showLabel: boolean;
  @Input() volumePrices: any = [];
  parsedPrices: any = [];
  lowestPrice: Price;
  vatText = '';

  constructor(
    private applicationService: ApplicationService,
    private articleService: ArticleService,
    private translateService: TranslateService
  ) {
    this.getShowroomMode();
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['price'] || changes['volumePrices']) {
      if (typeof this.price !== 'undefined') {
        this.parsePrices();
      }
      if (this.price && this.hasDifferentPrices) {
        if (this.lowestPrice?.value < this.price.value) {
          //    this.price = this.lowestPrice;
          this.hasDifferentPrices = true;
        }
      }
    }
  }

  ngOnInit() {
    this.applicationService.changeEmitted$.subscribe((response) => {
      this.getShowroomMode();
    });

    if (this.price?.vat_percentage) {
      this.vatText = this.translateService
        .instant('INLCUDING_VAT')
        .replace('_vat_', this.price.vat_percentage);
    }

    setTimeout(() => {
      this.loading = false;
    }, 1000);

    this.parsePrices();
  }

  parsePrices() {
    const volumePrices = this.volumePrices;
    this.parsedPrices = [];
    if (typeof this.price !== 'undefined') {
      if (volumePrices && volumePrices.length) {
        for (const volumePrice of volumePrices) {
          let hidePrice = false;
          let price = this.articleService.getVolumePrice(volumePrice);

          if (price.value > this.price.value) {
            hidePrice = true;
          }

          if (!hidePrice) {
            this.parsedPrices.push({
              volume: volumePrice['volume'],
              price: this.articleService.getVolumePrice(volumePrice),
            });
          }
        }
        if (this.parsedPrices.length) {
          const prices = this.parsedPrices.sort((a, b) => a.price.value - b.price.value);
          this.lowestPrice = prices[0].price;
          this.price = this.lowestPrice;
          this.hasDifferentPrices = true;
        } else {
          this.hasDifferentPrices = false;

          // Hide volume price indication
          if (typeof this.article !== "undefined") {
            this.article.volume_price = [];
          }
        }
      }
    }
  }
}
