import { Component, OnInit } from '@angular/core';
import { ArticleCompensationResult } from 'src/app/interfaces/warranty/article-compensation-result';

@Component({
  selector: "app-warranty-manfacturer-notify",
  templateUrl: "./warranty-manfacturer-notify.component.html",
  styleUrls: ["./warranty-manfacturer-notify.component.css"],
})
export class WarrantyManfacturerNotifyComponent implements OnInit {
  warrantyCompensation: ArticleCompensationResult = null;
  constructor() {}

  ngOnInit(): void {}
}
