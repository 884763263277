import { Component, OnInit, Input } from '@angular/core';
import { Breadcrumb } from 'src/app/interfaces/bread-crumb';
import { CommonModule, Location } from '@angular/common';
import { SearchService } from 'src/app/services/search.service';
import { Router, RouterModule } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { ApplicationService } from 'src/app/services/application.service';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-partner-portal-breadcrumbs',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule],
  templateUrl: './partner-portal-breadcrumbs.component.html',
  styleUrl: './partner-portal-breadcrumbs.component.scss',
})
export class PartnerPortalBreadcrumbsComponent {
  @Input() breadcrumbs: Breadcrumb[];
  constructor(
    private location: Location,
    private searchService: SearchService,
    private accountService: AccountService,
    private applicationService: ApplicationService,
    private router: Router
  ) {}

  ngOnInit() {}

  gotoUrl(event, url) {
    if (url === '/' || url.indexOf('/search?dt') >= 0) {
      this.searchService.showSearch(true);
      event.preventDefault();
      return false;
    } else if (
      url.indexOf('/search#filter') >= 0 &&
      url.toLowerCase().indexOf('imagebank') < 0
    ) {
      this.location.go(url);
      this.searchService.emitBreadcrumbsChange();
    } else if (url === '#') {
      event.preventDefault();
      return false;
    } else {
      if (this.applicationService.isPartnerPortal() && url.includes('home')) {
        url = `${this.applicationService.getSelectCompanyGroupCode()}/partnerPortal`;
      }
      this.router.navigateByUrl(url);
    }

    event.preventDefault();
    return false;
  }
}
