import { MatrixOption } from "./matrixOption";

export interface ArticleMatrix {
  model_id: number;
  options: MatrixOption[];
}
export class ArticleMatrix implements ArticleMatrix {
  constructor() {
    this.options = Array<MatrixOption>();
  }
}
