import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PackingSlipFinderComponent } from "../packing-slip-finder/packing-slip-finder.component";
import { WarrantyArticle } from "src/app/interfaces/warranty/warranty-article";
import { Article } from "src/app/interfaces/article";
import { TranslateService } from "@ngx-translate/core";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-warranty-returns",
  templateUrl: "./warranty-returns.component.html",
  styleUrls: ["./warranty-returns.component.scss"]
})
export class WarrantyReturnsComponent implements OnInit {
  @Output() partSelect: EventEmitter<Article> = new EventEmitter<Article>();
  @Input() formGroup: FormGroup;
  @Input() warrantyId: number;
  @Input() currentArticle: WarrantyArticle;
  @Output() typeChange: EventEmitter<number> = new EventEmitter<number>();
  tabSelected = "";
  reasonIds = {
    wrong_delivered: "1",
    wrong_ordered: "17",
    damaged: "2",
    battery: "1"
  };
  showSerial: boolean;
  constructor(private modalService: NgbModal, private translationService: TranslateService, private helperService: HelperService) { }

  typeChanged() {
    //Disable inputs
    if (this.tabSelected !== "battery") {
      this.formGroup.get("packingSlipNumber").enable();
      this.formGroup.get("framenumber").disable();
      this.formGroup.get("serialNumber").setValue("");
      this.formGroup.get("serialNumber").updateValueAndValidity();
      this.formGroup.get("batteryKind").setValue("");
      this.formGroup.get("batteryType").setValue("");
      this.formGroup.get("batteryType").disable();
      this.formGroup.get("batteryType").updateValueAndValidity();
      this.showSerial = false;
      this.showPackingSlipFinder();
    } else {
      this.formGroup.get("articleCode").setValue("");
      this.formGroup.get("articleCode").updateValueAndValidity();
      this.formGroup.get("serialNumber").updateValueAndValidity();
      this.formGroup.get("packingSlipNumber").disable();
      this.formGroup.get("framenumber").enable();
      this.formGroup.get("batteryType").setValue("");
      this.formGroup.get("batteryType").enable();
      this.formGroup.get("batteryType").updateValueAndValidity();
      this.showSerial = true;
    }

    this.formGroup.get("guaranteeDate").disable();
    this.typeChange.emit(this.reasonIds[this.tabSelected]);
  }

  ngOnInit() {
    this.formGroup.get("batteryType").clearValidators();
    this.formGroup.get("batteryType").updateValueAndValidity();
    if (this.currentArticle) {
      if (this.currentArticle.article_type && this.currentArticle.article_type.indexOf("battery") >= 0 || this.currentArticle.article_type === "motor") {
        this.tabSelected = "battery";
      } else {
        switch (this.currentArticle.reason_id) {
          case 1:
            this.tabSelected = "wrong_delivered";
            break;
          case 17:
            this.tabSelected = "wrong_ordered";
            break;
          case 2:
            this.tabSelected = "damaged";
            break;
        }
      }

    }
    this.formGroup.get("packingSlipNumber").enable();
    this.formGroup.get("framenumber").disable();
  }

  showPackingSlipFinder() {
    const packingSlipModal = this.modalService.open(
      PackingSlipFinderComponent,
      {
        centered: true,
        container: "#modalContainer",
        backdrop: "static",
        size: "lg"
      }
    );
    this.showSerial = false;
    packingSlipModal.componentInstance.packingSlipArticleSelected.subscribe(
      packingSlip => {
        this.formGroup.get("articleCode").setValue(packingSlip["articleCode"]);
        this.formGroup.get("packingSlipNumber").enable();
        this.formGroup
          .get("packingSlipNumber")
          .setValue(packingSlip["packingSlipNumber"]);
        packingSlipModal.close();

        const articleDescription = packingSlip["article"] && packingSlip["article"].description ? packingSlip["article"].description.toLowerCase() : "";
        const articlePrice = packingSlip["article"] && packingSlip["article"].retail_price ? packingSlip["article"].retail_price.value : 0;
        const currency = packingSlip["article"] && packingSlip["article"].retail_price ? packingSlip["article"].retail_price.currency : "EUR";

        const isBattery = (((packingSlip["description"] && packingSlip["description"].toLowerCase().indexOf("batterijen") >= 0) || articleDescription.indexOf("batterij") >= 0 || articleDescription.indexOf("accu") >= 0) && articleDescription.indexOf("oplader") < 0) && this.helperService.isBatteryOrMotorPrice(articlePrice, currency);
        const isMotor =  !isBattery && articleDescription.indexOf("oplader") < 0 && this.helperService.isBatteryOrMotorPrice(articlePrice, currency);

        if (isBattery || isMotor) {
          this.showSerial = true;
          this.formGroup.get("batteryKind").setValue(isMotor ? "motor" : "main_battery");
          this.formGroup.get("batteryType").setValue(isBattery ? "liion_battery" : "");
          packingSlip["description"] = ` (${this.translationService.instant("BATTERY")} - ${packingSlip["articleCode"]})`;
        } else if (articleDescription) {
          packingSlip["description"] = articleDescription;
        }

        this.partSelect.next(packingSlip);
      }
    );
  }

  setTab(tabId: string) {
    this.tabSelected = tabId;
    this.typeChanged();
  }

}
