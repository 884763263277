<div class="row">
  <div class="col-12">
    <h1><app-header-icon iconName="assignment_return"></app-header-icon>{{ "RETURNS" | translate }} #{{ id }}</h1>
    <ng-container *ngIf="warrantyDetails && selectedForShipment">
      <ng-content></ng-content>
      <div class="from-group row" *ngIf="warrantyDetails.active">
        <div class="col-6">
          <label class="me-2">{{ "REMARKS" | translate }} {{ "OWN_ADMINISTRATION" | translate }}:
          </label>
          <input type="text" class="form-control form-control-lg w-50" maxlength="60" [(ngModel)]="warrantyDetails.remarks"
            (change)="updateReference()" />
        </div>
        <div class="col-6 d-flex justify-content-end">
          <button class="btn btn-success btn-lg " (click)="addArticle()" *ngIf="warrantyDetails.warranty_articles?.length < 64">
            {{ "ADD_WARRANTY_ARTICLE" | translate }}
          </button>
          <ngb-alert [dismissible]="false" type="danger" *ngIf="warrantyDetails.warranty_articles?.length >= 64">
            {{ "MAX_WARRANTY_REACHED" | translate }}
          </ngb-alert>
        </div>
      </div>


      <div *ngIf="warrantyDetails.credit_note_info?.retour_lines" class="d-flex flex-column mb-4 p-3 border rounded">
        <h5>{{"STATUS"|translate}}: <span class="p-1 rounded text-white" [class.bg-success]="warrantyDetails.credit_note_info?.retour_status === 'CO'" [class.bg-dark]="warrantyDetails.credit_note_info?.retour_status === 'NE'"  [class.bg-warning]="warrantyDetails.credit_note_info?.retour_status === 'PC'">{{status[warrantyDetails.credit_note_info?.retour_status]|translate}}</span>
          <span class="d-block mt-2" *ngIf="warrantyDetails.rth_number">
            {{"REFERENCENO"|translate}}: {{warrantyDetails.rth_number}}
          </span>
        </h5>
        <h5>{{"INVOICES"|translate}}:
          <a *ngFor="let retour_line of warrantyDetails.credit_note_info?.retour_lines" (click)="viewInvoice(retour_line.invoice_number)" class="btn btn-default d-inline-flex align-items-center me-1 mb-1"><i class="material-icons me-1">
            picture_as_pdf
          </i> {{"INVOICE"|translate}} {{retour_line.invoice_number}}</a>
        </h5>
      </div>

      <p *ngIf="
          !warrantyDetails.active &&
          (groupedArticles.regular.length ||
            groupedArticles.lease.length ||
            groupedArticles.motors.length ||
            groupedArticles.batteries.length)
        ">
        <button class="btn btn-primary" (click)="showLabels()">
          {{ "SHOW_LABELS" | translate }}
        </button>
      </p>

      <ngx-skeleton-loader count="20" appearance="line" [theme]="{ height: '80px' }" *ngIf="!loaded">
      </ngx-skeleton-loader>

      <!-- Lease -->
      <div class="art-container mt-2"  *ngIf="
        loaded && groupedArticles.lease && groupedArticles.lease.length
      ">
       <h2 class="mt-2 w-100 mb-0">{{ "LEASE" | translate }} ({{"SERVICE"|translate}} / {{"SERVICE_MAINTENANCE"|translate}})</h2>
       <h5>{{ "DISPOSE_ARTICLES" | translate }}</h5>
       <app-select-for-warranty-shipment *ngIf="warrantyDetails.active" [name]="'lease_wrt'"
         [selectedValue]="selectedForShipment.lease"
         (changed)="selectedForShipment.saveSelection('lease', id, $event)">
       </app-select-for-warranty-shipment>
       <app-warranty-requests-container [articles]="groupedArticles.lease" [showControls]="warrantyDetails.active"
         (added)="getDetails()">
       </app-warranty-requests-container>
      </div>


      <!-- Regular -->
      <div class="art-container mt-2"  *ngIf="
          loaded && groupedArticles.regular && groupedArticles.regular.length
        ">
        <h2 class="mt-2 w-100">{{ "REGULAR_ARTICLES" | translate }}</h2>
        <app-select-for-warranty-shipment *ngIf="warrantyDetails.active" [name]="'regular_wrt'"
          [selectedValue]="selectedForShipment.regular"
          (changed)="selectedForShipment.saveSelection('regular', id, $event)">
        </app-select-for-warranty-shipment>
        <app-warranty-requests-container [articles]="groupedArticles.regular" [showControls]="warrantyDetails.active"
          (added)="getDetails()">
        </app-warranty-requests-container>
      </div>

      <div class="mt-2" [hidden]="
          !groupedArticles.regular_dispose ||
          !groupedArticles.regular_dispose.length
        ">
        <h2 class="mt-1">{{ "DISPOSE_ARTICLES" | translate }}</h2>
        <app-warranty-requests-container [articles]="groupedArticles.regular_dispose"
          [showControls]="warrantyDetails.active">
        </app-warranty-requests-container>
      </div>

      <!-- Batteries -->
      <div class="art-container" *ngIf="
          loaded &&
          groupedArticles.batteries &&
          groupedArticles.batteries.length
        ">
        <h2 class="mt-1">{{ "BATTERIES_AND_CHARGERS" | translate }}</h2>
        <app-select-for-warranty-shipment *ngIf="warrantyDetails.active" [name]="'battery_wrt'"
          [selectedValue]="selectedForShipment.batteries"
          (changed)="selectedForShipment.saveSelection('batteries', id, $event)">
        </app-select-for-warranty-shipment>
        <app-warranty-requests-container [articles]="groupedArticles.batteries" [showControls]="warrantyDetails.active"
          (added)="getDetails()">
        </app-warranty-requests-container>
      </div>

      <!-- Motors -->
      <div class="art-container" *ngIf="
          loaded && groupedArticles.motors && groupedArticles.motors.length
        ">
        <h2 class="mt-1">{{ "MOTOR" | translate }}</h2>
        <app-select-for-warranty-shipment *ngIf="warrantyDetails.active" [name]="'motor_wrt'"
          [selectedValue]="selectedForShipment.motors"
          (changed)="selectedForShipment.saveSelection('motors', id, $event)">
        </app-select-for-warranty-shipment>
        <app-warranty-requests-container [articles]="groupedArticles.motors" [showControls]="warrantyDetails.active"
          (added)="getDetails()">
        </app-warranty-requests-container>
      </div>

      <!-- Buttons -->
      <app-bottom-navbar [fixed]="true">
        <ng-container *ngTemplateOutlet="options"></ng-container>
      </app-bottom-navbar>
    </ng-container>

    <ng-template #options>
      <div class="col-6 col-md-6 px-0">
        <a class="btn btn-dark btn-lg"
          [routerLink]="['/' + companyGroupCode + '/Warranty/']">{{ "BACK" | translate }}</a>
      </div>
      <div class="col-6 col-md-6 px-0 text-end">
        <a class="btn btn-primary btn-lg" *ngIf="
            warrantyDetails.active &&
            this.warrantyDetails.warranty_articles &&
            this.warrantyDetails.warranty_articles.length
          " [routerLink]="[
            '/' + companyGroupCode + '/warrantySendOverview/' + id
          ]">{{ "NEXT" | translate }}</a>
      </div>
    </ng-template>
  </div>
</div>
