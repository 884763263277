<div class="row">
  <ng-content></ng-content>
  <div class="col-4 pe-0" *ngIf="orderWidget !== null">
    <swiper-container

      [slidesPerView]="1"
      [autoplay]="false"
      init="false"
      #swiper
      class="position-relative promotions-carousel carousel"
    >
      <swiper-slide>
        <div class="image-wrapper">
          <img
            [src]="orderWidget.language_definitions[0].image_url"
            [alt]="orderWidget.language_definitions[0].title"
            class="img"
            (click)="gotoUrl(orderWidget)"
            loading="lazy"
          />
        </div>
        <p class="title" *ngIf="applicationService.getSelectCompanyGroupCode() !== 'RAL'">{{ orderWidget.language_definitions[0].title }}</p>
      </swiper-slide>
    </swiper-container>
  </div>
  <div class="col-8 ps-1" [ngClass]="orderWidget !== null ? 'col-8 ps-0' : 'col-12'">
    <swiper-container
      *ngIf="widgets && widgets.length"
      [slidesPerView]="orderWidget ? 2 : 3"
      [autoplay]="true"
      [navigation]="true"
      init="false"
      #swiper
      class="position-relative promotions-carousel carousel"
    >
      <ng-container *ngFor="let slide of widgets; index as i">
        <swiper-slide *ngIf="slide.language_definitions.length">
          <div class="image-wrapper">
            <img
              [src]="slide.language_definitions[0].image_url"
              [alt]="slide.language_definitions[0].title"
              class="img"
              (click)="gotoUrl(slide)"
              loading="lazy"
            />
          </div>
          <p class="title" *ngIf="applicationService.getSelectCompanyGroupCode() !== 'RAL'">{{ slide.language_definitions[0].title }}</p>
        </swiper-slide>
      </ng-container>
    </swiper-container >
  </div>
</div>
