<div (click)="showDetails()" class="news-item mb-3 p-3" [class.clickable]="!newsItem?.detailled">
  <div class="row mx-0">
    <div class="col-md-4 col-lg-4 col-xl-3 col-3 pe-3 ps-0">
      <!-- Image -->
      <img
        [src]="newsItem.language_definitions[0].image_url"
        loading="lazy"
        class="w-100 mb-2"
        alt="{{ newsItem.language_definitions[0].title }}"
      />
    </div>
    <div class="col-md-8 col-lg-8 col-xl-9 col-9 px-0">
      <!-- Title -->
      <h5 class="mb-1 fw-bold">
        {{ newsItem.language_definitions[0].title }}
      </h5>
      <!-- Date -->
      <h6>
        <small class="mb-2 text-muted text-uppercase date-heading">{{
          formattedDate
        }}</small>
      </h6>
      <!-- Summary -->
      <p
        class="with-ellipsis"
        *ngIf="!newsItem.detailled"
        [innerHTML]="newsItem.language_definitions[0].summary"
      ></p>

      <div *ngIf="story && story.content && !story.html && newsItem.detailled">
        <p>
        <ndc-dynamic
          [ndcDynamicComponent]="components['page']"
          [ndcDynamicInputs]="story"
        >
        </ndc-dynamic>
        </p>
      </div>
      <p class="content" [hidden]="!story.html || !newsItem.detailled"></p>
    </div>
  </div>
</div>
