export interface ChangePasswordContainer {
    "old_password": string;
    "new_password": string;
    "confirm_password": string;
    "bearer_token": string;
}

export class ChangePasswordContainer implements ChangePasswordContainer {
  constructor() {
    this.old_password = "";
    this.new_password = "";
    this.confirm_password = "";
    this.bearer_token = "";
  }
}
