import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ImagebankService {
  constructor(private http: HttpClient) {}

  getBrandImages(brandid: Number) {
    return this.http.get(environment.apiendpoint + "brandimages/" + brandid);
  }

  getBrandImage(imageid: number) {
    return this.http.get(
      `${environment.apiendpoint}brandimages/${imageid}/download?fileType=pdf`
    );
  }

  deleteBrandImage(imageid: number) {
    return this.http.delete(
      `${environment.apiendpoint}brandimages/${imageid}`
    );
  }
}
