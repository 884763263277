import { EbstAccess } from "./ebst-access";
import { AcademyAccess } from "./academy-access";
import { EbstPermissions } from "./ebst-permissions";
import { ShippingAddressGroup } from "./shipping-address-group";

export interface UpdateUserInput {
  username: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  profile_id: number;
  password: string;
  ebst: EbstAccess;
  rayon_codes: RayonCode[];
  ebst_permissions: EbstPermissions;
  academy: AcademyAccess;
  shipping_ids: ShippingAddressGroup[];
  owner: boolean;
}

export class UpdateUserInput implements UpdateUserInput {


}

export interface RayonCode {
  company_code: string;
  code: string;
}
