import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "currencyConvert",
})
export class CurrencyConvertPipe implements PipeTransform {
  transform(value: number, currency: string): any {
    if (currency === null) {
      currency = "";
    }
    if (value || value === 0) {
      const convertedValue = String(Number(value).toFixed(2));
      const hasDot = convertedValue.indexOf(".") > 0;
      return (
        (currency !== "EUR" && typeof currency !== "undefined"
          ? currency
          : "&euro;") +
        " " +
        this.countryFormat(convertedValue) +
        (!hasDot ? ",-" : "")
      );
    }
  }

  private countryFormat(value) {
    const country = localStorage.getItem("country")
      ? localStorage.getItem("country")
      : "NL";
    let dotsDecimal = false;
    if (country !== "GB" && country !== "IE") {
      value = Number(value).toLocaleString("nl-NL", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      dotsDecimal = false;
    } else {
      // Use dots for DB / Ireland
      value = Number(value).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      dotsDecimal = true;
    }
    return value;
  }
}
