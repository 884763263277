import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Invoice } from "src/app/interfaces/financial/invoices/invoice";
import { NumberFormatPipe } from "src/app/pipes/number-format.pipe";
import { ApplicationService } from "src/app/services/application.service";
import { FinancialService } from "src/app/services/financial.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PdfViewerComponent } from "src/app/components/general-components/pdf-viewer/pdf-viewer.component";
import { PaymentService } from "src/app/services/payment.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-invoice-line",
  templateUrl: "./invoice-line.component.html",
  styleUrls: ["./invoice-line.component.scss"],
  providers: [NumberFormatPipe],
})
export class InvoiceLineComponent implements OnInit {
  @Input() invoice: Invoice;
  @Output() invoiceSelectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() onlinePaymentRestrictionLevel: number;
  paymentUrl: string = "";
  @Input() disablePayment: boolean;
  @Input() disablePaymentSelection: boolean;
  constructor(
    public numberFormat: NumberFormatPipe,
    public applicationService: ApplicationService,
    private financialService: FinancialService,
    private paymentService: PaymentService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {}

  invoiceSelectionChanged() {
    this.invoiceSelectionChange.emit();
  }

  viewInvoice() {
    if (this.invoice.invoice_type !== "credit") {
      this.financialService
        .getInvoicesPdfFile([this.invoice.invoice_number])
        .subscribe((blob: Blob) =>
          this.showPdfDialog(blob, `invoice_${this.invoice.invoice_number}.pdf`)
        );
    } else {
      this.financialService
        .getCreditPdfFile([this.invoice.invoice_number])
        .subscribe((blob: Blob) =>
          this.showPdfDialog(blob, `invoice_${this.invoice.invoice_number}.pdf`)
        );
    }
  }

  showPdfDialog(blob: Blob, fileName: string) {
    const modalRef = this.modalService.open(PdfViewerComponent, {
      centered: true,
      size: "lg",
      container: "#modalContainer",
    });
    modalRef.componentInstance.fileName = fileName;
    modalRef.componentInstance.pdfSrc = blob;
    event.preventDefault();
    return false;
  }

  get paid() {
    return this.invoice.date_paid !== "00000000";
  }

  payInvoice() {
    this.paymentService
      .getInvoicePaymentUrl(this.invoice.invoice_number)
      .subscribe((response: ApiResponse) => {
        if (response.success) {
          this.paymentUrl = response.result;
          window.location.href = this.paymentUrl;
        } else {
          this.alertService.showErrorNotification(
            this.translateService.instant("NO_INVOICE_PAYMENT_FOUND")
          );
        }
      });
  }
}
