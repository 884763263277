<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container">
  <div class="row">
    <div class="col-12 col-md-9 col-lg-6 px-3 mx-auto">
      <h1>{{ "UNMERGE_DEALER" | translate }}</h1>
      <form  ngNativeValidate>
        <div class="form-row mb-3">
          <div class="col">
            <label class="fw-bold" for=""
              >{{ "USERNAME" | translate }}:</label
            >
            <div>
              <input
                type="text"
                class="form-control"
                name="dealerID"
                [(ngModel)]="dealerID"
                required
              />
            </div>
          </div>
        </div>
        <div class="form-row" *ngIf="!isValidForUnmerge">
          <div class="col">
            <ng-container *ngIf="errorMessages && errorMessages.length">
              <div class="alert alert-warning" role="alert">
                {{ 'UNMERGE_UNSUCCESSFUL' | translate }}:
                <ul>
                  <li *ngFor="let message of errorMessages" [innerHTML]="message"></li>
                </ul>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="form-row" *ngIf="isValidForUnmerge && submittedSuccessfully">
          <div class="col">
              <div class="alert alert-success" role="alert">
                {{ 'UNMERGE_SUCCESSFUL' | translate }}
              </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col mt-3">
            <input
              type="submit"
              (click)="checkIfDealerIsValid()"
              [value]="'UNMERGE' | translate"
              [disabled]="!dealerID"
              class="btn btn-primary"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
