import { Article } from "./article";
import { ArticleMatrix } from "./article-matrix";
import { Price } from "./price";
import { AvailableArticleTypes } from "./article-type";

export interface BasketArticle extends Article {
  article_code: string;
  quantity: number;
  remarks: string;
  sold: boolean;
  updating_delivery: boolean;
}

export class BasketArticle implements BasketArticle {
  shopType: string;
  add_date: string;
  user_name: string;
  article_code: string;
  quantity: number;
  remarks: string;
  sold: boolean;
  delivery_week: string;
  delivery_year: string;
  specs: any[];
  parsedSpecs: any[];
  article_codes: string[] | null;
  id: number;
  model_code: string;
  single_article_code: string;
  brand: string;
  ean: string;
  article_type: AvailableArticleTypes;
  company_id: string;
  description: string;
  caption: string;
  retail_price: Price;
  net_price: Price;
  gross_price: Price;
  volume_price: [];
  min_order_amount: number;
  oem: number;
  base_collection: boolean;
  new: boolean;
  promotion: boolean;
  stock_year: number;
  stock_week: number;
  stock_date: string;
  stock_status: number;
  orderline_number: string;
  thumbs?: string[] | null;
  images?: string[] | null;
  colors?: string[] | null;
  bom: boolean;
  option_count: number;
  thumbnail: string;
  selected: boolean;
  matrix: ArticleMatrix;
  selected_for_compare: boolean;
  alternative_ecommerce: boolean;
  cross_sell_ecommerce: boolean;
  has_mandatory_item: boolean;
  is_mandatory: boolean;
  setitem: boolean;
  article_details: Article;
  item_details: Article;
  parsedImages: string[];
  has_replacement: boolean;
  replacement_article: {};
  img: any[] | null;
  value: string;
  checkout: boolean;
  usps: any[] | null;
  backorder: boolean;
  price: Price;
  amount: number;
  warning: string;
  asap_delivery: boolean;
  parent_found: boolean;
  workshop: boolean;
  order_type: string;
  order_line_group: string;
  parent_basket_item_id: number;
  parent_id: number;
  children: BasketArticle[];
  week: any;
  year: any;
  basket_id: number | string;
  constructor() {
    this.delivery_week =
      String(this.delivery_week).length < 1
        ? "0" + this.delivery_week
        : this.delivery_week;
  }
}
