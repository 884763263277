import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { ImagebankService } from "src/app/services/imagebank.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { AtmosphericImage } from "src/app/interfaces/imageBank/atmospheric-image";
import * as _ from "underscore";
import { AvailableBrands } from "src/app/interfaces/brands";
import { Dictionary } from "underscore";
import { ApplicationService } from "src/app/services/application.service";
import { HelperService } from "src/app/services/helper.service";
import { environment } from "src/environments/environment";
import { MenuLayout } from "src/app/interfaces/menu-layout";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageBankConditionsModalComponent } from "../image-bank-conditions-modal/image-bank-conditions-modal.component";


@Component({
  selector: "app-image-bank-article-container",
  templateUrl: "./image-bank-article-container.component.html",
  styleUrls: ["./image-bank-article-container.component.scss"],
})
export class ImageBankArticleContainerComponent implements OnInit {
  @Input() articles: Article[];
  @Input() activeTab = "";
  @Output() tabSelected: EventEmitter<string> = new EventEmitter<string>();
  images: AtmosphericImage[];
  filteredImages: AtmosphericImage[];
  years: string[] = [];
  yearsCount: Dictionary<number>;
  yearsCountAll: number;
  brands: string[] = [];
  brandsCount: Dictionary<number>;
  brandsCountAll: number;
  brandnames: string[];
  selectedyear: string = "";
  selectedbrand = 0;
  imagesData: boolean = false;
  totalCount = 18;
  keyword: string = "";
  showConditions: boolean;
  showDeleteButton = false;
  fileTypes: any[];
  fileTypesCount: {};
  selectedFileType = "";
  constructor(
    private imagebankService: ImagebankService,
    public applicationService: ApplicationService,
    private helperService: HelperService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.setTab(null, "articles");
    this.applicationService.getMenuItems().then((layout: MenuLayout) => {
      this.showDeleteButton = environment.imageBankAdmins.includes(
        layout.user_name.toLowerCase()
      );
      if (this.applicationService.getSelectCompanyGroupCode() === "ANL") {
        this.showConditions = true;
      }
    });
  }

  _resetCount() {
    this.totalCount = 18;
  }
  setTab(event, tabId) {
    this.activeTab = tabId;
    this.selectedyear = "";
    this.selectedbrand = 0;
    this._resetCount();

    if (this.activeTab === "atmospheric") {
      this.getBrandImages(0);
    }

    this.tabSelected.emit(tabId);
    if (event) {
      event.preventDefault();
    }
    return false;
  }

  getBrandImages(brandid: Number) {
    this.imagebankService
      .getBrandImages(brandid)
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.images = apiResponse.result;
          this.filteredImages = apiResponse.result;
          this.getYears(this.images);
          this.getBrands(this.images);
          this.getFiletypes(this.images);
        }
      });
  }

  getYears(results: AtmosphericImage[]) {
    this.years =  (_.uniq(_.pluck(results, "model_year"))).sort();
    this.yearsCount = _.countBy(results, "model_year");
  }

  getFiletypes(results: AtmosphericImage[]) {
    let fileTypes = [];
    let fileTypesCount = {};
    results.filter((f: AtmosphericImage) => {
      const fileType = f.cdn_name.substring(f.cdn_name.lastIndexOf(".") + 1).toLowerCase();
      if (!fileTypes.includes(fileType)) {
        fileTypes.push(fileType)
      }
      if (typeof  fileTypesCount[fileType] === "undefined") {
        fileTypesCount[fileType] = 0;
      }
      fileTypesCount[fileType]++;
    })
    this.fileTypes = fileTypes;
    this.fileTypesCount = fileTypesCount;
  }

  getBrands(results: AtmosphericImage[]) {
    for (let result of results) {
      // parse to enum
      const brand: AvailableBrands = AvailableBrands[result.brand_id];

      if (brand) {
        // set brand description
        result.brand_description = brand.toString();
      }
    }
    this.brands = _.uniq(_.pluck(results, "brand_description"));
    this.brandsCount = _.countBy(results, "brand_description");
    this.brandsCountAll = results.length;
    this.yearsCountAll = results.length;
    if (results.length > 0) {
      this.imagesData = true;
    } else {
      this.imagesData = false;
    }
  }

  filter(filterBy: string = "") {
    this._resetCount();
    const filteredImages = this.images.filter((i: AtmosphericImage) => {
      return (
        (i.brand_description === String(this.selectedbrand) ||
          !this.selectedbrand) &&
        (i.model_year === this.selectedyear || !this.selectedyear) &&
        i.cdn_name.toLowerCase().includes(this.keyword.toLowerCase()) && i.cdn_name.toLowerCase().endsWith(`${this.selectedFileType.toLowerCase()}`)
      );
    });
    if (filterBy === "brand") {
      this.yearsCount = _.countBy(filteredImages, "model_year");
      this.yearsCountAll = filteredImages.length;
    }

    if (filteredImages.length > 0) {
      this.imagesData = true;
    } else {
      this.imagesData = false;
    }

    this.filteredImages = filteredImages;
  }

  trackByFunction(image: AtmosphericImage) {
    if (!image) return null;
    return image.id;
  }

  search() {
    this.filter("");
  }

  showConditionsModal() {
    const modalRef = this.modalService.open(ImageBankConditionsModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: "medium",
      container: '#modalContainer',
      centered: true,
    });

  }


}
