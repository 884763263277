import { Component, OnInit } from "@angular/core";
import { BasketService } from "src/app/services/basket.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationService } from "src/app/services/application.service";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { Basket } from "src/app/interfaces/basket";
import { TranslateService } from "@ngx-translate/core";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Location } from "@angular/common";
import { GroupedBasketArticles } from "src/app/interfaces/grouped-basket-articles";
import { AlertService } from "src/app/services/alert.service";
import { TrackingService } from "src/app/services/tracking.service";
import { AccountInformation } from "src/app/interfaces/account-information";
import { AccountService } from "src/app/services/account.service";
import { HelperService } from "src/app/services/helper.service";
@Component({
  selector: "app-basket-payment-conditions",
  templateUrl: "./basket-payment-conditions.component.html",
  styleUrls: ["./basket-payment-conditions.component.scss"],
})
export class BasketPaymentConditionsComponent implements OnInit {
  basketId: 0;
  showroomMode = false;
  basket: Basket;
  breadcrumbs: any = [];
  ordered = false;
  bico = false;
  paymentCondition: String[];
  selected = "";
  valid = false;
  flex_discount = false;
  groupId: string;
  basketSum: {};
  languageCode: string;
  countryCode: any;
  hideBico: boolean;
  translationKey: string;
  loading: boolean;
  zeg: boolean;
  accountLoaded: boolean;
  showFlex: boolean;

  get isBenelux() {
    const benelux = ["NL", "BE", "LU"];
    return benelux.indexOf(this.countryCode) >= 0;
  }

  constructor(
    private translateService: TranslateService,
    private basketService: BasketService,
    public applicationService: ApplicationService,
    private route: ActivatedRoute,
    private router: Router,
    private trackingService: TrackingService,
    private alertService: AlertService,
    private accountService: AccountService,
    private helperService: HelperService
  ) {
    this.getShowroomMode();
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  ngOnInit() {
    this.languageCode = this.applicationService.getSelectedLanguage();
    this.getAccountInformation();
    this.route.params.subscribe((params) => {
      if (params["groupId"]) {
        this.groupId = params["groupId"];
        if (this.groupId === "direct") {
          this.flex_discount = true;
        }
      }
      if (params["id"]) {
        this.basketId = params["id"];
        this.getCheckoutBasket();
      }
    });
  }
  getAccountInformation() {
    const noBico = ["HR", "HU", "CZ", "SK", "IT", "PL"];
    this.accountService
      .getAccountInformation()
      .then((accountInformation: AccountInformation) => {
        this.accountLoaded = true;
        this.countryCode = accountInformation.country_code;
        this.bico = accountInformation.profile?.dealer_association === "BICO";
        this.zeg = accountInformation.profile?.dealer_association === "ZEG";

        switch (this.countryCode) {
          case "AT":
          case "CH":
            this.translationKey = `_${this.countryCode}`;
            break;
          case "PL":
          case "SK":
          case "HR":
          case "CZ":
            this.translationKey = `_HR`;
          break;
          case "NL":
          case "BE":
          case "LU":
            this.translationKey = `_BENELUX`;
            break;
          case "IT":
            this.translationKey = `_IT`;
            break;
          default:
            this.translationKey = "";
        }
      });
  }

  addBreadCrumbs() {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/"
      ),
      new Breadcrumb(
        this.translateService.instant("BASKET"),
        this.applicationService.getSelectCompanyGroupCode() +
        (`/preorderCheckout/${this.basket.basket_type === "PreOrderBikes" || this.basket.preorder_type === "Bicycles" ?"Bikes":"Parts"}/`) +
          this.basketId
      ),
      new Breadcrumb(this.translateService.instant("PAYMENT_CONDITIONS"), "#"),
    ];
  }

  getCheckoutBasket() {
    this.applicationService.showLoader(false);
    this.basketService
      .getCheckoutBasket(this.basketId)
      .subscribe((response: ApiResponse) => {
        this.applicationService.hideLoader();
        this.basket = response.result;
        this.calcTotal(this.basket.items);
        this.checkSelection();
        this.addBreadCrumbs();
      });
  }

  back() {
    const url =
      this.applicationService.getSelectCompanyGroupCode() +
      (`/preorderCheckout/${this.basket.basket_type === "PreOrderBikes" || this.basket.preorder_type === "Bicycles" ?"Bikes":"Parts"}/`) +
          this.basketId + "/" +
      this.groupId;

    this.router.navigateByUrl(url);
  }

  checkSelection() {
    if (this.bico || this.zeg) {
      this.selected = "";
      this.valid = true;
      return true;
    }

    if (this.groupId === "DEMO" || this.groupId === "REPAIR") {
      this.selected = Boolean(this.selected) === true ? "3" : "";
    }

    switch (this.groupId) {
      case "direct":
      case "02":
      case "04":
      case "DEMO":
      case "REPAIR":
      case "Lapierre":
      case "all":
      default:
        this.valid = this.selected !== "";
        break;
    }
  }

  calcTotal(articles) {
    this.basketSum = this.basketService.calcTotal(
      articles,
      "basket",
      this.showroomMode,
      this.basket.shipping_cost,
      "regular",
      0
    );
  }

  checkout() {
    this.loading = true;
    this.basketService
      .addPaymentConditionBasket(this.basketId, {
        payment_condition: this.bico ? 2 : Number(this.selected),
        flex_discount: this.flex_discount ? true : false,
      })
      .subscribe((response: ApiResponse) => {
        this.loading = false;
        if (this.helperService.checkResponse(response)) {
          this.ordered = true;
          this.alertService.showPopup(
            this.translateService.instant("ORDER_SENT_SUCCESS")
          );

          // Track purchase
          this.trackingService.purchase(
            String(this.basketId),
            this.applicationService.getSelectCompanyGroupCode(),
            this.basket.items,
            this.basketSum["totalWithShipping"],
            String(
              this.basket.shipping_cost ? this.basket.shipping_cost.value : 0
            )
          );

          // Remove stored ordered baskets
          sessionStorage.removeItem("baskets");

          this.router.navigate([
            this.applicationService.getSelectCompanyGroupCode() +
              "/order/" +
              this.basketId,
          ]);
        }
      });
  }
}
