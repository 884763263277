<div class="px-0 col-md-12">
  <form>
    <div
      class="px-3 pt-3 pb-1 row"
      [ngClass]="
        applicationService.getSelectCompanyGroupCode() !== 'WG'
          ? 'justify-content-around'
          : 'justify-content-around justify-content-sm-between'
      "
    >
      <div
        class="mb-3 row"
        [ngClass]="
          applicationService.getSelectCompanyGroupCode() === 'WG'
            ? 'col-md-4'
            : 'col-md-3 col-lg-3'
        "
        [ngStyle]="{
          'max-width':
            applicationService.getSelectCompanyGroupCode() === 'WG'
              ? '100%'
              : '320px;'
        }"
      >
        <div class="ps-0 col-md-7 ps-md-3 pe-0">
          <label class="fw-bold" for="keyWord">
            {{ "SEARCH" | translate }}</label
          >
          <input
            type="search"
            class="form-control"
            id="keyWord"
            name="keyWord"
            [(ngModel)]="filters['keyWord']"
          />
        </div>
        <div class="ps-0 col-md-5 pe-0 pe-md-3">
          <label
            class="fw-bold"
            for="keyFilter"
            style="visibility: hidden"
            >Filter</label
          >
          <select
            id="keyFilter"
            class="form-control btn btn-outline-dark"
            name="keyWordOption"
            [(ngModel)]="filters['keyWordOption']"
          >
            <option
              *ngFor="let option of filterOptions.keyWordOptions"
              [value]="option.value"
            >
              {{ option.description | translate }}
            </option>
          </select>
        </div>
      </div>

      <div
        class="mb-3 col-md-1 col-lg-1"
        *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'"
      >
        <label class="fw-bold" for="soldState">{{
          "SOLD" | translate
        }}</label>
        <select
          id="soldState"
          class="form-control"
          [(ngModel)]="filters['sold']"
          name="sold"
          style="min-width: 70px"
        >
          <option
            *ngFor="let option of filterOptions.soldOptions"
            [value]="option.value"
          >
            {{ option.description | translate }}
          </option>
        </select>
      </div>
      <div
        class="mb-3 col-md-2 col-lg-2"
        *ngIf="
          applicationService.getSelectCompanyGroupCode() == 'WG' &&
          applicationService.checkIfDealerHasCompany(101) &&
          applicationService.checkIfDealerHasCompany(102)
        "
      >
        <label class="fw-bold" for="companyFilter">{{
          "SELECT_COMPANY" | translate
        }}</label>
        <select
          class="form-control"
          [(ngModel)]="filters['companyFilter']"
          (change)="submit()"
          name="companyFilter"
        >
          <option value="" selected>{{ "ALL" | translate }}</option>
          <option
            value="101"
            *ngIf="applicationService.checkIfDealerHasCompany(101)"
          >
            Winora Bikes
          </option>
          <option
            value="102"
            *ngIf="applicationService.checkIfDealerHasCompany(102)"
          >
            E. Wiener Bike Parts
          </option>
        </select>
      </div>
      <div
        class="mb-3 col-md-1 col-lg-1"
        *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'"
      >
        <label class="fw-bold" for="inputState">{{
          "ARTICLE_KIND" | translate
        }}</label>
        <select
          class="form-control"
          [(ngModel)]="filters['articleType']"
          name="articleType"
        >
          <option
            *ngFor="let option of filterOptions.articleKindOptions"
            [value]="option.value"
          >
            {{ option.description | translate }}
          </option>
        </select>
        <ng-container *ngIf="filters['articleType'] === 'bicycles' && (bicycleBrands && bicycleBrands.length > 1)">
          <label class="fw-bold" for="companyFilter">{{
            "SELECT_COMPANY" | translate
          }}</label>
          <select
            class="form-control"
            [(ngModel)]="filters['companyFilter']"
            name="companyFilter"
          >
            <option value="">{{ "ALL" | translate }}</option>
            <option *ngFor="let brand of bicycleBrands" [value]="brand.belongs">
              {{ brand.description }}
            </option>
          </select>
        </ng-container>
      </div>
      <div
        class="mb-3 col-md-2 col-lg-2"
        *ngIf="
          applicationService.getSelectCompanyGroupCode() !== 'WG' &&
          shippingAddressGroup &&
          shippingAddressGroup.shipping_addresses.length
        "
      >
        <label class="fw-bold" for="inputState">{{
          "SHIPPINGADDRESS" | translate
        }}</label>
        <app-select-shipping-address
          [shippingAddresses]="shippingAddressGroup"
          [showBlank]="true"
          (shippingAddressSelected)="setShippingAddress($event)"
        ></app-select-shipping-address>
      </div>
      <div
        class="mb-3 col-md-1 col-lg-1"
        *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'"
      >
        <label class="fw-bold" for="inputState">{{
          "ORDERDATE" | translate
        }}</label>
        <div class="input-group">
          <input
            class="form-control bg-white"
            placeholder="dd-mm-YYYY"
            [maxDate]="{
              year: maxDate.getFullYear(),
              month: maxDate.getMonth() + 1,
              day: maxDate.getDate()
            }"
            name="dp"
            (click)="d.toggle()"
            [(ngModel)]="filters['orderDate']"
            ngbDatepicker
            readonly
            #d="ngbDatepicker"
          />
        </div>
      </div>
      <div
        class="mb-3 col-md-2 col-lg-1"
        *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'"
      >
        <label class="fw-bold">{{
          "OPEN_ORDER_STATUS" | translate
        }}</label>
        <div class="input-group">
          <select
            id="OpenOrderStatus"
            class="form-control"
            name="openOrderStatus"
            [(ngModel)]="filters['openOrderStatus']"
          >
            <option value="all">
              {{ "OPEN_ORDER_STATUS_ALL" | translate }}
            </option>
            <option value="inDeliveryOrDelivered">
              {{ "OPEN_ORDER_STATUS_PARTIAL" | translate }}
            </option>
            <option value="orderedOrBackorder">
              {{ "OPEN_ORDER_STATUS_NEW" | translate }}
            </option>
          </select>
        </div>
      </div>
      <div class="mb-3 col-md-2 col-lg-2">
        <label for="inputState" style="visibility: hidden">{{
          "SEARCH" | translate
        }}</label>
        <div class="me-1 input-group">
          <div class="btn-group" role="group">
            <button class="btn btn-dark" (click)="submit()">
              {{ "SEARCH" | translate }}
            </button>
            <button class="ms-1 btn btn-default" (click)="resetFilters()">
              {{ "RESET" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
