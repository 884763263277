export interface AccountAccessList {
  CanAccessArticles: boolean;
  CanAccessBaskets: boolean;
  CanOrder: boolean;
  CanAccessCostprice: boolean;
  CanAccessOrders: boolean;
  CanAccessFinances: boolean;
  CanAccessDropshipment: boolean;
  CanAccessSupplyDemand: boolean;
  CanAccessEbsc: boolean;
  CanManageFavorites: boolean;
  CanAccessReturns: boolean;
  CanSendReturns: boolean;
  CanAccessNetCostprice: boolean;
  CanAccessEbscGuaranteeReturns: boolean;
}


export class AccountAccessList implements AccountAccessList {
  constructor() {
    this.CanAccessArticles = false;
    this.CanAccessBaskets = false;
    this.CanOrder = false;
    this.CanAccessCostprice = false;
    this.CanAccessOrders = false;
    this.CanAccessFinances = false;
    this.CanAccessDropshipment = false;
    this.CanAccessSupplyDemand = false;
    this.CanAccessEbsc = false;
    this.CanManageFavorites = false;
    this.CanAccessReturns = false;
    this.CanSendReturns = false;
    this.CanAccessNetCostprice = false;
    this.CanAccessEbscGuaranteeReturns = false;
  }
}
