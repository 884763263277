import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: 'app-lottery-registration-page',
  templateUrl: './lottery-registration-page.component.html',
  styleUrls: ['./lottery-registration-page.component.scss']
})
export class LotteryRegistrationPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  constructor(
    private translateService: TranslateService,
    private applicationService: ApplicationService
  ) {
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/home"
      ),
      new Breadcrumb(this.translateService.instant("ORDER"), "#"),
      new Breadcrumb(this.translateService.instant("PC_LOTTERY_BIKE"), "#")
    ];
  }

  ngOnInit() {}
}
