<!-- Select type -->
<app-select-financial-type [(type)]="type" #financialType (typeChange)="typeChanged($event)">
</app-select-financial-type>

<!-- Open orders -->
<app-open-orders *ngIf="financialType.type === 'openorders'"
  [navigationObserver]="navigationChangeEmitted$" [shippingAddressGroup]="shippingAddressGroup"
  [type]="financialType.type">
</app-open-orders>

<!-- Invoices -->
<app-invoices
  *ngIf="financialType.type === 'invoices'"
  [shippingAddressGroup]="shippingAddressGroup"
  [type]="financialType.type"
  [onlinePaymentRestrictionLevel]="onlinePaymentRestrictionLevel">
  <!-- Number of results -->
  <div class="numResults">
    <app-invoice-balance-overview *ngIf="financialType.type === 'invoices'"
      [numResults]="pageData.numResults['invoices']"></app-invoice-balance-overview>
  </div>
</app-invoices>

<app-balance *ngIf="financialType.type === 'balance'">

</app-balance>

<app-baskets *ngIf="financialType.type === 'sent_orders'" [type]="'sent'" [hideHeader]="true"  [basePage]="'financial'"></app-baskets>


<!-- Packingslips -->
<app-packing-slips *ngIf="financialType.type === 'packingslips'" [type]="financialType.type"
  [shippingAddressGroup]="shippingAddressGroup"></app-packing-slips>
