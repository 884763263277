<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'OPTIONS' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
</div>
<div class="modal-body d-flex gap-2 flex-column mx-auto" style="max-width: 400px">
  <button class="btn btn-dark btn-block" (click)="getOfferFile()"> {{'PRINT_OFFER' | translate }} </button>
  <button class="btn btn-dark btn-block" (click)="getPdfFile()"> {{'PRINT_ORDER' | translate }} </button>
  <button class="btn btn-dark btn-block" (click)="getExcelFile()"> {{'TOEXCEL' | translate }} </button>
  <button class="btn btn-dark btn-block" (click)="scanBarcode()" *ngIf="isMobile"> {{'SCAN' | translate }} ({{'BARCODE' | translate }}) </button>
  <button class="btn btn-dark btn-block" (click)="addToPriceLabels()"> {{'ADD_TO_PRICELABEL' | translate }} ({{"SELECTION"|translate}}) </button>
  <button class="btn btn-block btn-danger" *ngIf="!basket.locked" (click)="!basket.locked && emptyBasket()"> {{'EMPTY_BASKET' | translate }} </button>
  <button class="btn btn-block btn-danger" *ngIf="!basket.locked" (click)="!basket.locked && deleteBasket()"> {{'DELETE_BASKET' | translate }} </button>
</div>
