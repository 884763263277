import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  @Input() pdfSrc: string;
  @Input() pdfBlob: Blob;
  @Input() loading: boolean;
  @Input() filename: string;
  @Input() type: string;
  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
  }

  downloadPdf() {
    saveAs(this.pdfBlob, `${this.filename}.pdf`);
  }

}
