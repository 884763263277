<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ (currentArticle ? "EDIT" : "ADD_WARRANTY_ARTICLE") | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss()"
  ></button>
</div>

<div class="modal-body p-3">
  <div class="row mx-0">
    <div class="col-12 col-sm-7 ps-0 pe-4 mx-0">
      <div class="left-container h-100 p-4">
        <h5>{{ "MAKE_A_CHOICE" | translate }}</h5>
        <div class="btn-group btn-group-toggle py-2 d-flex gap-2">
          <button
            class="btn"
            (click)="switch('GAR')"
            [ngClass]="tabTypeSelected === 'GAR' ? 'btn-dark' : 'btn-primary'"
          >
            {{ descriptions["GAR"] | translate }}
          </button>
          <button
            class="btn"
            (click)="switch('WDL')"
            [ngClass]="tabTypeSelected === 'WDL' ? 'btn-dark' : 'btn-primary'"
          >
            {{ descriptions["WDL"] | translate }}
          </button>
          <button
            class="btn"
            *ngIf="hasLease"
            (click)="switch('LEASE')"
            [ngClass]="tabTypeSelected === 'LEASE' ? 'btn-dark' : 'btn-primary'"
          >
            {{ "LEASE" | translate }}
          </button>
          <button
            class="btn"
            (click)="switch('REC')"
            [ngClass]="tabTypeSelected === 'REC' ? 'btn-dark' : 'btn-primary'"
          >
            {{ descriptions["REC"] | translate }}
          </button>
          <!-- hide button 25-09-2023, since no longer active
          <button
            class="btn"
            (click)="switch('REC_BATT')"
            [ngClass]="
              tabTypeSelected === 'REC_BATT' ? 'btn-dark' : 'btn-primary'
            "
          >
            {{ descriptions["REC_BATT"] | translate }}
          </button>
          -->
        </div>
        <form [formGroup]="formGroup" [ngClass]="validated ? 'validated' : ''">
          <!-- GUARANTEE -->
          <app-warranty-guarantee
            #guarantee
            *ngIf="tabTypeSelected === 'GAR'"
            [warrantyId]="warrantyId"
            [currentArticle]="currentArticle"
            (bicycleSelect)="this.bicycle = $event"
            (typeChange)="setReasonId($event)"
            [hasLease]="hasLease"
            (partSelect)="selectPart($event)"
            [formGroup]="formGroup"
          >
          </app-warranty-guarantee>

          <!-- RETURNS -->
          <app-warranty-returns
            #returns
            *ngIf="tabTypeSelected === 'WDL'"
            [currentArticle]="currentArticle"
            (partSelect)="selectPart($event)"
            [warrantyId]="warrantyId"
            (typeChange)="setReasonId($event)"
            [formGroup]="formGroup"
          ></app-warranty-returns>

          <!-- CALLBACK -->
          <app-warranty-callback
            #callback
            *ngIf="tabTypeSelected === 'REC'"
            [currentArticle]="currentArticle"
            (bicycleSelect)="this.bicycle = $event"
            (partSelect)="this.part = $event"
            [formGroup]="formGroup"
            [tabSelected]="tabTypeSelected"
          ></app-warranty-callback>

          <!-- LEASING -->
          <app-warranty-leasing
            #leasing
            *ngIf="tabTypeSelected === 'LEASE'"
            [formGroup]="formGroup"
            [bicycle]="bicycle"
            [warrantyId]="warrantyId"
            [currentArticle]="currentArticle"
            (getContent)="getExplanation($event)"
            (bicycleSelect)="this.bicycle = $event"
            (partSelect)="this.part = $event"
          ></app-warranty-leasing>

          <!-- BATTERY CALLBACK -->
          <app-warranty-battery-callback
            #callback
            *ngIf="tabTypeSelected === 'REC_BATT'"
            [currentArticle]="currentArticle"
            (partSelect)="selectPart($event)"
            [formGroup]="formGroup"
            [tabSelected]="tabTypeSelected"
          ></app-warranty-battery-callback>
        </form>
      </div>
    </div>
    <div class="col-12 col-sm-5 px-5 px-sm-1 row align-content-start">
      <ng-container *ngIf="bicycle && formGroup.get('framenumber').enabled">
        <h4>{{ "BICYCLE" | translate }}</h4>
        <app-article-card
          class="w-100 mb-3"
          [article]="bicycle"
          [useLazyLoad]="false"
        ></app-article-card>
      </ng-container>
      <ng-container *ngIf="part && formGroup.get('articleCode').value">
        <h4>
          {{ "PART" | translate }} ({{
            descriptions[tabTypeSelected] | translate
          }})
        </h4>
        <app-article-card
          class="w-100"
          [article]="part"
          [useLazyLoad]="false"
        ></app-article-card>
      </ng-container>
      <ng-container>
        <app-cms-page-content [pageId]="pageId"></app-cms-page-content>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <p *ngIf="validated && !formGroup.valid" class="text-danger text-end">
    {{ "REQUIRED_FIELDS_TEXT" | translate }}
  </p>

  <span class="col-md-12 p-0 d-flex justify-content-between">
    <button
      class="btn btn-dark btn-lg float-left"
      (click)="modal.dismiss('cancel click')"
    >
      {{ "CLOSE" | translate }}
    </button>
    <div>
      <button
        class="btn btn-success btn-lg me-1"
        id="submitAnotherWizard"
        #sendButton
        [hidden]="!formGroup.get('articleValid').valid"
        [disabled]="loading"
        *ngIf="formGroup.get('framenumber').value"
        (click)="checkForm(true)"
      >
        {{ "ADD_AND_NEW" | translate }}
      </button>
      <button
        class="btn btn-success btn-lg"
        id="submitWizard"
        #sendButton
        [hidden]="!formGroup.get('articleValid').valid"
        [disabled]="loading"
        (click)="checkForm()"
      >
        {{ "SAVE" | translate }}
      </button>
    </div>
  </span>
</div>
