import { Component, OnInit } from "@angular/core";
import { HelpdeskService } from "src/app/services/helpdesk.service";
import { User } from "src/app/interfaces/user";
import { ModalsService } from "src/app/modals.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SearchDealerModalComponent } from "../search-dealer-modal/search-dealer-modal.component";
import { ActivatedRoute, Router } from "@angular/router";
import { Dealer } from "src/app/interfaces/dealer";
import { AccountService } from "src/app/services/account.service";

@Component({
  selector: "app-selected-dealer-nav-bar",
  templateUrl: "./selected-dealer-nav-bar.component.html",
  styleUrls: ["./selected-dealer-nav-bar.component.scss"]
})
export class SelectedDealerNavBarComponent implements OnInit {
  selectedDealer: User;
  selectedDealerInformation: Dealer
  constructor(
    private helpdeskService: HelpdeskService,
    private modalService: NgbModal,
    private router: Router,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.selectedDealerInformation = this.accountService.getDealerInformation();
    this.helpdeskService.changeEmitted$.subscribe((user: User) => {
      this.selectedDealer = user;
      this.selectedDealerInformation = this.accountService.getDealerInformation();
    });

    this.selectedDealer = this.helpdeskService.getSelectedDealer();
    window.addEventListener("storage", (event) => {
      if (event["key"] && event["key"] === "selectedDealer") {
        this.selectedDealer = this.helpdeskService.getSelectedDealer();
      }
    }, false);
  }

  selectDealer() {
    const modal = this.modalService.open(SearchDealerModalComponent, {
      size: "lg",
      container: "#modalContainer",
      backdrop: "static",
      windowClass: "large"
    });

    modal.componentInstance.redirectUrl =
      this.router.url.indexOf("yearAgreement") >= 0 ? this.router.url : "/";
  }
}
