import { AgreementBikeModel } from "./agreement-bike-model";
import { TurnOverSelection } from "./turn-over-selection";
import { AgreementBrand } from "./agreement-brand";

export interface AccountAgreement {
  id: number;
  account_code: string;
  year: number;
  remarks: string;
  cm_dealer: boolean;
  locked: boolean;
  signed: string;
  send: string;
  brands: AgreementBrand[];
  turnover_history: TurnOverSelection[];
  turnover: TurnOverSelection[];
  options_selection: OptionSelection[];
  focus_model_selection: AgreementBikeModel[];
  demo_model_selection: AgreementBikeModel[];
  affiliates: string;
  dealer_type: string;
  turnover_cmdealer: number;
  contract_email: string;
}

export class AccountAgreement implements AccountAgreement {
  lease_promo: boolean;
  constructor(year: number) {
    this.year = year;
  }
}


export interface OptionSelection {
  id: number;
  affiliate: string;
}
