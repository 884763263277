import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ProfileAccess } from "src/app/interfaces/profile-access";
import { AccountService } from "src/app/services/account.service";
import { Profile } from "src/app/interfaces/profile";
import { ApiResponse } from "src/app/interfaces/api-response";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-edit-profile-modal",
  templateUrl: "./edit-profile-modal.component.html",
  styleUrls: ["./edit-profile-modal.component.scss"]
})
export class EditProfileModalComponent implements OnInit {
  profileUpdated: EventEmitter<any> = new EventEmitter<any>();
  title: string;
  profile: Profile;
  availableProfileAccess: ProfileAccess[];
  selectedAccess: number[];
  description: string;
  valid: boolean;
  profileId = -1;

  constructor(
    public modal: NgbActiveModal,
    private accountService: AccountService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {
    this.selectedAccess = [];
  }

  ngOnInit() {
    if (this.profile) {
      this.description = this.profile.profile_name;

      if (this.availableProfileAccess) {
        this.availableProfileAccess.forEach((element: ProfileAccess) => {
          if (this.profile.access_rights.indexOf(Number(element.key)) >= 0) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        });
      }

      this.checkDescription();
      this.checkSelected();
    } else {
      // Reset options when new profile
      this.availableProfileAccess.forEach((element: ProfileAccess) => {
          element.checked = false;
      })
    }
  }

  confirm() {
    this.checkDescription();
    this.checkSelected();

    if (this.valid && this.selectedAccess.length) {
      const profile = new Profile();
      profile.profile_id = this.profileId;
      profile.profile_name = this.description;
      profile.access_rights = this.selectedAccess;
      this.accountService
        .updateProfile(profile)
        .subscribe((apiResponse: ApiResponse) => {
          if (apiResponse.success) {
            this.alertService.showInfoNotification(
              this.translateService.instant("SAVED")
            );
            this.profileUpdated.next(true);
          } else {
            this.alertService.showDangerNotification(
              this.translateService.instant("ERROR")
            );
          }
        });
    }
  }

  checkSelected() {
    this.selectedAccess = [];
    this.availableProfileAccess.forEach((element: ProfileAccess) => {
      if (element.checked) {
        this.selectedAccess.push(Number(element.key));
      }
    });
  }

  checkDescription() {
    this.valid = this.description ? true : false;
    return this.valid;
  }
}
