<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ "EDIT" | translate }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    </button>
</div>
<div class="modal-body" *ngIf="order">
  <div [ngClass]="order.article ? 'row mx-0' : ''">
    <div [ngClass]="order.article ? 'col-md-8' : ''">
      <form>
        <div class="mb-3 row">
          <label class="col-sm-5 col-form-label">{{
            "ORDER_NUMBER" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              readonly
              [value]="order.erp_id"
            />
          </div>
        </div>
        <div class="mb-3 row" *ngIf="order.accentry_id">
          <label class="col-sm-5 col-form-label">{{
            "ACCENTRY_REFERENCE_ID" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              readonly
              [value]="order.accentry_id"
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-5 col-form-label">{{
            "ARTICLE_NO" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              readonly
              [value]="order.article_code"
            />
          </div>
        </div>
        <div class="mb-3 row" *ngIf="!order.article">
          <label class="col-sm-5 col-form-label">{{
            "ARTICLE" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              readonly
              [value]="order.article_description"
            />
          </div>
        </div>
        <div class="mb-3 row" *ngIf="order.battery && currentBattery">
          <label class="col-sm-5 col-form-label">{{
               ((order.article_type_text !== "BIKES" ? "BICYCLE" : "BATTERY") | translate)
          }}</label>
          <div class="col-md-4">
            <ng-container *ngIf="!currentBatteryLoading">
            {{currentBattery.description}}<br/>
            {{currentBattery.caption}} ({{currentBattery.single_article_code}})</ng-container>
            <ng-container *ngIf="currentBatteryLoading">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </ng-container>
          </div>
        </div>
        <div
          class="mb-3 row"
          *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'"
        >
          <div class="col-sm-5">{{ "SOLD" | translate }}</div>
          <div class="col-sm-7">
            <div class="form-check form-switch">
              <input
                type="checkbox"
                class="form-check-input"
                id="toggle_sold"
                name="sold"
                [(ngModel)]="order.sold"
              />
              <label
                class="form-check-label justify-content-end"
                for="toggle_sold"
                >{{ "SOLD" | translate }}</label
              >
            </div>
          </div>
        </div>
        <div
          class="mb-3 row"
          *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG'"
        >
          <label class="col-sm-5 col-form-label">{{
            "REMARKS" | translate
          }}</label>
          <div class="col-sm-7">
            <input
              type="text"
              class="form-control"
              name="remark"
              [(ngModel)]="order.remark"
            />
          </div>
        </div>
        <div
          class="mb-3 row"
          *ngIf="
            applicationService.getSelectCompanyGroupCode() !== 'WG' &&
            (order.backward_shifting_status < 3 ||
              order.can_push_fordward_delivery_week) && !order.disallow_backwards_shifting
          "
        >
          <label class="col-sm-5 col-form-label">{{
            "DELIVERY_WEEK" | translate
          }}</label>
          <div class="col-sm-7">
            <select
              [(ngModel)]="order.delivery_week_or_date"
              name="delivery_week_or_date"
              class="form-control"
              (change)="weekChanged = true"
            >
              <option [value]="currentDeliveryWeek">
                {{ currentDeliveryWeek | formatDeliveryWeek }}
              </option>
              <ng-container *ngIf="order.backward_shifting_status < 3">
                <ng-container *ngFor="let week of this.shiftingBackWeeks">
                  <option *ngIf="week != currentDeliveryWeek && week != 999999" [value]="week">
                    {{ week | formatDeliveryWeek }}
                  </option>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="order.can_push_fordward_delivery_week">
                <ng-container *ngFor="let week of this.shiftingWeeks">
                  <option [value]="week" *ngIf="week != currentDeliveryWeek && week != 999999">
                    {{ week | formatDeliveryWeek }}
                  </option>
                </ng-container>
                <ng-container *ngFor="let week of this.selectableDeliveryWeek">
                  <option *ngIf="week != currentDeliveryWeek && week != 999999" [value]="week">
                    {{ week | formatDeliveryWeek }}
                  </option>
                </ng-container>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-5 col-form-label">{{
            "SHIPPINGADDRESS" | translate
          }}</label>
          <div class="col-sm-7">
            <app-select-shipping-address
              [shippingAddresses]="shippingAddressGroup"
              *ngIf="
                shippingAddressGroup &&
                  shippingAddressGroup.shipping_addresses &&
                  shippingAddressGroup.shipping_addresses.length;
                else customShipping
              "
              [selectedShippingAddress]="getSelectedShippingId()"
              (shippingAddressSelected)="setShippingAddress(order, $event)"
              [type]="'dropdown'"
            ></app-select-shipping-address>
            <ng-template #customShipping>
              {{ order.shipping_address_line_1 }}<br />
              {{ order.shipping_address_line_2 }}<br />
              {{ order.shipping_address_line_3 }}<br />
              {{ order.shipping_address_line_4 }}<br />
              {{ order.shipping_address_line_5 }}
            </ng-template>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-5 col-form-label">{{
            "QUANTITY" | translate
          }}</label>
          <div class="col-sm-4">
            <input
              type="text"
              readonly
              class="form-control"
              [value]="order.quantity"
            />
            <ng-container *ngIf="order.quantity > 1">
              <button
                type="button"
                class="mt-1 btn btn-sm btn-primary btn-block"
                *ngIf="
                  !splitted &&
                  applicationService.getSelectCompanyGroupCode() !== 'WG'
                "
                (click)="splitOrder()"
              >
                {{ "SPLIT_ORDER_LINE" | translate }}
              </button>
            </ng-container>
          </div>
        </div>

        <ng-container
          *ngIf="
            splitted && applicationService.getSelectCompanyGroupCode() !== 'WG'
          "
        >
          <h5 class="my-4">{{ "SPLITTED_ORDER" | translate }}</h5>
          <div class="mb-3 row">
            <div class="col-sm-5">{{ "SOLD" | translate }}</div>
            <div class="col-sm-7">
              <div class="form-check form-switch">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="toggle_splitted_sold"
                  name="sold"
                  [(ngModel)]="splittedOrder.sold"
                />
                <label
                  class="form-check-label justify-content-end text-light"
                  for="toggle_splitted_sold"
                  >{{ "SOLD" | translate }}</label
                >
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-5 col-form-label">{{
              "REMARKS" | translate
            }}</label>
            <div class="col-sm-7">
              <input
                type="text"
                class="form-control"
                name="remark"
                [(ngModel)]="splittedOrder.remark"
              />
            </div>
          </div>

          <div
            class="mb-3 row"
            *ngIf="
              shippingAddressGroup &&
              shippingAddressGroup.shipping_addresses &&
              shippingAddressGroup.shipping_addresses.length
            "
          >
            <label class="col-sm-5 col-form-label">{{
              "SHIPPINGADDRESS" | translate
            }}</label>
            <div class="col-sm-7">
              <app-select-shipping-address
                [shippingAddresses]="splittedAddressGroups"
                [selectedShippingAddress]="getSelectedShippingId()"
                (shippingAddressSelected)="
                  setShippingAddress(splittedOrder, $event)
                "
                [type]="'dropdown'"
              ></app-select-shipping-address>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-5 col-form-label">{{
              "QUANTITY" | translate
            }}</label>
            <div class="col-sm-4">
              <input
                type="number"
                class="form-control"
                name="quantity"
                [value]="splittedOrder.quantity"
                (keyup)="splitQuantityChange($event)"
                (click)="splitQuantityChange($event)"
                [readonly]="originalQuantity === 2"
                (change)="splitQuantityChange($event)"
              />
              <button
                type="button"
                class="mt-1 btn btn-sm btn-primary"
                (click)="splitted = false; order.quantity = originalQuantity"
              >
                {{ "SPLIT_ORDER_LINE" | translate }}
                {{ "CANCEL_ACTION" | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <div class="mb-3 row" *ngIf="order.article && order.article.has_mandatory_item">
          <label class="col-sm-5 col-form-label">{{
            "SELECT_FLEX_BATTERY" | translate
          }}</label>
          <div class="col-md-4">
            <button
              class="mt-2 btn btn-primary btn-dark"
              (click)="changeBattery()"
            >
              {{ "SELECT" | translate }}
            </button>
          </div>
        </div>
        <div class="mb-3 row" *ngIf="newBattery && newBattery.id">
          <div class="col-12">
            <ngb-alert [type]="'success'" [dismissible]="false">
              {{ "SELECTED_BATTERY" | translate }}: {{ newBattery.description }} {{newBattery.caption}} ({{newBattery.single_article_code}})
            </ngb-alert>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-4" *ngIf="order.article">
      <app-article-card
        [article]="order.article"
        class="d-inline-block w-100"
        [useLazyLoad]="false"
      ></app-article-card>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="me-auto btn btn-outline-dark btn-lg"
    (click)="modal.dismiss('Close click')"
  >
    {{ "CANCEL_ACTION" | translate }}
  </button>
  <button
    type="button"
    class="ms-auto btn btn-success btn-lg"
    (click)="saveOpenOrder()"
    [disabled]="loading"
  >
    {{ "SAVE" | translate }}
    <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  </button>
</div>
