import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'src/app/services/account.service';
import { AlertService } from 'src/app/services/alert.service';
import { ApplicationService } from 'src/app/services/application.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-image-bank-conditions-modal',
  templateUrl: './image-bank-conditions-modal.component.html',
  styleUrls: ['./image-bank-conditions-modal.component.scss']
})
export class ImageBankConditionsModalComponent implements OnInit {

  pageId: string;
  constructor(
    private modal: NgbActiveModal,
    private applicationService: ApplicationService,
  ) {}

  ngOnInit(): void {
    if (this.applicationService.getSelectCompanyGroupCode() === "ANL") {
      this.pageId = "beeldenbank-notities";
    }
  }

  closeModal() {

    this.modal.close();
  }
}
