import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Article } from "src/app/interfaces/article";
import { ArticleService } from "src/app/services/article.service";
import { saveAs } from "file-saver";
import { ApplicationService } from "src/app/services/application.service";
import { LeafletSendRequest } from "src/app/interfaces/leaflet-send-request";
import { ApiResponse } from "src/app/interfaces/api-response";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
import { ValidateEmail } from "src/app/validators/customvalidators";
import { Router } from "@angular/router";

@Component({
  selector: "app-view-leaflet",
  templateUrl: "./view-leaflet.component.html",
  styleUrls: ["./view-leaflet.component.scss"]
})
export class ViewLeafletComponent implements OnInit {
  @Input() article: Article;
  leafletForm: FormGroup;
  pdfSrc: string;
  pdfBlob: any;
  loaded: boolean;
  leafletSent: boolean;

  // Form model
  sendLeafletData: LeafletSendRequest;

  constructor(
    public modal: NgbActiveModal,
    private articleService: ArticleService,
    public formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private alertService: AlertService,
    private translateService: TranslateService,
    public router: Router
  ) {}

  ngOnInit() {
    this.sendLeafletData = new LeafletSendRequest();

    this.leafletForm = this.formBuilder.group({
      email_address: new FormControl(this.sendLeafletData.email_address, [
        Validators.required,
        ValidateEmail
      ]),
      name: new FormControl(this.sendLeafletData.name),
      remarks: new FormControl(this.sendLeafletData.remarks)
    });

    this.getLeaflet();
  }

  // Get pdf from API
  getLeaflet() {
    this.applicationService.showLoader(true);
    this.articleService
      .getLeaflet(this.article.id, this.article.single_article_code)
      .subscribe((blob: Blob) => {
        this.applicationService.hideLoader();
        this.generateUrl(blob);
      });
  }

  // Generate pdf
  generateUrl(blob) {
    const file = new Blob([blob], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    this.pdfSrc = fileURL;
    this.pdfBlob = file;
    this.loaded = true;
  }

  // Download file
  download() {
    saveAs(this.pdfBlob, `leaflet-${this.article.id}.pdf`);
  }

  // Send the pdf to the specified emailaddress
  sendPdf() {
    this.leafletSent = false;
    this.sendLeafletData.email_address = this.leafletForm.get(
      "email_address"
    ).value;
    this.sendLeafletData.name = this.leafletForm.get("name").value;
    this.sendLeafletData.remarks = this.leafletForm.get("remarks").value;
    this.sendLeafletData.article_code = this.article.single_article_code;
    this.sendLeafletData.model_id = String(this.article.id);
    this.applicationService.showLoader(true);
    this.articleService
      .sendLeaflet(this.sendLeafletData)
      .subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();

        if (apiResponse.success) {
          this.alertService.showInfoNotification(
            this.translateService.instant("LEAFLET_SENT")
          );
          this.leafletSent = true;
          this.sendLeafletData = new LeafletSendRequest();
          this.leafletForm.reset();
        }
      });
  }
}
