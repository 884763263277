import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-year-agreement",
  templateUrl: "./year-agreement.component.html",
  styleUrls: ["./year-agreement.component.scss"]
})
export class YearAgreementComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  type = "bikes";
  currentYear = "";
  constructor(
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private router: ActivatedRoute
  ) {}

  ngOnInit() {
    this.type = this.router.snapshot.data["type"];
    this.breadcrumbs = [
      new Breadcrumb(
        this.translateService.instant("HOME"),
        this.applicationService.getSelectCompanyGroupCode() + "/home"
      ),
      new Breadcrumb(this.translateService.instant("FINANCIAL"), "#"),
      new Breadcrumb(this.translateService.instant("YEAR_AGREEMENT"), "#")
    ];
  }
}
