import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "numberFormat"
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: number, currency: string): any {
    if (value || value === 0) {
      let convertedValue = this.formatDot(String(Number(value).toFixed(2)));
      return `${currency} ${convertedValue.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        "."
      )}`;
    }
  }

  formatDot(value: string) {
    if (value) {
      return String(value).includes('GBP') ? value : String(value).replace(".", ",");
    } else {
      return "";
    }
  }

  removeDecimals(value: number, currency: string = "") {
    const formatted = value ? Number(value ).toFixed(0) : 0;
    return `${formatted}`
  }

  toTwoDecimals(value: number, currency: string = "") {
    const formatted = value ? Number(value ).toFixed(2) : 0;
    return `${formatted}`
  }

  toMillion(value: number, currency: string = "") {
    const formatted = value ? Number(value / 1000000).toFixed(2) : 0;
    return `${this.transform(Number(formatted),"")}M`
  }
}
