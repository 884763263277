import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, timer } from "rxjs";
import { MenuLayout } from "src/app/interfaces/menu-layout";
import { MenuItem } from "src/app/interfaces/menuItem";
import { ApplicationService } from "src/app/services/application.service";
import * as _ from "underscore";
import { filter } from "underscore";

@Component({
  selector: "app-search-menu",
  templateUrl: "./search-menu.component.html",
  styleUrls: ["./search-menu.component.scss"],
})
export class SearchMenuComponent implements OnInit {
  itemClicked = new Subject<MenuItem>();
  groupClicked = new Subject<Object>();
  submitObserverEmitted$ = this.itemClicked.asObservable();
  searchText: string = "";
  menuItems: MenuLayout = null;
  results: MenuItem[] = [];
  itemIndex = 0;
  timer: any;
  presses = 0;
  blocked = false;
  @ViewChild("mainInput") input: ElementRef;
  navigationKeys = ["ArrowUp", "ArrowDown", "Enter"];
  groups: MenuItem[] = [];
  hasScrollBar = true;

  constructor(
    public modal: NgbActiveModal,
    public applicationSerice: ApplicationService,
    private renderer: Renderer2,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getMenuItems();
    this.searchText = sessionStorage.getItem("menuSearchText") ?? "";
    this.applicationSerice.searchMenuActive.next(true);
    this.getResults();
  }

  ngOnDestroy(): void {
    this.applicationSerice.searchMenuActive.next(false);
  }

  ngAfterViewInit() {
    timer(100).subscribe(() => {
      this.renderer.selectRootElement(this.input["nativeElement"]).focus();
    });
  }

  getMenuItems() {
    const menuItems = sessionStorage.getItem(
      `${this.applicationSerice.getSelectCompanyGroupCode()}menuItems`
    );
    this.menuItems = JSON.parse(menuItems) as MenuLayout;
  }

  navigate(event) {
    const self = this;
    function checkNavigation() {
      if (self.itemIndex < 0) {
        self.itemIndex = self.results.concat(self.groups).length - 1;
      }
      if (self.itemIndex > self.results.concat(self.groups).length - 1) {
        self.itemIndex = 0;
      }
    }

    function scrollToActiveItem() {
      const container = document.getElementById("search-scroller");
      if (container) {
        const listItem = container.getElementsByClassName("list-group-item");
        if (listItem && listItem.length) {
          if (self.itemIndex > 5) {
            const height = listItem[0].clientHeight + 4;
            container.scrollTop = self.itemIndex * height;
          } else {
            container.scrollTop = 0;
          }
        }
      }
    }

    if (this.navigationKeys.includes(event.code)) {
      let delta = 0;
      if (event.code === "ArrowDown") {
        delta = 1;
      } else if (event.code === "ArrowUp") {
        delta = -1;
      } else {
        this.gotoItem(this.results.concat(this.groups)[this.itemIndex]);
      }

      if (!this.blocked) {
        this.itemIndex += delta;
        checkNavigation();
        scrollToActiveItem();
        this.blocked = true;
        timer(75).subscribe(() => {
          this.blocked = false;
        });
      }
      event.preventDefault();
      return false;
    }
  }

  keyupSearch(event) {
    if (!this.navigationKeys.includes(event.code)) {
      if (event.code === "Backspace" && !this.searchText) {
        return
      } else {
        this.getMenuItems();
        this.getResults();
      }
    }
    if (event.code === "Enter" && !this.results.length && !this.groups.length) {
      this.router.navigateByUrl(
        `/${this.applicationSerice.getSelectCompanyGroupCode()}/search/${
          this.searchText
        }`
      );
      this.modal.close();
    }
  }

  getResults() {
    this.itemIndex = 0;
    let results = [];
    if (this.menuItems) {
      results = this.searchInItems([...this.menuItems.menu_items], "page");
      results = results.concat(
        this.searchInItems([...this.menuItems.settings], "page")
      );
      this.results = _.sortBy(results, "description") || [];
      this.groups = this.searchInItems(
        [...this.menuItems.browse_groups],
        "group"
      );
    }

    timer(0).subscribe(() => {
      this.checkHasScrollBar();
    })
  }

  private searchInItems(items, section) {
    let results = [];

    // Recursive filtering (menuItems)
    function filterItems(items: MenuItem[], self) {
      items.forEach((s: MenuItem) => {
        if (typeof s.sub_items !== "undefined" ) {
          if (
            typeof s.description !== "undefined" &&
            s.description?.toLowerCase().includes(self.searchText) &&
            s.name !== "usersettings"
          ) {
            if (s.url !== "#") {
              s.class = section;
              results.push(s);
            }
          }
          if (typeof s.sub_items !== "undefined") {
            filterItems(s.sub_items, self);
          }
        } else if (s.description?.toLowerCase().includes(self.searchText)) {
          s.class = section;
          results.push(s);
        }
      });
    }
    // Recursive filtering (menuItems)
    function filterGroups(items: MenuItem[], self, code: string) {
      items.forEach((s: MenuItem) => {
        if (typeof code !== "undefined") {
          s.name += `_${code}`;
        }
        if (typeof s.sub_items !== "undefined") {
          if (s.description.toLowerCase().includes(self.searchText)) {
            if (s.url !== "#") {
              s.class = section;

              results.push(s);
            }
          }
          if (typeof s.sub_items !== "undefined") {
            filterGroups(s.sub_items, self, s.name);
          }
        } else if (s && s.description && s.description.toLowerCase().includes(self.searchText)) {
          s.class = section;
          results.push(s);
        }
      });
    }

    this.blocked = false;
    sessionStorage.setItem("menuSearchText", this.searchText);
    if (section === "page") {
      filterItems(items, this);
    } else {
      filterGroups(items, this, undefined);
    }

    return results;
  }

  private checkHasScrollBar() {
    const div = document.getElementById("search-scroller");
    if (div && div !== null) {
      this.hasScrollBar = div.scrollHeight > div.clientHeight;
    }
  }

  gotoItem(item: MenuItem) {
    if (item?.class === "page") {
      this.itemClicked.next(item);
    } else {
      this.gotoGroup(item);
    }
  }

  gotoGroup(item: MenuItem) {
    if (typeof item?.name !== "undefined") {
      const data = item.name.split("_");
      this.groupClicked.next(data.reverse());
    }
  }
}
