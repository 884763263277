import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiResponse } from 'src/app/interfaces/api-response';
import { Article } from 'src/app/interfaces/article';
import { ApplicationService } from 'src/app/services/application.service';
import { ArticleService } from 'src/app/services/article.service';
import { BasketService } from 'src/app/services/basket.service';
import { HelperService } from 'src/app/services/helper.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { ArticleModalComponent } from '../../modal-components/article-modal/article-modal.component';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'app-article-container',
  templateUrl: './article-container.component.html',
  styleUrls: ['./article-container.component.scss'],
})
export class ArticleContainerComponent implements OnInit {
  @Input() article_codes: string;
  @Input() article_horizontal_alignment: string;
  @Input() _uid: any;
  @Input() component: any;
  @Input() _editable: any;
  articles: Article[] = [];
  companyGroupCode: any;

  get justify() {
    switch (this.article_horizontal_alignment) {
      case 'start':
        return 'justify-content-start';
      case 'center':
        return 'justify-content-center';
      case 'end':
        return 'justify-content-end';
      default:
        return ""
    }
  }

  constructor(
    private articleService: ArticleService,
    private helperService: HelperService,
    private searchService: SearchService,
    private basketService: BasketService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.articleService
      .getArticleByCodes(this.article_codes.replace(/ /g, '').split(','))
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.articles = Object.values(apiResponse.result);
          this.searchService.getNettPrices(this.articles);
        }
      });
  }
}
