<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ "ACTIVATION.RESEND_ACTIVATION" | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  >
    </button>
</div>

<div class="modal-body p-3">
  <div class="mb-3 mt-3">
    <label for="username">{{ "EMAIL" | translate }}</label>
    <input
      type="text"
      id="email"
      class="form-control form-control-lg"
      name="email"
      [(ngModel)]="email"
    />
    <span class="text-danger" [ngClass]="{'invisible': !emailInvalid}">{{"JS_SECRET_EMAIL"|translate}}</span>
  </div>
</div>
<div class="modal-footer">
  <button
    class="btn btn-success me-auto"
    (click)="submit()"
    [disabled]="loading || emailInvalid"
  >
    {{ "SEND" | translate }}
  </button>
  <button class="btn btn-dark ms-auto" (click)="modal.dismiss()">
    {{ "CANCEL_ACTION" | translate }}
  </button>
</div>
