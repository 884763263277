<h3 class="mb-5">
    <app-header-icon iconName="admin_panel_settings"></app-header-icon> Admin tools</h3>
<!--<h5>Menu</h5>
 <ul>
  <li class="clickable withOpacity" [routerLink]="cgcRoot + '/admin/menu-editor'">
    Edit menu layout
  </li>
</ul> -->
<h5>Article groups</h5>
<ul class="d-flex gap-2">
  <li class="clickable withOpacity" [routerLink]="cgcRoot + '/admin/classifications'" >
    Edit article groups layout
  </li>
  <li class="clickable withOpacity" [routerLink]="cgcRoot + '/admin/specifications'" >
    Edit specifications
  </li>
</ul>
<!-- <h5>Articles</h5>
<ul>
  <li class="clickable withOpacity" [routerLink]="cgcRoot + '/admin/preorder-settings'">
    Article specification whitelist
  </li>
  <li class="clickable withOpacity" [routerLink]="cgcRoot + '/admin/preorder-settings'">
    Article groups specifications
  </li>
</ul> -->
<h5>{{"SETTINGS"|translate}}</h5>
<ul class="d-flex gap-2">
  <li class="clickable withOpacity" [routerLink]="cgcRoot + '/admin/preorder-settings'"
    *ngIf="['ANL','GHO','WG','COM','LAP'].includes(applicationService.getSelectCompanyGroupCode())">
    Preorder
  </li>
  <li class="clickable withOpacity" [routerLink]="cgcRoot + '/admin/xl-settings'"
    *ngIf="['ANL'].includes(applicationService.getSelectCompanyGroupCode())">Accentry XL</li>
  <li class="clickable withOpacity" [routerLink]="cgcRoot + '/admin/marketplace-settings'"
    *ngIf="['ANL','LAP'].includes(applicationService.getSelectCompanyGroupCode())">{{"SUPPLY_DEMAND"|translate}}
   </li>
    <!-- <li class="clickable withOpacity" [routerLink]="cgcRoot + '/admin/general-conditions'">{{"GENERAL_TERMS_AND_CONDITIONS"|translate}}</li> -->
</ul>
