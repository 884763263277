import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-warranty-article-description-input',
  templateUrl: './warranty-article-description-input.component.html',
  styleUrls: ['./warranty-article-description-input.component.scss']
})
export class WarrantyArticleDescriptionInputComponent implements OnInit {
  @Input() formGroup: FormGroup;
  constructor() { }

  ngOnInit() {
  }

}
