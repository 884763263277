<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'PACKINGSLIP' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    </button>
</div>
<div class="modal-body p-0">
  <div class="row">
    <div class="col-md-7">
      <div class="scrollable customScrollbar pdfContainer">
        <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" class="border d-block w-100">
        </pdf-viewer>
      </div>
    </div>
    <div class="col-md-5 pt-3" *ngIf="packingSlip">
      {{packingSlip.packing_slip_number}}<br />
      {{packingSlip.shipment}}<br />
      {{'PACKINGSLIP_NUMBER' | translate}}: {{packingSlip.packing_slip_number}}<br />
      {{'INVOICE_NUMBER' | translate}}: {{packingSlip.invoice_number}}<br />
      {{packingSlip.order_status_text}}<br />
      {{packingSlip.shipment_date}}<br />
      {{packingSlip | shippingAddressText}}<br />
      <button type="button" class="btn btn-dark btn-outline mt-2" (click)="download()" *ngIf="loaded">{{'DOWNLOAD' | translate}}</button>
    </div>
  </div>
  <div class="modal-footer">
    <span class="col-md-12 p-0">

    </span>
  </div>
