<div class="row">
  <div class="col-md-12">
    <h1><app-header-icon iconName="assignment_return"></app-header-icon>{{ "RETURNS" | translate }}
      {{ language === 'DE' ? ('OVERVIEW' | translate) : ('OVERVIEW' | translate | lowercase) }}</h1>
    <ngx-skeleton-loader count="20" appearance="line" [theme]="{ height: '80px' }"
      *ngIf="!warrantyRequests || !warrantyRequests.length">
    </ngx-skeleton-loader>
    <div class="table-responsive">
      <table class="table text-nowrap">
        <thead>
          <tr>
            <th scope="col" class="text-end">#</th>
            <th scope="col">{{ "REMARKS" | translate }}</th>
            <th scope="col">{{ "DATE_SUBMITTED" | translate }}</th>
            <th scope="col">{{ "STATUS" | translate }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let warrantyRequest of warrantyRequests">
            <app-warranty-request class="d-table-row" [warrantyRequest]="warrantyRequest"></app-warranty-request>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
