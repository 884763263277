<div class="col-12">
  <swiper-container class="full mb-2" class="image-carousel" init="false" *ngIf="articleImages" [navigation]="true" [slidesPerView]="1" #swiper [centeredSlides]="true" [centeredSlidesBounds]="true" thumbs-swiper=".mySwiper">
    <ng-container *ngFor="let img of articleImages; index as i">
      <swiper-slide>
        <div class="image-wrapper mx-0 ps-0" style="overflow: hidden">
          <img [src]="img.medium" class="img" loading="lazy" (click)="openLightBox()"/>
        </div>
      </swiper-slide>
    </ng-container>
  </swiper-container>
  <swiper-container
    (swiper)="thumbsSwiper = $event"
    [spaceBetween]="10"
    [slidesPerView]="4"
    [freeMode]="true"
    [watchSlidesProgress]="true"
    class="mySwiper swiper-thumbs mt-1"
    *ngIf="articleImages.length > 1"
  >
    <ng-container *ngFor="let img of articleImages; index as i">
      <swiper-slide
        ><img [src]="img.small" class="img clickable withOpacity" loading="lazy" (click)="gotoImage(img.small)"
      /></swiper-slide>
    </ng-container>
  </swiper-container>
</div>
