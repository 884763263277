<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container" *ngIf="dealer">

      <h1><app-header-icon iconName="contact_page"></app-header-icon>{{ 'CONTACTDATA' | translate }}</h1>

      <div class="contact-details-card">
        <h2 *ngIf="dealer.dealer_name && dealer.dealer_name.length" [innerHTML]="dealer.dealer_name"></h2>
        <span class="code" *ngIf="dealer.dealer_code && dealer.dealer_code.length" [innerHTML]="dealer.dealer_code"></span>
        <span class="street" *ngIf="dealer.address.street && dealer.address.street.length">
          <span *ngIf="dealer.address.street && dealer.address.street.length" [innerHTML]="dealer.address.street"></span>
          <span *ngIf="dealer.address.housenumber && dealer.address.housenumber.length" [innerHTML]="' ' + dealer.address.housenumber"></span>
          <span *ngIf="dealer.address.housenumber_addition && dealer.address.housenumber_addition.length" [innerHTML]="' ' + dealer.address.housenumber_addition"></span>
        </span>
        <span class="place" *ngIf="(dealer.address.zip && dealer.address.zip.length) || (dealer.address.city && dealer.address.city.length)">
          <span *ngIf="dealer.address.zip && dealer.address.zip.length" [innerHTML]="dealer.address.zip"></span>
          <span *ngIf="dealer.address.city && dealer.address.city.length" [innerHTML]="' ' + dealer.address.city"></span>
        </span>
        <span class="country" *ngIf="dealer.address.country && dealer.address.country.length" [innerHTML]="dealer.address.country"></span>
        <a href="mailto:{{dealer.email}}" class="email" *ngIf="dealer.email && dealer.email.length" [innerHTML]="dealer.email"></a>
        <a href="tel:{{dealer.phone}}" class="phone" *ngIf="dealer.phone && dealer.phone.length" [innerHTML]="dealer.phone"></a>
        <a [href]="dealer.url" class="url" *ngIf="dealer.url && dealer.url.length" [innerHTML]="dealer.url"></a>
      </div>

</div>
