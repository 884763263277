import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationService } from "src/app/services/application.service";
import { SearchService } from "src/app/services/search.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-article-options",
  templateUrl: "./article-options.component.html",
  styleUrls: ["./article-options.component.scss"]
})
export class ArticleOptionsComponent implements OnInit {
  @Output() navigationChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedIndex: number;
  @Input() page: number;
  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private searchService: SearchService,
    private location: Location
  ) {}

  ngOnInit() {}

  prevArticle() {
    this.navigationChange.emit("prev");
  }

  nextArticle() {
    this.navigationChange.emit("next");
  }

  goBack() {
    this.searchService.showSearch(true);
  }
}
