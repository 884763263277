<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{'EDIT'|translate}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">
    </button>
</div>
<div class="modal-body p-3" *ngIf="basketArticles && basketArticles.length">
  <app-preorder-container [edit]="true" [type]="type" [basketArticles]="basketArticles"></app-preorder-container>
</div>
<div class="modal-footer">
  <span class="col-md-12 p-0">
    <ng-container>
      <button class="btn btn-dark " (click)="modal.dismiss('cancel click')">{{'CLOSE' | translate}}</button>
    </ng-container>
  </span>
</div>
