import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationService } from 'src/app/services/application.service';

@Component({
  selector: "app-group-select-homepage",
  templateUrl: "./group-select-homepage.component.html",
  styleUrls: ["./group-select-homepage.component.scss"],
  animations: [
    trigger("enterAnimation", [
      transition(":enter", [
        style({
          opacity: 0,
          overflow: "hidden",
        }),
        animate("425ms ease-out", style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class GroupSelectHomepageComponent implements OnInit {

  constructor(private applicationService: ApplicationService) {}

  ngOnInit(): void {
  }
}
