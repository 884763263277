import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { CmsService } from "src/app/services/cms.service";
import { ApiResponse } from "src/app/interfaces/api-response";

@Component({
  selector: "app-screen-saver",
  templateUrl: "./screen-saver.component.html",
  styleUrls: ["./screen-saver.component.scss"]
})
export class ScreenSaverComponent implements OnInit {
  @Output() active: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private cmsService: CmsService) {}

  ngOnInit() {}
}
