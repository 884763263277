export interface Campaign {
  id: number;
  name: string;
  position: number;
  creative: string;
}

export class Campaign implements Campaign {
  constructor(id: number, name: string, position: number, creative: string) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.creative = creative;
  }
}
