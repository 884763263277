export interface OpenOrdersSelectedFilters {
  articleType: string;
  sold: string;
  keyWord: string;
  keyWordOption: string;
  orderDate: string;
  companyFilter: string;
  openOrderStatus: string;
}

export class OpenOrdersSelectedFilters implements OpenOrdersSelectedFilters {
  constructor(articleType, sold, keyWord, keyWordoption) {
    this.articleType = articleType;
    this.sold = sold;
    this.keyWord = keyWord;
    this.keyWordOption = keyWordoption;
    this.companyFilter = "";
    this.openOrderStatus = "all";
  }
}
