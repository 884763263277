import { OrderLineOptionInput } from "./order-line-option-input";

export interface OpenOrdersFilters {
  options: OrderLineOptionInput;
  next_page_reference: string;
  page: number;
}

export class OpenOrdersFilters implements OpenOrdersFilters {
  constructor() {
    this.options = new OrderLineOptionInput();
    this.next_page_reference = "";
  }
}
