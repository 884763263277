<nav aria-label="breadcrumb">
  <div class="container">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs"
        [ngClass]="breadcrumb.url === '#' ? 'active' : ''">
        <a [href]="breadcrumb.url" (click)="gotoUrl($event, breadcrumb.url)" *ngIf="breadcrumb.url !== '#'"
          [innerHTML]="breadcrumb.title|translate"></a>
        <span *ngIf="breadcrumb.url === '#'" [innerHTML]="breadcrumb.title|translate"></span>
      </li>
    </ol>
  </div>
</nav>
