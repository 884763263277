import { Component, OnInit, Input } from "@angular/core";
import { ApplicationService } from "src/app/services/application.service";
import { Price } from "src/app/interfaces/price";

@Component({
  selector: "app-article-sum",
  templateUrl: "./article-sum.component.html",
  styleUrls: ["./article-sum.component.scss"]
})
export class ArticleSumComponent implements OnInit {
  @Input() netPrice: Price;
  @Input() retailPrice: Price;
  @Input() amount = 0;
  showroomMode = false;
  constructor(private applicationService: ApplicationService) {
    this.getShowroomMode();
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(
      this.showroomMode
    );
  }

  ngOnInit() {
    this.applicationService.changeEmitted$.subscribe(response => {
      this.getShowroomMode();
    });
  }
}
