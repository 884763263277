<ng-container *ngIf="!customLogo">
<!--?xml version="1.0" encoding="utf-8"?-->
<!-- Generator: Adobe Illustrator 15.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->

<svg version="1.1" id="svg2" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 621.775 128.975" enable-background="new 0 0 621.775 128.975" xml:space="preserve" [ngClass]="active ? 'active' : ''">
<rect x="90.387" y="20.488" fill="transparent" width="200" height="86.5" class="svg-elem-1"></rect>
<g id="g10" transform="matrix(1.25,0,0,-1.25,0,128.975)">
	<g id="g12" transform="scale(0.1,0.1)">
		<g id="g14">
			<g id="g16">
				<path id="path22" fill="#FFF" d="M2287.201,448.681c-33.43-142.594-159.055-242.195-305.469-242.195
					c-173.063,0-313.844,140.797-313.844,313.844c0,173.039,140.781,313.82,313.844,313.82c143.5,0,268.547-97.117,304.078-236.172
					l4.977-19.484h-136.68l-3.945,9.758c-27.992,69.141-94.109,113.82-168.43,113.82c-100.234,0-181.766-81.531-181.766-181.742
					s81.531-181.742,181.766-181.742c75.586,0,144.047,47.852,170.336,119.07l3.773,10.203h135.859L2287.201,448.681
					L2287.201,448.681z M1370.959,448.681c-33.438-142.594-159.047-242.195-305.477-242.195
					c-173.055,0-313.844,140.797-313.844,313.844c0,173.039,140.789,313.82,313.844,313.82
					c143.508,0,268.547-97.117,304.078-236.172l4.992-19.484h-136.68l-3.961,9.758c-27.992,69.141-94.102,113.82-168.43,113.82
					c-100.227,0-181.758-81.531-181.758-181.742s81.531-181.742,181.758-181.742c75.594,0,144.055,47.852,170.344,119.07
					l3.766,10.203h135.867L1370.959,448.681z M1964.928,1023.751c-130.945,0-250.188-50.016-339.75-131.961
					c-88.477-52.188-168.945-24.813-219.117,5.352c-88.828,78.727-205.633,126.609-333.664,126.609
					c-278.047,0-503.453-225.391-503.453-503.422c0-278.047,225.406-503.437,503.453-503.437
					c134.422,0,256.43,52.789,346.727,138.633c82.117,44.766,156.789,22.109,205.836-5.719
					c89.617-82.281,208.711-132.914,339.969-132.914c278.031,0,503.414,225.391,503.414,503.437
					C2468.342,798.361,2242.959,1023.751,1964.928,1023.751" class="svg-elem-2"></path>
				<path id="path24" fill="#FFF" d="M225.225,353.189c27.195,0,51.398,6.711,72.594,20.141
					c21.203,13.414,35.508,28.961,42.93,46.633v81.078h-86.375c-32.859,0-57.766-8.211-74.727-24.641
					c-16.953-16.43-25.43-36.305-25.43-59.617c0-19.078,6.352-34.445,19.07-46.102C186.006,359.017,203.318,353.189,225.225,353.189
					z M366.717,249.853c-5.656,13.07-10.516,26.844-14.57,41.336c-4.07,14.477-6.984,29.32-8.75,44.516
					c-15.898-27.914-36.828-51.055-62.797-69.43c-25.969-18.359-56.969-27.547-93-27.547c-60.062,0-106.344,15.445-138.844,46.367
					C16.248,316.009-0.002,358.134-0.002,411.486c0,56.172,21.641,99.703,64.922,130.625
					c43.266,30.914,106.422,46.367,189.453,46.367h86.375v45.578c0,27.555-7.25,48.93-21.727,64.125
					c-14.492,15.18-35.859,22.789-64.125,22.789c-25.086,0-44.43-6.094-58.023-18.289c-13.609-12.188-20.406-29.063-20.406-50.609
					l-149.437,0.531l-0.531,3.18c-2.477,48.398,18.547,90.172,63.062,125.328c44.508,35.148,102.977,52.734,175.406,52.734
					c68.531,0,124.18-17.406,166.93-52.203c42.742-34.805,64.125-84.352,64.125-148.641V396.111c0-26.852,2.023-52.109,6.094-75.773
					c4.055-23.68,10.508-47.172,19.336-70.484H366.717" class="svg-elem-3"></path>
				<path id="path26" fill="#FFF" d="M2800.263,714.603c-28.609,0-51.234-10.953-67.828-32.852
					c-16.602-21.914-26.852-50.703-30.734-86.383l1.594-2.648h189.719v13.781c0,32.852-7.695,59.086-23.063,78.695
					C2854.584,704.798,2831.365,714.603,2800.263,714.603z M2814.052,238.728c-82.672,0-148.75,26.492-198.203,79.484
					c-49.469,52.992-74.188,120.297-74.188,201.906v21.195c0,85.141,23.398,155.273,70.219,210.383
					c46.805,55.117,109.594,82.492,188.383,82.148c77.375,0,137.438-23.32,180.18-69.953
					c42.75-46.633,64.117-109.695,64.117-189.188v-84.258h-342.32l-1.07-3.164c2.82-37.656,15.453-68.633,37.883-92.906
					c22.438-24.281,52.906-36.414,91.43-36.414c34.258,0,62.688,3.445,85.313,10.336c22.609,6.883,47.336,17.75,74.195,32.586
					l41.859-95.617c-23.32-19.172-53.875-35.055-91.68-47.648C2902.365,245.025,2860.318,238.728,2814.052,238.728" class="svg-elem-4"></path>
				<path id="path28" fill="#FFF" d="M3266.381,823.243l6.891-82.141c19.078,29.32,42.57,52.102,70.492,68.359
					c27.891,16.242,59.156,24.383,93.789,24.383c57.938,0,103.156-18.203,135.656-54.586c32.5-36.391,48.75-93.453,48.75-171.172
					V249.853h-155.258v358.523c0,38.594-7.781,65.945-23.32,82.055c-15.547,16.117-39.031,24.172-70.477,24.172
					c-20.492,0-38.875-4.164-55.125-12.484s-29.859-20.102-40.797-35.32V249.853H3122.24v573.391H3266.381" class="svg-elem-5"></path>
				<path id="path30" fill="#FFF" d="M3911.615,963.673v-140.43h96.445V714.603h-96.445V422.611
					c0-22.258,4.586-38.156,13.766-47.695c9.188-9.531,21.555-14.305,37.102-14.305c8.133,0,15.109,0.438,20.93,1.32
					c5.844,0.883,12.633,2.391,20.406,4.508l13.242-111.812c-15.547-5.305-30.727-9.281-45.57-11.93
					c-14.836-2.648-30.906-3.969-48.219-3.969c-53.359,0-94.422,14.648-123.211,43.984c-28.797,29.32-43.188,75.781-43.188,139.367
					v292.523h-83.742v108.641h83.742v140.43H3911.615" class="svg-elem-6"></path>
				<path id="path32" fill="#FFF" d="M4398.388,690.228l-56.695,1.586c-22.969,0-41.875-4.766-56.703-14.305
					c-14.836-9.539-26.148-22.969-33.93-40.273V249.853h-154.195v573.391h143.609l6.875-84.789
					c13.43,30.023,30.82,53.43,52.211,70.211c21.367,16.781,46.023,25.18,73.93,25.18c7.758,0,14.922-0.625,21.461-1.859
					c6.523-1.242,13.148-2.742,19.859-4.508L4398.388,690.228" class="svg-elem-7"></path>
				<path id="path34" fill="#FFF" d="M4688.56,490.447l6.359-31.797h3.195l109.688,364.594h166.398L4741.56,164.001
					c-16.258-40.977-38.336-75.516-66.242-103.602c-27.914-28.086-69.773-42.133-125.586-42.133c-13.078,0-25.18,1.063-36.313,3.18
					c-11.125,2.125-24.641,5.133-40.539,9.016l18.023,112.875c4.586-0.703,9.539-1.406,14.836-2.125
					c5.305-0.703,10.07-1.055,14.313-1.055c25.438,0,45.039,6.281,58.82,18.813c13.781,12.547,24.367,28.18,31.789,46.898
					l18.016,44.516l-203.484,572.859h166.398L4688.56,490.447" class="svg-elem-8"></path>
				<path id="path36" display="none" fill="#FFF" d="M2520.724,76.814c-16.086,0.367-31.414-3.148-31.414-16.82
					c0-8.867,5.734-12.93,12.93-12.93c9.063,0,15.711,5.906,17.742,12.375c0.563,1.664,0.742,3.516,0.742,4.992V76.814z
					 M2523.67,32.829l-1.469,9.984h-0.563c-5.539-7.023-14.953-12.016-26.594-12.016c-18.125,0-28.273,13.125-28.273,26.797
					c0,22.727,20.133,34.18,53.398,34v1.477c0,5.914-2.406,15.703-18.289,15.703c-8.883,0-18.117-2.766-24.211-6.648l-4.438,14.781
					c6.648,4.063,18.289,7.945,32.516,7.945c28.836,0,37.141-18.289,37.141-37.883V54.454c0-8.133,0.375-16.078,1.305-21.625
					H2523.67" class="svg-elem-9"></path>
				<path id="path38" display="none" fill="#FFF" d="M2631.443,35.603c-4.813-2.211-14.234-4.617-25.5-4.617
					c-28.094,0-46.391,17.922-46.391,45.828c0,26.977,18.477,48.039,50.078,48.039c8.32,0,16.82-1.844,21.992-4.25l-4.063-17
					c-3.695,1.664-9.055,3.508-17.188,3.508c-17.367,0-27.719-12.75-27.539-29.188c0-18.484,12.008-29.016,27.539-29.016
					c7.938,0,13.492,1.664,17.922,3.508L2631.443,35.603" class="svg-elem-10"></path>
				<path id="path40" display="none" fill="#FFF" d="M2711.115,35.603c-4.805-2.211-14.234-4.617-25.492-4.617
					c-28.094,0-46.391,17.922-46.391,45.828c0,26.977,18.477,48.039,50.078,48.039c8.32,0,16.813-1.844,21.992-4.25l-4.063-17
					c-3.695,1.664-9.07,3.508-17.188,3.508c-17.367,0-27.719-12.75-27.539-29.188c0-18.484,12.008-29.016,27.539-29.016
					c7.938,0,13.492,1.664,17.922,3.508L2711.115,35.603" class="svg-elem-11"></path>
				<path id="path42" display="none" fill="#FFF" d="M2780.24,87.345c0.195,8.313-3.5,21.984-18.656,21.984
					c-14.039,0-19.953-12.75-20.883-21.984H2780.24L2780.24,87.345z M2740.701,71.447c0.563-16.258,13.305-23.281,27.719-23.281
					c10.539,0,18.109,1.484,24.953,4.07l3.32-15.711c-7.75-3.133-18.484-5.539-31.414-5.539c-29.195,0-46.375,17.922-46.375,45.453
					c0,24.945,15.148,48.414,43.977,48.414c29.188,0,38.805-24.023,38.805-43.797c0-4.242-0.375-7.57-0.734-9.609H2740.701" class="svg-elem-12"></path>
				<path id="path44" display="none" fill="#FFF" d="M2818.342,32.829h22.914v131.195h-22.914V32.829z" class="svg-elem-13"></path>
				<path id="path46" display="none" fill="#FFF" d="M2919.802,87.345c0.188,8.313-3.5,21.984-18.664,21.984
					c-14.039,0-19.945-12.75-20.875-21.984H2919.802L2919.802,87.345z M2880.263,71.447c0.555-16.258,13.297-23.281,27.719-23.281
					c10.539,0,18.109,1.484,24.953,4.07l3.32-15.711c-7.75-3.133-18.484-5.539-31.414-5.539c-29.203,0-46.383,17.922-46.383,45.453
					c0,24.945,15.156,48.414,43.984,48.414c29.188,0,38.797-24.023,38.797-43.797c0-4.242-0.367-7.57-0.727-9.609H2880.263" class="svg-elem-14"></path>
				<path id="path48" display="none" fill="#FFF" d="M2957.904,93.806c0,12.203-0.195,20.883-0.742,29.016h19.773l0.734-17.188
					h0.742c4.43,12.758,14.969,19.219,24.578,19.219c2.219,0,3.5-0.188,5.359-0.555v-21.43c-1.859,0.359-3.891,0.734-6.656,0.734
					c-10.898,0-18.289-7.023-20.32-17.18c-0.367-2.039-0.742-4.445-0.742-7.023v-46.57h-22.727V93.806" class="svg-elem-15"></path>
				<path id="path50" display="none" fill="#FFF" d="M3068.06,76.814c-16.086,0.367-31.422-3.148-31.422-16.82
					c0-8.867,5.734-12.93,12.945-12.93c9.047,0,15.695,5.906,17.727,12.375c0.563,1.664,0.75,3.516,0.75,4.992V76.814z
					 M3071.013,32.829l-1.484,9.984h-0.555c-5.531-7.023-14.961-12.016-26.602-12.016c-18.109,0-28.281,13.125-28.281,26.797
					c0,22.727,20.148,34.18,53.406,34v1.477c0,5.914-2.398,15.703-18.289,15.703c-8.875,0-18.117-2.766-24.211-6.648l-4.43,14.781
					c6.656,4.063,18.297,7.945,32.523,7.945c28.82,0,37.133-18.289,37.133-37.883V54.454c0-8.133,0.375-16.078,1.297-21.625
					H3071.013" class="svg-elem-16"></path>
				<path id="path52" display="none" fill="#FFF" d="M3137.568,148.509v-25.688h21.625v-17h-21.625V66.095
					c0-10.906,2.961-16.633,11.641-16.633c3.883,0,6.836,0.555,8.875,1.109l0.367-17.367c-3.328-1.297-9.242-2.219-16.445-2.219
					c-8.32,0-15.344,2.766-19.594,7.391c-4.797,5.172-7.023,13.305-7.023,25.133v42.313h-12.93v17h12.93v20.328L3137.568,148.509" class="svg-elem-17"></path>
				<path id="path54" display="none" fill="#FFF" d="M3229.24,87.345c0.18,8.313-3.508,21.984-18.656,21.984
					c-14.055,0-19.953-12.75-20.883-21.984H3229.24L3229.24,87.345z M3189.701,71.447c0.555-16.258,13.305-23.281,27.711-23.281
					c10.539,0,18.117,1.484,24.953,4.07l3.32-15.711c-7.75-3.133-18.477-5.539-31.406-5.539c-29.195,0-46.391,17.922-46.391,45.453
					c0,24.945,15.164,48.414,43.992,48.414c29.18,0,38.789-24.023,38.789-43.797c0-4.242-0.367-7.57-0.727-9.609H3189.701" class="svg-elem-18"></path>
				<path id="path56" display="none" fill="#FFF" d="M3318.162,122.822l16.258-48.227c1.852-5.547,3.703-12.383,4.992-17.555
					h0.563c1.477,5.172,3.141,11.828,4.805,17.742l14.039,48.039h24.383l-22.539-61.344c-12.383-33.633-20.688-48.602-31.406-57.836
					c-8.875-7.953-18.117-10.914-23.844-11.641l-5.18,19.211c3.891,0.93,8.703,2.961,13.313,6.289
					c4.25,2.766,9.234,8.125,12.391,14.219c0.914,1.672,1.469,2.961,1.469,4.07c0,0.922-0.18,2.219-1.305,4.438l-32.891,82.594
					H3318.162" class="svg-elem-19"></path>
				<path id="path58" display="none" fill="#FFF" d="M3410.029,77.736c0-17.367,8.516-30.492,22.359-30.492
					c12.93,0,21.992,12.75,21.992,30.859c0,14.047-6.281,30.117-21.797,30.117C3416.506,108.22,3410.029,92.697,3410.029,77.736z
					 M3477.849,78.658c0-33.078-23.281-47.859-46.195-47.859c-25.5,0-45.094,17.555-45.094,46.383
					c0,29.563,19.414,47.672,46.57,47.672C3459.92,124.853,3477.849,106.009,3477.849,78.658" class="svg-elem-20"></path>
				<path id="path60" display="none" fill="#FFF" d="M3575.459,59.806c0-10.711,0.375-19.578,0.734-26.977h-19.953l-1.109,13.672
					h-0.359c-3.891-6.461-12.758-15.703-28.828-15.703c-16.453,0-31.422,9.789-31.422,39.18v52.844h22.727V73.853
					c0-14.969,4.805-24.578,16.813-24.578c9.063,0,14.969,6.469,17.383,12.203c0.734,2.031,1.281,4.43,1.281,7.016v54.328h22.734
					V59.806" class="svg-elem-21"></path>
				<path id="path62" display="none" fill="#FFF" d="M3598.404,93.806c0,12.203-0.195,20.883-0.742,29.016h19.773l0.727-17.188
					h0.75c4.43,12.758,14.969,19.219,24.578,19.219c2.211,0,3.5-0.188,5.359-0.555v-21.43c-1.859,0.359-3.891,0.734-6.656,0.734
					c-10.898,0-18.289-7.023-20.32-17.18c-0.383-2.039-0.75-4.445-0.75-7.023v-46.57h-22.719V93.806" class="svg-elem-22"></path>
				<path id="path64" display="none" fill="#FFF" d="M3721.873,69.978c0-1.852,0.18-3.703,0.547-5.367
					c2.398-9.047,10.352-15.883,20.141-15.883c14.234,0,22.922,11.453,22.922,29.563c0,15.891-7.578,28.82-22.742,28.82
					c-9.227,0-17.539-6.648-20.125-16.625c-0.375-1.664-0.742-3.703-0.742-5.914V69.978z M3698.404,32.829
					c0.367,6.102,0.734,16.078,0.734,25.32v105.875h22.734v-53.586h0.367c5.539,8.688,15.344,14.414,28.82,14.414
					c22,0,37.703-18.289,37.508-45.641c0-32.336-20.5-48.414-40.836-48.414c-11.641,0-21.977,4.437-28.453,15.523h-0.367
					l-1.109-13.492H3698.404" class="svg-elem-23"></path>
				<path id="path66" display="none" fill="#FFF" d="M3886.185,59.806c0-10.711,0.367-19.578,0.727-26.977h-19.953l-1.109,13.672
					h-0.359c-3.891-6.461-12.758-15.703-28.828-15.703c-16.453,0-31.422,9.789-31.422,39.18v52.844h22.734V73.853
					c0-14.969,4.797-24.578,16.82-24.578c9.047,0,14.969,6.469,17.367,12.203c0.742,2.031,1.289,4.43,1.289,7.016v54.328h22.734
					V59.806" class="svg-elem-24"></path>
				<path id="path68" display="none" fill="#FFF" d="M3908.013,53.712c5.172-3.141,14.969-6.469,23.102-6.469
					c9.969,0,14.406,4.063,14.406,9.977c0,6.102-3.688,9.242-14.781,13.125c-17.555,6.094-24.945,15.703-24.758,26.234
					c0,15.898,13.117,28.273,34,28.273c9.977,0,18.656-2.586,23.836-5.359l-4.438-16.078c-3.875,2.219-11.086,5.18-19.031,5.18
					c-8.125,0-12.57-3.883-12.57-9.422c0-5.734,4.25-8.508,15.711-12.57c16.266-5.914,23.828-14.227,24.023-27.531
					c0-16.258-12.742-28.086-36.578-28.086c-10.914,0-20.711,2.586-27.367,6.281L3908.013,53.712" class="svg-elem-25"></path>
				<path id="path70" display="none" fill="#FFF" d="M3984.545,32.829v89.992h22.906V32.829H3984.545z M4008.756,147.954
					c0-6.844-4.992-12.195-12.945-12.195c-7.578,0-12.57,5.352-12.57,12.195c0,7.023,5.18,12.375,12.766,12.375
					C4003.763,160.329,4008.56,154.978,4008.756,147.954" class="svg-elem-26"></path>
				<path id="path72" display="none" fill="#FFF" d="M4030.201,96.033c0,10.344-0.188,19.023-0.75,26.789h19.961l1.109-13.492
					h0.547c3.883,7.023,13.688,15.523,28.656,15.523c15.695,0,31.961-10.164,31.961-38.617V32.829h-22.726v50.82
					c0,12.93-4.805,22.727-17.188,22.727c-9.063,0-15.328-6.469-17.742-13.305c-0.734-2.031-0.914-4.805-0.914-7.391V32.829h-22.914
					V96.033" class="svg-elem-27"></path>
				<path id="path74" display="none" fill="#FFF" d="M4189.88,87.345c0.188,8.313-3.5,21.984-18.656,21.984
					c-14.047,0-19.953-12.75-20.883-21.984H4189.88z M4150.341,71.447c0.563-16.258,13.297-23.281,27.719-23.281
					c10.531,0,18.109,1.484,24.953,4.07l3.32-15.711c-7.766-3.133-18.484-5.539-31.414-5.539c-29.195,0-46.375,17.922-46.375,45.453
					c0,24.945,15.148,48.414,43.977,48.414c29.188,0,38.797-24.023,38.797-43.797c0-4.242-0.367-7.57-0.727-9.609H4150.341" class="svg-elem-28"></path>
				<path id="path76" display="none" fill="#FFF" d="M4226.88,53.712c5.172-3.141,14.969-6.469,23.102-6.469
					c9.969,0,14.398,4.063,14.398,9.977c0,6.102-3.688,9.242-14.766,13.125c-17.563,6.094-24.953,15.703-24.773,26.234
					c0,15.898,13.133,28.273,34.008,28.273c9.984,0,18.664-2.586,23.844-5.359l-4.445-16.078c-3.875,2.219-11.086,5.18-19.023,5.18
					c-8.141,0-12.57-3.883-12.57-9.422c0-5.734,4.25-8.508,15.695-12.57c16.266-5.914,23.844-14.227,24.031-27.531
					c0-16.258-12.75-28.086-36.586-28.086c-10.906,0-20.703,2.586-27.352,6.281L4226.88,53.712" class="svg-elem-29"></path>
				<path id="path78" display="none" fill="#FFF" d="M4302.294,53.712c5.164-3.141,14.969-6.469,23.094-6.469
					c9.977,0,14.406,4.063,14.406,9.977c0,6.102-3.695,9.242-14.773,13.125c-17.563,6.094-24.953,15.703-24.766,26.234
					c0,15.898,13.125,28.273,34.008,28.273c9.977,0,18.656-2.586,23.836-5.359l-4.438-16.078c-3.883,2.219-11.094,5.18-19.031,5.18
					c-8.141,0-12.57-3.883-12.57-9.422c0-5.734,4.25-8.508,15.703-12.57c16.258-5.914,23.836-14.227,24.031-27.531
					c0-16.258-12.75-28.086-36.586-28.086c-10.906,0-20.703,2.586-27.359,6.281L4302.294,53.712" class="svg-elem-30"></path>
			</g>
		</g>
	</g>
</g>
</svg>
</ng-container>
<ng-container *ngIf="customLogo">
  <img [src]="customLogoSrc" style="max-height: 40px">
</ng-container>
