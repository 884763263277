import { Component, OnInit } from "@angular/core";
import { Breadcrumb } from "src/app/interfaces/bread-crumb";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-preorder-basket-page",
  templateUrl: "./preorder-basket-page.component.html",
  styleUrls: ["./preorder-basket-page.component.scss"]
})
export class PreorderBasketPageComponent implements OnInit {
  breadcrumbs: Breadcrumb[];
  constructor(
    translateService: TranslateService,
    applicationService: ApplicationService
  ) {
    this.breadcrumbs = [
      new Breadcrumb(
        translateService.instant("BASKETS"),
        applicationService.getSelectCompanyGroupCode() + "/baskets"
      ),
      new Breadcrumb(translateService.instant("PREORDER_BASKET"), "#")
    ];
  }

  ngOnInit() {}
}
