<div class="row">
  <div class="col-md-12 bg-transparent">
    <div class="card-body section">
      <h4>{{ "BONUS" | translate }}</h4>
      <ng-container *ngFor="let bonus of bonusses">
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item py-0 ps-1 border-0"
            style="background: none"
          >
            <div class="row mx-0">
              <div class="col-1"></div>
              <div class="col-6">
                {{ "BONUS" | translate }} ({{ "SELECT_MAX" | translate }}:
                {{ bonus.max_bonus_percentage }}%)
              </div>
              <div class="col-4 text-end" *ngIf="bonus.has_planning">{{ "PLANNING" | translate }}</div>
            </div>
          </li>
        </ul>
        <ul class="list-group mb-2">
          <li class="list-group-item py-1 ps-1">
            <div class="row mx-0">
              <div class="col-1 switch-container">
                <div class="form-check form-switch">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [disabled]="yearAgreementPAndAService.agreementModel.year_agreement_base.locked"
                    [id]="'bonus_' + bonus.id"
                    [(ngModel)]="bonus.selected"
                    (change)="setSelection(bonus)"
                  />
                  <label
                    class="form-check-label"
                    [for]="'bonus_' + bonus.id"
                  ></label>
                </div>
              </div>
              <div class="col-9 d-flex align-items-center">
                <label
                  class="clickable withOpacity"
                  [for]="'bonus_' + bonus.id"
                  >{{ bonus.description }}</label
                >
              </div>
              <div class="col-2 d-flex align-items-center">
                <div class="form-check form-switch" *ngIf="bonus.has_planning">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [disabled]="yearAgreementPAndAService.agreementModel.year_agreement_base.locked"
                    [id]="'bonus_planning_' + bonus.id"
                    [(ngModel)]="bonus.planning"
                  />
                  <label
                    class="form-check-label"
                    [for]="'bonus_planning_' + bonus.id"
                  ></label>
                </div>
              </div>
            </div>
            <ng-container *ngIf="bonus.selected">
              <div class="row mx-0" *ngFor="let subBonus of bonus.options">
                <div class="col-1 switch-container">
                  <div class="form-check form-switch">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      [id]="'bonus_' + subBonus.id"
                      [(ngModel)]="subBonus.selected"
                      [disabled]="
                        (totalBonusPercentage + subBonus.bonus_percentage >
                          bonus.max_bonus_percentage &&
                          !subBonus.selected) ||
                        yearAgreementPAndAService.agreementModel.year_agreement_base.locked
                      "
                    />
                    <label
                      class="form-check-label"
                      [for]="'bonus_' + subBonus.id"
                    ></label>
                  </div>
                </div>
                <div class="col-5 d-flex align-items-center">
                  <label
                    class="clickable withOpacity"
                    [for]="'bonus_' + subBonus.id"
                    >{{ subBonus.description }}</label
                  >
                </div>
                <div class="col-4 text-end">
                  <ng-container *ngIf="subBonus.selected"
                    >{{ subBonus.bonus_percentage }}%</ng-container
                  >
                </div>
              </div>
            </ng-container>
          </li>

          <li class="list-group-item total py-2 ps-1 border-0">
            <div class="row mx-0">
              <div class="col-1"></div>
              <div class="col-5">{{ "TOTAL" | translate }}</div>
              <div class="col-4 text-end">{{ totalBonusPercentage }}%</div>
            </div>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
</div>
