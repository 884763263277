<div class="container pt-5">
    <div class="mb-3 ">
        <label for="framenumber" class="form-label">{{"FRAMENUMBER"|translate}}:</label>
        <input type="text" id="framenumber" class="form-control" [(ngModel)]="framenumber" placeholder="" />
    </div>
    <button class="btn btn-primary" (click)="fetchBatteries()" [disabled]="!framenumber">{{"GET_BATTERY_DETAILS"|translate}}</button>
    <div *ngIf="showDetails" class="mt-4">
        <h3>{{"BATTERY_DETAILS"|translate}}</h3>
        <div *ngIf="batteryDetails">
            <h5>{{batteryDetails.model}}</h5>
            <table class="table table-bordered border" *ngIf="batteryDetails.battery1?.length">
                <thead>
                    <tr>
                        <th>{{"BATTERY"|translate}} #</th>
                        <th>{{"DATE"|translate}}</th>
                        <th>{{"BATTERY_NUMBER"|translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let battery of batteryDetails.battery1; let i = index">
                        <td>1</td>
                        <td>{{battery.sys_add | date: "dd-MM-yyyy"}}</td>
                        <td>{{battery.battery_number}}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-bordered border mt-4" *ngIf="batteryDetails.battery2?.length">
                    <thead>
                        <tr>
                            <th>{{"BATTERY"|translate}} #</th>
                            <th>{{"DATE"|translate}}</th>
                            <th>{{"BATTERY_NUMBER"|translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let battery of batteryDetails.battery2; let i = index" [class.border-top]="i === 0">
                            <td>2</td>
                            <td>{{battery.sys_add | date: "dd-MM-yyyy"}}</td>
                            <td>{{battery.battery_number}}</td>
                        </tr>
                </tbody>
            </table>
        </div>
    <button *ngIf="batteryDetails?.battery1.length > 0 || batteryDetails?.battery2.length > 0" class="btn btn-warning me-2" (click)="replaceBatteries();" [ngClass]="!showNew && showForm ? 'border border-black' : ''">
        {{"REPLACE_BATTERIES" | translate}}
    </button>
    <button *ngIf="!batteryDetails?.battery1.length > 0 || !batteryDetails?.battery2.length > 0" class="btn btn-warning" (click)="showNew = true; setValidators()" [ngClass]="showNew ? 'border border-black' : ''">
        {{"ADD_BATTERIES" | translate}}
    </button>
    </div>
    <div *ngIf="showForm || showNew" class="mt-4 rounded form-container p-4">
        <form [formGroup]="batteriesForm" (ngSubmit)="save()">
            <div class="mb-3" *ngIf="(!showNew && batteryDetails?.battery1?.length > 0) || (showNew && !batteryDetails?.battery1.length)">
                <label for="battery1" class="form-label">{{"BATTERY"|translate}} 1:<ng-container *ngIf="!showNew"> ({{batteryDetails.battery1[batteryDetails.battery1.length - 1].battery_number}})</ng-container></label>
                <input id="battery1" formControlName="battery1" type="text" class="form-control" (change)="updateValidation()" />
                <div *ngIf="batteriesForm.get('battery1')?.invalid && batteriesForm.get('battery1')?.touched"
                    class="text-danger">
                    {{"LENGTH_10-13"|translate}}
                </div>
            </div>
            <div class="mb-3" *ngIf="(!showNew && batteryDetails?.battery2?.length > 0) || (showNew && !batteryDetails?.battery2?.length && (batteryDetails.battery1?.length))">
                <label for="battery2" class="form-label">{{"BATTERY"|translate}} 2:<ng-container *ngIf="!showNew"> ({{batteryDetails.battery2[batteryDetails.battery2.length - 1].battery_number}})</ng-container></label>
                <input id="battery2" formControlName="battery2" type="text" class="form-control" (change)="updateValidation()" />
                <div *ngIf="batteriesForm.get('battery2')?.invalid && batteriesForm.get('battery2')?.touched"
                    class="text-danger">
                    {{"LENGTH_10-13"|translate}}
                </div>
            </div>
            <button type="submit" class="btn btn-success btn-lg"
                [disabled]="batteriesForm.invalid || !batteriesFilledIn">{{"SAVE"|translate}}</button>
        </form>
    </div>
</div>