
<div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown" [autoClose]="false">
  <button class="ps-1 btn iconWithBadge basketPreviewContainer pe-0 pe-lg-0" id="dropdownManual" [attr.data-count]="numArticles > 0 ? numArticles : ''" [ngClass]="[adding ? 'adding' : '', isDealer ? 'iconOnly' : '']"
    style="background: none; color: #FFF" ngbDropdownAnchor (click)="gotoBasket(myDrop)" (mouseleave)="stopTimer(myDrop)"
     (mouseenter)="showDropdown(myDrop)" [ngClass]="changing ? 'changing' : ''">
    <ng-container *ngFor="let article of addedArticles; let i = index">
    <i class="part-icon" *ngIf="article.thumbs && article.thumbs.length" [ngStyle]="{'animation-delay': (i *250) + 'ms'}">
      <img [src]="article.thumbs[0]" style="width: 32px; height: auto">
      </i>
    </ng-container>
    <i class="material-icons basket-icon light">
      shopping_basket
    </i>
    <small class="d-block text-end pe-2" *ngIf="!isDealer">{{"BASKET" | translate}}</small>
    <div class="spinner spinner-border text-light spinner-border-sm position-absolute" style="pointer-events: none;" role="status" *ngIf="loading">
      <span class="visually-hidden">{{'LOADING'|translate}}</span>
    </div>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownManual" class="py-0" (clickOutside)="closeDropdown(myDrop, true)" (mouseenter)="cursorOutModal = false"
    (mouseleave)="closeDropdown(myDrop)">
    <div class="p-3 col-12 bg-white" *ngIf="loading && !basket?.items?.length"><div class="spinner spinner-border spinner-border-sm"> <span class="visually-hidden">{{'LOADING'|translate}}</span>
    </div></div>
    <div class="p-2 col-12 bg-white" *ngIf="!basket && !loading">
      <p class="p-3 mb-0">{{'YOU_HAVE_NO_ARTICLES'|translate}}</p>
      <span class="mt-2 me-2 btn btn-dark btn-lg mb-1" *ngIf="!basket"
      (click)="myDrop.close()">{{
      'CLOSE' |
      translate}}</span>
    </div>
    <div class="ps-1 ps-sm-3 p-sm-3 pb-5 dropDownContainer" *ngIf="basket">
      <span class="position-absolute end-0 me-3 close clickable withOpacity" style="z-index: 99;" (click)="myDrop.close()">
        <i class="material-icons">close</i>
      </span>
      <div class="groupedArticles customScrollbar">
        <div class="position-relative">

          <!-- Locked basket overlay -->
          <div class="top-0 basket-locked position-absolute w-100 h-100 d-flex justify-content-center align-items-top" *ngIf="basket.locked">
            <div class="flex mt-5 justify-content-center align-items-center">
              <span class="me-2 material-icons text-danger">
                lock
              </span>
              <span class="me-3">{{ 'BASKET_LOCKED' | translate }}</span><button class="btn btn-sm btn-outline-primary" (click)="unlockBasket()">{{ 'UNLOCK_BASKET' | translate }}</button>
            </div>
          </div>

          <!-- Parts -->
          <app-grouped-articles
            *ngIf="groupedArticles && groupedArticles['parts'] && groupedArticles['parts'].items.length"
            [groupedArticles]="groupedArticles['parts']" (contentChanged)="calcTotal(articles)"
            [currency]="basket.total_price?.currency" [header]="'PARTS'" [sum]="basketSum['parts']" [compact]="true"
            [basketId]="basket.id"></app-grouped-articles>

          <!-- Bicycles -->
          <app-grouped-articles
            *ngIf="groupedArticles && groupedArticles['bicycles'] && groupedArticles['bicycles'].items.length"
            [groupedArticles]="groupedArticles['bicycles']" (contentChanged)="calcTotal(articles)"
            [currency]="basket.total_price?.currency" [header]="'BICYCLES'" [sum]="basketSum['bicycles']" [compact]="true"
            [basketId]="basket.id"></app-grouped-articles>
          <p class="px-3" *ngIf="(articles && !articles.length) || !articles">{{'YOU_HAVE_NO_ARTICLES'|translate}}</p>
        </div>
      </div>
      <div class="px-2 mt-3 col-md-12 d-flex">
        <ul class="w-100 px-0 pb-0 mb-0 list-group list-group-flush col-md-12 pt-1">
          <li class="px-0 list-group-item">
            <div class="w-100 d-flex">
              <div class="ps-0 col-md-9 buttons">
                <a class="float-left me-2 btn btn-dark btn-lg mb-1" *ngIf="articles && articles.length"
                  (click)="myDrop.close()" [routerLink]="[selectedCompanyGroup + '/basket/' + basket.id]" [queryParams]="{checkout: true}">{{
                  'CHECKOUT' |
                  translate}}</a>

                <a class="float-left me-2 btn btn-success btn-lg mb-1" *ngIf="articles && articles.length"
                  (click)="myDrop.close()" [routerLink]="[selectedCompanyGroup + '/basket/' + basket.id]">{{
                  'VIEW'
                  |
                  translate}}</a>
                <button class="btn btn-light btn-lg mb-1" (click)="myDrop.close()">
                  <a [routerLink]="'/' + selectedCompanyGroup + '/baskets/'">{{'ALL_BASKETS' | translate }}</a>
                </button>
              </div>
              <div class="ms-auto col-6 col-md-3 hideForShowroomMode sum totalSum"
                *ngIf="articles && articles.length && basket.shipping_cost">
                <small class="d-block" *ngIf="basket.shipping_cost && selectedCompanyGroup !== 'WG'" style="font-size: 12px; text-wrap: balance">{{'SHIPPINGCOST' | translate }} <span
                    [innerHTML]="basket.shipping_cost.value | currencyConvert: basket.shipping_cost?.currency"></span></small>
                {{'TOTAL' | translate }} <span class="d-block d-sm-inline-block"
                  [innerHTML]="basketSum.totalWithShipping | currencyConvert: basket.shipping_cost?.currency"></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
