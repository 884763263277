<div class="form-container p-5" [ngClass]="active ? 'active' : ''">
  <div class="w-100 text-center">
    <img src="/assets/images/Accentry-logo.svg" style="width: 300px" />
    <h1>{{ "PLEASE_REGISTER" | translate }}</h1>
  </div>
  <form
    class="form-signin"
    id="form-register"
    ngNativeValidate
    *ngIf="!success"
  >
    <ng-container *ngIf="!error">
      <div class="form-signin-heading mb-4">
        <p>{{ "REGISTER_INFO" | translate }}</p>
      </div>

      <div class="mb-3" [formGroup]="formGroup">
        <label for="username">{{ "EMAIL" | translate }}</label>
        <small class="small d-block">* {{ "IS_USERNAME" | translate }}</small>
        <input
          type="email"
          class="form-control"
          name="username"
          autofocus=""
          id="username"
          formControlName="username"
          required
        />
      </div>
      <div class="mb-3 mb-2" [formGroup]="formGroup">
        <label for="username" class="mt-1">{{
          "CONFIRM_EMAIL" | translate
        }}</label>
        <input
          type="email"
          class="form-control"
          name="usernameconfirm"
          id="usernameconfirm"
          [ngClass]="userNameDiffers ? 'border-danger' : ''"
          formControlName="usernameconfirm"
          required
        />
      </div>

      <div [formGroup]="formGroup">
        <input
          type="submit"
          [disabled]="loading || error"
          class="btn btn-lg btn-block btn-success"
          [value]="'SEND' | translate"
          (click)="submit()"
        />
      </div>
    </ng-container>
    <ng-container *ngIf="error">
      <p class="text-danger h2">{{"ERROR" | translate }}</p>
    </ng-container>
    <a
      [routerLink]="'/login'"
      class="btn text-primary forgot btn-link ps-0 mt-3"
    >
      {{ "BACK_TO_LOGIN" | translate }}</a
    >
  </form>
  <app-success-check-icon *ngIf="success">
    <b class="mt-2">{{ "ACTIVATE_TITLE" | translate }}</b>
    <p class="successMsg">{{ successText }}</p>
  </app-success-check-icon>
</div>
