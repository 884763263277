<div class="col-md-12 px-0">
  <form>
    <div class="row pt-3 px-3 pb-1 justify-content-around">
      <div class="mb-3 row pe-0 col-md-3">
        <div class="col-md-12 ps-md-3 ps-0 pe-1">
          <label class="fw-bold" for="keyWord"> {{'SEARCH' | translate }}</label>
          <input type="search" class="form-control" id="keyWord" name="keyWord" [(ngModel)]="filters['keyWord']">
        </div>
      </div>
      <div class="mb-3 col-md-1 col-lg-1" *ngIf="applicationService.getSelectCompanyGroupCode() == 'WG' && applicationService.checkIfDealerHasCompany(101) && applicationService.checkIfDealerHasCompany(102)">
        <label class="fw-bold" for="companyFilter">{{'SELECT_COMPANY'|translate}}</label>
        <select class="form-control" [(ngModel)]="filters['companyFilter']" name="companyFilter">
          <option value="" selected>{{'ALL'|translate}}</option>
          <option value="101" *ngIf="applicationService.checkIfDealerHasCompany(101)">Winora Bikes</option>
          <option value="102" *ngIf="applicationService.checkIfDealerHasCompany(102)">E. Wiener Bike Parts</option>
        </select>
      </div>
      <div class="mb-3 col-md-2">
        <label class="fw-bold" for="invoiceTypeState">{{'INVOICE_TYPE'|translate}}</label>
        <select id="invoiceTypeState" class="form-control" [(ngModel)]="filters['invoice_state_filter']" name="invoice_state_filter">
          <option value="All">{{"ALL"|translate}}</option>
          <option value="O">{{"UNPAID"|translate}}</option>
          <option value="B">{{"PAID"|translate}}</option>
        </select>
      </div>
      <div class="mb-3 col-md-2" *ngIf="applicationService.getSelectCompanyGroupCode() !== 'WG' && shippingAddressGroup && shippingAddressGroup.shipping_addresses.length">
        <label class="fw-bold" for="inputState">{{'SHIPPINGADDRESS'|translate}}</label>
        <app-select-shipping-address [shippingAddresses]="shippingAddressGroup"
          (shippingAddressSelected)="setShippingAddress($event)"></app-select-shipping-address>
      </div>
      <div class="mb-3 col-md-2 date-container">
        <label class="fw-bold" for="inputState">{{'INVOICE_DATE_RANGE'|translate}} {{'FROM'|translate}}</label>
        <div class="input-group">
          <input class="form-control" placeholder="dd-mm-YYYY"
            [maxDate]="{year: maxDate.getFullYear(), month: (maxDate.getMonth() + 1), day:maxDate.getDate()}"
            name="dpfrom" (click)="df.toggle()" [(ngModel)]="filters['date_from']" ngbDatepicker #df="ngbDatepicker">
        </div>
      </div>
      <div class="mb-3 col-md-2 date-container">
        <label class="fw-bold" for="inputState">{{'INVOICE_DATE_RANGE'|translate}} {{'TILL'|translate}}</label>
        <div class="input-group">
          <input class="form-control" placeholder="dd-mm-YYYY"
            [maxDate]="{year: maxDate.getFullYear(), month: (maxDate.getMonth() + 1), day:maxDate.getDate()}"
            name="dpto" (click)="dt.toggle()" [(ngModel)]="filters['date_to']" ngbDatepicker #dt="ngbDatepicker">
        </div>
      </div>
      <div class="mb-3 col-md-2">
        <label for="inputState" style="visibility: hidden">{{ 'SEARCH' | translate }}</label>
        <div class="input-group me-1">
          <div class="btn-group" role="group">
            <button class="btn btn-dark" (click)="submit()">{{ 'SEARCH' | translate }}</button>
            <button class="btn btn-default ms-1" (click)="resetFilters()">{{'RESET' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
