import { Injectable } from "@angular/core";
import { PreorderInputCollection } from "../interfaces/preorder/preorder-input-collection";
import { BasketArticle } from "../interfaces/basketArticle";
import * as _ from "underscore";
import * as moment from "moment";
import { ApplicationService } from "./application.service";
import { environment } from "src/environments/environment";
import { ApiResponse } from "../interfaces/api-response";
import {
  PreorderSettings,
  PreorderSettingsContainer,
} from "../interfaces/settings/preorder-settings";
import { HttpClient } from "@angular/common/http";
import { CacheService } from "./cache.service";
import { CacheType } from "../interfaces/cache-tracker";
import { NdaCheckComponent } from "../components/preorder-components/nda-check/nda-check.component";
import { ModalsService } from "../modals.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Injectable({
  providedIn: "root",
})
export class PreorderService {
  public availableTimeSlots = [1, 2, 4];
  public availableTimeSlots_WG = [1,2,3,4,5,6,7,8,9,10,11,12];
  public availableTimeSlots_GHO = [2, 4];
  public availableTimeSlotsBico = [2];
  public startYear = null;
  unsaved: boolean;
  loading: Promise<PreorderSettingsContainer>;
  reload: boolean;
  preorderType: string = "";
  constructor(
    private applicationService: ApplicationService,
    private http: HttpClient,
    private cacheService: CacheService,
    private modalService: NgbModal,
  ) {
    this.applicationService.emitChangeCompanyGroupChanged$.subscribe(
      () => {
        this.loading = undefined
      })
  }

  // Gets the preorder settings
  getPreorderSettings(): Promise<PreorderSettingsContainer> {

    if (typeof this.loading !== "undefined") {
      return this.loading;
    }

    this.loading = new Promise((resolve) => {
      if (!this.cacheService.needRefresh(CacheType.preorder)) {
        const cached = this.cacheService.preorderSettings[this.applicationService.getSelectCompanyGroupCode()];
        resolve(cached);
        return false;
      }

      this.http
        .get(
          `${
            environment.apiendpoint
          }companygroups/${this.applicationService.getSelectCompanyGroupCode()}/preordersettings`
        )
        .subscribe((response: any) => {
          if (response && response.result) {
            let settings = <PreorderSettingsContainer>response.result;

            if (typeof settings !== "undefined") {
              const preorderTypes = ["Bicycles", "Parts"];

              preorderTypes.forEach((t) => {
                settings[t] = this.parseSettings(settings[t]);
              });
            } else {
              // No settings found - create
              settings = new PreorderSettingsContainer();
            }
            this.cacheService.preorderSettings[this.applicationService.getSelectCompanyGroupCode()] = settings;
            this.cacheService.dataLoaded(CacheType.preorder);
            resolve(settings);
          }
        });
      return false;
    });
    return this.loading;
  }

  getPreorderSettingsOfType(preorderType: string) {
    return new Promise((resolve, reject) => {
      this.getPreorderSettings().then(
        (preorderSettings: PreorderSettingsContainer) => {
          resolve(
            typeof preorderSettings[preorderType] !== "undefined"
              ? preorderSettings[preorderType]
              : new PreorderSettingsContainer()
          );
        }
      );
    });
  }

  parseSettings(settings: PreorderSettings) {
    if (typeof settings !== "undefined") {
      // Check if - for internal user only
      if (settings.internal && localStorage.getItem("accountType") === "DLR") {
        settings.enabled.from = null;
        settings.enabled.untill = null;
      }
      settings.use_time_slots = false
      if (   this.applicationService.getSelectCompanyGroupCode() === "WG" ||
      this.applicationService.getSelectCompanyGroupCode() === "GHO") {
        settings.separate_checkout = true;
      }
    } else {
      settings = new PreorderSettings();
    }
    return settings;
  }

  preorderEnabled(preorderType) {
    return new Promise((resolve, reject) => {
      this.getPreorderSettings().then((response) => {
        const preorderSettings = (response as PreorderSettingsContainer)[
          preorderType
        ];
        if (
          preorderSettings &&
          preorderSettings.enabled &&
          moment(preorderSettings.enabled.from).isSameOrBefore(moment()) &&
          moment(preorderSettings.enabled.untill).isSameOrAfter(moment())
        ) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  collectInput(parent, parentBasketArticleId?: number) {
    const articleList = [];
    const inputs = parent.getElementsByClassName("articleMonthInput");
    let totalQuantity = 0;
    for (const element of inputs) {
      const quantity =
        Number(element.value) || element.getAttribute("data-quantity");
      if (quantity || element.value === 0) {
        let deliveryWeek = String(element.getAttribute("data-week"));
        if (Number(deliveryWeek) < 10) {
          deliveryWeek = "0" + String(deliveryWeek);
        }
        totalQuantity += quantity;
        const basketArticle = {
          quantity: quantity,
          remarks: "",
          sold: false,
          brand: String(element.getAttribute("data-brand")).toLowerCase(),
          back_order: true,
          type: element.getAttribute("data-type")
            ? element.getAttribute("data-type")
            : null,
          single_article_code: element.getAttribute("data-articlecode"),
          delivery_week: element.getAttribute("data-week")
            ? element.getAttribute("data-year") + deliveryWeek
            : null,
          asap_delivery: !element.getAttribute("data-week") ? true : false,
        };
        if (parentBasketArticleId) {
          basketArticle["parent_basket_item_id"] = parentBasketArticleId;
          basketArticle["parent_id"] = parentBasketArticleId;
        }
        articleList.push(basketArticle);
      }
    }
    const preorderInputCollection: PreorderInputCollection =
      new PreorderInputCollection(totalQuantity, articleList);
    return preorderInputCollection;
  }

  preOrderUrl(articleCode: string) {
    return `${window.location.origin}/${this.applicationService.getSelectCompanyGroupCode()}/article/${articleCode}`;
  }

  ndaModal() {
    return new Promise((approve) => {
      const reference = this.modalService.open(NdaCheckComponent, {
        container: "#modalContainer",
        windowClass: "fixed-modal medium",
        backdrop: "static",
        backdropClass: "blurred",
        centered: true,
      });

      reference.componentInstance.title = "PREORDER_AGREEMENT";
      reference.componentInstance.key = "preorder";
      reference.componentInstance.section = "preorder";
      reference.componentInstance.ts = moment().year().toString();

      // NDA Storybloks page Ids
      reference.componentInstance.pageIds = {
        ANL: 960422135,
        GHO: 960422146,
        WG: 960422147,
      };

      reference.componentInstance.checkedEvent.subscribe(() => {
        approve(true)
      });
    })
  }
}
