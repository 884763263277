import { Article } from "./article";
import { BasketArticle } from "./basketArticle";

export interface BasketArticles {
  checkoutCount: number;
  items: BasketArticle[] | null;
}

export class BasketArticles implements BasketArticles {
  constructor() {
    this.checkoutCount = 0;
    this.items = Array<BasketArticle>();
  }
}
