import { TurnOverSelection } from "./turn-over-selection";

export interface YearAgreementDataModel {
  cmDealer: boolean;
  locked: boolean;
  selected: any;
  section_percentages: any;
  countryCode: string;
  year: number;
  remarks: string;
  brands: YearAgreementDataGroup;
  sports: YearAgreementDataGroup;
  specials: YearAgreementDataGroup;
  dealer: YearAgreementDataGroup;
  turnover_history: any;
  showMargins: boolean;
  disabled: boolean;
  focusmodels: any[];
  demomodels: any[];
  affiliates: string;
  dummyTurnover: number;
  lease_promo: boolean;
  texts: {}
}

export class YearAgreementDataModel implements YearAgreementDataModel {
  constructor() {
    this.cmDealer = false;
    this.selected = {
      Bonus: {}
    };
    this.section_percentages = {
      Bonus: {}
    };
    this.countryCode = "";
    this.year = 0;
    this.dummyTurnover = 0;
    this.remarks = "";
    this.brands = new YearAgreementDataGroup(false, false);
    this.sports = new YearAgreementDataGroup(false, false);
    this.specials = new YearAgreementDataGroup(false, false);
    this.dealer = new YearAgreementDataGroup(false, false);
    this.showMargins = false;
    this.focusmodels = [];
    this.demomodels = [];
    this.turnover_history = {};
    this.texts = {};
  }
}

export interface YearAgreementDataGroup {
  loaded: boolean;
  hasAccess: boolean;
  selected: any;
  percentages: any;
  section_percentages: {};
  turnover_selection: {};
  brands: [];
  brands_affiliates: [];
  manualTurnover: number;
}

export class YearAgreementDataGroup implements YearAgreementDataGroup {
  constructor(loaded: boolean, hasAccess: boolean) {
    this.loaded = loaded;
    this.hasAccess = hasAccess;
    this.selected = {};
    this.percentages = {};
    this.section_percentages = {};
    this.turnover_selection = {};
    this.brands = [];
  }
}
