<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container">
  <div class="row">
    <div class="col-md-12 px-3 text-center">
      <h1>{{'404ERROR' | translate}}</h1>

<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 458.841 458.841" style="enable-background:new 0 0 458.841 458.841;" xml:space="preserve">
<g>
	<g id="XMLID_844_">
		<path id="xBody" d="M228.091,183.62c11.131-14.109,20.512-26.026,20.512-26.026l59.914,40.036
			c7.904,5.282,18.585,3.149,23.86-4.745c5.28-7.902,3.152-18.583-4.745-23.86l-73.161-48.889c-2.928-1.957-6.251-2.901-9.547-2.901
			c-5.116,0-10.183,2.263-13.54,6.579c-4.621,5.94-12.176,16.395-12.176,16.395s-6.419,18.008-25.767,13.743l-52.22-10.337
			l54.061-3.426c4.791-0.303,9.112-2.985,11.511-7.143l28.618-49.609c3.956-6.858,1.604-15.624-5.254-19.58
			c-6.86-3.956-15.624-1.603-19.58,5.254l-24.778,42.952l-46.389,2.94l28.18-9.401l-10.814-8.461
			c-9.297-7.274-22.73-5.634-30.004,3.663l-23.487,30.019c-0.228,0.292-0.421,0.598-0.632,0.897l-30.324,10.35l-49.256-37.863
			c-6.277-4.825-15.276-3.648-20.102,2.629s-3.648,15.277,2.63,20.102l55.35,42.546c3.813,2.931,8.833,3.749,13.367,2.201
			l32.398-11.058c0.08,0.064,0.149,0.136,0.23,0.2l41.945,32.489C173.48,212.361,208.826,208.038,228.091,183.62z"/>
		<circle  id="xHead" cx="80.106" cy="83.605" r="31.64"/>
		<path id="xBike" d="M433.158,359.63l-21.743,2.827l-0.92-67.206c2.559-0.535,5.101-1.234,7.61-2.107
			c31.639-10.992,48.352-45.496,37.356-77.14l0,0c-10.971-31.565-45.578-48.325-77.141-37.354
			c-31.566,10.968-48.324,45.573-37.355,77.14c7.427,21.373,25.58,36,46.596,39.784l0.067,4.902l-97.049-36.462
			c-7.295-2.792-15.149,2.374-15.554,10.112l-3.87,65.892l-12.636-9.676c7.449-14.932,8.365-31.599,3.046-46.931
			c-0.003-0.01-0.005-0.019-0.008-0.029c-0.004-0.011-0.009-0.021-0.013-0.032c-11.004-31.612-45.489-48.318-77.127-37.322
			c-31.64,10.994-48.352,45.496-37.355,77.14c10.968,31.563,45.416,48.38,77.14,37.354c7.644-2.656,14.542-6.72,20.411-11.941
			l24.919,19.082l-0.642,10.936l-17.191,5.974c-5.979,2.077-9.146,8.606-7.068,14.597c2.08,5.984,8.616,9.147,14.598,7.068
			l49.908-17.342c5.979-2.077,9.146-8.606,7.068-14.597c-1.527-4.394-5.458-7.259-9.809-7.652l81.8-24.794l0.462,33.793
			c0.094,6.873,6.173,12.101,12.945,11.215l34.513-4.488c6.281-0.816,10.711-6.57,9.894-12.851
			C445.193,363.242,439.432,358.812,433.158,359.63z M216.673,338.858c-19.663,6.833-41.113-3.549-47.945-23.218
			c-6.835-19.662,3.548-41.112,23.218-47.946c16.065-5.583,33.059,0.354,42.502,12.969l-33.903,11.781
			c-3.984,1.385-6.888,4.846-7.558,9.011s1.001,8.362,4.35,10.927L225.83,334.2C223.038,336.136,219.971,337.712,216.673,338.858z
			 M239.769,315.984l-11.579-8.866l13.785-4.79C242.098,306.951,241.36,311.569,239.769,315.984z M293.749,346.514l3.264-55.581
			l80.422,30.215L293.749,346.514z M409.681,235.74c-0.086-6.28-5.203-11.312-11.464-11.312c-0.053,0-0.106,0-0.16,0.001
			c-6.333,0.086-11.396,5.291-11.31,11.624l0.491,35.881c-11.254-3.452-20.524-11.921-24.606-23.673
			c-6.818-19.62,3.598-41.129,23.218-47.947c19.629-6.82,41.132,3.606,47.946,23.218l0,0c6.838,19.679-3.571,41.368-23.624,48.077
			L409.681,235.74z"/>
	</g>
</g>

</svg>

    </div>
  </div>
</div>
