<div class="wrap mt-4">
  <div class="container py-3">
    <div class="row p-3">
      <app-cms-page-content carousel class="col" *ngIf="id" [pageId]="id" [showNotFound]="false"></app-cms-page-content>
    </div>
  </div>
  <div class="container px-3">
    <div class="bottom-content mb-5 px-4">
      <app-cms-page-content carousel class="col" *ngIf="id_bottom" [pageId]="id_bottom" [showNotFound]="false"></app-cms-page-content>
    </div>
  </div>
</div>
