import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HelpdeskService } from "../services/helpdesk.service";
import { AccountService } from '../services/account.service';

@Injectable({
  providedIn: 'root'
})
export class IsHelpdeskUserGuard implements CanActivate {
  constructor(
    private helpdeskService: HelpdeskService,
    private accountService: AccountService,
    private router: Router
    ) {}
  accountType: string;
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const selectedDealer = this.helpdeskService.getSelectedDealer();
    this.accountType = this.accountService.getAccountType()
    if (this.accountType === 'HLP') {
      return true
    } else {
      this.router.navigateByUrl("/");
    }
  }
}
