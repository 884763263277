<div class="container px-3">
  <img src="./assets/images/Accentry-logo-dark.svg">
  <h1 class="my-3">{{ "OUTDATED_BROWSER" | translate }}</h1>
  <div class="row mx-0">
    <div class="col-12 px-0 my-2">
      <p>{{ "OUTDATED_BROWSER_EXPLANATION" | translate }}</p>
    </div>
    <div class="card col text-center">
      <img class="card-img-top img-fluid mt-2 logo" src="./assets/images/chrome.svg" alt="Google Chrome" style="width: 100px!important; height: 170px">
      <h5 class="card-title">Google Chrome</h5>
      <p class="card-text"></p>
      <a href="https://www.google.com/chrome" class="btn btn-primary m-2">Download</a>
    </div>
    <div class="card col text-center">
      <img class="card-img-top img-fluid mt-2 logo" src="./assets/images/firefox.svg" alt="Mozilla Firefox" style="width: 100px!important; height: 170px" >
      <h5 class="card-title">Mozilla Firefox</h5>
      <p class="card-text"></p>
      <a href="https://www.firefox.com/" class="btn btn-primary m-2">Download</a>
    </div>
    <div class="card col text-center">
      <img class="card-img-top img-fluid mt-2 logo" src="./assets/images/edge-logo.png" alt="Microsoft Edge" style="width: 250px!important; height: 170px" >
      <h5 class="card-title">Microsoft Edge</h5>
      <p class="card-text"></p>
      <a href="https://www.microsoft.com/edge" class="btn btn-primary m-2">Download</a>
    </div>
  </div>
</div>
