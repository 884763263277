<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container">
  <div class="row px-3">
    <div class="col-md-12 px-3">
      <h1><app-header-icon iconName="face"></app-header-icon>{{ 'MANAGEUSERS' | translate}}</h1>
      <div class="mb-3 row seachBar mx-0 p-3">
        <label class="col-sm-1 col-form-label">{{ 'SEARCH'|translate }}</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" placeholder="" [(ngModel)]="model" (blur)="selectedIndex = -1"
            (keyup)="selectedIndex = -1">
        </div>
        <div class="col-sm-2 mt-3 mt-md-0">
          <button class="btn btn-primary " (click)="newUser()" *ngIf="usersOverview">{{ 'CREATE_USER' | translate }}</button>
        </div>
      </div>
      <ul class="list-group mb-5">
        <li *ngFor="let user of users | SearchUserPipe:'username':model; let i = index" class="list-group-item py-1"
          [ngClass]="selectedIndex === i ? 'active' : ''">
          <div class="row align-items-center">
            <span class="col-12 d-sm-none">
              <h5>{{user.username}}</h5>
            </span>
            <div class="col-2 col-md-5 d-none d-sm-inline-flex align-items-center" (click)="editUser(user, i)">
              <span class="col-md-6 d-flex align-items-center">
                <i class="material-icons" *ngIf="user.owner" [ngbTooltip]="'OWNER' | translate" placement="top">
                  supervised_user_circle
                </i>

                {{user.username}}
              </span>

              <span class="col-md-4 " *ngIf="user.okta"><i class="material-icons">how_to_reg</i>
                <small>Okta</small>
              </span>
            </div>
            <div class="col-8 col-md-5 d-flex" (click)="editUser(user, i)">
              <span>{{user.profile_name}}</span>
              <span class="col-md-4 ps-2 d-flex align-items-center" *ngIf="user.linked" [attr.title]="'LINKED_ACCOUNT'|translate"><i class="material-icons">link</i>
                <small>{{user.linked}}</small>
              </span>
              <small class="badge bg-warning d-sm-none" *ngIf="user.okta">Okta</small>
              <small class="badge bg-success d-sm-none ms-1" *ngIf="user.owner" [ngbTooltip]="'OWNER' | translate"
                placement="top">{{'OWNER' | translate}}</small>
            </div>

            <div class="col-4 col-md-2 d-flex flex-row-reverse align-items-center">
              <i class="material-icons clickable withOpacity text-danger" (click)="deleteUser(user, i)"
              *ngIf="!user.owner">delete_forever</i>
              <i class="material-icons clickable withOpacity me-1" (click)="editUser(user, i)">edit</i>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
