<div class="container ps-0 ps-xxl-1" (mouseenter)="showItems = true">
  <div class="col text-start">
    <div
      ngbDropdown
      class="d-inline-block position-relative"
      *ngFor="let productGroup of browseGroups"
      (mouseleave)="productGroup.open = false"
    >
      <button
        type="button"
        class="btn btn-outline-primary catalogItem border-0 me-2"
        [id]="productGroup.name"
        ngbDropdownToggle
        (mouseenter)="productGroup.open = true"
      >
        {{ productGroup.description }}
      </button>
      <ng-container *ngIf="showItems">
        <div
          ngbDropdownMenu
          [attr.aria-labelledby]="productGroup.name"
          class="mt-0 py-0"
          [class.show]="productGroup.open"
          [class.longlist]="productGroup.sub_items?.length > 20"
        >
          <ng-container *ngFor="let articleGroup of productGroup.sub_items">
            <div
              ngbDropdown
              class="d-block w-100"
              *ngIf="articleGroup.sub_items?.length"
              (mouseleave)="articleGroup.open = false"
            >
              <button
                ngbDropdownToggle
                class="btn btn-outline-primary catalogItem border-0 w-100 text-start"
                (click)="
                  selectGroup(productGroup.name, articleGroup.name);
                  productGroup.open = false
                "
                (mouseenter)="articleGroup.open = true"
              >
                {{ articleGroup.description }}
              </button>
              <div
                ngbDropdownMenu
                [attr.aria-labelledby]="articleGroup.name"
                class="mt-0 position-absolute py-0"
                style="left: 100%"
                [style.top]="
                  articleGroup.sub_items.length > 2
                    ? '-50px'
                    : articleGroup.sub_items.length == 2
                    ? '-20px'
                    : '-10px'
                "
                [class.show]="articleGroup.open"
              >
                <ng-container
                  *ngFor="let articleSubGroup of articleGroup.sub_items"
                >
                  <div
                    ngbDropdown
                    class="d-block w-100"
                    *ngIf="articleSubGroup.sub_items?.length"
                    (mouseleave)="articleSubGroup.open = false"
                  >
                    <button
                      ngbDropdownToggle
                      class="btn btn-outline-primary border-0 w-100 text-start"
                      (click)="
                        selectGroup(
                          productGroup.name,
                          articleGroup.name,
                          articleGroup.name
                        );
                        articleGroup.open = false
                      "
                      (mouseenter)="articleSubGroup.open = true"
                    >
                      {{ articleSubGroup.description }}
                    </button>
                    <div
                      ngbDropdownMenu
                      [attr.aria-labelledby]="articleSubGroup.name"
                      class="mt-0 position-absolute py-0"
                      style="left: 100%"
                      [style.top]="
                        articleSubGroup.sub_items.length > 2
                          ? '-50px'
                          : articleSubGroup.sub_items.length == 2
                          ? '-20px'
                          : '-10px'
                      "
                      [class.show]="articleSubGroup.open"
                    >
                      <button
                        *ngFor="let subSubGroup of articleSubGroup.sub_items"
                        class="btn btn-outline-primary border-0 w-100 text-start"
                        (click)="
                          selectGroup(
                            productGroup.name,
                            articleGroup.name,
                            articleSubGroup.name,
                            subSubGroup.name
                          );
                          articleSubGroup.open = false
                        "
                      >
                        {{ subSubGroup.description }}
                      </button>
                    </div>
                  </div>

                  <button
                    *ngIf="!articleSubGroup.sub_items?.length"
                    class="btn btn-outline-primary border-0 w-100 text-start"
                    (click)="
                      selectGroup(
                        productGroup.name,
                        articleGroup.name,
                        articleSubGroup.name
                      );
                      productGroup.open = false;
                      articleGroup.open = false
                    "
                  >
                    {{ articleSubGroup.description }}
                  </button>
                </ng-container>
              </div>
            </div>
            <button
              class="btn btn-outline-primary border-0 w-100 text-start"
              (click)="
                selectGroup(productGroup.name, articleGroup.name);
                productGroup.open = false;
                articleGroup.open = false
              "
              *ngIf="!articleGroup.sub_items?.length"
            >
              {{ articleGroup.description }}
            </button>

          </ng-container>
          <button
          class="btn btn-outline-primary border-0 w-100 text-start fw-bold"
          (click)="
            selectGroup(productGroup.name);
            productGroup.open = false
          "
        >
          {{ "VIEW_ALL" | translate }}
        </button>
        </div>

      </ng-container>
    </div>
    <div
      class="d-inline-block position-relative ng-star-inserted dropdown"
      ngbDropdown
      (mouseleave)="showPromotions = false"
    >
      <button
        ngbDropdownToggle
        class="dropdown-toggle btn btn-outline-primary catalogItem border-0 me-2"
        (mouseenter)="getPromotions()"
      >
        {{ "PROMOTIONS" | translate }}
      </button>

      <div ngbDropdownMenu class="mt-0 py-0" [class.show]="showPromotions">
        <ng-container *ngIf="promotions && promotions.length">
          <ng-container *ngFor="let groupedPromotions of promotions">
            <button
              class="btn btn-outline-primary border-0 w-100 text-start"
              *ngFor="let promotion of groupedPromotions"
              [innerHTML]="
                promotion.title === ''
                  ? promotion.promotioncode
                  : promotion.title
              "
              (click)="
                gotoSearchPage(
                  'CMN_PROM&HOMEPROMO=' +
                    promotion.promotioncode +
                    '&COMPANY=' +
                    +promotion.company_id,
                  $event
                )
              "
            ></button>
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="promotions && !promotions.length && !promotionsLoaded"
        >
          <button class="px-3 py-1 dropdown-item btn">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </ng-container>
        <button
          class="btn btn-outline-primary border-0 w-100 text-start fw-bold"
          *ngIf="promotionsLoaded"
          (click)="gotoSearchPage('CMN_PROM', $event)"
        >
          {{ "VIEW_ALL" | translate }}
        </button>
      </div>
    </div>
    <div class="d-inline-block position-relative ng-star-inserted dropdown">
      <button
        class="dropdown-toggle btn btn-outline-primary catalogItem border-0 hide-toggle me-2"
        (click)="gotoSearchPage('CMN_OLD,CMN_BOM', $event)"
        *ngIf="showBom"
      >
        {{ "BOM" | translate }}
      </button>
    </div>
  </div>
</div>
