import { Injectable } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor() {}

  private showNotification(icon: string, message: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      target: '#alertContainer',
      timer: 3000,
      timerProgressBar: true,
      customClass: {
        container: 'toastContainer',
      },
      onOpen: (toast: any) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon,
      title: message,
    });
  }

  showInfoNotification(message: string | object) {
    if (typeof message === 'object') {
      this.showNotification('success', message['message']);
    } else {
      this.showNotification('success', message);
    }
  }

  showDangerNotification(
    message: string,
    timeout: number = 3000,
    iconFile: string = ''
  ) {
    this.showNotification('info', message);
  }

  showErrorNotification(message: string) {
    this.showNotification('error', message);
  }

  showPopup(message: string, icon: string = 'success', timer = 3000) {
    Swal.fire({
      position: 'center',
      icon: icon,
      title: message,
      target: '#modalContainer',
      showConfirmButton: false,
      backdrop: false,
      showCloseButton: true,
      timer: timer,
    });
  }

  showActionPopup(
    title: string,
    message: string,
    icon: string = 'success',
    textSuccess: string,
    textCancel: string,
    actionSuccess: Function,
    actionCancel?: Function
  ) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success mx-1',
        cancelButton: 'btn btn-dark mx-1',
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        position: 'bottom',
        html: message,
        backdrop: false,
        target: '#modalContainer',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: textCancel,
        reverseButtons: true,
      })
      .then((result: any) => {
        if (result.value) {
          actionSuccess();
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      });
  }

  closePopup() {
    Swal.close();
  }
}
