import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from "@angular/core";
import { NgxHmCarouselBreakPointUp } from "ngx-hm-carousel";
import { ApiResponse } from "src/app/interfaces/api-response";
import { CmsService } from "src/app/services/cms.service";
import { Widget } from "src/app/interfaces/cms/widget";
import { ApplicationService } from "src/app/services/application.service";
import { TrackingService } from "src/app/services/tracking.service";
import { Campaign } from "src/app/interfaces/tracking/campaign";
import { Router } from "@angular/router";
import SwiperCore, {
  A11y,
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Swiper,
} from "swiper";
import { CacheService } from "src/app/services/cache.service";
import { CacheType } from "src/app/interfaces/cache-tracker";
import * as _ from "underscore";
import { register } from 'swiper/element/bundle';
@Component({
  selector: 'app-promotions-carousel',
  templateUrl: './promotions-carousel.component.html',
  styleUrls: ['./promotions-carousel.component.scss'],
})
export class PromotionsCarouselComponent {
  @ViewChild('swiper') swiper: any;
  @Output() widgetsLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() autoload: boolean;
  widgets: Widget[] = [];
  loaded = false;
  allWidgets: any[];
  interval = null;
  intervalDuration = 2500;
  orderWidget: Widget = null;
  constructor(
    private cmsService: CmsService,
    private applicationService: ApplicationService,
    private trackService: TrackingService,
    private router: Router,
    private cacheService: CacheService
  ) {}

  ngOnInit() {
    if (this.autoload) {
      this.getWidgets();
    }
  }

  ngAfterViewChecked() {
    register();

    const swiperEl = document.querySelector('.promotions-carousel');
    if (swiperEl && !swiperEl.classList.contains("swiper-initialized")) {
      const params = {
        injectStylesUrls: ['/assets/carousel.css'],
      };

      Object.assign(swiperEl, params);

      swiperEl['initialize']();
    }
  }

  ngAfterViewInit(): void {
    //Swiper instance will be displayed in console
  }

  ngOnDestroy() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  }

  refresh(event) {
    if (event.altKey) {
      this.getWidgets();
      event.preventDefault();
      return false;
    }
    return false;
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  getWidgets() {
    if (this.cacheService.needRefresh(CacheType.widgets)) {
      this.cacheService.widgets = [];
      this.cmsService.getWidgets().subscribe((apiResponse: ApiResponse) => {
        this.loaded = true;
        this.cacheService.dataLoaded(CacheType.widgets);
        if (apiResponse && apiResponse.success && apiResponse.result) {
          if (apiResponse.result.items && apiResponse.result.items.length) {
            let allWidgets = [...apiResponse.result.items];
            let visibleWidgets = [];

            // Ugly solution for the ugly Winora / Ghost widget
            if (
              ['WG', 'GHO'].includes(
                this.applicationService.getSelectCompanyGroupCode()
              )
            ) {
              visibleWidgets = this.sortWidgets(allWidgets);
            } else {
              visibleWidgets = allWidgets;
            }

            sessionStorage.setItem(
              'widgets-' + this.applicationService.getSelectCompanyGroupCode(),
              JSON.stringify(visibleWidgets)
            );
            this.cacheService.widgets = visibleWidgets;
            this.cacheService.allWidgets = [...apiResponse.result.items];
            this.widgets = visibleWidgets;
            if (this.widgets.length) {
              this.widgetsLoaded.emit(true);
            }
            this.trackWidgets();
          }
        } else {
          this.cacheService.widgets = [];
          this.widgets = [];
        }
      });
    } else {
      this.widgets = this.cacheService.widgets;
      if (this.widgets.length) {
        this.widgetsLoaded.emit(true);
      }

      if (
        ['WG', 'GHO'].includes(
          this.applicationService.getSelectCompanyGroupCode()
        ) &&
        this.cacheService.allWidgets?.length
      ) {
        this.widgets = this.sortWidgets(this.cacheService.allWidgets);
      }
      this.trackWidgets();
    }
  }

  sortWidgets(widgets) {
    const allWidgets = [...widgets];
    const orderWidget = _.findIndex(allWidgets, {
      id:
        this.applicationService.getSelectCompanyGroupCode() === 'WG'
          ? '785'
          : '784',
    });
    if (orderWidget >= 0) {
      this.orderWidget = widgets[orderWidget];
      allWidgets.splice(orderWidget, 1);
      return allWidgets;
    } else {
      return allWidgets;
    }
  }

  trackWidgets() {
    let campaigns = Array<Campaign>();
    if (this.widgets) {
      for (let i = 0; i < this.widgets.length; i++) {
        const widget = this.widgets[i];
        this.widgets[i].position = i;
        campaigns.push(
          new Campaign(
            widget.id,
            widget.language_definitions[0].title,
            i,
            widget.language_definitions[0].link_url
          )
        );
      }
      this.trackService.campaign('promoView', campaigns);
    }
  }

  async gotoUrl(widget: Widget) {
    let internalUrl = false;
    const url = widget.language_definitions[0].link_url;
    const urlType = widget.language_definitions[0].url_type;
    let formattedUrl = url;

    if (url && url.indexOf('accentry.com') >= 0) {
      internalUrl = true;
      formattedUrl = url.replace('https:', 'protocol:');
      formattedUrl = formattedUrl.replace('http:', 'protocol:');
      if (formattedUrl.includes('alpha.')) {
        formattedUrl = formattedUrl.replace('protocol://alpha.accentry.com/', '');
      } else if (formattedUrl.includes('beta.')) {
        formattedUrl = formattedUrl.replace('protocol://beta.accentry.com/', '');
      } else if (formattedUrl.includes('shop.')) {
        formattedUrl = formattedUrl.replace('protocol://shop.accentry.com/', '');
      } else if (formattedUrl.includes('classic.')) {
        formattedUrl = formattedUrl.replace('protocol://classic.accentry.com/', '');
      } else {
        formattedUrl = formattedUrl.replace('protocol://www.accentry.com/', '');
      }

      if (
        formattedUrl.indexOf('search#') >= 0 &&
        formattedUrl.indexOf('filter') < 0
      ) {
        formattedUrl = formattedUrl.replace('search#', 'articleDetails/');
      }

      if (
        formattedUrl.indexOf('results#') >= 0 &&
        formattedUrl.indexOf('filter') < 0
      ) {
        formattedUrl = formattedUrl.replace('results#', 'articleDetails/');
      }
    }

    if (urlType === 1) {
      internalUrl = true;
      formattedUrl = `${this.applicationService.getSelectCompanyGroupCode()}/companyPage/${formattedUrl}`;
    }

    if (urlType === 2) {
      internalUrl = true;
      formattedUrl = `${this.applicationService.getSelectCompanyGroupCode()}/news/${formattedUrl}`;
    }

    this.trackService.campaign('promoClick', [
      new Campaign(
        widget.id,
        widget.language_definitions[0].title,
        widget.position,
        formattedUrl
      ),
    ]);

    if (internalUrl) {
      formattedUrl = formattedUrl.replace('results', 'search');
      formattedUrl = formattedUrl.replace('news#details/', 'news/');
      if (formattedUrl.includes('search')) {
        formattedUrl += '&widgetId=' + widget.id;
      }

    await this.router.navigateByUrl('.', { skipLocationChange: true });
    this.router.navigateByUrl(formattedUrl);
   
     
    } else if (formattedUrl) {
      window.open(formattedUrl, '_new');
    }
  }
}
