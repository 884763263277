<div class="d-flex d-sm-flex flex-wrap" [ngClass]="!article.single_article_code ? 'noSelection' : ''">
  <span *ngIf="article.temporarily_undeliverable || modelTemporarilyUndeliverable" class="d-block text-danger w-100">
    {{"TEMPORARILY_UNDELIVERABLE"|translate}}
  </span>
  <ng-container *ngFor="let option of article.matrix.options">
    <div class="d-inline-block" [ngClass]="[
        option.value.length > 1 ? 'w-100' : '',
        option.value.length >= 5 && article.matrix.options.length > 1
          ? 'long'
          : ''
      ]">
      <label class="d-block mt-2" *ngIf="article.matrix.options.length > 1 && option.value.length > 1">{{
        option.description }}</label>
      <div class="d-flex flex-wrap">
        <ng-container *ngFor="let optionArticle of option.value">
          <button [style.display]="optionArticle.enabled ? '' : 'none'"
            class="btn btn-default articleRowLabel align-items-center" placement="top" ngbTooltip="{{
              (optionArticle | statusText : false : false) +
                ' - ' +
                ('ARTICLE_NO' | translate) +
                ' ' +
                optionArticle.single_article_code
            }}" [ngClass]="[
              optionArticle.single_article_code === article.single_article_code
                ? 'btn-default selected'
                : 'btn-default',
              'status_' + optionArticle.stock_status,
              'stockweek_' + optionArticle.stock_week,
              'company_' + article.company_id,
              optionArticle.enabled ? 'd-inline-flex' : 'd-none'
            ]" (click)="selectArticle(optionArticle)">
            {{
            option.value.length > 1 ? optionArticle.value : option.description
            }}
            <i></i>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div class="d-inline-flex">
    <i class="material-icons d-inline-flex align-items-center clickable withOpacity expander"
      (click)="expand = !expand"> {{expand?"expand_less":"expand_more"}}</i>
  </div>
  <ng-container *ngIf="expand">
    <table class="table table-striped mt-2 stock-list w-100 border bg-white overview-table">
      <ng-container *ngFor="let option of article.matrix.options">
        <tr class="px-2 clickable">
          <ng-container *ngFor="let optionArticle of option.value">
            <td class="ps-2" (click)="selectArticle(optionArticle)" [ngClass]="[
            optionArticle.single_article_code === article.single_article_code
              ? 'fw-bold'
              : '']">{{
              option.description }}</td>
            <td (click)="selectArticle(optionArticle)" [ngClass]="[
              optionArticle.single_article_code === article.single_article_code
                ? 'fw-bold'
                : '']">
              {{

              optionArticle.single_article_code
              }}</td>
            <td (click)="selectArticle(optionArticle)" [ngClass]="[
            optionArticle.single_article_code === article.single_article_code
              ? 'fw-bold'
              : '']">{{
              (optionArticle | statusText : false : false)}}</td>

          </ng-container>
        </tr>
      </ng-container>
    </table>
  </ng-container>
</div>
