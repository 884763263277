export interface PackingSlipsFilterOptions {
  keyWordOptions: any[];
  soldOptions: any[];
  articleKindOptions: any[];
}

export class PackingSlipsFilterOptions implements PackingSlipsFilterOptions {
  constructor() {
    this.keyWordOptions = [
      {
        value: "PackSlipNumber",
        description: "PACKINGSLIP_NUMBER"
      },
      {
        value: "OrderNumber",
        description: "ORDERNUMBER"
      },
      {
        value: "OrderNumber_Accentry",
        description: "ORDERNUMBER_ACCENTRY"
      },
      {
        value: "OrderReference_Accentry",
        description: "REFERENCE"
      },
      {
        value: "ArticleNo",
        description: "ARTICLE_NO"
      }
    ];
  }
}
