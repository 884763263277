<header class="d-flex justify-content-around">
  <img
    class="logo"
    src="./assets/images/Accentry-logo-white.svg"
    alt="Accentry"
    title="Accentry"
  />
  <span class="close" (click)="close()">
    <i class="material-icons">cancel</i>
  </span>
</header>

<div
  class="swiperContainer d-flex flex-column"
  (panmove)="onPanMove($event)"
  (panend)="onPanUp($event)"
  (swipeleft)="swipeleft()"
  (swiperight)="swiperight(article)"
  [ngStyle]="{ transform: 'translateX(' + distanceX + 'px)' }"
>
  <div
    class="swipe-card"
    [@cardAnimator]="animationState"
    (@cardAnimator.done)="resetAnimationState($event)"
    [ngStyle]="{ transform: 'rotate(' + rotation + 'deg)' }"
  >
    <div class="w-100 text-center">
      <img [src]="article.images[0]" />
    </div>
    <h1>{{ article.description }}</h1>
    <p>{{ article.caption }}</p>
    <app-stock-status [article]="article" [showText]="true"></app-stock-status>
    <h4 class="mt-2" *ngIf="article.retail_price">
      <app-retail-price
        [price]="article.retail_price"
        [showLabel]="true"
      ></app-retail-price>
    </h4>
    <h4
      class="mt-2"
      *ngIf="!article.hide_nett_price"
    >
      <app-nett-price
        [price]="article.net_price"
        [hasDifferentPrices]="article.has_different_prices"
        [showLabel]="true"
      >
      </app-nett-price>
    </h4>
  </div>
</div>
<div class="controls d-flex w-100 justify-content-center align-items-center">
  <i class="material-icons lg" (click)="swipeleft()">clear</i>
  <i class="material-icons sm mx-2" (click)="openModal(article)">info</i>
  <i class="material-icons lg" (click)="swiperight(article)">favorite</i>
</div>
