<h3 class="mb-1">{{'BATTERY'|translate}}</h3>
<div class="btn-group btn-group-toggle py-2 d-flex gap-2" [ngClass]="formGroup.get('batteryType').invalid ? 'border border-danger pl-2' : ''"
  (change)="typeChanged($event)">
  <button class="btn" (click)="typeChanged('nimh_battery')" [ngClass]="tabSelected === 'nimh_battery' ? 'btn-dark' : 'btn-primary'">
    {{'NIMH_BATTERY'|translate}}
  </button>
  <button class="btn" (click)="typeChanged('motor')" [ngClass]="tabSelected === 'motor' ? 'btn-dark' : 'btn-primary'">
    {{'MOTOR'|translate}}
  </button>
  <button class="btn" (click)="typeChanged('liion_battery')" [ngClass]="tabSelected === 'liion_battery' ? 'btn-dark' : 'btn-primary'">
    {{'LIION_BATTERY'|translate}}
  </button>
</div>
<div *ngIf="tabSelected">
  <div class="mb-3 row">
    <label class="col-md-4 control-label text-start">{{'SERIALNO'|translate}} <ng-container *ngIf="tabSelected === 'motor'; else battery">{{'MOTOR'|translate|lowercase}}</ng-container><ng-template #battery>{{'BATTERY'|translate|lowercase}}</ng-template> *</label>
    <div class="col-md-6" [formGroup]="formGroup">
      <input type="text" class="form-control" formControlName="serialNumber">
    </div>
  </div>
  <div class="mb-3 row" *ngIf="tabSelected !== 'motor'">
    <label class="col-md-4 control-label text-start">{{'BATTERY_IS_DAMAGED'|translate}}</label>
    <div class="col-md-6">
      <div class="form-check form-switch">
      <input type="checkbox" class="form-check-input" id="damaged"
        (change)="damageSwitchChanged(false)" [(ngModel)]="damaged">
      <label class="form-check-label" for="damaged"></label>
    </div>
    </div>
    <div  class="col-md-10">
      <ngb-alert [type]="'danger'" *ngIf="damaged">{{'CONTACT_EBSC'|translate}}</ngb-alert>
    </div>
  </div>
  <div class="mb-3 row" *ngIf="tabSelected === 'liion_battery'">
    <label class="col-md-4 control-label text-start">{{'BATTERY_KIND'|translate}}</label>
    <div class="col-md-6" [formGroup]="formGroup">
      <select class="form-control" formControlName="batteryKind">
        <option value="main_battery">{{'MAIN_BATTERY'|translate}}</option>
        <option value="range_extender">{{'RANGE_EXTENDER'|translate}}</option>
      </select>
    </div>
  </div>
</div>
