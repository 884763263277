export interface SelectedForWarrantyShipment {
  lease: boolean;
  regular: boolean;
  batteries: boolean;
  motors: boolean;
}
export class SelectedForWarrantyShipment
  implements SelectedForWarrantyShipment {
  warrantyId: number;

  constructor(warrantyId: number, hasLease: boolean, hasRegular: boolean, hasBatteries: boolean, hasMotors: boolean) {
    this.warrantyId = warrantyId;
    this.lease = this.getSelection("lease", hasLease);
    this.regular = this.getSelection("regular", hasRegular);
    this.batteries = this.getSelection("batteries", hasBatteries);
    this.motors = this.getSelection("motors", hasMotors);
  }

  private getSelection(type: string, enable: boolean) {
    const value = sessionStorage.getItem(`${type}_${this.warrantyId}`);
    return value === "0" || !enable ? false : true;
  }

  public saveSelection(type: string, warrantyId: number, value: boolean) {
    sessionStorage.setItem(`${type}_${warrantyId}`, value ? "1" : "0");
  }
}
