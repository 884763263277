<label class="filterHeader" style="font-size: 110%">{{
  "GROUPS" | translate
}}</label>
<ul class="list-unstyled mb-0">
  <li>
    <span (click)="resetProductGroups()">{{ "ALL_GROUPS" | translate }}</span>
  </li>
  <li *ngFor="let group of groups; let i = index" class="treeItem">
    <span (click)="selectProductGroup(group, true)"
      >{{ group.description }}
      <ng-container *ngIf="group.doc_count">
        ({{ group.doc_count }})</ng-container
      ></span
    >
    <ul class="list-unstyled ps-1">
      <li
        class="treeItem"
        *ngFor="let article_group of group.sub_groups; let i = index"
        [ngStyle]="{
          'font-size': group.sub_groups.length > 5 ? '90%' : ''
        }"
      >
        <span
          (click)="
            selectProductGroup(group); selectArticleGroup(article_group, true)
          "
          >{{ article_group.description }}
          <ng-container *ngIf="article_group.doc_count"
            >({{ article_group.doc_count }})</ng-container
          ></span
        >
        <ul class="list-unstyled ps-1">
          <li
            class="treeItem"
            *ngFor="
              let article_sub_group of article_group.sub_groups;
              let i = index
            "
          >
            <span
              (click)="
                selectProductGroup(group);
                selectArticleGroup(article_group);
                selectArticleSubGroup(article_sub_group, true)
              "
              >{{ article_sub_group.description }}
              <ng-container *ngIf="article_sub_group.doc_count"
                >({{ article_sub_group.doc_count }})</ng-container
              ></span
            >
            <ul class="list-unstyled ps-1">
              <li
                class="treeItem"
                *ngFor="
                  let article_variation_group of article_sub_group.sub_groups;
                  let i = index
                "
              >
                <span
                  (click)="
                    selectProductGroup(group);
                    selectArticleGroup(article_group);
                    selectArticleSubGroup(article_sub_group);
                    selectArticleVariationGroup(article_variation_group, true)
                  "
                  >{{ article_variation_group.description }} ({{
                    article_variation_group.doc_count
                  }})</span
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </li>
</ul>
