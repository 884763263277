<div class="row">
  <div class="col-12 px-0">
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [theme]="{ height: '160px' }"
    >
    </ngx-skeleton-loader>
  </div>
  <div class="col-12 col-sm-9 col-md-8 col-lg-9 px-0">
    <ngx-skeleton-loader
      count="20"
      appearance="line"
      [theme]="{ height: '80px' }"
    >
    </ngx-skeleton-loader>
  </div>
  <div class="col-12 col-sm-3 col-md-4 col-lg-3 pe-0">
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      [theme]="{ height: '120px' }"
    >
    </ngx-skeleton-loader>
  </div>
</div>
