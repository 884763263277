import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KioskService {
  // Kioskmode emitters
  private emitKiosk = new Subject<any>();
  public emitExitKioskMode$ = this.emitKiosk.asObservable();

  // Emit search query to search component
  private searchInputSource = new BehaviorSubject('');
  public searchInput = this.searchInputSource.asObservable()

  // Emit hiding searchbar after searching
  private emitHideSearchBar = new Subject<any>()
  public hideSearchBar = this.emitHideSearchBar.asObservable()

  public comparedArticlesValue = new BehaviorSubject(this.comparedArticles);
  pinStorageKey = "k-p";

  set comparedArticles(value) {
    this.comparedArticlesValue.next(value);
    sessionStorage.setItem('comparedArticles', JSON.stringify(value));
  }

  get comparedArticles() {
    return JSON.parse(sessionStorage.getItem('comparedArticles'));
  }

  constructor() { }

  exitKioskMode() {
    this.emitKiosk.next(true);
  }

  emitSearchInput(searchInput: string) {
    this.searchInputSource.next(searchInput)
  }

  hideSearchBarEmitter() {
    this.emitHideSearchBar.next(false)
  }

  getPinCode() {
    return localStorage.getItem(this.pinStorageKey) ? window.atob(localStorage.getItem(this.pinStorageKey)) : null;
  }

  updatePinCode(value: string) {
    return localStorage.setItem(this.pinStorageKey, window.btoa(value));
  }
}
