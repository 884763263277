<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container">
  <div class="row">
    <div class="col-md-12 px-3">
      <h1><app-header-icon iconName="assignment_turned_in"></app-header-icon>{{'YEARAGREEMENT' | translate}} ({{(type === "bikes" ? "BICYCLES" : "PARTS_ACCESSORIES") | translate}}) &centerdot; {{currentYear}}</h1>
      <app-year-agreement-container (agreementCreated)="currentYear = $event" *ngIf="type === 'bikes'"></app-year-agreement-container>
      <app-year-agreement-container-parts (agreementCreated)="currentYear = $event" *ngIf="type === 'parts'"></app-year-agreement-container-parts>
      <app-year-agreement-pand-a (agreementCreated)="currentYear = $event" *ngIf="type === 'pa'"></app-year-agreement-pand-a>
    </div>
  </div>
</div>
