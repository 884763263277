<div
  class="tableContainer p-2 position-relative"
  *ngIf="
    (yearAgreementModel[type]['turnover_history'] &&
      yearAgreementModel[type]['turnover_history'][year]) ||
    !readonly
  "
>
  <table class="table mb-0">
    <thead>
      <tr>
        <th>
          <h2 class="mb-0">{{ year }}</h2>
        </th>
        <th *ngFor="let brand of distributionBrands">
          {{ brand.toUpperCase() | translate }}
        </th>
        <th *ngIf="readonly"></th>
      </tr>
    </thead>
    <tbody>

      <!-- DISTRIBUTION -->
      <tr *ngIf="overviewType === 'distribution'">
        <td>{{ "AMOUNT" | translate }}</td>
        <td *ngFor="let brand of distributionBrands">
          <input
            *ngIf="!readonly"
            type="number"
            min="0"
            step="10000"
            class="form-control"
            [(ngModel)]="yearAgreementModel[type]['turnover_selection'][brand]"
            (change)="calcTotal()"
          />
          <ng-container *ngIf="readonly">
            {{
              yearAgreementModel[type]["turnover_history"][year][brand]
                | numberFormat: ""
            }}
          </ng-container>
        </td>
        <td class="text-end">
          <ng-container *ngIf="readonly">
            {{
              yearAgreementModel[type]["turnover_history"][year]["total"]
                | numberFormat: "&euro;"
            }}
          </ng-container>
          <input
            type="text"
            [ngClass]="!inputValid ? 'text-danger distributionInvalid' : ''"
            *ngIf="!readonly"
            [value]="sum | numberFormat: '&euro;'"
            readonly
            class="form-control mb-1 text-end "
          />
        </td>
      </tr>

      <!-- PLANNING -->
      <tr *ngIf="overviewType === 'planning'">
        <td>{{ "AMOUNT" | translate }}</td>
        <td *ngFor="let brand of distributionBrands">
          {{yearAgreementModel[type]['turnover_selection'][brand] * (planningPercentage / 100)  | numberFormat: ''}}
        </td>
        <td class="text-end">
          {{sum * (planningPercentage / 100) | numberFormat: '&euro;'}}
        </td>
      </tr>

      <!-- PLANNING backorder -->
      <tr *ngIf="overviewType === 'planning'">
        <td>{{ "BACKORDER_HEADER" | translate }}</td>
        <td *ngFor="let brand of distributionBrands">
          {{getBrandBackOrder(brand) | numberFormat: "" }}
        </td>
        <td class="text-end">
         {{getTotalBackOrder() | numberFormat: '&euro;'}}
        </td>
      </tr>

      <!-- PLANNING total -->
      <tr *ngIf="overviewType === 'planning'">
        <td class="total"><b>{{ "TOTAL" | translate }}</b></td>
        <td *ngFor="let brand of distributionBrands" class="total">
          {{getBrandTotal(brand) | numberFormat: "" }}
        </td>
        <td class="text-end fw-bold total">
         {{getTotalMinusBackOrder() | numberFormat: '&euro;'}}
        </td>
      </tr>
    </tbody>
  </table>
  <span
    class="btn btn-sm btn-dark position-absolute"
    style="bottom: 10px;"
    *ngIf="!readonly && overviewType === 'distribution'"
    (click)="divide()"
    >{{ "DIVIDE" | translate }}</span
  >
  <div class="position-absolute" style="bottom: 0;">
    <ng-content></ng-content>
  </div>
</div>
