export interface OrderLineOptionInput {
  company_id: string;
  part_type: string;
  shipping_id: string;
  shipping_type: string;
  company_id_filter: number;
  show_also_main_address: boolean;
  article_type: string;
  order_by: string;
  only_new_order_lines: boolean;
  delivery_week: string;
  sold: boolean;
  workshop: boolean;
  article_code: string;
  order_line_description: string;
  date_from: string;
  date_to: string;
  accentry_id: string;
  ace_id: string;
  article_description: string;
  remarks: string;
  order_line_status: string;
  page: number;
  address_type: any;
  shipping_address_type: string;
}

export class OrderLineOptionInput implements OrderLineOptionInput {

}
