import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { Article } from "src/app/interfaces/article";
import { ArticleModalComponent } from "../../modal-components/article-modal/article-modal.component";
import { PreorderArticleModalComponent } from "../../modal-components/preorder-article-modal/preorder-article-modal.component";
import { ApplicationService } from "src/app/services/application.service";

@Component({
  selector: "app-preorder-month-input",
  templateUrl: "./preorder-month-input.component.html",
  styleUrls: ["./preorder-month-input.component.scss"],
})
export class PreorderMonthInputComponent implements OnInit {
  @Input() optionArticle: Article;
  @Input() month: any = {};
  @Input() value: number;
  @Input() inputIndex: number;
  @Input() brand: string;
  @Input() type: string;
  @Input() max: string = "99";
  @Input() min: string = "1";
  @Input() monthSelectionOnly: boolean = false;
  @Output() checkQuantity: EventEmitter<any> = new EventEmitter<any>();
  quantities = [];
  tempQuantities = [];
  companyGroup: string;
  constructor(private modalService: NgbModal, private applicationService: ApplicationService) {}

  ngOnInit() {
    this.companyGroup = this.applicationService.getSelectCompanyGroupCode();
  }

  quantityChange(data) {
    if (Number(data.target.value) > Number(this.max)) {
      data.target.value = this.max;
    }
    this.checkQuantity.emit();
  }

  firstWeek() {
    return moment(
      `01-${this.month.month}-${this.month.year}`,
      "DD-MM-YYYY"
    ).isoWeek();
  }

  openModal(article, event) {
    const modalRef = this.modalService.open(PreorderArticleModalComponent, {
      size: "lg",
      centered: true,
      backdrop: "static",
      container: "#modalContainer",
    });
    modalRef.componentInstance.article = article;
    modalRef.componentInstance.modelId = article["id"];
    modalRef.componentInstance.showDetailed = true;
    modalRef.componentInstance.preOrder = true;
    modalRef.componentInstance.showInModal = true;
    modalRef.componentInstance.month = this.month;
    modalRef.componentInstance.selectedArticle = article["single_article_code"];
    modalRef.componentInstance.article.img = article["images"];
    modalRef.componentInstance.showInstant();
    modalRef.componentInstance.initQuantities = [
      ...(this.quantities.length ? this.quantities : this.tempQuantities),
    ];
    modalRef.componentInstance.submitObserver.subscribe((response: any) => {
      this.quantities = response;
      this.checkQuantity.emit(1);
    });
    event.preventDefault();
  }

  add() {
    if (!this.tempQuantities.length || this.tempQuantities.length > 1) {
      const quantity = {
        week: this.firstWeek(),
        year: this.month.year,
        quantity: 1,
      };
      this.tempQuantities = [quantity];
    } else {
      this.tempQuantities[0]["quantity"]++;
    }
  }

  decrease() {
    if (this.tempQuantities.length || this.tempQuantities.length > 1) {
      this.tempQuantities[0]["quantity"] =
        this.tempQuantities[0]["quantity"] > 1
          ? this.tempQuantities[0]["quantity"] - 1
          : 0;
    }
  }

  closeTooltip(tooltip: NgbTooltip) {
    this.quantities = this.tempQuantities;
    tooltip.close();
  }

  get disableInputField(): boolean {

    const alwaysOrder = this.optionArticle.always_order;
    const outOfStock = this.optionArticle.stock_status >= 3;
    const noStockDate =
      this.optionArticle.stock_week === 99 ||
      this.optionArticle.stock_year === 9999;
    return !alwaysOrder && (outOfStock || noStockDate);
  }
}
