import { Component, OnInit, Input } from '@angular/core';
import { BalanceItem } from 'src/app/interfaces/financial/balance/balance-item';
import { NumberFormatPipe } from 'src/app/pipes/number-format.pipe';
import { FinancialService } from 'src/app/services/financial.service';
import { saveAs } from "file-saver";
@Component({
  selector: 'app-balance-line',
  templateUrl: './balance-line.component.html',
  styleUrls: ['./balance-line.component.scss'],
  providers: [NumberFormatPipe]
})
export class BalanceLineComponent implements OnInit {
  @Input() invoice: BalanceItem;
  constructor(public numberFormat: NumberFormatPipe, private financialService: FinancialService) { }

  ngOnInit() {
  }

  viewInvoice() {
    this.financialService
    .getInvoicesPdfFile([this.invoice.invoice_number])
    .subscribe((blob: Blob) =>
      saveAs(blob, `invoice_${this.invoice.invoice_number}.pdf`)
    );
  }

}
