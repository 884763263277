import { AcademyAccess } from "./academy-access";

export interface AccountSettings {
  account_code: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  username: string;
  okta: boolean;
  academy_access: AcademyAccess;
  profile_access: string[];
  company_group: string;
  language_code: string;
  secret_answer: string;
  secret_email: string;
  secret_question: string;
  showroom_mode: boolean;
  showroom_mode_default: boolean;
  supply_and_demand: number;
  registered: boolean;
  verified: boolean;
}

export class AccountSettings implements AccountSettings {
}
