import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ArticleGroup } from "src/app/interfaces/articleGroup";
import { GroupCollection } from "src/app/interfaces/group-collection";

@Component({
  selector: "app-groups-tree",
  templateUrl: "./groups-tree.component.html",
  styleUrls: ["./groups-tree.component.scss"]
})
export class GroupsTreeComponent implements OnInit {
  @Input() groups: ArticleGroup[] = [];
  @Output()
  groupSelected: EventEmitter<any> = new EventEmitter<any>();
  selectedGroup: any;
  constructor() {
    this.selectedGroup = new GroupCollection();
  }

  ngOnInit() {}

  resetProductGroups() {
    this.selectedGroup["article_group_codes"] = null;
    this.selectedGroup["article_sub_group_codes"] = null;
    this.selectedGroup["product_group_codes"] = null;
    this.selectedGroup["variation_group_code"] = null;
    this.groupSelected.emit(this.selectedGroup);
  }

  selectProductGroup(group, emit = false) {
    this.selectedGroup["article_group_codes"] = null;
    this.selectedGroup["article_sub_group_codes"] = null;
    this.selectedGroup["variation_group_code"] = null;
    this.selectedGroup["product_group_codes"] =
      this.selectedGroup["product_group_codes"] !== group.code
        ? group.code
        : this.selectedGroup["product_group_codes"];
        if (emit) {
          this.groupSelected.emit(this.selectedGroup);
        }
  }

  selectArticleGroup(articleGroup, emit = false) {
    this.selectedGroup["article_sub_group_codes"] = null;
    this.selectedGroup["variation_group_code"] = null;
    this.selectedGroup["article_group_codes"] =
      this.selectedGroup["article_group_codes"] !== articleGroup.code
        ? articleGroup.code
        : this.selectedGroup["article_group_codes"];
        if (emit) {
          this.groupSelected.emit(this.selectedGroup);
        }
  }

  selectArticleSubGroup(articleSubGroup, emit = false) {
    this.selectedGroup["variation_group_code"] = null;
    if (this.selectedGroup["article_sub_group_codes"] !== articleSubGroup.code) {
      this.selectedGroup["article_sub_group_codes"] = articleSubGroup.code;
      if (emit) {
      this.groupSelected.emit(this.selectedGroup);
      }
    }
  }

  selectArticleVariationGroup(articleVariationGroup, emit = false) {
    if (this.selectedGroup["variation_group_code"] !== articleVariationGroup.code) {
      this.selectedGroup["variation_group_code"] = articleVariationGroup.code;
      if (emit) {
      this.groupSelected.emit(this.selectedGroup);
      }
    }
  }
}
