export const environment = {
  shopType: window['ACC_SHOPTYPE'] ? window['ACC_SHOPTYPE'] : (process.env["ACC_SHOPTYPE"] ? process.env["ACC_SHOPTYPE"] : 'b2b'),
  storeName: window['ACC_STORENAME'] ? window['ACC_STORENAME'] : (process.env["ACC_STORENAME"] ? process.env["ACC_STORENAME"] : 'raleigh'),
  production: true,
  development: false,
  apiendpoint: "https://api-gateway.accentry.com/",
  socketapiendpoint: "https://hubs.accentry.com/notify/",
  publicapiendpoint: "https://public.web-p.accell.cloud/",
  classicUrl: "https://classic.accentry.com/login/connect?data=",
  oktaUrl: "https://accell-group.okta-emea.com/login/",
  name: "production",
  hostname: "https://shop.accentry.com/",
  blobStorageUrl: "https://accentry.blob.core.windows.net/",
  storyblokPreviewToken: "",
  storyblokAccentryPreviewToken: "",
  previewUser: {
    username: "",
    password: "",
  },
  imageBankAdmins: ["b.samson@accell.nl","marketing@winora-group.de","elias neumann","matthias fenn"],
};
