<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container">
  <ng-container *ngIf="type === 'overview'">
    <app-warranty-overview *ngIf="!id" [action]="action"></app-warranty-overview>
  </ng-container>
  <ng-container *ngIf="type === 'sendDetails' || type === 'details'">
    <app-warranty-details *ngIf="type === 'details' && id" [id]="id" [add]="add">
      <ngb-alert type="success" *ngIf="submitted">{{'WARRANTY_SENT'|translate}}</ngb-alert>
      <ngb-alert type="warning" *ngIf="submitted">{{'WARRANTY_SENT_INSTRUCTION'|translate}}</ngb-alert>
    </app-warranty-details>
    <app-warranty-send-details *ngIf="type !== 'details' && id" [id]="id" (submitted)="showSubmitted()"></app-warranty-send-details>
  </ng-container>
  <ng-container *ngIf="type === 'form'">
    <app-warranty-request-form [type]="'warranty'"></app-warranty-request-form>
  </ng-container>
  <ng-container *ngIf="type === 'aftersales'">
    <app-warranty-request-form [type]="'aftersales'"></app-warranty-request-form>
  </ng-container>
  <ng-container *ngIf="type === 'crm_overview'">
    <app-warranty-crm-overview></app-warranty-crm-overview>
  </ng-container>
  <ng-container *ngIf="type === 'crm_request'">
    <app-warranty-request-crm-form></app-warranty-request-crm-form>
  </ng-container>
</div>
