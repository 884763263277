import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../environments/environment";
import { Observable } from "rxjs";
import { ApiResponse } from "./interfaces/api-response";

export function createTranslateLoader(http: HttpClient) {
  return new AccentryTranslations(
    http,
    environment.apiendpoint + "translations/",
    ""
  );
}

class AccentryTranslations extends TranslateHttpLoader {
  httpService: HttpClient;
  storeKey: string = `translations_`;
  constructor(http: HttpClient, prefix?: string, suffix?: string) {
    super(http, prefix, suffix);
    this.httpService = http;
  }

  // Override default function to retrieve translations
  override getTranslation(lang: string = "en"): Observable<any> {

    return Observable.create(observer => {
      this.httpService
        .get(environment.apiendpoint + "translations/" + lang)
        .subscribe((res: any) => {
          observer.next(res["result"]);
          observer.complete();
        });
    });
  }
}
