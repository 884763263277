

<ng-container *ngIf="preorderSettings">
  <ng-container *ngIf="!edit">

    <div id="infoContainer" class="col-lg-12 px-2">
      <div class="py-2" *ngIf="preorderCMSPageId && showButtons">
        <app-cms-page-content [pageId]="preorderCMSPageId" *ngIf="preorderCMSPageId" [showNotFound]="false"></app-cms-page-content>
        <h3 *ngIf="applicationService.getSelectCompanyGroupCode() !== 'ANL'">{{"PREORDER"|translate}}</h3>
        <ngb-alert type="success" *ngIf="signedCondition" [dismissible]="false" class="mb-0">
          {{ "CONDITIONS_SIGNED_ON" | translate }}:
          {{ _getSignedDate(signedCondition.signed) }}</ngb-alert
        >
      </div>

      <nav class="buttons preorder-buttons border-bottom align-items-center justify-content-between" [ngClass]="{' position-fixed bg-white shadow': showButtons && (scrollPosition > 100), 'hlp' : isHelpdesk}" [ngStyle]="{'max-width': showButtons ? (buttonsMaxWidth+'px') : '100%'}">
        <div class="float-md-left" *ngIf="showButtons && !hideSaveButton">
          <select class="form-control" [(ngModel)]="preorderType" (ngModelChange)="typeChange()" *ngIf="preorderBicyclesEnabled && preorderPartsEnabled && type !== 'wishlist'">
            <option [value]="'Bicycles'" *ngIf="preorderBicyclesEnabled">{{'BIKES'|translate}}</option>
            <option [value]="'Parts'" *ngIf="preorderPartsEnabled">{{'PARTS'|translate}}</option>
          </select>
        </div>
        <ng-content select="[topButtons]"></ng-content>
        <ng-container *ngIf="showButtons && !hideSaveButton">
          <div class="float-md-right py-2" *ngIf="type !== 'wishlist'">
            <button class="btn btn-dark me-2" *ngIf="hasWebcam && type !== 'wishlist'" (click)="startScanner()">
              <img src="/assets/images/scan.svg" class="me-1" height="20px">
              {{'SCAN' | translate }}
            </button>
            <a class="btn btn-outline-primary me-2" *ngIf="!maxQuantity" (click)="showWishList()">
              <i class="material-icons me-1" style="font-size: 18px; transform: translateY(2.5px);"> folder_special</i>
              {{
              'WISHLIST' |
              translate }}</a>
            <a class="btn btn-primary" *ngIf="!maxQuantity && basketId" [routerLink]="'/' + companyGroupCode + '/preorderBasket/' + (preorderType === 'Bicycles' ? 'Bikes' : 'Parts') + '/' + basketId">
              <i class="material-icons me-1" style="font-size: 18px; transform: translateY(2.5px);"> shopping_basket</i>
              {{
              'PREORDER_BASKET' |
              translate }}</a>
          </div>
        </ng-container>
        <div *ngIf="maxQuantity">
          <ngb-alert [dismissible]="false">
            {{ 'MANDATORY_BATTERY' | translate }}. {{'SELECT'|translate}}: {{maxQuantity}}
          </ngb-alert>
        </div>
      </nav>
    </div>
    <div id="preorderArticles" *ngIf="!scanning && accountInformation && showArticles" class="px-2 mx-0 row w-100" >
      <ng-container *ngFor="let article of articles; trackBy: articleId">
        <app-article-list-item [monthSelectionOnly]="preorderSettings.month_selection_only" [preorderBasketId]="basketId" [hideSaveButton]="maxQuantity || hideSaveButton ? true : false" [selectedArticles]="selectedArticles" [useTimeSlots]="preorderSettings.use_time_slots" [showAdditionalItems]="showAdditionalItems"
          [schedule]="bico && article.bico ? preorderSettings.months_schedule_bico : preorderSettings.months_schedule" [article]="article" (qtyChanged)="qtyChanged.emit($event)" [bico]="bico" [type]="preorderType"
          class="card d-flex col-12 align-items-stretch px-0" [ngClass]="article.is_mandatory_item ? 'col-lg-6 col-xl-4' : 'col-lg-12'" (showArticleDetails)="showArticleDetails($event)" [basketArticleId]="basketArticleId" [countryCode]="accountInformation.country_code">
        </app-article-list-item>
      </ng-container>
    </div>
    <ng-content></ng-content>
  </ng-container>
  <ng-container *ngIf="edit && accountInformation">
    <ng-container *ngFor="let basketArticle of basketArticles;">
      <app-article-list-item *ngIf="basketArticle['basket_item'].length " [monthSelectionOnly]="preorderSettings.month_selection_only" [preorderBasketId]="basketId" [article]="basketArticle['item_details']" [edit]="true" [bico]="bico" [type]="preorderType" [hideSaveButton]="hideSaveButton" [useTimeSlots]="preorderSettings.use_time_slots" [showAdditionalItems]="showAdditionalItems"
        [basketArticles]="basketArticle['basket_item']" [schedule]="bico && basketArticle['item_details'].bico ? preorderSettings.months_schedule_bico : preorderSettings.months_schedule"
        class="card d-flex col-lg-12 align-items-stretch  px-0" (showArticleDetails)="showArticleDetails($event)" [countryCode]="accountInformation.country_code">
      </app-article-list-item>
  </ng-container>
