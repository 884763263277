<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top"
  [ngClass]="[state === 'show' ? 'active' : '', isHelpdesk ? 'hlp' : '']">
  <div class="container pt-2" *ngIf="state === 'show'">
    <div class="row w-100 d-flex">
      <div class="col-md-6 row d-flex imgContainer align-items-center">
        <div class="col-md-3">
          <app-article-image *ngIf="article && article.thumbs" [imageUrl]="article.thumbs[0]" [useLazyLoad]="false" [articleType]="article.article_type"></app-article-image>
        </div>
        <div class="col-md-9">
          <h5 class="mt-2 pb-2 d-inline">{{article.description}}</h5>
          <app-stock-status [showText]="true" [article]="article"
            *ngIf="!article.matrix || article.matrix.options.length === 1"></app-stock-status>
          <app-matrix class="d-block" [article]="article" *ngIf="article.matrix && article.matrix.options.length > 1">
          </app-matrix>
        </div>
      </div>
      <div class="col-md-6 pt-2 mt-4 buttonContainer">
        <app-add-to-basket-button [article]="article" size="btn-sm"></app-add-to-basket-button>
        <app-article-links class="" [article]="article"></app-article-links>
      </div>
    </div>
  </div>
</nav>
