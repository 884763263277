import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { WarrantyArticle } from "src/app/interfaces/warranty/warranty-article";
import { Article } from "src/app/interfaces/article";
import { WarrantyPart } from "src/app/interfaces/warranty/warranty-part";
import { WarrantyService } from "src/app/services/warranty.service";
import { ApiResponse } from "src/app/interfaces/api-response";
import { HelperService } from "src/app/services/helper.service";
import moment from "moment";

@Component({
  selector: "app-warranty-callback",
  templateUrl: "./warranty-callback.component.html",
  styleUrls: ["./warranty-callback.component.scss"]
})
export class WarrantyCallbackComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() currentArticle: WarrantyArticle;
  @Input() tabSelected = "";
  @Output() bicycleSelect: EventEmitter<Article> = new EventEmitter<Article>();
  @Output() partSelect: EventEmitter<Article> = new EventEmitter<Article>();
  bicycleUnknown = false;
  constructor(private warrantyService: WarrantyService, private helperService: HelperService) {}

  ngOnInit() {}

  bicycleSelected(bicycle: Article) {
    this.bicycleUnknown = bicycle ? false : true;
    this.bicycleSelect.next(bicycle);
  }

  partSelected(data: WarrantyPart) {
    this.partSelect.next(data.article);
    const article = data.article as Article;

    this.warrantyService
      .checkArticleGuarantee(
        article.single_article_code,
        moment().format("YYYYMMDD"),
        "1"
      )
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
        }
      });

  }
}
