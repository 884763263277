import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: "app-barcode",
  templateUrl: "./barcode.component.html",
  styleUrls: ["./barcode.component.css"],
})
export class BarcodeComponent implements AfterViewInit {

  barcodeValue;

  ngAfterViewInit() {
   // this.barcodeScanner.start();
  }

  onValueChanges(result) {
    this.barcodeValue = result.codeResult.code;
  }

  onStarted(started) {
    console.log(started);
  }
}
