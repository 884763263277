import {
  Component,
  OnInit,
  Input,
  ComponentFactoryResolver,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { NewsItem } from 'src/app/interfaces/cms/news-item';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../../modal-components/confirm-dialog/confirm-dialog.component';
import { NewsDetailsComponent } from '../../cms/news-details/news-details.component';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from 'src/app/services/application.service';
import { StoryblokComponents } from '../../storyblok/storyblok-components';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss'],
})
export class NewsItemComponent implements OnInit {
  @Input() newsItem: NewsItem;
  @Input() useLazyLoad: boolean;
  @Input() active: boolean;
  @Input() setLocation: boolean;
  story = { content: null, name: '', html: false };
  components = StoryblokComponents;
  placeHolder = 'assets/images/newsPage.svg';
  formattedDate: string;

  constructor(
    private modalService: NgbModal,
    private translateService: TranslateService,
    private applicationService: ApplicationService,
    private location: Location,
    private elem: ElementRef
  ) {
    // Detect if browser has lazy loading
    if ('loading' in HTMLImageElement.prototype) {
      this.useLazyLoad = false;
    }
  }

  ngOnInit() {
    this.parseNewsItem();
  }

  ngAfterViewInit() {
    if (this.newsItem.start_date) {
      const startDate = this.newsItem.start_date.split('-');
      setTimeout(() => {
        this.formattedDate = `${startDate[0]} ${this.translateService.instant(
          moment.months(Number(startDate[1]) - 1).toUpperCase()
        )} ${startDate[2]}`;
      });
    }
    if (this.active) {
      this.showDetails();
    }
  }

  parseNewsItem() {
    this.story.html = false;

    try {
      const story = JSON.parse(this.newsItem.language_definitions[0].content);
      if (typeof story.content !== 'undefined') {
        this.story = story;
      } else {
        // render plain HTML (old CMS layout)
        this._setHtmlContent(story);
      }
    } catch (err) {
      this._setHtmlContent(this.newsItem.language_definitions[0].content);
    }
  }

  _setHtmlContent(content: string) {
    this.story.html = true;
    const elements = this.elem.nativeElement.getElementsByClassName(`content`);
    if (elements && elements.length) {
      elements[0].innerHTML = content;
    }
  }

  showDetails() {
    if (!this.newsItem.detailled) {
      const modalRef = this.modalService.open(ConfirmDialogComponent, {
        size: 'lg',
        windowClass: 'medium',
        container: '#modalContainer',
      });

      const newsItem = Object.assign({}, this.newsItem);
      newsItem.detailled = true;
      modalRef.componentInstance.isConfirm = false;

      if (this.setLocation) {
        this.location.go(
          this.applicationService.getSelectCompanyGroupCode() +
            '/news/' +
            this.newsItem.id
        );
        }
      modalRef.componentInstance.setContent(
        NewsDetailsComponent,
        'newsItem',
        newsItem
      );
    }
  }
}
