import { ScheduleWeek } from "../preorder/schedule-week";

export interface PreorderSettingsContainer {
  Bicycles: PreorderSettings;
  Parts: PreorderSettings;
}

export class PreorderSettingsContainer implements PreorderSettingsContainer {
  constructor() {
    this.Bicycles = new PreorderSettings();
    this.Parts = new PreorderSettings();
  }
}

export interface PreorderSettings {
  months_schedule_bico: ScheduleWeek[];
  company_group_code: string;
  enabled: Enabled;
  internal: boolean;
  use_time_slots: boolean;
  month_selection_only: boolean;
  last_possible_delivery_month: LastPossibleDeliveryMonth;
  months_schedule: ScheduleWeek[];
  active_dealer_codes: string;
  preorder_kind: string;
  separate_checkout: boolean;
}

export class PreorderSettings implements PreorderSettings {
  constructor() {
    this.enabled = {
      from: null,
      untill: null
    };
  }
}

export interface Enabled {
  from: Date;
  untill: Date;
}

export interface LastPossibleDeliveryMonth {
  year: number;
  month: number;
}
