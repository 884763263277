import { Injectable } from "@angular/core";
import * as moment from "moment";
import { timer } from "rxjs";
import { ApplicationService } from "src/app/services/application.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  loaded = false;
  constructor(private applicationService: ApplicationService) {}

  initChat() {
    if (
      this.applicationService.getSelectedLanguage() === "NL" &&
      this.applicationService.getSelectCompanyGroupCode() === "ANL"
    ) {
      // CHAT todo
      if (environment.name === "production" && Number(moment().format("YYYYMMDD")) < 20211118) {
        if (window["initChat"]) {
          window["initChat"]();
          return true;
        }
      } else {
        if (window["showChatBubble"]) {
          setTimeout(() => {
            if (!this.loaded) {
              this.loaded = true;
              window["showChatBubble"]();
            }
          }, 1000);

          return true;
        }
      }
    } else {
      this.toggleChat(false);
      return false;
    }
    return false;
  }

  showChat() {
    window["showChatWindow"]();
  }

  hasChat() {
    const el = document.getElementById("chatButton");
    if (el && el.classList) {
      const isOnline = el.classList.contains("online");
      if (isOnline) {
        return true;
      }
    }
    return false;
  }

  sendMessage(message: string) {
    window["sendMessage"](message);
  }

  setDepartment(companyId: string, partType: string) {
    if (typeof window["setDepartment"] !== "undefined") {
      window["setDepartment"](companyId, partType);
    }
  }

  toggleChat(state: boolean) {
    const chatButtonsQuery = document.querySelectorAll(".embeddedServiceHelpButton");
    if (chatButtonsQuery && chatButtonsQuery.length) {
      const chatButton = chatButtonsQuery[0] as HTMLDivElement;
      chatButton.style.display = state ? "block" : "none";
    }
  }
}
