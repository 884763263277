import { Component, Input } from '@angular/core';
import { StoryblokComponents } from 'src/app/components/storyblok/storyblok-components';
import { SearchComponent } from 'src/app/components/search-components/search/search.component';
import { SearchModule } from 'src/app/modules/search/search.module';

@Component({
  standalone: true,
  selector: 'app-article-collection',
  templateUrl: './article-collection.component.html',
  styleUrls: ['./article-collection.component.scss'],
  imports: [SearchModule]
})
export class ArticleCollectionComponent {
  components = StoryblokComponents;

  @Input() _uid: string;
  @Input() url: string;
  @Input() component: any;
}
