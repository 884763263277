export interface ProfileAccess {
  key: string;
  description: string;
  checked: boolean;
}

export class ProfileAccess implements ProfileAccess {
  constructor(key, description) {
    this.key = key;
    this.description = description;
  }
}
