import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Article } from "src/app/interfaces/article";
import { ApplicationService } from "src/app/services/application.service";
import { ArticleService } from "src/app/services/article.service";
import { Router } from "@angular/router";
import { saveAs } from 'file-saver';

@Component({
  selector: "app-image-bank-article",
  templateUrl: "./image-bank-article.component.html",
  styleUrls: ["./image-bank-article.component.scss"],
})
export class ImageBankArticleComponent implements OnInit {
  @Input() article: Article;

  constructor(
    private router: Router,
    public applicationService: ApplicationService,
    private articleService: ArticleService
  ) {}

  get hasImage() {
    if (this.article && this.article.images.length) {
      if (this.article.images[0].indexOf("coming_soon") >= 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  ngOnInit() {}

  gotoItem(event, article: Article) {
    if (this.hasImage) {
      const url =
        this.applicationService.getSelectCompanyGroupCode() +
        ("/imageDetails/" +
          article.id +
          "?u=" +
          encodeURIComponent(window.location.pathname + window.location.hash));

      this.router.navigateByUrl(url);
    }
  }

  downloadZip(modelId) {
    this.articleService.getDownloadZip(modelId).subscribe((blob: Blob) => {
      const file = new Blob([blob], { type: "application/octet-stream" });
      saveAs(file, `${this.article.description}.zip`);
    });
  }
}
