<li class="list-group-item py-0">
  <div class="row">
    <div class="col-3 col-md-1">{{profile.profile_id}}</div>
    <div class="col-5 col-md-9">{{profileTranslation(profile.profile_name)}}</div>
    <div class="col-4 col-md-2 flex flex-row-reverse">
      <i class="material-icons clickable withOpacity text-danger "
      (click)="deleteProfile()">delete_forever</i>
      <i class="material-icons clickable withOpacity  me-1" (click)="editProfile()">edit</i>
    </div>
  </div>
</li>
