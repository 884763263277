<app-bread-crumbs [(breadcrumbs)]="breadcrumbs"></app-bread-crumbs>
<div class="container">
  <div class="row">
    <div class="col-md-12 col-xl-6">
      <h1>{{'PC_LOTTERY_HEADER' | translate}}</h1>
      <p>{{'PC_LOTTERY_INTROTEXT' | translate}}</p>
      <app-lottery-registration></app-lottery-registration>
    </div>
  </div>
</div>
