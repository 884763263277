import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { forkJoin, Subject } from "rxjs";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Article } from "src/app/interfaces/article";
import { Basket } from "src/app/interfaces/basket";
import { BasketAction } from "src/app/interfaces/basket-action";
import { BasketArticle } from "src/app/interfaces/basketArticle";
import { ApplicationService } from "src/app/services/application.service";
import { BasketService } from "src/app/services/basket.service";
import { HelperService } from "src/app/services/helper.service";
import { SearchService } from "src/app/services/search.service";
import * as _ from "underscore";
import { saveAs } from 'file-saver';

@Component({
  selector: "app-backorder-alerter",
  templateUrl: "./backorder-alerter.component.html",
  styleUrls: ["./backorder-alerter.component.scss"],
})
export class BackorderAlerterComponent implements OnInit {
  articles: BasketArticle[] = [];
  articlesInBackorder = {};
  articlesInBackorderDetails: Article[] = [];
  basketItems: BasketArticle[] = [];
  basketId: number;
  basket: Basket;
  submitObserver = new Subject<any>();
  submitObserverEmitted$ = this.submitObserver.asObservable();

  deliverAllObserver = new Subject<any>();
  deliverAllObserverEmitted$ = this.submitObserver.asObservable();

  showDeliverAll: boolean;
  loading: boolean;
  constructor(
    public modal: NgbActiveModal,
    private searchService: SearchService,
    private helperService: HelperService,
    public applicationService: ApplicationService,
    private basketService: BasketService
  ) {}

  ngOnInit(): void {
    if (this.articlesInBackorder) {
      this.getArticles();
    }
  }

  checkAll() {
    this.articles.forEach((a: BasketArticle) => (a.backorder = true));
  }

  getArticles() {
    const codes = Object.keys(this.articlesInBackorder);
    if (codes.length) {
      this.searchService
      .getArticlesByCodes(Object.keys(this.articlesInBackorder))
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.articlesInBackorderDetails = Object.values(apiResponse.result);
        }
      });
    }
  }

  deleteArticle(article: Article) {
    this.applicationService.showLoader(true);
    const promises = [];
    const basketArticles = _.filter(this.basketItems, {
      article_code: article.single_article_code,
    });
    basketArticles.forEach((basketArticle: BasketArticle) => {
      promises.push(
        this.basketService.deleteArticle(this.basketId, basketArticle)
      );
    });

    forkJoin(promises).subscribe((apiResponses: ApiResponse[]) => {
      let hasError = false;
      apiResponses.forEach((apiResponse: ApiResponse) => {
        if (!this.helperService.checkResponse(apiResponse)) {
          this.articlesInBackorderDetails.forEach((a: Article) => {
            if (a.single_article_code === article.single_article_code) {
              a.error = true;
              hasError = true;
            }
          });
        }
        if (!hasError) {
          this.articlesInBackorderDetails = _.without(
            this.articlesInBackorderDetails,
            _.findWhere(this.articlesInBackorderDetails, {
              single_article_code: article.single_article_code,
            })
          );
        }
      });
      const basketAction = new BasketAction();
      basketAction.type = "refresh";
      basketAction.basketId = this.basketId;
      this.basketService.emitChange(basketAction);
      this.applicationService.hideLoader();
    });
  }

  // Set condition to deliver the order
  updateDelivery(completeDeliveryOnly) {
    this.loading = true;
    this.basket.complete_delivery_only = completeDeliveryOnly;
    this.basketService.updateBasket(this.basket).subscribe(() => {
      this.deliverAllObserver.next(this.basket.complete_delivery_only);
      this.loading = false;
    });
  }

  submit() {
    this.submitObserver.next(true);
    this.modal.close();
  }

  articleSwitchChanged(article: BasketArticle, value, key) {
    this.loading = true;
    article[key] = value;
    this.basketService
      .updateArticle(this.basketId, article)
      .subscribe((response) => {
        this.loading = false;
      });
  }
}
