import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RefineKeyword } from 'src/app/interfaces/refine-keyword';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
})
export class SearchFilterComponent implements OnInit {
  @Output() filter: EventEmitter<RefineKeyword> =
    new EventEmitter<RefineKeyword>();
  keyword = '';
  focussed = false;
  keepFilters = true;
  constructor() {}

  ngOnInit() {}

  search() {
    this.filter.emit({
      keyword: this.keyword,
      keepFilters: this.keepFilters,
    });
  }
}
