import { Article } from "./article";
import { Coupon } from "./coupon";

export interface BasketAction {
  type: string;
  basketId: number|string;
  article: Article;
  articles: Article[];
  basketArticleId: number;
  value: number;
  quantity: number;
  article_type: string;
  coupon: Coupon;
}

export class BasketAction implements BasketAction {
}
