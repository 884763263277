import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AccountSettings } from '../interfaces/account-settings';
import { User } from '../interfaces/user';
import { UpdateUserInput } from '../interfaces/update-user-input';
import { Credentials } from '../interfaces/credentials';
import { UnlinkAccount } from '../interfaces/unlink-account';
import { Profile } from '../interfaces/profile';
import { HelpdeskService } from './helpdesk.service';
import { AccountInformation } from '../interfaces/account-information';
import * as appGlobal from '../globals';
import { ApiResponse } from '../interfaces/api-response';
import { Dealer } from '../interfaces/dealer';
import { AccountAccessList } from '../interfaces/account-access-list';
import { OktaRegisterInput } from '../interfaces/okta-register-input';
import { HelperService } from './helper.service';
import { ApplicationService } from './application.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticateService } from './authenticate.service';
import moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NdaCheckComponent } from '../components/preorder-components/nda-check/nda-check.component';
import { SignedCondition } from '../interfaces/signed-condition';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  tempLoginUsername: string = '';
  apiCallPromise: Promise<unknown>;
  constructor(
    private http: HttpClient,
    private helpdeskService: HelpdeskService,
    private helperService: HelperService,
    private applicationService: ApplicationService,
    private translateService: TranslateService,
    private authService: AuthenticateService,
    private modalService: NgbModal
  ) {
    this.applicationService.emitChangeCompanyGroupChanged$.subscribe(
      (response) => {
        this.apiCallPromise = undefined;
      })
  }

  saveDealerInformation(dealerInformation: Dealer) {
    localStorage.setItem(
      'dealerInformation',
      JSON.stringify(dealerInformation)
    );
  }

  getDealerInformation() {
    return (<unknown>(
      this.helperService.getStorageItem('dealerInformation')
    )) as Dealer;
  }

  getAccountInformation(reset?: boolean) {
    let accountInformation: AccountInformation;

    if (this.apiCallPromise) {
      // If an API call is already in progress, return the existing promise
      return this.apiCallPromise;
    }

    this.apiCallPromise = new Promise((resolve, reject) => {
      if (!appGlobal.accountInformation || reset) {
        this.http
          .get(environment.apiendpoint + 'accounts/?v=2.0')
          .subscribe((apiResponse: ApiResponse) => {
            if (this.helperService.checkResponse(apiResponse)) {
              accountInformation = apiResponse.result;
            }
            appGlobal.saveAccountInformation(accountInformation);
            this.applicationService.hasDropshipment =
              accountInformation.can_access_dropshipment;
            resolve(accountInformation);
          });
      } else {
        resolve(appGlobal.accountInformation);
      }
    });

    return this.apiCallPromise;
  }

  getAvailableCompanyGroups() {
    return this.http.get(environment.apiendpoint + 'accounts/companygroups');
  }

  getMainDealerInfo() {
    return this.http.get(environment.apiendpoint + 'accounts/maindealerinfo');
  }

  getUsers() {
    return this.http.get(environment.apiendpoint + 'accounts/users');
  }

  getProfiles() {
    return this.http.get(environment.apiendpoint + 'accounts/profiles');
  }

  updateProfile(profile: Profile) {
    const updateData = {
      name: profile.profile_name,
      access: profile.access_rights,
    };
    return this.http.put(
      environment.apiendpoint + `accounts/profiles/${profile.profile_id}`,
      updateData
    );
  }

  getAccountSettings() {
    return this.http.get(environment.apiendpoint + 'accounts/settings');
  }

  setAccountAccess(accountSettings: AccountSettings) {
    if (accountSettings && accountSettings.profile_access) {
      const access = Object.values(accountSettings.profile_access);
      let accessList: any = new AccountAccessList();

      access.filter((e: string) => {
        accessList[e] = true;
      });
      localStorage.setItem('accessList', JSON.stringify(accessList));
    }
  }

  getAccountAccessOption(key: string) {
    const access = localStorage.getItem('accessList');
    if (access) {
      const parsedAccess: any = JSON.parse(access) as AccountAccessList;
      return typeof parsedAccess[key] !== 'undefined'
        ? parsedAccess[key]
        : true;
    } else {
      return true;
    }
  }

  updateAccountSettings(data: AccountSettings) {
    return this.http.put(environment.apiendpoint + 'accounts/settings/', data);
  }

  updateUserSettings(id: number, data: UpdateUserInput) {
    return this.http.put(
      environment.apiendpoint + 'accounts/users/' + id,
      data
    );
  }
  createUserSettings(data: UpdateUserInput) {
    return this.http.post(environment.apiendpoint + 'accounts/users/', data);
  }

  deleteUser(data: User) {
    return this.http.delete(
      environment.apiendpoint +
        'accounts/users/' +
        data.user_id +
        '/' +
        encodeURI(data.username)
    );
  }

  deleteProfile(data: Profile) {
    return this.http.delete(
      environment.apiendpoint + 'accounts/profiles/' + data.profile_id
    );
  }

  linkAccount(credentials: Credentials) {
    return this.http.post(
      environment.apiendpoint + 'accounts/link/',
      credentials
    );
  }
  unlinkAccount(unlinkAccount: UnlinkAccount) {
    return this.http.post(
      environment.apiendpoint + 'accounts/unlink/',
      unlinkAccount
    );
  }

  // Get the access options for a profile
  getProfileAccess() {
    return this.http.get(environment.apiendpoint + 'accounts/access');
  }

  getLinkedDealers() {
    return this.http.get(environment.apiendpoint + 'accounts/linkeddealers');
  }

  getAccountType() {
    return localStorage.getItem('accountType')
      ? localStorage.getItem('accountType')
      : null;
  }

  getRayonCodes() {
    return this.http.get(environment.apiendpoint + 'accounts/rayoncodes');
  }

  isDealer() {
    return (
      this.getAccountType() === 'DLR' ||
      this.helpdeskService.getSelectedDealer() !== null
    );
  }

  isHelpdesk() {
    return this.getAccountType() !== 'DLR';
  }

  isInternal() {
    return this.getAccountType() === 'INT';
  }


  resetPassword(user: User) {
    return this.http.post(environment.apiendpoint + 'reset', user);
  }

  resendActivation(user: User) {
    return this.http.get(
      environment.apiendpoint + `accounts/resendactivation/${user.user_id}`
    );
  }

  checkUniqueEmail(email: string) {
    return this.http.get(
      environment.apiendpoint +
        'accounts/uniqueemail?email=' +
        encodeURIComponent(email)
    );
  }

  selectLinkedAccount(userId: string) {
    return this.http.post(
      environment.apiendpoint + `accounts/linked/${userId}/activate/`,
      {
        userId: userId,
      }
    );
  }

  register(user: OktaRegisterInput) {
    return this.http.post(environment.apiendpoint + `accounts/register/`, user);
  }

  // Unlink new
  unlinkOktaAccount(user: User) {
    return this.http.get(
      environment.apiendpoint + `accounts/${user.user_id}/unlinkaccount`
    );
  }

  // Reset new
  resetOkta(user: User) {
    return this.http.get(
      environment.apiendpoint + `accounts/${user.user_id}/resetokta`
    );
  }

  // Resend new
  resendOktaActivation(user: User, email: string) {
    return this.http.post(
      environment.apiendpoint + `accounts/${user.user_id}/sendactivation`,
      {
        activation_email: email,
      }
    );
  }

  // Get the NonDisclosureAgreement
  getNda(section: string, year?: string, checkForDealer?: boolean) {
    const yearKey = `${year ? '_' : ''}${year ? year : ''}`;

    return new Promise((approve) => {
      const companyGroup = this.applicationService.getSelectCompanyGroupCode();
      this.http
        .get(
          environment.apiendpoint +
            `nda/${section}_${companyGroup}${yearKey}${checkForDealer && this.isHelpdesk() ? '?mode=servicedesk' : ''}`
        )
        .subscribe((apiResponse: ApiResponse) => {
          if (
            this.helperService.checkResponse(apiResponse) ||
            companyGroup !== this.applicationService.getSelectCompanyGroupCode()
          ) {
            approve(apiResponse.result as SignedCondition);
          } else {
            approve(null);
          }
        });

    });
  }

  // Saves the NonDisclosureAgreement
  saveNda(section: string, year?: string) {
    const yearKey = `${year ? '_' : ''}${year ? year : ''}`;
    return this.http.post(
      environment.apiendpoint +
        `nda/${section}_${this.applicationService.getSelectCompanyGroupCode()}${yearKey}`,
      {}
    );
  }

  saveAccountSettings(accountSettings: AccountSettings) {
    if (
      typeof accountSettings === 'object' &&
      accountSettings !== null &&
      typeof accountSettings.language_code !== 'undefined'
    ) {
      // save language of dealer to localstorage
      this.applicationService.saveSelectedLanguage(
        accountSettings.language_code
      );

      this.translateService.use(accountSettings.language_code);
      this.setAccountAccess(accountSettings);

      // Show showroommode button ?
      this.applicationService.saveSetting(
        'showShowroomMode',
        this.getAccountAccessOption('CanAccessNetCostprice')
          ? String(accountSettings.showroom_mode)
          : '0'
      );

      if (!this.getAccountAccessOption('CanAccessNetCostprice')) {
        this.applicationService.saveSetting('showroomMode', '1');
      }

      // Save the account code
      this.applicationService.saveSetting(
        'accountCode',
        accountSettings.account_code
      );

      // Save the username
      this.applicationService.saveSetting('userName', accountSettings.username);

      // Showroommode on/off
      this.applicationService.saveShowroomMode(
        String(accountSettings.showroom_mode_default)
      );

      // Save settings in global var
      this.applicationService.saveAccountSettings(accountSettings);

      this.applicationService.saveSelectedCompanyGroupCode(
        accountSettings.company_group
      );
    } else {
      this.authService.logout();
    }
  }

  portalSignin() {
    return this.http.get(
      `${environment.apiendpoint}accounts/serviceportallink`
    );
  }

  activationLink(accountCode: string, userId: string) {
    return this.http.get(
      `${environment.apiendpoint}accounts/getactivationlink/${accountCode}/${userId}`
    );
  }

  checkGeneralConditions() {
    this.getNda('preorder', moment().year().toString()).then(
      (response: SignedCondition) => {
        const hasSignedNda = response !== null;
        const reference = this.modalService.open(NdaCheckComponent, {
          container: '#modalContainer',
          windowClass: 'fixed-modal medium',
          backdrop: 'static',
          backdropClass: 'blurred',
          centered: true,
        });
        // Storyblok PageIds
        const pageIds = {
          GHO: 'nutzungsbedingungen-datenschutz',
          WG: 'nutzungsbedingungen-datenschutz',
        };
        reference.componentInstance.pageIds = pageIds;
        reference.componentInstance.signed = hasSignedNda;
        reference.componentInstance.checkedEvent.subscribe(() => {});
      }
    );
  }

  checkNda() {
    this.getNda('preorder', moment().year().toString()).then(
      (response: SignedCondition) => {
        const hasSignedNda = response !== null;
        const reference = this.modalService.open(NdaCheckComponent, {
          container: '#modalContainer',
          windowClass: 'fixed-modal medium',
          backdrop: 'static',
          backdropClass: 'blurred',
          centered: true,
        });
        // Storyblok PageIds
        const pageIds = {
          ANL: 960422135,
          GHO: 960422146,
          WG: 960422147,
        };
        reference.componentInstance.pageIds = pageIds;
        reference.componentInstance.signed = hasSignedNda;
        reference.componentInstance.checkedEvent.subscribe(() => {});
      }
    );
  }

  // Check if a user is in a testgroup
  isInABTestGroup() {
    const accountCode = localStorage.getItem('accountCode');
    const lastChar = accountCode.slice(-1);
    const testGroupString = ['0', '1', '2'];
    if (testGroupString.includes(lastChar)) {
      return true;
    } else {
      return false;
    }
  }
}
